/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { FormValidator } from '@syncfusion/ej2-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent, CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent, ListBoxComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import { RichTextEditorComponent, Toolbar as RTEtoolbar, Inject as RTEInject, Image, Link, HtmlEditor, Count, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import { Browser } from '@syncfusion/ej2-base';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random } from 'underscore';
import moment from 'moment';
import { MDBFooter } from 'mdb-react-ui-kit';
import PaginaPeriziaOnlineCalcoli from './PaginaPeriziaOnlineCalcoli';
import PaginaDettaglioSinistro from './PaginaDettaglioSinistro';
import $ from 'jquery';

export default class PaginaPeriziaOnline extends SampleBase {
    constructor() {
        super(...arguments);
        this.componentUnaVolta = 0;
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.childKey = 0;
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.ddlFieldsList = { text: 'text', value: 'id' };
        this.RTEitems = ['Bold', 'Italic', 'Underline',
            'FontName', 'FontSize',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'CreateLink', 'Print', '|', 'Undo', 'Redo'
        ];
        this.RTEtoolbarSettings = {
            items: this.RTEitems
        };
        this.formOptions = {
            rules: {
            }
        }
        //PREFISSO COMPAGNIA
        this.prefissoCompagnia = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabellaCompagnie_prefissi')).find(({ COD_ELE }) => COD_ELE === this.props.IdCompagnia.toString())?.DESCRIZ;
        this.nomeCompagnia = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_compagnie')).find(({ id_compagnia }) => id_compagnia === this.props.IdCompagnia.toString())?.compagnia;
        this.dsMotiviRivalsa = [];
        if (this.isCompagniaGenerali()) {
            this.dsPartiteRicGen = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_partita_ric`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
            this.dsPartite = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_partita`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, GUID: '0000-0000-0000-0000' } });
            this.dsGaranzie = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_garanzia`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, GUID: '0000-0000-0000-0000', SERVIZIO_STR2: elm.SERVIZIO_STR2 } });
            this.dsGaranzieRicevuteGen = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_garanzia_ric`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
            this.dsGruppiGaranzia = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_gruppi_gar`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
            this.dsFormaAssicurazioni = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_forma_ass`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
            this.dsFormulaAssicurazioni = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_formula_ass`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
            this.dsCondizioneRischioData = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_stato_rischio`)).map(elm => { return { id: elm.COD_ELE, text: elm.DESCRIZ } });
        }
        else {
            if (this.isCompagniaUnipol()) {
                this.dsPartite = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaUNIPOLSAI_Partite`)).map(elm => { return { VALUE: elm.id_partita.toString(), TEXT: elm.partita, TIPO_SIN: elm.id_tipo_sinistro } });
                this.dsMotiviRivalsa = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaUNI_motivo_rivalsa`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
            }
            else
                this.dsPartite = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_partite_app`)).map(elm => { return { VALUE: elm.id_partita_app.toString(), TEXT: elm.partita_app, TIPO_SIN: elm.id_tipo_sinistro } });
            // this.dsPartiteFiltrato = []
            // this.dsPartite.forEach(elm => {
            //     let more = []
            //     if (elm.TIPO_SIN.includes(',')) {
            //         more = elm.TIPO_SIN.split(',');
            //         more.forEach(moreEle => {
            //             if (moreEle === this.props.tipoSinistro) {
            //                 let obj = {
            //                     VALUE: elm.VALUE,
            //                     TEXT: elm.TEXT,
            //                 };
            //                 this.dsPartiteFiltrato.push(obj)
            //             }
            //         });
            //     }
            // })
            // this.dsPartite = this.dsPartiteFiltrato;
            // if (this.dsPartite.length === 0)
            //     this.dsPartite = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_partite_app`)).map(elm => { return { VALUE: elm.id_partita_app.toString(), TEXT: elm.partita_app, TIPO_SIN: elm.id_tipo_sinistro } });

            this.dsGaranzie = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_garanzie`)).map(elm => { return { VALUE: elm.id_garanzia.toString(), TEXT: elm.garanzia, TIPO_SIN: elm.id_tipo_sinistro.toString() } });
            // this.dsGaranzieFiltrato = []
            // this.dsGaranzie.forEach(elm => {
            //     if (elm.TIPO_SIN === this.props.tipoSinistro) {
            //         let obj = {
            //             VALUE: elm.VALUE,
            //             TEXT: elm.TEXT,
            //         };
            //         this.dsGaranzieFiltrato.push(elm)
            //     }
            // })
            // this.dsGaranzie = this.dsGaranzieFiltrato;
            // if (this.dsGaranzie.length === 0)
            //     this.dsGaranzie = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_garanzie`)).map(elm => { return { VALUE: elm.id_garanzia.toString(), TEXT: elm.garanzia, TIPO_SIN: elm.id_tipo_sinistro.toString() } });

            //DS di Generali che non sono utilizzati ma devono essere instanziati
            this.dsPartiteRicGen = [];
            this.dsGaranzieRicevuteGen = [];
            this.dsFormaAssicurazioni = [];
            this.dsFormulaAssicurazioni = [];
            this.dsGruppiGaranzia = [];
        }
        //DS BASE CONDIVISI CON TUTTE LE COMPAGNIE
        //todo da gestire le altre determinazioni del danno!
        //todo da sbloccare quando ho gestito gli altri casi
        // this.dsDeterminazioneDanno = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaDeterm_danno`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
        //momentaneo!!
        this.dsDeterminazioneDanno = [
            { TEXT: 'Valore a Nuovo', VALUE: '001' },
            { TEXT: "Valore allo stato d'uso e supplemento di indennizzo", VALUE: '003' }];

        if (this.isCompagniaUnipol())
            this.dsCondizioneRischioData = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_stato_fabbricato`)).map(elm => { return { id: elm.COD_ELE, text: elm.DESCRIZ } });
        else
            //DS GENERALI CONDIVISI CON TUTTE LE COMPAGNIE
            this.dsCondizioneRischioData = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaGEN_stato_rischio`)).map(elm => { return { id: elm.COD_ELE, text: elm.DESCRIZ } });
        //todo da gestire le altre forme garanzie!
        //todo da sbloccare quando ho gestito gli altri casi
        // this.dsFormaGaranzia = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaGEN_forma_ass`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
        this.dsFormaGaranzia = [
            { TEXT: "P.R.A.", VALUE: '1' },
            { TEXT: "VALORE INTERO", VALUE: '2' }
        ]
        this.dsTipoServizio = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaGEN_servizio`)).map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, SERVIZIO_STR2: elm.SERVIZIO_STR2 } });

        //Numero di ogni combo per ciclare i box
        this.numComboPartite = 0;
        this.numComboGaranzie = 0;
        this.numComboStime = 0;
        this.danneggiati = [];
        this.dsDanneggiati = [];
        this.dsFranchigiaCondivisa = [];

        //STRUTTURA INTERNA STATI OPERAZIONI CRUD 
        this.strutturaStatiPartite = [];
        this.strutturaStatiGaranzie = [];
        this.strutturaStatiStime = [];
        this.statoInfoElaboratoPeritale = {};

        this.strutturaStatiEliminaDati = {
            idPartite: [],
            idGaranzie: [],
            idStime: [],
            idPreesistenzaXpert: [],
            idPreesistenzaCostruz: [],
            idPreesistenzaDescr: [],
            idPreesistenzaGen: [],
            idStimeDescrizioni: [],
            idDannoDettInfoRichGen: [],
            idCoassicurazioni: [],
            idInfoRichieste: [],
            idDanneggiatiGen: [],
            idFranchige: [],
            idRiepilogoPerizia: 0,
            idRiepilogoDanneggiati: [],
            idRiepilogoRigheDanneggiato: [],
            idCausali: []
        }
        //VARIABILI DI UTILITY
        this.datiPerNote = {}
        this.danneggiatiAggiunti = []
        this.periziaSenzaCalcoli = false;

        //DATI DELLE LISTBOX
        this.dsLimiteIndennizzo = [
            { TEXT: 'Anno', VALUE: 'anno' },
            { TEXT: 'Sinistro', VALUE: 'sinistro' },
        ]

        this.tipoAbitazioneRischAssData = [
            { text: 'Condominio', id: 'condominio' },
            { text: 'Appartamento', id: 'appartamento' },
            { text: 'Villa', id: 'villa' },
            { text: 'Villa a schiera', id: 'villa-a-schiera' },
            { text: 'Fabbricato Unifamiliare', id: 'fabbricato-unifamiliare' },
            { text: 'Capannone Industriale', id: 'capannone-unifamiliare' },
            { text: 'Negozio', id: 'negozio' },
            { text: 'Capannone commerciale', id: 'capannone-commerciale' }
        ];
        this.adibitoData = [
            { text: 'Totalita', id: 'totalita' },
            { text: 'Almeno 3/4', id: 'almeno-3/4' },
            { text: 'Almeno 2/3', id: 'almeno-2/3' },
            { text: 'Prevalentemente', id: 'prevalentemente' }
        ];
        this.adibitoAData = [
            { text: 'Civile Abitazione', id: 'civile-abitazione' },
            { text: 'Uso Commerciale', id: 'uso-commerciale' },
            { text: 'Uso Industriale', id: 'uso-industriale' },
            { text: 'Uso Servizi', id: 'uso-servizi' }
        ];
        this.strutturaPortanteData = [
            { text: 'Calcestruzzo', id: 'calcestruzzo' },
            { text: 'Legno', id: 'legno' },
            { text: 'Muratura Portante', id: 'muratura-portante' },

        ];
        this.tamponamentiData = [
            { text: 'Laterizio', id: 'laterizio' },
            { text: 'Pennelli Metallici', id: 'pennelli-metallici' },
            { text: 'Legno', id: 'legno' },
        ];
        this.ordituraTettoData = [
            { text: 'Calcestruzzo', id: 'calcestruzzo' },
            { text: 'Legno', id: 'legno' },
            { text: 'Metallo', id: 'metallo' },
            { text: 'Prefabbricati', id: 'prefabbricati' },
        ];
        this.coperturaData = [
            { text: 'Tegole', id: 'tegole' },
            { text: 'Pennelli Metallici', id: 'pennelli-metallici' },
        ];
        this.finitureData = [
            { text: 'Standard', id: 'standard' },
            { text: 'Economiche', id: 'economiche' },
            { text: 'Di Pregio', id: 'di-pregio' }
        ];
        this.tipoImpiantoData = [
            { text: 'Installato o A terra', id: 'installato-o-a-terra' },
            { text: 'Integrato', id: 'integrato' },
        ]
        this.tipoAbitazioneMezzChiusData = [
            { text: 'Condominio', id: 'condominio' },
            { text: 'Appartamento', id: 'appartamento' },
            { text: 'Villa', id: 'villa' },
            { text: 'Villa a schiera', id: 'villa-a-schiera' },
            { text: 'Fabbricato Unifamiliare', id: 'fabbricato-unifamiliare' },
            { text: 'Capannone Industriale', id: 'capannone-unifamiliare' },
            { text: 'Negozio', id: 'negozio' },
            { text: 'Capannone commerciale', id: 'capannone-commerciale' }
        ];
        this.porteIngressoLocaliData = [
            { text: 'In legno pieno', id: 'in-legno-pieno' },
            { text: 'In metallo', id: 'metallo' },
            { text: 'In plastica', id: 'plastica' },
            { text: 'Con telaio in legno e vetro', id: 'con-telaio-in-legno-e-vetro' },
            { text: 'Con telaio in metallo e vetro', id: 'con-telaio-in-metallo-e-vetro' },
        ]
        this.finestreFuoriTerra = [
            { text: 'In legno pieno', id: 'in-legno-pieno' },
            { text: 'In metallo', id: 'in-metallo' },
            { text: 'In plastica', id: 'in-plastica' },
            { text: 'Con telaio in legno e vetro', id: 'con-telaio-in-legno-e-vetro' },
            { text: 'Con telaio in metallo e vetro', id: 'con-telaio-in-metallo-e-vetro' },
        ]
        this.tipoFinestreFuoriTerra = [
            { text: 'Antisfondamento', id: 'antisfondamento' },
            { text: 'Non antisfondamento', id: 'non-antisfondamento' },
        ];
        this.tipoFinestreFuoriTerra2 = [
            { text: 'Tapparelle', id: 'tapparelle' },
            { text: 'Gelosie', id: 'gelosie' },
        ];
        this.tipoFinestreFuoriTerra3 = [
            { text: 'In legno', id: 'in-legno' },
            { text: 'In PVC', id: 'in-pvc' },
            { text: 'Metalliche', id: 'metalliche' },
        ];
        this.dsCausaSinistro = [
            { text: 'Occlusione Della Colonna Di Scarico', id: 'occlusioneColonnaScarico' },
            { text: 'Occlusione Impianto Fognario', id: 'occlusioneImpiantoFogniario' },
            { text: 'Rottura Accidentale Braga Del Bagno', id: 'rotturaBragaBagno' },
            { text: 'Rottura Accidentale Colonna Di Scarico', id: 'rotturaColonnaScarico' },
            { text: 'Rottura Accidentale Colonna Di Carico e Braga Del Bagno', id: 'rotturaColCaricoBragaBagno' },
            { text: 'Rottura Accidentale Colonna Di Scarico e Braga Del Bagno', id: 'rotturaColScaricoBragaBagno' },
            { text: 'Rottura Accidentale Conduttura Di Scarico', id: 'rotturaColonnaScarico' },
            { text: 'Rottura Accidentale Cucchiaia', id: 'rotturaCucchiaia' },
            { text: 'Rottura Accidentale Impianto Di Riscaldamento', id: 'rotturaImpiantoRisc' },
            { text: 'Rottura Accidentale Pluviale Incassato', id: 'rotturaPluvialeIncassato' },
            { text: 'Rottura Accidentale Pluviale Esterno', id: 'rotturaPluvialeEsterno' },
            { text: "Rottura Accidentale Tubazione Alimentazione Dell'Acqua", id: 'rotturaTubazioneAcqua' },
            { text: 'Rottura Accidentale Tubazione Di Scarico', id: 'rotturaTubazioneScarico' },
            { text: 'Rottura Accidentale Tubazione Scarico Sotto Piatto Doccia', id: 'rotturaTubazioneScaricoPiattoDoccia' },
            { text: 'Rottura Accidentale Tubazione Esterna', id: 'rotturaTubazioneEsterna' },
            { text: 'Rottura Accidentale Sifone a Pavimento', id: 'rotturaSifonePavimento' },
            { text: 'Rottura Tubazione Riscaldamento Vaso Di Espansione', id: 'rotturaRiscaldamentoEspansione' },
            { text: 'Semplici Infiltrazioni, Senza Alcuna Rottura', id: 'infiltrazioni' },
        ]
        this.dsEffettoSinistro = [
            { text: "Spargimento d'Acqua Nello Stesso Locale", id: 'spargStessoLocale' },
            { text: "Spargimento d'Acqua Nello Stesso Piano", id: 'spargStessoPiano' },
            { text: "Spargimento d'Acqua Nel Piano Sottostante", id: 'spargPianoSotto' },
            { text: "Spargimento d'Acqua Nei Piani Sottostanti", id: 'spargPianiSotto' },
            { text: "Spargimento d'Acqua Nello Stesso Piano e Nei Piani Sottostanti", id: 'spargStessoPianoSotto' },
            { text: "Spargimento d'Acqua Nello Stesso Locale e In Quello Al Piano Sottostante", id: 'spargStessoLocaleSotto' },
            { text: "Spargimento d'Acqua Nel Locale Cantine", id: 'spargLocaleCantine' },
            { text: "Spargimento d'Acqua Nel Vano Scale", id: 'spargVanoScale' },
        ];
        this.riservaData = [
            { text: "No RG", id: 'no-rg' },
            { text: "No Danni Materiali", id: 'no-danni-materiali' },
            { text: "Tempistiche", id: 'tempistiche' },
            { text: "No Garanzia", id: 'no-garanzia' },
            { text: "Esclusione", id: 'esclusione' },
            { text: "No Residui", id: 'no-residui' },
            { text: "Rischio Non Conforme", id: 'rischio-non-conforme' },
        ]

        this.dataSourcePartite = [];
        this.dataSourceGaranzie = [];
        this.keyPartitePrecModificate = []
        this.keyGaranziePrecModificate = []
        this.keyStimePrecModificate = []
    }
    state = {
        blocking: false,
        showDialogElab: false
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toggleBlocking2(show) {
        let container, root;
        if (show) {
            document.getElementById('blockUIGenerico').style.removeProperty('display');
            document.getElementById('spinnerUIGenerico').style.removeProperty('display');
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
        }
        else {
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.unmount();
            document.getElementById('blockUIGenerico').style.display = 'none';
            document.getElementById('spinnerUIGenerico').style.display = 'none';
        }
    }

    //------------------FUNZIONI DI UTILITY INIZIO------------------
    rimuoviDaStrutturaStatiElementoNuovoEliminato(value, index, arr) {
        // If the value at the current array index matches the specified value
        if (value.statoIniziale === 'n' && value.stato === 'd') {
            // Removes the value from the original array
            arr.splice(index, 1);
            return;
        }
        return;
    }

    //------------------FUNZIONI DI UTILITY FINE------------------

    //------------------GESTIONE MODIFICA INIZIO------------------
    modificaPerizia() {
        this.dialogElaboratoInstance.height = '25%';
        this.dialogElaboratoInstance.minHeight = '25%';
        this.dialogElaboratoInstance.width = '40%';
        this.dialogElaboratoInstance.beforeOpen = (args) => {
            const container = document.getElementById('containerDialogElaboratoPeritale');
            const root = createRoot(container);
            root.unmount();
        }
        this.dialogElaboratoInstance.open = (args) => {
            const container = document.getElementById('containerDialogElaboratoPeritale');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
            }}>{<>
                <div style={{ textAlign: 'center' }}>
                    <h4 style={{ color: 'goldenrod' }}>ATTENZIONE!</h4>
                    <h6>Se modifichi la costruzione delle tabelle ricordati di <span style={{ color: 'green' }}>ri-salvare i calcoli </span>aggiornati nella pagina successiva</h6>
                    <h6>Sicuro di voler procedere ?</h6>
                </div>
            </>}</CallbackWrapper>);
        }
        this.dialogElaboratoInstance.buttons = [
            {
                click: () => {
                    this.dialogElaboratoInstance.hide();
                    document.getElementById(`btnModificaPerizia`).ej2_instances[0].disabled = true;
                    document.getElementById('salvaEProcedi').ej2_instances[0].disabled = false;

                    //SBLOCCA COMBO
                    for (let p = 0; p < this.numComboPartite; p++) {
                        if (document.getElementById(`Partita${p}`)) {
                            //SBLOCCO PARTITE
                            document.getElementById(`Partita${p}`).ej2_instances[0].readonly = false;
                            document.getElementById(`DescrizionePartita${p}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FormaGaranzia${p}`).ej2_instances[0].readonly = false;
                            document.getElementById(`aggiungiPartita${p}`).ej2_instances[0].disabled = false
                            //se la partita NON E' importata lo abilito
                            if (document.getElementById(`partitaImportata${p}`).innerHTML !== '<i class="fas fa-arrow-circle-up"></i>')
                                document.getElementById(`eliminaPartita${p}`).ej2_instances[0].disabled = false

                        }
                    }

                    document.getElementById(`btnAggiungiPartita`).ej2_instances[0].disabled = false

                    for (let i = 0; i < this.numComboGaranzie; i++) {
                        if (document.getElementById(`Garanzia${i}`)) {
                            //SBLOCCO GARANZIE
                            document.getElementById(`Garanzia${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`DeterminazioneDanno${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FranchigiaCondivisa${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FranchigiaEuro${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FranchigiaGiorni${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`ScopertoPerc${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`ScopertoMinimo${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`ScopertoMassimo${i}`).ej2_instances[0].readonly = false;
                            //SBLOCCO GARANZIE GENERALI
                            document.getElementById(`GruppoGaranzia${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`partitaAssicurata${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FormulaAssicurazione${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FormaAssicurazione${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`SommaAssicurata${i}`).ej2_instances[0].readonly = false;

                            document.getElementById(`aggiungiGaranzia${i}`).ej2_instances[0].disabled = false
                            //se la garanzia NON E' importata lo abilito
                            if (document.getElementById(`garanziaImportata${i}`).innerHTML !== '<i class="fas fa-arrow-circle-up"></i>')
                                document.getElementById(`eliminaGaranzia${i}`).ej2_instances[0].disabled = false
                        }
                    }

                    document.getElementById(`btnAggiungiGaranzia`).ej2_instances[0].disabled = false

                    //controllo se ci sono più stime con lo stesso danneggiato importato le altre si possono eliminare 

                    let idDannImportati = []
                    for (let i = 0; i < this.numComboStime; i++) {
                        if (document.getElementById(`containerComboStima${i}`)) {
                            //SBLOCCO CAMPI TABELLE DI STIMA GENERICI
                            document.getElementById(`PartitaTabellaStima${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`GaranziaTabellaStima${i}`).ej2_instances[0].readonly = false;
                            document.getElementById(`aggiungiStima${i}`).ej2_instances[0].disabled = false

                            //FACCIO IL CONTROLLO SUI DANENGGIATI IMPORTATI 
                            if (document.getElementById(`danneggiatoImportato${i}`).innerHTML === '<i class="fas fa-arrow-circle-up"></i>') {
                                idDannImportati.push({
                                    id: document.getElementById(`DanneggiatoTabellaStima${i}`).ej2_instances[0].value,
                                    num: i
                                })
                            }
                            else {
                                //se non è importato lo abilito
                                document.getElementById(`DanneggiatoTabellaStima${i}`).ej2_instances[0].readonly = false;
                                document.getElementById(`btnSwitchDanneggiato${i}`).ej2_instances[0].disabled = false;
                                document.getElementById(`eliminaStima${i}`).ej2_instances[0].disabled = false
                            }
                        }
                    }
                    const raggruppati = idDannImportati.reduce((acc, obj) => {
                        const key = obj.id;
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(obj);
                        return acc;
                    }, {});
                    for (const groupId in raggruppati) {
                        console.log(`Group ${groupId}:`);
                        const group = raggruppati[groupId];
                        let indice = 0;
                        for (const obj of group) {
                            if (indice > 0) {
                                //sono le stime con danneggiato importato doppio e posso modificarle a piacimento
                                document.getElementById(`DanneggiatoTabellaStima${obj.num}`).ej2_instances[0].readonly = false;
                                document.getElementById(`btnSwitchDanneggiato${obj.num}`).ej2_instances[0].disabled = false;
                                document.getElementById(`eliminaStima${obj.num}`).ej2_instances[0].disabled = false
                            }
                            indice++
                        }
                    }

                    document.getElementById(`btnAggiungiStima`).ej2_instances[0].disabled = false;

                    //partite precedenti 
                    this.strutturaStatiPartite.forEach(part => {
                        this.keyPartitePrecModificate.push(
                            {
                                key: part.key,
                                codEle: document.getElementById(`Partita${part.num}`).ej2_instances[0].value,
                                formaGaranzia: document.getElementById(`FormaGaranzia${part.num}`).ej2_instances[0].value
                            }
                        )
                    })
                    //precedenti garanzie
                    this.strutturaStatiGaranzie.forEach(gar => {
                        this.keyGaranziePrecModificate.push(
                            {
                                key: gar.key,
                                codEle: document.getElementById(`Garanzia${gar.num}`).ej2_instances[0].value,
                                determinazioneDanno: document.getElementById(`DeterminazioneDanno${gar.num}`).ej2_instances[0].value
                            }
                        )
                    })
                    //stime precedenti
                    this.strutturaStatiStime.forEach(stima => {
                        this.keyStimePrecModificate.push(
                            {
                                keyXpert: stima.keyXpert,
                                keyGen: stima.keyGenerali,
                                codEleGaranzia: document.getElementById(`GaranziaTabellaStima${stima.num}`).ej2_instances[0].itemData.COD_ELE,
                            }
                        )
                    })
                },
                buttonModel: {
                    content: 'CONFERMA',
                    cssClass: 'e-primary',
                    isPrimary: true,
                }
            },
            {
                click: () => {
                    this.dialogElaboratoInstance.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogElaboratoInstance.show();
    }

    ripristinaPerizia() {
        this.dialogElaboratoInstance.height = '20%';
        this.dialogElaboratoInstance.minHeight = '25%';
        this.dialogElaboratoInstance.width = '30%';
        this.dialogElaboratoInstance.beforeOpen = (args) => {
            const container = document.getElementById('containerDialogElaboratoPeritale');
            const root = createRoot(container);
            root.unmount();
        }
        this.dialogElaboratoInstance.open = (args) => {
            const container = document.getElementById('containerDialogElaboratoPeritale');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
            }}>{<>
                <div style={{ textAlign: 'center' }}>
                    <h4 style={{ color: 'red' }}>ATTENZIONE!</h4>
                    <h6>Se decidi di ripristinare la perizia tornerai allo stato iniziale e <span style={{ color: 'red' }}> perderai TUTTE </span> le modifiche e i calcoli effettuati!</h6>
                    <h6>Sicuro di voler procedere ?</h6>
                </div>
            </>}</CallbackWrapper>);
        }
        this.dialogElaboratoInstance.buttons = [
            {
                click: () => {
                    this.eliminaTuttiCalcoliPerizia();
                },
                buttonModel: {
                    content: 'CONFERMA',
                    cssClass: 'e-primary',
                    isPrimary: true,
                }
            },
            {
                click: () => {
                    this.dialogElaboratoInstance.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogElaboratoInstance.show();
    }

    //------------------GESTIONE MODIFICA FINE------------------


    //------------------GESTIONE PARTITE INIZIO------------------
    aggiungiPartita(retDataPartiteP, casoIniziale) {
        let numCPG = this.numComboPartite;
        this.numComboPartite++;

        const DivContainer = document.getElementById(`tutteComboPartite`);
        const newDiv = document.createElement("div");
        newDiv.setAttribute("id", `containerComboPartita${numCPG}`);
        newDiv.setAttribute("class", "container-combo-periziaOnline-p1")
        DivContainer.append(newDiv);

        const container = document.getElementById(`containerComboPartita${numCPG}`)
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
            if (CustomJS.isNullEmpty(retDataPartiteP)) {
                let objStrutturaStatiPartite = {
                    id_incarico: this.props.IdIncarico,
                    importato: false,
                    stato: 'n',
                    statoIniziale: 'n',
                    guid_partita: CustomJS.createGuid4(),
                    guidTemp: CustomJS.createGuid4(),
                    key: null,
                    id: `containerComboPartita${numCPG}`,
                    casoIniziale: false,
                    num: numCPG
                };
                this.strutturaStatiPartite.push(objStrutturaStatiPartite)
                if (this.isCompagniaGenerali()) {
                    let numFirstChild = document.getElementById("tutteComboPartite").firstChild.id;
                    numFirstChild = numFirstChild.replace("containerComboPartita", "");
                    document.getElementById(`PartitaRicevuta${numCPG}`).ej2_instances[0].value = document.getElementById(`PartitaRicevuta${numFirstChild}`).ej2_instances[0].value
                }
            }
            else {
                if (casoIniziale === 'casoIniziale') {
                    document.getElementById(`PartitaRicevuta${numCPG}`).ej2_instances[0].value = retDataPartiteP.tipoPartita_codice;
                    document.getElementById(`SommaAssicurataPartita${numCPG}`).ej2_instances[0].value = retDataPartiteP.valore_assicurato;

                    let objStrutturaStatiPartite = {
                        id_incarico: this.props.IdIncarico,
                        importato: retDataPartiteP.importato,
                        stato: 'o',
                        statoIniziale: 'o',
                        guid_partita: retDataPartiteP.id_partita,
                        guidTemp: CustomJS.createGuid4(),
                        key: null,
                        id: `containerComboPartita${numCPG}`,
                        casoIniziale: true,
                        num: numCPG
                    };
                    this.strutturaStatiPartite.push(objStrutturaStatiPartite)
                }
                else {
                    document.getElementById(`Partita${numCPG}`).ej2_instances[0].value = retDataPartiteP.cod_ele_partita;
                    document.getElementById(`FormaGaranzia${numCPG}`).ej2_instances[0].value = retDataPartiteP.formaGaranzia;
                    document.getElementById(`SommaAssicurataPartita${numCPG}`).ej2_instances[0].value = retDataPartiteP.sommaAssicurataPartita;
                    document.getElementById(`DescrizionePartita${numCPG}`).ej2_instances[0].value = retDataPartiteP.descrizione;
                    //BLOCCO CAMPI
                    document.getElementById(`Partita${numCPG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`FormaGaranzia${numCPG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`SommaAssicurataPartita${numCPG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`DescrizionePartita${numCPG}`).ej2_instances[0].readonly = true;
                    //BLOCCO TASTI
                    document.getElementById(`aggiungiPartita${numCPG}`).ej2_instances[0].disabled = true
                    document.getElementById(`eliminaPartita${numCPG}`).ej2_instances[0].disabled = true

                    //GENERALI
                    if (this.isCompagniaGenerali()) {
                        document.getElementById(`PartitaRicevuta${numCPG}`).ej2_instances[0].value = retDataPartiteP.tipoPartita_codice;
                    }
                    let objStrutturaStatiPartite = {
                        id_incarico: this.props.IdIncarico,
                        importato: retDataPartiteP.importato,
                        stato: 'o',
                        statoIniziale: 'o',
                        guid_partita: retDataPartiteP.id_partita,
                        key: retDataPartiteP.id_partitaPeriziaOnline,
                        guidTemp: retDataPartiteP.guid_partita_temp, //da mettere
                        id: `containerComboPartita${numCPG}`,
                        casoIniziale: false,
                        num: numCPG
                    };
                    this.strutturaStatiPartite.push(objStrutturaStatiPartite)
                    setTimeout(() => {
                        document.getElementById(`Partita${numCPG}`).ej2_instances[0].itemData.GUID = retDataPartiteP.id_partita;
                    }, 75)
                }

                if (this.isCompagniaGenerali()) {
                    //BLOCCO CAMPI
                    document.getElementById(`PartitaRicevuta${numCPG}`).ej2_instances[0].readonly = true;
                    //SE E' IMPORTATO NON E' ELIMINABILE!
                    if (retDataPartiteP.importato === 1 || retDataPartiteP.importato === true) {
                        document.getElementById(`partitaImportata${numCPG}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                        document.getElementById(`DescrizionePartita${numCPG}`).style.backgroundColor = '#edf0fa';
                        if (document.getElementById(`eliminaPartita${numCPG}`)) {
                            document.getElementById(`eliminaPartita${numCPG}`).ej2_instances[0].disabled = true;
                        }
                    }
                }
            }
        }}><div className='row'>
                <p className='importatoGen' title='Partita importata' id={`partitaImportata${numCPG}`}></p>
                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                    <DropDownListComponent id={`PartitaRicevuta${numCPG}`} fields={this.ddlFields} dataSource={this.dsPartiteRicGen}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        placeholder="Partita Ricevuta" floatLabelType='always' cssClass='e-outline'>
                    </DropDownListComponent>
                </div>
                <div className={this.isCompagniaGenerali() ? "col-xs-2 col-sm-2 col-lg-2 col-md-2" : "col-xs-4 col-sm-4 col-lg-4 col-md-4"}>
                    <DropDownListComponent onChange={this.cambiaPartita.bind(this)} id={`Partita${numCPG}`} fields={this.ddlFields} dataSource={this.dsPartite}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                    </DropDownListComponent>
                </div>
                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                    <DropDownListComponent id={`FormaGaranzia${numCPG}`} fields={this.ddlFields} dataSource={this.dsFormaGaranzia}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        placeholder="Forma Della Garanzia/Forma Assicurazione" floatLabelType='always' cssClass='e-outline'>
                    </DropDownListComponent>
                </div>
                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" >
                    <NumericTextBoxComponent showSpinButton={false} id={`SommaAssicurataPartita${numCPG}`} floatLabelType='Auto' format="c2" decimals='2' placeholder='Somma Assicurata' cssClass='e-outline'></NumericTextBoxComponent >
                </div>
                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                    <TextBoxComponent id={`DescrizionePartita${numCPG}`} onChange={this.descrizionePartiteChange.bind(this, numCPG)} floatLabelType='Auto' placeholder='Descrizione (Facoltativo)' cssClass='e-outline'></TextBoxComponent>
                </div>
                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                    <ButtonComponent id={`eliminaPartita${numCPG}`} onClick={this.eliminaPartita.bind(this, `containerComboPartita${numCPG}`, numCPG)} title='Elimina' cssClass='e-fill e-danger' type='button'>X</ButtonComponent>
                    <ButtonComponent style={{ marginLeft: '15px' }} id={`aggiungiPartita${numCPG}`} onClick={this.aggiungiPartita.bind(this, '', '')} title='Aggiungi' cssClass='e-fill e-info' type='button'>+</ButtonComponent>
                </div>
            </div></CallbackWrapper>);
    }
    eliminaPartita(idContainerDaElimin, indice, args) {
        delete this.formOptions.rules[`Partita${indice}`];
        delete this.formOptions.rules[`FormaGaranzia${indice}`];
        delete this.formOptions.rules[`DescrizionePartita${indice}`];

        if (this.isCompagniaGenerali()) {
            delete this.formOptions.rules[`PartitaRicevuta${indice}`];
            delete this.formOptions.rules[`SommaAssicurataPartita${indice}`];
        }

        let containerDaElimin = document.getElementById(idContainerDaElimin);
        let containerGenitore = document.getElementById('tutteComboPartite');
        containerGenitore.removeChild(containerDaElimin);

        this.strutturaStatiPartite.forEach(elm => {
            if (elm.id === idContainerDaElimin)
                elm.stato = 'd';
        });
        this.strutturaStatiPartite.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)

        this.cambiaPartita();
    }


    cambiaPartita(args) {
        let combo = this.numComboPartite;
        let partitaVal = [];
        for (let index = 0; index < combo; index++) {
            if (!CustomJS.isNullEmpty(document.getElementById(`Partita${index}`))) {
                if (!CustomJS.isNullEmpty(document.getElementById(`Partita${index}`).ej2_instances[0].text)) {
                    partitaVal.push(document.getElementById(`Partita${index}`).ej2_instances[0].text)
                }
            }
        }
        this.contaDuplicatiPartiteDescrizioni(partitaVal);
        this.partiteScelteNelleStime(args);
    }
    contaDuplicatiPartiteDescrizioni(a) {
        let counts = {}
        let idproprieta = [];
        for (let i = 0; i < a.length; i++) {
            if (counts[a[i]]) {
                counts[a[i]] += 1
            } else {
                counts[a[i]] = 1
            }
        }
        for (let prop in counts) {
            if (counts[prop] >= 2) {
                for (let index = 0; index < this.numComboPartite; index++) {
                    if (!CustomJS.isNullEmpty(document.getElementById(`Partita${index}`))) {
                        if (document.getElementById(`Partita${index}`).ej2_instances[0].text === prop) {
                            idproprieta.push(`DescrizionePartita${index}`);
                        }
                    }
                }
            }
        }
        for (let index = 0; index < this.numComboPartite; index++) {
            if (!CustomJS.isNullEmpty(document.getElementById(`DescrizionePartita${index}`))) {
                document.getElementById(`DescrizionePartita${index}`).ej2_instances[0].placeholder = "Descrizione (Facoltativo)";
                document.getElementById(`DescrizionePartita${index}`).style.backgroundColor = '#edf0fa';
                delete this.formOptions.rules[`DescrizionePartita${index}`];
            }
        }
        for (let index = 0; index < idproprieta.length; index++) {
            document.getElementById(idproprieta[index]).ej2_instances[0].placeholder = "Descrizione Obbligatoria!";
            document.getElementById(idproprieta[index]).style.backgroundColor = '#ffcccc';
            document.getElementById(idproprieta[index]).style.borderRadius = '10px';
            this.formOptions.rules[idproprieta[index]] = {
                required: [true, "Descrizione è un campo obbligatorio!"]
            }
        }
    }
    partiteScelteNelleStime() {
        let arrayPartite = []
        let obj = {}
        let descr = ''
        let guidGen = '';
        let guidTemp = '';
        for (let index = 0; index < this.numComboPartite; index++) {
            if (document.getElementById(`containerComboPartita${index}`)) {
                if (!CustomJS.isNullEmpty(document.getElementById(`Partita${index}`).ej2_instances[0].value)) {
                    this.strutturaStatiPartite.forEach(elm => {
                        if (elm.id === `containerComboPartita${index}`) {
                            guidGen = elm.guid_partita;
                            guidTemp = elm.guidTemp;
                            return;
                        }
                    });
                    let descrizionePartita = CustomJS.isNullEmpty(document.getElementById(`DescrizionePartita${index}`).ej2_instances[0].value) ? '' : (' (' + document.getElementById(`DescrizionePartita${index}`).ej2_instances[0].value + ')');
                    obj = {
                        TEXT: document.getElementById(`Partita${index}`).ej2_instances[0].text + descrizionePartita,
                        VALUE: guidTemp,
                        GUID: guidGen,
                        COD_ELE: document.getElementById(`Partita${index}`).ej2_instances[0].value
                    }
                    arrayPartite.push(obj)
                }
            }
        }
        for (let index = 0; index < this.numComboStime; index++) {
            if (document.getElementById(`PartitaTabellaStima${index}`)) {
                document.getElementById(`PartitaTabellaStima${index}`).ej2_instances[0].dataSource = arrayPartite;
                let esiste = false;

                document.getElementById(`PartitaTabellaStima${index}`).ej2_instances[0].dataSource.forEach(element => {
                    if (element.VALUE === document.getElementById(`PartitaTabellaStima${index}`).ej2_instances[0].value) {
                        esiste = true;
                    }
                });
                if (esiste === false) {
                    document.getElementById(`PartitaTabellaStima${index}`).ej2_instances[0].value = null;
                }
            }
        }
    }
    descrizionePartiteChange(num, args) {
        if (!CustomJS.isNullEmpty(document.getElementById(`DescrizionePartita${num}`).ej2_instances[0]) && document.getElementById(`DescrizionePartita${num}`).ej2_instances[0].placeholder === "Descrizione Obbligatoria!") {
            document.getElementById(`DescrizionePartita${num}`).style.backgroundColor = '#edf0fa';
        }
        this.partiteScelteNelleStime();
    }

    //------------------GESTIONE PARTITE FINE------------------


    //------------------GESTIONE GARANZIE INIZIO------------------
    aggiungiGaranzia(retDataGaranzieG, casoIniziale) {
        const DivContainer = document.getElementById(`tutteComboGaranzie`);
        const newDiv = document.createElement("div");
        let numG = this.numComboGaranzie;
        ++this.numComboGaranzie;
        newDiv.setAttribute("id", `containerComboGaranzia${numG}`);
        newDiv.setAttribute("class", `container-combo-periziaOnline-p1`);
        DivContainer.append(newDiv);

        const container = document.getElementById(`containerComboGaranzia${numG}`)
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
            if (CustomJS.isNullEmpty(retDataGaranzieG)) {
                let objStrutturaStatiGaranzie = {
                    id_incarico: this.props.IdIncarico,
                    importato: false,
                    stato: 'n',
                    statoIniziale: 'n',
                    guid_garanzia: CustomJS.createGuid4(),
                    guidTemp: CustomJS.createGuid4(),
                    key: null,
                    id: `containerComboGaranzia${numG}`,
                    casoIniziale: false,
                    num: numG
                };
                this.strutturaStatiGaranzie.push(objStrutturaStatiGaranzie)

                if (this.isCompagniaGenerali()) {
                    let numFirstChild = document.getElementById("tutteComboGaranzie").firstChild.id;
                    numFirstChild = numFirstChild.replace("containerComboGaranzia", "");
                    document.getElementById(`GaranziaRicevuta${numG}`).ej2_instances[0].value = document.getElementById(`GaranziaRicevuta${numFirstChild}`).ej2_instances[0].value
                }
            }
            else {
                if (casoIniziale === 'casoIniziale') {
                    document.getElementById(`GaranziaRicevuta${numG}`).ej2_instances[0].value = retDataGaranzieG.tipoGaranzia_codice;
                    document.getElementById(`GruppoGaranzia${numG}`).ej2_instances[0].value = retDataGaranzieG.gruppo_garanzia;
                    document.getElementById(`partitaAssicurata${numG}`).ej2_instances[0].value = retDataGaranzieG.partita_assicurata;
                    document.getElementById(`FormaAssicurazione${numG}`).ej2_instances[0].value = retDataGaranzieG.formaAssicurazione_codice;
                    document.getElementById(`FormulaAssicurazione${numG}`).ej2_instances[0].value = retDataGaranzieG.formulaAssicurazione_codice;

                    let objStrutturaStatiGaranzie = {
                        id_incarico: this.props.IdIncarico,
                        importato: retDataGaranzieG.importato,
                        stato: 'o',
                        statoIniziale: 'o',
                        guidTemp: CustomJS.createGuid4(),
                        guid_garanzia: retDataGaranzieG.id_garanzia,
                        key: null,
                        id: `containerComboGaranzia${numG}`,
                        casoIniziale: true,
                        num: numG
                    };
                    this.strutturaStatiGaranzie.push(objStrutturaStatiGaranzie)
                }
                else {
                    document.getElementById(`Garanzia${numG}`).ej2_instances[0].value = retDataGaranzieG.cod_ele_garanzia;
                    document.getElementById(`DeterminazioneDanno${numG}`).ej2_instances[0].value = retDataGaranzieG.id_determinazioneDanno;

                    document.getElementById(`LimiteIndennizzoEuro${numG}`).ej2_instances[0].value = retDataGaranzieG.limiteIndennizzoEuro;
                    document.getElementById(`LimiteIndennizzoPer${numG}`).ej2_instances[0].value = retDataGaranzieG.limitePer;
                    document.getElementById(`FranchigiaEuro${numG}`).ej2_instances[0].value = retDataGaranzieG.franchigiaEuro;
                    document.getElementById(`FranchigiaGiorni${numG}`).ej2_instances[0].value = retDataGaranzieG.franchigiaGiorni;
                    document.getElementById(`ScopertoPerc${numG}`).ej2_instances[0].value = retDataGaranzieG.scopertoPerc;
                    document.getElementById(`ScopertoMinimo${numG}`).ej2_instances[0].value = retDataGaranzieG.scopertoMin;
                    document.getElementById(`ScopertoMassimo${numG}`).ej2_instances[0].value = retDataGaranzieG.scopertoMax;

                    //BLOCCO
                    document.getElementById(`Garanzia${numG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`DeterminazioneDanno${numG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`LimiteIndennizzoEuro${numG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`LimiteIndennizzoPer${numG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`LimiteIndennizzoGiorni${numG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`FranchigiaEuro${numG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`FranchigiaGiorni${numG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`ScopertoPerc${numG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`ScopertoMinimo${numG}`).ej2_instances[0].readonly = true;
                    document.getElementById(`ScopertoMassimo${numG}`).ej2_instances[0].readonly = true;

                    document.getElementById(`aggiungiGaranzia${numG}`).ej2_instances[0].disabled = true
                    document.getElementById(`eliminaGaranzia${numG}`).ej2_instances[0].disabled = true

                    //GENERALI
                    if (this.isCompagniaGenerali()) {
                        document.getElementById(`GaranziaRicevuta${numG}`).ej2_instances[0].value = retDataGaranzieG.tipoGaranzia_codice;
                        document.getElementById(`GruppoGaranzia${numG}`).ej2_instances[0].value = retDataGaranzieG.gruppo_garanzia;
                        document.getElementById(`partitaAssicurata${numG}`).ej2_instances[0].value = retDataGaranzieG.partita_assicurata;
                        document.getElementById(`FormulaAssicurazione${numG}`).ej2_instances[0].value = retDataGaranzieG.formulaAssicurazione_codice;
                        document.getElementById(`FormaAssicurazione${numG}`).ej2_instances[0].value = retDataGaranzieG.formaAssicurazione_codice;
                        document.getElementById(`SommaAssicurata${numG}`).ej2_instances[0].value = retDataGaranzieG.somma_assicurata;
                        //BLOCCO GENERALI
                        document.getElementById(`GruppoGaranzia${numG}`).ej2_instances[0].readonly = true;
                        document.getElementById(`partitaAssicurata${numG}`).ej2_instances[0].readonly = true;
                        document.getElementById(`FormulaAssicurazione${numG}`).ej2_instances[0].readonly = true;
                        document.getElementById(`FormaAssicurazione${numG}`).ej2_instances[0].readonly = true;
                        document.getElementById(`SommaAssicurata${numG}`).ej2_instances[0].readonly = true;
                    }
                    let objStrutturaStatiGaranzie = {
                        id_incarico: this.props.IdIncarico,
                        importato: retDataGaranzieG.importato,
                        stato: 'o',
                        statoIniziale: 'o',
                        guidTemp: retDataGaranzieG.guid_garanzia_temp,
                        guid_garanzia: retDataGaranzieG.id_garanzia,
                        key: retDataGaranzieG.id_garanziaPeriziaOnline,
                        id: `containerComboGaranzia${numG}`,
                        casoIniziale: false,
                        num: numG
                    };
                    this.strutturaStatiGaranzie.push(objStrutturaStatiGaranzie)
                    setTimeout(() => {
                        document.getElementById(`Garanzia${numG}`).ej2_instances[0].itemData.GUID = retDataGaranzieG.id_garanzia;
                        setTimeout(() => {
                            this.onChangeGaranzia(numG, 'fromCaricaDati', '')
                            document.getElementById(`FranchigiaCondivisa${numG}`).ej2_instances[0].value = retDataGaranzieG.franchigiaCondivisa;
                            //BLOCCO
                            document.getElementById(`FranchigiaCondivisa${numG}`).ej2_instances[0].readonly = true;
                        }, 200)
                    }, 100)
                }

                if (this.isCompagniaGenerali()) {
                    document.getElementById(`GaranziaRicevuta${numG}`).ej2_instances[0].readonly = true;
                    //SE E' IMPORTATO NON E' ELIMINABILE!
                    if (retDataGaranzieG.importato === 1 || retDataGaranzieG.importato === true) {
                        document.getElementById(`garanziaImportata${numG}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                        if (document.getElementById(`eliminaGaranzia${numG}`)) {
                            document.getElementById(`eliminaGaranzia${numG}`).ej2_instances[0].disabled = true;
                        }
                    }
                }
            }
        }}><>
                <div className='row'>
                    <p className='importatoGen' title='Garanzia importata' id={`garanziaImportata${numG}`}></p>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <DropDownListComponent id={`GaranziaRicevuta${numG}`} fields={this.ddlFields} dataSource={this.dsGaranzieRicevuteGen}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Garanzia Ricevuta" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className={this.isCompagniaGenerali() ? "col-xs-2 col-sm-2 col-lg-2 col-md-2" : "col-xs-4 col-sm-4 col-lg-4 col-md-4"} >
                        <p className='mexErrGaranzia' id={`messaggioErroreDoppiaGaranzia${numG}`}><br></br></p>
                        <DropDownListComponent onChange={this.onChangeGaranzia.bind(this, numG, '')} id={`Garanzia${numG}`} fields={this.ddlFields} dataSource={this.dsGaranzie}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" >
                        <DropDownListComponent id={`DeterminazioneDanno${numG}`} fields={this.ddlFields} dataSource={this.dsDeterminazioneDanno}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Determinazione Del Danno" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id={`boxFranchigiaCondivisa${numG}`}>
                        <p className='mexErrGaranzia' id={`messaggioErroreStessaFranchigia${numG}`}><br></br></p>
                        <DropDownListComponent onChange={this.trasferisciFranchigiaCondivisa.bind(this, numG)} id={`FranchigiaCondivisa${numG}`} fields={this.ddlFields} dataSource={this.dsFranchigiaCondivisa}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Franchigia/Scopertura Condivisa" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }} >
                        <DropDownListComponent id={`GruppoGaranzia${numG}`} fields={this.ddlFields} dataSource={this.dsGruppiGaranzia}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Gruppo Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <DropDownListComponent id={`partitaAssicurata${numG}`} fields={this.ddlFields} dataSource={this.dsPartiteRicGen}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Partita Assicurata" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <DropDownListComponent id={`FormulaAssicurazione${numG}`} fields={this.ddlFields} dataSource={this.dsFormulaAssicurazioni}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Formula Assicurazione" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <DropDownListComponent id={`FormaAssicurazione${numG}`} fields={this.ddlFields} dataSource={this.dsFormaGaranzia}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Forma Assicurazione" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <NumericTextBoxComponent showSpinButton={false} id={`SommaAssicurata${numG}`} onChange={() => { document.getElementById(`LimiteIndennizzoEuro${numG}`).ej2_instances[0].value = document.getElementById(`SommaAssicurata${numG}`).ej2_instances[0].value }} floatLabelType='Auto' format="c2" decimals='2' placeholder='Somma Assicurata' cssClass='e-outline'></NumericTextBoxComponent >
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" >
                        <div tabIndex="0" className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                            <div className="e-card-content" id={`cardContentLimiti${numG}`} style={{ backgroundColor: '#e5ebf3' }}>
                                <div className='row'>
                                    <h5 className="card-title" style={{ color: 'black', fontWeight: '400', paddingBottom: '15px', display: 'flex', justifyContent: 'center' }}>Limite Indennizzo</h5>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <NumericTextBoxComponent showSpinButton={false} id={`LimiteIndennizzoEuro${numG}`} floatLabelType='Auto' format="c2" decimals='2' placeholder='Limite Indennizzo €' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <div id={`LimiteIndennizzoGiorniDiv${numG}`} style={{ display: 'none' }} className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                        <TextBoxComponent id={`LimiteIndennizzoGiorni${numG}`} floatLabelType='Auto' placeholder='GG' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
                                        <DropDownListComponent id={`LimiteIndennizzoPer${numG}`} value={'sinistro'} dataSource={this.dsLimiteIndennizzo} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Tipo Limite" floatLabelType='always' cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <p style={{ color: 'red', fontWeight: '500', fontSize: '12.5px' }} id={`mexErrMettIndennizzo${numG}`}><br></br></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                            <div className="e-card-content" id={`cardContentFranchigie${numG}`} style={{ backgroundColor: '#e5ebf3' }}>
                                <div className='row'>
                                    <h5 className="card-title" style={{ color: 'black', fontWeight: '400', paddingBottom: '15px', display: 'flex', justifyContent: 'center' }}><h5 id={`sceltaFranchigia${numG}`}>→</h5> Franchigia</h5>
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <NumericTextBoxComponent validateHidden={false} showSpinButton={false} id={`FranchigiaEuro${numG}`} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'franchigia')} onBlur={this.modificaFranchigiaETrasporta.bind(this, numG)} floatLabelType='Auto' format="c2" decimals='2' placeholder='Franchigia' value={0} cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <TextBoxComponent id={`FranchigiaGiorni${numG}`} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'franchigia')} onBlur={this.modificaFranchigiaETrasporta.bind(this, numG)} decimals='0' type='number' floatLabelType='Auto' placeholder='GG' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                            <div className="e-card-content" id={`cardContentScoperti${numG}`} style={{ backgroundColor: '#e5ebf3' }} >
                                <div className='row'>
                                    <h5 className="card-title" style={{ color: 'black', fontWeight: '400', paddingBottom: '15px', display: 'flex', justifyContent: 'center' }}><h5 id={`sceltaScoperto${numG}`}></h5> Scoperto</h5>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <NumericTextBoxComponent showSpinButton={false} id={`ScopertoPerc${numG}`} onBlur={this.modificaScopertoETrasporta.bind(this, numG)} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'scoperto')} floatLabelType='Auto' decimals='2' min={0} format="#.## '%'" placeholder='Scoperto %' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <NumericTextBoxComponent showSpinButton={false} id={`ScopertoMinimo${numG}`} onBlur={this.modificaScopertoETrasporta.bind(this, numG)} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'scoperto')} floatLabelType='Auto' format="c2" decimals='2' placeholder='Scoperto Min' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <NumericTextBoxComponent showSpinButton={false} id={`ScopertoMassimo${numG}`} onBlur={this.modificaScopertoETrasporta.bind(this, numG)} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'scoperto')} floatLabelType='Auto' format="c2" decimals='2' placeholder='Scoperto Max' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <h6 id={`scopertoMinMaxErr${numG}`} style={{ fontSize: '12px', color: 'red', display: 'none', justifyContent: 'center' }}>Il minimo deve essere minore del massimo!</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1" width='5%'>
                        <div className="eliminaGaranzAgg">
                            <ButtonComponent id={`eliminaGaranzia${numG}`} onClick={this.eliminaGaranzia.bind(this, `containerComboGaranzia${numG}`, numG)} title='Elimina' cssClass='e-fill e-danger' type='button'>X</ButtonComponent>
                            <ButtonComponent style={{ marginLeft: '15px' }} id={`aggiungiGaranzia${numG}`} onClick={this.aggiungiGaranzia.bind(this, '', '')} title='Aggiungi' cssClass='e-fill e-info' type='button'>+</ButtonComponent>
                        </div>
                    </div>
                </div>
            </></CallbackWrapper>);


    }
    onChangeGaranzia(num, fromCaricaDati, args) {
        if (!CustomJS.isNullEmpty(args)) {
            //TG005, 74, 4 è la garanzia Evento Atmosferico
            if (document.getElementById(`Garanzia${num}`).ej2_instances[0].value === "TG005" ||
                document.getElementById(`Garanzia${num}`).ej2_instances[0].value === '4')
                document.getElementById(`LimiteIndennizzoGiorniDiv${num}`).style.removeProperty('display')
            else
                document.getElementById(`LimiteIndennizzoGiorniDiv${num}`).style.display = 'none';
            for (let index = 0; index < this.numComboGaranzie; index++) {
                if (document.getElementById(`Garanzia${index}`)) {
                    if (num !== index) {
                        if (document.getElementById(`Garanzia${num}`).ej2_instances[0].value === document.getElementById(`Garanzia${index}`).ej2_instances[0].value) {
                            document.getElementById(`messaggioErroreDoppiaGaranzia${num}`).innerHTML = 'Non puoi inserire una garanzia due volte!';
                            document.getElementById(`Garanzia${num}`).ej2_instances[0].value = null;
                            setTimeout(() => {
                                if (document.getElementById(`messaggioErroreDoppiaGaranzia${num}`))
                                    document.getElementById(`messaggioErroreDoppiaGaranzia${num}`).innerHTML = '</br>';
                            }, 3500)
                        }
                    }
                }
            }
        }
        let arrayV = []
        let obj = {}
        for (let index = 0; index < this.numComboGaranzie; index++) {
            if (document.getElementById(`Garanzia${index}`)) {
                if (index === 0) {
                    obj = {
                        TEXT: document.getElementById(`Garanzia${index}`).ej2_instances[0].text,
                        VALUE: document.getElementById(`Garanzia${index}`).ej2_instances[0].value
                    }
                    if (!CustomJS.isNullEmpty(obj.TEXT))
                        arrayV.push(obj)
                }
                else {
                    if (document.getElementById(`Garanzia${index}`).ej2_instances[0].text !== document.getElementById(`FranchigiaCondivisa${index}`).ej2_instances[0].text && index !== num) {
                        obj = {
                            TEXT: document.getElementById(`Garanzia${index}`).ej2_instances[0].text,
                            VALUE: document.getElementById(`Garanzia${index}`).ej2_instances[0].value
                        }
                        if (!CustomJS.isNullEmpty(obj.TEXT))
                            arrayV.push(obj)
                    }
                }
            }
            if (!CustomJS.isNullEmpty(document.getElementById(`FranchigiaCondivisa${index}`))) {
                document.getElementById(`FranchigiaCondivisa${index}`).ej2_instances[0].dataSource = arrayV
            }
        }
        if (fromCaricaDati !== 'fromCaricaDati') {
            this.garanzieScelteNelleStime();
            this.sinistroELeSueModalitaEMezziChiusuraSiNO();
        }
        if (this.isCompagniaGenerali()) {
            let codEle = document.getElementById(`Garanzia${num}`).ej2_instances[0].value;
            let codGruppo = ''
            this.dsGaranzie.forEach(elm => {
                if (elm.VALUE === codEle) {
                    codGruppo = elm.SERVIZIO_STR2;
                }
            })
            document.getElementById(`GruppoGaranzia${num}`).ej2_instances[0].value = codGruppo;
        }
    }

    garanzieScelteNelleStime(args) {
        let arrayGaranzie = []
        let obj = {}
        let guidGen = '';
        let guidTemp = '';
        for (let index = 0; index < this.numComboGaranzie; index++) {
            if (document.getElementById(`containerComboGaranzia${index}`)) {
                if (!CustomJS.isNullEmpty(document.getElementById(`Garanzia${index}`).ej2_instances[0].value)) {
                    this.strutturaStatiGaranzie.forEach(elm => {
                        if (elm.id === `containerComboGaranzia${index}`) {
                            guidGen = elm.guid_garanzia;
                            guidTemp = elm.guidTemp;
                            return;
                        }
                    })
                    obj = {
                        TEXT: document.getElementById(`Garanzia${index}`).ej2_instances[0].text,
                        VALUE: guidTemp,
                        GUID: guidGen,
                        COD_ELE: document.getElementById(`Garanzia${index}`).ej2_instances[0].value
                    }
                    arrayGaranzie.push(obj)
                }
            }
        }
        for (let index = 0; index < this.numComboStime; index++) {
            if (document.getElementById(`containerComboStima${index}`)) {
                document.getElementById(`GaranziaTabellaStima${index}`).ej2_instances[0].dataSource = arrayGaranzie;

                let esiste = false;
                document.getElementById(`GaranziaTabellaStima${index}`).ej2_instances[0].dataSource.forEach(element => {
                    if (element.VALUE === document.getElementById(`GaranziaTabellaStima${index}`).ej2_instances[0].value) {
                        esiste = true;
                    }
                });
                if (esiste === false) {
                    document.getElementById(`GaranziaTabellaStima${index}`).ej2_instances[0].value = null;
                }
            }
        }
    }



    eliminaGaranzia(idContainerDaElimin, indice, args) {
        delete this.formOptions.rules[`Garanzia${indice}`];
        delete this.formOptions.rules[`DeterminazioneDanno${indice}`];

        if (this.isCompagniaGenerali()) {
            delete this.formOptions.rules[`GaranziaRicevuta${indice}`];
            delete this.formOptions.rules[`GruppoGaranzia${indice}`];
            delete this.formOptions.rules[`partitaAssicurata${indice}`];
            delete this.formOptions.rules[`FormulaAssicurazione${indice}`];
            delete this.formOptions.rules[`FormaAssicurazione${indice}`];
        }

        let containerDaElimin = document.getElementById(idContainerDaElimin);
        let containerGenitore = document.getElementById('tutteComboGaranzie');
        containerGenitore.removeChild(containerDaElimin);
        this.garanzieScelteNelleStime()

        this.strutturaStatiGaranzie.forEach(elm => {
            if (elm.id === idContainerDaElimin)
                elm.stato = 'd';
        });
        this.strutturaStatiGaranzie.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)
        this.garanzieScelteNelleStime();
    }

    //------------------GESTIONE GARANZIE FINE------------------


    //------------------GESTIONE STIME INIZIO------------------
    switchDanneggiato(numTS) {
        if (document.getElementById(`btnSwitchDanneggiato${numTS}`).ej2_instances[0].children === '+') {
            document.getElementById(`btnSwitchDanneggiato${numTS}`).ej2_instances[0].children = '←';
            document.getElementById(`btnSwitchDanneggiato${numTS}`).innerText = '←';
            document.getElementById(`DanneggiatoTabellaStima${numTS}`).ej2_instances[0].value = null;

            document.getElementById(`contentDanneggiatiEsistenti${numTS}`).style.display = 'none'
            document.getElementById(`contentAggDanneggiato${numTS}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnSwitchDanneggiato${numTS}`).ej2_instances[0].children === '←') {
            document.getElementById(`btnSwitchDanneggiato${numTS}`).ej2_instances[0].children = '+';
            document.getElementById(`btnSwitchDanneggiato${numTS}`).innerText = '+';
            document.getElementById(`DanneggiatoAggiunto${numTS}`).ej2_instances[0].value = '';

            document.getElementById(`contentAggDanneggiato${numTS}`).style.display = 'none'
            document.getElementById(`contentDanneggiatiEsistenti${numTS}`).style.removeProperty('display');
        }
    }
    aggiungiStima(retDataStimeS, retDataDanneggiatiD, fromCaricaDanneggiati) {
        console.log(retDataStimeS)
        console.log(retDataDanneggiatiD)
        console.log(fromCaricaDanneggiati)

        let numTS = this.numComboStime;
        ++this.numComboStime;

        const DivContainer = document.getElementById(`tutteTabelleStima`);
        const newDiv = document.createElement("div");
        newDiv.setAttribute("id", `containerComboStima${numTS}`);
        newDiv.setAttribute("class", "container-combo-periziaOnline-p1")
        DivContainer.append(newDiv);

        const container = document.getElementById(`containerComboStima${numTS}`)
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
            if (numTS === 0) {
                document.getElementById('eliminaStima0').style.display = 'none'
            }
            if (fromCaricaDanneggiati !== true)
                this.cambiaPartita();

            if (CustomJS.isNullEmpty(retDataStimeS)) {
                if (fromCaricaDanneggiati !== true)
                    this.garanzieScelteNelleStime();
            }
            if (CustomJS.isNullEmpty(retDataStimeS) && CustomJS.isNullEmpty(retDataDanneggiatiD)) {
                let objStrutturaStatiStime = {
                    id_incarico: this.props.IdIncarico,
                    stato: 'n',
                    statoIniziale: 'n',
                    keyXpert: null,
                    keyGenerali: null,
                    id: `containerComboStima${numTS}`,
                    num: numTS,
                    danneggiatoEliminato: 0
                };
                this.strutturaStatiStime.push(objStrutturaStatiStime)
            }
            else if (!CustomJS.isNullEmpty(retDataDanneggiatiD)) {
                document.getElementById(`DanneggiatoTabellaStima${numTS}`).ej2_instances[0].dataSource = this.dsDanneggiati;
                document.getElementById(`DanneggiatoTabellaStima${numTS}`).ej2_instances[0].value = retDataDanneggiatiD.id_danneggiato_xpert

                //CASO INIZIALE per i danneggiati
                let objStrutturaStatiStime = {
                    id_incarico: this.props.IdIncarico,
                    stato: 'n',
                    statoIniziale: 'n',
                    keyXpert: null,
                    keyGenerali: null,
                    id: `containerComboStima${numTS}`,
                    num: numTS,
                    danneggiatoEliminato: 0
                };
                this.strutturaStatiStime.push(objStrutturaStatiStime)

                if (retDataDanneggiatiD.importato === 1 || retDataDanneggiatiD.importato === true) {
                    document.getElementById(`danneggiatoImportato${numTS}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                    if (document.getElementById(`eliminaStima${numTS}`)) {
                        document.getElementById(`eliminaStima${numTS}`).ej2_instances[0].disabled = true;
                    }

                    document.getElementById(`DanneggiatoTabellaStima${numTS}`).ej2_instances[0].readonly = true;
                    document.getElementById(`btnSwitchDanneggiato${numTS}`).ej2_instances[0].disabled = true;
                }
            }
            else {
                document.getElementById(`DanneggiatoTabellaStima${numTS}`).ej2_instances[0].dataSource = this.dsDanneggiati;
                let objStrutturaStatiStime = {
                    id_incarico: this.props.IdIncarico,
                    stato: 'o',
                    statoIniziale: 'o',
                    keyXpert: retDataStimeS.id_stima,
                    keyGenerali: retDataStimeS.id_danno,
                    id: `containerComboStima${numTS}`,
                    num: numTS,
                    danneggiatoEliminato: 0
                };
                this.strutturaStatiStime.push(objStrutturaStatiStime)

                let textP = '';
                this.dsPartite.forEach(elm => {
                    if (elm.VALUE === retDataStimeS.cod_ele_partita) { textP = elm.TEXT }
                });
                textP = CustomJS.isNullEmpty(retDataStimeS.descrizione) ? textP : textP + ' (' + retDataStimeS.descrizione + ')'
                let objP = {
                    TEXT: textP,
                    VALUE: retDataStimeS.guid_partita_temp,
                    GUID: retDataStimeS.id_partita_colpita,
                    COD_ELE: retDataStimeS.cod_ele_partita
                }
                this.dataSourcePartite.push(objP)

                let textG = '';
                this.dsGaranzie.forEach(elm => {
                    if (elm.VALUE === retDataStimeS.cod_ele_garanzia) { textG = elm.TEXT }
                });
                let objG = {
                    TEXT: textG,
                    VALUE: retDataStimeS.guid_garanzia_temp,
                    GUID: retDataStimeS.id_garanzia_colpita,
                    COD_ELE: retDataStimeS.cod_ele_garanzia
                }
                this.dataSourceGaranzie.push(objG)

                setTimeout(() => {
                    document.getElementById(`PartitaTabellaStima${numTS}`).ej2_instances[0].dataSource = this.dataSourcePartite;
                    document.getElementById(`GaranziaTabellaStima${numTS}`).ej2_instances[0].dataSource = this.dataSourceGaranzie;

                    setTimeout(() => {
                        document.getElementById(`DanneggiatoTabellaStima${numTS}`).ej2_instances[0].value = retDataStimeS.id_danneggiato;
                        document.getElementById(`PartitaTabellaStima${numTS}`).ej2_instances[0].value = retDataStimeS.guid_partita_temp;
                        document.getElementById(`GaranziaTabellaStima${numTS}`).ej2_instances[0].value = retDataStimeS.guid_garanzia_temp;

                        //BLOCCO
                        document.getElementById(`DanneggiatoTabellaStima${numTS}`).ej2_instances[0].readonly = true;
                        document.getElementById(`PartitaTabellaStima${numTS}`).ej2_instances[0].readonly = true;
                        document.getElementById(`GaranziaTabellaStima${numTS}`).ej2_instances[0].readonly = true;
                        document.getElementById(`btnSwitchDanneggiato${numTS}`).ej2_instances[0].disabled = true;


                        document.getElementById(`aggiungiStima${numTS}`).ej2_instances[0].disabled = true
                        document.getElementById(`eliminaStima${numTS}`).ej2_instances[0].disabled = true

                        if (retDataStimeS.importato === 1 || retDataStimeS.importato === true) {
                            document.getElementById(`danneggiatoImportato${numTS}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                        }
                        setTimeout(() => {
                            document.getElementById(`DanneggiatoTabellaStima${numTS}`).ej2_instances[0].GUID = retDataStimeS.id_danneggiato_colpito;
                            document.getElementById(`DanneggiatoTabellaStima${numTS}`).ej2_instances[0].IMPORTATO = true;
                        }, 250)
                    }, 100)
                }, 300)
            }
        }}><div className='row'>
                <p className='importatoGen' title='Danneggiato importato' id={`danneggiatoImportato${numTS}`}> </p>
                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                    <div className='row'>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <ButtonComponent id={`btnSwitchDanneggiato${numTS}`} alt='Switch Danneggiato' cssClass='e-fill e-info' type='button' onClick={this.switchDanneggiato.bind(this, numTS)}>+</ButtonComponent>
                        </div>
                        <div id={`contentDanneggiatiEsistenti${numTS}`} className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
                            <DropDownListComponent onChange={this.stessaComboTabStima.bind(this, numTS)} id={`DanneggiatoTabellaStima${numTS}`} fields={this.ddlFields} dataSource={this.dsDanneggiati}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="Danneggiato" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div id={`contentAggDanneggiato${numTS}`} className="col-xs-10 col-sm-10 col-lg-10 col-md-10" style={{ display: 'none' }}>
                            <TextBoxComponent id={`DanneggiatoAggiunto${numTS}`} floatLabelType='Auto' placeholder='Aggiungi Danneggiato' cssClass='e-outline'></TextBoxComponent>
                        </div>
                        <h6 id={`mexErroreDanneggiato${numTS}`} style={{ color: '#f44336', display: 'none', fontFamily: 'Roboto', fontSize: '12px' }}>Danneggiato (Stime) è un campo obbligatorio!</h6>
                    </div>
                </div>
                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                    <DropDownListComponent onChange={this.stessaComboTabStima.bind(this, numTS)} id={`PartitaTabellaStima${numTS}`} fields={this.ddlFields}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                    </DropDownListComponent>
                </div>
                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                    <DropDownListComponent onChange={this.stessaComboTabStima.bind(this, numTS)} id={`GaranziaTabellaStima${numTS}`} fields={this.ddlFields}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        placeholder="Garanzia" floatLabelType='always' cssClass='e-outline'>
                    </DropDownListComponent>
                    <p id={`mexErroreStessaStima${numTS}`} style={{ color: 'red', fontWeight: '400' }}><br /></p>
                </div>

                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                    <ButtonComponent id={`eliminaStima${numTS}`} onClick={this.eliminaStima.bind(this, `containerComboStima${numTS}`)} title='Elimina' cssClass='e-fill e-danger' type='button'>X</ButtonComponent>
                    <ButtonComponent style={{ marginLeft: '15px' }} id={`aggiungiStima${numTS}`} onClick={this.aggiungiStima.bind(this, '', false)} title='Aggiungi' cssClass='e-fill e-info' type='button'>+</ButtonComponent>

                </div>
            </div></CallbackWrapper>);
    }
    eliminaStima(idContainerDaElimin, args) {
        for (let index = 0; index < this.numComboStime; index++) {
            if (document.getElementById(`containerComboStima${index}`)) {
                // if (document.getElementById(`btnSwitchDanneggiato${index}`).ej2_instances[0].children === '+') {
                //     delete this.formOptions.rules[`DanneggiatoTabellaStima${index}`];
                // }
                // else if (document.getElementById(`btnSwitchDanneggiato${index}`).ej2_instances[0].children === '←') {
                //     delete this.formOptions.rules[`DanneggiatoAggiunto${index}`];
                // }
                delete this.formOptions.rules[`PartitaTabellaStima${index}`];
                delete this.formOptions.rules[`GaranziaTabellaStima${index}`];
            }
        }
        this.strutturaStatiStime.forEach(elm => {
            if (elm.id === idContainerDaElimin) {
                elm.stato = 'd';
                elm.danneggiatoEliminato = CustomJS.isNullEmpty(document.getElementById(`DanneggiatoTabellaStima${elm.num}`).ej2_instances[0].value) ? 0 : document.getElementById(`DanneggiatoTabellaStima${elm.num}`).ej2_instances[0].value
            }

        });
        this.strutturaStatiStime.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)
        let containerDaElimin = document.getElementById(idContainerDaElimin);
        let containerGenitore = document.getElementById('tutteTabelleStima');
        containerGenitore.removeChild(containerDaElimin);
    }

    stessaComboTabStima(num, args) {
        for (let index = 0; index < this.numComboStime; index++) {
            if (document.getElementById(`DanneggiatoTabellaStima${index}`)) {
                if (index !== num) {
                    if (document.getElementById(`btnSwitchDanneggiato${num}`).ej2_instances[0].children === '+') {
                        if (document.getElementById(`DanneggiatoTabellaStima${num}`).ej2_instances[0].value === document.getElementById(`DanneggiatoTabellaStima${index}`).ej2_instances[0].value &&
                            document.getElementById(`PartitaTabellaStima${num}`).ej2_instances[0].value === document.getElementById(`PartitaTabellaStima${index}`).ej2_instances[0].value &&
                            document.getElementById(`GaranziaTabellaStima${num}`).ej2_instances[0].value === document.getElementById(`GaranziaTabellaStima${index}`).ej2_instances[0].value) {
                            document.getElementById(`GaranziaTabellaStima${num}`).ej2_instances[0].value = null;
                            document.getElementById(`mexErroreStessaStima${num}`).innerHTML = 'Non puoi inserire due volte la stessa stima!'
                            setTimeout(() => {
                                document.getElementById(`mexErroreStessaStima${num}`).innerHTML = '</br>';
                            }, 3500)
                        }
                    }
                }
            }
        }
    }
    ritiraPeriziaSenzaCalcoli() {
        if (this.periziaSenzaCalcoli === true) {
            //ritorno attivi tutti i buttons
            document.getElementById('btnPeriziaZeroCalcoli').ej2_instances[0].disabled = false
            document.getElementById('btnAggiungiPartita').ej2_instances[0].disabled = false
            document.getElementById('btnAggiungiGaranzia').ej2_instances[0].disabled = false
            document.getElementById('btnAggiungiStima').ej2_instances[0].disabled = false

            //apro gli accordion
            this.acrdnInstance0.expandItem(true, 0);
            this.acrdnInstance0.expandItem(true, 1);
            this.acrdnInstance0.expandItem(true, 2);

            //aggiungo una riga
            this.aggiungiPartita('', '')
            this.aggiungiGaranzia('', '')
            this.aggiungiStima('', '', false);

            this.periziaSenzaCalcoli = false;
            document.getElementById('btnPeriziaZeroCalcoliUndo').ej2_instances[0].disabled = true;
            document.getElementById('btnPeriziaZeroCalcoli').ej2_instances[0].disabled = false;
        }
    }
    abilitaPeriziaSenzaCalcoli(isFromCarica, args) {
        this.periziaSenzaCalcoli = true;
        let messaggioErrore = 'Attenzione! ';
        if (isFromCarica === false) {
            //---------INIZIO CONTROLLO SE CE UN DANNEGGIATO RICHIESTO DA GENERALI!!----------
            if (this.isCompagniaGenerali()) {
                for (let p = 0; p < this.numComboPartite; p++) {
                    if (document.getElementById(`containerComboPartita${p}`)) {
                        if (document.getElementById(`partitaImportata${p}`).innerHTML === '<i class="fas fa-arrow-circle-up"></i>') {
                            let nomePartita = document.getElementById(`PartitaRicevuta${p}`).ej2_instances[0].text;
                            messaggioErrore += "\n" + "La partita " + nomePartita + " è richiesta dalla compagnia Generali!"
                            this.periziaSenzaCalcoli = false;
                        }
                    }
                }
                for (let i = 0; i < this.numComboGaranzie; i++) {
                    if (document.getElementById(`containerComboGaranzia${i}`)) {
                        if (document.getElementById(`garanziaImportata${i}`).innerHTML === '<i class="fas fa-arrow-circle-up"></i>') {
                            let nomeGaranzia = document.getElementById(`GaranziaRicevuta${i}`).ej2_instances[0].text;
                            messaggioErrore += "\n" + "La garanzia " + nomeGaranzia + " è richiesta dalla compagnia Generali!"
                            this.periziaSenzaCalcoli = false;
                        }
                    }
                }
                for (let i = 0; i < this.numComboStime; i++) {
                    if (document.getElementById(`containerComboStima${i}`)) {
                        if (document.getElementById(`danneggiatoImportato${i}`).innerHTML === '<i class="fas fa-arrow-circle-up"></i>') {
                            let nomeDanneggiato = document.getElementById(`DanneggiatoTabellaStima${i}`).ej2_instances[0].text;
                            messaggioErrore += "\n" + "Il danneggiato " + nomeDanneggiato + " è richiesto dalla compagnia Generali!"
                            this.periziaSenzaCalcoli = false;
                        }
                    }
                }
            }
        }
        //---------FUNZIONE PERIZIA SENZA CALCOLI OK----------
        if (this.periziaSenzaCalcoli === true) {
            //disabilito tutti i buttons
            document.getElementById('btnPeriziaZeroCalcoli').ej2_instances[0].disabled = true
            document.getElementById('btnAggiungiPartita').ej2_instances[0].disabled = true
            document.getElementById('btnAggiungiGaranzia').ej2_instances[0].disabled = true
            document.getElementById('btnAggiungiStima').ej2_instances[0].disabled = true


            for (let p = 0; p < this.numComboPartite; p++) {
                if (document.getElementById(`containerComboPartita${p}`)) {
                    this.eliminaPartita(`containerComboPartita${p}`, p)
                }
            }
            for (let i = 0; i < this.numComboGaranzie; i++) {
                if (document.getElementById(`containerComboGaranzia${i}`)) {
                    this.eliminaGaranzia(`containerComboGaranzia${i}`, i)

                }
            }
            for (let i = 0; i < this.numComboStime; i++) {
                if (document.getElementById(`containerComboStima${i}`)) {
                    this.eliminaStima(`containerComboStima${i}`, '');
                }
            }
            //chiudo gli accordion
            this.acrdnInstance0.expandItem(false, 0);
            this.acrdnInstance0.expandItem(false, 1);
            this.acrdnInstance0.expandItem(false, 2);

            document.getElementById('btnPeriziaZeroCalcoliUndo').ej2_instances[0].disabled = false;
            document.getElementById('btnPeriziaZeroCalcoli').ej2_instances[0].disabled = true;
        }
        else {
            toast.error(messaggioErrore, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }
    }

    //------------------GESTIONE STIME FINE------------------

    //------------------GESTIONE FRANCHIGE SCOPERTI INIZIO------------------
    trasferisciFranchigiaCondivisa(num, args) {
        if (document.getElementById(`FranchigiaEuro${num}`)) {
            if (CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${num}`).ej2_instances[0].value)) {
                document.getElementById(`FranchigiaEuro${num}`).ej2_instances[0].value = 0;
                document.getElementById(`FranchigiaGiorni${num}`).ej2_instances[0].value = 0;
                document.getElementById(`ScopertoPerc${num}`).ej2_instances[0].value = 0;
                document.getElementById(`ScopertoMinimo${num}`).ej2_instances[0].value = 0;
                document.getElementById(`ScopertoMassimo${num}`).ej2_instances[0].value = 0;
                document.getElementById(`FranchigiaEuro${num}`).ej2_instances[0].readonly = false;
                document.getElementById(`FranchigiaGiorni${num}`).ej2_instances[0].readonly = false;
                document.getElementById(`ScopertoPerc${num}`).ej2_instances[0].readonly = false;
                document.getElementById(`ScopertoMinimo${num}`).ej2_instances[0].readonly = false;
                document.getElementById(`ScopertoMassimo${num}`).ej2_instances[0].readonly = false;
            }
        }
        if (!CustomJS.isNullEmpty(args.value)) {
            for (let index = 0; index < this.numComboGaranzie; index++) {
                if (document.getElementById(`Garanzia${index}`)) {
                    if (document.getElementById(`FranchigiaCondivisa${num}`).value === document.getElementById(`Garanzia${num}`).value) {
                        document.getElementById(`FranchigiaCondivisa${num}`).value = null;
                        document.getElementById(`messaggioErroreStessaFranchigia${num}`).innerHTML = 'Non puoi inserire la stessa garanzia della franchigia!'
                    }
                    setTimeout(() => {
                        if (!CustomJS.isNullEmpty(document.getElementById(`messaggioErroreStessaFranchigia${num}`)))
                            document.getElementById(`messaggioErroreStessaFranchigia${num}`).innerHTML = '</br>';
                    }, 3500)
                    if (!CustomJS.isNullEmpty(document.getElementById(`Garanzia${index}`).ej2_instances[0].text)) {
                        if (document.getElementById(`Garanzia${index}`).ej2_instances[0].value === args.value) {
                            document.getElementById(`FranchigiaEuro${num}`).ej2_instances[0].value = document.getElementById(`FranchigiaEuro${index}`).ej2_instances[0].value;
                            document.getElementById(`FranchigiaGiorni${num}`).ej2_instances[0].value = document.getElementById(`FranchigiaGiorni${index}`).ej2_instances[0].value;
                            document.getElementById(`ScopertoPerc${num}`).ej2_instances[0].value = document.getElementById(`ScopertoPerc${index}`).ej2_instances[0].value;
                            document.getElementById(`ScopertoMinimo${num}`).ej2_instances[0].value = document.getElementById(`ScopertoMinimo${index}`).ej2_instances[0].value;
                            document.getElementById(`ScopertoMassimo${num}`).ej2_instances[0].value = document.getElementById(`ScopertoMassimo${index}`).ej2_instances[0].value;

                            document.getElementById(`sceltaFranchigia${num}`).innerText = document.getElementById(`sceltaFranchigia${index}`).innerText
                            document.getElementById(`sceltaScoperto${num}`).innerText = document.getElementById(`sceltaScoperto${index}`).innerText;


                            document.getElementById(`FranchigiaEuro${num}`).ej2_instances[0].readonly = true;
                            document.getElementById(`FranchigiaGiorni${num}`).ej2_instances[0].readonly = true
                            document.getElementById(`ScopertoPerc${num}`).ej2_instances[0].readonly = true;
                            document.getElementById(`ScopertoMinimo${num}`).ej2_instances[0].readonly = true;
                            document.getElementById(`ScopertoMassimo${num}`).ej2_instances[0].readonly = true;
                        }
                    }
                }
            }
        }
        else {
            document.getElementById(`FranchigiaEuro${num}`).ej2_instances[0].readonly = false;
            document.getElementById(`FranchigiaGiorni${num}`).ej2_instances[0].readonly = false;
            document.getElementById(`ScopertoPerc${num}`).ej2_instances[0].readonly = false;
            document.getElementById(`ScopertoMinimo${num}`).ej2_instances[0].readonly = false;
            document.getElementById(`ScopertoMassimo${num}`).ej2_instances[0].readonly = false;
        }
    }
    modificaFranchigiaETrasporta(num) {
        document.getElementById(`sceltaFranchigia${num}`).innerText = '→';
        document.getElementById(`sceltaScoperto${num}`).innerText = '';
        for (let index = 0; index < this.numComboGaranzie; index++) {
            if (document.getElementById(`FranchigiaEuro${index}`)) {
                if (document.getElementById(`FranchigiaCondivisa${index}`)) {
                    if (document.getElementById(`Garanzia${num}`).ej2_instances[0].value === document.getElementById(`FranchigiaCondivisa${index}`).ej2_instances[0].value) {
                        document.getElementById(`FranchigiaEuro${index}`).ej2_instances[0].value = document.getElementById(`FranchigiaEuro${num}`).ej2_instances[0].value
                        document.getElementById(`FranchigiaGiorni${index}`).ej2_instances[0].value = document.getElementById(`FranchigiaGiorni${num}`).ej2_instances[0].value
                        document.getElementById(`ScopertoPerc${index}`).ej2_instances[0].value = null
                        document.getElementById(`ScopertoMassimo${index}`).ej2_instances[0].value = null
                        document.getElementById(`ScopertoMinimo${index}`).ej2_instances[0].value = null

                        document.getElementById(`sceltaFranchigia${index}`).innerText = '→';
                        document.getElementById(`sceltaScoperto${index}`).innerText = '';

                    }
                }
            }
        }
    }
    minimoMinoreDiMassimoCheck(num) {
        let minimo = document.getElementById(`ScopertoMinimo${num}`).ej2_instances[0].value;
        let massimo = document.getElementById(`ScopertoMassimo${num}`).ej2_instances[0].value;
        if (!CustomJS.isNullEmpty(minimo) && !CustomJS.isNullEmpty(massimo)) {
            if (minimo < massimo) {
                return true
            }
            else {
                document.getElementById(`scopertoMinMaxErr${num}`).style.display = 'flex';
                setTimeout(() => {
                    document.getElementById(`scopertoMinMaxErr${num}`).style.display = 'none';
                }, 3500)
                return false;
            }
        }
        else {
            return true;
        }
    }
    modificaScopertoETrasporta(num) {
        let isOk = this.minimoMinoreDiMassimoCheck(num);
        if (isOk === true) {
            document.getElementById(`sceltaFranchigia${num}`).innerText = '';
            document.getElementById(`sceltaScoperto${num}`).innerText = '→';
            for (let index = 0; index < this.numComboGaranzie; index++) {
                if (document.getElementById(`ScopertoPerc${index}`)) {
                    if (document.getElementById(`FranchigiaCondivisa${index}`)) {
                        if (document.getElementById(`Garanzia${num}`).ej2_instances[0].value === document.getElementById(`FranchigiaCondivisa${index}`).ej2_instances[0].value) {
                            document.getElementById(`ScopertoPerc${index}`).ej2_instances[0].value = document.getElementById(`ScopertoPerc${num}`).ej2_instances[0].value
                            document.getElementById(`ScopertoMassimo${index}`).ej2_instances[0].value = document.getElementById(`ScopertoMassimo${num}`).ej2_instances[0].value
                            document.getElementById(`ScopertoMinimo${index}`).ej2_instances[0].value = document.getElementById(`ScopertoMinimo${num}`).ej2_instances[0].value
                            document.getElementById(`FranchigiaEuro${index}`).ej2_instances[0].value = null
                            document.getElementById(`FranchigiaGiorni${index}`).ej2_instances[0].value = null

                            document.getElementById(`sceltaFranchigia${index}`).innerText = '';
                            document.getElementById(`sceltaScoperto${index}`).innerText = '→';
                        }
                    }
                }
            }
        }
    }
    bloccaFranchigiaOScopertura(num, francOScop, args) {
        if (francOScop === 'scoperto') {
            document.getElementById(`FranchigiaEuro${num}`).ej2_instances[0].value = null;
            document.getElementById(`FranchigiaGiorni${num}`).ej2_instances[0].value = null;
        }
        else {
            document.getElementById(`ScopertoPerc${num}`).ej2_instances[0].value = null;
            document.getElementById(`ScopertoMinimo${num}`).ej2_instances[0].value = null;
            document.getElementById(`ScopertoMassimo${num}`).ej2_instances[0].value = null;
        }
    }
    //------------------GESTIONE FRANCHIGE SCOPERTI FINE------------------


    //------------------GESTIONE ACCORDION RISCHIO ASSICURATO INIZIO------------------
    rischioAssicuratoFabbricatoOFotovoltaico(fabbricatoOFotovoltaico, args) {
        if (fabbricatoOFotovoltaico === 'fotovoltaico') {
            document.getElementById('schedaFabbricato').style.display = 'none';
            document.getElementById('schedaFotovoltaico').style.removeProperty('display');
        }
        else if (fabbricatoOFotovoltaico === 'fabbricato') {
            document.getElementById('schedaFotovoltaico').style.display = 'none';
            document.getElementById('schedaFabbricato').style.removeProperty('display');
        }
    }
    rischioAssicuratoConformeSiNo(scelta, args) {
        if (scelta === 'si') {
            document.getElementById('noteRischioNoConforme').ej2_instances[0].value = '';
            document.getElementById('noteRischioNoConformeDiv').style.display = 'none';
        }
        else if (scelta === 'no') {
            document.getElementById('noteRischioNoConformeDiv').style.removeProperty('display');
        }
    }
    generaDescrizioneRischioAssicurato(tipo) {
        if (tipo === 'fabbricato') {
            let tipo = CustomJS.isNullEmpty(document.getElementById('tipoAbitazioneRischAss').ej2_instances[0].value[0]) ? '' : document.getElementById('tipoAbitazioneRischAss').ej2_instances[0].value[0];
            let piani = CustomJS.isNullEmpty(document.getElementById('pianiFT').ej2_instances[0].value) ? '' : document.getElementById('pianiFT').ej2_instances[0].value;
            let pianiStr = (piani === '1' ? 'piano' : 'piani');
            let anni = CustomJS.isNullEmpty(document.getElementById('costruitoAnniFabbr').ej2_instances[0].value) ? '' : document.getElementById('costruitoAnniFabbr').ej2_instances[0].value;
            let adibito = CustomJS.isNullEmpty(document.getElementById('adibitoList').ej2_instances[0].value[0]) ? '' : document.getElementById('adibitoList').ej2_instances[0].value[0];
            adibito = adibito === 'totalita' ? 'totalità' : adibito;
            let adibitoA = CustomJS.isNullEmpty(document.getElementById('adibitoAList').ej2_instances[0].value[0]) ? '' : document.getElementById('adibitoAList').ej2_instances[0].value[0];
            let strutturaPortante = CustomJS.isNullEmpty(document.getElementById('strutturaPortante').ej2_instances[0].value[0]) ? '' : document.getElementById('strutturaPortante').ej2_instances[0].value[0];
            let tamponamenti = CustomJS.isNullEmpty(document.getElementById('tamponamenti').ej2_instances[0].value[0]) ? '' : document.getElementById('tamponamenti').ej2_instances[0].value[0];
            let ordituraTetto = CustomJS.isNullEmpty(document.getElementById('ordituraTetto').ej2_instances[0].value[0]) ? '' : document.getElementById('ordituraTetto').ej2_instances[0].value[0];
            let copertura = CustomJS.isNullEmpty(document.getElementById('copertura').ej2_instances[0].value[0]) ? '' : document.getElementById('copertura').ej2_instances[0].value[0];
            let finiture = CustomJS.isNullEmpty(document.getElementById('finiture').ej2_instances[0].value[0]) ? '' : document.getElementById('finiture').ej2_instances[0].value[0];
            let condizioneRischio = CustomJS.isNullEmpty(document.getElementById('condizioneRischio').ej2_instances[0].value[0]) ? '' : document.getElementById('condizioneRischio').ej2_instances[0].value[0];
            condizioneRischio = condizioneRischio === '1' ? 'buono' : condizioneRischio === '2' ? 'normale' : condizioneRischio === '3' ? 'mediocre' : condizioneRischio === '4' ? 'pessimo' : condizioneRischio;
            let rischio = document.getElementById('rischioFabbricatoConforme').ej2_instances[0].checked === true ? 'CONFORME' : 'NON CONFORME';
            let testo = '';
            if (CustomJS.isNullEmpty(tipo) || CustomJS.isNullEmpty(adibito) || CustomJS.isNullEmpty(adibitoA) || CustomJS.isNullEmpty(strutturaPortante) || CustomJS.isNullEmpty(tamponamenti)
                || CustomJS.isNullEmpty(ordituraTetto) || CustomJS.isNullEmpty(copertura) || CustomJS.isNullEmpty(finiture) || CustomJS.isNullEmpty(condizioneRischio)) {
                testo = 'Inserisci tutti i campi!'
            }
            else {
                testo = `Trattasi di ${tipo.toLowerCase()} elevato a ${piani} ${pianiStr}, costruito negli anni ${anni} e adibito per ${adibito.toLowerCase()} a ${adibitoA.toLowerCase()}.\nL'immobile è costruito con struttura portante in ${strutturaPortante.toLowerCase()} e con tamponamenti in ${tamponamenti.toLowerCase()}.\nIl tetto è realizzato con orditura in ${ordituraTetto.toLowerCase()} e copertura con ${copertura.toLowerCase()}.\nLe finiture sono ${finiture.toLowerCase()} e lo stato di condizione rischio è ${condizioneRischio.toLowerCase()}.\n\nIl rischio è risultato ${rischio.toLowerCase()} alla descrizione indicata in polizza. `;
                testo = testo.replaceAll('-', ' ');
            }
            document.getElementById('noteRischioAssicuratoFabbricato').ej2_instances[0].value = testo;
        }
        else {
            let impianto = CustomJS.isNullEmpty(document.getElementById('impianto').ej2_instances[0].value[0]) ? '' : document.getElementById('impianto').ej2_instances[0].value[0];
            let anniFotov = CustomJS.isNullEmpty(document.getElementById('costruitoAnniFotov').ej2_instances[0].value) ? '' : document.getElementById('costruitoAnniFotov').ej2_instances[0].value;
            let rischioFotov = document.getElementById('rischioFotovConforme').ej2_instances[0].checked === true ? 'CONFORME' : 'NON CONFORME';
            let monitorato = document.getElementById('monitoratoFotovSi').ej2_instances[0].checked === true ? 'MONITORATO' : 'NON MONITORATO';
            let testoFotov = '';
            if (CustomJS.isNullEmpty(impianto) || CustomJS.isNullEmpty(anniFotov)) {
                testoFotov = 'Inserisci tutti i campi!'
            }
            else {
                testoFotov = `Trattasi di ${impianto} costruito negli anni ${anniFotov}.\n// IL RISCHIO È RISULTATO ${rischioFotov} ALLA DESCRIZIONE INDICATA IN POLIZZA\n// È RISULTATO ${monitorato} ALLA DESCRIZIONE INDICATA IN POLIZZA `;
                testoFotov = testoFotov.replaceAll('-', ' ');
            }
            document.getElementById('noteRischioAssicuratoFotovoltaico').ej2_instances[0].value = testoFotov;
        }
    }

    //------------------GESTIONE ACCORDION RISCHIO ASSICURATO FINE------------------


    //------------------GESTIONE ACCORDION SINISTRO E LE SUE MODALITA INIZIO------------------
    sinistroELeSueModalitaEMezziChiusuraSiNO() {
        let presenteAcquaCondotta = false;
        let presenteFenomenoElettrico = false;
        let presenteFurto = false;
        let presenteEventoAtmosferico = false;
        let testoRivalsa = ''

        for (let g = 0; g < this.numComboGaranzie; g++) {
            if (document.getElementById(`containerComboGaranzia${g}`)) {
                if (!CustomJS.isNullEmpty(document.getElementById(`Garanzia${g}`).ej2_instances[0].value)) {
                    if (document.getElementById(`Garanzia${g}`).ej2_instances[0].value === 'TG001' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '2' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '3' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '14' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '48' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '70' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '71') {
                        presenteAcquaCondotta = true;
                    }
                    if (document.getElementById(`Garanzia${g}`).ej2_instances[0].value === 'TG007' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '5') {
                        presenteFenomenoElettrico = true;
                    }
                    if (document.getElementById(`Garanzia${g}`).ej2_instances[0].value === 'TG009' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '10' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '69') {
                        presenteFurto = true;
                    }
                    if (document.getElementById(`Garanzia${g}`).ej2_instances[0].value === 'TG003' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '75' ||
                        document.getElementById(`Garanzia${g}`).ej2_instances[0].value === '4') {
                        presenteEventoAtmosferico = true;
                    }
                }
            }
        }
        //Acqua Condotta
        if (presenteAcquaCondotta === true)
            document.getElementById('acquaCondottaModalita').style.removeProperty('display');
        else
            document.getElementById('acquaCondottaModalita').style.display = 'none';
        //Fenomeno Elettrico
        if (presenteFenomenoElettrico === true)
            document.getElementById('fenomenoElettricoModalita').style.removeProperty('display');
        else
            document.getElementById('fenomenoElettricoModalita').style.display = 'none';
        //Furto
        if (presenteFurto === true) {
            document.getElementById('furtoModalita').style.removeProperty('display');
            document.getElementById('mezziDiChiusura').style.removeProperty('display');
            testoRivalsa = 'Alla data attuale, allo stato delle indagini conosciute, gli autori del reato restano ignoti ne sono emerse responsabilità civili a carico di terzi che possano colpevolmente aver agevolato la commissione del reato. Conseguentemente a oggi non sono emersi elementi che permettano di avviare con successo azione di rivalsa.'
            document.getElementById('noteRivalsa').ej2_instances[0].value = testoRivalsa;
        }
        else {
            document.getElementById('furtoModalita').style.display = 'none';
            document.getElementById('mezziDiChiusura').style.display = 'none';
            testoRivalsa = 'Non sussistono a nostro avviso elementi che consentano di individuare precise responsabilità di terzi nel sinistro occorso e, conseguentemente, che permettano di avviare con successo azione di rivalsa.'
            document.getElementById('noteRivalsa').ej2_instances[0].value = testoRivalsa;

        }
        //Evento Atmosferico
        if (presenteEventoAtmosferico === true)
            document.getElementById('eventoAtmosfericoModalita').style.removeProperty('display');
        else
            document.getElementById('eventoAtmosfericoModalita').style.display = 'none'
    }
    generaDescrizioneSinistroSueModalita(garanzia) {
        let testo = '';
        //ACQUA CONDOTTA
        if (garanzia === 'acquaCondotta') {
            let causa = CustomJS.isNullEmpty(document.getElementById('CausaSinistro').ej2_instances[0].value[0]) ? '' : document.getElementById('CausaSinistro').ej2_instances[0].value[0];
            let effetto = CustomJS.isNullEmpty(document.getElementById('EffettoSinistro').ej2_instances[0].value[0]) ? '' : document.getElementById('EffettoSinistro').ej2_instances[0].value[0];
            testo = `La causa del sinistro è una ${causa.toLowerCase()} con ${effetto.toLowerCase()}`;
            document.getElementById('noteSinistroModalita').ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById('noteSinistroModalita').ej2_instances[0].value) ? '' : document.getElementById('noteSinistroModalita').ej2_instances[0].value) + testo;
        }
        //FENOMENO ELETTRICO
        else if (garanzia === 'sovratensioneFenomenoElettrico') {
            testo = ` A causa di una sovratensione elettrica che si è verificata in data ${this.datiPerNote.dataAccadimento} , si danneggiava il ………………. La denuncia del sinistro è stata effettuata in data  Data_Denuncia  . Dalle verifiche tecniche il danneggiamento è conseguente ad una sovratensione elettrica`
            document.getElementById('noteSinistroModalita').ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById('noteSinistroModalita').ej2_instances[0].value) ? '' : document.getElementById('noteSinistroModalita').ej2_instances[0].value) + testo;

        }
        else if (garanzia === 'temporaleFenomenoElettrico') {
            testo = `Durante un temporale che si è abbattuto nella zona ove è ubicato il rischio assicurato in data ${this.datiPerNote.dataAccadimento}, si verificava una sovratensione elettrica che causava danni al ……………….La denuncia del sinistro è stata effettuata in data Data_Denuncia. Dalle verifiche tecniche il danneggiamento è conseguente ad una sovratensione elettrica.`;
            document.getElementById('noteSinistroModalita').ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById('noteSinistroModalita').ej2_instances[0].value) ? '' : document.getElementById('noteSinistroModalita').ej2_instances[0].value) + testo;
        }
        else if (garanzia === 'furto') {
            testo = `In data ${this.datiPerNote.dataAccadimento} ignoti malfattori si introducevano nei locali del rischio assicurato e commettevano un furto come da denuncia allegata.`;
            document.getElementById('noteSinistroModalita').ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById('noteSinistroModalita').ej2_instances[0].value) ? '' : document.getElementById('noteSinistroModalita').ej2_instances[0].value) + testo;
        }
        else if (garanzia === 'eventoAtmosferico') {
            testo = `In data ${this.datiPerNote.dataAccadimento} si abbatteva nella zona del rischio assicurato un eccezionale evento atmosferico caratterizzato da venti pioggia e grandine. `;
            document.getElementById('noteSinistroModalita').ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById('noteSinistroModalita').ej2_instances[0].value) ? '' : document.getElementById('noteSinistroModalita').ej2_instances[0].value) + testo;
        }
    }

    //------------------GESTIONE ACCORDION SINISTRO E LE SUE MODALITA FINE------------------


    //------------------GESTIONE ACCORDION MEZZI DI CHIUSURA INIZIO------------------
    mezziChiusuraAntisfondamentoONo(args) {
        if (args.value[0] === 'con-telaio-in-legno-e-vetro' || args.value[0] === 'con-telaio-in-metallo-e-vetro')
            document.getElementById('appareSoloSeCeVetro').style.removeProperty('display');
        else
            document.getElementById('appareSoloSeCeVetro').style.display = 'none';
    }
    motivazioniMezziChiusuraSiNo() {
        if (document.getElementById('nonConformi').ej2_instances[0].checked)
            document.getElementById('containerNoteMotivazioniMezziChiusura').style.removeProperty('display');
        else
            document.getElementById('containerNoteMotivazioniMezziChiusura').style.display = 'none';
    }
    generaDescrMezziChiusura() {
        let tipo = CustomJS.isNullEmpty(document.getElementById('tipoListMezzChius').ej2_instances[0].value[0]) ? '' : document.getElementById('tipoListMezzChius').ej2_instances[0].value[0];
        let porta = CustomJS.isNullEmpty(document.getElementById('porteIngressoLocali').ej2_instances[0].value[0]) ? '' : document.getElementById('porteIngressoLocali').ej2_instances[0].value[0];
        let tipoFinestre = CustomJS.isNullEmpty(document.getElementById('tipoFinestreFuoriTerra').ej2_instances[0].value[0]) ? '' : document.getElementById('tipoFinestreFuoriTerra').ej2_instances[0].value[0];
        let tipoFinestreDue = CustomJS.isNullEmpty(document.getElementById('tipoFinestreFuoriTerra2').ej2_instances[0].value[0]) ? '' : document.getElementById('tipoFinestreFuoriTerra2').ej2_instances[0].value[0];
        let tipoFinestreTre = CustomJS.isNullEmpty(document.getElementById('tipoFinestreFuoriTerra3').ej2_instances[0].value[0]) ? '' : document.getElementById('tipoFinestreFuoriTerra3').ej2_instances[0].value[0];
        let inferriate = document.getElementById('inferriate').ej2_instances[0].checked === true ? 'Inferriate' : '';
        let antintrusione = document.getElementById('antintrusione').ej2_instances[0].checked === true ? 'Impianto Antintrusione' : '';
        let conformi = document.getElementById('pienamenteConformi').ej2_instances[0].checked === true ? 'Pienamente Conformi' : (document.getElementById('parzialmenteConformi').ej2_instances[0].checked === true ? 'Parzialmente conformi' : 'Non conformi')
        let sfondamentoOno = '';
        let testo = '';
        let sfondamentoBool = (tipoFinestre === 'con-telaio-in-legno-e-vetro' || tipoFinestre === 'con-telaio-in-metallo-e-vetro');
        if (sfondamentoBool)
            sfondamentoOno = CustomJS.isNullEmpty(document.getElementById('antisfondamentoONo').ej2_instances[0].value[0]) ? '' : document.getElementById('antisfondamentoONo').ej2_instances[0].value[0];
        else
            sfondamentoOno = '';

        if (CustomJS.isNullEmpty(tipo) || CustomJS.isNullEmpty(porta) || CustomJS.isNullEmpty(tipoFinestre)
            || CustomJS.isNullEmpty(tipoFinestreDue) || CustomJS.isNullEmpty(tipoFinestreTre) || CustomJS.isNullEmpty(conformi)) {
            testo = 'Inserisci tutti i campi!'
        }
        else {
            testo = `Trattasi di ${tipo.toLowerCase()} di cui ingresso ai locali è costituito da porta ${porta.toLowerCase()} di cui mezzi di chiusura al primo 
            piano fuori terra sono costituiti da finestre ${tipoFinestre.toLowerCase()} con ${sfondamentoOno.toLowerCase() + ' con '} ${tipoFinestreDue.toLowerCase()} ${tipoFinestreTre.toLowerCase()}; </br>
            Le finestre sono ${inferriate.toLowerCase()} ${antintrusione.toLowerCase()}. </br>
            I mezzi di chiusura sono ${conformi.toLowerCase()}.`;
            testo = testo.replaceAll('-', ' ');
        }
        document.getElementById('noteMezziChiusura').ej2_instances[0].value = testo;
    }

    //------------------GESTIONE ACCORDION MEZZI DI CHIUSURA FINE------------------


    //------------------GESTIONE ACCORDION OPERAZIONI PERITALI INIZIO------------------
    generaDescrizioneOperazioniPeritali(tipologia, args) {
        let testo = ''
        if (tipologia === 'standard') {
            testo = `Ricevuto l\' incarico, abbiamo conferito con l \'Assicurato e fissato un sopralluogo presso l\'ubicazione del rischio assicurato per il giorno ${this.datiPerNote.dataSopralluogo}.\n In tale occasione si prendeva visione dei luoghi e si acquisiva la documentazione necessaria all\'espletamento delle indagini peritali`;
        }
        else if (tipologia === 'videoPerizia') {
            testo = 'Ricevuto l\'incarico, contattavamo il Contraente e ci accordavamo per effettuare la videoperizia.\n Attraverso tale strumento si prendeva visione dei danni e successivamente si acquisiva la documentazione necessaria all\'espletamento delle indagini peritali.';
        }
        document.getElementById('noteOperazioniPeritali').ej2_instances[0].value = testo;
    }

    //------------------GESTIONE ACCORDION OPERAZIONI PERITALI FINE------------------

    //------------------GESTIONE ACCORDION RIVALSA INIZIO------------------
    possibilitaRivalsaSiNoRinuncia(scelta) {
        let testo = ''//
        if (scelta === 'si') {
            document.getElementById('motivoRivalsa').ej2_instances[0].value = '01';
            document.getElementById('motivoRivalsa').ej2_instances[0].enabled = true;
            document.getElementById('cognomeDebitoreRivalsa').ej2_instances[0].enabled = true;
            document.getElementById('nomeDebitoreRivalsa').ej2_instances[0].enabled = true;
            testo = `A nostro parere è esperibile azione di rivalsa nei confronti di ${this.datiPerNote.nomeContraente}`;
            document.getElementById('noteRivalsa').ej2_instances[0].value = testo;
            this.formOptions.rules[`motivoRivalsa`] = {
                required: [true, "Motivo Rivalsa è un campo obbligatorio!"]
            };
            this.formOptions.rules[`cognomeDebitoreRivalsa`] = {
                required: [true, "Cognome Debitore è un campo obbligatorio!"]
            }
            this.formOptions.rules[`nomeDebitoreRivalsa`] = {
                required: [true, "Nome Debitore è un campo obbligatorio!"]
            }
        }
        else if (scelta === 'no') {
            this.sinistroELeSueModalitaEMezziChiusuraSiNO();
            document.getElementById('motivoRivalsa').ej2_instances[0].value = null;
            document.getElementById('cognomeDebitoreRivalsa').ej2_instances[0].value = null;
            document.getElementById('nomeDebitoreRivalsa').ej2_instances[0].value = null;
            document.getElementById('motivoRivalsa').ej2_instances[0].enabled = false;
            document.getElementById('cognomeDebitoreRivalsa').ej2_instances[0].enabled = false;
            document.getElementById('nomeDebitoreRivalsa').ej2_instances[0].enabled = false;
            if (document.getElementById('motivoRivalsa-info')) 
                document.getElementById('motivoRivalsa-info').style.display = 'none';
            if (document.getElementById('cognomeDebitoreRivalsa-info'))
                document.getElementById('cognomeDebitoreRivalsa-info').style.display = 'none';
            if (document.getElementById('nomeDebitoreRivalsa-info'))
                document.getElementById('nomeDebitoreRivalsa-info').style.display = 'none';                
            delete this.formOptions.rules[`motivoRivalsa`];
            delete this.formOptions.rules[`cognomeDebitoreRivalsa`];
            delete this.formOptions.rules[`nomeDebitoreRivalsa`];
        }
        else if (scelta === 'rinuncia') {
            testo = 'Si evidenzia che in polizza è prevista clausola di rinuncia alla rivalsa all\'art.';
            document.getElementById('noteRivalsa').ej2_instances[0].value = testo;
            document.getElementById('motivoRivalsa').ej2_instances[0].value = null;
            document.getElementById('cognomeDebitoreRivalsa').ej2_instances[0].value = null;
            document.getElementById('nomeDebitoreRivalsa').ej2_instances[0].value = null;
            document.getElementById('motivoRivalsa').ej2_instances[0].enabled = false;
            document.getElementById('cognomeDebitoreRivalsa').ej2_instances[0].enabled = false;
            document.getElementById('nomeDebitoreRivalsa').ej2_instances[0].enabled = false;
            if (document.getElementById('motivoRivalsa-info')) 
                document.getElementById('motivoRivalsa-info').style.display = 'none';
            if (document.getElementById('cognomeDebitoreRivalsa-info'))
                document.getElementById('cognomeDebitoreRivalsa-info').style.display = 'none';
            if (document.getElementById('nomeDebitoreRivalsa-info'))
                document.getElementById('nomeDebitoreRivalsa-info').style.display = 'none';                
            delete this.formOptions.rules[`motivoRivalsa`];
            delete this.formOptions.rules[`cognomeDebitoreRivalsa`];
            delete this.formOptions.rules[`nomeDebitoreRivalsa`];
        }
    }
    //------------------GESTIONE ACCORDION RIVALSA FINE------------------

    //------------------GESTIONE ACCORDION RISERVA INIZIO------------------
    generaDescrizioneRiserva() {
        let lista = document.getElementById('riservaList').ej2_instances[0].value;
        let testo = '';
        let scelta = ''
        lista.forEach(elm => {
            scelta = elm;
            if (scelta === 'no-rg')
                testo += ` Si eleva riserva di indennizzabilità in quanto, non essendo stata ancora effettuata la ricerca del guasto, non è stato possibile determinare le cause dell' evento e appurarne la riconducibilità alle garanzie contrattuali.\n`;
            if (scelta === 'no-danni-materiali')
                testo += `Si eleva riserva di indennizzabilità in quanto, non essendo stato rilevato danno materiale e diretto alle cose assicurate ai sensi dell'art. xx , Danni d'acqua, non risultano garantite le spese di ricerca della perdita di cui all'art. xx , Ricerca del guasto.\n`;
            if (scelta === 'tempistiche')
                testo += `Si eleva riserva di indennizzabilità in quanto non sono state rispettate le tempistiche contrattualizzate in termini di avviso alla Compagnia (o alle Autorità) così come previsto dal comma xx dell'articolo xx , Obblighi in caso di sinistro.\n`;
            if (scelta === 'no-garanzia')
                testo += `Si eleva riserva di indennizzabilità in quanto la garanzia coinvolta (art.xx , Ricerca del guasto) non è stata sottoscritta dal Contraente di polizza.\n`;
            if (scelta === 'esclusione')
                testo += `Si eleva riserva di indennizzabilità per i danni alla xxxxxxx, in quanto bene esplicitamente escluso in virtù di quanto riportato al comma xx dell'articolo xx , Esclusioni (delimitazioni di garanzia, ecc..)\n`;
            if (scelta === 'no-residui')
                testo = `Si eleva riserva di indennizzabilità in quanto non sono state mantenute le tracce ed i residui del sinistro, impedendo in questo modo al perito di poter verificare il danno, la causa e la riconducibilità del medesimo alle garanzie di polizza.\n`;
            if (scelta === 'rischio-non-conforme')
                testo += `Si eleva riserva di indennizzabilità in quanto il rischio non risulta conforme alle descrizioni di polizza. Il contratto assicura la "dimora abituale", mentre il fabbricato oggetto dell'evento risulta in realtà "dimora saltuaria" dell'assicurato.\n`;
            document.getElementById('noteRiserva').ej2_instances[0].value = testo;
        })
        // this.generaDescrizioneDefinizione('default');
    }
    //------------------GESTIONE ACCORDION RISERVA FINE------------------


    //------------------GESTIONE ACCORDION DEFINIZIONE INIZIO------------------
    // generaDescrizioneDefinizione(scelta) {
    //     let testo = ''
    //     if (scelta === 'default') {
    //         if (!CustomJS.isNullEmpty(document.getElementById('noteRiserva').ej2_instances[0].value))
    //             // se c'è la riserva :
    //             testo = `Al termine degli accertamenti peritali riteniamo che il sinistro non rientri nelle garanzie di polizza in quanto sussistono riserve di operatività.`
    //         else
    //             // se non c'è la riserva :
    //             testo = `Al termine degli accertamenti peritali riteniamo che il sinistro rientri nelle garanzie di polizza in quanto non sussistono riserve di operatività.`
    //     }
    //     else if (scelta === 'attesaAtto')
    //         testo = `Per il presente sinistro abbiamo inviato all'amm.re pro tempore del condomino  assicurato atto con RISERVA che ad oggi non ci è stato restituito perfezionato.`
    //     else if (scelta === 'accordoVerbale')
    //         testo = `Il danno stimato è stato concordato verbalmente nella persona dell'amm.re pro-tempore sig. ${this.datiPerNote.nomeContraente}.  per un importo pari a  ${this.datiPerNote.totaleRiepilogo}  al netto di franchigia.`
    //     else if (scelta === 'atto')
    //         testo = `Il danno de quo è stato definito mediante Atto con il sig. ${this.datiPerNote.nomeContraente} in qualità di amm.re pro tempore del condomino assicurato per un importo pari a ${this.datiPerNote.totaleRiepilogo} al netto delle franchigie.`
    //     document.getElementById('noteDefinizione').ej2_instances[0].value = testo;
    // }
    //------------------GESTIONE ACCORDION DEFINIZIONE FINE------------------



    //----------------------------------------CONTENT ACCORDION INIZIO ----------------------------------------
    contentPartite() {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 offset-lg-8 col-lg-2 col-md-12" style={{ marginBottom: '-5px', marginTop: '-10px', textAlign: 'right' }}>
                                <div id='aggiungiPartita' style={{ textAlign: 'right', marginRight: '-100%' }}>
                                    <ButtonComponent id="btnAggiungiPartita" onClick={this.aggiungiPartita.bind(this, '', '')} title='Aggiungi Partita' cssClass='e-fill e-info' type='button'>AGGIUNGI PARTITA</ButtonComponent>
                                </div>
                            </div>
                        </div>
                        <div id='tutteComboPartite'>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentGaranzie(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 offset-lg-8 col-lg-2 col-md-12" style={{ marginBottom: '-5px', marginTop: '-10px', textAlign: 'right' }}>
                                <div id='aggiungiGaranzia' style={{ textAlign: 'right', marginRight: '-100%' }}>
                                    <ButtonComponent id="btnAggiungiGaranzia" onClick={this.aggiungiGaranzia.bind(this, '', '')} title='Aggiungi Garanzia' cssClass='e-fill e-info' type='button'>AGGIUNGI GARANZIA</ButtonComponent>
                                </div>
                            </div>
                        </div>
                        <div id='tutteComboGaranzie' className='container-tutte-le-garanzie'>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    contentStime(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className='row'>
                            <div className="col-xs-100col-sm-10 col-lg-10 col-md-10"></div>
                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end" style={{ marginBottom: '-5px', marginTop: '-10px', marginRight: '-50px' }}>
                                <ButtonComponent id="btnAggiungiStima" onClick={this.aggiungiStima.bind(this, '', '', false)} title='Aggiungi Stima' cssClass='e-fill e-info' type='button'>AGGIUNGI STIMA</ButtonComponent>
                            </div>
                        </div>
                        <div id='tutteTabelleStima'>
                            {/* Qui tutte le tabelle di stima */}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentRischioAssicurato(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="control-section card-control-section basic_card_layout" >
                            <div className="e-card-resize-container">
                                <div id='schedaFabbricato'>
                                    <div className="row card-layout">
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <h4>Note Rischio Assicurato</h4>
                                                            <RichTextEditorComponent id='noteRischioAssicuratoFabbricato' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                            </RichTextEditorComponent>
                                                            <ButtonComponent id="eliminaNoteRischioAssicuratoFabbricato" style={{ marginTop: '50px' }} onClick={() => { document.getElementById('noteRischioAssicuratoFabbricato').ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                                            <div style={{ display: 'none', marginTop: '30px' }} id='noteRischioNoConformeDiv'>
                                                                <h5>Note Rischio non conforme</h5>
                                                                <RichTextEditorComponent id='noteRischioNoConforme' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                    maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                                    <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                                </RichTextEditorComponent>
                                                                <ButtonComponent id="eliminaNoteRischioNoConforme" style={{ marginTop: '20px' }} onClick={() => { document.getElementById('noteRischioNoConforme').ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                    <div id='btnFotFabbDiv' style={{ textAlign: 'right', marginLeft: '55%' }}>
                                                        <ButtonComponent id="btnFotovoltaico" style={{ margin: '0px 10px' }} onClick={this.rischioAssicuratoFabbricatoOFotovoltaico.bind(this, 'fotovoltaico')} cssClass='e-fill e-info pulsante-disabilitato' type='button'>Fotovoltaico</ButtonComponent>
                                                    </div>
                                                    <div className='row'>
                                                        <h5>Scheda Fabbricato</h5>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>Tipo</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='tipoAbitazioneRischAss' dataSource={this.tipoAbitazioneRischAssData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>Piani</h6>
                                                            <TextBoxComponent type='number' value={0} id='pianiFT' placeholder='Piani' />
                                                            <h6>Costruito negli Anni</h6>
                                                            <TextBoxComponent type='number' value={0} id='costruitoAnniFabbr' placeholder='Anni' />
                                                            <div className='row' style={{ marginTop: '25px' }} >
                                                                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                                    <h6>Adibito</h6>
                                                                    <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='adibitoList' dataSource={this.adibitoData} >
                                                                        <Inject services={[CheckBoxSelection]} />
                                                                    </ListBoxComponent>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                                    <h6>A</h6>
                                                                    <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='adibitoAList' dataSource={this.adibitoAData} >
                                                                        <Inject services={[CheckBoxSelection]} />
                                                                    </ListBoxComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Struttura Portante</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='strutturaPortante' dataSource={this.strutturaPortanteData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Tamponamenti</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='tamponamenti' dataSource={this.tamponamentiData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Orditura Tetto</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='ordituraTetto' dataSource={this.ordituraTettoData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Copertura</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='copertura' dataSource={this.coperturaData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Finiture</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='finiture' dataSource={this.finitureData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Condizione Rischio</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='condizioneRischio' dataSource={this.dsCondizioneRischioData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6 style={{ textAlign: 'center' }}>Rischio</h6>
                                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                <RadioButtonComponent id='rischioFabbricatoConforme' onChange={this.rischioAssicuratoConformeSiNo.bind(this, 'si')} checked={true} label="Conforme" name="rischio" />
                                                                <RadioButtonComponent id='rischioFabbricatoNonConforme' onChange={this.rischioAssicuratoConformeSiNo.bind(this, 'no')} label="Non Conforme" name="rischio" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='row' style={{ marginLeft: '70%' }}>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" >
                                                            <ButtonComponent id="btnGeneraDescrizioneFabbricato" onClick={this.generaDescrizioneRischioAssicurato.bind(this, 'fabbricato')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Genera Descrizione</ButtonComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id='schedaFotovoltaico' style={{ display: 'none' }}>
                                    <div className="row card-layout">
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <RichTextEditorComponent id='noteRischioAssicuratoFotovoltaico' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                            </RichTextEditorComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                    <div id='btnFotFabbDiv' style={{ textAlign: 'right' }} >
                                                        <ButtonComponent id="btnFabbricato" style={{ margin: '0px 10px' }} onClick={this.rischioAssicuratoFabbricatoOFotovoltaico.bind(this, 'fabbricato')} cssClass='e-fill e-info pulsante-disabilitato' type='button'>Fabbricato</ButtonComponent>
                                                    </div>
                                                    <div className='row'>
                                                        <h5>Scheda Fotovoltaico</h5>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>Impianto</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='impianto' dataSource={this.tipoImpiantoData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <TextBoxComponent value={0} type='number' id='costruitoAnniFotov' placeholder='Costruito negli Anni' />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-center">
                                                            <h6 style={{ marginLeft: '75%' }}>Rischio</h6>
                                                            <RadioButtonComponent checked={true} id='rischioFotovConforme' label="Conforme" name="rischioFotov" />
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-center">
                                                            <h6><br></br></h6>
                                                            <RadioButtonComponent id='rischioFotovoltaicoNonConforme' label="Non Conforme" name="rischioFotov" />
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-center">
                                                            <h6 style={{ marginLeft: '75%' }}>Monitorato</h6>
                                                            <RadioButtonComponent checked={true} id='monitoratoFotovSi' label="Si" name="monitorato" />
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-center">
                                                            <h6><br></br></h6>
                                                            <RadioButtonComponent id='monitoratoFotovNo' label="No" name="monitorato" />
                                                        </div>
                                                    </div>
                                                    <div className='row' style={{ marginLeft: '70%' }}>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" >
                                                            <ButtonComponent id="btnGeneraDescrizioneFotovoltaico" onClick={this.generaDescrizioneRischioAssicurato.bind(this, "fotovoltaico")} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Genera Descrizione</ButtonComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentSinistroModalita(args) {
        // IL SINISTRO E LE SUE MODALITA
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout" >
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <RichTextEditorComponent id='noteSinistroModalita' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent style={{ marginTop: '50px' }} onClick={() => { document.getElementById('noteSinistroModalita').ej2_instances[0].value = '' }} id="btnEliminaNoteSinistroModalita" alt='Elimina Testo' cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            {/* ACQUA CONDOTTA */}
                            <div tabIndex="0" className="e-card" id="acquaCondottaModalita" style={{ borderRadius: '20px', border: '1px solid #bad0e3', display: 'none' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <h5>ACQUA CONDOTTA</h5>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <h5>AC -Causa del Sinistro</h5>
                                            <ListBoxComponent id='CausaSinistro' dataSource={this.dsCausaSinistro} />
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <h5>AC - Effetto</h5>
                                            <ListBoxComponent id='EffettoSinistro' dataSource={this.dsEffettoSinistro} />
                                            <ButtonComponent style={{ marginTop: '15px' }} onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'acquaCondotta')} id="btnModalitaAcquaCondotta" alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Acqua Condotta</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FENOMENO ELETTRICO */}
                            <div tabIndex="0" id='fenomenoElettricoModalita' className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3', display: 'none' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <h5>FENOMENO ELETTRICO</h5>
                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                            <ButtonComponent onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'sovratensioneFenomenoElettrico')} id="btnModalitaSovratensione" alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Sovratensione</ButtonComponent>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                            <ButtonComponent onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'temporaleFenomenoElettrico')} id="btnModalitaTemporale" alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Temporale</ButtonComponent>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FURTO */}
                            <div tabIndex="0" className="e-card" id="furtoModalita" style={{ borderRadius: '20px', border: '1px solid #bad0e3', display: 'none' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <h5>FURTO</h5>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <ButtonComponent onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'furto')} id="btnModalitaFurto" alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Furto</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* EVENTO ATMOSFERICO */}
                            <div tabIndex="0" className="e-card" id='eventoAtmosfericoModalita' style={{ borderRadius: '20px', border: '1px solid #bad0e3', display: 'none' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <h5>EVENTO ATMOSFERICO</h5>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <ButtonComponent onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'eventoAtmosferico')} id="btnModalitaEventoAtmosferico" alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Evento Atmosferico</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentMezziChiusura(args) {
        return <div >
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="control-section card-control-section basic_card_layout" >
                            <div className="e-card-resize-container">
                                <div className="row card-layout" >
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                            <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                <div className='row'>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        <RichTextEditorComponent id='noteMezziChiusura' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                            maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                            <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                        </RichTextEditorComponent>
                                                        <ButtonComponent style={{ marginTop: '50px' }} onClick={() => { document.getElementById('noteMezziChiusura').ej2_instances[0].value = '' }} id="btnEliminaNoteMezziChiusura" alt='Elimina Testo' cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <div tabIndex="0" className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                            <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                <div id='schedaMezziChiusura'>
                                                    <div className='row'>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>Tipo</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='tipoListMezzChius' dataSource={this.tipoAbitazioneMezzChiusData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>L'ingresso ai locali è costituito da porta :</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='porteIngressoLocali' dataSource={this.porteIngressoLocaliData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <h6>I mezzi di chiusura al primo piano fuori terra sono costituiti da finestre :</h6>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                            <ListBoxComponent fields={this.ddlFieldsList} onChange={this.mezziChiusuraAntisfondamentoONo.bind(this)} selectionSettings={{ mode: "Single", showCheckbox: false }} id='tipoFinestreFuoriTerra' dataSource={this.finestreFuoriTerra} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div id='appareSoloSeCeVetro' style={{ display: 'none' }} className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='antisfondamentoONo' dataSource={this.tipoFinestreFuoriTerra} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>

                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='tipoFinestreFuoriTerra2' dataSource={this.tipoFinestreFuoriTerra2} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                            <br></br>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id='tipoFinestreFuoriTerra3' dataSource={this.tipoFinestreFuoriTerra3} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <CheckBoxComponent checked={true} id='inferriate' label="Inferriate" />
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <CheckBoxComponent id='antintrusione' label="Impianto antintrusione" />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <h6>Mezzi di chiusura :</h6>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-center">
                                                            <RadioButtonComponent checked={true} onChange={this.motivazioniMezziChiusuraSiNo.bind(this)} id='pienamenteConformi' name='modalitaConformi' label="Pienamente conformi" />
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-center">
                                                            <RadioButtonComponent id='parzialmenteConformi' onChange={this.motivazioniMezziChiusuraSiNo.bind(this)} name='modalitaConformi' label="Parzialmente conformi" />
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-centers">
                                                            <RadioButtonComponent id='nonConformi' onChange={this.motivazioniMezziChiusuraSiNo.bind(this)} name='modalitaConformi' label="Non conformi" />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" id='containerNoteMotivazioniMezziChiusura' style={{ display: 'none' }} >
                                                            <h6>Motivazioni Mezzi di Chiusura Non Conformi:</h6>
                                                            <RichTextEditorComponent id='noteMotivazioniMezziChiusura' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                            </RichTextEditorComponent>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ marginTop: '100px', alignItems: 'end', textAlign: 'end' }}>
                                                            <ButtonComponent id="btnGeneraDescrizioneMezziChiusura" onClick={this.generaDescrMezziChiusura.bind(this)} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Genera Descrizione</ButtonComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentOperazioniPeritali(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h4>Note Operazioni Peritali</h4>
                                            <RichTextEditorComponent id='noteOperazioniPeritali' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent id="eliminaNoteOperazioniPeriitali" style={{ marginTop: '55px' }} onClick={() => { document.getElementById('noteOperazioniPeritali').ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                            <ButtonComponent id="btnOperazioniPeritaliStandard" onClick={this.generaDescrizioneOperazioniPeritali.bind(this, 'standard')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Frase Standard</ButtonComponent>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                            <ButtonComponent id="btnOperazioniPeritaliVideoPerizia" onClick={this.generaDescrizioneOperazioniPeritali.bind(this, 'videoPerizia')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Frase VideoPerizia</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentRivalsa(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h4>Note Rivalsa</h4>
                                            <RichTextEditorComponent id='noteRivalsa' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent id="eliminaNoteRivalsa" style={{ marginTop: '50px' }} onClick={() => { document.getElementById('noteRivalsa').ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h6 style={{ textAlign: 'center' }}>Possibilità Di Rivalsa</h6>
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <RadioButtonComponent id='possibilitaRivalsaSi' onChange={this.possibilitaRivalsaSiNoRinuncia.bind(this, 'si')} value='si' checked={false} label="Si" name="possRivalsa" />
                                                <RadioButtonComponent id='possibilitaRivalsaNo' onChange={this.possibilitaRivalsaSiNoRinuncia.bind(this, 'no')} value='no' label="No" name="possRivalsa" />
                                                <RadioButtonComponent id='possibilitaRivalsaRinuncia' onChange={this.possibilitaRivalsaSiNoRinuncia.bind(this, 'rinuncia')} value='rinuncia' label="Rinuncia" name="possRivalsa" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <DropDownListComponent id='motivoRivalsa' fields={this.ddlFields} dataSource={this.dsMotiviRivalsa}
                                            allowFiltering={false} filterType='Contains' showClearButton={true} 
                                            placeholder="Motivo Rivalsa" floatLabelType='always' cssClass='e-outline'>
                                        </DropDownListComponent>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <TextBoxComponent id='cognomeDebitoreRivalsa' floatLabelType='Auto' placeholder='Cognome Debitore' cssClass='e-outline'></TextBoxComponent>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <TextBoxComponent id='nomeDebitoreRivalsa' floatLabelType='Auto' placeholder='Nome Debitore' cssClass='e-outline'></TextBoxComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentRiserva() {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h4>Note Riserva</h4>
                                            <RichTextEditorComponent id='noteRiserva' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent id="eliminaNoteRiserva" style={{ marginTop: '50px' }} onClick={() => { document.getElementById('noteRiserva').ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Multiple", showCheckbox: true }} id='riservaList' dataSource={this.riservaData} >
                                                <Inject services={[CheckBoxSelection]} />
                                            </ListBoxComponent>
                                            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                                <ButtonComponent id="btnGeneraDescrizioneRiserva" onClick={this.generaDescrizioneRiserva.bind(this)} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Genera Descrizione</ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    // contentDefinizione(args) {
    //     return <div>
    //         <div className="control-section card-control-section basic_card_layout" >
    //             <div className="e-card-resize-container">
    //                 <div className="row card-layout">
    //                     <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
    //                         <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
    //                             <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
    //                                 <div className='row'>
    //                                     <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">

    //                                          <h4>Note Definizione</h4>
    //                                         <RichTextEditorComponent id='noteDefinizione' showCharCount={true} enableHtmlEncode={true} placeholder=''
    //                                             maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
    //                                             <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
    //                                         </RichTextEditorComponent>
    //                                         <ButtonComponent id="eliminaNoteDefinizione" style={{ marginTop: '20px' }} onClick={() => { document.getElementById('noteDefinizione').ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>

    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
    //                         <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
    //                             <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
    //                                 <div className='row'>
    //                                     <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
    //                                         <ButtonComponent id="btnDefinizioneDefault" onClick={this.generaDescrizioneDefinizione.bind(this, 'default')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Default</ButtonComponent>
    //                                     </div>
    //                                     <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
    //                                         <ButtonComponent id="btnDefinizioneAttesaAtto" onClick={this.generaDescrizioneDefinizione.bind(this, 'attesaAtto')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>In Attesa di Atto</ButtonComponent>
    //                                     </div>
    //                                     <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
    //                                         <ButtonComponent id="btnDefinizioneAccordoVerbale" onClick={this.generaDescrizioneDefinizione.bind(this, 'accordoVerbale')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Accordo Verbale</ButtonComponent>
    //                                     </div>
    //                                     <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
    //                                         <ButtonComponent id="btnDefinizioneAtto" onClick={this.generaDescrizioneDefinizione.bind(this, 'atto')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Atto</ButtonComponent>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>;
    // }
    contentRiservatoCompagnia(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h4>Note Riservate Alla Compagnia</h4>
                                            <RichTextEditorComponent id='noteRiservateCompagnia' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent id="btnNoteRiservateCompagnia" style={{ marginTop: '20px' }} onClick={() => { document.getElementById('noteRiservateCompagnia').ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;

    }
    //----------------------------------------CONTENT ACCORDION FINE ----------------------------------------

    render() {
        return (<React.Fragment>
            <div id='blockUIGenerico' className='blockUIPerizia' style={{ display: ' none' }}></div>
            <div id='spinnerUIGenerico' style={{ display: 'none' }} role="status"></div>
            <BlockUi id='blockUIPaginaPerizia' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <DialogComponent id="dialogElaboratoPeritale" showCloseIcon={true} animationSettings={this.animationSettings} visible={false}
                    ref={dialog => this.dialogElaboratoInstance = dialog} isModal={true}>
                    <div id='containerDialogElaboratoPeritale'></div>
                </DialogComponent>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <form id="formElaboratoPeritale">
                    <div className='container-fluid containerPeriziaElab1'>
                        <div className='idSinistroIncaricoElab'>
                            <p title='Sinistro importato' className='importatoGen' id='sinistroImportatoGen'></p>
                            <h4  >ID Sinistro : {this.props.IdSinistro}</h4>
                            <h4 id='idIncaricoTesta'>ID Incarico : {this.props.IdIncarico}</h4>
                            <h4 id='compagniaTesta'>Compagnia : {this.nomeCompagnia}</h4>
                        </div>
                        <div className='row' style={{ marginTop: '50px' }}>
                            <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3 text-start' >
                                <ButtonComponent style={{ marginLeft: '15px' }} id="btnPeriziaZeroCalcoliUndo" onClick={this.ritiraPeriziaSenzaCalcoli.bind(this)} title='Ritorna alla perizia con calcoli' cssClass='e-fill e-info' type='button'><i className="fas fa-undo"></i></ButtonComponent>
                                <ButtonComponent style={{ marginLeft: '15px' }} id="btnPeriziaZeroCalcoli" onClick={this.abilitaPeriziaSenzaCalcoli.bind(this, false)} title='Perizia senza calcoli' cssClass='e-fill e-warning' type='button'>PERIZIA SENZA CALCOLI</ButtonComponent>
                            </div>
                            <div className='col-lg-5 col-md-5 col-sm-5 col-xs-5'></div>
                            <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2 text-end'>
                                <ButtonComponent id="btnModificaPerizia" onClick={this.modificaPerizia.bind(this)} title='Modifica Partite/Garanzie/Stime' cssClass='e-fill e-info' type='button'>MODIFICA PERIZIA</ButtonComponent>
                            </div>
                            <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2 text-end' >
                                <ButtonComponent style={{ marginRight: '30px' }} id="btnRipristinaPerizia" onClick={this.ripristinaPerizia.bind(this)} title='Cancella tutti i calcoli della Perizia e Sblocca Aggiungi Partite/Garanzie e Stime' cssClass='e-fill e-danger' type='button'>RIPRISTINA PERIZIA</ButtonComponent>
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: '-85px', marginLeft: '10px' }}>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ marginTop: '100px', width: '97.77%', padding: '10px', textAlign: 'center', backgroundColor: '#edf0fa', borderRadius: '15px', border: '1px solid #bad0e3', alignItems: 'center' }}>
                                <DropDownListComponent id={`tipoServizio`} fields={this.ddlFields} dataSource={this.dsTipoServizio}
                                    allowFiltering={true} filterType='Contains' showClearButton={true} placeholder="Servizio Eseguito"
                                    floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                </DropDownListComponent>
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: '-85px' }}>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ marginTop: '100px', width: '98.5%' }}>
                                <AccordionComponent ref={acrdn => this.acrdnInstance0 = acrdn}  >
                                    <AccordionItemsDirective >
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>PARTITE</h5>' content={this.contentPartite.bind(this)} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>GARANZIE</h5>' content={this.contentGaranzie.bind(this)} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>TABELLE DI STIMA</h5>' content={this.contentStime.bind(this)} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>RISCHIO ASSICURATO</h5>' content={this.contentRischioAssicurato.bind(this)} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>IL SINISTRO E LE SUE MODALITÀ</h5>' content={this.contentSinistroModalita.bind(this)} />
                                        <AccordionItemDirective id="mezziDiChiusura" style={{ backgroundColor: '#edf0fa', display: 'none' }} cssClass='e-outline' header='<h5>MEZZI DI CHIUSURA</h5>' content={this.contentMezziChiusura.bind(this)} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>OPERAZIONI PERITALI</h5>' content={this.contentOperazioniPeritali.bind(this)} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>RIVALSA</h5>' content={this.contentRivalsa.bind(this)} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>RISERVA</h5>' content={this.contentRiserva.bind(this)} />
                                        {/* <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>LA DEFINIZIONE</h5>' content={this.contentDefinizione.bind(this)} /> */}
                                        <AccordionItemDirective id='riservatoCompagnia' style={{ backgroundColor: '#edf0fa', display: 'none' }} cssClass='e-outline' header='<h5>NOTE RISERVATE COMPAGNIA</h5>' content={this.contentRiservatoCompagnia.bind(this)} />
                                    </AccordionItemsDirective>
                                </AccordionComponent>
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: '20px', marginRight: '3vw' }}>
                            <div className="col-xs-8 col-sm-8 offset-lg-10 col-lg-2 col-md-12">
                            </div>
                        </div>
                        <div className='row' style={{ marginRight: '95%' }}>
                            <div className="col-xs-12 col-sm-12 offset-lg-10 col-lg-2 col-md-12">
                            </div>
                        </div>
                    </div>
                </form>
                <MDBFooter id='footerElaboratoP1' bgColor='light' className='fixed-bottom ' style={{ marginLeft: '65px', zIndex: 10 }}>
                    <div className='row' style={{ backgroundColor: 'rgb(239, 239, 239)', borderTop: '1px solid #202351' }}>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-start">
                            <ButtonComponent id='paginaDettaglioFromPO1' style={{ marginLeft: '5%' }} alt='Torna al Dettaglio Sinistro' cssClass='e-fill e-info' type='button' onClick={this.paginaDettaglioSinistro.bind(this)}>← Dettaglio Sinistro</ButtonComponent>
                        </div>

                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-center">
                            <h3 id='statoNonModificabilePeritoP1' style={{ color: 'red' }}></h3>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                            <ButtonComponent id='tabelleCalcoloDaPaerizia1' alt='Tabelle di Calcolo' cssClass='e-fill e-info' type='button' onClick={this.paginaPeriziaOnlineCalcoli.bind(this)}>Elaborato Calcoli →</ButtonComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                            <ButtonComponent id='salvaEProcedi' style={{ marginRight: '5%' }} title='Salva tutto e Procedi alle Tabelle di Calcolo' cssClass='e-fill e-success' type='button' onClick={this.requiredElaBoratoPeritale.bind(this)}>Salva e Procedi →</ButtonComponent>
                        </div>
                    </div>
                </MDBFooter>
            </BlockUi>
        </React.Fragment>);
    }

    //-------------------------------GESTIONE CARICAMENTO INIZIO-------------------------------

    sinistroChiuso() {
        document.getElementById('statoNonModificabilePeritoP1').innerText = "Sinistro non modificabile!"
        document.getElementById(`btnRipristinaPerizia`).ej2_instances[0].disabled = true;
        document.getElementById(`btnModificaPerizia`).ej2_instances[0].disabled = true;

        document.getElementById(`btnAggiungiPartita`).ej2_instances[0].disabled = true;
        document.getElementById(`btnAggiungiGaranzia`).ej2_instances[0].disabled = true;
        document.getElementById(`btnAggiungiStima`).ej2_instances[0].disabled = true;
        document.getElementById(`salvaEProcedi`).ej2_instances[0].disabled = true;


        for (let p = 0; p < this.numComboPartite; p++) {
            if (document.getElementById(`Partita${p}`)) {
                document.getElementById(`aggiungiPartita${p}`).ej2_instances[0].disabled = true
                document.getElementById(`eliminaPartita${p}`).ej2_instances[0].disabled = true
            }
        }
        for (let i = 0; i < this.numComboGaranzie; i++) {
            if (document.getElementById(`Garanzia${i}`)) {
                //SBLOCCO GARANZIE
                document.getElementById(`aggiungiGaranzia${i}`).ej2_instances[0].disabled = true
                document.getElementById(`eliminaGaranzia${i}`).ej2_instances[0].disabled = true
            }
        }
        for (let i = 0; i < this.numComboStime; i++) {
            if (document.getElementById(`DanneggiatoTabellaStima${i}`)) {
                //SBLOCCO TABELLE STIMA
                document.getElementById(`btnSwitchDanneggiato${i}`).ej2_instances[0].disabled = true
                document.getElementById(`aggiungiStima${i}`).ej2_instances[0].disabled = true
                document.getElementById(`eliminaStima${i}`).ej2_instances[0].disabled = true

            }
        }
    }

    componentDidMount() {
        document.getElementById('titoloPagina').innerText = `Elaborato Peritale ${this.props.IdSinistro}`;
        document.getElementById('paginaDettaglioFromPO1').ej2_instances[0].disabled = true;
        document.getElementById('tabelleCalcoloDaPaerizia1').ej2_instances[0].disabled = true;
        document.getElementById('salvaEProcedi').ej2_instances[0].disabled = true;
        setTimeout(() => {
            this.collapseAccordionDatiSin()
            this.nuovaCaricaDati();
        }, 125)
    }
    collapseAccordionDatiSin() {
        this.acrdnInstance0.expandItem(true, 0);
        this.acrdnInstance0.expandItem(true, 1);
        this.acrdnInstance0.expandItem(true, 2);
        this.acrdnInstance0.expandItem(true, 3);
        this.acrdnInstance0.expandItem(true, 4);
        this.acrdnInstance0.expandItem(true, 5);
        this.acrdnInstance0.expandItem(true, 6);
        this.acrdnInstance0.expandItem(true, 7);
        this.acrdnInstance0.expandItem(true, 8);
        this.acrdnInstance0.expandItem(true, 9);
    }


    async nuovaCaricaDati() {
        let tempoAttesa = 0;
        let tempoOne = 200;

        this.toggleBlocking2(true);

        //RISERVATO ALLE COMPAGNIE ACCORDIO SI/NO
        if (this.isCompagniaUnipol())
            document.getElementById('riservatoCompagnia').style.removeProperty('display')
        else
            document.getElementById('riservatoCompagnia').style.display = 'none';
        //INFO INCARICO INTESTAZIONE

        let QueryExecIncarico = `SELECT T1.data_sop,T1.data_inc_stu, T1.sin_acc,T1.sin_num,T2.contraente,T2.nome as nome_contr,T2.cognome as cognome_contr, T3.id_incarico as id_incarico_generali, T4.id_incarico as id_incarico_unipol,T5.id_sinistro_xpert as id_sinistro_unipol_perizia,T6.id_sinistro_xpert as id_sinistro_unipol_osservazioni,T7.* FROM acsinistri T1 left outer join accontraenti T2 on T1.id_contraente = T2.id_contraente left outer join GENERALI_Incarico T3 on T1.id_sinistro = T3.id_sinistro_xpert left outer join UNIPOLSAI_ListaIncarichi T4 on T1.id_sinistro = T4.id_sinistro left outer join UNIPOLSAI_Perizia T5 on T1.id_sinistro = T5.id_sinistro_xpert left outer join UNIPOLSAI_Osservazioni T6 on T1.id_sinistro = T6.id_sinistro_xpert left outer join _perizia_incarico_info T7 on T1.id_sinistro = T7.id_sinistro WHERE T1.id_sinistro = '${this.props.IdSinistro}';`;
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIncarico, '', retDataIncarico => {
            retDataIncarico = JSON.parse(retDataIncarico.data);
            tempoAttesa += tempoOne;
            this.datiPerNote.dataSopralluogo = CustomJS.isNullEmpty(retDataIncarico[0].data_sop) ? '' : (moment(retDataIncarico[0].data_sop).format('DD/MM/YY'))
            this.datiPerNote.dataAccadimento = CustomJS.isNullEmpty(retDataIncarico[0].sin_acc) ? '' : (moment(retDataIncarico[0].sin_acc).format('DD/MM/YY'))
            this.datiPerNote.totaleRiepilogo = CustomJS.isNullEmpty(retDataIncarico[0].tot_indennizzo_netto_compl) ? '0' : (retDataIncarico[0].tot_indennizzo_netto_compl)
            this.datiPerNote.nomeContraente = CustomJS.isNullEmpty(retDataIncarico[0].contraente) ? (retDataIncarico[0].nome_contr + ' ' + retDataIncarico[0].cognome_contr) : retDataIncarico[0].contraente;

            if (this.isCompagniaUnipol()) {
                //Perizia
                if (CustomJS.isNullEmpty(retDataIncarico[0].id_sinistro_unipol_perizia) || retDataIncarico[0].id_sinistro_unipol_perizia === 0)
                    this.statoInfoElaboratoPeritale.statoUnipolPerizia = 'n'
                else
                    this.statoInfoElaboratoPeritale.statoUnipolPerizia = 'o'
                //Osservazioni
                if (CustomJS.isNullEmpty(retDataIncarico[0].id_sinistro_unipol_osservazioni) || retDataIncarico[0].id_sinistro_unipol_osservazioni === 0)
                    this.statoInfoElaboratoPeritale.statoUnipolOsservazioni = 'n'
                else
                    this.statoInfoElaboratoPeritale.statoUnipolOsservazioni = 'o'

                document.getElementById('idIncaricoTesta').innerHTML = CustomJS.isNullEmpty(this.props.IdIncaricoUnipol) ? '' : ('ID Incarico : ' + this.props.IdIncaricoUnipol);
            }
            else if (this.isCompagniaGenerali()) {
                document.getElementById('idIncaricoTesta').innerHTML = CustomJS.isNullEmpty(this.props.IdIncarico) ? '' : ('ID Incarico : ' + this.props.IdIncarico);
                document.getElementById('sinistroImportatoGen').innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
            }
            // retDataIncarico[0].id_sinistro = E' riferito all' id_sinistro della tabella _perizia_incarico_info del db, se è vuoto bisogna effettuare la insert (stato Nuovo) altrimenti l' update (stato Modifica)
            //Se l' id_sinistro è vuoto sul db essendo un tipo INT nella retData è zero (0)
            if (retDataIncarico[0].id_sinistro === 0) {
                this.statoInfoElaboratoPeritale.statoInfo = 'Nuovo';
                setTimeout(() => {
                    this.valoriPredefinitiCaricamentoPagina();
                }, 150)
            }
            else {
                this.statoInfoElaboratoPeritale.statoInfo = 'Modifica';
                this.statoInfoElaboratoPeritale.key = retDataIncarico[0].id_sinistro;

                document.getElementById('tipoServizio').ej2_instances[0].value = retDataIncarico[0].servizio_eseguito;
                document.getElementById('noteRischioAssicuratoFabbricato').ej2_instances[0].value = retDataIncarico[0].RA_note_fabbr;
                if (retDataIncarico[0].RA_rischio_fabbr)
                    document.getElementById('rischioFabbricatoConforme').ej2_instances[0].checked = true;
                else
                    document.getElementById('rischioFabbricatoNonConforme').ej2_instances[0].checked = true;
                document.getElementById('noteRischioNoConforme').ej2_instances[0].value = retDataIncarico[0].RA_note_fabbr_no_conforme;
                document.getElementById('noteRischioAssicuratoFotovoltaico').ej2_instances[0].value = retDataIncarico[0].RA_note_fotov;
                if (retDataIncarico[0].RA_rischio_fotov)
                    document.getElementById('rischioFotovConforme').ej2_instances[0].checked = true;
                else
                    document.getElementById('rischioFotovoltaicoNonConforme').ej2_instances[0].checked = true;
                if (retDataIncarico[0].RA_monitorato_fotov)
                    document.getElementById('monitoratoFotovSi').ej2_instances[0].checked = true;
                else
                    document.getElementById('monitoratoFotovNo').ej2_instances[0].checked = true;
                document.getElementById('noteSinistroModalita').ej2_instances[0].value = retDataIncarico[0].SM_note;
                if (!CustomJS.isNullEmpty(retDataIncarico[0].MC_note)) {
                    document.getElementById('mezziDiChiusura').style.removeProperty('display');
                    document.getElementById('noteMezziChiusura').ej2_instances[0].value = retDataIncarico[0].MC_note;
                    if (retDataIncarico[0].MC_mezzi_chiusura === 1)
                        document.getElementById('pienamenteConformi').ej2_instances[0].checked = true;
                    else if (retDataIncarico[0].MC_mezzi_chiusura === 2)
                        document.getElementById('parzialmenteConformi').ej2_instances[0].checked = true;
                    else if (retDataIncarico[0].MC_mezzi_chiusura === 3) {
                        document.getElementById('containerNoteMotivazioniMezziChiusura').style.removeProperty('display')
                        document.getElementById('nonConformi').ej2_instances[0].checked = true;
                        document.getElementById('noteMotivazioniMezziChiusura').ej2_instances[0].value = retDataIncarico[0].MC_motivazioni;
                    }
                }
                document.getElementById('noteOperazioniPeritali').ej2_instances[0].value = retDataIncarico[0].OP_note;
                if (retDataIncarico[0].R_possibilita_rivalsa === 1)
                    document.getElementById('possibilitaRivalsaSi').ej2_instances[0].checked = true;
                else if (retDataIncarico[0].R_possibilita_rivalsa === 2)
                    document.getElementById('possibilitaRivalsaRinuncia').ej2_instances[0].checked = true;
                else
                    document.getElementById('possibilitaRivalsaNo').ej2_instances[0].checked = true;
                document.getElementById('motivoRivalsa').ej2_instances[0].value = retDataIncarico[0].R_motivo_rivalsa;
                document.getElementById('cognomeDebitoreRivalsa').ej2_instances[0].value = retDataIncarico[0].R_cognome_debitore;
                document.getElementById('nomeDebitoreRivalsa').ej2_instances[0].value = retDataIncarico[0].R_nome_debitore;
                document.getElementById('noteRivalsa').ej2_instances[0].value = retDataIncarico[0].R_note;
                document.getElementById('noteRiserva').ej2_instances[0].value = retDataIncarico[0].Ris_note;
                document.getElementById('condizioneRischio').ej2_instances[0].value = [retDataIncarico[0].condizioneRischioCodice];
                if (document.getElementById('possibilitaRivalsaSi').ej2_instances[0].checked === true) {
                    this.formOptions.rules[`motivoRivalsa`] = {
                        required: [true, "Motivo Rivalsa è un campo obbligatorio!"]
                    };
                    this.formOptions.rules[`cognomeDebitoreRivalsa`] = {
                        required: [true, "Cognome Debitore è un campo obbligatorio!"]
                    }
                    this.formOptions.rules[`nomeDebitoreRivalsa`] = {
                        required: [true, "Nome Debitore è un campo obbligatorio!"]
                    }    
                }
                else if (document.getElementById('possibilitaRivalsaNo').ej2_instances[0].checked === true 
                         || document.getElementById('possibilitaRivalsaRinuncia').ej2_instances[0].checked === true) {
                        document.getElementById('motivoRivalsa').ej2_instances[0].enabled = false;
                        document.getElementById('cognomeDebitoreRivalsa').ej2_instances[0].enabled = false;
                        document.getElementById('nomeDebitoreRivalsa').ej2_instances[0].enabled = false;             
                }
            }
            //FACCIO 3 QUERY ANNIDATE : PARTITE > GARANZIE > STIME
            //PARTITE
            const QueryExecPartite = `SELECT distinct T1.id_partitaPeriziaOnline, T1.id_partita as cod_ele_partita, T1.formaGaranzia, T1.sommaAssicurataPartita, T1.descrizione,T1.guid_partita_temp, T2.* FROM _partitePeriziaOnline T1 left outer join GENERALI_polizza_partite T2 on T1.id_partitaPeriziaOnline = T2.id_partita_xpert WHERE T1.id_sinistro = '${this.props.IdSinistro}';`;
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecPartite, '', retDataPartite => {
                retDataPartite = JSON.parse(retDataPartite.data);
                for (let index = 0; index < retDataPartite.length; index++) {
                    this.strutturaStatiEliminaDati.idPartite.push({
                        keyXpert: retDataPartite[index].id_partitaPeriziaOnline,
                        keyGen: retDataPartite[index].id_partita
                    })
                }

                //ordino per partita importata gen
                if (this.isCompagniaGenerali()) {
                    let ordinaPartite = []
                    retDataPartite.forEach(part => {
                        if (part.importato === true || part.importato === 1)
                            ordinaPartite.unshift(part)
                        else
                            ordinaPartite.push(part)
                    })
                    retDataPartite = ordinaPartite;
                }
                if (retDataPartite.length > 0) {
                    for (let p = 0; p < retDataPartite.length; p++) {
                        this.aggiungiPartita(retDataPartite[p], '')
                    }
                    document.getElementById(`btnAggiungiPartita`).ej2_instances[0].disabled = true;
                    tempoAttesa += tempoOne * retDataPartite.length;
                }
                else {
                    //CASO INIZIALE
                    //Se il sinistro è Generali e la retDataPartite è vuota allora non è ancora stato inserito nulla sulla _partitePeriziaOnline, quindi estraggo solo i dati dalla GENERALI_polizza_partite inviati da Generali
                    if (this.isCompagniaGenerali()) {
                        const QueryExecPartiteGenerali = (`SELECT id_partita,tipoPartita_codice,tipoPartita_descrizione,importato,valore_assicurato FROM GENERALI_polizza_partite WHERE id_incarico = '${this.props.IdIncarico}';`);
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecPartiteGenerali, '', retDataPartiteGen => {
                            retDataPartiteGen = JSON.parse(retDataPartiteGen.data);
                            //ordino per partita importata
                            let partiteOrdinate = []
                            retDataPartiteGen.forEach(part => {
                                if (part.importato === true || part.importato === 1)
                                    partiteOrdinate.unshift(part)
                                else
                                    partiteOrdinate.push(part)
                            })
                            retDataPartiteGen = partiteOrdinate;
                            for (let pGen = 0; pGen < retDataPartiteGen.length; pGen++) {
                                this.aggiungiPartita(retDataPartiteGen[pGen], 'casoIniziale')
                            }
                            tempoAttesa += tempoOne * retDataPartiteGen.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                    }
                    else {
                        tempoAttesa += tempoOne;
                        this.aggiungiPartita('', '');
                    }
                }
                //GARANZIE > STIME
                //GARANZIE
                const QueryExecGaranzie = `SELECT distinct T1.id_garanziaPeriziaOnline,T1.id_garanzia as cod_ele_garanzia,T1.id_determinazioneDanno,T1.franchigiaCondivisa,T1.limiteIndennizzoEuro,T1.limiteIndennizzoGiorni,T1.limitePer,T1.franchigiaEuro,T1.franchigiaGiorni,T1.scopertoPerc,T1.scopertoMax,T1.scopertoMin,T1.guid_garanzia_temp,T3.* FROM _garanziePeriziaOnline T1 left outer join  GENERALI_polizza_garanzie T3 on T1.id_garanziaPeriziaOnline = T3.id_garanzia_xpert WHERE id_sinistro = '${this.props.IdSinistro}';`;
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecGaranzie, '', retDataGaranzieZero => {
                    retDataGaranzieZero = JSON.parse(retDataGaranzieZero.data);
                    for (let index = 0; index < retDataGaranzieZero.length; index++) {
                        this.strutturaStatiEliminaDati.idGaranzie.push({
                            keyXpert: retDataGaranzieZero[index].id_garanziaPeriziaOnline,
                            keyGen: retDataGaranzieZero[index].id_garanzia
                        })
                    }
                    //ordino per franchigia condivisa
                    let retDataGaranzie = []
                    retDataGaranzieZero.forEach(elm => {
                        if (CustomJS.isNullEmpty(elm.franchigiaCondivisa))
                            // se la franchigia non c'è metto l' elemento all' inizio dell' array
                            retDataGaranzie.unshift(elm)
                        else
                            //se c'è lo metto alla fine 
                            retDataGaranzie.push(elm)
                    });
                    //ordino per garanzia importata gen
                    if (this.isCompagniaGenerali()) {
                        let ordinaGaranzie = []
                        retDataGaranzie.forEach(gar => {
                            if (gar.importato === true || gar.importato === 1)
                                ordinaGaranzie.unshift(gar)
                            else
                                ordinaGaranzie.push(gar)
                        })
                        retDataGaranzie = ordinaGaranzie;
                    }
                    if (retDataGaranzie.length > 0) {
                        for (let g = 0; g < retDataGaranzie.length; g++) {
                            this.aggiungiGaranzia(retDataGaranzie[g], '')
                        }
                        document.getElementById(`btnAggiungiGaranzia`).ej2_instances[0].disabled = true;
                        tempoAttesa += tempoOne * retDataGaranzie.length;
                    }
                    else {
                        //CASO INIZIALE
                        //Se il sinistro è Generali e la retDataGaranzie è vuota allora non è ancora stato inserito nulla sulla _garanziePeriziaOnline, quindi estraggo solo i dati dalla GENERALI_polizza_garanzie inviati da Generali
                        if (this.isCompagniaGenerali()) {
                            const QueryExecGaranzieGenerali = `SELECT id_garanzia,tipoGaranzia_codice,tipoGaranzia_descrizione,importato,formaAssicurazione_codice,formulaAssicurazione_codice,partita_assicurata,gruppo_garanzia FROM GENERALI_polizza_garanzie WHERE id_incarico = '${this.props.IdIncarico}';`;
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecGaranzieGenerali, '', retDataGaranzieGen => {
                                retDataGaranzieGen = JSON.parse(retDataGaranzieGen.data);
                                //ordino per garanzia importata
                                let garanzieOrdinate = []
                                retDataGaranzieGen.forEach(gar => {
                                    if (gar.importato === true || gar.importato === 1)
                                        garanzieOrdinate.unshift(gar)
                                    else
                                        garanzieOrdinate.push(gar)
                                })
                                retDataGaranzieGen = garanzieOrdinate;
                                for (let gGen = 0; gGen < retDataGaranzieGen.length; gGen++) {
                                    this.aggiungiGaranzia(retDataGaranzieGen[gGen], 'casoIniziale')
                                }
                                tempoAttesa += tempoOne * retDataGaranzieGen.length;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });
                        }
                        else {
                            tempoAttesa += tempoOne;
                            this.aggiungiGaranzia('', '')
                        }
                    }
                    //STIME (ultima query)
                    //STIME/DANNI + DANNEGGIATI
                    const QueryExecTabStima = `SELECT distinct T1.id_stima,T1.id_danneggiato,T1.id_partita,T1.id_garanzia,T2.id_danno,T2.id_partita_colpita,T2.id_garanzia_colpita,T2.id_danneggiato as id_danneggiato_colpito,T3.danneggiato,T3.nome,T3.cognome,T4.guid_partita_temp,T4.id_partita as cod_ele_partita,T4.descrizione,T5.guid_garanzia_temp,T5.id_garanzia as cod_ele_garanzia,T6.importato FROM _stime_xpert T1 left outer join GENERALI_danni T2 on T1.id_stima = T2.id_stima_xpert left outer join acdanneggiati T3 on T1.id_danneggiato = T3.id_danneggiato left outer join _partitePeriziaOnline T4 on T1.id_partita = T4.id_partitaPeriziaOnline left outer join _garanziePeriziaOnline T5 on T1.id_garanzia = T5.id_garanziaPeriziaOnline left outer join GENERALI_danneggiati T6 on T2.id_danneggiato = T6.id_danneggiato WHERE T1.id_sinistro = ${this.props.IdSinistro};`;
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecTabStima, '', retDataStime => {
                        retDataStime = JSON.parse(retDataStime.data);
                        for (let index = 0; index < retDataStime.length; index++) {
                            this.strutturaStatiEliminaDati.idStime.push({
                                keyXpert: retDataStime[index].id_stima,
                                keyGen: retDataStime[index].id_danno
                            })
                        }
                        //anche se ci sono le stime carico comunque tutti i danneggiati nel datasource nel caso uno volesse rifare le stime
                        if (retDataStime.length > 0) {
                            this.statoInfoElaboratoPeritale.statoStime = 'Esistenti';
                            const QueryExecTabDanneggiati = `SELECT distinct T1.id_danneggiato as id_danneggiato_xpert,T1.danneggiato,T1.nome,T1.cognome, T2.id_danneggiato as guid_danneggiato,T3.id_liquidazione FROM acdanneggiati T1 left outer join  GENERALI_danneggiati T2 on T1.id_danneggiato = T2.id_danneggiato_xpert left outer join GENERALI_danneggiato_liquidazioni T3 on T2.id_danneggiato = T3.id_danneggiato WHERE T1.id_sinistro = '${this.props.IdSinistro}';`;
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecTabDanneggiati, '', retDataDann => {
                                retDataDann = JSON.parse(retDataDann.data);
                                console.log(retDataDann) // fare attenzione al numero di liquidazioni!
                                //carico danneggiati sul ds danneggiati
                                for (let d = 0; d < retDataDann.length; d++) {
                                    let objDanneggiato = {
                                        id_danneggiato: retDataDann[d].id_danneggiato_xpert,
                                        danneggiato: CustomJS.isNullEmpty(retDataDann[d].danneggiato) ? retDataDann[d].nome + ' ' + retDataDann[d].cognome : retDataDann[d].danneggiato,
                                        guid_danneggiato: retDataDann[d].guid_danneggiato
                                    }
                                    this.danneggiati.push(objDanneggiato);

                                    this.strutturaStatiEliminaDati.idDanneggiatiGen.push({
                                        keyXpert: retDataDann[d].id_danneggiato_xpert,
                                        keyGen: retDataDann[d].guid_danneggiato,
                                        keyGenLiquidazione: retDataDann[d].id_liquidazione
                                    })
                                }
                                const unique2 = this.danneggiati.filter((obj, index) => {
                                    return index === this.danneggiati.findIndex(o => obj.id_danneggiato === o.id_danneggiato && obj.danneggiato === o.danneggiato && obj.guid_danneggiato === o.guid_danneggiato);
                                });

                                this.danneggiati = unique2;
                                sessionStorage.setItem('JellyfishSIPAEvoDanneggiatiPerizia', JSON.stringify(this.danneggiati));
                                this.dsDanneggiati = JSON.parse(sessionStorage.getItem('JellyfishSIPAEvoDanneggiatiPerizia')).map(elm => { return { VALUE: elm.id_danneggiato, TEXT: elm.danneggiato, GUID: elm.guid_danneggiato } });

                                //ordino per danneggiato importato da Generali
                                if (this.isCompagniaGenerali()) {
                                    let ordinaStime = []
                                    retDataStime.forEach(stima => {
                                        if (stima.importato === true || stima.importato === 1)
                                            ordinaStime.unshift(stima)
                                        else
                                            ordinaStime.push(stima)
                                    })
                                    retDataStime = ordinaStime;
                                }
                                for (let i = 0; i < retDataStime.length; i++) {
                                    this.aggiungiStima(retDataStime[i], '', false)
                                }
                                document.getElementById(`btnAggiungiStima`).ej2_instances[0].disabled = true;
                                tempoAttesa += tempoOne * retDataDann.length;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });
                            tempoAttesa += tempoOne * retDataStime.length;
                        }
                        else {
                            this.statoInfoElaboratoPeritale.statoStime = 'Nessuna';
                            //SE LE STIME PER QUESTO SINISTRO NON ESISTONO CARICO I DANNEGGIATI
                            //DANNEGGIATI
                            const QueryExecDanneggiati = (`SELECT distinct T1.id_danneggiato as id_danneggiato_xpert,T1.danneggiato,T1.nome,T1.cognome, T2.id_danneggiato as guid_danneggiato, T2.importato FROM acdanneggiati T1 left outer join  GENERALI_danneggiati T2 on T1.id_danneggiato = T2.id_danneggiato_xpert WHERE T1.id_sinistro = '${this.props.IdSinistro}';`);
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecDanneggiati, '', retDataDanneggiati => {
                                retDataDanneggiati = JSON.parse(retDataDanneggiati.data);
                                //ordino i danneggiati mettendo prima quelli importati da Generali dopo quelli non importati
                                if (this.isCompagniaGenerali()) {
                                    let ordinaDanneggiati = []
                                    retDataDanneggiati.forEach(dann => {
                                        if (dann.importato === true || dann.importato === 1)
                                            ordinaDanneggiati.unshift(dann); // lo mette all' inizio dell' array
                                        else
                                            ordinaDanneggiati.push(dann); // lo mette alla fine dell'array
                                    })
                                    retDataDanneggiati = ordinaDanneggiati;
                                }
                                if (retDataDanneggiati.length > 0) {
                                    for (let d = 0; d < retDataDanneggiati.length; d++) {
                                        let objDanneggiato = {
                                            id_danneggiato: retDataDanneggiati[d].id_danneggiato_xpert,
                                            danneggiato: CustomJS.isNullEmpty(retDataDanneggiati[d].danneggiato) ? retDataDanneggiati[d].nome + ' ' + retDataDanneggiati[d].cognome : retDataDanneggiati[d].danneggiato,
                                            guid_danneggiato: retDataDanneggiati[d].guid_danneggiato
                                        }
                                        this.danneggiati.push(objDanneggiato);
                                    }
                                    const unique2 = this.danneggiati.filter((obj, index) => {
                                        return index === this.danneggiati.findIndex(o => obj.id_danneggiato === o.id_danneggiato && obj.danneggiato === o.danneggiato && obj.guid_danneggiato === o.guid_danneggiato);
                                    });
                                    this.danneggiati = unique2;
                                    sessionStorage.setItem('JellyfishSIPAEvoDanneggiatiPerizia', JSON.stringify(this.danneggiati));
                                    this.dsDanneggiati = JSON.parse(sessionStorage.getItem('JellyfishSIPAEvoDanneggiatiPerizia')).map(elm => { return { VALUE: elm.id_danneggiato, TEXT: elm.danneggiato, GUID: elm.guid_danneggiato } });

                                    for (let d = 0; d < retDataDanneggiati.length; d++) {
                                        this.aggiungiStima('', retDataDanneggiati[d], true)
                                    }
                                    tempoAttesa += tempoOne * retDataDanneggiati.length;
                                }
                                //SE NON CI SONO STIME CONTROLLO SE LA PERIZIA E SENZA CALCOLI!
                                setTimeout(() => {
                                    if (retDataIncarico[0].periziaNoCalcoli === true || retDataIncarico[0].periziaNoCalcoli === 1) {
                                        this.abilitaPeriziaSenzaCalcoli(true, '');
                                    }
                                }, 200 * retDataDanneggiati.length)
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });
                        }
                        //DENTRO LE STIME CHE SONO DENTRO LE GARANZIE CARICO LE FRANCHIGE 
                        setTimeout(() => {
                            const QueryExecFranchige = (`SELECT * FROM _franchige_condivise_perizia where id_sinistro = '${this.props.IdSinistro}';`);
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecFranchige, '', retDataFranc => {
                                retDataFranc = JSON.parse(retDataFranc.data);
                                retDataFranc.forEach(elm => {
                                    this.strutturaStatiGaranzie.forEach(garanzia => {
                                        if (document.getElementById(`Garanzia${garanzia.num}`).ej2_instances[0].value === elm.codEleGaranzia) {
                                            if (elm.tipo === 'franchigia') {
                                                document.getElementById(`sceltaFranchigia${garanzia.num}`).innerText = '→';
                                                document.getElementById(`sceltaScoperto${garanzia.num}`).innerText = '';
                                            }
                                            else if (elm.tipo === 'scoperto') {
                                                document.getElementById(`sceltaFranchigia${garanzia.num}`).innerText = '';
                                                document.getElementById(`sceltaScoperto${garanzia.num}`).innerText = '→';
                                            }
                                        }
                                    })
                                })
                                //ID PER L ELIMINAZIONE DELLE FRANCHIGE
                                for (let index = 0; index < retDataFranc.length; index++) {
                                    this.strutturaStatiEliminaDati.idFranchige.push(retDataFranc[index].idFranchigiaCondivisa)
                                }
                                tempoAttesa += tempoOne * retDataFranc.length;
                                console.log(this.strutturaStatiEliminaDati)
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });
                        }, (retDataGaranzieZero.length * 300))

                        //SEMPRE DENTRO IL CALLBACK DELLE STIME CARICO GLI ID PER L EVENTUALE ELIMINAZIONE E IL TIMEOUT PER LA FINE DEL CARICAMENTO
                        //------------------ID PER L EVENTUALE ELIMINAZIONE INIZIO ------------------
                        const QueryExecIdPreesistenza = (`SELECT id_preesistenza,id_partitaPeriziaOnline FROM _preesistenza_xpert where id_sinistro = '${this.props.IdSinistro}'`);
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdPreesistenza, '', retDataIdPrees => {
                            retDataIdPrees = JSON.parse(retDataIdPrees.data);
                            for (let index = 0; index < retDataIdPrees.length; index++) {
                                this.strutturaStatiEliminaDati.idPreesistenzaXpert.push(
                                    { idPrees: retDataIdPrees[index].id_preesistenza, idPartita: retDataIdPrees[index].id_partitaPeriziaOnline }
                                )
                            }
                            tempoAttesa += tempoOne * retDataIdPrees.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                        //ID PREESISTENZA COSTRUZIONI
                        const QueryExecIdPreesCostr = (`SELECT id_preesistenza_costruzione,id_preesistenza FROM _preesistenza_costruzioni where id_sinistro = '${this.props.IdSinistro}'`);
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdPreesCostr, '', retDataIdPreesCostr => {
                            retDataIdPreesCostr = JSON.parse(retDataIdPreesCostr.data);
                            for (let index = 0; index < retDataIdPreesCostr.length; index++) {
                                this.strutturaStatiEliminaDati.idPreesistenzaCostruz.push({
                                    idCostruz: retDataIdPreesCostr[index].id_preesistenza_costruzione,
                                    idPrees: retDataIdPreesCostr[index].id_preesistenza
                                })
                            }
                            tempoAttesa += tempoOne * retDataIdPreesCostr.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                        //ID PREESISTENZA DESCRIZIONI
                        const QueryExecIdPreesDescr = (`SELECT id_preesistenza_descrizione,id_preesistenza FROM _preesistenza_descrizioni where id_sinistro = '${this.props.IdSinistro}'`);
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdPreesDescr, '', retDataIdPreesDescr => {
                            retDataIdPreesDescr = JSON.parse(retDataIdPreesDescr.data);
                            for (let index = 0; index < retDataIdPreesDescr.length; index++) {
                                this.strutturaStatiEliminaDati.idPreesistenzaDescr.push({
                                    idDescr: retDataIdPreesDescr[index].id_preesistenza_descrizione, idPrees: retDataIdPreesDescr[index].id_preesistenza
                                })
                            }
                            tempoAttesa += tempoOne * retDataIdPreesDescr.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                        if (this.isCompagniaGenerali()) {
                            //ID PREESISTENZA GENERALI
                            const QueryExecIdPreesGen = (`SELECT id_preesistenza,id_preesistenza_xpert,id_prees_costruz_xpert FROM GENERALI_partita_preesistenze where id_incarico = '${this.props.IdIncarico}'`);
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdPreesGen, '', retDataIdPreesGen => {
                                retDataIdPreesGen = JSON.parse(retDataIdPreesGen.data);
                                for (let index = 0; index < retDataIdPreesGen.length; index++) {
                                    this.strutturaStatiEliminaDati.idPreesistenzaGen.push({
                                        idGen: retDataIdPreesGen[index].id_preesistenza, idXpert: retDataIdPreesGen[index].id_preesistenza_xpert, idCostruzXpert: retDataIdPreesGen[index].id_prees_costruz_xpert
                                    })
                                }
                                tempoAttesa += tempoOne * retDataIdPreesGen.length;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });
                        }
                        //ID STIME DESCRIZIONI
                        const QueryExecIdStimeDescr = (`SELECT T1.id_stima_descrizione,T1.id_stima,T2.id_dettaglio,T2.id_danno FROM _stime_descrizioni T1 left outer join GENERALI_danno_dettagli T2 on T1.id_stima_descrizione = T2.id_stima_descrizione where id_sinistro = ${this.props.IdSinistro}`);
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdStimeDescr, '', retDataIdStimeDescr => {
                            retDataIdStimeDescr = JSON.parse(retDataIdStimeDescr.data);
                            for (let index = 0; index < retDataIdStimeDescr.length; index++) {
                                this.strutturaStatiEliminaDati.idStimeDescrizioni.push({
                                    keyXpert: retDataIdStimeDescr[index].id_stima_descrizione,
                                    keyStima: retDataIdStimeDescr[index].id_stima,
                                    keyGen: retDataIdStimeDescr[index].id_dettaglio,
                                    keyDanno: retDataIdStimeDescr[index].id_danno
                                })
                            }
                            tempoAttesa += tempoOne * retDataIdStimeDescr.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                        if (this.isCompagniaGenerali()) {
                            //ID GENERALI DANNO DETTAGLI INFO RICHIESTE
                            const QueryExecIdDannoDettInfoRichGen = (`SELECT id_info_richiesta,id_dettaglio FROM GENERALI_dannoDettagli_infoRichieste where id_incarico = '${this.props.IdIncarico}';`);
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdDannoDettInfoRichGen, '', retDataIdDannoDettInfoRichGen => {
                                retDataIdDannoDettInfoRichGen = JSON.parse(retDataIdDannoDettInfoRichGen.data);
                                for (let index = 0; index < retDataIdDannoDettInfoRichGen.length; index++) {
                                    this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.push(
                                        {
                                            idInfo: retDataIdDannoDettInfoRichGen[index].id_info_richiesta,
                                            idDettaglio: retDataIdDannoDettInfoRichGen[index].id_dettaglio
                                        }

                                    )
                                }
                                tempoAttesa += tempoOne * retDataIdDannoDettInfoRichGen.length;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });
                        }
                        //ID COASSICURAZIONI
                        const QueryExecCoass = (`SELECT T1.id_compagnia_coass,T1.id_stima,T2.id_coassi_indiretta,T2.id_danno FROM _righeCompagnieCoassicurazione T1 left outer join GENERALI_coassi_indirette T2 on T1.id_compagnia_coass = T2.id_compagnia_coass_xpert WHERE id_sinistro = ${this.props.IdSinistro};`);
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecCoass, '', retDataCoass => {
                            retDataCoass = JSON.parse(retDataCoass.data);
                            for (let index = 0; index < retDataCoass.length; index++) {
                                this.strutturaStatiEliminaDati.idCoassicurazioni.push({
                                    keyXpert: retDataCoass[index].id_compagnia_coass,
                                    keyStima: retDataCoass[index].id_stima,
                                    keyGen: retDataCoass[index].id_coassi_indiretta,
                                    keyDanno: retDataCoass[index].id_danno
                                })
                            }
                            tempoAttesa += tempoOne * retDataCoass.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                        //ID INFO RICHIESTE
                        const QueryExecInfoRichieste = (`SELECT T1.id_info_richieste_compagnia,T1.id_stima, T2.id_info_richiesta,T2.id_danno FROM _info_richieste_compagnia_riep T1 left outer join GENERALI_danno_infoRichieste T2 on T1.id_info_richieste_compagnia = T2.id_info_rich_xpert where id_sinistro = ${this.props.IdSinistro};`);
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecInfoRichieste, '', retDataInfo => {
                            retDataInfo = JSON.parse(retDataInfo.data);
                            for (let index = 0; index < retDataInfo.length; index++) {
                                this.strutturaStatiEliminaDati.idInfoRichieste.push({
                                    keyXpert: retDataInfo[index].id_info_richieste_compagnia,
                                    keyGen: retDataInfo[index].id_info_richiesta,
                                    idStima: retDataInfo[index].id_stima,
                                    idDanno: retDataInfo[index].id_danno
                                })
                            }
                            tempoAttesa += tempoOne * retDataInfo.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });

                        //ID RIEPILOGO
                        const QueryExecIdRiep = (`SELECT idRiepilogo_perizia FROM _riepilogo_perizia where id_sinistro = ${this.props.IdSinistro};`);
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdRiep, '', retDataIdPerizia => {
                            retDataIdPerizia = JSON.parse(retDataIdPerizia.data);
                            if (retDataIdPerizia.length > 0) {
                                this.strutturaStatiEliminaDati.idRiepilogoPerizia = retDataIdPerizia[0].idRiepilogo_perizia;
                                const QueryExecIdRiepDanneggiati = (`SELECT idDanneggiatoRiep FROM _perizia_riepilogo_danneggiati where id_sinistro = ${this.props.IdSinistro};`);
                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdRiepDanneggiati, '', retDataIdPeriziaDann => {
                                    retDataIdPeriziaDann = JSON.parse(retDataIdPeriziaDann.data);
                                    for (let index = 0; index < retDataIdPeriziaDann.length; index++) {
                                        this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.push(retDataIdPeriziaDann[index].idDanneggiatoRiep)
                                    }
                                    tempoAttesa += tempoOne * retDataIdPeriziaDann.length;
                                    const QueryExecIdRiepRigheDanneggiato = (`SELECT idGarPart FROM _perizia_riep_righeDanneggiato where id_sinistro = ${this.props.IdSinistro};`);
                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdRiepRigheDanneggiato, '', retDataIdPeriziaRigheDann => {
                                        retDataIdPeriziaRigheDann = JSON.parse(retDataIdPeriziaRigheDann.data);
                                        for (let index = 0; index < retDataIdPeriziaRigheDann.length; index++) {
                                            this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.push(retDataIdPeriziaRigheDann[index].idGarPart)
                                        }
                                        tempoAttesa += tempoOne * retDataIdPeriziaRigheDann.length;
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    });
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                });
                            }
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                        //ID CAUSALI NON INDENNIZZO
                        if (this.isCompagniaGenerali()) {
                            const QueryExecIdCausali = (`SELECT id_causale,id_danno FROM GENERALI_danno_causali_nonIndennizzo where id_incarico = '${this.props.IdIncarico}';`);
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdCausali, '', retDataIdCaus => {
                                retDataIdCaus = JSON.parse(retDataIdCaus.data);
                                if (retDataIdCaus.length > 0) {
                                    for (let index = 0; index < retDataIdCaus.length; index++) {
                                        this.strutturaStatiEliminaDati.idCausali.push({
                                            idCausale: retDataIdCaus[index].id_causale,
                                            idDanno: retDataIdCaus[index].id_danno
                                        })
                                    }
                                    tempoAttesa += tempoOne * retDataIdCaus.length;
                                }
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });
                        }
                        //------------------ID PER L EVENTUALE ELIMINAZIONE FINE ------------------
                        setTimeout(() => {
                            console.log("tempo attesa : " + tempoAttesa)
                            //todo da mettere un tempo calcolato
                            document.getElementById('paginaDettaglioFromPO1').ej2_instances[0].disabled = false;
                            if (this.statoInfoElaboratoPeritale.statoStime === 'Nessuna') { //SE NON CI SONO STIME
                                document.getElementById('salvaEProcedi').ej2_instances[0].disabled = false; //posso salvare
                                document.getElementById('tabelleCalcoloDaPaerizia1').ej2_instances[0].disabled = true; // non posso accedere ai calcoli che non ci sono
                                document.getElementById('btnModificaPerizia').ej2_instances[0].disabled = true;
                                if (this.periziaSenzaCalcoli === false) {
                                    document.getElementById('btnPeriziaZeroCalcoli').ej2_instances[0].disabled = false;
                                    document.getElementById('btnPeriziaZeroCalcoliUndo').ej2_instances[0].disabled = false;
                                }
                            }
                            else { //SE CI SONO STIME
                                document.getElementById('salvaEProcedi').ej2_instances[0].disabled = true; //NON POSSO SALVARE A MENO CHE NON CLICCO SUI TASTI MODIFICA E RICOSTRUISCI
                                document.getElementById('tabelleCalcoloDaPaerizia1').ej2_instances[0].disabled = false; //posso accedere ai calcoli
                                document.getElementById('btnModificaPerizia').ej2_instances[0].disabled = false;
                                document.getElementById('btnPeriziaZeroCalcoli').ej2_instances[0].disabled = true;
                                document.getElementById('btnPeriziaZeroCalcoliUndo').ej2_instances[0].disabled = true;
                            }
                            //controllo se il sinistro è chiuso
                            if (this.props.restituito === true) {
                                this.sinistroChiuso();
                            }
                            this.toggleBlocking2(false);
                        }, tempoAttesa)
                        //fine retData stime
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    });
                    //fine retData garanzie
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                });
                //fine retData partite
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            });
            //fine retData incarico
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }
    valoriPredefinitiCaricamentoPagina() {
        //Servizio Eseguito
        document.getElementById('tipoServizio').ej2_instances[0].value = '3';

        //Rischio Assicurato (Fabbricato)
        document.getElementById('tipoAbitazioneRischAss').ej2_instances[0].value = ['condominio'];
        document.getElementById('adibitoList').ej2_instances[0].value = ['totalita'];
        document.getElementById('adibitoAList').ej2_instances[0].value = ['civile-abitazione'];
        document.getElementById('strutturaPortante').ej2_instances[0].value = ['calcestruzzo'];
        document.getElementById('tamponamenti').ej2_instances[0].value = ['laterizio'];
        document.getElementById('ordituraTetto').ej2_instances[0].value = ['calcestruzzo'];
        document.getElementById('copertura').ej2_instances[0].value = ['tegole'];
        document.getElementById('finiture').ej2_instances[0].value = ['standard'];
        if (this.isCompagniaUnipol())
            document.getElementById('condizioneRischio').ej2_instances[0].value = ['Buono'];
        else
            document.getElementById('condizioneRischio').ej2_instances[0].value = ['2'];
        //Rischio Assicurato (Fotovoltaico)
        document.getElementById('impianto').ej2_instances[0].value = ['installato-o-a-terra'];

        //Mezzi di chiusura
        document.getElementById('tipoListMezzChius').ej2_instances[0].value = ['condominio'];
        document.getElementById('porteIngressoLocali').ej2_instances[0].value = ['in-legno-pieno'];
        document.getElementById('tipoFinestreFuoriTerra').ej2_instances[0].value = ['in-legno-pieno'];
        document.getElementById('antisfondamentoONo').ej2_instances[0].value = ['antisfondamento'];
        document.getElementById('tipoFinestreFuoriTerra2').ej2_instances[0].value = ['tapparelle'];
        document.getElementById('tipoFinestreFuoriTerra3').ej2_instances[0].value = ['in-legno'];

        //Rivalsa
        if (this.isCompagniaGenerali())
            document.getElementById('possibilitaRivalsaNo').ej2_instances[0].checked = true

        //Operazioni Peritali 
        document.getElementById('noteOperazioniPeritali').ej2_instances[0].value = `Ricevuto l'incarico, abbiamo conferito con l'Assicurato e fissato un sopralluogo presso l'ubicazione del rischio assicurato per il giorno ${this.datiPerNote.dataSopralluogo}.\n In tale occasione si prendeva visione dei luoghi e si acquisiva la documentazione necessaria all'espletamento delle indagini peritali.`
        //Definizione
        // document.getElementById('noteDefinizione').ej2_instances[0].value = `Al termine degli accertamenti peritali riteniamo che il sinistro rientri nelle garanzie di polizza in quanto non sussistono riserve di operatività.`
    }

    //-------------------------------GESTIONE CARICAMENTO FINE-------------------------------


    //-------------------------------GESTIONE SALVATAGGIO INIZIO-------------------------------




    requiredElaBoratoPeritale() {
        if (this.isCompagniaGenerali()) {
            this.formOptions.rules[`noteSinistroModalita`] = {
                required: [true, "Note Sinistro Modalità è un campo obbligatorio!"]
            }
            this.formOptions.rules[`tipoServizio`] = {
                required: [true, "Servizio Eseguito è un campo obbligatorio!"]
            }
            this.formOptions.rules[`condizioneRischio`] = {
                required: [true, "Condizione Rischio è un campo obbligatorio!"]
            }
        }
        //SE LA PERIZIA HA I I CALCOLI
        if (this.periziaSenzaCalcoli === false) {
            let almenoUnDanneggiatoOmesso = false;
            for (let index = 0; index < this.numComboPartite; index++) {
                if (document.getElementById(`containerComboPartita${index}`)) {
                    this.formOptions.rules[`Partita${index}`] = {
                        required: [true, "Partita è un campo obbligatorio!"]
                    }
                    this.formOptions.rules[`FormaGaranzia${index}`] = {
                        required: [true, "Forma Garanzia è un campo obbligatorio!"]
                    }
                    //CAMPI GENERALI
                    if (this.isCompagniaGenerali()) {
                        this.formOptions.rules[`PartitaRicevuta${index}`] = {
                            required: [true, "Partita Ricevuta è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`SommaAssicurataPartita${index}`] = {
                            required: [true, "Somma Assicurata Partita è un campo obbligatorio!"]
                        }
                    }
                }
            }
            for (let index = 0; index < this.numComboGaranzie; index++) {
                if (document.getElementById(`containerComboGaranzia${index}`)) {
                    this.formOptions.rules[`Garanzia${index}`] = {
                        required: [true, "Garanzia è un campo obbligatorio!"]
                    }
                    this.formOptions.rules[`DeterminazioneDanno${index}`] = {
                        required: [true, "Determinazione Danno è un campo obbligatorio!"]
                    }

                    //CAMPI GENERALI
                    if (this.isCompagniaGenerali()) {
                        this.formOptions.rules[`GaranziaRicevuta${index}`] = {
                            required: [true, "Garanzia Ricevuta è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`GruppoGaranzia${index}`] = {
                            required: [true, "Gruppo Garanzia è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`partitaAssicurata${index}`] = {
                            required: [true, "Partita Assicurata è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`FormulaAssicurazione${index}`] = {
                            required: [true, "Formula Assicurazione è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`FormaAssicurazione${index}`] = {
                            required: [true, "Forma Assicurazione è un campo obbligatorio!"]
                        }
                    }
                }
                else {
                    //Campi da togliere
                    if (this.formOptions.rules[`FranchigiaEuro${index}`])
                        delete this.formOptions.rules[`FranchigiaEuro${index}`];

                    if (this.formOptions.rules[`FranchigiaGiorni${index}`])
                        delete this.formOptions.rules[`FranchigiaGiorni${index}`];

                    if (this.formOptions.rules[`LimiteIndennizzoEuro${index}`])
                        delete this.formOptions.rules[`LimiteIndennizzoEuro${index}`];

                    if (this.formOptions.rules[`ScopertoMassimo${index}`])
                        delete this.formOptions.rules[`ScopertoMassimo${index}`];

                    if (this.formOptions.rules[`ScopertoMinimo${index}`])
                        delete this.formOptions.rules[`ScopertoMinimo${index}`];

                    if (this.formOptions.rules[`ScopertoPerc${index}`])
                        delete this.formOptions.rules[`ScopertoPerc${index}`];

                    if (this.formOptions.rules[`SommaAssicurata${index}`])
                        delete this.formOptions.rules[`SommaAssicurata${index}`];
                }
            }

            for (let index = 0; index < this.numComboStime; index++) {
                if (document.getElementById(`containerComboStima${index}`)) {
                    let danneggiatoObbligatorio = false;
                    if (document.getElementById(`btnSwitchDanneggiato${index}`).ej2_instances[0].children === '+') {
                        if (CustomJS.isNullEmpty(document.getElementById(`DanneggiatoTabellaStima${index}`).ej2_instances[0].value))
                            danneggiatoObbligatorio = true;
                    }
                    else if (document.getElementById(`btnSwitchDanneggiato${index}`).ej2_instances[0].children === '←') {
                        if (CustomJS.isNullEmpty(document.getElementById(`DanneggiatoAggiunto${index}`).ej2_instances[0].value))
                            danneggiatoObbligatorio = true;
                    }
                    if (danneggiatoObbligatorio === true) {
                        almenoUnDanneggiatoOmesso = true;
                        document.getElementById(`mexErroreDanneggiato${index}`).style.removeProperty('display');
                        setTimeout(() => {
                            document.getElementById(`mexErroreDanneggiato${index}`).style.display = 'none'
                        }, 3500)
                    }
                    this.formOptions.rules[`PartitaTabellaStima${index}`] = {
                        required: [true, "Partita (Stime) è un campo obbligatorio!"]
                    }
                    this.formOptions.rules[`GaranziaTabellaStima${index}`] = {
                        required: [true, "Garanzia (Stime) è un campo obbligatorio!"]
                    }
                }
            }

            this.formObject = new FormValidator('#formElaboratoPeritale', this.formOptions);
            console.log(this.formObject)
            if (this.formObject.validate() && almenoUnDanneggiatoOmesso === false) {
                document.getElementById('salvaEProcedi').ej2_instances[0].disabled = true;

                this.collapseAccordionDatiSin()
                let arrayFranchigeCondivise = this.garanzieCondivise();
                setTimeout(() => {
                    this.salvaDati(arrayFranchigeCondivise);
                }, 350)
            }
            else {
                let fraseDanneggiato = 'Danneggiato (Stime) è un campo obbligatorio!';
                let messaggioCampiMancanti = '';
                this.formObject.errorRules.forEach(elm => {
                    messaggioCampiMancanti = messaggioCampiMancanti + elm.message + '\n'
                })
                messaggioCampiMancanti = almenoUnDanneggiatoOmesso === true ? (messaggioCampiMancanti + fraseDanneggiato) : messaggioCampiMancanti;
                toast.warn(messaggioCampiMancanti, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }
        }
        //SE NON HA I CALCOLI (CASO SPECIALE)
        else {
            this.formObject = new FormValidator('#formElaboratoPeritale', this.formOptions);
            if (this.formObject.validate()) {
                document.getElementById('salvaEProcedi').ej2_instances[0].disabled = true;
                let arrayFranchigeCondivise = this.garanzieCondivise();
                setTimeout(() => {
                    this.salvaDati(arrayFranchigeCondivise);
                }, 350)
            }
            else {
                let messaggioCampiMancanti = '';
                this.formObject.errorRules.forEach(elm => {
                    messaggioCampiMancanti = messaggioCampiMancanti + elm.message + '\n'
                })
                toast.warn(messaggioCampiMancanti, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }
        }
    }


    salvataggioInfoPerizia() {
        this.toggleBlocking2(true);

        //INFO INCARICO
        let queryIncaricoXpert = '';
        if (this.statoInfoElaboratoPeritale.statoInfo === 'Nuovo')
            queryIncaricoXpert = `INSERT INTO _perizia_incarico_info (id_sinistro,servizio_eseguito,RA_note_fabbr,RA_rischio_fabbr,RA_note_fabbr_no_conforme,RA_note_fotov,RA_rischio_fotov,RA_monitorato_fotov,SM_note,MC_note,MC_mezzi_chiusura,MC_motivazioni,OP_note,R_note,R_possibilita_rivalsa,Ris_note,condizioneRischioCodice,periziaNoCalcoli,R_cognome_debitore,R_nome_debitore,R_motivo_rivalsa) VALUES (@idSinistro,@servizioEseguito,@rischioAssicuratoNoteFabbr,@rischioFabbricato,@rischioAssicuratoFabbrNoteNoConforme,@rischioAssicuratoNoteFotov,@rischioFotovoltaico,@monitoratoFotov,@modalitaSinistroNote,@mezziChiusuraNote,@mezziChiusura,@motivazioniMezziChiusura,@operazioniPeritaliNote,@rivalsaNote,@possibilitaRivalsa,@riservaNote,@condizioneRischioCodice,@periziaNoCalcoli,@rivalsaCognomeDebitore,@rivalsaNomeDebitore,@rivalsaMotivo);`;

        else if (this.statoInfoElaboratoPeritale.statoInfo === 'Modifica')
            queryIncaricoXpert = `UPDATE _perizia_incarico_info SET servizio_eseguito = @servizioEseguito, RA_note_fabbr = @rischioAssicuratoNoteFabbr, RA_rischio_fabbr = @rischioFabbricato, RA_note_fabbr_no_conforme = @rischioAssicuratoFabbrNoteNoConforme, RA_note_fotov = @rischioAssicuratoNoteFotov,RA_rischio_fotov = @rischioFotovoltaico,RA_monitorato_fotov = @monitoratoFotov, SM_note = @modalitaSinistroNote,MC_note = @mezziChiusuraNote, MC_mezzi_chiusura = @mezziChiusura,MC_motivazioni = @motivazioniMezziChiusura, OP_note = @operazioniPeritaliNote, R_note = @rivalsaNote, R_possibilita_rivalsa = @possibilitaRivalsa, Ris_note = @riservaNote, condizioneRischioCodice = @condizioneRischioCodice,periziaNoCalcoli = @periziaNoCalcoli,R_cognome_debitore = @rivalsaCognomeDebitore,R_nome_debitore = @rivalsaNomeDebitore,R_motivo_rivalsa = @rivalsaMotivo WHERE id_sinistro = ${this.props.IdSinistro};`;

        //Mezzi di chiusura salvataggio : 1 = Pienamente conformi, 2 = Parzialmente conformi, 3 = Non conformi, 0 = non selezionato
        let mezziChiusura = '';
        if (document.getElementById('pienamenteConformi').ej2_instances[0].checked)
            mezziChiusura = 1;
        else if (document.getElementById('parzialmenteConformi').ej2_instances[0].checked)
            mezziChiusura = 2
        else if (document.getElementById('nonConformi').ej2_instances[0].checked)
            mezziChiusura = 3
        else
            mezziChiusura = 0;

        let condizioneRischioAssicuratoCodice = CustomJS.isNullEmpty(document.getElementById('condizioneRischio').ej2_instances[0].value) ? '0' : document.getElementById('condizioneRischio').ej2_instances[0].value[0];
        let condizioneRischioAssicuratoDescrizione = '';
        document.getElementById('condizioneRischio').ej2_instances[0].listData.forEach(elm => {
            if (elm.id === condizioneRischioAssicuratoCodice)
                condizioneRischioAssicuratoDescrizione = elm.text;
        });

        let jsonObjectIncaricoXpert = {
            QueryExec: queryIncaricoXpert,
            idSinistro: this.props.IdSinistro.toString(),
            servizioEseguito: CustomJS.isNullEmpty(document.getElementById(`tipoServizio`).ej2_instances[0].value) ? '' : document.getElementById(`tipoServizio`).ej2_instances[0].value,
            rischioAssicuratoNoteFabbr: CustomJS.isNullEmpty(document.getElementById(`noteRischioAssicuratoFabbricato`).ej2_instances[0].value) ? '' : document.getElementById('noteRischioAssicuratoFabbricato').ej2_instances[0].value,
            rischioFabbricato: document.getElementById(`rischioFabbricatoConforme`).ej2_instances[0].checked ? '1' : '0',
            rischioAssicuratoFabbrNoteNoConforme: CustomJS.isNullEmpty(document.getElementById(`noteRischioNoConforme`).ej2_instances[0].value) ? '' : document.getElementById('noteRischioNoConforme').ej2_instances[0].value,
            rischioAssicuratoNoteFotov: CustomJS.isNullEmpty(document.getElementById('noteRischioAssicuratoFotovoltaico').ej2_instances[0].value) ? '' : document.getElementById('noteRischioAssicuratoFotovoltaico').ej2_instances[0].value,
            rischioFotovoltaico: document.getElementById(`rischioFotovConforme`).ej2_instances[0].checked ? '1' : '0',
            monitoratoFotov: document.getElementById(`monitoratoFotovSi`).ej2_instances[0].checked ? '1' : '0',
            mezziChiusuraNote: CustomJS.isNullEmpty(document.getElementById('noteMezziChiusura').ej2_instances[0].value) ? '' : document.getElementById('noteMezziChiusura').ej2_instances[0].value,
            mezziChiusura: mezziChiusura.toString(),
            motivazioniMezziChiusura: CustomJS.isNullEmpty(document.getElementById('noteMotivazioniMezziChiusura').ej2_instances[0].value) ? '' : document.getElementById('noteMotivazioniMezziChiusura').ej2_instances[0].value,
            operazioniPeritaliNote: CustomJS.isNullEmpty(document.getElementById('noteOperazioniPeritali').ej2_instances[0].value) ? '' : document.getElementById('noteOperazioniPeritali').ej2_instances[0].value,
            rivalsaNote: document.getElementById('noteRivalsa') ? document.getElementById('noteRivalsa').ej2_instances[0].value : '',
            //possibilità di rivalsa : 1 = 'si' - 0 = 'no', 2 = 'rinuncia'
            possibilitaRivalsa: document.getElementById(`possibilitaRivalsaSi`).ej2_instances[0].checked ? '1' : document.getElementById(`possibilitaRivalsaNo`).ej2_instances[0].checked ? '0' : document.getElementById(`possibilitaRivalsaRinuncia`).ej2_instances[0].checked ? '2' : '0',
            riservaNote: document.getElementById('noteRiserva') ? document.getElementById('noteRiserva').ej2_instances[0].value : '',
            // definizioneNote: CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value) ? '' : document.getElementById('noteDefinizione').ej2_instances[0].value,
            condizioneRischioCodice: condizioneRischioAssicuratoCodice,
            modalitaSinistroNote: CustomJS.isNullEmpty(document.getElementById('noteSinistroModalita').ej2_instances[0].value) ? '' : document.getElementById('noteSinistroModalita').ej2_instances[0].value,
            periziaNoCalcoli: this.periziaSenzaCalcoli === true ? '1' : '0',
            rivalsaCognomeDebitore: document.getElementById('cognomeDebitoreRivalsa').ej2_instances[0].value,
            rivalsaNomeDebitore: document.getElementById('nomeDebitoreRivalsa').ej2_instances[0].value,
            rivalsaMotivo: document.getElementById('motivoRivalsa').ej2_instances[0].value
        }
        let jsonObjectIncaricoGenerali = {}

        if (this.isCompagniaGenerali()) {
            let queryIncaricoGenerali = `UPDATE GENERALI_Incarico SET polizza_descrizioneRischioAssicurato = @rischioAssicuratoNoteFabbr, cause_sinistro = @modalitaSinistroNote, condizioniRischioAssicurato_codice = @condizioniRischioAssicuratoCodice,condizioniRischioAssicurato_descrizione = @condizioniRischioAssicuratoDescrizione,rispondenza_rischio = @rischioFabbricato, rispondenza_rischio_nota = @rischioAssicuratoFabbrNoteNoConforme, possibilita_rivalsa = @possibilitaRivalsa, servizioEseguito_codice = @tipoServizioCodice,servizioEseguito_descrizione = @tipoServizioDescr WHERE id_incarico = '${this.props.IdIncarico}'`;
            jsonObjectIncaricoGenerali = {
                QueryExec: queryIncaricoGenerali,
                rischioAssicuratoNoteFabbr: CustomJS.isNullEmpty(document.getElementById(`noteRischioAssicuratoFabbricato`).ej2_instances[0].value) ? '' : document.getElementById('noteRischioAssicuratoFabbricato').ej2_instances[0].value,
                condizioniRischioAssicuratoCodice: condizioneRischioAssicuratoCodice,
                condizioniRischioAssicuratoDescrizione: condizioneRischioAssicuratoDescrizione,
                rischioFabbricato: document.getElementById(`rischioFabbricatoConforme`).ej2_instances[0].checked ? '1' : '0',
                rischioAssicuratoFabbrNoteNoConforme: CustomJS.isNullEmpty(document.getElementById(`noteRischioNoConforme`).ej2_instances[0].value) ? '' : document.getElementById('noteRischioNoConforme').ej2_instances[0].value,
                // definizioneNote: CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value) ? '' : document.getElementById('noteDefinizione').ej2_instances[0].value,
                possibilitaRivalsa: document.getElementById(`possibilitaRivalsaSi`).ej2_instances[0].checked ? '1' : document.getElementById(`possibilitaRivalsaNo`).ej2_instances[0].checked ? '0' : document.getElementById(`possibilitaRivalsaRinuncia`).ej2_instances[0].checked ? '2' : '0',
                tipoServizioCodice: CustomJS.isNullEmpty(document.getElementById('tipoServizio').ej2_instances[0].value) ? '' : document.getElementById('tipoServizio').ej2_instances[0].value,
                tipoServizioDescr: CustomJS.isNullEmpty(document.getElementById('tipoServizio').ej2_instances[0].text) ? '' : document.getElementById('tipoServizio').ej2_instances[0].text,
                modalitaSinistroNote: CustomJS.isNullEmpty(document.getElementById('noteSinistroModalita').ej2_instances[0].value) ? '' : document.getElementById('noteSinistroModalita').ej2_instances[0].value
            }
            console.log(jsonObjectIncaricoGenerali)
        }
        let jsonObjectIncaricoUnipolOss = {}
        let jsonObjectIncaricoUnipolPerizia = {}
        if (this.isCompagniaUnipol()) {
            //PERIZIA 
            let queryIncaricoUnipolPerizia = ``;
            if (this.statoInfoElaboratoPeritale.statoUnipolPerizia === 'n')
                queryIncaricoUnipolPerizia = `insert into UNIPOLSAI_Perizia (id_sinistro_xpert,riserveSuAtto,potenzialeRivalsa,cognomeDebitore,nomeDebitore,codiceMotivoRivalsa,noteRivalsa) values (${this.props.IdSinistro},@riserveSuAtto,@potenzialeRivalsa,@cognomeDebitore,@nomeDebitore,@codiceMotivoRivalsa,@noteRivalsa);`;
            else if (this.statoInfoElaboratoPeritale.statoUnipolPerizia === 'o')
                queryIncaricoUnipolPerizia = `update UNIPOLSAI_Perizia set riserveSuAtto = @riserveSuAtto, potenzialeRivalsa = @potenzialeRivalsa, cognomeDebitore = @cognomeDebitore, nomeDebitore = @nomeDebitore, codiceMotivoRivalsa = @codiceMotivoRivalsa, noteRivalsa = @noteRivalsa where id_sinistro_xpert = ${this.props.IdSinistro};`;

            jsonObjectIncaricoUnipolPerizia = {
                QueryExec: queryIncaricoUnipolPerizia,
                riserveSuAtto: document.getElementById('noteRiserva') ? (CustomJS.isNullEmpty(document.getElementById('noteRiserva').ej2_instances[0].value) ? '0' : '1') : '',
                potenzialeRivalsa: $("input[type='radio'][name='possRivalsa']:checked").val() === 'si' ? '1' : '0',
                cognomeDebitore: document.getElementById('cognomeDebitoreRivalsa').ej2_instances[0].value,
                nomeDebitore: document.getElementById('nomeDebitoreRivalsa').ej2_instances[0].value,
                codiceMotivoRivalsa: document.getElementById('motivoRivalsa').ej2_instances[0].value,
                noteRivalsa: $("input[type='radio'][name='possRivalsa']:checked").val() === 'si' ? document.getElementById('noteRivalsa').ej2_instances[0].value : ''
            }
            //OSSERVAZIONI
            let queryIncaricoUnipolOss = ``;
            if (this.statoInfoElaboratoPeritale.statoUnipolOsservazioni === 'n')
                queryIncaricoUnipolOss = `insert into UNIPOLSAI_Osservazioni (id_sinistro_xpert,noteRiservateCompagnia,rispondenzaRischio,codiceStatoFabbricato) values (${this.props.IdSinistro},@noteRiservateCompagnia,@rischioFabbricato,@codiceStatoFabbricato)`;
            else if (this.statoInfoElaboratoPeritale.statoUnipolOsservazioni === 'o')
                queryIncaricoUnipolOss = `update UNIPOLSAI_Osservazioni set  noteRiservateCompagnia = @noteRiservateCompagnia, rispondenzaRischio = @rischioFabbricato, codiceStatoFabbricato = @codiceStatoFabbricato where id_sinistro_xpert = ${this.props.IdSinistro};`;

            jsonObjectIncaricoUnipolOss = {
                QueryExec: queryIncaricoUnipolOss,
                // definizioneNote: CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value) ? '' : document.getElementById('noteDefinizione').ej2_instances[0].value,
                noteRiservateCompagnia: CustomJS.isNullEmpty(document.getElementById('noteRiservateCompagnia').ej2_instances[0].value) ? '' : document.getElementById('noteRiservateCompagnia').ej2_instances[0].value,
                rischioFabbricato: document.getElementById(`rischioFabbricatoConforme`).ej2_instances[0].checked ? '1' : '0',
                codiceStatoFabbricato: CustomJS.isNullEmpty(document.getElementById('condizioneRischio').ej2_instances[0].value) ? '' : document.getElementById('condizioneRischio').ej2_instances[0].value[0]
            }
        }
        console.log(jsonObjectIncaricoXpert)
        console.log(jsonObjectIncaricoGenerali)
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectIncaricoXpert), retDataIncaricoXpert => {
            if (this.isCompagniaGenerali()) {
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectIncaricoGenerali), retdataIncaricoIntestazione => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            }
            if (this.isCompagniaUnipol()) {
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectIncaricoUnipolPerizia), retdataIncaricoUnipolPerizia => {
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectIncaricoUnipolOss), retdataIncaricoUnipolOss => {
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    });
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            }
            this.toggleBlocking2(false);
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
    }

    async salvaDati(arrayFranchigeCondivise) {
        this.toggleBlocking2(true);

        this.salvataggioInfoPerizia();
        //ELIMINAZIONE DI TUTTI I CALCOLI SE L'UTENTE HA SCELTO DI MODIFICARE LE TABELLE

        let tempoOne = 75;
        let tempoTotale = (tempoOne * this.strutturaStatiStime.length) + (tempoOne * this.strutturaStatiPartite.length) + (tempoOne * this.strutturaStatiGaranzie.length)

        //AGGIUNTA DANNEGGIATO NEL CASO DI NUOVO INSERIMENTO INIZIO

        //----------------------INIZIO AGGIUNGI DANNEGGIATI---------------------------
        console.log(this.strutturaStatiPartite)
        console.log(this.strutturaStatiGaranzie)
        console.log(this.strutturaStatiStime)

        console.log('inizio danneggiati aggiunti')
        const fineInserimentoDanneggiati = () => {
            return new Promise((resolveFineDanneggiati) => {

                let funcListStime = [];
                this.strutturaStatiStime.forEach((elm) => {
                    const esegui = () => {
                        return new Promise(async (resolve) => {
                            //questo if serve per stabilire se il container con il danneggiato nuovo non sia stato eliminato
                            if (elm.stato !== 'd') {
                                if (document.getElementById(`btnSwitchDanneggiato${elm.num}`).ej2_instances[0].children === '←') {
                                    //aggiungo il tempo di una chiamata all' attesa
                                    tempoTotale += tempoOne;

                                    let guidGenerataDann = CustomJS.createGuid4()
                                    const aggiungiDanneggiatoXpert = () => {
                                        return new Promise((resolveInsert) => {
                                            let queryAggiungiDanneggiato = `insert into acdanneggiati (id_sinistro,danneggiato,aggiuntoDaStima,guid_utile_perizia) values (${this.props.IdSinistro}, @danneggiato,'1','${guidGenerataDann}');`;
                                            let jsonObjectDanneggiato = {
                                                QueryExec: queryAggiungiDanneggiato,
                                                danneggiato: CustomJS.isNullEmpty(document.getElementById(`DanneggiatoAggiunto${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`DanneggiatoAggiunto${elm.num}`).ej2_instances[0].value
                                            }
                                            console.log(jsonObjectDanneggiato)
                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDanneggiato), retDataDanneggiato => {
                                                resolveInsert()
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                }
                                                );
                                            });
                                        })
                                    }
                                    await aggiungiDanneggiatoXpert();

                                    if (this.isCompagniaGenerali()) {
                                        const aggiungiDanneggiatoGEN = () => {
                                            return new Promise((resolveInsert) => {
                                                let queryAggiungiDanneggiatoGen = `START TRANSACTION;insert into GENERALI_danneggiati (id_danneggiato,id_danneggiato_xpert,id_incarico,nominativo,importato,aggiuntoDaStimaXpert) values ('${CustomJS.createGuid4()}',(select id_danneggiato from acdanneggiati where guid_utile_perizia = '${guidGenerataDann}' and id_sinistro = ${this.props.IdSinistro} for update), '${this.props.IdIncarico}',@nominativo,'0','1');COMMIT;`;
                                                let jsonObjectDanneggiatoGen = {
                                                    QueryExec: queryAggiungiDanneggiatoGen,
                                                    danneggiato: CustomJS.isNullEmpty(document.getElementById(`DanneggiatoAggiunto${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`DanneggiatoAggiunto${elm.num}`).ej2_instances[0].value,
                                                    nominativo: CustomJS.isNullEmpty(document.getElementById(`DanneggiatoAggiunto${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`DanneggiatoAggiunto${elm.num}`).ej2_instances[0].value
                                                }
                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDanneggiatoGen), retDataDanneggiatoGen => {
                                                    resolveInsert()
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    }
                                                    );
                                                });
                                            })
                                        }
                                        await aggiungiDanneggiatoGEN();
                                    }
                                    //prendo gli id dei danneggiati aggiunti
                                    const queryIdDanneggiati = (`SELECT T1.id_danneggiato,T1.id_sinistro,T1.danneggiato, T2.id_danneggiato as guid_danneggiato,T2.id_incarico FROM acdanneggiati T1 left outer join GENERALI_danneggiati T2 on T1.id_danneggiato = T2.id_danneggiato_xpert where T1.id_sinistro = ${this.props.IdSinistro} and aggiuntoDaStima = 1;`);
                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryIdDanneggiati, '', retDataDanneggiati => {
                                        retDataDanneggiati = JSON.parse(retDataDanneggiati.data);
                                        for (let i = 0; i < retDataDanneggiati.length; i++) {
                                            let giaInserito = false;
                                            this.danneggiatiAggiunti.forEach(elm => {
                                                if (elm.VALUE === retDataDanneggiati[i].id_danneggiato)
                                                    giaInserito = true;
                                            })
                                            if (giaInserito === false) {
                                                this.danneggiatiAggiunti.push({
                                                    VALUE: retDataDanneggiati[i].id_danneggiato,
                                                    TEXT: retDataDanneggiati[i].danneggiato,
                                                    GUID: retDataDanneggiati[i].guid_danneggiato,
                                                })
                                            }
                                        }
                                        resolve('Prossima');
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                    });
                                }
                                else {
                                    resolve('Prossima');
                                }
                            }
                            else {
                                resolve('Prossima');
                            }
                        })
                    }
                    funcListStime.push(esegui);
                })
                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListStime[ind])) {
                        //fine ciclo chiamate nella strutturaStatiStime
                        //risolvo la Promise dentro la funzione fineInserimentoDanneggiati()
                        resolveFineDanneggiati();
                    }
                    else {
                        funcListStime[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }

        await fineInserimentoDanneggiati()

        //----------------------FINE AGGIUNGI DANNEGGIATI-----------------------------

        console.log('inizio partite')

        const fineCrudPartite = () => {
            return new Promise((resolveFinePartite) => {

                let funcListPartite = [];
                //PARTITE
                this.strutturaStatiPartite.forEach((elm) => {
                    const esegui = () => {
                        return new Promise(async (resolve) => {

                            let guidPartitaTemp = elm.guidTemp;
                            let queryPartitaXpert = '';
                            let jsonObjectPartitaXpert = {};

                            const crudPartitaXpert = () => {
                                return new Promise((resolveCrud) => {
                                    if (elm.stato === 'o' && elm.casoIniziale === false) {
                                        queryPartitaXpert = `UPDATE _partitePeriziaOnline SET id_partita = @idPartita, partitaDescrizione = @partitaDescr, formaGaranzia = @formaGaranzia, formaGaranziaDescrizione = @formaGaranziaDescrizione, sommaAssicurataPartita = @sommaAssicurata, descrizione = @descrizione WHERE id_partitaPeriziaOnline = ${elm.key};`
                                    }
                                    else if (elm.stato === 'o' && elm.casoIniziale === true) {
                                        queryPartitaXpert = `INSERT INTO _partitePeriziaOnline (id_sinistro,id_partita,partitaDescrizione,formaGaranzia,formaGaranziaDescrizione,sommaAssicurataPartita, descrizione,guid_partita_temp) VALUES (${this.props.IdSinistro},@idPartita,@partitaDescr,@formaGaranzia,@formaGaranziaDescrizione,@sommaAssicurata,@descrizione,'${guidPartitaTemp}');`;
                                    }
                                    else if (elm.stato === 'n') {
                                        queryPartitaXpert = `INSERT INTO _partitePeriziaOnline (id_sinistro,id_partita,partitaDescrizione,formaGaranzia,formaGaranziaDescrizione,sommaAssicurataPartita, descrizione,guid_partita_temp) VALUES (${this.props.IdSinistro},@idPartita,@partitaDescr,@formaGaranzia,@formaGaranziaDescrizione,@sommaAssicurata,@descrizione,'${guidPartitaTemp}');`;
                                    }
                                    else if (elm.stato === 'd') {
                                        queryPartitaXpert = `DELETE FROM _partitePeriziaOnline WHERE id_partitaPeriziaOnline = ${elm.key};`
                                    }
                                    if (elm.stato === 'd') {
                                        jsonObjectPartitaXpert = {
                                            QueryExec: queryPartitaXpert,
                                        }
                                    }
                                    else {
                                        jsonObjectPartitaXpert = {
                                            QueryExec: queryPartitaXpert,
                                            idPartita: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`Partita${elm.num}`).ej2_instances[0].value,
                                            partitaDescr: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`Partita${elm.num}`).ej2_instances[0].text,
                                            formaGaranzia: CustomJS.isNullEmpty(document.getElementById(`FormaGaranzia${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`FormaGaranzia${elm.num}`).ej2_instances[0].value,
                                            formaGaranziaDescrizione: CustomJS.isNullEmpty(document.getElementById(`FormaGaranzia${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`FormaGaranzia${elm.num}`).ej2_instances[0].text,
                                            sommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`SommaAssicurataPartita${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`SommaAssicurataPartita${elm.num}`).ej2_instances[0].value.toString(),
                                            descrizione: CustomJS.isNullEmpty(document.getElementById(`DescrizionePartita${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`DescrizionePartita${elm.num}`).ej2_instances[0].value,
                                        }
                                    }
                                    console.log(jsonObjectPartitaXpert)
                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectPartitaXpert), retDataPartitaXpert => {
                                        resolveCrud()
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                    });
                                })
                            }
                            await crudPartitaXpert()

                            if (this.isCompagniaGenerali()) {
                                const crudPartitaGEN = () => {
                                    return new Promise((resolveCrud) => {
                                        let queryPartitaGenerali = '';
                                        let jsonObjectPartitaGenerali = {};

                                        if (elm.stato === 'o' && elm.casoIniziale === false) {
                                            queryPartitaGenerali = `UPDATE GENERALI_polizza_partite SET tipoPartita_codice = @tipoPartitaCodice, tipoPartita_descrizione = @tipoPartitaDescr, partita_codice = @partitaCodice, partita_descrizione = @partitaDescr WHERE id_partita = '${elm.guid_partita}';`;
                                        }
                                        //Update Generali caso Iniziale
                                        else if (elm.stato === 'o' && elm.casoIniziale === true) {
                                            queryPartitaGenerali = `START TRANSACTION;UPDATE GENERALI_polizza_partite SET id_partita_xpert = (select id_partitaPeriziaOnline from _partitePeriziaOnline where guid_partita_temp = '${guidPartitaTemp}' and id_sinistro = ${this.props.IdSinistro} for update), partita_codice = @partitaCodice, partita_descrizione = @partitaDescr WHERE id_partita = '${elm.guid_partita}';COMMIT;`;
                                        }
                                        else if (elm.stato === 'n') {
                                            queryPartitaGenerali = `START TRANSACTION;INSERT INTO GENERALI_polizza_partite (id_partita,id_partita_xpert,id_incarico,tipoPartita_codice,tipoPartita_descrizione,partita_codice,partita_descrizione,importato) VALUES (@guidPartita,(select id_partitaPeriziaOnline from _partitePeriziaOnline where guid_partita_temp = '${guidPartitaTemp}' and id_sinistro = ${this.props.IdSinistro} for update),@idIncarico,@tipoPartitaCodice,@tipoPartitaDescr,@partitaCodice,@partitaDescr,@importato);COMMIT;`;
                                        }
                                        else if (elm.stato === 'd') {
                                            queryPartitaGenerali = `DELETE FROM GENERALI_polizza_partite WHERE id_partita = '${elm.guid_partita}' AND importato = 0;`
                                        }
                                        if (elm.stato === 'd') {
                                            jsonObjectPartitaGenerali = {
                                                QueryExec: queryPartitaGenerali,
                                            }
                                        }
                                        else {
                                            jsonObjectPartitaGenerali = {
                                                QueryExec: queryPartitaGenerali,
                                                guidPartita: elm.guid_partita,
                                                idIncarico: this.props.IdIncarico.toString(),
                                                tipoPartitaCodice: CustomJS.isNullEmpty(document.getElementById(`PartitaRicevuta${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`PartitaRicevuta${elm.num}`).ej2_instances[0].value,
                                                tipoPartitaDescr: CustomJS.isNullEmpty(document.getElementById(`PartitaRicevuta${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`PartitaRicevuta${elm.num}`).ej2_instances[0].text,
                                                partitaCodice: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`Partita${elm.num}`).ej2_instances[0].value,
                                                partitaDescr: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`Partita${elm.num}`).ej2_instances[0].text,
                                                importato: '0'
                                            }
                                        }
                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectPartitaGenerali), retDataPartitaGen => {
                                            resolveCrud()
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            }
                                            );
                                        });
                                    })
                                }
                                await crudPartitaGEN()
                            }
                            //------------gestione modifica partite inizio----------
                            let tempoPrec = tempoOne;
                            if (elm.stato !== 'd') {
                                console.log("gestione modifica partite/preesistenze")
                                this.keyPartitePrecModificate.forEach(mod => {
                                    if (mod.key === elm.key) {
                                        //se prima era fabbricato e ora non più elimino le righe delle costruzioni (anche sulle tab generali)
                                        if (mod.codEle === 'TP016' || mod.codEle === '1') {
                                            if (document.getElementById(`Partita${elm.num}`).ej2_instances[0].value !== 'TP016' && document.getElementById(`Partita${elm.num}`).ej2_instances[0].value !== '1') {
                                                console.log("prima fabbricato ora altra partita")
                                                tempoPrec += tempoOne;
                                                this.strutturaStatiEliminaDati.idPreesistenzaXpert.forEach(prees => {
                                                    if (prees.idPartita === elm.key) {
                                                        let quanteCostruzioni = 0;

                                                        this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(costruz => {
                                                            if (costruz.idPrees === prees.idPrees) {
                                                                quanteCostruzioni++;
                                                            }
                                                        })
                                                        this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(costruz => {
                                                            if (costruz.idPrees === prees.idPrees) {
                                                                let jsonObject = {
                                                                    QueryExec: `delete from _preesistenza_costruzioni where id_preesistenza_costruzione = ${costruz.idCostruz};`
                                                                }
                                                                console.log(jsonObject)
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), eliminato => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                                if (this.isCompagniaGenerali()) {
                                                                    this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(gen => {
                                                                        if (gen.idCostruzXpert === costruz.idCostruz) {
                                                                            if (quanteCostruzioni > 1) {
                                                                                let jsonObjectGen = {
                                                                                    QueryExec: `delete from GENERALI_partita_preesistenze where id_preesistenza = ${gen.idGen};`
                                                                                }
                                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectGen), eliminato => {
                                                                                }, messaggio => {
                                                                                    toast.warn(messaggio, {
                                                                                        containerId: 'toastContainer1',
                                                                                        onClose: () => toast.dismiss(),
                                                                                        position: "bottom-right",
                                                                                        autoClose: 5000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: false,
                                                                                        progress: undefined,
                                                                                        newestOnTop: true,
                                                                                        rtl: false,
                                                                                        pauseOnFocusLoss: true
                                                                                    }
                                                                                    );
                                                                                });
                                                                            }
                                                                            else if (quanteCostruzioni === 1) {
                                                                                let jsonObjectGen = {
                                                                                    QueryExec: `update GENERALI_partita_preesistenze set id_prees_costruz_xpert = null,valore_unitario = null,quantita = null, descrizione = @partitaDescr where id_preesistenza = ${gen.idGen};`,
                                                                                    partitaDescr: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`Partita${elm.num}`).ej2_instances[0].text,
                                                                                }
                                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectGen), update => {
                                                                                }, messaggio => {
                                                                                    toast.warn(messaggio, {
                                                                                        containerId: 'toastContainer1',
                                                                                        onClose: () => toast.dismiss(),
                                                                                        position: "bottom-right",
                                                                                        autoClose: 5000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: false,
                                                                                        progress: undefined,
                                                                                        newestOnTop: true,
                                                                                        rtl: false,
                                                                                        pauseOnFocusLoss: true
                                                                                    }
                                                                                    );
                                                                                });
                                                                            }
                                                                            quanteCostruzioni--;
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                        //se prima era altro e adesso è fabbricato
                                        else if (mod.codEle !== 'TP016' && mod.codEle !== '1') {
                                            if (document.getElementById(`Partita${elm.num}`).ej2_instances[0].value === 'TP016' || document.getElementById(`Partita${elm.num}`).ej2_instances[0].value === '1') {
                                                console.log("prima altra partita ora fabbricato")
                                                this.strutturaStatiEliminaDati.idPreesistenzaXpert.forEach(prees => {
                                                    if (prees.idPartita === elm.key) {
                                                        this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(gen => {
                                                            if (gen.idXpert === prees.idPrees) {
                                                                let jsonObjectGen = {
                                                                    QueryExec: `delete from GENERALI_partita_preesistenze where id_preesistenza = ${gen.idGen};`
                                                                }
                                                                console.log(jsonObjectGen)
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectGen), eliminato => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                        //se prima era valore intero e ora non lo è più elimino i dati della preesistenza dal db
                                        if (mod.formaGaranzia === '2') {
                                            if (document.getElementById(`FormaGaranzia${elm.num}`).ej2_instances[0].value !== '2') {
                                                tempoPrec += tempoOne;
                                                this.strutturaStatiEliminaDati.idPreesistenzaXpert.forEach(prees => {
                                                    if (prees.idPartita === elm.key) {
                                                        let jsonObjectPrees = {
                                                            QueryExec: `delete from _preesistenza_xpert where id_preesistenza = ${prees.idPrees};`
                                                        }
                                                        console.log(jsonObjectPrees)
                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectPrees), eliminato => {

                                                            this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(costruz => {
                                                                if (costruz.idPrees === prees.idPrees) {
                                                                    let jsonObjectCostruz = {
                                                                        QueryExec: `delete from _preesistenza_costruzioni where id_preesistenza_costruzione = ${costruz.idCostruz};`
                                                                    }
                                                                    console.log(jsonObjectCostruz)
                                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCostruz), eliminato => {

                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        }
                                                                        );
                                                                    });
                                                                }
                                                            })
                                                            this.strutturaStatiEliminaDati.idPreesistenzaDescr.forEach(descr => {
                                                                if (descr.idPrees === prees.idPrees) {
                                                                    let jsonObjectDescr = {
                                                                        QueryExec: `delete from _preesistenza_descrizioni where id_preesistenza_descrizione = ${descr.idDescr};`
                                                                    }
                                                                    console.log(jsonObjectDescr)
                                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDescr), eliminato => {
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        }
                                                                        );
                                                                    });
                                                                }
                                                            })
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            }
                                                            );
                                                        });
                                                        if (this.isCompagniaGenerali()) {
                                                            this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(gen => {
                                                                if (gen.idXpert === prees.idPrees) {
                                                                    let jsonObjectPreesGen = {
                                                                        QueryExec: `delete from GENERALI_partita_preesistenze where id_preesistenza = ${gen.idGen};`
                                                                    }
                                                                    console.log(jsonObjectPreesGen)
                                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectPreesGen), eliminato => {

                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        }
                                                                        );
                                                                    });
                                                                }
                                                            })
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    }
                                })
                            }
                            else {
                                //se lo stato è d devo eliminare le preesistenze già compilate correlate
                                console.log('elimino preesistenze legate alla partita eliminata : ' + elm.key)
                                this.strutturaStatiEliminaDati.idPreesistenzaXpert.forEach(prees => {
                                    if (prees.idPartita === elm.key) {
                                        let jsonObjectPrees = {
                                            QueryExec: `delete from _preesistenza_xpert where id_preesistenza = ${prees.idPrees};`
                                        }
                                        console.log(jsonObjectPrees)
                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectPrees), eliminato => {
                                            this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(costruz => {
                                                if (costruz.idPrees === prees.idPrees) {
                                                    let jsonObjectCostruz = {
                                                        QueryExec: `delete from _preesistenza_costruzioni where id_preesistenza_costruzione = ${costruz.idCostruz};`
                                                    }
                                                    console.log(jsonObjectCostruz)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCostruz), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                }
                                            })
                                            this.strutturaStatiEliminaDati.idPreesistenzaDescr.forEach(descr => {
                                                if (descr.idPrees === prees.idPrees) {
                                                    let jsonObjectDescr = {
                                                        QueryExec: `delete from _preesistenza_descrizioni where id_preesistenza_descrizione = ${descr.idDescr};`
                                                    }
                                                    console.log(jsonObjectDescr)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDescr), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                }
                                            })
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            }
                                            );
                                        });
                                        if (this.isCompagniaGenerali()) {
                                            this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(gen => {
                                                if (gen.idXpert === prees.idPrees) {
                                                    let jsonObjectPreesGen = {
                                                        QueryExec: `delete from GENERALI_partita_preesistenze where id_preesistenza = ${gen.idGen};`
                                                    }
                                                    console.log(jsonObjectPreesGen)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectPreesGen), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                            //------------gestione modifica partite fine----------
                            console.log(tempoPrec)
                            setTimeout(() => {
                                resolve('Prossima');
                            }, tempoPrec)
                        })
                    }
                    funcListPartite.push(esegui);
                })

                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListPartite[ind])) {
                        //fine operazioni crud tutte le partite nella strutturaStatiPartite
                        resolveFinePartite()
                    }
                    else {
                        funcListPartite[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }
        await fineCrudPartite();

        console.log('inizio garanzie')
        const fineCrudGaranzie = () => {
            return new Promise((resolveFineGaranzie) => {

                let funcListGaranzie = [];
                this.strutturaStatiGaranzie.forEach((elm) => {
                    const esegui = () => {
                        return new Promise(async (resolve) => {

                            let guidGaranziTemp = elm.guidTemp;
                            let queryGaranziaXpert = '';
                            let jsonObjectGaranziaXpert = {}

                            const crudGaranziaXpert = () => {
                                return new Promise((resolveGaranziaXpert) => {

                                    if (elm.stato === 'o' && elm.casoIniziale === false) {
                                        queryGaranziaXpert = `UPDATE _garanziePeriziaOnline SET id_garanzia = @idGaranzia, garanziaDescrizione = @garanziaDescr, id_determinazioneDanno = @determinazioneDanno, descrizioneDetermDanno = @determinazioneDannoDescr, franchigiaCondivisa = @franchigiaCondivisa,limiteIndennizzoEuro = @limiteIndennizzoEuro,limiteIndennizzoGiorni = @limiteIndennizzoGiorni, limitePer = @limitePer, franchigiaEuro = @franchigiaEuro,franchigiaGiorni = @franchigiaGiorni,scopertoPerc = @scopertoPerc,scopertoMax = @scopertoMax, scopertoMin = @scopertoMin WHERE id_garanziaPeriziaOnline = ${elm.key};`
                                    }
                                    else if (elm.stato === 'o' && elm.casoIniziale === true) {
                                        queryGaranziaXpert = `INSERT INTO _garanziePeriziaOnline (id_sinistro,id_garanzia,garanziaDescrizione,id_determinazioneDanno,descrizioneDetermDanno,franchigiaCondivisa,limiteIndennizzoEuro,limiteIndennizzoGiorni,limitePer,franchigiaEuro,franchigiaGiorni,scopertoPerc,scopertoMax,scopertoMin,guid_garanzia_temp) VALUES (${this.props.IdSinistro},@idGaranzia,@garanziaDescr,@determinazioneDanno,@determinazioneDannoDescr,@franchigiaCondivisa,@limiteIndennizzoEuro,@limiteIndennizzoGiorni,@limitePer,@franchigiaEuro,@franchigiaGiorni,@scopertoPerc,@scopertoMax,@scopertoMin,'${guidGaranziTemp}');`
                                    }
                                    else if (elm.stato === 'n') {
                                        queryGaranziaXpert = `INSERT INTO _garanziePeriziaOnline (id_sinistro,id_garanzia,garanziaDescrizione,id_determinazioneDanno,descrizioneDetermDanno,franchigiaCondivisa,limiteIndennizzoEuro,limiteIndennizzoGiorni,limitePer,franchigiaEuro,franchigiaGiorni,scopertoPerc,scopertoMax,scopertoMin,guid_garanzia_temp) VALUES (${this.props.IdSinistro},@idGaranzia,@garanziaDescr,@determinazioneDanno,@determinazioneDannoDescr,@franchigiaCondivisa,@limiteIndennizzoEuro,@limiteIndennizzoGiorni,@limitePer,@franchigiaEuro,@franchigiaGiorni,@scopertoPerc,@scopertoMax,@scopertoMin,'${guidGaranziTemp}');`
                                    }
                                    else if (elm.stato === 'd') {
                                        queryGaranziaXpert = `DELETE FROM _garanziePeriziaOnline WHERE id_garanziaPeriziaOnline = ${elm.key};`
                                    }
                                    if (elm.stato === 'd') {
                                        jsonObjectGaranziaXpert = {
                                            QueryExec: queryGaranziaXpert
                                        }
                                    }
                                    else {
                                        jsonObjectGaranziaXpert = {
                                            QueryExec: queryGaranziaXpert,
                                            idSinistro: this.props.IdSinistro.toString(),
                                            idGaranzia: CustomJS.isNullEmpty(document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].value,
                                            garanziaDescr: CustomJS.isNullEmpty(document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].text,
                                            determinazioneDanno: CustomJS.isNullEmpty(document.getElementById(`DeterminazioneDanno${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`DeterminazioneDanno${elm.num}`).ej2_instances[0].value,
                                            determinazioneDannoDescr: CustomJS.isNullEmpty(document.getElementById(`DeterminazioneDanno${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`DeterminazioneDanno${elm.num}`).ej2_instances[0].text,

                                            franchigiaCondivisa: CustomJS.isNullEmpty(document.getElementById(`FranchigiaCondivisa${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`FranchigiaCondivisa${elm.num}`).ej2_instances[0].value,
                                            limiteIndennizzoEuro: CustomJS.isNullEmpty(document.getElementById(`LimiteIndennizzoEuro${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`LimiteIndennizzoEuro${elm.num}`).ej2_instances[0].value.toString(),
                                            limiteIndennizzoGiorni: CustomJS.isNullEmpty(document.getElementById(`LimiteIndennizzoGiorni${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`LimiteIndennizzoGiorni${elm.num}`).ej2_instances[0].value.toString(),
                                            limitePer: CustomJS.isNullEmpty(document.getElementById(`LimiteIndennizzoPer${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`LimiteIndennizzoPer${elm.num}`).ej2_instances[0].value,
                                            franchigiaEuro: CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`FranchigiaEuro${elm.num}`).ej2_instances[0].value.toString(),
                                            franchigiaGiorni: CustomJS.isNullEmpty(document.getElementById(`FranchigiaGiorni${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`FranchigiaGiorni${elm.num}`).ej2_instances[0].value.toString(),
                                            scopertoPerc: CustomJS.isNullEmpty(document.getElementById(`ScopertoPerc${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoPerc${elm.num}`).ej2_instances[0].value.toString(),
                                            scopertoMax: CustomJS.isNullEmpty(document.getElementById(`ScopertoMassimo${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoMassimo${elm.num}`).ej2_instances[0].value.toString(),
                                            scopertoMin: CustomJS.isNullEmpty(document.getElementById(`ScopertoMinimo${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoMinimo${elm.num}`).ej2_instances[0].value.toString(),
                                        }
                                    }
                                    console.log(jsonObjectGaranziaXpert)
                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectGaranziaXpert), retDataGaranziaXpert => {
                                        resolveGaranziaXpert()
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                    });
                                })
                            }
                            await crudGaranziaXpert()

                            if (this.isCompagniaGenerali()) {
                                const crudGaranziaGEN = () => {
                                    return new Promise((resolveGaranziaGEN) => {
                                        let queryGaranziaGenerali = ''
                                        let jsonObjectGaranziaGenerali = {}

                                        if (elm.stato === 'o' && elm.casoIniziale === false) {
                                            queryGaranziaGenerali = `UPDATE GENERALI_polizza_garanzie SET tipoGaranzia_codice = @tipoGaranziaCod,tipoGaranzia_descrizione = @tipoGaranziaDescr,  garanzia_codice = @garanziaCodice, garanzia_descrizione = @garanziaDescr,gruppo_garanzia = @gruppoGaranzia,partita_assicurata = @partitaAssicurata,somma_assicurata = @sommaAssicurata,formaAssicurazione_codice = @formaAssicurazioneCodice,formaAssicurazione_descrizione = @formaAssicurazioneDescrizione, formulaAssicurazione_codice = @formulaAssicurazioneCodice, formulaAssicurazione_descrizione = @formulaAssicurazioneDescrizione, franchigia = @franchigia,scoperto_percentuale = @scopertoPerc,scoperto_minimo = @scopertoMin WHERE id_garanzia = '${elm.guid_garanzia}';`;
                                        }
                                        else if (elm.stato === 'o' && elm.casoIniziale === true) {
                                            queryGaranziaGenerali = `START TRANSACTION;UPDATE GENERALI_polizza_garanzie SET id_garanzia_xpert = (select id_garanziaPeriziaOnline from _garanziePeriziaOnline where guid_garanzia_temp = '${guidGaranziTemp}' and id_sinistro = ${this.props.IdSinistro} for update), garanzia_codice = @garanziaCodice, garanzia_descrizione = @garanziaDescr,gruppo_garanzia = @gruppoGaranzia,partita_assicurata = @partitaAssicurata,somma_assicurata = @sommaAssicurata,formaAssicurazione_codice = @formaAssicurazioneCodice,formaAssicurazione_descrizione = @formaAssicurazioneDescrizione, formulaAssicurazione_codice = @formulaAssicurazioneCodice, formulaAssicurazione_descrizione = @formulaAssicurazioneDescrizione, franchigia = @franchigia,scoperto_percentuale = @scopertoPerc,scoperto_minimo = @scopertoMin WHERE id_garanzia = '${elm.guid_garanzia}';COMMIT;`
                                        }
                                        else if (elm.stato === 'n') {
                                            queryGaranziaGenerali = `START TRANSACTION; INSERT INTO GENERALI_polizza_garanzie(id_garanzia,id_garanzia_xpert,id_incarico,tipoGaranzia_codice,tipoGaranzia_descrizione,garanzia_codice,garanzia_descrizione,gruppo_garanzia,partita_assicurata,somma_assicurata,formaAssicurazione_codice,formaAssicurazione_descrizione,formulaAssicurazione_codice,formulaAssicurazione_descrizione,franchigia,scoperto_percentuale,scoperto_minimo,importato) VALUES (@guidGaranzia,(select id_garanziaPeriziaOnline from _garanziePeriziaOnline where guid_garanzia_temp = '${guidGaranziTemp}' and id_sinistro = ${this.props.IdSinistro} for update),@idIncarico,@tipoGaranziaCod,@tipoGaranziaDescr,@garanziaCodice,@garanziaDescr,@gruppoGaranzia,@partitaAssicurata,@sommaAssicurata,@formaAssicurazioneCodice,@formaAssicurazioneDescrizione,@formulaAssicurazioneCodice,@formulaAssicurazioneDescrizione,@franchigia,@scopertoPerc,@scopertoMin,@importato);COMMIT;`
                                        }
                                        else if (elm.stato === 'd') {
                                            queryGaranziaGenerali = `DELETE FROM GENERALI_polizza_garanzie WHERE id_garanzia = '${elm.guid_garanzia}' AND importato = 0;`
                                        }
                                        if (elm.stato === 'd') {
                                            jsonObjectGaranziaGenerali = {
                                                QueryExec: queryGaranziaGenerali
                                            }
                                        }
                                        else {
                                            jsonObjectGaranziaGenerali = {
                                                QueryExec: queryGaranziaGenerali,
                                                guidGaranzia: elm.guid_garanzia,
                                                idIncarico: this.props.IdIncarico.toString(),
                                                tipoGaranziaCod: CustomJS.isNullEmpty(document.getElementById(`GaranziaRicevuta${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`GaranziaRicevuta${elm.num}`).ej2_instances[0].value,
                                                tipoGaranziaDescr: CustomJS.isNullEmpty(document.getElementById(`GaranziaRicevuta${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`GaranziaRicevuta${elm.num}`).ej2_instances[0].text,
                                                garanziaCodice: CustomJS.isNullEmpty(document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].value,
                                                garanziaDescr: CustomJS.isNullEmpty(document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].text,
                                                gruppoGaranzia: CustomJS.isNullEmpty(document.getElementById(`GruppoGaranzia${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`GruppoGaranzia${elm.num}`).ej2_instances[0].value,
                                                partitaAssicurata: CustomJS.isNullEmpty(document.getElementById(`partitaAssicurata${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`partitaAssicurata${elm.num}`).ej2_instances[0].value,
                                                sommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`SommaAssicurata${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`SommaAssicurata${elm.num}`).ej2_instances[0].value.toString(),
                                                formaAssicurazioneCodice: CustomJS.isNullEmpty(document.getElementById(`FormaAssicurazione${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`FormaAssicurazione${elm.num}`).ej2_instances[0].value,
                                                formaAssicurazioneDescrizione: CustomJS.isNullEmpty(document.getElementById(`FormaAssicurazione${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`FormaAssicurazione${elm.num}`).ej2_instances[0].text,
                                                formulaAssicurazioneCodice: CustomJS.isNullEmpty(document.getElementById(`FormulaAssicurazione${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`FormulaAssicurazione${elm.num}`).ej2_instances[0].value,
                                                formulaAssicurazioneDescrizione: CustomJS.isNullEmpty(document.getElementById(`FormulaAssicurazione${elm.num}`).ej2_instances[0].text) ? '' : document.getElementById(`FormulaAssicurazione${elm.num}`).ej2_instances[0].text,
                                                franchigia: CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`FranchigiaEuro${elm.num}`).ej2_instances[0].value.toString(),
                                                scopertoPerc: CustomJS.isNullEmpty(document.getElementById(`ScopertoPerc${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoPerc${elm.num}`).ej2_instances[0].value.toString(),
                                                scopertoMin: CustomJS.isNullEmpty(document.getElementById(`ScopertoMinimo${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoMinimo${elm.num}`).ej2_instances[0].value.toString(),
                                                importato: '0'
                                            }
                                        }
                                        console.log(jsonObjectGaranziaGenerali)
                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectGaranziaGenerali), retDataGaranziaGen => {
                                            resolveGaranziaGEN();
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            }
                                            );
                                        });
                                    })
                                }
                                await crudGaranziaGEN()
                            }
                            let tempoPrec = tempoOne;
                            if (elm.stato !== 'd') {
                                console.log("gestione modifica garanzie")
                                this.keyGaranziePrecModificate.forEach(garMod => {
                                    if (garMod.key === elm.key) {
                                        //se è diversa la determinazione del danno è cambiata
                                        if (garMod.determinazioneDanno !== document.getElementById(`DeterminazioneDanno${elm.num}`).ej2_instances[0].value) {
                                            this.keyStimePrecModificate.forEach(stimaMod => {
                                                //rintraccio la stima dove era usata quella garanzia modificata
                                                if (stimaMod.codEleGaranzia === garMod.codEle) {
                                                    tempoPrec += tempoOne;
                                                    let jsonObjectStima = {}
                                                    //se prima era un valore a stato uso 
                                                    if (garMod.determinazioneDanno === '003') {
                                                        //elimino i campi riferiti allo stato uso
                                                        jsonObjectStima = {
                                                            QueryExec: `update _stime_xpert set DCA_valore_a_nuovo = null, DCA_degrado25 = null, DCA_valore_stato_uso = null,DRP_valore_a_nuovo = null, DRP_valore_a_nuovoMAN = null, DRP_degrado25 = null, DRP_degrado25MAN = null, DRP_valore_stato_uso = null, DRP_valore_stato_usoMAN = null, DN_valore_a_nuovo = null, DN_degrado25 = null, DN_valore_stato_uso = null, suppl_indenn_coass = null, vsu_coass = null where id_stima = ${stimaMod.keyXpert};`
                                                        }
                                                    }
                                                    //se prima era a nuovo
                                                    else if (garMod.determinazioneDanno === '001') {
                                                        jsonObjectStima = {
                                                            QueryExec: `update _stime_xpert set danno_compless_accertato = null, dopo_regola_proporzionale = null, dopo_regola_proporzionaleMAN = null where id_stima = ${stimaMod.keyXpert};`
                                                        }
                                                    }
                                                    console.log(jsonObjectStima)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectStima), update => {
                                                        //update anche delle righe 
                                                        this.strutturaStatiEliminaDati.idStimeDescrizioni.forEach(descr => {
                                                            if (descr.keyStima === stimaMod.keyXpert) {
                                                                let jsonObjectDescrizione = {}
                                                                if (garMod.determinazioneDanno === '003') {
                                                                    jsonObjectDescrizione = {
                                                                        QueryExec: `START TRANSACTION;update _stime_descrizioni set supp_indenn_perc = null, supp_indenn_importo = null , valore_del_bene = null where id_stima_descrizione = ${descr.keyXpert};update GENERALI_danno_dettagli set degrado_percentuale = 0, valore_unitario_residuo = 0 where id_dettaglio = ${descr.keyGen};COMMIT;`
                                                                    }
                                                                }
                                                                else if (garMod.determinazioneDanno === '001') {
                                                                    jsonObjectDescrizione = {
                                                                        QueryExec: `update _stime_descrizioni set supp_indenn_perc = 0,supp_indenn_importo = 0,valore_del_bene = totale where id_stima_descrizione = ${descr.keyXpert};`
                                                                    }
                                                                }
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDescrizione), update => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                            }
                                                        })
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });

                                                }
                                            })
                                        }
                                    }
                                })
                            }
                            setTimeout(() => {
                                resolve('Prossima');
                            }, tempoPrec)
                        })
                    }
                    funcListGaranzie.push(esegui);
                })

                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListGaranzie[ind])) {
                        resolveFineGaranzie();
                    }
                    else {
                        funcListGaranzie[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }
        await fineCrudGaranzie();

        console.log('inizio stime')
        const fineCrudStime = () => {
            return new Promise((resolveFineStime) => {
                //devo prendere gli id delle partite e garanzie nuove inserite
                let queryTrovaIdPartite = `SELECT id_partitaPeriziaOnline,guid_partita_temp FROM _partitePeriziaOnline WHERE id_sinistro = ${this.props.IdSinistro}`;
                let queryTrovaIdGaranzie = `SELECT id_garanziaPeriziaOnline,guid_garanzia_temp FROM _garanziePeriziaOnline WHERE id_sinistro = ${this.props.IdSinistro}`;
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryTrovaIdPartite, '', retDataIdPartite => {
                    retDataIdPartite = JSON.parse(retDataIdPartite.data);
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryTrovaIdGaranzie, '', retDataIdGaranzie => {
                        retDataIdGaranzie = JSON.parse(retDataIdGaranzie.data);

                        //-------elimino franchige condivise gia presenti-------------
                        this.strutturaStatiEliminaDati.idFranchige.forEach(idFranc => {
                            let query = `delete from _franchige_condivise_perizia where idFranchigiaCondivisa = ${idFranc};`;
                            let jsonObject = {
                                QueryExec: query,
                            }
                            console.log(jsonObject)
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), eliminato => {
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                }
                                );
                            });
                        })

                        //STIME/DANNI
                        let funcListStime = [];
                        this.strutturaStatiStime.forEach((elm) => {
                            const esegui = () => {
                                return new Promise(async (resolve) => {

                                    let guidUtileStima = CustomJS.createGuid4();
                                    let queryStimaXpert = '';
                                    let jsonObjectStimaXpert = {}
                                    let keyPartita = 0;
                                    let keyGaranzia = 0;
                                    let idDanneggiato = 0;
                                    let guidDanneggiatoGenerali = '';
                                    //dati per stima
                                    if (elm.stato !== 'd') {
                                        retDataIdPartite.forEach(elmPartite => {
                                            if (elmPartite.guid_partita_temp === document.getElementById(`PartitaTabellaStima${elm.num}`).ej2_instances[0].value)
                                                keyPartita = elmPartite.id_partitaPeriziaOnline;
                                        })
                                        retDataIdGaranzie.forEach(elmGaranzie => {
                                            if (elmGaranzie.guid_garanzia_temp === document.getElementById(`GaranziaTabellaStima${elm.num}`).ej2_instances[0].value)
                                                keyGaranzia = elmGaranzie.id_garanziaPeriziaOnline;
                                        })
                                        if (document.getElementById(`btnSwitchDanneggiato${elm.num}`).ej2_instances[0].children === '+') {
                                            //dropdown
                                            idDanneggiato = CustomJS.isNullEmpty(document.getElementById(`DanneggiatoTabellaStima${elm.num}`).ej2_instances[0].value) ? '0' : document.getElementById(`DanneggiatoTabellaStima${elm.num}`).ej2_instances[0].value.toString()
                                            guidDanneggiatoGenerali = CustomJS.isNullEmpty(document.getElementById(`DanneggiatoTabellaStima${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`DanneggiatoTabellaStima${elm.num}`).ej2_instances[0].itemData.GUID
                                        }
                                        else if (document.getElementById(`btnSwitchDanneggiato${elm.num}`).ej2_instances[0].children === '←') {
                                            //textbox
                                            let nomeDanneggiato = CustomJS.isNullEmpty(document.getElementById(`DanneggiatoAggiunto${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`DanneggiatoAggiunto${elm.num}`).ej2_instances[0].value;
                                            this.danneggiatiAggiunti.forEach(elmDanneggiati => {
                                                if (elmDanneggiati.TEXT === nomeDanneggiato) {
                                                    idDanneggiato = elmDanneggiati.VALUE;
                                                    guidDanneggiatoGenerali = elmDanneggiati.GUID
                                                }
                                            })
                                        }
                                    }
                                    const crudStimaXpert = () => {
                                        return new Promise((resolveStimaXpert) => {
                                            if (elm.stato === 'o') {
                                                queryStimaXpert = `START TRANSACTION;UPDATE _stime_xpert SET id_danneggiato = @idDanneggiato, id_partita = @idPartita, id_garanzia = @idGaranzia,limite_sinistro = (select limiteIndennizzoEuro from _garanziePeriziaOnline where id_garanziaPeriziaOnline = @idGaranzia for update) WHERE id_stima = ${elm.keyXpert};COMMIT;`;
                                            }
                                            else if (elm.stato === 'n') {
                                                queryStimaXpert = `START TRANSACTION;INSERT INTO _stime_xpert (id_sinistro,id_danneggiato,id_partita,id_garanzia,limite_sinistro,guid_utile) VALUES (@idSinistro,@idDanneggiato,@idPartita,@idGaranzia,(select limiteIndennizzoEuro from _garanziePeriziaOnline where id_garanziaPeriziaOnline = @idGaranzia for update),'${guidUtileStima}');COMMIT;`;
                                            }
                                            else if (elm.stato === 'd') {
                                                queryStimaXpert = `DELETE FROM _stime_xpert WHERE id_stima = ${elm.keyXpert};`
                                            }
                                            if (elm.stato === 'd') {
                                                jsonObjectStimaXpert = {
                                                    QueryExec: queryStimaXpert
                                                };
                                            }
                                            else {
                                                jsonObjectStimaXpert = {
                                                    QueryExec: queryStimaXpert,
                                                    idSinistro: this.props.IdSinistro.toString(),
                                                    idDanneggiato: idDanneggiato.toString(),
                                                    idPartita: keyPartita.toString(),
                                                    idGaranzia: keyGaranzia.toString()
                                                }
                                            }
                                            console.log(jsonObjectStimaXpert)
                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectStimaXpert), retDataStimaXpert => {
                                                resolveStimaXpert();
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                }
                                                );
                                            });
                                        })
                                    }
                                    await crudStimaXpert();
                                    //--------------------SALVA FRANCHIGIE CONDIVISE INIZIO--------------------
                                    //todo gestire operazioni crud franchigia condivisa!
                                    //5) SALVATAGGIO
                                    let countGruppo = 0;
                                    arrayFranchigeCondivise.forEach((gruppo) => {
                                        countGruppo++;
                                        gruppo.forEach(codEle => {
                                            let esegui = false;
                                            codEle.idContainer.forEach(container => {
                                                if (container === elm.id) {
                                                    esegui = true;
                                                }
                                            })
                                            if (esegui === true) {
                                                let idStima = "";
                                                if (CustomJS.isNullEmpty(elm.keyXpert))
                                                    idStima = `(select id_stima from _stime_xpert where guid_utile = "${guidUtileStima}" and id_sinistro = ${this.props.IdSinistro} for update)`;
                                                else
                                                    idStima = elm.keyXpert;
                                                let query = `START TRANSACTION; insert into _franchige_condivise_perizia (id_sinistro,gruppo,idStimaCondivisa,codEleGaranzia,tipo,franchigia,scopertoMin,scopertoMax,scopertoPerc) values (${this.props.IdSinistro},${countGruppo},${idStima},'${codEle.codEle}','${codEle.tipo}',${codEle.franchigia},${codEle.scopertoMin},${codEle.scopertoMax},${codEle.scopertoPerc}); COMMIT;`;
                                                let jsonObject = {
                                                    QueryExec: query,
                                                }
                                                console.log(jsonObject)
                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    }
                                                    );
                                                });
                                            }
                                        })
                                    })
                                    //--------------------SALVA FRANCHIGIE CONDIVISE FINE--------------------
                                    if (this.isCompagniaGenerali()) {
                                        const crudStimaGEN = () => {
                                            return new Promise((resolveStimaGEN) => {
                                                let queryStimaGenerali = ''
                                                let jsonObjectStimaGenerali = {}

                                                if (elm.stato === 'o') {
                                                    queryStimaGenerali = `UPDATE GENERALI_danni SET  id_partita_colpita = @guidPartita, id_garanzia_colpita = @guidGaranzia, id_danneggiato = @guidDanneggiato WHERE id_danno = ${elm.keyGenerali}`;
                                                }
                                                else if (elm.stato === 'n') {
                                                    queryStimaGenerali = `START TRANSACTION; INSERT INTO GENERALI_danni (id_incarico,id_stima_xpert,id_partita_colpita,id_garanzia_colpita,id_danneggiato) VALUES ('${this.props.IdIncarico}',(select id_stima from _stime_xpert where guid_utile = '${guidUtileStima}' and id_sinistro = ${this.props.IdSinistro} for update),@guidPartita, @guidGaranzia,@guidDanneggiato);COMMIT;`;
                                                }
                                                else if (elm.stato === 'd') {
                                                    queryStimaGenerali = `DELETE FROM GENERALI_danni WHERE id_danno = ${elm.keyGenerali};`
                                                }
                                                if (elm.stato === 'd') {
                                                    jsonObjectStimaGenerali = {
                                                        QueryExec: queryStimaGenerali
                                                    }
                                                }
                                                else {
                                                    jsonObjectStimaGenerali = {
                                                        QueryExec: queryStimaGenerali,
                                                        guidPartita: CustomJS.isNullEmpty(document.getElementById(`PartitaTabellaStima${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`PartitaTabellaStima${elm.num}`).ej2_instances[0].itemData.GUID,
                                                        guidGaranzia: CustomJS.isNullEmpty(document.getElementById(`GaranziaTabellaStima${elm.num}`).ej2_instances[0].value) ? '' : document.getElementById(`GaranziaTabellaStima${elm.num}`).ej2_instances[0].itemData.GUID,
                                                        guidDanneggiato: guidDanneggiatoGenerali,
                                                    }
                                                }
                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectStimaGenerali), retDataStimaGen => {
                                                    resolveStimaGEN();
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    }
                                                    );
                                                });
                                            })
                                        }
                                        await crudStimaGEN();

                                        if (elm.stato !== 'd') {
                                            //--------------------gestione infoRichieste se modifico garanzia inizio-----------------
                                            console.log("gestione modifica perizia info richieste")
                                            this.keyStimePrecModificate.forEach(precStima => {
                                                if (precStima.keyXpert === elm.keyXpert) {
                                                    if (precStima.codEleGaranzia !== document.getElementById(`GaranziaTabellaStima${elm.num}`).ej2_instances[0].itemData.COD_ELE) {
                                                        this.strutturaStatiEliminaDati.idInfoRichieste.forEach(info => {
                                                            if (info.idStima === elm.keyXpert) {
                                                                let jsonObject = {
                                                                    QueryExec: `START TRANSACTION; delete from _info_richieste_compagnia_riep where id_info_richieste_compagnia = ${info.keyXpert}; delete from GENERALI_danno_infoRichieste where id_info_richiesta = ${info.keyGen};COMMIT;`,
                                                                }
                                                                console.log(jsonObject)
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), eliminato => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                            }
                                                        })
                                                    }
                                                }
                                            })
                                        }
                                        else {
                                            //elimino tutto ciò che riguarda quella stima
                                            //STIME DESCRIZIONI / DANNO DETTAGLI
                                            this.strutturaStatiEliminaDati.idStimeDescrizioni.forEach(descr => {
                                                if (descr.keyStima === elm.keyXpert) {
                                                    let jsonObjectDescr = {
                                                        QueryExec: `START TRANSACTION; delete from _stime_descrizioni where id_stima_descrizione = ${descr.keyXpert}; delete from GENERALI_danno_dettagli where id_dettaglio = ${descr.keyGen};COMMIT;`,
                                                    }
                                                    console.log(jsonObjectDescr)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDescr), eliminato => {
                                                        //GENERALI DANNO DETTAGLI INFO RICHIESTE
                                                        this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.forEach(dettInfo => {
                                                            if (dettInfo.idDettaglio === descr.keyGen) {
                                                                let jsonObjectDettInfo = {
                                                                    QueryExec: `delete from GENERALI_dannoDettagli_infoRichieste where id_info_richiesta = ${dettInfo.idInfo};`,
                                                                }
                                                                console.log(jsonObjectDettInfo)
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDettInfo), eliminato => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                            }
                                                        })
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                }
                                            })
                                            //DANNO CAUSALI NON INDENNIZZO
                                            this.strutturaStatiEliminaDati.idCausali.forEach(causale => {
                                                if (causale.idDanno === elm.keyGenerali) {
                                                    let jsonObjectEliminaCaus = {
                                                        QueryExec: `DELETE FROM GENERALI_danno_causali_nonIndennizzo WHERE id_causale = ${causale.idCausale};`
                                                    }
                                                    console.log(jsonObjectEliminaCaus)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaCaus), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                }
                                            })
                                            //UPDATE SULLA GENERALI DANNEGGIATI IMPORTO LORDO E NETTO
                                            //UPDATE SULLA GENERALI DANNEGGIATI LIQUIDAZIONI
                                            //se elimino la stima a cui era riferito quel danneggiato di Generali cancello anche l' importo netto e lordo dal db
                                            this.strutturaStatiEliminaDati.idDanneggiatiGen.forEach(dann => {
                                                if (dann.keyXpert === elm.danneggiatoEliminato) {
                                                    let queryUpdateDanneggiatiGen = `START TRANSACTION;UPDATE GENERALI_danneggiati set importo_netto = null, importo_lordo = null WHERE id_danneggiato = '${dann.keyGen}';UPDATE GENERALI_danneggiato_liquidazioni set arrotondamento = 0, importo_liquidazione = 0 WHERE id_liquidazione = '${dann.keyGenLiquidazione}';COMMIT;`;
                                                    let jsonObjectUpdateDanneggiati = {
                                                        QueryExec: queryUpdateDanneggiatiGen,
                                                    }
                                                    console.log(jsonObjectUpdateDanneggiati)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectUpdateDanneggiati), retDataUpdate => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                }
                                            })
                                            //COASSICURAZIONI/ GENERALI COASSCIRAZIONI
                                            this.strutturaStatiEliminaDati.idCoassicurazioni.forEach(coass => {
                                                if (coass.keyStima === elm.keyXpert) {
                                                    let jsonObjectCoass = {
                                                        QueryExec: `START TRANSACTION;DELETE FROM _righeCompagnieCoassicurazione WHERE id_compagnia_coass = ${coass.keyXpert};DELETE FROM GENERALI_coassi_indirette WHERE id_coassi_indiretta = ${coass.keyGen};COMMIT;`
                                                    }
                                                    console.log(jsonObjectCoass)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCoass), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                }
                                            })
                                            //INFO RICHIESTE XPERT + GEN
                                            this.strutturaStatiEliminaDati.idInfoRichieste.forEach(info => {
                                                if (info.idStima === elm.keyXpert) {
                                                    let jsonObject = {
                                                        QueryExec: `START TRANSACTION; delete from _info_richieste_compagnia_riep where id_info_richieste_compagnia = ${info.keyXpert}; delete from GENERALI_danno_infoRichieste where id_info_richiesta = ${info.keyGen};COMMIT;`,
                                                    }
                                                    console.log(jsonObject)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                }
                                            })
                                            //RIEPILOGO LO ELIMINO TUTTO SE CANCELLO ANCHE SOLO UNA STIMA
                                            //RIEPILOGO
                                            let jsonObjectRiep = {
                                                QueryExec: `delete from _riepilogo_perizia where idRiepilogo_perizia = ${this.strutturaStatiEliminaDati.idRiepilogoPerizia};`,
                                            }
                                            console.log(jsonObjectRiep)
                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectRiep), eliminato => {
                                                //ELIMINO DANNEGGIATI RIEPILOGO
                                                this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.forEach(idDann => {
                                                    let jsonObjectRiepDann = {
                                                        QueryExec: `delete from _perizia_riepilogo_danneggiati where idDanneggiatoRiep = ${idDann};`,
                                                    }
                                                    console.log(jsonObjectRiepDann)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectRiepDann), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                })
                                                //ELIMINO RIGHE PARTITA GARANZIA PER OGNI DANNEGGIATO
                                                this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.forEach(idRiga => {
                                                    let jsonObjectDannRiga = {
                                                        QueryExec: `delete from _perizia_riep_righeDanneggiato where idGarPart = ${idRiga};`,
                                                    }
                                                    console.log(jsonObjectDannRiga)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDannRiga), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                })
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                }
                                                );
                                            });
                                        }
                                        //--------------------gestione infoRichieste se modifico garanzia fine-----------------
                                    }
                                    resolve('Prossima');
                                })
                            }
                            funcListStime.push(esegui);
                        })
                        const eseguiCiclica = (ind) => {
                            if (CustomJS.isNullEmpty(funcListStime[ind])) {
                                resolveFineStime();
                            }
                            else {
                                funcListStime[ind]().then(ret => {
                                    if (ret === 'Prossima') {
                                        eseguiCiclica(ind + 1);
                                    }
                                }).catch(err => {
                                    console.log(err)
                                })
                            }
                        }
                        eseguiCiclica(0);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    });
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                });
            })
        }
        await fineCrudStime();
        console.log("tempo salvataggio : " + tempoTotale)
        if (this.periziaSenzaCalcoli === false) {
            setTimeout(() => {
                this.paginaPeriziaOnlineCalcoli();
                this.toggleBlocking2(false);
            }, tempoTotale)
        }
        else {
            setTimeout(() => {
                this.paginaDettaglioSinistro();
                this.toggleBlocking2(false);
            }, tempoTotale)
        }
    }

    garanzieCondivise() {
        //1) = FRANCHIGE CONDIVISE SULLE GARANZIE
        let array = []
        this.strutturaStatiGaranzie.forEach(elm => {
            if (document.getElementById(`containerComboGaranzia${elm.num}`)) {
                if (!CustomJS.isNullEmpty(document.getElementById(`FranchigiaCondivisa${elm.num}`).ej2_instances[0].value)) {
                    let garanziaPartenza = document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].value
                    let guidPartenza = elm.guidTemp;
                    let garanziaCondivisa = document.getElementById(`FranchigiaCondivisa${elm.num}`).ej2_instances[0].value;
                    let guidCondivisa = "";

                    let franchigiaEuro = CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${elm.num}`).ej2_instances[0].value) ? 0 : document.getElementById(`FranchigiaEuro${elm.num}`).ej2_instances[0].value;
                    let scopMin = CustomJS.isNullEmpty(document.getElementById(`ScopertoMinimo${elm.num}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoMinimo${elm.num}`).ej2_instances[0].value;
                    let scopMax = CustomJS.isNullEmpty(document.getElementById(`ScopertoMassimo${elm.num}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoMassimo${elm.num}`).ej2_instances[0].value;
                    let scopPerc = CustomJS.isNullEmpty(document.getElementById(`ScopertoPerc${elm.num}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoPerc${elm.num}`).ej2_instances[0].value;

                    let tipologia = "";
                    if (document.getElementById(`sceltaFranchigia${elm.num}`).innerText === "→") {
                        tipologia = "franchigia";
                    }
                    else if (document.getElementById(`sceltaScoperto${elm.num}`).innerText === "→")
                        tipologia = "scoperto"

                    this.strutturaStatiGaranzie.forEach(trovaGuid => {
                        if (document.getElementById(`containerComboGaranzia${trovaGuid.num}`)) {
                            if (document.getElementById(`Garanzia${trovaGuid.num}`).ej2_instances[0].value === garanziaCondivisa) {
                                guidCondivisa = trovaGuid.guidTemp
                            }
                        }
                    })

                    let gruppoNuovo = [
                        { tipo: tipologia, codEle: garanziaPartenza, guid: guidPartenza, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc },
                        { tipo: tipologia, codEle: garanziaCondivisa, guid: guidCondivisa, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc }
                    ];

                    let inserisci = true;
                    array.forEach(gruppoVecchio => {
                        gruppoVecchio.forEach(elmVecchio => {
                            if (elmVecchio.codEle === garanziaPartenza) {
                                gruppoVecchio.push(
                                    { tipo: tipologia, codEle: garanziaCondivisa, guid: guidCondivisa, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc }
                                );
                                inserisci = false
                            }
                            else if (elmVecchio.codEle === garanziaCondivisa) {
                                gruppoVecchio.push(
                                    { tipo: tipologia, codEle: garanziaPartenza, guid: guidPartenza, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc }
                                );
                                inserisci = false
                            }
                        })
                    })
                    if (inserisci === true)
                        array.push(gruppoNuovo);
                }

            }
        })


        //2) = FRANCHIGE CONDIVISE SULLE GARANZIE E ANCHE SULLE STIME PERCHE HANNO LA STESSA GARANZIA
        this.strutturaStatiStime.forEach(stima => {
            if (document.getElementById(`containerComboStima${stima.num}`)) {
                array.forEach(gruppo => {
                    gruppo.forEach(elm => {
                        if (document.getElementById(`GaranziaTabellaStima${stima.num}`).ej2_instances[0].value === elm.guid) {
                            elm.idContainer.push(stima.id)
                        }
                    })
                })
            }
        })


        //3) : FRANCHIGE NON CONDIVISE MA CHE POSSONO ESSERLO SE SU PIU STIME
        let arrayGaranzieSingole = []
        this.strutturaStatiGaranzie.forEach(elm => {
            if (document.getElementById(`containerComboGaranzia${elm.num}`)) {
                let esiste = false;
                array.forEach(gruppo => {
                    gruppo.forEach(elm2 => {
                        if (elm2.codEle === document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].value) {
                            esiste = true;
                        }
                    })
                })
                if (esiste === false) {
                    let tipologia = "";

                    if (document.getElementById(`sceltaFranchigia${elm.num}`).innerText === "→") {
                        tipologia = "franchigia";
                    }
                    else if (document.getElementById(`sceltaScoperto${elm.num}`).innerText === "→") {
                        tipologia = "scoperto"
                    }

                    let garanzia = document.getElementById(`Garanzia${elm.num}`).ej2_instances[0].value
                    let guid = elm.guidTemp;

                    let franchigiaEuro = CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${elm.num}`).ej2_instances[0].value) ? 0 : document.getElementById(`FranchigiaEuro${elm.num}`).ej2_instances[0].value;
                    let scopMin = CustomJS.isNullEmpty(document.getElementById(`ScopertoMinimo${elm.num}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoMinimo${elm.num}`).ej2_instances[0].value;
                    let scopMax = CustomJS.isNullEmpty(document.getElementById(`ScopertoMassimo${elm.num}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoMassimo${elm.num}`).ej2_instances[0].value;
                    let scopPerc = CustomJS.isNullEmpty(document.getElementById(`ScopertoPerc${elm.num}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoPerc${elm.num}`).ej2_instances[0].value;

                    arrayGaranzieSingole.push(
                        { tipo: tipologia, codEle: garanzia, guid: guid, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc }
                    )
                }
            }
        })

        //4) :AGGIUNTA DELLE FRANCHIGE SINGOLE CONDIVISE TRA LORO SULLE STIME ALL ARRAY
        this.strutturaStatiStime.forEach(stima => {
            if (document.getElementById(`containerComboStima${stima.num}`)) {
                arrayGaranzieSingole.forEach(elm => {
                    if (document.getElementById(`GaranziaTabellaStima${stima.num}`).ej2_instances[0].value === elm.guid) {
                        elm.idContainer.push(stima.id)
                    }
                })

            }
        })

        arrayGaranzieSingole.forEach(garanziaSingola => {
            let arr = []
            arr.push(garanziaSingola)
            array.push(arr)
        })
        return array;
    }



    eliminaTuttiCalcoliPerizia() {
        this.toggleBlocking2(true);
        let tempoOneDelete = 75;
        //DATI QUESTA PAGINA
        let tempoPartite = tempoOneDelete * this.strutturaStatiEliminaDati.idPartite.length;
        let tempoGaranzie = tempoOneDelete * this.strutturaStatiEliminaDati.idGaranzie.length;
        let tempoStime = tempoOneDelete * this.strutturaStatiEliminaDati.idStime.length;
        //DATI PAGINA CALCOLI
        let tempoPreesistenze = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaXpert.length;
        let tempoPreesCostruz = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaCostruz.length;
        let tempoPreesDescr = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaDescr.length;
        let tempoPreesGen = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaGen.length;
        let tempoStimeDescr = tempoOneDelete * this.strutturaStatiEliminaDati.idStimeDescrizioni.length;
        let tempoDannoDettInfoRichGen = tempoOneDelete * this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.length;
        let tempoCoassicurazioni = tempoOneDelete * this.strutturaStatiEliminaDati.idCoassicurazioni.length;
        let tempoInfoRichiesteGen = tempoOneDelete * this.strutturaStatiEliminaDati.idInfoRichieste.length;
        let tempoIdDanneggiati = tempoOneDelete * this.strutturaStatiEliminaDati.idDanneggiatiGen.length;
        let tempoUnipol = tempoOneDelete;
        let tempoTotaliPerizia = tempoOneDelete;
        let tempoFranchige = tempoOneDelete * this.strutturaStatiEliminaDati.idFranchige.length;
        let tempoIdRiepilogo = tempoOneDelete;
        let tempoIdRiepilogoDanneggiati = tempoOneDelete * this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.length;
        let tempoIdRiepilogoRigheDanneggiato = tempoOneDelete * this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.length;
        let tempoIdCausali = tempoOneDelete * this.strutturaStatiEliminaDati.idCausali.length;
        //TOTALE
        let tempoTotale = tempoPartite + tempoGaranzie + tempoStime + tempoPreesistenze + tempoPreesCostruz + tempoPreesDescr + tempoPreesGen + tempoStimeDescr +
            tempoDannoDettInfoRichGen + tempoCoassicurazioni + tempoInfoRichiesteGen + tempoIdDanneggiati + tempoUnipol +
            tempoTotaliPerizia + tempoFranchige + tempoIdRiepilogo + tempoIdRiepilogoDanneggiati + tempoIdRiepilogoRigheDanneggiato + tempoIdCausali;


        console.log(this.strutturaStatiEliminaDati)
        //ELIMINO TABELLE QUESTA PAGINA
        this.strutturaStatiEliminaDati.idPartite.forEach(elm => {
            let queryEliminaPartite = `START TRANSACTION;DELETE FROM _partitePeriziaOnline WHERE id_partitaPeriziaOnline = ${elm.keyXpert};DELETE FROM GENERALI_polizza_partite WHERE id_partita = '${elm.keyGen}' and importato != 1;UPDATE GENERALI_polizza_partite set partita_codice = null,partita_descrizione = null,valore_assicurato = 0, valore_riferimento = 0,totale_preesistenza = 0,scopertura_percentuale = 0 WHERE id_partita = '${elm.keyGen}' and importato = 1;COMMIT;`;
            let jsonObjectEliminaPartite = {
                QueryExec: queryEliminaPartite,
            }
            console.log(jsonObjectEliminaPartite)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaPartite), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,

                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        this.strutturaStatiEliminaDati.idGaranzie.forEach(elm => {
            let queryEliminaGaranzie = `START TRANSACTION;DELETE FROM _garanziePeriziaOnline WHERE id_garanziaPeriziaOnline = ${elm.keyXpert};DELETE FROM GENERALI_polizza_garanzie WHERE id_garanzia = '${elm.keyGen}' and importato != 1;UPDATE GENERALI_polizza_garanzie set garanzia_codice = null,garanzia_descrizione = null,gruppo_garanzia = null,partita_assicurata = null,somma_assicurata = null,formaAssicurazione_codice = null,formaAssicurazione_descrizione = null,formulaAssicurazione_codice = null,formulaAssicurazione_descrizione = null,franchigia = 0,scoperto_percentuale = 0,scoperto_minimo = 0 WHERE id_garanzia = '${elm.keyGen}' and importato = 1;COMMIT;`;
            let jsonObjectEliminaGaranzie = {
                QueryExec: queryEliminaGaranzie,
            }
            console.log(jsonObjectEliminaGaranzie)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaGaranzie), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,

                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        this.strutturaStatiEliminaDati.idStime.forEach(elm => {
            let queryEliminaStime = `START TRANSACTION;DELETE FROM _stime_xpert WHERE id_stima = ${elm.keyXpert};DELETE FROM GENERALI_danni WHERE id_danno = ${elm.keyGen};COMMIT;`;
            let jsonObjectEliminaStime = {
                QueryExec: queryEliminaStime,
            }
            console.log(jsonObjectEliminaStime)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaStime), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,

                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        //ELIMINA PREESISTENZE
        this.strutturaStatiEliminaDati.idPreesistenzaXpert.forEach(elm => {
            let queryEliminaPreesistenze = `DELETE FROM _preesistenza_xpert WHERE id_preesistenza = ${elm.idPrees};`;
            let jsonObjectEliminaPreesistenze = {
                QueryExec: queryEliminaPreesistenze,
            }
            console.log(jsonObjectEliminaPreesistenze)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaPreesistenze), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        //ELIMINA PREESISTENZE COSTRUZIONI
        this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(elm => {
            let queryEliminaPreesistenzeCostr = `DELETE FROM _preesistenza_costruzioni WHERE id_preesistenza_costruzione = ${elm.idCostruz};`;
            let jsonObjectEliminaPreesistenzeCostr = {
                QueryExec: queryEliminaPreesistenzeCostr,
            }
            console.log(jsonObjectEliminaPreesistenzeCostr)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaPreesistenzeCostr), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        //ELIMINA PREESISTENZE DESCRIZIONI
        this.strutturaStatiEliminaDati.idPreesistenzaDescr.forEach(elm => {
            let queryEliminaPreesistenzeDescr = `DELETE FROM _preesistenza_descrizioni WHERE id_preesistenza_descrizione = ${elm.idDescr};`;
            let jsonObjectEliminaPreesistenzeDescr = {
                QueryExec: queryEliminaPreesistenzeDescr,
            }
            console.log(jsonObjectEliminaPreesistenzeDescr)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaPreesistenzeDescr), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        //ELIMINA PREESISTENZE GENERALI
        if (this.isCompagniaGenerali()) {
            this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(elm => {
                let queryEliminaPreesistenzeGen = `DELETE FROM GENERALI_partita_preesistenze WHERE id_preesistenza = ${elm.idGen};`;
                let jsonObjectEliminaPreesistenzeGen = {
                    QueryExec: queryEliminaPreesistenzeGen,
                }
                console.log(jsonObjectEliminaPreesistenzeGen)
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaPreesistenzeGen), retDataEliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            })
        }
        //ELIMINO STIME DESCRIZIONI
        this.strutturaStatiEliminaDati.idStimeDescrizioni.forEach(elm => {
            let queryEliminaStimaDescr = `DELETE FROM _stime_descrizioni WHERE id_stima_descrizione = ${elm.keyXpert}; DELETE FROM GENERALI_danno_dettagli WHERE id_dettaglio = ${elm.keyGen};`;
            let jsonObjectEliminaStimaDescr = {
                QueryExec: queryEliminaStimaDescr,
            }
            console.log(jsonObjectEliminaStimaDescr)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaStimaDescr), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        if (this.isCompagniaGenerali()) {
            //ELIMINO DANNO DETTAGLI INFO RICHIESTE
            this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.forEach(elm => {
                let queryEliminaDannDettInfoRich = `DELETE FROM GENERALI_dannoDettagli_infoRichieste WHERE id_info_richiesta = ${elm.idInfo};`;
                let jsonObjectEliminaDannDettInfoRich = {
                    QueryExec: queryEliminaDannDettInfoRich,
                }
                console.log(jsonObjectEliminaDannDettInfoRich)
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaDannDettInfoRich), retDataEliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            })
        }
        //ELIMINA COASSICURAZIONI
        this.strutturaStatiEliminaDati.idCoassicurazioni.forEach(elm => {
            let queryEliminaCoass = `DELETE FROM _righeCompagnieCoassicurazione WHERE id_compagnia_coass = ${elm.keyXpert}; DELETE FROM GENERALI_coassi_indirette WHERE id_coassi_indiretta = ${elm.keyGen};`;
            let jsonObjectEliminaCoass = {
                QueryExec: queryEliminaCoass,
            }
            console.log(jsonObjectEliminaCoass)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaCoass), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        //ELIMINA INFO RICHIESTE
        this.strutturaStatiEliminaDati.idInfoRichieste.forEach(elm => {
            let queryEliminaInfoRich = `DELETE FROM _info_richieste_compagnia_riep WHERE id_info_richieste_compagnia = ${elm.keyXpert}; DELETE FROM GENERALI_danno_infoRichieste WHERE id_info_richiesta = ${elm.keyGen};`;
            let jsonObjectEliminaInfoRich = {
                QueryExec: queryEliminaInfoRich,
            }
            console.log(jsonObjectEliminaInfoRich)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaInfoRich), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        if (this.isCompagniaGenerali()) {
            this.strutturaStatiEliminaDati.idDanneggiatiGen.forEach(elm => {
                let queryUpdateDanneggiatiGen = `START TRANSACTION;UPDATE GENERALI_danneggiati set importo_netto = null, importo_lordo = null WHERE id_danneggiato = '${elm.keyGen}';UPDATE GENERALI_danneggiato_liquidazioni set arrotondamento = 0, importo_liquidazione = 0 WHERE id_liquidazione = '${elm.keyGenLiquidazione}';COMMIT;`;
                let jsonObjectUpdateDanneggiati = {
                    QueryExec: queryUpdateDanneggiatiGen,
                }
                console.log(jsonObjectUpdateDanneggiati)
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectUpdateDanneggiati), retDataUpdate => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            })
        }
        //UPDATE UNIPOL PERIZIA IMPORTI
        if (this.isCompagniaUnipol()) {
            let queryUpdatePeriziaUnipol = `update UNIPOLSAI_Perizia set importoPeriziato = null, importoConcordato = null, importoLiquidabile = null, presenzaAltreAssicurazioniStessoRischio = null where id_sinistro_xpert = ${this.props.IdSinistro};`;
            let jsonObjectUpdateUnipol = {
                QueryExec: queryUpdatePeriziaUnipol,
            }
            console.log(jsonObjectUpdateUnipol)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectUpdateUnipol), retDataUpdate => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        }
        let queryUpdatePeriziaTot = `update _perizia_incarico_info set tot_indennizzo_lordo_compl = null, tot_indennizzo_netto_compl = null, tot_indennizzo_netto_compl_arrot = null, tot_danni_accertati = null, periziaNoCalcoli = 0 where id_sinistro = ${this.props.IdSinistro}`;
        let jsonObjectUpdateTot = {
            QueryExec: queryUpdatePeriziaTot,
        }
        console.log(jsonObjectUpdateTot)
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectUpdateTot), retDataUpdate => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
        //ELIMINO FRANCHIGE CONDIVISE
        this.strutturaStatiEliminaDati.idFranchige.forEach(idFranc => {
            let query = `delete from _franchige_condivise_perizia where idFranchigiaCondivisa = ${idFranc};`;
            let jsonObject = {
                QueryExec: query,
            }
            console.log(jsonObject)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), eliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        //ELIMINO DATI DAL RIEPILOGO
        //RIEPILOGO
        let queryRiep = `delete from _riepilogo_perizia where idRiepilogo_perizia = ${this.strutturaStatiEliminaDati.idRiepilogoPerizia};`;
        let jsonObjectRiep = {
            QueryExec: queryRiep,
        }
        console.log(jsonObjectRiep)
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectRiep), eliminatoRiep => {
            //ELIMINO DANNEGGIATI RIEPILOGO
            this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.forEach(idDann => {
                let query = `delete from _perizia_riepilogo_danneggiati where idDanneggiatoRiep = ${idDann};`;
                let jsonObject = {
                    QueryExec: query,
                }
                console.log(jsonObject)
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), eliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            })
            //ELIMINO RIGHE PARTITA GARANZIA PER OGNI DANNEGGIATO
            this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.forEach(idRiga => {
                let query = `delete from _perizia_riep_righeDanneggiato where idGarPart = ${idRiga};`;
                let jsonObject = {
                    QueryExec: query,
                }
                console.log(jsonObject)
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), eliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            })
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
        //ELIMINA CAUSALI
        this.strutturaStatiEliminaDati.idCausali.forEach(elm => {
            let queryEliminaCaus = `DELETE FROM GENERALI_danno_causali_nonIndennizzo WHERE id_causale = ${elm.idCausale};`;
            let jsonObjectEliminaCaus = {
                QueryExec: queryEliminaCaus,
            }
            console.log(jsonObjectEliminaCaus)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaCaus), retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })

        this.distruggiDialog();
        setTimeout(() => {
            this.dialogElaboratoInstance.hide();
            this.toggleBlocking2(false);
            toast.success("Perizia ripristinata!", {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            const container = document.getElementById('contenutoDinamico2');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
            }}><PaginaPeriziaOnline key={this.childKey} restituito={this.props.restituito} IdSinistro={this.props.IdSinistro} IdIncarico={this.props.IdIncarico} IdIncaricoUnipol={this.props.IdIncaricoUnipol} IdCompagnia={this.props.IdCompagnia} tipoSinistro={this.props.tipoSinistro} numeroPolizza={this.props.numeroPolizza}></PaginaPeriziaOnline></CallbackWrapper>);
        }, tempoTotale)
    }

    //-------------------------------GESTIONE SALVATAGGIO FINE-------------------------------

    //-----------------------GESTIONE CAMBIO PAGINA INIZIO-----------------------

    distruggiDialog() {
        if (document.getElementById('dialogElaboratoPeritale'))
            document.getElementById('dialogElaboratoPeritale').ej2_instances[0]?.destroy();
    }

    paginaDettaglioSinistro() {
        ++this.childKey;
        document.getElementById('paginaDettaglioFromPO1').ej2_instances[0].disabled = true;
        const container = document.getElementById('contenutoDinamico2');
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
        }}><PaginaDettaglioSinistro key={this.childKey} IdIncarico={this.props.IdIncarico} IdSinistro={this.props.IdSinistro} IdCompagnia={this.props.IdCompagnia}></PaginaDettaglioSinistro></CallbackWrapper>);
    }

    paginaPeriziaOnlineCalcoli() {
        ++this.childKey;
        document.getElementById('tabelleCalcoloDaPaerizia1').ej2_instances[0].disabled = true;
        const container = document.getElementById('contenutoDinamico2');
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
            this.distruggiDialog();
        }}><PaginaPeriziaOnlineCalcoli restituito={this.props.restituito} key={this.childKey} IdIncarico={this.props.IdIncarico} IdIncaricoUnipol={this.props.IdIncaricoUnipol} IdSinistro={this.props.IdSinistro} IdCompagnia={this.props.IdCompagnia} numeroPolizza={this.props.numeroPolizza} tipoSinistro={this.props.tipoSinistro}></PaginaPeriziaOnlineCalcoli></CallbackWrapper>);
    }
    //-----------------------GESTIONE CAMBIO PAGINA FINE-----------------------

    //CONTROLLI PER COMPAGNIA VALIDI SOLO PER I SINISTRI IMPORTATI!
    isCompagniaGenerali() {
        return this.props.IdCompagnia.toString() === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(this.props.IdIncarico)
    }
    isCompagniaUnipol() {
        return this.props.IdCompagnia.toString() === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(this.props.IdIncaricoUnipol)
    }
}