/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { Query } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { PdfViewerComponent, Toolbar as PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject as PDFVInject } from '@syncfusion/ej2-react-pdfviewer';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Browser, getValue } from '@syncfusion/ej2-base';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';
import { beforeFileUpload } from '@syncfusion/ej2-react-richtexteditor';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

export default class PaginaModelliDocumento extends SampleBase {
    constructor() {
        super(...arguments);
        this.dsCompagnia = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_compagnie'))?.map(elm => { return { VALUE: elm.id_compagnia.toString(), TEXT: elm.compagnia } });
        this.dsCategoria = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_cat_moduli'))?.map(elm => { return { VALUE: elm.idcat.toString(), TEXT: `${elm.nome} (${elm.tipo})` } });
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.childKey = 0;
        this.editSettings = { allowAdding: this.controlloUtente(), allowEditing: this.controlloUtente(), allowDeleting: this.controlloUtente(), showDeleteConfirmDialog: true, mode: 'Normal' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.filterSettings = { type: 'CheckBox' };
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        this.groupSettings = {
            columns: ['categoriaDescr'], 
            showGroupedColumn: false,
            captionTemplate: this.groupTemplate.bind(this)
        };
        this.nuovoFileFisico = '';
        this.commands = [{ type: "Scarica Documento", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" } },
                        { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } },
                        { type: "Scarica Documento", buttonOption: { iconCss: "fas fa-download fa-10x", cssClass: "e-details" } }];
        this.asyncSettings = {
            saveUrl: `ChiamateAPISIPAEVO/GestioneAllegati/Upload/MODELLI_DOCUMENTO_XPERT/[NO]/[NO]/[NO]/[NO]`,
            removeUrl: `ChiamateAPISIPAEVO/GestioneAllegati/Remove/MODELLI_DOCUMENTO_XPERT/[NO]/[NO]/[NO]/[NO]`
        }
        this.DdlObj1 = null;
        this.elemSelezCompagnia = null;
        this.DdlObj2 = null;
        this.elemSelezCategoria = null;
        this.editTemplate1 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => {
                return this.DdlObj1.value
            },
            destroy: () => { this.DdlObj1.destroy() },
            write: (args) => {
                this.elemSelezCompagnia = args.rowData.compagnia; this.DdlObj1 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Compagnia', popupHeight: '200px',
                    dataSource: this.dsCompagnia, value: args.rowData.compagnia, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains'
                });
                if (CustomJS.isNullEmpty(this.DdlObj1.value)) {
                    if (!CustomJS.isNullEmpty(args.rowData.id_compagnia)) {
                        this.DdlObj1.value = args.rowData.id_compagnia.toString();
                    }
                }
                this.DdlObj1.appendTo(args.element)
            }
        };
        this.editTemplate2 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => {
                return this.DdlObj2.value
            },
            destroy: () => { this.DdlObj2.destroy() },
            write: (args) => {
                this.elemSelezCategoria = args.rowData.categoria; this.DdlObj2 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Categoria', popupHeight: '200px',
                    dataSource: this.dsCategoria, value: args.rowData.categoria?.toString(), floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains'
                });
                if (CustomJS.isNullEmpty(this.DdlObj2.value)) {
                    if (!CustomJS.isNullEmpty(args.rowData.categoria)) {
                        this.DdlObj2.value = args.rowData.categoria.toString();
                    }
                }
                this.DdlObj2.appendTo(args.element)
            }
        };
        this.objRecord = {};
        this.azione = '';
    }
    state = {
        blocking: false,
        showDialog1: false,
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    descrizioniCombo(args) {
        if (args.column.field === "id_compagnia" && args.data && args.cell) {
            args.cell.innerText = '';
            if (getValue('id_compagnia', args.data)) {
                this.dsCompagnia.forEach(element => {
                    if (element.VALUE === getValue('id_compagnia', args.data).toString()) {
                        args.cell.innerHTML = element.TEXT;
                    }
                });
            }
        }
        if (args.column.field === "categoria" && args.data && args.cell) {
            args.cell.innerText = '';
            if (getValue('categoria', args.data)) {
                this.dsCategoria.forEach(element => {
                    if (element.VALUE === getValue('categoria', args.data).toString()) {
                        args.cell.innerHTML = element.TEXT;
                    }
                });
            }
        }
    }
    groupTemplate(props) {
        if (props.field === 'categoria')
            return (<div>
                <span style={{ fontWeight: 600 }}>{_.find(this.dsCategoria, elm => { return elm.VALUE === props.items[0].categoria.toString(); }).TEXT} - {props.count} Articoli</span>
            </div>);
        else if (props.field === 'categoriaDescr')
            return (<div>
                <span style={{ fontWeight: 600 }}>{props.items[0].categoriaDescr} - {props.count} Articoli</span>
            </div>);
    }  
    onMinimizeDialogOpen(titolo, minWidth, minPosY, initMinHeight, dialogInstance) {
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-restore")?.classList.remove('fa-window-restore');
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize").setAttribute('title', 'Minimizza Finestra');
        dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
        dialogInstance.position = { X: 'center', Y: '70' };
        dialogInstance.allowDragging = true;
        document.getElementById(dialogInstance.id).style.height = initMinHeight;
        document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
        this.minBtn = document.getElementById(`min-btn-${dialogInstance.id}`);
        this.minBtn.onclick = (e) => {
            let minimizeIcon = dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize");
            if (!dialogInstance.element.classList.contains('e-dlg-fullscreen')) {
                if (!dialogInstance.element.classList.contains('dialog-minimized')) {
                    this.dialogOldPositions = { X: dialogInstance.position.X, Y: dialogInstance.position.Y };
                    this.dialogOldWidth = dialogInstance.width;
                    dialogInstance.element.classList.add('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.add('hide-content');
                    dialogInstance.position = { X: 'right', Y: minPosY };
                    dialogInstance.width = minWidth;
                    dialogInstance.height = '100px';
                    document.getElementById(dialogInstance.id).style.minHeight = '3%';
                    //dialogInstance.minHeight = '100px';
                    dialogInstance.dataBind();
                    minimizeIcon.classList.add('fa-window-restore');
                    minimizeIcon.setAttribute('title', 'Ripristina Finestra');
                }
                else {
                    dialogInstance.element.classList.remove('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.remove('hide-content');
                    minimizeIcon.classList.add('fa-window-minimize');
                    minimizeIcon.setAttribute('title', 'Minimizza Finestra');
                    minimizeIcon.classList.remove('fa-window-restore');
                    dialogInstance.position = this.dialogOldPositions;
                    dialogInstance.width = this.dialogOldWidth;
                    document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
                    document.getElementById(`dialog-title-${dialogInstance.id}`).innerHTML = titolo;
                    dialogInstance.dataBind();
                }
            }
            else {
                dialogInstance.show(false);
                dialogInstance.element.classList.remove('dialog-maximized');
                dialogInstance.element.classList.add('dialog-minimized');
                dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                minimizeIcon.classList.remove('fa-window-minimize');
                minimizeIcon.removeAttribute('title');
                dialogInstance.position = { X: 'center', Y: 'bottom' };
                dialogInstance.dataBind();
                this.isFullScreen = true;
            }
        };
    }
    PDFViewerRender() {
        ++this.childKey;
        return (<React.Fragment>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-content">
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <PdfViewerComponent id={`PDFViewer`} key={this.childKey} width="auto" style={{ 'height': '720px' }}
                                                enablePrint={true} enableDownload={true} enableAnnotation={false} enableCommentPanel={false} serviceUrl={`${CustomJS.APIVariousJfishXpertURLProd}/api/PdfViewer`}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
                                            </PdfViewerComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    onUploading(args) {
        args.customFormData = [{ 'nomeFileOriginale': this.nuovoFileFisico }];
        args.currentRequest.setRequestHeader("Authorization", CustomJS.tokenAuthAPIJellyfishSIPAEVO);
    }
    actionComplete(args) {
        if ((args.action === "edit" && args.requestType === "save")) {
            let descrizioneSplit = args.rowData.nome_file_fisico.split(".")[0];
            if (args.data.descrizione !== descrizioneSplit) {
                this.dialogInstance1.content = `<h3>Sei sicuro di voler fare l'upload di un nuovo file?<h3>`;
                this.dialogInstance1.width = '38%';
                this.dialogInstance1.height = '28%';
                this.dialogInstance1.minHeight = '28%';
                this.dialogInstance1.buttons = [
                    {
                        click: () => {
                            this.confermaUpload();
                            this.dialogInstance1.hide();
                        },
                        buttonModel: {
                            content: 'CONFERMA',
                            cssClass: 'e-primary',
                            isPrimary: true
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance1.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance1.show();
            }
            else {
                var dataObj = {}
                if (CustomJS.isNullEmpty(args.data[0]))
                    dataObj = args.data;
                else
                    dataObj = args.data[0];   
                const jsonObject = {
                    CodTab: '_modelliDocumento_xpert',
                    Azione: 'Edit',
                    id_modello: CustomJS.isNullEmpty(dataObj.id_modello) ? null : dataObj.id_modello.toString(),
                    id_compagnia: CustomJS.isNullEmpty(dataObj.id_compagnia) ? '0' : dataObj.id_compagnia.toString(),
                    id_categoria: CustomJS.isNullEmpty(dataObj.categoria) ? '0' : dataObj.categoria.toString(),
                    descrizione: CustomJS.isNullEmpty(dataObj.descrizione) ? "" : dataObj.descrizione,
                    nome_file_fisico: CustomJS.isNullEmpty(dataObj.nome_file_fisico) ? "" : dataObj.nome_file_fisico,
                }
                CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
                    'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                        this.caricaDati();
                        if (retData.response === 'KO') {
                            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer2',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            return;
                        }
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        })
                    }, true, false);
    
            }
        }
        if ((args.action === "add" && args.requestType === "save")) {
            this.confermaUpload();
        }
        if (args.requestType === 'delete'){
            const jsonObject = {
                CodTab: '_modelliDocumento_xpert',
                Azione: 'Delete',
                id_modello: CustomJS.isNullEmpty(args.data[0].id_modello) ? null : args.data[0].id_modello.toString(),
                id_compagnia: CustomJS.isNullEmpty(args.data[0].id_compagnia) ? '0' : args.data[0].id_compagnia.toString(),
                id_categoria: CustomJS.isNullEmpty(args.data[0].categoria) ? '0' : args.data[0].categoria.toString(),
                descrizione: CustomJS.isNullEmpty(args.data[0].descrizione) ? "" : args.data[0].descrizione,
                nome_file_fisico: CustomJS.isNullEmpty(args.data[0].nome_file_fisico) ? "" : args.data[0].nome_file_fisico,
            }
            CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    this.caricaDati();
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
        }
        this.objRecord = args.data;
        this.azione =  args.action;
    }
    confermaUpload() {
        this.dialogInstance1.hide();
        document.getElementById('uploadModelliDocumento').querySelector('button.e-css.e-btn').click();
    }
    onSuccess(args) {
        const jsonObject = {
            CodTab: '_modelliDocumento_xpert',
            Azione: this.azione === "edit" ? 'Edit' : this.azione === "add" ? 'Insert' : 'AzioneNonPrevista',
            id_modello: CustomJS.isNullEmpty(this.objRecord.id_modello) ? null : this.objRecord.id_modello.toString(),
            id_compagnia: CustomJS.isNullEmpty(this.objRecord.id_compagnia) ? '0' : this.objRecord.id_compagnia.toString(),
            id_categoria: CustomJS.isNullEmpty(this.objRecord.categoria) ? '0' : this.objRecord.categoria.toString(),
            descrizione: CustomJS.isNullEmpty(this.objRecord.descrizione) ? "" : this.objRecord.descrizione,
            nome_file_fisico: this.nuovoFileFisico,
        }
        CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
            'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                this.caricaDati();
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    return;
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }, true, false);
    }
    onSelected(args) {
        let estensione = args.filesData[0].type;
        this.nuovoFileFisico = `${this.objRecord.descrizione}.${estensione}`;
        if (args.filesData.length === 0) {
            this.caricaDati();
            return;
        }
    }
    controlloUtente() {
        if (!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabellaCompagnieReferente'))) || JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 1) {
            return true;
        }
        else {
            return false;
        }
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {
            this.toggleBlocking();
            const nomeFileFisico = args.rowData.nome_file_fisico;
            CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/MODELLI_DOCUMENTO_XPERT/[NO]/[NO]/[NO]/[NO]?nomeFileDownload=${nomeFileFisico}`, {}, 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    this.dialogInstance1.header = `<div><span id='dialog-title-${this.dialogInstance1.id}' class='title'>VISUALIZZAZIONE DOCUMENTO</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance1.id}' title='Minimizza la finestra'></span></div>`;
                    this.dialogInstance1.width = '65%';
                    this.dialogInstance1.height = '90%';
                    this.dialogInstance1.minHeight = '90%';
                    this.dialogInstance1.buttons = [];
                    this.dialogInstance1.beforeOpen = (args) => {
                        const container = document.getElementById('defaultDialogContentModDoc');
                        const root = createRoot(container);
                        root.unmount();
                    };
                    this.dialogInstance1.open = (args) => {
                        this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO`, '440px', '240', '90%', this.dialogInstance1);
                        const container = document.getElementById('defaultDialogContentModDoc');
                        const root = createRoot(container);
                        root.render(<CallbackWrapper callback={() => {
                            setTimeout(() => {
                                const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${retData.data}`;
                                document.getElementById(`IframeDocumento`).src = URLVisualOffice365;
                                this.toggleBlocking();
                            }, 100);
                        }}>{this.otherDocumentRender()}</CallbackWrapper>);
                    };
                    this.dialogInstance1.show();
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                }, true, false);
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-download') > -1) {
            this.toggleBlocking();
            const nomeFileFisico = args.rowData.nome_file_fisico;
            CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/MODELLI_DOCUMENTO_XPERT/[NO]/[NO]/[NO]/[NO]?nomeFileDownload=${nomeFileFisico}`, {}, 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    this.toggleBlocking();
                    let element = document.createElement('a');
                    element.setAttribute('href', `${retData.data}`);
                    element.setAttribute('download', nomeFileFisico);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                }, true, false);
        }
    }
    otherDocumentRender() {
        return (<React.Fragment>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-content">
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">
                                            <iframe id={`IframeDocumento`} title='Documenti' src='' style={{ width: '950px', height: '750px', border: '1px solid black' }}></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialogModelliDocumento" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance1 = dialog} isModal={true} height='90%' width='65%' minHeight='90%'>
                    <div id='defaultDialogContentModDoc'></div>
                </DialogComponent>
                <div className='container-fluid'>
                    <div className='row' >
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <div id='uploadModelliDocumento'><UploaderComponent id='documenti' autoUpload={true} ref={upload => { this.uploadObj = upload }} allowedExtensions='.docx, .xls, .xlsx, .xlsm' multiple={false} success={this.onSuccess.bind(this)} asyncSettings={this.asyncSettings} uploading={this.onUploading.bind(this)} selected={this.onSelected.bind(this)} /></div>
                            <GridComponent width={'100%'} id="gridModelliDocumento" queryCellInfo={this.descrizioniCombo.bind(this)} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} hierarchyPrintMode='All' allowSelection={true} enableHover={false} showColumnMenu={false} showColumnChooser={false}
                                allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} allowPaging={true} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings} groupSettings={this.groupSettings} sortSettings={{ columns: [{ field: 'categoriaDescr', direction: 'Ascending' }] }}
                                allowGrouping={true} allowExcelExport={true} editSettings={this.editSettings} filterSettings={this.filterSettings} style={{ border: '0px' }} commandClick={this.onCommandClick.bind(this)}
                                actionComplete={this.actionComplete.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id_modello' visible={false}></ColumnDirective>
                                    <ColumnDirective field='id_compagnia' headerText="Compagnia" edit={this.editTemplate1} width='20%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='descrizione' headerText="Descrizione" validationRules={{ required: true }} width='20%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='categoria' headerText="Categoria" edit={this.editTemplate2} validationRules={{ required: true }} width='20%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='categoriaDescr' visible={false}></ColumnDirective>
                                    <ColumnDirective field='nome_file_fisico' headerText="Nome File" width='30%' allowEditing={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='Comandi' commands={this.commands} headerText="" width='10%' headerTemplate={headerTemplate} allowFiltering={false} textAlign='Right'></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, Edit, ExcelExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        document.getElementById('uploadModelliDocumento').style.display = 'none';
        setTimeout(() => {
            this.caricaDati();
        }, 300);
    }
    caricaDati() {
        this.toggleBlocking();
        CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, '_modelliDocumento_xpert', false, retData => {
            document.getElementById('gridModelliDocumento').ej2_instances[0].dataSource = JSON.parse(retData.data);
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, 'SELECT T1.*, CONCAT(T2.nome,\' (\',T2.tipo,\')\') AS categoriaDescr FROM _modelliDocumento_xpert T1 INNER JOIN _cat_moduli T2 ON T1.categoria = T2.idcat ORDER BY T1.id_modello DESC;', null);
    }
}