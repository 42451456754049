import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { NavMenu } from './NavMenu';
import PaginaHome from './PaginaHome';
import IdleTimeout2 from './IdleTimeout2';

export class Layout extends Component {

  static displayName = Layout.name;

  render() {
    return (<React.Fragment>
      <NavMenu />
      <div id="bgImageDiv2"></div>
      <div id='contenutoDinamico2'></div>
      <IdleTimeout2></IdleTimeout2>
    </React.Fragment>);
  }

  componentDidMount() {
    const container = document.getElementById('contenutoDinamico2');
    const root = createRoot(container);
    root.render(<CallbackWrapper callback={() => {
      sessionStorage.setItem("JellyfishXpertPaginaLogin", "false");
    }}><PaginaHome></PaginaHome></CallbackWrapper>);
  }
}