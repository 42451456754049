/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';
import { ButtonComponent, CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';



function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}
export default class PaginaUtenti extends SampleBase {
    constructor() {
        super(...arguments);

        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.childKey = 0;

        this.editSettings = {
            allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog', template: this.dialogTemplate.bind(this)
        };
        this.dsProvince = JSON.parse(sessionStorage.getItem('JellyfishXpertTabellaProvince'));
        this.dsSedi = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_sedi'))?.map(elm => { return { VALUE: elm.idparam.toString(), TEXT: elm.valore } });
        this.dsClassi = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_param_user'))?.map(elm => { return { VALUE: elm.idparam.toString(), TEXT: elm.valore } });

        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.filterSettings = { type: 'CheckBox' };
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        this.idMaxUser = '';
        //province
        this.DdlObj = null;
        this.elemSelezProvincia = null;
        this.editTemplateProvince = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => {
                return this.DdlObj.value
            },
            destroy: () => { this.DdlObj.destroy() },
            write: (args) => {
                this.elemSelezProvincia = args.rowData.provincia; this.DdlObj = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Provincia', popupHeight: '200px',
                    dataSource: JSON.parse(sessionStorage.getItem('JellyfishXpertTabellaProvince')), value: args.rowData.provincia, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.provincia = args2.value;
                        this.elemSelezProvincia = args2.value;
                    }
                });
                if (CustomJS.isNullEmpty(this.DdlObj.value)) {
                    this.DdlObj.value = args.rowData.prov;
                }
                this.DdlObj.appendTo(args.element)
            },
        };
        //sedi
        this.DdlObj2 = null;
        this.elemSelezSedi = null;
        this.editTemplateSedi = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => {
                return this.DdlObj2.value
            },
            destroy: () => { this.DdlObj2.destroy() },
            write: (args) => {
                this.elemSelezSedi = args.rowData.sedi; this.DdlObj2 = new DropDownList({
                    fields: { text: 'valore', value: 'idparam' }, placeholder: 'Sedi Partenza', popupHeight: '200px',
                    dataSource: JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_sedi')), value: args.rowData.sedi, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.sedi = args2.value;
                        this.elemSelezSedi = args2.value;
                    }
                });
                if (CustomJS.isNullEmpty(this.DdlObj2.value)) {
                    this.DdlObj2.value = args.rowData.sede_partenza;
                }
                this.DdlObj2.appendTo(args.element)
            },
        };
        //classe altri rami elementari
        this.DdlObj3 = null;
        this.elemSelezClasseRamiElem = null;
        this.editTemplateClasseRamiElem = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => {
                return this.DdlObj3.value
            },
            destroy: () => { this.DdlObj3.destroy() },
            write: (args) => {
                this.elemSelezClasseRamiElem = args.rowData.classeRamiElem; this.DdlObj3 = new DropDownList({
                    fields: { text: 'valore', value: 'idparam' }, placeholder: "Classe Rami Elementari", popupHeight: '200px',
                    dataSource: JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_param_user')), value: args.rowData.classeRamiElem, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.classeRamiElem = args2.value;
                        this.elemSelezClasseRamiElem = args2.value;
                    }
                });
                if (CustomJS.isNullEmpty(this.DdlObj3.value))
                    this.DdlObj3.value = args.rowData.classe;

                this.DdlObj3.appendTo(args.element)
            },
        };
        //classe furto
        this.DdlObj4 = null;
        this.elemSelezClasseFurto = null;
        this.editTemplateClasseFurto = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => {
                return this.DdlObj4.value
            },
            destroy: () => { this.DdlObj4.destroy() },
            write: (args) => {
                this.elemSelezClasseFurto = args.rowData.classeFurto; this.DdlObj4 = new DropDownList({
                    fields: { text: 'valore', value: 'idparam' }, placeholder: "Classe Furto", popupHeight: '200px',
                    dataSource: JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_param_user')), value: args.rowData.classeFurto, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.classeFurto = args2.value;
                        this.elemSelezClasseFurto = args2.value;
                    }
                });
                if (CustomJS.isNullEmpty(this.DdlObj4.value))
                    this.DdlObj4.value = args.rowData.classe_furto;

                this.DdlObj4.appendTo(args.element)
            },
        };
        //classe acqua condotta
        this.DdlObj5 = null;
        this.elemSelezClasseAcquaCond = null;
        this.editTemplateClasseAcquaCond = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => {
                return this.DdlObj5.value
            },
            destroy: () => { this.DdlObj5.destroy() },
            write: (args) => {
                this.elemSelezClasseAcquaCond = args.rowData.classeAcquaCond; this.DdlObj5 = new DropDownList({
                    fields: { text: 'valore', value: 'idparam' }, placeholder: "Classe Acqua Condotta", popupHeight: '200px',
                    dataSource: JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_param_user')), value: args.rowData.classeAcquaCond, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.classeAcquaCond = args2.value;
                        this.elemSelezClasseAcquaCond = args2.value;
                    }
                });
                if (CustomJS.isNullEmpty(this.DdlObj5.value))
                    this.DdlObj5.value = args.rowData.classe_ac;

                this.DdlObj5.appendTo(args.element)
            },
        };
        //classe RC terzi
        this.DdlObj6 = null;
        this.elemSelezClasseRCTerzi = null;
        this.editTemplateRCT = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => {
                return this.DdlObj6.value
            },
            destroy: () => { this.DdlObj6.destroy() },
            write: (args) => {
                this.elemSelezClasseRCTerzi = args.rowData.classeRCT; this.DdlObj6 = new DropDownList({
                    fields: { text: 'valore', value: 'idparam' }, placeholder: "Classe Acqua Condotta", popupHeight: '200px',
                    dataSource: JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_param_user')), value: args.rowData.classeRCT, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.classeRCT = args2.value;
                        this.elemSelezClasseRCTerzi = args2.value;
                    }
                });
                if (CustomJS.isNullEmpty(this.DdlObj6.value))
                    this.DdlObj6.value = args.rowData.classe_rct;

                this.DdlObj6.appendTo(args.element)
            },
        };
    }

    state = {
        blocking: false,
        showDialog1: false,
    }

    onChange(args) {
        this.setState({ [args.target.name]: args.target.value });
    }

    dialogTemplate(props) {
        this.state = Object.assign({}, props);
        const data = this.state;
        return <>
            <div className="e-card-resize-container stile_utenti_dialog">
                <div className="row card-layout">
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="denom" name='denom' className="e-input " type="text" defaultValue={!data.isAdd ? data.denom : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Nominativo</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="nome" name='nome' className="e-input" type="text" defaultValue={!data.isAdd ? data.nome : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Nome</label>
                                </div>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="cognome" name='cognome' className="e-input" type="text" defaultValue={!data.isAdd ? data.cognome : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Cognome</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="email" name='email' className="e-input" type="text" defaultValue={!data.isAdd ? data.email : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Email</label>
                                </div>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="codice_fiscale" name='codice_fiscale' className="e-input" type="text" defaultValue={!data.isAdd ? data.codice_fiscale : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Codice Fiscale</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="telefono" name="telefono" className="e-input" type="text" defaultValue={!data.isAdd ? data.telefono : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Telefono</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="telefono_interno" name="telefono_interno" className="e-input" type="text" defaultValue={!data.isAdd ? data.telefono_interno : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Telefono 2</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="indirizzo" name="indirizzo" className="e-input" type="text" defaultValue={!data.isAdd ? data.indirizzo : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Indirizzo</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="citta" name="citta" className="e-input" type="text" defaultValue={!data.isAdd ? data.citta : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Cttà</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <div style={{ marginTop: '15px', paddingTop: '15px' }}>
                                        <DropDownListComponent id='provincia' value={!data.isAdd ? data.provincia : ''} fields={this.ddlFields} dataSource={this.dsProvince} onChange={this.onChange.bind(this)}
                                            filterType='Contains' floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
                                    </div>
                                    <label className="e-float-text e-label-top dropdown-label">Provincia</label>
                                </div>
                            </div>
                            <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="cap" name="cap" className="e-input" type="text" defaultValue={!data.isAdd ? data.cap : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">CAP</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="psw" name="psw" className="e-input" type="text" defaultValue={!data.isAdd ? data.psw : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Password</label>
                                </div>
                            </div>
                            {/* <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-start">
                                <ButtonComponent cssClass='e-fill e-info' onClick={this.mostraPassword.bind(this)}><i o class='fas fa-eye'></i></ButtonComponent>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <div style={{ marginTop: '15px', paddingTop: '15px' }}>
                                        <DropDownListComponent id='sede_partenza' value={!data.isAdd ? data.sede_partenza.toString() : ''} fields={this.ddlFields} dataSource={this.dsSedi} onChange={this.onChange.bind(this)}
                                            filterType='Contains' floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
                                    </div>
                                    <label className="e-float-text e-label-top dropdown-label">Sede di Partenza</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <div style={{ marginTop: '15px', paddingTop: '15px' }}>
                                        <DropDownListComponent id='classe' value={!data.isAdd ? data.classe.toString() : ''} fields={this.ddlFields} dataSource={this.dsClassi} onChange={this.onChange.bind(this)}
                                            filterType='Contains' floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
                                    </div>
                                    <label className="e-float-text e-label-top dropdown-label">Classe</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <div style={{ marginTop: '15px', paddingTop: '15px' }}>
                                        <DropDownListComponent id='classe_ac' value={!data.isAdd ? data.classe_ac.toString() : ''} fields={this.ddlFields} dataSource={this.dsClassi} onChange={this.onChange.bind(this)}
                                            filterType='Contains' floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
                                    </div>
                                    <label className="e-float-text e-label-top dropdown-label">Classe AC</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <div style={{ marginTop: '15px', paddingTop: '15px' }}>
                                        <DropDownListComponent id='classe_furto' value={!data.isAdd ? data.classe_furto.toString() : ''} fields={this.ddlFields} dataSource={this.dsClassi} onChange={this.onChange.bind(this)}
                                            filterType='Contains' floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
                                    </div>
                                    <label className="e-float-text e-label-top dropdown-label">Classe Furto</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <div style={{ marginTop: '15px', paddingTop: '15px' }}>
                                        <DropDownListComponent id='classe_rct' value={!data.isAdd ? data.classe_rct.toString() : ''} fields={this.ddlFields} dataSource={this.dsClassi} onChange={this.onChange.bind(this)}
                                            filterType='Contains' floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
                                    </div>
                                    <label className="e-float-text e-label-top dropdown-label">Classe RCT</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='bloccato' name='bloccato' label="Attivo" checked={!data.isAdd ? data.bloccato : false} onChange={this.onChange.bind(this)} />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='sospeso' name='sospeso' label="Sospeso" checked={!data.isAdd ? data.sospeso : false} onChange={this.onChange.bind(this)} />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='uscita' name='uscita' label="In Uscita" checked={!data.isAdd ? data.uscita : false} onChange={this.onChange.bind(this)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='team_leader' name='team_leader' label="Team Leader" checked={!data.isAdd ? data.team_leader : false} onChange={this.onChange.bind(this)} />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='ck_referente' name='ck_referente' label="Referente" checked={!data.isAdd ? data.ck_referente : false} onChange={this.onChange.bind(this)} />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='referente_zona' name='referente_zona' label="Referente di Zona" checked={!data.isAdd ? data.referente_zona : false} onChange={this.onChange.bind(this)} />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='act' name='act' label="Perito ACT" checked={!data.isAdd ? data.act : false} onChange={this.onChange.bind(this)} />
                            </div>
                        </div>
                        <div className="row text">
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='abilitatoOse' name='abilitatoOse' label="Abilitato Ose" checked={!data.isAdd ? data.abilitatoOse : false} onChange={this.onChange.bind(this)} />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='abilitatoReporter' name='abilitatoReporter' label="Solo Surveyer Fotografico" checked={!data.isAdd ? data.abilitatoReporter : false} onChange={this.onChange.bind(this)} />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='teamComplessi' name='teamComplessi' label="Abilitato Team Complessi" checked={!data.isAdd ? data.teamComplessi : false} onChange={this.onChange.bind(this)} />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <CheckBoxComponent id='monitor' name='monitor' label="Monitor" checked={!data.isAdd ? data.monitor : false} onChange={this.onChange.bind(this)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="note" name='note' className="e-input" type="text" defaultValue={!data.isAdd ? data.note : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Note</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    }

    mostraPassword() {
        //todo da fare!
        // var x = document.getElementById("psw");
        // if (x.type === "password") {
        //     x.type = "text";
        // } else {
        //     x.type = "password";
        // }
    }

    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    actionBegin(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
            this.gridInstance.columns.forEach(elm => {
                if (elm.field === 'psw' || elm.field === 'nome' || elm.field === 'cognome' || elm.field === 'email' || elm.field === 'codice_fiscale'
                    || elm.field === 'telefono_interno' || elm.field === 'note' || elm.field === 'monitor' || elm.field === 'ck_referente'
                    || elm.field === 'referente_zona' || elm.field === 'abilitatoOse' || elm.field === 'teamComplessi'
                    || elm.field === 'classe_ac' || elm.field === 'classe_furto' || elm.field === 'classe_rct' || elm.field === 'classe' ||
                    elm.field === 'sede_partenza' || elm.field === 'abilitatoReporter' || elm.field === 'act')
                    elm.visible = true;
            });
        }

    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            args.dialog.allowDragging = false;
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo Utente' : `Dettaglio di ${CustomJS.isNullEmpty(args.rowData.denom) ? 'Nominativo da inserire' : args.rowData.denom}`;
        }
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, '_user', false, retData => {
                retData = JSON.parse(retData.data);
                if (args.action === "add" && args.requestType === "save")
                    this.idMaxUser = retData[0].max + 1;
                //inizio
                var dataObj = {}
                if (CustomJS.isNullEmpty(args.data[0]))
                    dataObj = args.data;
                else
                    dataObj = args.data[0];
                const jsonObject = {
                    CodTab: '_user',
                    Azione: args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista',
                    id_team_leader: dataObj.team_leader === true ? (CustomJS.isNullEmpty(dataObj.id_team_leader) ? (this.idMaxUser).toString() : (dataObj.id_team_leader).toString()) : '0', // metto l' id user
                    team_leader: dataObj.team_leader === true ? '1' : (dataObj.team_leader === false ? '0' : null),
                    id_user: CustomJS.isNullEmpty(dataObj.id_user) ? '0' : (dataObj.id_user).toString(),
                    denom: CustomJS.isNullEmpty(dataObj.denom) ? "" : dataObj.denom,
                    nome: CustomJS.isNullEmpty(dataObj.nome) ? "" : dataObj.nome,
                    cognome: CustomJS.isNullEmpty(dataObj.cognome) ? "" : dataObj.cognome,
                    email: CustomJS.isNullEmpty(dataObj.email) ? "" : dataObj.email,
                    psw: CustomJS.isNullEmpty(dataObj.psw) ? "" : dataObj.psw,
                    codice_fiscale: CustomJS.isNullEmpty(dataObj.codice_fiscale) ? "" : dataObj.codice_fiscale,
                    indirizzo: CustomJS.isNullEmpty(dataObj.indirizzo) ? "" : dataObj.indirizzo,
                    citta: CustomJS.isNullEmpty(dataObj.citta) ? "" : dataObj.citta,
                    cap: CustomJS.isNullEmpty(dataObj.cap) ? "" : dataObj.cap,
                    provincia: CustomJS.isNullEmpty(dataObj.provincia) ? "" : dataObj.provincia,
                    telefono: CustomJS.isNullEmpty(dataObj.telefono) ? "" : dataObj.telefono,
                    telefono_interno: CustomJS.isNullEmpty(dataObj.telefono_interno) ? "" : dataObj.telefono_interno,
                    foto: CustomJS.isNullEmpty(dataObj.foto) ? "" : dataObj.foto,
                    note: CustomJS.isNullEmpty(dataObj.note) ? "" : dataObj.note,
                    sede_partenza: CustomJS.isNullEmpty(dataObj.sede_partenza) ? '19' : (dataObj.sede_partenza).toString(), // metto l' id sede partenza
                    classe: CustomJS.isNullEmpty(dataObj.classe) ? "" : (dataObj.classe).toString(),
                    classe_ac: CustomJS.isNullEmpty(dataObj.classe_ac) ? "" : (dataObj.classe_ac).toString(),
                    classe_furto: CustomJS.isNullEmpty(dataObj.classe_furto) ? "" : (dataObj.classe_furto).toString(),
                    classe_rct: CustomJS.isNullEmpty(dataObj.classe_rct) ? "" : (dataObj.classe_rct).toString(),
                    bloccato: dataObj.bloccato === true ? '1' : (dataObj.bloccato === false ? '0' : null),
                    sospeso: dataObj.sospeso === true ? '1' : (dataObj.sospeso === false ? '0' : null),
                    uscita: dataObj.uscita === true ? '1' : (dataObj.uscita === false ? '0' : null),
                    monitor: dataObj.monitor === true ? '1' : (dataObj.monitor === false ? '0' : null),
                    ck_referente: dataObj.ck_referente === true ? '1' : (dataObj.ck_referente === false ? '0' : null),
                    referente_zona: dataObj.referente_zona === true ? '1' : (dataObj.referente_zona === false ? '0' : null),
                    abilitatoOse: dataObj.abilitatoOse === true ? '1' : (dataObj.abilitatoOse === false ? '0' : null),
                    abilitatoReporter: dataObj.abilitatoReporter === true ? '1' : (dataObj.abilitatoReporter === false ? '0' : null),
                    teamComplessi: dataObj.teamComplessi === true ? '1' : (dataObj.teamComplessi === false ? '0' : null),
                    act: dataObj.act === true ? '1' : (dataObj.act === false ? '0' : null),
                    // data_cr:  //GESTITA LATO BACKAND
                    //firmaPerito: //GESTITA LATO BACKAND
                }
                this.idMaxUser = '';
                CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
                    'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                        this.gridInstance.columns.forEach(elm => {
                            if (elm.field === 'psw' || elm.field === 'nome' || elm.field === 'cognome' || elm.field === 'email' || elm.field === 'codice_fiscale'
                                || elm.field === 'telefono_interno' || elm.field === 'note' || elm.field === 'monitor' || elm.field === 'ck_referente'
                                || elm.field === 'referente_zona' || elm.field === 'abilitatoOse' || elm.field === 'teamComplessi'
                                || elm.field === 'classe_ac' || elm.field === 'classe_furto' || elm.field === 'classe_rct' || elm.field === 'classe' ||
                                elm.field === 'sede_partenza' || elm.field === 'abilitatoReporter' || elm.field === 'act')
                                elm.visible = false;
                        });
                        if (retData.response === 'KO') {
                            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer2',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            return;
                        }
                        const QueryExec = `SELECT id_team_leader, denom FROM _user WHERE team_leader = 1;`
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData2 => {
                            sessionStorage.setItem('JellyfishSIPAEVOTabella_teamleader', retData2.data);
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        })
                    }, true, false);
                if (args.action === "add" || args.action === 'edit') {
                    setTimeout(() => {
                        this.caricaDati();
                    }, 600);
                }
                //fine

            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
                // T1.id_team_leader,
            }, 'SELECT MAX(id_user) AS max FROM _user', null);


        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className='container-fluid'>
                    <div className='row' >
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' >
                            <GridComponent width={'100%'} id="gridUtenti" ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} hierarchyPrintMode='All' allowSelection={true} enableHover={false} showColumnMenu={false} showColumnChooser={false}
                                allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} allowPaging={true} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings}
                                allowGrouping={true} allowExcelExport={true} editSettings={this.editSettings} filterSettings={this.filterSettings} style={{ border: '0px' }}
                                actionComplete={this.actionComplete.bind(this)} actionBegin={this.actionBegin.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field='team_leader' headerText="Team Leader" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_team_leader' headerText="ID Team Leader" visible={false} width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_user' headerText='ID' width='10%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='denom' validationRules={{ required: true }} headerText="Nominativo" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='nome' headerText="Nome" width='15%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cognome' headerText="Cognome" width='15%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='email' headerText="Email" width='20%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='psw' headerText="Password" width='20%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='codice_fiscale' headerText="Codice Fiscale" visible={false} width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='indirizzo' headerText="Indirizzo" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='citta' headerText="Citta" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cap' headerText="CAP" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='provincia' editType='dropdownedit' headerText="Provincia" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='telefono' headerText="Telefono" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='telefono_interno' headerText="Telefono 2" visible={false} width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='foto' headerText="Foto" width='15%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='note' headerText="Note" width='15%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='sede_partenza' visible={false} headerText="Sede di Partenza" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='classe' visible={false} headerText="Classe Altri Rami Elementari" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='classe_ac' visible={false} headerText="Classe Acqua Condotta" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='classe_furto' visible={false} headerText="Classe Furto" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='classe_rct' visible={false} headerText="Classe R.C. Terzi" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='bloccato' headerText="Attivo" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='sospeso' headerText="Sospeso" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='monitor' visible={false} headerText="Monitor" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='ck_referente' visible={false} headerText="Referente" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='referente_zona' visible={false} headerText="Referente di Zona" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='abilitatoOse' visible={false} headerText="Abilitato Ose" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='abilitatoReporter' visible={false} headerText="Solo Surveyer Fotografico" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='teamComplessi' visible={false} headerText="Abilitato Team Complessi" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='act' visible={false} headerText="Perito ACT" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='uscita' headerText="In Uscita" displayAsCheckBox="true" editType="booleanedit" type="boolean" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, Edit, ExcelExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        // document.getElementById('titoloPagina').innerText = 'Utenti';
        //sessionStorage.setItem('BtnSblocca', false);
        setTimeout(() => {
            this.caricaDati();
        }, 300);
    }
    caricaDati() {
        this.toggleBlocking();
        CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, '_user', false, retData => {
            document.getElementById('gridUtenti').ej2_instances[0].dataSource = JSON.parse(retData.data);
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
            // T1.id_team_leader,
        }, 'SELECT * FROM _user ORDER BY id_user DESC', null);
    }
}