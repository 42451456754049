/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { Query } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { PdfViewerComponent, Toolbar as PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject as PDFVInject } from '@syncfusion/ej2-react-pdfviewer';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Browser, getValue } from '@syncfusion/ej2-base';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';
import { beforeFileUpload } from '@syncfusion/ej2-react-richtexteditor';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

export default class PaginaCga extends SampleBase {
    constructor() {
        super(...arguments);
        this.dsCompagnia = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_compagnie'))?.map(elm => { return { VALUE: elm.id_compagnia, TEXT: elm.compagnia } });
        this.dsDivisione = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_divisioni'))?.map(elm => { return { VALUE: elm.id_divisione, TEXT: elm.divisione, ID_COMPAGNIA: elm.id_compagnia } });
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.childKey = 0;
        this.editSettings = { allowAdding: this.controlloUtente(), allowEditing: this.controlloUtente(), allowDeleting: this.controlloUtente(), showDeleteConfirmDialog: true, mode: 'Normal' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.filterSettings = { type: 'CheckBox' };
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        this.groupSettings = {
            columns: ['id_compagnia'], 
            showGroupedColumn: true,
            captionTemplate: this.groupTemplate.bind(this)
        };
        this.nuovoFileFisico = '';
        this.commands = [{ type: "Visualizza Documento", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" } }, 
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } },
                         { type: "Scarica Documento", buttonOption: { iconCss: "fas fa-download fa-10x", cssClass: "e-details" } }];
        this.asyncSettings = {
            saveUrl: `ChiamateAPISIPAEVO/GestioneAllegati/Upload/CGA_XPERT/[NO]/[NO]/[NO]/[NO]`,
            removeUrl: `ChiamateAPISIPAEVO/GestioneAllegati/Remove/CGA_XPERT/[NO]/[NO]/[NO]/[NO]`
        }
        //compagnie
        this.DdlObj = null;
        this.elemSelezCompagnia = null;
        this.editTemplate = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => {
                return this.DdlObj.value
            },
            destroy: () => { this.DdlObj.destroy() },
            write: (args) => {
                this.elemSelezCompagnia = args.rowData.compagnia; this.DdlObj = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Compagnia', popupHeight: '200px',
                    dataSource: this.dsCompagnia, value: args.rowData.compagnia, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.compagnia = args2.value;
                        this.elemSelezCompagnia = args2.value;
                        let ddlObj = document.getElementById('gridCgaid_divisione').ej2_instances[0];
                        ddlObj.enabled = !CustomJS.isNullEmpty(args2.value);
                        let tempQuery = new Query().where('ID_COMPAGNIA', 'equal', args2.value);
                        ddlObj.query = tempQuery;
                        ddlObj.value = args2.itemData.VALUE;
                        ddlObj.text = args2.itemData.TEXT;
                        ddlObj.dataBind();
                    }
                });
                if (CustomJS.isNullEmpty(this.DdlObj.value)) {
                    if (!CustomJS.isNullEmpty(args.rowData.id_compagnia)) {
                        this.DdlObj.value = args.rowData.id_compagnia.toString();
                    }
                }
                this.DdlObj.appendTo(args.element)
            }
        };
        //divisioni
        this.DdlObj2 = null;
        this.elemSelezDivisione = null;
        this.editTemplate2 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => { return this.DdlObj2.value },
            destroy: () => { this.DdlObj2.destroy() },
            write: (args) => {
                this.elemSelezDivisione = args.rowData.divisione; this.DdlObj2 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Divisione', popupHeight: '200px',
                    dataSource: this.dsDivisione, value: args.rowData.id_divisione, floatLabelType: 'Auto', enabled: false,
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.divisione = args2.value;
                        this.elemSelezDivisione = args2.value;
                    }
                });
                // this.DdlObj2.value = args.rowData.id_divisione.toString();
                this.DdlObj2.appendTo(args.element)
            }
        };
        this.objRecord = {};
        this.azione = '';
    }
    state = {
        blocking: false,
        showDialog1: false,
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    descrizioniCombo(args) {
        if (args.column.field === "id_compagnia" && args.data && args.cell) {
            args.cell.innerText = '';
            if (getValue('id_compagnia', args.data)) {
                this.dsCompagnia.forEach(element => {
                    if (element.VALUE === getValue('id_compagnia', args.data).toString()) {
                        args.cell.innerHTML = element.TEXT;
                    }
                });
            }
        }
        if (args.column.field === "id_divisione" && args.data && args.cell) {
            args.cell.innerText = '';
            if (getValue('id_divisione', args.data)) {
                this.dsDivisione.forEach(element => {
                    if (element.VALUE === getValue('id_divisione', args.data).toString()) {
                        args.cell.innerHTML = element.TEXT;
                    }
                });
            }
        }
    }
    groupTemplate(props) {
        if (props.field === 'id_compagnia')
            return (<div>
                <span style={{ fontWeight: 600 }}>{_.find(this.dsCompagnia, elm => { return elm.VALUE === props.items[0].id_compagnia.toString(); }).TEXT} - {props.count} Articoli</span>
            </div>);
    }  
    onMinimizeDialogOpen(titolo, minWidth, minPosY, initMinHeight, dialogInstance) {
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-restore")?.classList.remove('fa-window-restore');
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize").setAttribute('title', 'Minimizza Finestra');
        dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
        dialogInstance.position = { X: 'center', Y: '70' };
        dialogInstance.allowDragging = true;
        document.getElementById(dialogInstance.id).style.height = initMinHeight;
        document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
        this.minBtn = document.getElementById(`min-btn-${dialogInstance.id}`);
        this.minBtn.onclick = (e) => {
            let minimizeIcon = dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize");
            if (!dialogInstance.element.classList.contains('e-dlg-fullscreen')) {
                if (!dialogInstance.element.classList.contains('dialog-minimized')) {
                    this.dialogOldPositions = { X: dialogInstance.position.X, Y: dialogInstance.position.Y };
                    this.dialogOldWidth = dialogInstance.width;
                    dialogInstance.element.classList.add('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.add('hide-content');
                    dialogInstance.position = { X: 'right', Y: minPosY };
                    dialogInstance.width = minWidth;
                    dialogInstance.height = '100px';
                    document.getElementById(dialogInstance.id).style.minHeight = '3%';
                    //dialogInstance.minHeight = '100px';
                    dialogInstance.dataBind();
                    minimizeIcon.classList.add('fa-window-restore');
                    minimizeIcon.setAttribute('title', 'Ripristina Finestra');
                }
                else {
                    dialogInstance.element.classList.remove('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.remove('hide-content');
                    minimizeIcon.classList.add('fa-window-minimize');
                    minimizeIcon.setAttribute('title', 'Minimizza Finestra');
                    minimizeIcon.classList.remove('fa-window-restore');
                    dialogInstance.position = this.dialogOldPositions;
                    dialogInstance.width = this.dialogOldWidth;
                    document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
                    document.getElementById(`dialog-title-${dialogInstance.id}`).innerHTML = titolo;
                    dialogInstance.dataBind();
                }
            }
            else {
                dialogInstance.show(false);
                dialogInstance.element.classList.remove('dialog-maximized');
                dialogInstance.element.classList.add('dialog-minimized');
                dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                minimizeIcon.classList.remove('fa-window-minimize');
                minimizeIcon.removeAttribute('title');
                dialogInstance.position = { X: 'center', Y: 'bottom' };
                dialogInstance.dataBind();
                this.isFullScreen = true;
            }
        };
    }
    PDFViewerRender() {
        ++this.childKey;
        return (<React.Fragment>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-content">
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <PdfViewerComponent id={`PDFViewer`} key={this.childKey} width="auto" style={{ 'height': '720px' }}
                                                enablePrint={true} enableDownload={true} enableAnnotation={false} enableCommentPanel={false}
                                                serviceUrl = {`${CustomJS.APIVariousJfishXpertURLProd}/api/PdfViewer`}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
                                            </PdfViewerComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    actionBegin(args) {
        if (args.requestType === 'save') {
            this.nuovoFileFisico = `${args.data.descrizione}___${args.data.modello}___${args.data.edizione}.pdf`;
        }
    }
    onUploading(args) {
        args.customFormData = [{ 'nomeFileOriginale': this.nuovoFileFisico }];
        args.currentRequest.setRequestHeader("Authorization", CustomJS.tokenAuthAPIJellyfishSIPAEVO);
    }
    actionComplete(args) {
        if ((args.action === "edit" && args.requestType === "save")) {
            let modelloSplit = args.rowData.nome_file_fisico.split("___")[1];
            let edizioneDaSplittare = args.rowData.nome_file_fisico.split("___")[2];
            let ultimoPunto = edizioneDaSplittare.lastIndexOf(".");
            let edizioneSplit = edizioneDaSplittare.substring(0, ultimoPunto);
            if (args.data.modello !== modelloSplit || args.data.edizione !== edizioneSplit) {
                this.dialogInstance1.content = `<h3>Sei sicuro di voler fare l'upload di un nuovo file?<h3>`;
                this.dialogInstance1.width = '38%';
                this.dialogInstance1.height = '28%';
                this.dialogInstance1.minHeight = '28%';
                this.dialogInstance1.buttons = [
                    {
                        click: () => {
                            this.confermaUpload();
                            this.dialogInstance1.hide();
                        },
                        buttonModel: {
                            content: 'CONFERMA',
                            cssClass: 'e-primary',
                            isPrimary: true
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance1.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance1.show();
            }
        }
        if ((args.action === "add" && args.requestType === "save")) {
            this.confermaUpload();
        }
        if (args.requestType === 'delete'){
            const jsonObject = {
                CodTab: '_cga_xpert',
                Azione: 'Delete',
                id_cga: CustomJS.isNullEmpty(args.data[0].id_cga) ? null : args.data[0].id_cga.toString(),
                id_compagnia: CustomJS.isNullEmpty(args.data[0].id_compagnia) ? '0' : args.data[0].id_compagnia.toString(),
                id_divisione: CustomJS.isNullEmpty(args.data[0].id_divisione) ? '0' : args.data[0].id_divisione.toString(),
                descrizione: CustomJS.isNullEmpty(args.data[0].descrizione) ? "" : args.data[0].descrizione,
                modello: CustomJS.isNullEmpty(args.data[0].modello) ? "" : args.data[0].modello,
                edizione: CustomJS.isNullEmpty(args.data[0].edizione) ? "" : args.data[0].edizione,
                nome_file_fisico: CustomJS.isNullEmpty(args.data[0].nome_file_fisico) ? "" : args.data[0].nome_file_fisico,
            }
            CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    this.caricaDati();
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
        }
        this.objRecord = args.data;
        this.azione = args.action;
    }
    confermaUpload(args) {
        this.dialogInstance1.hide();
        document.getElementById('uploadCga').querySelector('button.e-css.e-btn').click();
    }
    onSuccess(args) {
        const jsonObject = {
            CodTab: '_cga_xpert',
            Azione: this.azione === "edit" ? 'Edit' : this.azione === "add" ? 'Insert' : 'AzioneNonPrevista',
            id_cga: CustomJS.isNullEmpty(this.objRecord.id_cga) ? null : this.objRecord.id_cga.toString(),
            id_compagnia: CustomJS.isNullEmpty(this.objRecord.id_compagnia) ? '0' : this.objRecord.id_compagnia.toString(),
            id_divisione: CustomJS.isNullEmpty(this.objRecord.id_divisione) ? '0' : this.objRecord.id_divisione.toString(),
            descrizione: CustomJS.isNullEmpty(this.objRecord.descrizione) ? "" : this.objRecord.descrizione,
            modello: CustomJS.isNullEmpty(this.objRecord.modello) ? "" : this.objRecord.modello,
            edizione: CustomJS.isNullEmpty(this.objRecord.edizione) ? "" : this.objRecord.edizione,
            nome_file_fisico: this.nuovoFileFisico,
        }
        CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
            'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                this.caricaDati();
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    return;
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }, true, false);
    }
    controlloUtente() {
        if (!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabellaCompagnieReferente'))) || JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 1) {
            return true;
        }
        else {
            return false;
        }
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {
            this.toggleBlocking();
            const salvaNomeFileFisico = args.rowData.nome_file_fisico;
            CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/CGA_XPERT/[NO]/[NO]/[NO]/[NO]?nomeFileDownload=${args.rowData.nome_file_fisico}`, {}, 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    this.dialogInstance1.header = `<div><span id='dialog-title-${this.dialogInstance1.id}' class='title'>VISUALIZZAZIONE DOCUMENTO PDF</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance1.id}' title='Minimizza la finestra'></span></div>`;
                    this.dialogInstance1.width = '65%';
                    this.dialogInstance1.height = '90%';
                    this.dialogInstance1.minHeight = '90%';
                    this.dialogInstance1.buttons = [];
                    this.dialogInstance1.beforeOpen = (args) => {
                        const container = document.getElementById('defaultDialogContentCGA');
                        const root = createRoot(container);
                        root.unmount();
                    };
                    this.dialogInstance1.open = (args) => {
                        this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO PDF`, '440px', '240', '90%', this.dialogInstance1);
                        const container = document.getElementById('defaultDialogContentCGA');
                        const root = createRoot(container);
                        root.render(<CallbackWrapper callback={() => {
                            document.getElementById(`PDFViewer`).ej2_instances[0].enableDownload = true;
                            document.getElementById(`PDFViewer`).ej2_instances[0].downloadFileName = salvaNomeFileFisico;
                            document.getElementById(`PDFViewer`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                            //document.getElementById(`PDFViewer`).ej2_instances[0].dataBind(); //NON SEMBRA SERVIRE
                            setTimeout(() => {
                                document.getElementById(`PDFViewer`).ej2_instances[0].load(`data:application/pdf;base64,${retData.data}`, null);
                                this.toggleBlocking();
                            }, 100);
                        }}>{this.PDFViewerRender()}</CallbackWrapper>);
                    };
                    this.dialogInstance1.show();
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                }, true, false);
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-download') > -1) {
            this.toggleBlocking();
            CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/CGA_XPERT/[NO]/[NO]/[NO]/[NO]?nomeFileDownload=${args.rowData.nome_file_fisico}`, {}, 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    this.toggleBlocking();
                    let element = document.createElement('a');
                    element.setAttribute('href', `data:text/plain;base64,${retData.data}`);
                    element.setAttribute('download', args.rowData.nome_file_fisico);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
            }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                }, true, false);
        }

    }
    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialogCGA" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance1 = dialog} isModal={true} height='90%' width='65%' minHeight='90%'>
                    <div id='defaultDialogContentCGA'></div>
                </DialogComponent>
                <div className='container-fluid'>
                    <div className='row' >
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <div id='uploadCga'><UploaderComponent id='documenti' autoUpload={true} ref={upload => { this.uploadObj = upload }} allowedExtensions='.pdf' multiple={false} success={this.onSuccess.bind(this)} asyncSettings={this.asyncSettings} uploading={this.onUploading.bind(this)}/></div>
                            <GridComponent width={'100%'} id="gridCga" queryCellInfo={this.descrizioniCombo.bind(this)} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} hierarchyPrintMode='All' allowSelection={true} enableHover={false} showColumnMenu={false} showColumnChooser={false}
                                allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} allowPaging={true} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings} groupSettings={this.groupSettings}
                                allowGrouping={true} allowExcelExport={true} editSettings={this.editSettings} filterSettings={this.filterSettings} style={{ border: '0px' }} actionBegin={this.actionBegin.bind(this)} commandClick={this.onCommandClick.bind(this)}
                                actionComplete={this.actionComplete.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id_cga' headerText='ID' width='10%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_compagnia' headerText="Compagnia" validationRules={{ required: true }} edit={this.editTemplate} width='15%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='id_divisione' headerText="Divisione" edit={this.editTemplate2} width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='descrizione' headerText="Descrizione" validationRules={{ required: true }} width='20%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='modello' headerText="Modello" width='10%' validationRules={{ required: true }} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='edizione' headerText="Edizione" width='10%' validationRules={{ required: true }} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='nome_file_fisico' headerText="Nome File" width='30%' allowEditing={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='Comandi' commands={this.commands} headerText="" width='12%' headerTemplate={headerTemplate} textAlign='Right'></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, Edit, ExcelExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        document.getElementById('uploadCga').style.display = 'none';
        setTimeout(() => {
            this.caricaDati();
        }, 300);
    }
    caricaDati() {
        this.toggleBlocking();
        CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, '_cga_xpert', false, retData => {
            document.getElementById('gridCga').ej2_instances[0].dataSource = JSON.parse(retData.data);
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, 'SELECT T1.* FROM _cga_xpert T1 ORDER BY id_cga DESC;', null);
    }
}

