import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, Inject, Sort, Page, Aggregate } from '@syncfusion/ej2-react-grids';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';
import moment from 'moment';
import $ from 'jquery';

// eslint-disable-next-line no-extend-native
Array.prototype.sumAperturaChiusura = function (prop, dataDa, dataA) {
    let total = 0;
    for ( let i = 0, _len = this.length; i < _len; i++ ) {
        total += (moment(this[i][prop], 'YYYY-MM-DD').isSameOrAfter(moment(dataDa).format('YYYY-MM-DD')) && moment(this[i][prop], 'YYYY-MM-DD').isSameOrBefore(moment(dataA).format('YYYY-MM-DD'))) ? 1 : 0;
    }
    return total;
}

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.80vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'NominativoGestore' ? 'purple' : 'black';
    let renderDiv = <div><span style={{ fontSize: '0.85vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}

function footerSum (props) {
    return (<span style={{ fontSize: '1.1vw', color: 'darkslateblue', fontWeight: 'bolder' }}>{props.Sum}</span>);
};

export default class PaginaStatistiche extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Search', 'ExcelExport'];
        this.pageSettings = { pageSize: 10 };
        this.container = null;
        this.rootContainer = null;
        this.excelData = [];
    }
    state = {
        blocking: false
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-excelexport':
                this.gridInstance1.excelExport();
                break;
            default:
                break;
        }
    }
    contentStatisticaGestoriSegreteria() {
        return(<>
            <div className='row'>
                <div className='col-lg-3 offset-lg-2 col-md-3 offset-md-2 col-sm-3 offset-sm-2 col-xs-3 offset-xs-2'>
                        <DateRangePickerComponent id="dateRangeStatisticaGestori" ref={drObj => this.drpInstance = drObj} placeholder='Data Estrazione Da-A' change={this.estraiStatisticaGestori.bind(this)} floatLabelType='Always'/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <ButtonComponent id='estraiStatisticaGestori' cssClass='e-fill e-normal' onClick={this.estraiStatisticaGestori.bind(this)}>Aggiorna</ButtonComponent>
                </div>
                <div className='col-lg-2 offset-lg-3 col-md-2 offset-md-3 col-sm-2 offset-sm-3 col-xs-2 offset-xs-3' style={{ marginTop: '30px'}}>
                    <ButtonComponent id='estraiStatisticaDettaglioGestori' cssClass='e-fill e-info' onClick={this.estraiStatisticaDettaglioGestori.bind(this)}>Estrazione Statistica Dettaglio</ButtonComponent>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-xs-8 offset-xs-2'>
                    <GridComponent id="GridStatisticaGestori" ref={grid => this.gridInstance1 = grid} toolbar={this.toolbarOptions} allowSelection={false} enableHover={false} showColumnMenu={false} showColumnChooser={false}
                        allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings}
                        allowExcelExport={true} height='480px'>
                        <ColumnsDirective>
                            <ColumnDirective field='NominativoGestore' headerText='Nominativo Gestore' width='50%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='NumeroSinistriApertura' headerText="Numero Sinistri Apertura" width='25%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='NumeroSinistriChiusura' headerText="Numero Sinistri Chiusura" width='25%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                        </ColumnsDirective>
                        <AggregatesDirective>
                            <AggregateDirective>
                                <AggregateColumnsDirective>
                                    <AggregateColumnDirective field='NumeroSinistriApertura' type='Sum' footerTemplate={footerSum}/>
                                    <AggregateColumnDirective field='NumeroSinistriChiusura' type='Sum' footerTemplate={footerSum}/>
                                </AggregateColumnsDirective>
                            </AggregateDirective>                            
                        </AggregatesDirective>
                        <Inject services={[Toolbar, ExcelExport, Sort, Page, Aggregate]} />
                    </GridComponent>
                </div>
            </div>
        </>);
    }
    estraiStatisticaGestori() {
        if (CustomJS.isNullEmpty(this.drpInstance.startDate) || CustomJS.isNullEmpty(this.drpInstance.endDate))
            return;
        this.toggleBlocking();
        const QueryExec = `SELECT DISTINCT T1.id_sinistro AS 'ID Sinistro', T1.sin_num AS 'Numero Sinistro Compagnia', CASE WHEN IFNULL(T1.data_chi, '0000-00-00') = '0000-00-00' THEN DATEDIFF(CURDATE(),T1.data_ins) ELSE DATEDIFF(T1.data_chi,T1.data_ins) END AS Ritardo, T2.compagnia AS 'Compagnia', T7.agenzia AS 'Agenzia', T1.sin_per AS 'Data Pervenimento', T1.data_ins AS 'Data Inserimento', T1.data_res AS 'Data Restituzione Perito', T1.data_chi AS 'Data Chiusura', T5.denom AS 'Utente Segreteria Apertura', T6.denom AS 'Utente Segreteria Chiusura' FROM acsinistri T1 LEFT OUTER JOIN _compagnie T2 ON T1.id_compagnia = T2.id_compagnia LEFT OUTER JOIN _action_log T3 ON T1.id_sinistro = T3.param_1 AND T3.action = 'Creazione Sinistro' LEFT OUTER JOIN _action_log T4 ON T1.id_sinistro = T4.param_1 AND T4.action = 'Chiusura Sinistro' LEFT OUTER JOIN _user T5 ON T5.id_user = T3.id_user LEFT OUTER JOIN _user T6 ON T6.id_user = T4.id_user LEFT OUTER JOIN _agenzie T7 ON T7.id_agenzia = T1.id_agenzia WHERE (T3.action_data BETWEEN '${moment(this.drpInstance.startDate).format('YYYY-MM-DD')}' AND '${moment(this.drpInstance.endDate).format('YYYY-MM-DD')}') OR (T4.action_data BETWEEN '${moment(this.drpInstance.startDate).format('YYYY-MM-DD')}' AND '${moment(this.drpInstance.endDate).format('YYYY-MM-DD')}') ORDER BY 7`;
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
            if (JSON.parse(retData.data) === "[]")
                this.gridInstance1.dataSource = [];
            else {
                let gridData = [];
                let gruppiSinistriApertura = _.groupBy(JSON.parse(retData.data), 'Utente Segreteria Apertura');
                let gruppiSinistriChiusura = _.groupBy(JSON.parse(retData.data), 'Utente Segreteria Chiusura');
                Object.values(gruppiSinistriApertura).forEach((elm, idx) => {
                    if (CustomJS.isNullEmpty(Object.keys(gruppiSinistriApertura)[idx]))
                        return;  
                    if (elm.sumAperturaChiusura('Data Inserimento', this.drpInstance.startDate, this.drpInstance.endDate) > 0)                  
                        gridData.push({ NominativoGestore: Object.keys(gruppiSinistriApertura)[idx], NumeroSinistriApertura: elm.sumAperturaChiusura('Data Inserimento', this.drpInstance.startDate, this.drpInstance.endDate), NumeroSinistriChiusura: 0 })
                });
                Object.values(gruppiSinistriChiusura).forEach((elm, idx) => {
                    if (CustomJS.isNullEmpty(Object.keys(gruppiSinistriChiusura)[idx]))
                        return;
                    if (elm.sumAperturaChiusura('Data Chiusura', this.drpInstance.startDate, this.drpInstance.endDate) > 0) {
                        let objGestore = _.find(gridData, elm2 => { return elm2.NominativoGestore.trim() === Object.keys(gruppiSinistriChiusura)[idx].trim()});
                        if (CustomJS.isNullEmpty(objGestore)) {
                            gridData.push({ NominativoGestore: Object.keys(gruppiSinistriChiusura)[idx], NumeroSinistriApertura: 0, NumeroSinistriChiusura: elm.sumAperturaChiusura('Data Chiusura', this.drpInstance.startDate, this.drpInstance.endDate) })
                        }
                        else {
                            objGestore["NumeroSinistriChiusura"] = elm.sumAperturaChiusura('Data Chiusura', this.drpInstance.startDate, this.drpInstance.endDate);
                        }    
                    }                  
                });
                this.gridInstance1.dataSource = gridData;
                this.excelData = JSON.parse(retData.data);
            }
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }
    estraiStatisticaDettaglioGestori() {
        if (this.excelData.length === 0)
            return;
        this.toggleBlocking();
        this.excelData.forEach(elm => {
            elm["Data Pervenimento"] = CustomJS.isNullEmpty(elm["Data Pervenimento"]) ? '' : moment(elm["Data Pervenimento"], 'YYYY-MM-DD').format('DD/MM/YYYY');
            elm["Data Inserimento"] = CustomJS.isNullEmpty(elm["Data Inserimento"]) ? '' : moment(elm["Data Inserimento"], 'YYYY-MM-DD').format('DD/MM/YYYY');
            elm["Data Restituzione Perito"] = CustomJS.isNullEmpty(elm["Data Restituzione Perito"]) ? '' : moment(elm["Data Restituzione Perito"], 'YYYY-MM-DD').format('DD/MM/YYYY');
            elm["Data Chiusura"] = CustomJS.isNullEmpty(elm["Data Chiusura"]) ? '' : moment(elm["Data Chiusura"], 'YYYY-MM-DD').format('DD/MM/YYYY');
        });
        this.gridInstance2.dataSource = this.excelData;
        this.gridInstance2.excelExportComplete = (args) => {
            this.gridInstance2.dataSource = null;
            this.gridInstance2.columns = [];
            this.gridInstance2.refreshColumns();
            this.gridInstance2.refresh();
            this.toggleBlocking();
        };
        setTimeout(() => { 
            const exportProperties = {
                fileName : `${moment(new Date()).format('YYYYMMDD')}_${moment(new Date()).format('HHMMSS')}_StatisticaDettaglioGestori.xlsx`
            }
            this.gridInstance2.excelExport(exportProperties);
        }, 300);  
    }
    onBeforeExcelExportStatistiche(args){
        args.gridObject.dataSource.forEach(elm => {
        });
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <AccordionComponent ref={acrdn => this.accordInstance = acrdn} expandMode='Single'>
                            </AccordionComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
            <GridComponent id="GridEstrazioneStatistiche" ref={ grid => this.gridInstance2 = grid } allowExcelExport={true} beforeExcelExport={this.onBeforeExcelExportStatistiche.bind(this)} style={{ display: 'none' }}>
            <Inject services={[ExcelExport]} />
            </GridComponent>
        </React.Fragment>);
    }
    componentDidMount() {
        setTimeout(() => {
            $('#dateRangeStatisticaGestori').closest('div').css("width", "70%");
        }, 150);

        if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 1) {
            this.accordInstance.addItem({ header: '<h4>Statistica Gestori</h4>', content: this.contentStatisticaGestoriSegreteria.bind(this), expanded: true });
            setTimeout(() => {
                if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].user !== 'allc45')  //Abilitato solo per l'utente Starrantino
                    document.getElementById('estraiStatisticaDettaglioGestori').ej2_instances[0].disabled = true;
            }, 150);
        }
    }    
}