/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent, CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import PaginaDettaglioSinistro from './PaginaDettaglioSinistro';
import $ from 'jquery';


export default class PaginaCercaSinistro extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.childKey = 0;
    }
    state = {
        blocking: false,
        showDialog1: false,
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    cercaSinistro(args) {
        if (document.getElementById('messError'))
            document.getElementById('messError').style.display = 'none';
        this.toggleBlocking();
        let QueryExec = '';
        if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 9) {
            QueryExec = `SELECT id_sinistro, id_compagnia FROM acsinistri WHERE (id_sinistro = '${document.getElementById('cercaSinistro').ej2_instances[0].value}' OR sin_num = '${document.getElementById('cercaSinistro').ej2_instances[0].value}') AND id_perito = '${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user}' LIMIT 1;`;
        }
        else {
            QueryExec = `SELECT id_sinistro, id_compagnia FROM acsinistri WHERE id_sinistro = '${document.getElementById('cercaSinistro').ej2_instances[0].value}' OR sin_num = '${document.getElementById('cercaSinistro').ej2_instances[0].value}' LIMIT 1;`;
        }
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
            if (retData.data === "[]") {
                document.getElementById('messError').style.display = 'block';
                this.toggleBlocking();
                return;
            }
            else {
                ++this.childKey;
                const container = document.getElementById('contenutoDinamico2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.toggleBlocking();
                }}><PaginaDettaglioSinistro key={this.childKey} IdSinistro={JSON.parse(retData.data)[0].id_sinistro} IdCompagnia={JSON.parse(retData.data)[0].id_compagnia}></PaginaDettaglioSinistro></CallbackWrapper>);
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className='container-fluid' style={{ height: '300px' }}>
                    <div className='row' style={{ marginTop: '100px' }}>
                        <div className='offset-lg-3 col-lg-5 offset-md-3 col-md-5 offset-sm-3 col-sm-5 offset-xs-3 col-xs-5' >
                            <TextBoxComponent id='cercaSinistro' floatLabelType='Auto' placeholder='Cerca Sinistro' cssClass='e-outline' ></TextBoxComponent>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>
                            <ButtonComponent id='btnCercaSinistro' alt='cercaSinistro' cssClass='e-fill e-info' onClick={this.cercaSinistro.bind(this)}>Cerca Sinistro</ButtonComponent>
                        </div>
                    </div>
                    <div className='row' id='messError' style={{ display: 'none', textAlign: 'center', color: 'red' }}>
                        <h3>Sinistro non trovato!</h3>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        sessionStorage.setItem('BtnSblocca', false);
        document.getElementById('titoloPagina').innerText = 'Cerca Sinistro';
        $(document).off('keypress');
        $(document).on('keypress',
            event => {
                if (event.key === 'Enter') {
                    setTimeout(() => {
                        this.cercaSinistro();
                        $(document).off('keypress');
                    }, 200)
                }
            });        
    }
}
