import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import ReactMapGL from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
//import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
//import MapboxGeocoder from 'react-mapbox-gl-geocoder';
import axios from 'axios'
import mioMarker from '../assets/iconeCustom/mioMarker.png'
import mioMarkerSatellite from '../assets/iconeCustom/mioMarkerSatellite.png';
import * as CustomJS from '../Custom';

mapboxgl.accessToken = 'pk.eyJ1IjoiZGlnaXRhbHByYWVzaWRpdW0iLCJhIjoiY2xoZG13YnViMDN2eTNjcDRwMzdrdnQ5bCJ9.-3DG9BpNM9vL3d-qyUF76w';
const bingKey = 'AlRZWzkwAqoX9AOLbziIT6_EF8VzrUFDA98gI1QabvqYgc9yX7U-hlQVBT3Yau2_';

function Mappa(props) {

    const [coordinate, setCoordinate] = useState([]);

    var listaLuoghi = [];
    var markerMappa = '';
    var coloreTesto = '';
    var stileMappa = '';

    if (props.tipoMappa === "satellite") {
        stileMappa = "mapbox://styles/mapbox/satellite-v9";
        markerMappa = mioMarkerSatellite;
        coloreTesto = "white"
    }
    else {
        stileMappa = "mapbox://styles/mapbox/streets-v11";
        markerMappa = mioMarker;
        coloreTesto = "black"
    }

    useEffect(() => {
        let funcList = [];
        var contaIndirizzi = 0;
        var count = 0;

        props.tuttiGliIndirizzi.forEach(elm => {
            const esegui = () => {
                return new Promise((resolve, reject) => {
                    contaIndirizzi++;
                    var localita = elm.citta + " " + elm.provincia;
                    var chiamata = `https://dev.virtualearth.net/REST/v1/Locations?countryRegion=${elm.regione}&locality=${localita}&postalCode=${elm.cap}&addressLine=${elm.indirizzo}&key=${bingKey}`
                    axios.get(chiamata)
                        .then(res => {
                            const posts = res.data;
                            var lat = res.data.resourceSets[0].resources[0].geocodePoints[0].coordinates[0];
                            var lon = res.data.resourceSets[0].resources[0].geocodePoints[0].coordinates[1];
                            var coppia = {
                                nome: elm.danneggiato,
                                latitudine: lat,
                                longitudine: lon
                            }
                            var newCooordinate = coordinate;
                            newCooordinate.push(coppia);
                            setCoordinate(newCooordinate);
                        }).finally(() => {
                            var luogoObj = {
                                type: "Feature",
                                properties: {
                                    title: elm.danneggiato
                                },
                                geometry: {
                                    coordinates: [coordinate[count].longitudine, coordinate[count].latitudine],
                                    type: "Point"
                                }
                            }
                            listaLuoghi.push(luogoObj);
                            count++;
                            setTimeout(() => {
                                resolve('Prossima');
                            }, 350)
                        })
                })
            }
            funcList.push(esegui);
        })

        const eseguiCiclica = (ind) => {
            if (CustomJS.isNullEmpty(funcList[ind])) {
                //quando ha finito di caricare tutti i segnaposto
                const map = new mapboxgl.Map({
                    container: 'mapContainer',
                    style: stileMappa,
                    center: [12.482932, 41.89332],
                    zoom: 5.77
                });

                const miaImage = new Image(40, 40);
                miaImage.src = markerMappa;
                map.on("load", function () {
                    document.getElementById('divSidebar').innerHTML = `Longitudine: ${map.getCenter().lng.toFixed(4)} | Latitudine: ${map.getCenter().lat.toFixed(4)} | Zoom: ${map.getZoom().toFixed(2)}`;
                    map.loadImage(
                        "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
                        function (error, image) {
                            if (error) throw error;
                            map.addImage("custom-marker", miaImage);
                            map.addSource("points", {
                                type: "geojson",
                                data: {
                                    type: "FeatureCollection",
                                    features: listaLuoghi,
                                },
                            });
                            map.addLayer({
                                id: "points",
                                type: "symbol",
                                source: "points",
                                paint: {
                                    "text-color": coloreTesto
                                },
                                layout: {
                                    "icon-image": "custom-marker",
                                    "text-field": ["get", "title"],
                                    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                                    "text-offset": [0, 1.25],
                                    "text-anchor": "top",
                                },
                            });
                        }
                    );
                    var newArrCoordinate = [];
                    var count = 0;
                    coordinate.forEach(elm => {
                        newArrCoordinate[count] = [elm.longitudine, elm.latitudine];
                        count++
                    })
                    const coordinates = newArrCoordinate;
                    const bounds = new mapboxgl.LngLatBounds(
                        coordinates[0],
                        coordinates[0]
                    );
                    for (const coord of coordinates) {
                        bounds.extend(coord);
                    }
                    if (contaIndirizzi === 0) {
                        map.setCenter({
                            lng: 12.482932,
                            lat: 41.89332
                        });
                        map.setZoom(5.5);
                    }
                    else {
                        map.fitBounds(bounds, {
                            padding: 20
                        });
                        if (contaIndirizzi === 1) {
                            map.setZoom(15);
                        }
                        else {
                            map.fitBounds(bounds, {
                                padding: 20
                            });
                        }
                    }
                    if (document.getElementById('divSidebar'))
                        document.getElementById('divSidebar').innerHTML = `Longitudine: ${map.getCenter().lng.toFixed(4)} | Latitudine: ${map.getCenter().lat.toFixed(4)} | Zoom: ${map.getZoom().toFixed(2)}`;
                });
                map.on('move', () => {
                    if (document.getElementById('divSidebar'))
                        document.getElementById('divSidebar').innerHTML = `Longitudine: ${map.getCenter().lng.toFixed(4)} | Latitudine: ${map.getCenter().lat.toFixed(4)} | Zoom: ${map.getZoom().toFixed(2)}`;
                });
                map.addControl(new mapboxgl.NavigationControl(), "top-right");
                return () => map.remove();
            }
            else {
                funcList[ind]().then(ret => {
                    if (ret === 'Prossima') {
                        eseguiCiclica(ind + 1);
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        }
        eseguiCiclica(0);
    }, []);

    return (
        <div>
            <div className='sidebarStyle'>
                <div id="divSidebar"></div>
            </div>
            <div id='mapContainer' className="map-container" />
        </div>
    );
};

export default Mappa;