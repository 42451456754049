/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, Search, Selection, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';


function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

export default class PaginaDanneggiati extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.childKey = 0;
        this.isAllowAdding = this.props.arrivaDaDetSin === true || this.props.arrivaDaPaginaPerizia ? true : false;
        this.editSettings = { allowEditing: true, allowDeleting: true, allowAdding: this.isAllowAdding, showDeleteConfirmDialog: true, mode: 'Dialog', template: this.dialogTemplate.bind(this) };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.tabHeaderText = [{ text: "SITUAZIONE SINISTRI" }];
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.filterSettings = { type: 'CheckBox' };
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        //insert province
        this.DdlObj = null;
        this.DdlObj2 = null;
        this.elemSelezProvincia = null;
        this.editTemplate2 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => { return this.DdlObj2.value },
            destroy: () => { this.DdlObj2.destroy() },
            write: (args) => {
                this.elemSelezProvincia = args.rowData.provincia; this.DdlObj2 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Provincia', popupHeight: '200px',
                    dataSource: JSON.parse(sessionStorage.getItem('JellyfishXpertTabellaProvince')), value: args.rowData.provincia, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.provincia = args2.value;
                        this.elemSelezProvincia = args2.value;
                    }
                });
                if (this.DdlObj2.value === null) {
                    this.DdlObj2.value = args.rowData.prov;
                }
                this.DdlObj2.appendTo(args.element)
            }
        };
        this.isFromAdd = false;
        this.isFromEdit = false;
        this.danneggiatoAgg = {};
        this.IDAddOrEdit = {};
        this.divErroreAggiunto = false
        this.dsProvince = JSON.parse(sessionStorage.getItem('JellyfishXpertTabellaProvince')); //per template

    }
    state = {
        blocking: false,
        showDialog1: false,
    }

    onChange(args) {
        this.setState({ [args.target.name]: args.target.value });
    }

    dialogTemplate(props) {
        this.state = Object.assign({}, props);
        const data = this.state;
        console.log(data)
        return <>
            <div className="e-card-resize-container stile_utenti_dialog">
                <div className="row card-layout">
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="danneggiato" name='danneggiato' className="e-input " type="text" defaultValue={!data.isAdd ? data.danneggiato : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Danneggiato</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="nome" name='nome' className="e-input " type="text" defaultValue={!data.isAdd ? data.nome : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Nome</label>
                                </div>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="cognome" name='cognome' className="e-input " type="text" defaultValue={!data.isAdd ? data.cognome : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Cognome</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="email" name='email' className="e-input " type="text" defaultValue={!data.isAdd ? data.email : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">E-mail</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="tel" name='tel' className="e-input " type="text" defaultValue={!data.isAdd ? data.tel : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Telefono</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="cell" name='cell' className="e-input " type="text" defaultValue={!data.isAdd ? data.cell : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Cellulare</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="codfis" name='codfis' className="e-input " type="text" defaultValue={!data.isAdd ? data.codfis : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Codice fiscale</label>
                                </div>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="piva" name='piva' className="e-input " type="text" defaultValue={!data.isAdd ? data.piva : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Partita IVA</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="indir" name='indir' className="e-input " type="text" defaultValue={!data.isAdd ? data.indir : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Indirizzo</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="citta" name='citta' className="e-input " type="text" defaultValue={!data.isAdd ? data.citta : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">Citta</label>
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                <div className="e-float-input e-control-wrapper">
                                    <div style={{ marginTop: '15px', paddingTop: '15px' }}>
                                        <DropDownListComponent id='prov' value={!data.isAdd ? data.prov : ''} fields={this.ddlFields} dataSource={this.dsProvince} onChange={this.onChange.bind(this)}
                                            filterType='Contains' floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
                                    </div>
                                    <label className="e-float-text e-label-top dropdown-label">Provincia</label>
                                </div>
                            </div>
                            <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                                <div className="e-float-input e-control-wrapper">
                                    <input id="cap" name='cap' className="e-input " type="text" defaultValue={!data.isAdd ? data.cap : ''} onChange={this.onChange.bind(this)} />
                                    <label className="e-float-text e-label-top">CAP</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div className="e-float-input e-control-wrapper text-center">
                                    <h5 style={{ color: 'red' }} id='mexErroreCampiDanneggiato'></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }



    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });

    }
    isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    actionBegin(args) {
        if (args.requestType === 'save') {
            // const content = document.querySelector('#gridDanneggiati_dialogEdit_wrapper .e-footer-content');
            // var newH5;
            // if (this.divErroreAggiunto === false) {
            //     this.divErroreAggiunto = true;
            //     const newDiv = document.createElement("div");
            //     newH5 = document.createElement("h5");
            //     newH5.setAttribute('id', 'fraseErroreDanneggiati')
            //     newDiv.append(newH5);
            //     content.append(newDiv);
            //     newDiv.style.textAlign = 'center';
            //     newH5.style.color = 'red'
            // }
            // else {
            //     newH5 = document.getElementById('fraseErroreDanneggiati');
            // }


            let newH5 = document.getElementById('mexErroreCampiDanneggiato');

            let cellValue = args.data['cell'];
            let telValue = args.data['tel'];
            let emailValue = args.data['email'];
            if (!CustomJS.isNullEmpty(telValue) && telValue?.charAt(0) !== '0') {
                newH5.innerText = 'Il campo "tel" non è corretto.';
                // alert('Il campo "tel" non è corretto.');
                args.cancel = true;
                setTimeout(() => {
                    newH5.innerText = '';
                }, 3500)
            }
            if (!CustomJS.isNullEmpty(cellValue) && cellValue?.substring(0, 3) === '+39') {
                if (cellValue.charAt(3) !== '3') {
                    newH5.innerText = 'Il campo "cell" non è corretto.';
                    // alert('Il campo "cell" non è corretto.');
                    args.cancel = true;
                    setTimeout(() => {
                        newH5.innerText = '';
                    }, 3500)
                }
            }
            else if (!CustomJS.isNullEmpty(cellValue) && cellValue?.substring(0, 3) !== '+39') {
                if (cellValue.charAt(0) !== '3') {
                    newH5.innerText = 'Il campo "cell" non è corretto.';
                    // alert('Il campo "cell" non è corretto.');
                    args.cancel = true;
                    setTimeout(() => {
                        newH5.innerText = '';
                    }, 3500)
                }
            }
            if (!CustomJS.isNullEmpty(emailValue) && this.isValidEmail(emailValue) === false) {
                newH5.innerText = 'Il campo "email" non è corretto.';
                // alert('Il campo "email" non è corretto.');
                args.cancel = true;
                setTimeout(() => {
                    newH5.innerText = '';
                }, 3500)
            }
            if (CustomJS.isNullEmpty(cellValue) && CustomJS.isNullEmpty(emailValue)) {
                newH5.innerText = 'Obbligatorio email o cellulare.';
                // alert('Obbligatorio email o cellulare.');
                args.cancel = true;
                setTimeout(() => {
                    newH5.innerText = '';
                }, 3500)
            }
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            var identificativo = '';
            args.dialog.allowDragging = false;
            if (args.rowData.danneggiato === '') {
                if (args.rowData.cognome === '')
                    identificativo = args.rowData.nome;
                else
                    identificativo = args.rowData.cognome;
            }
            else
                identificativo = args.rowData.danneggiato;
            this.IDAddOrEdit = args.rowData.id_danneggiato;
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo Danneggiato' : `Dettaglio di ${identificativo}`;
        }
        if (args.requestType === 'searching') {
            this.toggleBlocking();
            let QueryExec = '';

            QueryExec = `SELECT DISTINCT T1.id_danneggiato, T1.id_sinistro, T1.danneggiato, T1.email,T1.nome, T1.cognome, T1.indir, T1.citta, T1.prov, T1.cap, T1.cell,T1.tel, T1.codfis, T1.piva FROM acdanneggiati T1 WHERE T1.id_danneggiato = '${args.searchString}' OR T1.danneggiato LIKE '%${args.searchString}%' OR T1.indir LIKE '%${args.searchString}%' OR T1.citta LIKE '%${args.searchString}%' OR T1.prov LIKE '%${args.searchString}%' OR T1.cap LIKE '%${args.searchString}%' OR T1.cell LIKE '%${args.searchString}%' OR T1.codfis LIKE '%${args.searchString}%' OR T1.piva LIKE '%${args.searchString}%' ORDER BY id_danneggiato DESC;`;

            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                if (JSON.parse(retData.data) === "[]")
                    this.gridInstance.dataSource = [];
                else
                    this.gridInstance.dataSource = JSON.parse(retData.data);
                if (this.isFromAdd === true) {
                    this.gridInstance.selectRow(0);
                    document.getElementById(`btnInserisciDefault${this.props.isPerito}`).click();
                    this.isFromAdd = false;
                    this.toggleBlocking();
                }
                else {
                    this.toggleBlocking();
                }
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        }
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            var dataObj = {}
            if (CustomJS.isNullEmpty(args.data[0]))
                dataObj = args.data;
            else
                dataObj = args.data[0];
            const jsonObject = {
                CodTab: 'acdanneggiati',
                Azione: args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista',
                id_danneggiato: CustomJS.isNullEmpty(dataObj.id_danneggiato) ? null : dataObj.id_danneggiato.toString(),
                id_sinistro: CustomJS.isNullEmpty(this.props.IdSinistro) ? '0' : this.props.IdSinistro.toString(),
                danneggiato: CustomJS.isNullEmpty(dataObj.danneggiato) ? "" : dataObj.danneggiato,
                nome: CustomJS.isNullEmpty(dataObj.nome) ? "" : dataObj.nome,
                cognome: CustomJS.isNullEmpty(dataObj.cognome) ? "" : dataObj.cognome,
                email: CustomJS.isNullEmpty(dataObj.email) ? "" : dataObj.email,
                indir: CustomJS.isNullEmpty(dataObj.indir) ? "" : dataObj.indir,
                citta: CustomJS.isNullEmpty(dataObj.citta) ? "" : dataObj.citta,
                prov: CustomJS.isNullEmpty(dataObj.prov) ? "" : dataObj.prov,
                cap: CustomJS.isNullEmpty(dataObj.cap) ? "" : dataObj.cap,
                cell: CustomJS.isNullEmpty(dataObj.cell) ? "" : dataObj.cell,
                tel: CustomJS.isNullEmpty(dataObj.tel) ? "" : dataObj.tel,
                piva: CustomJS.isNullEmpty(dataObj.piva) ? "" : dataObj.piva,
                codfis: CustomJS.isNullEmpty(dataObj.codfis) ? "" : dataObj.codfis,
                id_anagrafico_esperto: '0'
            }

            CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);

            if (args.action === "add" || args.action === 'edit') {
                if (args.action === "add") {
                    this.isFromAdd = true;
                    this.danneggiatoAgg = document.getElementById('gridDanneggiati').ej2_instances[0].dataSource[0];
                    setTimeout(() => {
                        const QueryExec = encodeURIComponent(`SELECT MAX(id_danneggiato) AS id_danneggiato FROM acdanneggiati;`);
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                            retData = JSON.parse(retData.data);
                            sessionStorage.setItem('DanneggiatoCreato', retData[0].id_danneggiato);
                            setTimeout(() => {
                                this.caricaDati()
                            }, 100)
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            }
                            );
                        });
                    }, 200);
                }
                else {
                    this.isFromEdit = true;
                    setTimeout(() => {
                        this.caricaDati();
                    }, 200);
                }
            }
        }
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                {/* <DialogComponent id="defaultDialog" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance1 = dialog} isModal={true} height='80%' width='90%' minHeight='90%'>
                    <div id='defaultDialogContent'></div>
                </DialogComponent> */}
                <div className='container-fluid'>
                    <div className='row' id='gridSegreteria' >
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <GridComponent width={'100%'} id="gridDanneggiati" ref={grid => this.gridInstance = grid} selectedRowIndex={0} toolbar={this.toolbarOptions} hierarchyPrintMode='All' allowSelection={true} enableHover={false} showColumnMenu={false} showColumnChooser={false}
                                allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} allowPaging={true} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings}
                                allowGrouping={true} allowExcelExport={true} editSettings={this.editSettings} filterSettings={this.filterSettings} style={{ border: '0px' }}
                                actionComplete={this.actionComplete.bind(this)} actionBegin={this.actionBegin.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id_sinistro' headerText="ID Sinistro" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='danneggiato' headerText="Danneggiato" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_danneggiato' visible={false} headerText='ID' width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_sinistro' headerText="ID sinistro" width='15%' headerTemplate={headerTemplate} visible={false} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='nome' headerText="Nome" width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cognome' headerText="Cognome" width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='email' headerText="E-mail" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='indir' headerText="Indirizzo" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='citta' headerText="Città" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    {/* <ColumnDirective field='prov' headerText="Prov." width='8%' edit={this.editTemplate2} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective> */}
                                    <ColumnDirective field='prov' headerText="Prov." width='8%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cap' headerText="CAP" width='8%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='tel' headerText="Telefono" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cell' headerText="Cellulare" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='codfis' headerText="Cod. Fiscale" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='descdanno' headerText="Descrizione Danno" width='10%' headerTemplate={headerTemplate} visible={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    {/* <ColumnDirective field='garanzia' headerText="Garanzia" width='10%' headerTemplate={headerTemplate} visible={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='dannolordo' headerText="Danno Lordo" width='10%' headerTemplate={headerTemplate} visible={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='dannonetto' headerText="Danno netto" width='10%' headerTemplate={headerTemplate} visible={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective> */}
                                    <ColumnDirective field='piva' headerText="P.IVA" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, Edit, ExcelExport, Filter, DetailRow, CommandColumn, Selection, Sort, Group, Page, Search]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        // document.getElementById('titoloPagina').innerText = 'Danneggiati';
        //sessionStorage.setItem('BtnSblocca', false);
        setTimeout(() => {
            this.caricaDati();
        }, 300);
    }
    caricaDati() {
        if (this.props.arrivaDaDetSin === true && this.isFromAdd === false && this.isFromEdit === false) {
            return;
        }
        else if (this.props.arrivaDaDetSin === true && this.isFromEdit === true) {
            this.toggleBlocking();
            this.isFromEdit = false;
            CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'acdanneggiati', false, retData => {
                retData = JSON.parse(retData.data);
                this.gridInstance.dataSource = retData;
                this.gridInstance.search(retData[0].id_danneggiato)
                this.toggleBlocking();
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            }, `SELECT T1.id_danneggiato, T1.id_sinistro, T1.danneggiato, T1.nome, T1.cognome,T1.email, T1.indir, T1.citta, T1.prov, T1.cap, T1.cell,T1.tel,  T1.codfis, T1.piva FROM acdanneggiati T1 where id_danneggiato = ${this.IDAddOrEdit};`, null);
        }
        else if (this.props.arrivaDaDetSin === true && this.isFromAdd === true) {
            // this.toggleBlocking();
            CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'acdanneggiati', false, retData => {
                this.gridInstance.dataSource = JSON.parse(retData.data);
                this.gridInstance.search(this.danneggiatoAgg.id_danneggiato);
                // this.toggleBlocking();
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            }, 'SELECT T1.id_danneggiato, T1.id_sinistro, T1.danneggiato, T1.nome, T1.cognome, T1.email,T1.indir, T1.citta, T1.prov, T1.cap, T1.cell,T1.tel, T1.codfis, T1.piva FROM acdanneggiati T1 ORDER BY id_danneggiato DESC LIMIT 10000;', null);
            return;
        }
        else {
            this.toggleBlocking();
            CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'acdanneggiati', false, retData => {
                this.gridInstance.dataSource = JSON.parse(retData.data);
                this.toggleBlocking();
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            }, 'SELECT DISTINCT T1.id_danneggiato, T1.nome, T1.cognome,T1.email, T1.id_sinistro, T1.danneggiato, T1.indir, T1.citta, T1.prov, T1.cap, T1.cell,T1.tel, T1.codfis, T1.piva FROM acdanneggiati T1 INNER JOIN acsinistri T2 ON T1.id_sinistro = T2.id_sinistro LIMIT 10000;', null);
            return;
        }

    }
}
