/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { MenuComponent, SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { SampleBase } from './sampleBase';
import PaginaHome from './PaginaHome';
import PaginaLogin from './PaginaLogin';
import PaginaDettaglioSinistro from './PaginaDettaglioSinistro';
import PaginaSituazioneSinistri from './PaginaSituazioneSinistri';
import PaginaDanneggiati from './PaginaDanneggiati';
import PaginaAree from './PaginaAree';
import PaginaAgenzie from './PaginaAgenzie';
import PaginaBroker from './PaginaBroker';
import PaginaCompagnie from './PaginaCompagnie';
import PaginaDivisioni from './PaginaDivisioni';
import PaginaContraenti from './PaginaContraenti';
import PaginaUtenti from './PaginaUtenti';
import PaginaTipiPolizza from './PaginaTipiPolizza';
import PaginaTipiSinistro from './PaginaTipiSinistro';
import PaginaReferenti from './PaginaReferenti';
import PaginaControparti from './PaginaControparti';
import PaginaAssicurati from './PaginaAssicurati';
import PaginaCercaSinistro from './PaginaCercaSinistro';
import PaginaStatistiche from './PaginaStatistiche';
import JFXpertNav from '../assets/JFXpertNav.png';
import LogoDigitalPraesidium from '../assets/LogoDigitalPraesidium.png';
import FlagItaly from '../assets/Flag_Italy4.png';
import FlagUK from '../assets/Flag_UK4.png';
import FlagItaly2 from '../assets/Flag_Italy3.png';
import FlagUK2 from '../assets/Flag_UK3.png';
import * as CustomJS from '../Custom';
import logo from '../assets/iconeCustom/logoAllC.png'
import PaginaAmministratori from './PaginaAmministratori';
import PaginaLiquidatori from './PaginaLiquidatori';
import PaginaStati from './PaginaStati';
import PaginaTipiPerito from './PaginaTipiPerito';
import PaginaTabelleStandard from './PaginaTabelleStandard';
import PaginaCGA from './PaginaCGA';
import PaginaPiattaformaCorrezione from './PaginaPiattaformaCorrezione';
import PaginaModelliDocumento from './PaginaModelliDocumento';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import _ from 'underscore/underscore-min';

export class NavMenu extends SampleBase {
    constructor(props) {
        super(...arguments);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.fields = { tooltip: 'text' };
        this.menuItemsAdm = [
            { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
            {
                id: "id2", text: 'Gestione Sinistri', iconCss: "fas fa-file-contract", items: [{ id: 'id2_1', text: 'Situazione Sinistri', pagina: "SituazioneSinistri" },
                { id: 'id2_2', text: 'Inserimento Nuovo Sinistro', pagina: "NuovoSinistro" }]
            },
            { id: "id3", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' },
        ];
        this.menuItemsSegreteria = [
            { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
            {
                id: "id2", text: 'Gestione Sinistri', iconCss: "fas fa-book", items:
                    [
                        { id: 'id2_1', text: 'Situazione Sinistri', pagina: "SituazioneSinistri" },
                        { id: 'id2_2', text: 'Inserimento Nuovo Sinistro', pagina: "NuovoSinistro" }
                    ]
            },
            { id: "id3", text: 'Cerca ID', iconCss: "fas fa-search", pagina: "CercaSinistro" },
            { id: "id5", text: 'Statistiche', iconCss: "fas fa-chart-pie", pagina: "Statistiche" },
            { id: "id6", text: 'Centro messaggi', iconCss: "fas fa-envelope", pagina: "CentroMessaggi" },
            { id: "id7", text: 'Monitor', iconCss: "fas fa-tv", pagina: "Monitor" },
            { id: "id8", text: 'Athenaeum', iconCss: "fas fa-graduation-cap", pagina: "Athenaeum" },
            { id: "id9", text: 'Fatturazione', iconCss: "fas fa-receipt", pagina: "Fatturazione" },
            { id: "id10", text: 'Biblioteca', iconCss: "fas fa-folder", pagina: "Biblioteca" },
            {
                id: "id11", text: 'Link Utili', iconCss: "fas fa-file-invoice", items:
                    [
                        { id: 'id11_1', text: 'Fulmini', pagina: "Fulmini" },
                        { id: 'id11_2', text: 'Geoportale', pagina: "Geoportale" },
                        { id: 'id11_3', text: 'Radarmeteo', pagina: "Radarmeteo" },
                        { id: 'id11_4', text: 'Rivaluta', pagina: "Rivaluta" },
                        { id: 'id11_5', text: 'Preesistenza', pagina: "Preesistenza" },
                        { id: 'id11_6', text: 'Mappa', pagina: "Mappa" }
                    ]
            },
            {
                id: "id12S", text: 'Anagrafiche', iconCss: "fas fa-tools", items:
                    [
                        { id: 'id12_1', text: 'Aree', pagina: "Aree" },
                        { id: 'id12_2', text: 'Agenzie', pagina: "Agenzie" },
                        { id: 'id12_3', text: 'Assicurati', pagina: "Assicurati" },
                        { id: 'id12_4', text: 'Amministratori', pagina: "Amministratori" },
                        { id: 'id12_5', text: 'Broker', pagina: "Broker" },
                        { id: 'id12_6', text: 'Contraenti', pagina: "Contraenti" },
                        { id: 'id12_7', text: 'Compagnie', pagina: "Compagnie" },
                        { id: 'id12_8', text: 'Controparti', pagina: "Controparti" },
                        { id: 'id12_9', text: 'Divisioni', pagina: "Divisioni" },
                        { id: 'id12_10', text: 'Danneggiati', pagina: "Danneggiati" },
                        { id: 'id12_11', text: 'Referenti', pagina: "Referenti" },
                        { id: 'id12_12', text: 'Tipi Polizza', pagina: "TipiPolizza" },
                        { id: 'id12_13', text: 'Tipi Sinistro', pagina: "TipiSinistro" },
                        { id: 'id12_14', text: 'Utenti', pagina: "Utenti" },
                        { id: 'id12_15', text: 'Liquidatori', pagina: "Liquidatori" },
                        { id: 'id12_16', text: 'Tipi Perito', pagina: "TipiPerito" },
                        { id: 'id12_17', text: 'Tabelle Standard', pagina: "TabelleStandard" },
                        { id: 'id12_18', text: 'CGA', pagina: "CGA" },
                        { id: 'id12_19', text: 'Modelli Documento', pagina: "ModelliDocumento" },
                    ]
            },

            { id: "navMenu_uscita", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' },
        ];
        this.menuItemsPeriti = [
            { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
            { id: "id2", text: 'Gestione Sinistri', iconCss: "fas fa-book", pagina: "SituazioneSinistri" },
            { id: "id3", text: 'Cerca ID', iconCss: "fas fa-search", pagina: "CercaSinistro" },
            // { id: "id4", text: 'Perizia Online', iconCss: "fas fa-edit", pagina: "PeriziaOnline" },
            { id: "id5", text: 'Perizia Smart', iconCss: "fas fa-video", pagina: "PeriziaSmart" },
            { id: "id6", text: 'Statistiche', iconCss: "fas fa-chart-pie", pagina: "Statistiche" },
            { id: "id7", text: 'Centro messaggi', iconCss: "fas fa-envelope", pagina: "CentroMessaggi" },
            { id: "id8", text: 'Monitor', iconCss: "fas fa-tv", pagina: "Monitor" },
            { id: "id9", text: 'Athenaeum', iconCss: "fas fa-graduation-cap", pagina: "Athenaeum" },
            { id: "id10", text: 'Fatturazione', iconCss: "fas fa-receipt", pagina: "Fatturazione" },
            { id: "id11", text: 'Biblioteca', iconCss: "fas fa-folder", pagina: "Biblioteca" },
            {
                id: "id12", text: 'Link Utili', iconCss: "fas fa-file-invoice", items:
                    [
                        { id: 'id12_1', text: 'Fulmini', pagina: "Fulmini" },
                        { id: 'id12_2', text: 'Geoportale', pagina: "Geoportale" },
                        { id: 'id12_3', text: 'Radarmeteo', pagina: "Radarmeteo" },
                        { id: 'id12_4', text: 'Rivaluta', pagina: "Rivaluta" },
                        { id: 'id12_5', text: 'Preesistenza', pagina: "Preesistenza" },
                        { id: 'id11_6', text: 'Mappa', pagina: "Mappa" }
                    ]
            },
            {
                id: "id13", text: 'Anagrafiche', iconCss: "fas fa-tools", items:
                    [
                        { id: 'id13_1', text: 'CGA', pagina: "CGA" },
                        { id: 'id13_2', text: 'Modelli Documento', pagina: "ModelliDocumento" },
                    ]
            },
            { id: "navMenu_uscita", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' },
        ];
        this.menuItemsCorrettori = [
            { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
            { id: "id14", text: 'Correzione', iconCss: "fas fa-marker", pagina: 'Correzione' },
            { id: "id6", text: 'Statistiche', iconCss: "fas fa-chart-pie", pagina: "Statistiche" },
            { id: "id7", text: 'Centro messaggi', iconCss: "fas fa-envelope", pagina: "CentroMessaggi" },
            { id: "navMenu_uscita", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' },
        ];

        this.menuItemsUtente = [];
        this.childKey = 0;
        this.itemsNavUtente = [
            { id: "idLog1", text: 'Profilo', iconCss: "fa fa-user", pagina: 'Profilo' },
            { id: "idLog2", text: 'Richiesta Assistenza', iconCss: "fas fa-handshake-angle", pagina: 'Assistenza' },
            { id: "idLog3", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' }
        ];
        this.container = null;

        //CONTROLLO TIPO UTENTE
        this.tipoUtente = '';
        let profilo = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo;
        let isReferente = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].ck_referente;
        if (profilo === 9) {
            if (isReferente === 1 || isReferente === true)
                this.tipoUtente = 'correttore_cr'
            else
                this.tipoUtente = 'perito'
        }
        else if (profilo === 8) {
            this.tipoUtente = 'correttore_cq'
        }
        else if (profilo === 1) {
            this.tipoUtente = 'segreteria'
        }
        this.idUser = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;
    }
    state = {
        collapsed: true,
        showDialogConfermaUscitaNavbar: false,
        showDialog1: false
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    cambiaLinguaApplicativo(lingua) {

    }
    openClick() {
        document.getElementById('sidebar-menu').ej2_instances[0].toggle();
        if (document.getElementById('sidebar-menu').ej2_instances[0].isOpen === true) {
            document.getElementById('contenutoDinamico2').style.marginLeft = '155px'
        }
        else {
            document.getElementById('contenutoDinamico2').style.marginLeft = '0px'
        }
    }
    onSelectNavUtente(args) {
        if (CustomJS.isNullEmpty(args.item.pagina)) return;
        document.getElementById('sidebar-menu').ej2_instances[0].hide();
        let componente, divContainer = '';

        ++this.childKey;
        switch (args.item.pagina) {
            case 'Login':
                sessionStorage.removeItem("JellyfishXpertSessionTimeout")
                sessionStorage.removeItem('JellyfishSIPAEVOAuthenticationPayload');
                sessionStorage.setItem("JellyfishXpertPaginaLogin", "true");
                componente = <PaginaLogin key={this.childKey}></PaginaLogin>;
                divContainer = 'contenutoDinamico1';
                break;
            default:
                alert('Funzionalità non ancora implementata!');
                return;
        }
        const container = document.getElementById(divContainer);
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
            if (divContainer === 'contenutoDinamico1')
                window.location.reload();
        }}>{componente}</CallbackWrapper>);
    }
    onSelectMenu(args) {
        if (CustomJS.isNullEmpty(args.item.pagina)) return;
        if (sessionStorage.getItem('BtnSblocca') === 'true'
            && (args.item.pagina === 'Home' || args.item.pagina === 'SituazioneSinistri' || args.item.pagina === 'NuovoSinistro' || args.item.pagina === 'Login' || args.item.pagina === 'CercaSinistro')) {
            this.dialogInstanceConfermaUscitaNavbar.width = '30%';
            this.dialogInstanceConfermaUscitaNavbar.height = '25%';
            this.dialogInstanceConfermaUscitaNavbar.minHeight = '25%';
            this.dialogInstanceConfermaUscitaNavbar.buttons = [];
            this.dialogInstanceConfermaUscitaNavbar.beforeOpen = (args) => {
                const container = document.getElementById('confermaUscitaDialogNavBarContent');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstanceConfermaUscitaNavbar.open = (args2) => {
                const container = document.getElementById('confermaUscitaDialogNavBarContent');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                }}>
                    <div style={{ textAlign: 'center' }}>
                        <h4>{CustomJS.messaggioConfermaUscita}</h4>
                        <i className="fa fa-question-circle"></i>Scegli un opzione<br></br>
                        <div style={{ width: '70%', display: 'inline-flex' }}>
                            <button id='annullaBtn' className='pulsanti-sessione-custom' onClick={() => { this.dialogInstanceConfermaUscitaNavbar.hide(); }} >Annulla</button>
                            <button id='dialogMenuEsciBtn' style={{ backgroundColor: '#b62f20' }} className='pulsanti-sessione-custom' onClick={this.onSelectMenuAfterDialog.bind(this, args.item.pagina)}>Esci</button>
                        </div>
                    </div>
                </CallbackWrapper>);
            };
            this.dialogInstanceConfermaUscitaNavbar.show();
        }
        else {
            document.getElementById('sidebar-menu').ej2_instances[0].hide();
            let componente, divContainer = '';

            ++this.childKey;
            switch (args.item.pagina) {
                case 'Home':
                    this.distruggiDialog();
                    componente = <PaginaHome key={this.childKey}></PaginaHome>;
                    divContainer = 'contenutoDinamico2';
                    break;
                case 'SituazioneSinistri':
                    this.distruggiDialog();
                    componente = <PaginaSituazioneSinistri key={this.childKey}></PaginaSituazioneSinistri>;
                    divContainer = 'contenutoDinamico2';
                    break;
                case 'NuovoSinistro':
                    this.distruggiDialog();
                    componente = <PaginaDettaglioSinistro key={this.childKey}></PaginaDettaglioSinistro>;
                    divContainer = 'contenutoDinamico2';
                    break;
                case 'CercaSinistro':
                    this.distruggiDialog();
                    componente = <PaginaCercaSinistro key={this.childKey}></PaginaCercaSinistro>;
                    divContainer = 'contenutoDinamico2';
                    break;
                case 'Statistiche':
                    componente = <PaginaStatistiche key={this.childKey}></PaginaStatistiche>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Statistiche');
                    return;
                    break;
                case 'Login':
                    sessionStorage.removeItem("JellyfishXpertSessionTimeout")
                    sessionStorage.removeItem('JellyfishSIPAEVOAuthenticationPayload');
                    sessionStorage.setItem("JellyfishXpertPaginaLogin", "true");
                    componente = <PaginaLogin key={this.childKey}></PaginaLogin>;
                    divContainer = 'contenutoDinamico1';
                    break;
                case 'Geoportale':
                    window.open(
                        'http://geoportale.comune.torino.it/web/',
                        '_blank')
                    break;
                case 'Radarmeteo':
                    window.open(
                        'https://meteocast.radarmeteo.com/',
                        '_blank')
                    break;
                case 'Preesistenza':
                    window.open(
                        'https://cresme.cineas.it/',
                        '_blank')
                    break;
                case 'Fulmini':
                    window.open(
                        'https://www.lightningmaps.org/?lang=it#m=oss;t=3;s=0;o=0;b=;ts=0;ts24=1;',
                        '_blank')
                    break;
                case 'Rivaluta':
                    window.open(
                        'https://rivaluta.istat.it/Rivaluta/',
                        '_blank')
                    break;
                case 'Monitor':
                    window.open(
                        'http://93.186.247.14:5000/',
                        '_blank')
                    break;
                case 'Athenaeum':
                    window.open(
                        'https://athenaeum.gruppoallconsulting.com/',
                        '_blank')
                    break;
                case 'Mappa':
                    window.open(
                        'https://www.google.com/maps/d/viewer?mid=1dBCPfPbzLOZwLeR2pZxMvz_0xdLb8Bc&ll=42.607263382307465%2C10.138121849999997&z=6',
                        '_blank')
                    break;
                case 'Danneggiati':
                    componente = <PaginaDanneggiati key={this.childKey}></PaginaDanneggiati>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Danneggiati');
                    return;
                    break;
                case 'Aree':
                    componente = <PaginaAree key={this.childKey}></PaginaAree>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Aree');
                    return;
                    break;
                case 'Agenzie':
                    componente = <PaginaAgenzie key={this.childKey}></PaginaAgenzie>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Agenzie');
                    return;
                    break;
                case 'Broker':
                    componente = <PaginaBroker key={this.childKey}></PaginaBroker>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Broker');
                    return;
                    break;
                case 'Compagnie':
                    componente = <PaginaCompagnie key={this.childKey}></PaginaCompagnie>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Compagnie');
                    return;
                    break;
                case 'Divisioni':
                    componente = <PaginaDivisioni key={this.childKey}></PaginaDivisioni>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Divisioni');
                    return;
                    break;
                case 'Contraenti':
                    componente = <PaginaContraenti key={this.childKey}></PaginaContraenti>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Contraenti');
                    return;
                    break;
                case 'Utenti':
                    componente = <PaginaUtenti key={this.childKey}></PaginaUtenti>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Utenti');
                    return;
                    break;
                case 'TipiPolizza':
                    componente = <PaginaTipiPolizza key={this.childKey}></PaginaTipiPolizza>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Tipi Polizza');
                    return;
                    break;
                case 'TipiSinistro':
                    componente = <PaginaTipiSinistro key={this.childKey}></PaginaTipiSinistro>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Tipi Sinistro');
                    return;
                    break;
                case 'Referenti':
                    componente = <PaginaReferenti key={this.childKey}></PaginaReferenti>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Referenti');
                    return;
                    break;
                case 'Controparti':
                    componente = <PaginaControparti key={this.childKey}></PaginaControparti>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Controparti');
                    return;
                    break;
                case 'Assicurati':
                    componente = <PaginaAssicurati key={this.childKey}></PaginaAssicurati>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Assicurati');
                    return;
                    break;
                case 'Amministratori':
                    componente = <PaginaAmministratori key={this.childKey}></PaginaAmministratori>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Amministratori');
                    return;
                    break;
                case 'Liquidatori':
                    componente = <PaginaLiquidatori key={this.childKey}></PaginaLiquidatori>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Liquidatori');
                    return;
                    break;
                case 'Stati':
                    componente = <PaginaStati key={this.childKey}></PaginaStati>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Stati Sinistro');
                    return;
                    break;
                case 'TipiPerito':
                    componente = <PaginaTipiPerito key={this.childKey}></PaginaTipiPerito>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Tipi Perito');
                    return;
                    break;
                case 'TabelleStandard':
                    componente = <PaginaTabelleStandard key={this.childKey}></PaginaTabelleStandard>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Tabelle Standard');
                    return;
                    break;
                case 'CGA':
                    componente = <PaginaCGA key={this.childKey}></PaginaCGA>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'CGA');
                    return;
                    break;
                case 'Correzione':
                    this.distruggiDialog();
                    componente = <PaginaPiattaformaCorrezione key={this.childKey}></PaginaPiattaformaCorrezione>;
                    divContainer = 'contenutoDinamico2';
                    break;
                case 'ModelliDocumento':
                    componente = <PaginaModelliDocumento key={this.childKey}></PaginaModelliDocumento>;
                    divContainer = 'contenutoDinamico2';
                    this.apriDialogFunzionalita(componente, 'Modelli Documento');
                    return;
                    break;
                default:
                    alert('Funzionalità non ancora implementata!');
                    return;
            }
            if (document.getElementById('defaultDialogFunzionalita').ej2_instances[0].visible === true)
                document.getElementById('defaultDialogFunzionalita').ej2_instances[0].hide();

            this.container = document.getElementById(divContainer);
            this.rootContainer = createRoot(this.container);
            this.rootContainer.render(<CallbackWrapper callback={() => {
                if (divContainer === 'contenutoDinamico1') {
                    window.location.reload();
                }
                else {
                    if (document.getElementById('sidebar-menu').ej2_instances[0].isOpen === true) {
                        document.getElementById('contenutoDinamico2').style.marginLeft = '155px'
                    }
                    else {
                        document.getElementById('contenutoDinamico2').style.marginLeft = '0px'
                    }
                }
            }}>{componente}</CallbackWrapper>);
        }
    }
    onSelectMenuAfterDialog(args) {
        this.dialogInstanceConfermaUscitaNavbar.hide();
        if (CustomJS.isNullEmpty(args))
            return;
        document.getElementById('sidebar-menu').ej2_instances[0].hide();
        let componente, divContainer = '';

        ++this.childKey;
        switch (args) {
            case 'Home':
                this.distruggiDialog();
                componente = <PaginaHome key={this.childKey}></PaginaHome>;
                divContainer = 'contenutoDinamico2';
                break;
            case 'SituazioneSinistri':
                this.distruggiDialog();
                componente = <PaginaSituazioneSinistri key={this.childKey}></PaginaSituazioneSinistri>;
                divContainer = 'contenutoDinamico2';
                break;
            case 'NuovoSinistro':
                this.distruggiDialog();
                componente = <PaginaDettaglioSinistro key={this.childKey}></PaginaDettaglioSinistro>;
                divContainer = 'contenutoDinamico2';
                break;
            case 'Login':
                sessionStorage.removeItem("JellyfishXpertSessionTimeout")
                sessionStorage.removeItem('JellyfishSIPAEVOAuthenticationPayload');
                sessionStorage.setItem("JellyfishXpertPaginaLogin", "true");
                componente = <PaginaLogin key={this.childKey}></PaginaLogin>;
                divContainer = 'contenutoDinamico1';
                break;
            case 'Geoportale':
                window.open(
                    'http://geoportale.comune.torino.it/web/',
                    '_blank')
                break;
            case 'Radarmeteo':
                window.open(
                    'https://meteocast.radarmeteo.com/',
                    '_blank')
                break;
            case 'Preesistenza':
                window.open(
                    'https://cresme.cineas.it/',
                    '_blank')
                break;
            case 'Fulmini':
                window.open(
                    'https://www.lightningmaps.org/?lang=it#m=oss;t=3;s=0;o=0;b=;ts=0;ts24=1;',
                    '_blank')
                break;
            case 'Rivaluta':
                window.open(
                    'https://rivaluta.istat.it/Rivaluta/',
                    '_blank')
                break;
            case 'Monitor':
                window.open(
                    'http://93.186.247.14:5000/',
                    '_blank')
                break;
            case 'Athenaeum':
                window.open(
                    'https://athenaeum.gruppoallconsulting.com/',
                    '_blank')
                break;
            case 'Mappa':
                window.open(
                    'https://www.google.com/maps/d/viewer?mid=1dBCPfPbzLOZwLeR2pZxMvz_0xdLb8Bc&ll=42.607263382307465%2C10.138121849999997&z=6',
                    '_blank')
                break;
            case 'Danneggiati':
                componente = <PaginaDanneggiati key={this.childKey}></PaginaDanneggiati>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Danneggiati');
                return;
                break;
            case 'Aree':
                componente = <PaginaAree key={this.childKey}></PaginaAree>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Aree');
                return;
                break;
            case 'Agenzie':
                componente = <PaginaAgenzie key={this.childKey}></PaginaAgenzie>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Agenzie');
                return;
                break;
            case 'Broker':
                componente = <PaginaBroker key={this.childKey}></PaginaBroker>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Broker');
                return;
                break;
            case 'Compagnie':
                componente = <PaginaCompagnie key={this.childKey}></PaginaCompagnie>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Compagnie');
                return;
                break;
            case 'Divisioni':
                componente = <PaginaDivisioni key={this.childKey}></PaginaDivisioni>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Divisioni');
                return;
                break;
            case 'Contraenti':
                componente = <PaginaContraenti key={this.childKey}></PaginaContraenti>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Contraenti');
                return;
                break;
            case 'Utenti':
                componente = <PaginaUtenti key={this.childKey}></PaginaUtenti>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Utenti');
                return;
                break;
            case 'TipiPolizza':
                componente = <PaginaTipiPolizza key={this.childKey}></PaginaTipiPolizza>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Tipi Polizza');
                return;
                break;
            case 'TipiSinistro':
                componente = <PaginaTipiSinistro key={this.childKey}></PaginaTipiSinistro>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Tipi Sinistro');
                return;
                break;
            case 'Referenti':
                componente = <PaginaReferenti key={this.childKey}></PaginaReferenti>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Referenti');
                return;
                break;
            case 'Controparti':
                componente = <PaginaControparti key={this.childKey}></PaginaControparti>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Controparti');
                return;
                break;
            case 'Assicurati':
                componente = <PaginaAssicurati key={this.childKey}></PaginaAssicurati>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Assicurati');
                return;
                break;
            case 'Amministratori':
                componente = <PaginaAmministratori key={this.childKey}></PaginaAmministratori>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Amministratori');
                return;
                break;
            case 'Liquidatori':
                componente = <PaginaLiquidatori key={this.childKey}></PaginaLiquidatori>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Liquidatori');
                return;
                break;
            case 'Stati':
                componente = <PaginaStati key={this.childKey}></PaginaStati>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Stati Sinistro');
                return;
                break;
            case 'TipiPerito':
                componente = <PaginaTipiPerito key={this.childKey}></PaginaTipiPerito>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Tipi Perito');
                return;
                break;
            case 'TabelleStandard':
                componente = <PaginaTabelleStandard key={this.childKey}></PaginaTabelleStandard>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Tabelle Standard');
                return;
                break;
            case 'CercaSinistro':
                this.distruggiDialog();
                componente = <PaginaCercaSinistro key={this.childKey}></PaginaCercaSinistro>;
                divContainer = 'contenutoDinamico2';
                break;
            case 'Statistiche':
                componente = <PaginaStatistiche key={this.childKey}></PaginaStatistiche>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Statistiche');
                return;
                break;
            case 'CGA':
                componente = <PaginaCGA key={this.childKey}></PaginaCGA>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'CGA');
                return;
                break;
            case 'Correzione':
                this.distruggiDialog();
                componente = <PaginaPiattaformaCorrezione key={this.childKey}></PaginaPiattaformaCorrezione>;
                divContainer = 'contenutoDinamico2';
                break;
            case 'ModelliDocumento':
                componente = <PaginaModelliDocumento key={this.childKey}></PaginaModelliDocumento>;
                divContainer = 'contenutoDinamico2';
                this.apriDialogFunzionalita(componente, 'Modelli Documento');
                return;
                break;
            default:
                alert('Funzionalità non ancora implementata!');
                return;
        }

        if (document.getElementById('defaultDialogFunzionalita').ej2_instances[0].visible === true)
            document.getElementById('defaultDialogFunzionalita').ej2_instances[0].hide();

        this.container = document.getElementById(divContainer);
        this.rootContainer = createRoot(this.container);
        this.rootContainer.render(<CallbackWrapper callback={() => {
            if (divContainer === 'contenutoDinamico1') {
                window.location.reload();
            }
            else {
                if (document.getElementById('sidebar-menu').ej2_instances[0].isOpen === true) {
                    document.getElementById('contenutoDinamico2').style.marginLeft = '155px'
                }
                else {
                    document.getElementById('contenutoDinamico2').style.marginLeft = '0px'
                }
            }
        }}>{componente}</CallbackWrapper>);
    }
    onCreatedSideBar(args) {
        setTimeout(() => {
            this.element.style.visibility = "visible";
        }, 2500)
    }
    apriDialogFunzionalita(componente, titolo) {
        if (this.dialogInstance1.visible === true) {
            let container = document.getElementById('defaultDialogFunzionalitaContent');
            let root = createRoot(container);
            root.unmount();
            setTimeout(() => {
                container = document.getElementById('defaultDialogFunzionalitaContent');
                root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.dialogInstance1.header = `<div style="text-align: center;"><span class='titoloPagina'>${titolo}</span></div>`;
                }}><div className='anagrafiche-sipa'>{componente}</div></CallbackWrapper>);
            }, 100);
        }
        else {
            this.dialogInstance1.header = `<div style="text-align: center;"><span class='titoloPagina'>${titolo}</span></div>`;
            this.dialogInstance1.width = '95%';
            this.dialogInstance1.height = '93%';
            this.dialogInstance1.minHeight = '93%';
            this.dialogInstance1.buttons = [];
            this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogFunzionalitaContent');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogFunzionalitaContent');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                }}><div className='anagrafiche-sipa'>{componente}</div></CallbackWrapper>);
            };
            this.dialogInstance1.close = (args) => {
                if (document.getElementById('defaultDialogCGA'))
                    document.getElementById('defaultDialogCGA').ej2_instances[0]?.destroy();
                if (document.getElementById('defaultDialogModelliDocumento'))
                    document.getElementById('defaultDialogModelliDocumento').ej2_instances[0]?.destroy();
            };
            this.dialogInstance1.show();
        }
    }
    distruggiDialog() {
        //DASHBOARD
        if (document.getElementById('dialogDashboard'))
            document.getElementById('dialogDashboard').ej2_instances[0]?.destroy();
        //SITUAZIONE SINISTRI
        if (document.getElementById('defaultDialogSitSin'))
            document.getElementById('defaultDialogSitSin').ej2_instances[0]?.destroy();
        if (document.getElementById('defaultDialogSitSin2'))
            document.getElementById('defaultDialogSitSin2').ej2_instances[0]?.destroy();
        if (document.getElementById('defaultDialogSitSin3'))
            document.getElementById('defaultDialogSitSin3').ej2_instances[0]?.destroy();
        //DETTAGLIO SINISTRO SEGRETERIA
        if (document.getElementById('aggiungiDefaultsDialog'))
            document.getElementById('aggiungiDefaultsDialog').ej2_instances[0]?.destroy();
        if (document.getElementById('defaultDialogConfermaUscita'))
            document.getElementById('defaultDialogConfermaUscita').ej2_instances[0]?.destroy();
        if (document.getElementById('defaultDialogContattaAssicurato'))
            document.getElementById('defaultDialogContattaAssicurato').ej2_instances[0]?.destroy();
        if (document.getElementById('defaultDialog1'))
            document.getElementById('defaultDialog1').ej2_instances[0]?.destroy();
        //DETTAGLIO SINISTRO PERITO
        if (document.getElementById('aggiungiDefaultsDialogPerito'))
            document.getElementById('aggiungiDefaultsDialogPerito').ej2_instances[0]?.destroy();
        if (document.getElementById('defaultDialog3'))
            document.getElementById('defaultDialog3').ej2_instances[0]?.destroy();
        //PERIZIA ONLINE ELABORATO
        if (document.getElementById('dialogElaboratoPeritale'))
            document.getElementById('dialogElaboratoPeritale').ej2_instances[0]?.destroy();
        //PERIZIA ONLINE CALCOLI
        if (document.getElementById('dialogPrezziario'))
            document.getElementById('dialogPrezziario').ej2_instances[0]?.destroy();
        if (document.getElementById('dialogEliminaCalcoli'))
            document.getElementById('dialogEliminaCalcoli').ej2_instances[0]?.destroy();
        //PIATTAFORMA CORREZIONE
        if (document.getElementById('dialogCorrezione'))
            document.getElementById('dialogCorrezione').ej2_instances[0]?.destroy();
        if (document.getElementById('dialogCorrezione2'))
            document.getElementById('dialogCorrezione2').ej2_instances[0]?.destroy();
        if (document.getElementById('dialogCorrezione3'))
            document.getElementById('dialogCorrezione3').ej2_instances[0]?.destroy();
        if (document.getElementById('dialogCorrezioneAllegatiPdf'))
            document.getElementById('dialogCorrezioneAllegatiPdf').ej2_instances[0]?.destroy();
    }

    render() {
        // if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].external_login === 'Amministratori'
        //     || JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].external_login === 'SysAdmin') {
        //     this.menuItemsUtente = this.menuItemsAdm;
        // }
        if (this.tipoUtente === 'segreteria') {
            this.menuItemsUtente = this.menuItemsSegreteria;
        }
        else if (this.tipoUtente === 'perito') {
            this.menuItemsUtente = this.menuItemsPeriti;
        }
        else if (this.tipoUtente === 'correttore_cr') {
            this.menuItemsPeriti.push({ id: "id14", text: 'Correzione', iconCss: "fas fa-marker", pagina: 'Correzione' })
            this.menuItemsUtente = this.menuItemsPeriti;
        }
        else if (this.tipoUtente === 'correttore_cq') {
            this.menuItemsUtente = this.menuItemsCorrettori;
        }
        return (
            <React.Fragment>
                <DialogComponent id="defaultDialogConfermaUscitaNavbar" showCloseIcon={false} target='body' animationSettings={this.animationSettings} visible={this.state.showDialogConfermaUscitaNavbar}
                    ref={dialog => this.dialogInstanceConfermaUscitaNavbar = dialog} isModal={true}>
                    <div id='confermaUscitaDialogNavBarContent'></div>
                </DialogComponent>
                <DialogComponent id="defaultDialogFunzionalita" showCloseIcon={true} target='body' closeOnEscape={true} animationSettings={this.animationSettings} visible={this.state.showDialog1}
                    ref={dialogObj => this.dialogInstance1 = dialogObj} isModal={true} style={{ marginTop: '40px', marginLeft: '50px' }}>
                    <div id='defaultDialogFunzionalitaContent'></div>
                </DialogComponent>
                <div className="header-section" id='header'>
                    <div className="row">
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 text-start">
                            <span id="hamburger" onClick={this.openClick.bind(this)} className="fas fa-bars fa-2x icon list" style={{ width: '64px', backgroundColor: '#243866', color: 'white', marginTop: '-5px', paddingLeft: '17px' }}  ></span>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-start" >
                            <img src={JFXpertNav} height='auto' width="120px" style={{ marginLeft: '-40px' }} />
                        </div>
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 text-start" >
                            <img src={LogoDigitalPraesidium} height='auto' width="120px" style={{ marginLeft: '-70%' }} />
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 text-center">
                            <span id='titoloPagina' style={{ paddingTop: '25px', fontSize: '1.8vw', fontWeight: 'bolder', color: '#133B95', fontFamily: 'Muli, sans-serif' }}></span>
                        </div>
                        {/* <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-end">
                            <span style={{ paddingTop: '30px', fontSize: '1.3vw', fontWeight: '600', color: '#133B95', fontFamily: 'system-ui', marginRight: '20px' }}>{JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].denom} ({JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo == 1 ? 'Segreteria' : 'Perito'})</span>
                        </div> */}
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2" id='tempoSessione'>
                            <span >Tempo rimanente scadenza sessione :</span><br></br>
                            <span id="tempoRimanenteNav" ></span>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" id='boxUserLogout'>
                            <DropDownButtonComponent id="UserLogout" items={this.itemsNavUtente} select={this.onSelectNavUtente.bind(this)}>
                                <img id='logoAllC' src={logo} ></img>
                                {JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].denom} ({JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 1 ? 'Segreteria' : JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 8 ? 'Correttore CQ' : 'Perito'})
                            </DropDownButtonComponent>
                        </div>
                    </div>
                    <div >
                        <SidebarComponent enableGestures={false} ref={Sidebar => this.sidebarObj = Sidebar} dockSize="65px" enableDock="true" width="220px" id="sidebar-menu" type="Push" created={this.onCreatedSideBar} showBackdrop={true} closeOnDocumentClick={false}>
                            <div className="title-header">
                                <div className="main-menu">
                                    <div>
                                        <div id="listcontainer">
                                            <MenuComponent id='main-menubar' items={this.menuItemsUtente} created={this.insertBadge.bind(this)} select={this.onSelectMenu.bind(this)} orientation='Vertical' cssClass='dock-menu' style={{ marginLeft: '8px' }}></MenuComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SidebarComponent>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    insertBadge(args) {
        //controllo utente
        if (this.tipoUtente === 'correttore_cr' || this.tipoUtente === 'correttore_cq') {
            const container = document.getElementById('id14');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                //carico numero dati badge
                //idUser = @id_correttore della chiamata al backand
                CustomJS.callAPI(`AccessoDatiSIPAEVO/SituazioneSinistriCorrezione/${this.tipoUtente}/${this.idUser}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    if (retData.response === 'OK') {
                        var arrayData = JSON.parse(retData.data);
                        var dataSourceDaCorreggere = [];
                        var dataSourceInCorrezione = [];

                        if (this.tipoUtente === 'correttore_cq') {
                            arrayData.forEach(elm => {
                                if ((elm.in_correzione === 1 || elm.in_correzione === true) &&
                                    ((CustomJS.isNullEmpty(elm.id_correttore)) || elm.id_correttore === 0)
                                    && elm.profilo === 8) {
                                    dataSourceDaCorreggere.push(elm)
                                }
                            })
                            arrayData.forEach(elm => {
                                if (elm.id_correttore === JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user &&
                                    (elm.in_correzione === 1 || elm.in_correzione === true)
                                    && elm.profilo === 8) {
                                    dataSourceInCorrezione.push(elm)
                                }
                            });

                        }
                        if (this.tipoUtente === 'correttore_cr') {
                            arrayData.forEach(elm => {
                                if ((elm.in_correzione === 1 || elm.in_correzione === true)
                                    && (elm.data_inizio === '0000-00-00 00:00:00' || CustomJS.isNullEmpty(elm.data_inizio))
                                    && (elm.data_res ?? '0000-00-00') === '0000-00-00' && elm.profilo === 9) {
                                    dataSourceDaCorreggere.push(elm)
                                }
                            })
                            arrayData.forEach(elm => {
                                if ((elm.in_correzione === 1 || elm.in_correzione === true)
                                    && elm.data_inizio !== '0000-00-00 00:00:00' && !CustomJS.isNullEmpty(elm.data_inizio)
                                    && (elm.data_res ?? '0000-00-00') === '0000-00-00' && elm.profilo === 9) {
                                    dataSourceInCorrezione.push(elm)
                                }
                            });
                        }
                        //aggiorno i badge
                        document.getElementById('badgeDaCorreggere').innerText = dataSourceDaCorreggere.length;
                        document.getElementById('badgeInCorrezione').innerText = dataSourceInCorrezione.length;
                    }
                    else if (retData.response === 'KO') {
                        toast.warn(retData.msg === 'UtenteNonTrovato' ? CustomJS.messaggioUtenteNonTrovato : CustomJS.messaggioErroreNoDBSIPA, {
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, false)
            }}><div className="badge-block">
                    <span id='id14_icona' className='e-menu-icon fas fa-marker' style={{ marginLeft: '-10px' }}></span>
                    <span id='badgeDaCorreggere' title='Da correggere' className="e-badge e-badge-info e-badge-overlap e-badge-notification" style={{ top: '0px', left: '37px', backgroundColor: 'green', padding: '0px' }}>0</span>
                    <span id='badgeInCorrezione' title='In correzione' className="e-badge e-badge-info e-badge-overlap e-badge-notification" style={{ top: '22px', left: '37px', backgroundColor: 'red', padding: '0px' }}>0</span>
                </div></CallbackWrapper>);
            //carico dati numero correzioni
        }
    }
}