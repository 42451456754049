/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent, CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent, DateRangePickerComponent, Inject as DTPInject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';
import PaginaDettaglioSinistro from './PaginaDettaglioSinistro';
import "react-awesome-lightbox/build/style.css";
import $ from 'jquery';
import moment from 'moment';
import imgAttenzione from '../assets/Attenzione.png';
import imgChiusuraTelematica from '../assets/GreenOK2.png';
import imgPeriziaOnlinePresente from '../assets/RedOK.png';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate1(props) {
    let colore = props.column.field === 'Esito' && props[props.column.field].indexOf('scartato') > -1 ? 'red' : 'green';
    let renderDiv = <div><span style={{ fontSize: '0.85vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}

function fieldTemplate2(props) {
    let renderDiv = (props.column.field === 'id_sinistro' || props.column.field === 'RITARDO_SINISTRO' || props.column.field === 'denom') ? <div><span style={{ fontSize: '0.7vw', fontWeight: 'bolder', fontFamily: 'Muli, sans-serif' }}>{props[props.column.field]}</span></div> : (props.column.field === 'Notifica' && !CustomJS.isNullEmpty(props.PRESENZA_ALTRI_SINISTRI_UGUALI)) ? <><img title='Attenzione!!' src={imgAttenzione} height='25px' width='25px' /></> : <></>;
    return (
        renderDiv
    );
}

function fieldTemplate3(props) {
    let renderDiv = [];
    if (props.column.field === 'chiusura_telematica' && (!CustomJS.isNullEmpty(props.id_incarico) || (props.chiusura_telematica)?.toString() === 'true'))
        renderDiv.push(<><img title='Comunicazione Telematica Abilitata' src={imgChiusuraTelematica} height='35px' width='35px' /></>);
    else 
        renderDiv.push(<></>);
    if (!CustomJS.isNullEmpty(props.PresenzaPeriziaOnline))
        renderDiv.push(<><img title='Presenza Perizia Online' src={imgPeriziaOnlinePresente} height='30px' width='30px' style={{ marginTop: '5px' }} /></>);
    return (
        renderDiv
    );
}
let flag = true;

export default class PaginaSituazioneSinistri extends SampleBase {
    constructor() {
        super(...arguments);
        this.toolbarOptions = ['Add', 'Edit', 'Update', 'Cancel', 'ExcelExport', { template: this.ddlFiltroSinistriToolbarSegreteria.bind(this) }, { text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" }, 'Search'];
        this.toolbarOptionsPerito = [{ template: this.ddlFiltroSinistriToolbar.bind(this) }, { text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" }, { template: this.creaProformaToolbar.bind(this) }, 'Search'];
        this.toolbarOptionsImportGenerali = ['Search', 'ExcelExport'];
        this.toolbarOptionsImportUnipolSAI = ['Search', 'ExcelExport'];
        this.toolbarOptionsImportZurich = ['Search', 'ExcelExport'];
        this.toolbarOptionsImportAssimoco = ['Search', 'ExcelExport'];
        this.toolbarOptionsImportAllianzViva = ['Search', 'ExcelExport'];
        this.RTEitems = ['Bold', 'Italic', 'Underline',
            'FontName', 'FontSize',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'CreateLink', 'Print', '|', 'Undo', 'Redo'
        ];
        this.RTEtoolbarSettings = {
            items: this.RTEitems
        };
        this.childKey = 0;
        this.editSettings = { allowAdding: true, allowEditing: true, mode: 'Normal' };
        this.editSettingsPerito = { allowEditing: true, mode: 'Normal' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.tabHeaderText = [{ text: "SITUAZIONE SINISTRI" }];
        this.dataSourceFiltroPerito = [{ TEXT: 'In Carico', VALUE: 'carico' }, { TEXT: 'Da Fatturare', VALUE: 'fatturare' }, { TEXT: 'Fatturati', VALUE: 'fatturati' }, { TEXT: 'Tutti', VALUE: 'tutti' }];
        this.dsFiltroSinistri = [{ TEXT: 'Da Assegnare', VALUE: 'assegnare' }, { TEXT: 'Assegnati (negli ultimi 12 mesi)', VALUE: 'assegnati' }, { TEXT: 'Da Chiudere', VALUE: 'chiudere' }, { TEXT: 'Chiusi (negli ultimi 6 mesi)', VALUE: 'chiusi' }, { TEXT: 'Attesa Documentazione', VALUE: 'attesaDoc' }, { TEXT: 'Sospesi', VALUE: 'sospesi' }];
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.commands = [{ type: "Modifica", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
        { type: "Vedi Notifiche Sinistro", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" } }];
        this.commands2 = [{ type: "Importa Sinistro", buttonOption: { iconCss: "fas fa-download fa-10x", cssClass: "e-details" } }];
        this.commands3 = [{ type: "Modifica", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                          { type: "Chiudi Sinistro", buttonOption: { iconCss: "fas fa-times fa-10x", cssClass: "e-details" } },
                          { type: "Vedi Notifiche Sinistro", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" } },
                          { type: "Restituisci a Perito", buttonOption: { iconCss: "fas fa-undo fa-10x", cssClass: "e-details" } },
                          { type: "Scarica Documento Compagnia", buttonOption: { iconCss: "fas fa-file-pdf fa-10x", cssClass: "e-details" } },
                          { type: "Invia Accettazione Sinistro", buttonOption: { iconCss: "fas fa-paper-plane fa-10x", cssClass: "e-details" } },
                          { type: "Invia Nuovo Importo Indennizzabile", buttonOption: { iconCss: "fas fa-euro-sign fa-10x", cssClass: "e-details" } }];
        this.filterSettings = { type: 'Excel' };
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        this.pageSettingsImportGenerali = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        this.pageSettingsImportUnipolSAI = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        this.qualeDialog = '';

        this.btnItems = [
            // {
            //     text: 'UNIPOLSAI (Email)',
            //     id: 'ImportaUnipolSAIEmail'
            // },
            {
                text: 'UNIPOLSAI',
                id: 'ImportaUnipolSAIAPI'
            },
            {
                text: 'GENERALI',
                id: 'ImportaGenerali'
            },
            // {
            //     text: 'ZURICH',
            //     id: 'ImportaZurich'
            // },
            {
                text: 'ALLIANZ',
                id: 'ImportaAllianz'
            },
            {
                text: 'ALLIANZVIVA',
                id: 'ImportaAllianzViva'
            },
            {
                text: 'ASSIMOCO',
                id: 'ImportaAssimoco'
            }
        ];
        this.dsCompagnie = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_compagnie'))?.map(elm => { return { VALUE: elm.id_compagnia.toString(), TEXT: elm.compagnia } });
        this.dsAree = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_aree'))?.map(elm => { return { VALUE: elm.id_area.toString(), TEXT: elm.area } });
        this.dsAgenzie = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_agenzie'))?.map(elm => { return { VALUE: elm.id_agenzia.toString(), TEXT: elm.agenzia } });
        this.dsBroker = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_broker'))?.map(elm => { return { VALUE: elm.id_broker.toString(), TEXT: elm.broker } });
        this.dsDivisioni = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_divisioni'))?.map(elm => { return { VALUE: elm.id_divisione.toString(), TEXT: elm.divisione } });
        this.dsUtentiFiltrato = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_periti'))?.map(elm => { return { VALUE: elm.id_user.toString(), TEXT: elm.denom, codFis: elm.codice_fiscale, email: elm.email, telefono: elm.telefono, codFis: elm.codice_fiscale } });
        this.dsReferenti = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_referenti'))?.map(elm => { return { VALUE: elm.id_user.toString(), TEXT: elm.denom } });
        this.dsTipoPolizza = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_tipo_polizza'))?.map(elm => { return { VALUE: elm.id_tipo_polizza.toString(), TEXT: elm.tipo_polizza } });
        this.dsSedi = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_sedi'))?.map(elm => { return { VALUE: elm.idparam.toString(), TEXT: elm.valore } });
        this.dsStati = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_stati'))?.map(elm => { return { VALUE: elm.COD_ELE.toString(), TEXT: elm.DESCRIZ, STATO: elm.SERVIZIO_STR1 } });
        this.dsComuni = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabellaCodici_comuni')).filter(elm => { return elm.SERVIZIO_STR1 !== '' })?.map(elm => { return { VALUE: elm.COD_ELE.toString(), TEXT: elm.DESCRIZ } });
    }
    state = {
        blocking: false,
        showDialog1: false,
        NumSinistro: '',
        showLightbox: false,

    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toggleBlocking2(show) {
        let container, root;
        if (show) {
            document.getElementById('blockUIGenerico').style.removeProperty('display');
            document.getElementById('spinnerUIGenerico').style.removeProperty('display');
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
        }
        else {
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.unmount();
            document.getElementById('blockUIGenerico').style.display = 'none';
            document.getElementById('spinnerUIGenerico').style.display = 'none';
        }
    }
    actionComplete(args) {
        // if (args.requestType === 'searching') {
        //     if (args.searchString === '') {
        //         this.caricaDati();
        //         if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 1) {
        //             setTimeout(() => {
        //                 document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].enabled = true;
        //             }, 200)
        //         }
        //     }
        //     this.toggleBlocking();
        //     let QueryExec = '';
        //     if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 9) {
        //         QueryExec = `SELECT T1.id_sinistro, T1.id_compagnia, T1.sin_num, T1.pol_num, T1.luo_ind, T1.luo_cit, T1.luo_pro, T1.luo_cap, T1.data_inc_per, T1.data_chi, T2.compagnia, T3.contraente, T4.tipo_sinistro, T5.DESCRIZ FROM acsinistri T1 LEFT OUTER JOIN _compagnie T2 ON T2.id_compagnia = T1.id_compagnia LEFT OUTER JOIN accontraenti T3 ON T1.id_contraente=T3.id_contraente LEFT OUTER JOIN _tipo_sinistro T4 ON T1.id_tipo_sinistro = T4.id_tipo_sinistro LEFT OUTER JOIN ELEMENTITBSTD T5 ON T5.COD_TAB = 'stati_sinistro' AND T1.id_stato = T5.COD_ELE WHERE (T1.id_sinistro LIKE '%${args.searchString}%' OR T1.sin_num LIKE '%${args.searchString}%' OR T1.pol_num LIKE '%${args.searchString}%' OR T1.luo_ind LIKE '%${args.searchString}%' OR T1.luo_cit LIKE '%${args.searchString}%' OR T1.luo_pro LIKE '%${args.searchString}%' OR T1.luo_cap LIKE '%${args.searchString}%' OR T2.compagnia LIKE '%${args.searchString}%' OR T3.contraente LIKE '%${args.searchString}%' OR T4.tipo_sinistro LIKE '%${args.searchString}%') AND T1.id_perito = '${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user}' ORDER BY id_sinistro DESC;`;
        //         CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
        //             if (JSON.parse(retData.data) === "[]")
        //                 document.getElementById('GridSituazioneSinistriPerito').ej2_instances[0].dataSource = [];
        //             else
        //                 document.getElementById('GridSituazioneSinistriPerito').ej2_instances[0].dataSource = JSON.parse(retData.data);
        //             this.toggleBlocking();
        //         }, messaggio => {
        //             toast.warn(messaggio, {
        //                 containerId: 'toastContainer1',
        //                 onClose: () => toast.dismiss(),
        //                 position: "bottom-right",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: false,
        //                 progress: undefined,
        //                 newestOnTop: true,
        //                 rtl: false,
        //                 pauseOnFocusLoss: true
        //             }
        //             );
        //         });
        //     }
        //     else {
        //         document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].enabled = false;
        //         QueryExec = `SELECT T1.id_sinistro, T1.id_compagnia, T1.sin_num, T1.pol_num, T1.luo_ind, T1.luo_cit, T1.luo_pro, T1.luo_cap, T1.data_inc_per, T1.data_chi, T2.compagnia, T3.contraente, T4.tipo_sinistro, T5.DESCRIZ, T6.agenzia, T7.denom, T8.denom AS denom_referente FROM acsinistri T1 LEFT OUTER JOIN _compagnie T2 ON T2.id_compagnia = T1.id_compagnia LEFT OUTER JOIN accontraenti T3 ON T1.id_contraente=T3.id_contraente LEFT OUTER JOIN _tipo_sinistro T4 ON T1.id_tipo_sinistro = T4.id_tipo_sinistro LEFT OUTER JOIN ELEMENTITBSTD T5 ON T5.COD_TAB = 'stati_sinistro' AND T1.id_stato = T5.COD_ELE LEFT OUTER JOIN _agenzie T6 ON T6.id_agenzia = T1.id_agenzia LEFT OUTER JOIN _user T7 ON T7.id_user = T1.id_perito LEFT OUTER JOIN _user T8 ON T8.id_user = T1.referente WHERE T1.id_sinistro = '%${args.searchString}%' OR T1.sin_num LIKE '%${args.searchString}%' OR T1.pol_num LIKE '%${args.searchString}%' OR T1.luo_ind LIKE '%${args.searchString}%' OR T1.luo_cit LIKE '%${args.searchString}%' OR T1.luo_pro LIKE '%${args.searchString}%' OR T1.luo_cap LIKE '%${args.searchString}%' OR T2.compagnia LIKE '%${args.searchString}%' OR T3.contraente LIKE '%${args.searchString}%' OR T4.tipo_sinistro LIKE '%${args.searchString}%' OR T6.agenzia LIKE '%${args.searchString}%' OR T7.denom LIKE '%${args.searchString}%' ORDER BY T1.id_sinistro DESC;`;
        //         CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
        //             if (JSON.parse(retData.data) === "[]")
        //                 document.getElementById('GridSituazioneSinistri').ej2_instances[0].dataSource = [];
        //             else
        //                 document.getElementById('GridSituazioneSinistri').ej2_instances[0].dataSource = JSON.parse(retData.data);
        //             this.toggleBlocking();
        //         }, messaggio => {
        //             toast.warn(messaggio, {
        //                 containerId: 'toastContainer1',
        //                 onClose: () => toast.dismiss(),
        //                 position: "bottom-right",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: false,
        //                 progress: undefined,
        //                 newestOnTop: true,
        //                 rtl: false,
        //                 pauseOnFocusLoss: true
        //             }
        //             );
        //         });
        //     }
        // }
    }
    ddlFiltroSinistriToolbar() {
        return (
            <div id='ddlFiltriContent'><DropDownListComponent id='ddlFiltroSinistri' fields={this.ddlFields} ref={ddlObj => this.ddlInstance2 = ddlObj} dataSource={this.dataSourceFiltroPerito} value={sessionStorage.getItem('JellyfishSIPAEVOFiltroComboPerito')} allowFiltering={false} filterType='Contains'
                style={{ width: '14vw' }} placeholder="Seleziona i sinistri da visualizzare" floatLabelType='always' popupHeight="250px" cssClass='e-outline' change={this.onChangeFiltroSinistri.bind(this)} /></div>
        );
    }
    ddlFiltroSinistriToolbarSegreteria() {
        return (
            <DropDownListComponent id='ddlFiltroSinistriSegreteria' fields={this.ddlFields} ref={ddlObj => this.ddlInstance1 = ddlObj} dataSource={this.dsFiltroSinistri} change={this.onChangeFiltroSinistriSegreteria.bind(this)} allowFiltering={false} filterType='Contains'
                style={{ width: '14vw' }} placeholder="Selezionare i sinistri da visualizzare" floatLabelType='always' popupHeight="250px" cssClass='e-outline' value={sessionStorage.getItem('JellyfishSIPAEVOFiltroComboSegreteria')} />
        );
    }
    creaProformaToolbar() {
        return (
            <ButtonComponent id='creaProforma' alt='proforma' cssClass='e-fill e-info' style={{ marginLeft: '30px', display: 'none' }}>Crea proforma</ButtonComponent>
        );
    }
    onChangeFiltroSinistri(args) {
        sessionStorage.setItem("JellyfishSIPAEVOFiltroComboPerito", args.value);
        setTimeout(() => {
            this.caricaDati();
        }, 200)
    }
    onChangeFiltroSinistriSegreteria(args) {
        if (!args.isInteracted) return;
        //SOLO IN PRODUZIONE
        // if (args.value === 'assegnati' || args.value === 'chiusi') {
        //     alert('Funzionalità non abilitata perché in fase di revisione!!');
        //     return;
        // }
        sessionStorage.setItem("JellyfishSIPAEVOFiltroComboSegreteria", args.value);
        setTimeout(() => {
            this.caricaDati();
        }, 200)
    }
    distruggiDialog() {
        if (document.getElementById('defaultDialogSitSin'))
            document.getElementById('defaultDialogSitSin').ej2_instances[0]?.destroy();
        if (document.getElementById('defaultDialogSitSin2'))
            document.getElementById('defaultDialogSitSin2').ej2_instances[0]?.destroy();
        if (document.getElementById('defaultDialogSitSin3'))
            document.getElementById('defaultDialogSitSin3').ej2_instances[0]?.destroy();
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
        if (args.item.id === 'GridSituazioneSinistri_add') {
            args.cancel = true;
            this.toggleBlocking();
            setTimeout(() => {
                ++this.childKey;
                const container = document.getElementById('contenutoDinamico2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.toggleBlocking();
                    this.distruggiDialog()
                }}><PaginaDettaglioSinistro key={this.childKey} IdSinistro={this.gridInstance.getSelectedRecords()[0].id_sinistro} IdCompagnia={this.gridInstance.getSelectedRecords()[0].id_compagnia}></PaginaDettaglioSinistro></CallbackWrapper>);
            }, 50);
        }
        if (args.item.id === 'GridSituazioneSinistri_edit') {
            args.cancel = true;
            this.toggleBlocking();
            setTimeout(() => {
                ++this.childKey;
                const container = document.getElementById('contenutoDinamico2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.toggleBlocking();
                    this.distruggiDialog()
                }}><PaginaDettaglioSinistro key={this.childKey} IdSinistro={this.gridInstance.getSelectedRecords()[0].id_sinistro} IdCompagnia={this.gridInstance.getSelectedRecords()[0].id_compagnia}></PaginaDettaglioSinistro></CallbackWrapper>);
            }, 50);
        }
        if (args.item.id === 'aggiornatabella') {
            this.caricaDati();
        }
    }
    toolbarClickImportGenerali(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstanceImportGenerali.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstanceImportGenerali.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstanceImportGenerali.csvExport();
                break;
        }
    }
    toolbarClickImportUnipolSAI1(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstanceImportUnipolSAI1.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstanceImportUnipolSAI1.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstanceImportUnipolSAI1.csvExport();
                break;
        }
    }
    toolbarClickImportUnipolSAI2(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstanceImportUnipolSAI2.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstanceImportUnipolSAI2.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstanceImportUnipolSAI2.csvExport();
                break;
        }
    }
    toolbarClickImportZurich(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstanceImportZurich.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstanceImportZurich.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstanceImportZurich.csvExport();
                break;
        }
    }
    toolbarClickImportAssimoco(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstanceImportAssimoco.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstanceImportAssimoco.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstanceImportAssimoco.csvExport();
                break;
        }
    }
    toolbarClickImportAllianzViva(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstanceImportAllianzViva.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstanceImportAllianzViva.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstanceImportAllianzViva.csvExport();
                break;
        }
    }
    onQueryCellInfoImportUnipolSAI1(args) {
        if (args.column.field === "NumeroSinistro")
            args.cell.innerHTML = `<div><span style="font-size: 0.85vw; color: purple; font-weight: bold;">${args.cell.innerHTML}</span></div>`;
    }
    onQueryCellInfoImportZurich(args) {
        if (args.column.field === "NumeroSinistro")
            args.cell.innerHTML = `<div><span style="font-size: 0.85vw; color: purple; font-weight: bold;">${args.cell.innerHTML}</span></div>`;
    }
    onQueryCellInfoImportAssimoco(args) {
        if (args.column.field === "NumeroSinistro")
            args.cell.innerHTML = `<div><span style="font-size: 0.85vw; color: purple; font-weight: bold;">${args.cell.innerHTML}</span></div>`;
    }
    onQueryCellInfoImportAllianzViva(args) {
        if (args.column.field === "NumeroSinistro")
            args.cell.innerHTML = `<div><span style="font-size: 0.85vw; color: purple; font-weight: bold;">${args.cell.innerHTML}</span></div>`;
    }
    onDataBoundSegreteria(args) {
        if (flag === true) {
            flag = false;
        }
        else {
            if (document.getElementById(`ddlFiltroSinistriSegreteria`).ej2_instances[0].value === 'assegnare') {
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[8].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[10].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[11].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[12].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[13].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[14].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[15].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[16].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[17].visible = false;
                this.gridInstance.refreshColumns();
                flag = true;
            }
            if (document.getElementById(`ddlFiltroSinistriSegreteria`).ej2_instances[0].value === 'chiudere') {
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[8].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[10].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[11].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[12].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[13].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[14].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[15].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[16].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[17].visible = true;
                this.gridInstance.refreshColumns();
                flag = true;
            }
            if (document.getElementById(`ddlFiltroSinistriSegreteria`).ej2_instances[0].value === 'attesaDoc') {
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[8].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[10].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[11].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[12].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[13].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[14].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[15].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[16].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[17].visible = false;
                this.gridInstance.refreshColumns();
                flag = true;
            }
            if (document.getElementById(`ddlFiltroSinistriSegreteria`).ej2_instances[0].value === 'sospesi') {
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[8].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[10].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[11].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[12].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[13].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[14].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[15].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[16].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[17].visible = false;
                this.gridInstance.refreshColumns();
                flag = true;
            }
            if (document.getElementById(`ddlFiltroSinistriSegreteria`).ej2_instances[0].value === 'chiusi') {
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[8].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[10].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[11].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[12].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[13].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[14].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[15].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[16].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[17].visible = true;
                this.gridInstance.refreshColumns();
                flag = true;
            }
            if (document.getElementById(`ddlFiltroSinistriSegreteria`).ej2_instances[0].value === 'assegnati') {
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[8].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[10].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[11].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[12].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[13].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[14].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[15].visible = false;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[16].visible = true;
                document.getElementById(`GridSituazioneSinistri`).ej2_instances[0].columns[17].visible = false;
                this.gridInstance.refreshColumns();
                flag = true;
            }
        }
    }
    onDataBoundPerito(args) {
        setTimeout(() => {
            if (flag === true) {
                flag = false;
            }
            else {
                if (document.getElementById(`ddlFiltroSinistri`).ej2_instances[0].value === 'carico') {
                    document.getElementById(`creaProforma`).style.display = 'none';
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[5].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[6].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[7].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[8].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[9].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[10].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[11].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[12].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[13].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[14].visible = true;
                    this.gridInstancePerito.refreshColumns();
                    flag = true;
                }
                if (document.getElementById(`ddlFiltroSinistri`).ej2_instances[0].value === 'fatturare') {
                    document.getElementById(`creaProforma`).style.removeProperty('display');
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[5].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[6].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[7].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[8].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[9].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[10].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[11].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[12].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[13].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[14].visible = true;
                    this.gridInstancePerito.refreshColumns();
                    flag = true;
                }
                if (document.getElementById(`ddlFiltroSinistri`).ej2_instances[0].value === 'fatturati') {
                    document.getElementById(`creaProforma`).style.display = 'none';
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[5].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[6].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[7].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[8].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[9].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[10].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[11].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[12].visible = false;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[13].visible = true;
                    document.getElementById(`GridSituazioneSinistriPerito`).ej2_instances[0].columns[14].visible = true;
                    this.gridInstancePerito.refreshColumns();
                    flag = true;
                }
            }
        }, 50);
    }
    renderImportaGenerali() {
        return (<React.Fragment>
            <div className="container-fluid">
                <div className='row' style={{ marginTop: '50px' }}>
                    <div id='divDateRange' className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{ marginTop: '-30px', marginLeft: '40px' }}>
                        <DateRangePickerComponent style={{ marginLeft: '5%' }} id="dateRangeIncarichi" ref={drObj => this.drInstance1 = drObj} placeholder='Data Sincronizzazone Da-A' floatLabelType='Always' />
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>
                        <ButtonComponent id='sincronizzaIncarichiGenerali' style={{ marginTop: '-60px' }} cssClass='e-fill e-info' onClick={this.sincronizzaIncarichiGenerali.bind(this)}>Sincronizza Incarichi</ButtonComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <GridComponent id="GridImportGenerali" rowDataBound={this.rowDataBound.bind(this)} ref={grid => this.gridInstanceImportGenerali = grid} toolbar={this.toolbarOptionsImportGenerali} hierarchyPrintMode='All' commandClick={this.onCommandClick2.bind(this)}
                            allowSelection={true} enableHover={false} sortSettings={{ columns: [{ field: 'status', direction: 'Ascending' }, { field: 'id_sinistro', direction: 'Descending' }] }} allowTextWrap={true} toolbarClick={this.toolbarClickImportGenerali.bind(this)} allowPaging={true} allowSorting={true} allowFiltering={true}
                            enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettingsImportGenerali} allowGrouping={false} allowExcelExport={true} filterSettings={this.filterSettings} >
                            <ColumnsDirective>
                                <ColumnDirective field='id_incarico' validationRules={{ required: true }} headerText='ID Generali' width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='numero_sinistro' headerText="Num. Sinistro" width='17%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='nome_contraente' headerText="Contraente " width='33%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='data_incarico' headerText="Data Incarico" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='id_sinistro' headerText="ID Sinistro" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='status' headerText="Status" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='command' commands={this.commands2} headerText="" width='5%' headerTemplate={headerTemplate} textAlign='Center' allowFiltering={false} allowSorting={false} ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                        </GridComponent>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    renderImportaUnipolSAI() {
        return (<React.Fragment>
            <div className="container-fluid">
                <div className='row' style={{ paddingTop: '50px' }}>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>
                        <ButtonComponent id='sincronizzaIncarichiUnipolSAI' cssClass='e-fill e-info' onClick={this.sincronizzaIncarichiUnipolSAI.bind(this)}>Sincronizza Incarichi</ButtonComponent>
                    </div>
                    <div className='col-lg-2 offset-lg-7 col-md-2 offset-md-7 col-sm-2 offset-sm-7 col-xs-2 offset-xs-7'>
                        <ButtonComponent id='importaIncarichiSelezionatiUnipolSAI' cssClass='e-fill e-info' onClick={this.importaIncarichiSelezionatiUnipolSAI.bind(this)}>Importa Incarichi Selezionati</ButtonComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <GridComponent id="GridImportUnipolSAI2" rowDataBound={this.rowDataBound.bind(this)} ref={grid => this.gridInstanceImportUnipolSAI2 = grid} toolbar={this.toolbarOptionsImportUnipolSAI} hierarchyPrintMode='All' commandClick={this.onCommandClick4.bind(this)}
                            allowSelection={true} enableHover={false} sortSettings={{ columns: [{ field: 'status', direction: 'Ascending' }, { field: 'id_sinistro', direction: 'Descending' }] }} allowTextWrap={true} toolbarClick={this.toolbarClickImportUnipolSAI2.bind(this)} allowPaging={true} allowSorting={true} allowFiltering={true}
                            enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettingsImportUnipolSAI} allowGrouping={false} allowExcelExport={true} filterSettings={this.filterSettings} 
                            selectionSettings={{ type: 'Multiple', checkboxMode: 'ResetOnRowClick', mode: 'Row', enableToggle: true, checkboxOnly: true }}>
                            <ColumnsDirective>
                                <ColumnDirective type='checkbox' allowSorting={false} allowFiltering={false} width='60'></ColumnDirective>
                                <ColumnDirective field='id_incarico' validationRules={{ required: true }} headerText='ID UnipolSAI' width='13%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='numero_sinistro' headerText="Num. Sinistro" width='17%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='nome_contraente' headerText="Contraente " width='33%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='data_incarico' headerText="Data Incarico" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='id_sinistro' headerText="ID Sinistro" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='status' headerText="Status" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='command' commands={this.commands2} headerText="" width='50' headerTemplate={headerTemplate} textAlign='Center' allowFiltering={false} allowSorting={false} ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                        </GridComponent>
                    </div>
                </div>
            </div>
        </React.Fragment >);
    }
    renderReportImportazioneUNIPOLSAI() {
        return (<React.Fragment>
            <div style={{ textAlign: 'center' }}>
                <h3>Elenco Sinistri Importati da UNIPOLSAI</h3>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <GridComponent id="GridImportUnipolSAI1" ref={grid => this.gridInstanceImportUnipolSAI1 = grid} toolbar={this.toolbarOptionsImportUnipolSAI} toolbarClick={this.toolbarClickImportUnipolSAI1.bind(this)}
                        allowSelection={false} enableHover={false} showColumnMenu={false} gridLines='Both' allowExcelExport={true} height='570px'
                        allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} queryCellInfo={this.onQueryCellInfoImportUnipolSAI1.bind(this)}
                        pageSettings={{ pageSize: 1000, pageCount: 1, pageSizes: [] }}>
                        <ColumnsDirective>
                            <ColumnDirective field='NumeroSinistro' headerText='Numero Sinistro Compagnia' headerTemplate={headerTemplate} disableHtmlEncode={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='NominativoContraente' headerText="Nominativo Contraente" headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='DescrizioneSinistro' headerText="Descrizione Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='Esito' headerText="Esito Importazione" headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate1} clipMode="EllipsisWithTooltip"></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page]} />
                    </GridComponent>
                </div>
            </div>
        </React.Fragment>);
    }
    renderReportImportazioneZURICH() {
        return (<React.Fragment>
            <div style={{ textAlign: 'center' }}>
                <h3>Elenco Sinistri Importati da ZURICH</h3>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <GridComponent id="GridImportZurich" ref={grid => this.gridInstanceImportZurich = grid} toolbar={this.toolbarOptionsImportZurich} toolbarClick={this.toolbarClickImportZurich.bind(this)}
                        allowSelection={false} enableHover={false} showColumnMenu={false} gridLines='Both' allowExcelExport={true} height='570px'
                        allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} queryCellInfo={this.onQueryCellInfoImportZurich.bind(this)}
                        pageSettings={{ pageSize: 1000, pageCount: 1, pageSizes: [] }}>
                        <ColumnsDirective>
                            <ColumnDirective field='NumeroSinistro' headerText='Numero Sinistro Compagnia' headerTemplate={headerTemplate} disableHtmlEncode={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='NominativoContraente' headerText="Nominativo Contraente" headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='DescrizioneSinistro' headerText="Descrizione Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='Esito' headerText="Esito Importazione" headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate1} clipMode="EllipsisWithTooltip"></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page]} />
                    </GridComponent>
                </div>
            </div>
        </React.Fragment>);
    }
    renderReportImportazioneASSIMOCO() {
        return (<React.Fragment>
            <div style={{ textAlign: 'center' }}>
                <h3>Elenco Sinistri Importati da ASSIMOCO</h3>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <GridComponent id="GridImportAssimoco" ref={grid => this.gridInstanceImportAssimoco = grid} toolbar={this.toolbarOptionsImportAssimoco} toolbarClick={this.toolbarClickImportAssimoco.bind(this)}
                        allowSelection={false} enableHover={false} showColumnMenu={false} gridLines='Both' allowExcelExport={true} height='570px'
                        allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} queryCellInfo={this.onQueryCellInfoImportAssimoco.bind(this)}
                        pageSettings={{ pageSize: 1000, pageCount: 1, pageSizes: [] }}>
                        <ColumnsDirective>
                            <ColumnDirective field='NumeroSinistro' headerText='Numero Sinistro Compagnia' headerTemplate={headerTemplate} disableHtmlEncode={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='NominativoContraente' headerText="Nominativo Contraente" headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='DescrizioneSinistro' headerText="Descrizione Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='Esito' headerText="Esito Importazione" headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate1} clipMode="EllipsisWithTooltip"></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page]} />
                    </GridComponent>
                </div>
            </div>
        </React.Fragment>);
    }
    renderReportImportazioneALLIANZVIVA() {
        return (<React.Fragment>
            <div style={{ textAlign: 'center' }}>
                <h3>Elenco Sinistri Importati da ALLIANZ/ALLIANZVIVA</h3>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <GridComponent id="GridImportAllianzViva" ref={grid => this.gridInstanceImportAllianzViva = grid} toolbar={this.toolbarOptionsImportAllianzViva} toolbarClick={this.toolbarClickImportAllianzViva.bind(this)}
                        allowSelection={false} enableHover={false} showColumnMenu={false} gridLines='Both' allowExcelExport={true} height='570px'
                        allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} queryCellInfo={this.onQueryCellInfoImportAllianzViva.bind(this)}
                        pageSettings={{ pageSize: 1000, pageCount: 1, pageSizes: [] }}>
                        <ColumnsDirective>
                            <ColumnDirective field='NumeroSinistro' headerText='Numero Sinistro Compagnia' headerTemplate={headerTemplate} disableHtmlEncode={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='NominativoContraente' headerText="Nominativo Contraente" headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='DescrizioneSinistro' headerText="Note Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='Esito' headerText="Esito Importazione" headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate1} clipMode="EllipsisWithTooltip"></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page]} />
                    </GridComponent>
                </div>
            </div>
        </React.Fragment>);
    }
    onSelectSplitBtn(args) {
        if (args.item.id === 'ImportaGenerali') {
            this.qualeDialog = 'ImportaGenerali';
            this.dialogInstance1.header = "<div style='text-align: center;'><span style='font-weight: bold; font-size: 28px; color: #133B95'><span style='font-weight: bold; font-size: 28px; color: #133B95'>Importazione Sinistri GENERALI</span></div>";
            this.dialogInstance1.height = '95%';
            this.dialogInstance1.minHeight = '95%';
            this.dialogInstance1.width = '65%';
            this.dialogInstance1.buttons = [];
            this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.toggleBlocking2(true);
                    const QueryExec = 'SELECT * FROM GENERALI_ListaIncarichi';
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                        retData = JSON.parse(retData.data);
                        if (retData.length > 0) {
                            retData.forEach(elm => {
                                elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                if (elm.id_sinistro === 0)
                                    elm.status = 'Da importare'
                                else
                                    elm.status = 'Importato'
                            });
                            this.gridInstanceImportGenerali.dataSource = retData;
                        }
                        else
                            this.gridInstanceImportGenerali.dataSource = null;
                        let startDate = new Date();
                        startDate.setDate(startDate.getDate() - 1);
                        this.drInstance1.startDate = startDate;
                        this.drInstance1.endDate = new Date();
                        this.toggleBlocking2(false);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    });
                }}>{this.renderImportaGenerali()}</CallbackWrapper>);
            };
            this.dialogInstance1.close = (args) => {
                this.ddlInstance1.value = 'assegnare';
                setTimeout(() => {
                    document.getElementById('aggiornatabella').click();
                }, 100);
            };
            this.dialogInstance1.show();
        }
        else if (args.item.id === 'ImportaUnipolSAIEmail') {
            //FUNZIONALITA' DISMESSA A CAUSA DI SOSTITUZIONE CON IMPORTAZIONE TRAMITE API
            return;
            // this.dialogInstance1.header = "<div style='text-align: center;'><span style='font-weight: bold; font-size: 22px; color: #133B95'>Procedura importazione automatica sinistri UNIPOLSAI</span></div>";
            // this.dialogInstance1.width = '40%';
            // this.dialogInstance1.height = '33%';
            // this.dialogInstance1.minHeight = '33%';
            // this.dialogInstance1.close = undefined;
            // this.dialogInstance1.beforeOpen = () => {
            //     const container = document.getElementById('defaultDialogSitSinContent');
            //     const root = createRoot(container);
            //     root.unmount();
            // };
            // this.dialogInstance1.open = (args) => {
            //     const container = document.getElementById('defaultDialogSitSinContent');
            //     const root = createRoot(container);
            //     root.render(<React.Fragment>
            //         <div className='boxSai' style={{ textAlign: 'center' }}>
            //             <h6>
            //                 La procedura importa automaticamente i sinistri prendendoli dalle email ricevute da <b>UNIPOLSAI</b>. Le email considerate sono quelle nello stato <b>DA LEGGERE</b> ed il file allegato che viene considerato per l'importazione è quello denominato <b>INCARICO.txt</b>. Durante l'importazione verrà effettuato un controllo sul <b>Numero Sinistro Compagnia</b> per evitare che un sinistro già inserito possa essere replicato. Alla fine della procedura verrà presentato un resoconto dei sinistri importati. Confermare l'operazione?
            //             </h6>
            //         </div>
            //     </React.Fragment>);
            // };
            // this.dialogInstance1.buttons = [
            //     {
            //         click: () => {
            //             this.dialogInstance1.hide();
            //             this.toggleBlocking();
            //             const Utente = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;
            //             CustomJS.callAPI(`ChiamateAPISIPAEVO/LeggiEmailCreaSinistri/UNIPOLSAI/[NO]/[NO]/${Utente}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
            //                 let retData2 = CustomJS.isNullEmpty(retData.data) ? null : JSON.parse(retData.data);
            //                 if (retData.response === 'OK') {
            //                     this.toggleBlocking();
            //                     if (retData2.length === 0) {
            //                         toast.warn(`Nessun sinistro da elaborare. Controllare il contenuto della casella email di riferimento ed eventualmente riprovare!!`, {
            //                             containerId: 'toastContainer1',
            //                             onClose: () => toast.dismiss(),
            //                             position: "bottom-right",
            //                             autoClose: 10000,
            //                             hideProgressBar: false,
            //                             closeOnClick: true,
            //                             pauseOnHover: true,
            //                             draggable: false,
            //                             progress: undefined,
            //                             newestOnTop: true,
            //                             rtl: false,
            //                             pauseOnFocusLoss: true
            //                         });
            //                         return;
            //                     }
            //                     else {
            //                         this.dialogInstance1.header = '';
            //                         this.dialogInstance1.width = '65%';
            //                         this.dialogInstance1.height = '90%';
            //                         this.dialogInstance1.minHeight = '90%';
            //                         this.dialogInstance1.buttons = [];
            //                         this.dialogInstance1.beforeOpen = (args) => {
            //                             const container = document.getElementById('defaultDialogSitSinContent');
            //                             const root = createRoot(container);
            //                             root.unmount();
            //                         };
            //                         this.dialogInstance1.open = (args) => {
            //                             document.getElementById('aggiornatabella').click();
            //                             setTimeout(() => {
            //                                 const container = document.getElementById('defaultDialogSitSinContent');
            //                                 const root = createRoot(container);
            //                                 root.render(<CallbackWrapper callback={() => {
            //                                     this.gridInstanceImportUnipolSAI1.dataSource = retData2;
            //                                 }}>{this.renderReportImportazioneUNIPOLSAI()}</CallbackWrapper>);
            //                             }, 150);
            //                         };
            //                         this.dialogInstance1.show();
            //                     }
            //                 }
            //                 else if (retData.response === 'KO') {
            //                     toast.error(retData.message, {
            //                         containerId: 'toastContainer1',
            //                         onClose: () => toast.dismiss(),
            //                         position: "bottom-right",
            //                         autoClose: 5000,
            //                         hideProgressBar: false,
            //                         closeOnClick: true,
            //                         pauseOnHover: true,
            //                         draggable: false,
            //                         progress: undefined,
            //                         newestOnTop: true,
            //                         rtl: false,
            //                         pauseOnFocusLoss: true
            //                     });
            //                     this.toggleBlocking();
            //                 }
            //             }, error => {
            //                 let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            //                 toast.warn(messaggio, {
            //                     containerId: 'toastContainer1',
            //                     onClose: () => toast.dismiss(),
            //                     position: "bottom-right",
            //                     autoClose: 15000,
            //                     hideProgressBar: false,
            //                     closeOnClick: true,
            //                     pauseOnHover: true,
            //                     draggable: false,
            //                     progress: undefined,
            //                     newestOnTop: true,
            //                     rtl: false,
            //                     pauseOnFocusLoss: true
            //                 });
            //                 this.toggleBlocking();
            //             }, true, false);
            //         },
            //         buttonModel: {
            //             content: 'CONFERMA',
            //             cssClass: 'e-primary',
            //             isPrimary: true
            //         }
            //     },
            //     {
            //         click: () => {
            //             this.dialogInstance1.hide();
            //         },
            //         buttonModel: {
            //             content: 'ANNULLA',
            //         }
            //     }
            // ];
            // this.dialogInstance1.show();
        }
        else if (args.item.id === 'ImportaUnipolSAIAPI') {
            this.qualeDialog = 'ImportaUnipolSAI';
            this.dialogInstance1.header = "<div style='text-align: center;'><span style='font-weight: bold; font-size: 28px; color: #133B95'>Importazione Sinistri UNIPOLSAI</span></div>";
            this.dialogInstance1.height = '95%';
            this.dialogInstance1.minHeight = '95%';
            this.dialogInstance1.width = '65%';
            this.dialogInstance1.buttons = [];
            this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.unmount();
            }
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.toggleBlocking2(true);
                    const QueryExec = 'SELECT * FROM UNIPOLSAI_ListaIncarichi';
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                        retData = JSON.parse(retData.data);
                        if (retData.length > 0) {
                            retData.forEach(elm => {
                                elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                if (elm.id_sinistro === 0)
                                    elm.status = 'Da importare'
                                else
                                    elm.status = 'Importato'
                            });
                            this.gridInstanceImportUnipolSAI2.dataSource = retData;
                        }
                        else
                            this.gridInstanceImportUnipolSAI2.dataSource = null;
                        this.toggleBlocking2(false);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking2(false);
                    });
                }}>{this.renderImportaUnipolSAI()}</CallbackWrapper>);
            };
            this.dialogInstance1.close = (args) => {
                this.ddlInstance1.value = 'assegnare';
                setTimeout(() => {
                    document.getElementById('aggiornatabella').click();
                }, 100);
            };
            this.dialogInstance1.show();
        }
        else if (args.item.id === 'ImportaZurich') {
            return;

            this.dialogInstance1.header = "<div style='text-align: center;'><span style='font-weight: bold; font-size: 22px; color: #133B95'>Procedura importazione automatica sinistri ZURICH</span></div>";
            this.dialogInstance1.width = '40%';
            this.dialogInstance1.height = '33%';
            this.dialogInstance1.minHeight = '33%';
            this.dialogInstance1.close = undefined;
            this.dialogInstance1.beforeOpen = () => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.render(<React.Fragment>
                    <div className='boxSai' style={{ textAlign: 'center' }}>
                        <h6>
                            La procedura importa automaticamente i sinistri prendendoli dalle email ricevute da <b>ZURICH</b>. Le email considerate sono quelle nello stato <b>DA LEGGERE</b> ed il file allegato che viene considerato per l'importazione è quello denominato <b>incarico.xml</b>. Durante l'importazione verrà effettuato un controllo sul <b>Numero Sinistro Compagnia</b> per evitare che un sinistro già inserito possa essere replicato. Alla fine della procedura verrà presentato un resoconto dei sinistri importati. Confermare l'operazione?
                        </h6>
                    </div>
                </React.Fragment>);
            };
            this.dialogInstance1.buttons = [
                {
                    click: () => {
                        this.dialogInstance1.hide();
                        this.toggleBlocking();
                        const Utente = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;
                        CustomJS.callAPI(`ChiamateAPISIPAEVO/LeggiEmailCreaSinistri/ZURICH/[NO]/[NO]/${Utente}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                            let retData2 = CustomJS.isNullEmpty(retData.data) ? null : JSON.parse(retData.data);
                            if (retData.response === 'OK') {
                                this.toggleBlocking();
                                if (retData2.length === 0) {
                                    toast.warn(`Nessun sinistro da elaborare. Controllare il contenuto della casella email di riferimento ed eventualmente riprovare!!`, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                    return;
                                }
                                else {
                                    this.dialogInstance1.header = '';
                                    this.dialogInstance1.width = '65%';
                                    this.dialogInstance1.height = '90%';
                                    this.dialogInstance1.minHeight = '90%';
                                    this.dialogInstance1.buttons = [];
                                    this.dialogInstance1.beforeOpen = (args) => {
                                        const container = document.getElementById('defaultDialogSitSinContent');
                                        const root = createRoot(container);
                                        root.unmount();
                                    };
                                    this.dialogInstance1.open = (args) => {
                                        document.getElementById('aggiornatabella').click();
                                        setTimeout(() => {
                                            const container = document.getElementById('defaultDialogSitSinContent');
                                            const root = createRoot(container);
                                            root.render(<CallbackWrapper callback={() => {
                                                this.gridInstanceImportZurich.dataSource = retData2;
                                            }}>{this.renderReportImportazioneZURICH()}</CallbackWrapper>);
                                        }, 150);
                                    };
                                    this.dialogInstance1.show();
                                }
                            }
                            else if (retData.response === 'KO') {
                                toast.error(retData.message, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking();
                            }
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking();
                        }, true, false);
                    },
                    buttonModel: {
                        content: 'CONFERMA',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance1.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance1.show();
        }
        else if (args.item.id === 'ImportaAssimoco') {
            //return;
            this.dialogInstance1.header = "<div style='text-align: center;'><span style='font-weight: bold; font-size: 22px; color: #133B95'>Procedura importazione automatica sinistri ASSIMOCO</span></div>";
            this.dialogInstance1.width = '40%';
            this.dialogInstance1.height = '33%';
            this.dialogInstance1.minHeight = '33%';
            this.dialogInstance1.close = undefined;
            this.dialogInstance1.beforeOpen = () => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.render(<React.Fragment>
                    <div className='boxSai' style={{ textAlign: 'center' }}>
                        <h6>
                            La procedura importa automaticamente i sinistri prendendoli dalle email ricevute da <b>ASSIMOCO</b>. Le email considerate sono quelle nello stato <b>DA LEGGERE</b> ed il file allegato che viene considerato per l'importazione è quello denominato <b>incarico.xml</b>. Durante l'importazione verrà effettuato un controllo sul <b>Numero Sinistro Compagnia</b> per evitare che un sinistro già inserito possa essere replicato. Alla fine della procedura verrà presentato un resoconto dei sinistri importati. Confermare l'operazione?
                        </h6>
                    </div>
                </React.Fragment>);
            };
            this.dialogInstance1.buttons = [
                {
                    click: () => {
                        this.dialogInstance1.hide();
                        this.toggleBlocking();
                        const Utente = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;
                        CustomJS.callAPI(`ChiamateAPISIPAEVO/LeggiEmailCreaSinistri/ASSIMOCO/[NO]/[NO]/${Utente}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                            let retData2 = CustomJS.isNullEmpty(retData.data) ? null : JSON.parse(retData.data);
                            if (retData.response === 'OK') {
                                this.toggleBlocking();
                                if (retData2.length === 0) {
                                    toast.warn(`Nessun sinistro da elaborare. Controllare il contenuto della casella email di riferimento ed eventualmente riprovare!!`, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                    return;
                                }
                                else {
                                    this.dialogInstance1.header = '';
                                    this.dialogInstance1.width = '65%';
                                    this.dialogInstance1.height = '90%';
                                    this.dialogInstance1.minHeight = '90%';
                                    this.dialogInstance1.buttons = [];
                                    this.dialogInstance1.beforeOpen = (args) => {
                                        const container = document.getElementById('defaultDialogSitSinContent');
                                        const root = createRoot(container);
                                        root.unmount();
                                    };
                                    this.dialogInstance1.open = (args) => {
                                        document.getElementById('aggiornatabella').click();
                                        setTimeout(() => {
                                            const container = document.getElementById('defaultDialogSitSinContent');
                                            const root = createRoot(container);
                                            root.render(<CallbackWrapper callback={() => {
                                                this.gridInstanceImportAssimoco.dataSource = retData2;
                                            }}>{this.renderReportImportazioneASSIMOCO()}</CallbackWrapper>);
                                        }, 150);
                                    };
                                    this.dialogInstance1.show();
                                }
                            }
                            else if (retData.response === 'KO') {
                                toast.error(retData.message, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking();
                            }
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking();
                        }, true, false);
                    },
                    buttonModel: {
                        content: 'CONFERMA',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance1.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance1.show();
        }
        else if (args.item.id === 'ImportaAllianzViva' || args.item.id === 'ImportaAllianz') {
            //return;
            this.dialogInstance1.header = `<div style='text-align: center;'><span style='font-weight: bold; font-size: 22px; color: #133B95'>Procedura importazione automatica sinistri ${args.item.id === 'ImportaAllianz' ? 'ALLIANZ' : 'ALLIANZVIVA'}</span></div>`;
            this.dialogInstance1.width = '40.8%';
            this.dialogInstance1.height = '33%';
            this.dialogInstance1.minHeight = '33%';
            this.dialogInstance1.close = undefined;
            this.dialogInstance1.beforeOpen = () => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.render(<React.Fragment>
                    <div className='boxSai' style={{ textAlign: 'center' }}>
                        <h6>
                            La procedura importa automaticamente i sinistri prendendoli dalle email ricevute da <b>ALLIANZ/ALLIANZVIVA</b>. Le email considerate sono quelle nello stato <b>DA LEGGERE</b> ed il file allegato che viene considerato per l'importazione è quello denominato <b>MESSAGGIO.INI</b>. Durante l'importazione verrà effettuato un controllo sul <b>Numero Sinistro Compagnia</b> per evitare che un sinistro già inserito possa essere replicato. Alla fine della procedura verrà presentato un resoconto dei sinistri importati. Confermare l'operazione?
                        </h6>
                    </div>
                </React.Fragment>);
            };
            this.dialogInstance1.buttons = [
                {
                    click: () => {
                        this.dialogInstance1.hide();
                        this.toggleBlocking();
                        const Utente = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;
                        const APIURL = args.item.id === 'ImportaAllianzViva' ? `ChiamateAPISIPAEVO/LeggiEmailCreaSinistri/ALLIANZVIVA/[NO]/[NO]/${Utente}` : `ChiamateAPISIPAEVO/LeggiEmailCreaSinistri/ALLIANZ/[NO]/[NO]/${Utente}`;
                        CustomJS.callAPI(APIURL, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                            let retData2 = CustomJS.isNullEmpty(retData.data) ? null : JSON.parse(retData.data);
                            if (retData.response === 'OK') {
                                this.toggleBlocking();
                                if (retData2.length === 0) {
                                    toast.warn(`Nessun sinistro da elaborare. Controllare il contenuto della casella email di riferimento ed eventualmente riprovare!!`, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                    return;
                                }
                                else {
                                    this.dialogInstance1.header = '';
                                    this.dialogInstance1.width = '65%';
                                    this.dialogInstance1.height = '90%';
                                    this.dialogInstance1.minHeight = '90%';
                                    this.dialogInstance1.buttons = [];
                                    this.dialogInstance1.beforeOpen = (args) => {
                                        const container = document.getElementById('defaultDialogSitSinContent');
                                        const root = createRoot(container);
                                        root.unmount();
                                    };
                                    this.dialogInstance1.open = (args) => {
                                        document.getElementById('aggiornatabella').click();
                                        setTimeout(() => {
                                            const container = document.getElementById('defaultDialogSitSinContent');
                                            const root = createRoot(container);
                                            root.render(<CallbackWrapper callback={() => {
                                                this.gridInstanceImportAllianzViva.dataSource = retData2;
                                            }}>{this.renderReportImportazioneALLIANZVIVA()}</CallbackWrapper>);
                                        }, 150);
                                    };
                                    this.dialogInstance1.show();
                                }
                            }
                            else if (retData.response === 'KO') {
                                toast.error(retData.message, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking();
                            }
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking();
                        }, true, false);
                    },
                    buttonModel: {
                        content: 'CONFERMA',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance1.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance1.show();
        }
    }
    sincronizzaIncarichiGenerali() {
        if (CustomJS.isNullEmpty(this.drInstance1.value))
            return;
        this.toggleBlocking2(true);
        CustomJS.callAPI(`ChiamateAPISIPAEVO/SincronizzaIncarichiGenerali/${moment(this.drInstance1.startDate).format('YYYY-MM-DD')}/${moment(this.drInstance1.endDate).format('YYYY-MM-DD')}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
            if (retData.response === 'OK') {
                const QueryExec = 'SELECT * FROM GENERALI_ListaIncarichi';
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData2 => {
                    retData2 = JSON.parse(retData2.data);
                    if (retData2.length > 0) {
                        retData2.forEach(elm => {
                            elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                            if (elm.id_sinistro === 0)
                                elm.status = 'Da importare'
                            else
                                elm.status = 'Importato'
                        });
                        this.gridInstanceImportGenerali.dataSource = retData2;
                    }
                    else
                        this.gridInstanceImportGenerali.dataSource = null;
                    toast.success(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                });
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false)
    }
    sincronizzaIncarichiUnipolSAI() {
        this.toggleBlocking2(true);
        CustomJS.callAPI(`ChiamateAPISIPAEVO/SincronizzaIncarichiUnipolSAI`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
            if (retData.response === 'OK') {
                const QueryExec = 'SELECT * FROM UNIPOLSAI_ListaIncarichi';
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData2 => {
                    retData2 = JSON.parse(retData2.data);
                    if (retData2.length > 0) {
                        retData2.forEach(elm => {
                            elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                            if (elm.id_sinistro === 0)
                                elm.status = 'Da importare'
                            else
                                elm.status = 'Importato'
                        });
                        this.gridInstanceImportUnipolSAI2.dataSource = retData2;
                    }
                    else
                        this.gridInstanceImportUnipolSAI2.dataSource = null;
                    toast.success(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                });
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false)
    }
    importaIncarichiSelezionatiUnipolSAI() { 
        let funcList = [], listaIncarichiImportati = [];
        this.gridInstanceImportUnipolSAI2.getSelectedRecords().forEach(elm => {
            const esegui = () => {
                return new Promise((resolve, reject) => {
                    CustomJS.callAPI(`ChiamateAPISIPAEVO/ImportaIncaricoUnipolSAI/${elm.guid_incarico}/${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user.toString()}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                        if (retData.response === 'OK') {
                            listaIncarichiImportati.push({ guidIncarico: elm.guid_incarico, incarico: elm.id_incarico, esito: 'OK' });
                            resolve(retData.message);
                        }
                        else if (retData.response === 'KO') {
                            listaIncarichiImportati.push({ incarico: elm.id_incarico, esito: 'KO' });
                            reject(retData.message);
                        }
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        reject(messaggio);                        
                    }, true, false);       
                });
            };
            funcList.push(esegui);    
        });

        const eseguiCiclica = (ind) => {
            if (CustomJS.isNullEmpty(funcList[ind])) {
                const QueryExec = 'SELECT * FROM UNIPOLSAI_ListaIncarichi';
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData2 => {
                    retData2 = JSON.parse(retData2.data);
                    if (retData2.length > 0) {
                        retData2.forEach(elm => {
                            elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                            if (elm.id_sinistro === 0)
                                elm.status = 'Da importare'
                            else
                                elm.status = 'Importato'
                        });
                        this.gridInstanceImportUnipolSAI2.dataSource = retData2;
                    }
                    else
                        this.gridInstanceImportUnipolSAI2.dataSource = null;
                    listaIncarichiImportati.filter(obj => { return obj.esito === 'OK' }).forEach(elm => {
                        let idSinistro = _.find(this.gridInstanceImportUnipolSAI2.dataSource, elm2 => { return elm2.id_incarico === elm.incarico }).id_sinistro;
                        this.invioAccettazioneUnipolSAI(elm.guidIncarico, idSinistro); 
                    });
                    this.toggleBlocking2(false);
                    if (listaIncarichiImportati.filter(obj => { return obj.esito === 'OK' }).length > 0)
                        toast.success(`${CustomJS.messaggioImportaSelezionatiOK} ${listaIncarichiImportati.filter(obj => { return obj.esito === 'OK' }).map(obj => { return obj.incarico }).join(', ')}`, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });   
                    if (listaIncarichiImportati.filter(obj => { return obj.esito === 'KO' }).length > 0)
                        toast.error(`${CustomJS.messaggioImportaSelezionatiKO} ${listaIncarichiImportati.filter(obj => { return obj.esito === 'KO' }).map(obj => { return obj.incarico }).join(', ')}`, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });    
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                });        
            }
            else {
                funcList[ind]().then(ret => {
                    if(!CustomJS.isNullEmpty(ret)){
                        eseguiCiclica(ind + 1);
                    }
                }).catch(err => { 
                    eseguiCiclica(ind + 1);
                });
            } 
        };
        if (funcList.length === 0) return;
        this.toggleBlocking2(true);
        eseguiCiclica(0);    
    }
    rowDataBound(args) {
        if (args.data.status === 'Da importare')
            $($(args.row).find('button')).show();
        else {
            $($(args.row).find('button')).hide();
            args.isSelectable = false;
        }
    }
    rowDataBound2(args) {
        if (this.props.tipoPrecSin === "Polizza" || this.props.tipoPrecSin === "Luogo") {
            $($(args.row).find('button')).hide();
        }
        if (args.data.ESITO_ULTIMO_EVENTO_UNI === 'OK' || args.data.ESITO_ULTIMO_EVENTO_GEN === 'OK')
            $($(args.row).find('button')[1]).css('background', 'lawngreen');
        else if (args.data.ESITO_ULTIMO_EVENTO_UNI === 'KO' || args.data.ESITO_ULTIMO_EVENTO_GEN === 'KO')
            $($(args.row).find('button')[1]).css('background', 'orangered');
    }
    rowDataBound3(args) {
        if (this.props.tipoPrecSin === "Polizza" || this.props.tipoPrecSin === "Luogo") {
            $($(args.row).find('button')).hide();
        }
        else {
            if ((args.data.id_compagnia.toString() === CustomJS.codiceCompagniaGenerali || args.data.id_compagnia.toString() === CustomJS.codiceCompagniaUnipolSAI)
                && !CustomJS.isNullEmpty(args.data.data_res) && args.data.data_res !== '0000-00-00' && (args.data.data_chi === '0000-00-00' || CustomJS.isNullEmpty(args.data.data_chi))) {
                $($(args.row).find('button')[0]).show();
                $($(args.row).find('button')[1]).show();
                $($(args.row).find('button')[2]).show();
                $($(args.row).find('button')[3]).show();
                $($(args.row).find('button')[4]).hide();
                $($(args.row).find('button')[5]).hide();
                $($(args.row).find('button')[6]).hide();
            }
            if (document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'chiudere') {
                $($(args.row).find('button')[0]).show();
                $($(args.row).find('button')[1]).show();
                $($(args.row).find('button')[2]).show();
                $($(args.row).find('button')[3]).show();
                $($(args.row).find('button')[4]).hide();
                $($(args.row).find('button')[5]).hide();
                $($(args.row).find('button')[6]).hide();
            }
            else if (document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'sospesi' || document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'chiusi') {
                $($(args.row).find('button')[0]).show();
                $($(args.row).find('button')[1]).hide();
                $($(args.row).find('button')[2]).show();
                $($(args.row).find('button')[3]).show();
                if (document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'chiusi' && !CustomJS.isNullEmpty(args.data.id_incarico)
                    && args.data.chiusura_telematica === true)
                    $($(args.row).find('button')[4]).show();
                else
                    $($(args.row).find('button')[4]).hide();
                $($(args.row).find('button')[5]).hide();
                if (args.data.id_compagnia.toString() === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(args.data.id_incarico) 
                    && document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'chiusi')
                    $($(args.row).find('button')[6]).show();
                else
                    $($(args.row).find('button')[6]).hide();             
            }
            else {
                $($(args.row).find('button')[0]).show();
                $($(args.row).find('button')[1]).hide();
                $($(args.row).find('button')[2]).show();
                $($(args.row).find('button')[3]).hide();
                $($(args.row).find('button')[4]).hide();
                $($(args.row).find('button')[5]).hide();
                $($(args.row).find('button')[6]).hide();
            }
            if (args.data.PRESENZA_ACCETTAZIONE_SINISTRO === 0 && args.data.id_compagnia.toString() === CustomJS.codiceCompagniaUnipolSAI 
                && args.data.id_stato === 21 && new Date(args.data.action_datatime_xpert) < new Date().setMinutes(new Date().getMinutes() - 60)) {
                $($(args.row).find('button')[5]).show();
                $($(args.row).find('button')[5]).css('background', 'gold');
            }
            if (!CustomJS.isNullEmpty(args.data.PRESENZA_ALTRI_SINISTRI_UGUALI))
                $($(args.row).find('button')[2]).css('background', 'gold');
            if (args.data.ESITO_ULTIMO_EVENTO_UNI === 'OK' || args.data.ESITO_ULTIMO_EVENTO_GEN === 'OK')
                $($(args.row).find('button')[2]).css('background', 'lawngreen');
            else if (args.data.ESITO_ULTIMO_EVENTO_UNI === 'KO' || args.data.ESITO_ULTIMO_EVENTO_GEN === 'KO')
                $($(args.row).find('button')[2]).css('background', 'orangered');
        }
    }
    onRecordDoubleClick(args) {
        this.toggleBlocking();
        setTimeout(() => {
            ++this.childKey;
            const container = document.getElementById('contenutoDinamico2');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                this.toggleBlocking();
                this.distruggiDialog()
            }}><PaginaDettaglioSinistro key={this.childKey} IdSinistro={args.rowData.id_sinistro} IdCompagnia={args.rowData.id_compagnia}></PaginaDettaglioSinistro></CallbackWrapper>);
        }, 50);
    }
    onActionBegin(args) {
        if (args.requestType === "beginEdit") {
            args.cancel = true;
        }
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-edit') > -1) {
            this.toggleBlocking();
            setTimeout(() => {
                ++this.childKey;
                const container = document.getElementById('contenutoDinamico2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.toggleBlocking();
                    this.distruggiDialog()
                }}><PaginaDettaglioSinistro key={this.childKey} IdSinistro={args.rowData.id_sinistro} IdCompagnia={args.rowData.id_compagnia}></PaginaDettaglioSinistro></CallbackWrapper>);
            }, 50);
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {
            this.emettiNotifiche(args.rowData.id_sinistro, null, true, args.rowData.id_compagnia);
        }
    }
    onCommandClick2(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-download') > -1) {
            this.toggleBlocking2(true);
            CustomJS.callAPI(`ChiamateAPISIPAEVO/ImportaIncaricoGenerali/${args.rowData.id_incarico}/${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user.toString()}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                if (retData.response === 'OK') {
                    const QueryExec = 'SELECT * FROM GENERALI_ListaIncarichi';
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData2 => {
                        retData2 = JSON.parse(retData2.data);
                        if (retData2.length > 0) {
                            retData2.forEach(elm => {
                                elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                if (elm.id_sinistro === 0)
                                    elm.status = 'Da importare'
                                else
                                    elm.status = 'Importato'
                            });
                            this.gridInstanceImportGenerali.dataSource = retData2;
                        }
                        else
                            this.gridInstanceImportGenerali.dataSource = null;
                        toast.success(retData.message, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    });
                    this.toggleBlocking2(false);
                }
                else if (retData.response === 'KO') {
                    toast.warn(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }, true, false);
        }
    }
    onCommandClick3(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-edit') > -1) {
            this.toggleBlocking();
            setTimeout(() => {
                ++this.childKey;
                const container = document.getElementById('contenutoDinamico2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.toggleBlocking();
                    this.distruggiDialog()
                }}><PaginaDettaglioSinistro key={this.childKey} IdSinistro={args.rowData.id_sinistro} IdCompagnia={args.rowData.id_compagnia}></PaginaDettaglioSinistro></CallbackWrapper>);
            }, 50);
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-times') > -1) {
            this.dialogInstance1.header = `<div id="header-dialog-${this.dialogInstance1.id}"></div>`;
            this.dialogInstance1.height = '26%';
            this.dialogInstance1.minHeight = '26%';
            this.dialogInstance1.width = '28%';
            this.dialogInstance1.close = undefined;
            this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.render(<div style={{ textAlign: 'center' }}>
                    <h5>Operazione di chiusura del sinistro. Tutte le operazioni post chiusura, non gestite da Jellyfish XPERT, dovranno essere eseguite nei rispettivi sistemi di competenza. Confermare la chiusura del sinistro?</h5>
                    <i className="fa fa-question-circle"></i>Scegli un opzione<br></br>
                </div>);
            };
            this.dialogInstance1.buttons = [
                {
                    click: () => {
                        this.dialogInstance1.hide();
                        this.toggleBlocking();
                        if (args.rowData.id_compagnia.toString() === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(args.rowData.id_incarico)
                            && !CustomJS.isNullEmpty(args.rowData.PresenzaPeriziaOnline)) {
                            CustomJS.callAPI(`ChiamateAPISIPAEVO/ChiusuraDanneggiatoGenerali/${args.rowData.id_sinistro}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                                if (retData.response === 'OK') {
                                    toast.success(retData.message, {
                                        containerId: 'toastContainer1',
                                        //onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 7000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                    debugger; //TODO METTERE QUI L'IMPOSTAZIONE DELL'OCCHIO VERDE LATO CLIENT
                                    CustomJS.callAPI(`ChiamateAPISIPAEVO/ChiusuraIncaricoGenerali/${args.rowData.id_sinistro}/${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                                        if (retData.response === 'OK') {
                                            this.toggleBlocking();
                                            toast.success(retData.message, {
                                                containerId: 'toastContainer1',
                                                //onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 7000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });
                                            setTimeout(() => {
                                                document.getElementById('aggiornatabella').click();
                                            }, 200);
                                        }
                                        else if (retData.response === 'KO') {
                                            // toast.error(retData.message, {
                                            //     containerId: 'toastContainer1',
                                            //     onClose: () => toast.dismiss(),
                                            //     position: "bottom-right",
                                            //     autoClose: 7000,
                                            //     hideProgressBar: false,
                                            //     closeOnClick: true,
                                            //     pauseOnHover: true,
                                            //     draggable: false,
                                            //     progress: undefined,
                                            //     newestOnTop: true,
                                            //     rtl: false,
                                            //     pauseOnFocusLoss: true
                                            // });
                                            this.toggleBlocking();
                                            debugger; //TODO METTERE QUI L'IMPOSTAZIONE DELL'OCCHIO ROSSO LATO CLIENT
                                            this.emettiNotifiche(args.rowData.id_sinistro, args.rowData.sin_num, false, args.rowData.id_compagnia);
                                        }
                                    }, error => {
                                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 15000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                        this.toggleBlocking();
                                    }, true, false);
                                }
                                else if (retData.response === 'KO') {
                                    // toast.error(retData.message, {
                                    //     containerId: 'toastContainer1',
                                    //     onClose: () => toast.dismiss(),
                                    //     position: "bottom-right",
                                    //     autoClose: 7000,
                                    //     hideProgressBar: false,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: false,
                                    //     progress: undefined,
                                    //     newestOnTop: true,
                                    //     rtl: false,
                                    //     pauseOnFocusLoss: true
                                    // });
                                    this.toggleBlocking();
                                    debugger; //TODO METTERE QUI L'IMPOSTAZIONE DELL'OCCHIO ROSSO LATO CLIENT
                                    this.emettiNotifiche(args.rowData.id_sinistro, args.rowData.sin_num, false, args.rowData.id_compagnia);
                                }
                            }, error => {
                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking();
                            }, true, false);
                        }
                        else if (args.rowData.id_compagnia.toString() === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(args.rowData.id_incarico)
                            && !CustomJS.isNullEmpty(args.rowData.PresenzaPeriziaOnline)) {
                            let jsonObject = {
                                idSinistro: args.rowData.id_sinistro.toString(),
                                tipoEvento: 'R_PER',
                            };
                            CustomJS.callAPI(`ChiamateAPISIPAEVO/InvioComunicazioniUnipolSAI`, JSON.stringify(jsonObject), 'POST',
                                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                                    if (retData.response === 'OK') {
                                        //this.toggleBlocking();
                                        toast.success(retData.message, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                        let jsonObject3 = {
                                            QueryExec: `START TRANSACTION; UPDATE acsinistri SET data_chi = @dataChiusura, id_stato = @idStato, chiusura_telematica = 1 WHERE id_sinistro = @idSinistro; INSERT INTO _action_log (sigla_studio, id_user, action_data, action, note, param_1, param_2, param_3, action_datatime_xpert) VALUES ('ac', @idUtente, @dataChiusura, 'Chiusura Sinistro', '', @idSinistro, 0, 0, CURRENT_TIMESTAMP); COMMIT;`,
                                            dataChiusura: moment(new Date()).format('YYYY-MM-DD'),
                                            idUtente: JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user.toString(),
                                            idSinistro: args.rowData.id_sinistro.toString(),
                                            idStato: '22'
                                        };
                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject3), retData3 => {
                                            if (retData3.response === 'OK') {
                                                this.toggleBlocking();
                                                toast.success('Sinistro chiuso con successo!! Tutte le operazioni post chiusura non gestite da Jellyfish XPERT, dovranno essere eseguite nei rispettivi sistemi di competenza...', {
                                                    containerId: 'toastContainer1',
                                                    //onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 7000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                                setTimeout(() => {
                                                    document.getElementById('aggiornatabella').click();
                                                }, 200);
                                                var jsonObject2 = {
                                                    idSinistro: args.rowData.id_sinistro.toString(),
                                                    tipoEvento: 'DOCS_PER',
                                                };
                                                this.toggleBlocking();
                                                CustomJS.callAPI(`ChiamateAPISIPAEVO/InvioComunicazioniUnipolSAI`, JSON.stringify(jsonObject2), 'POST',
                                                    'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData2 => {
                                                        if (retData2.response === 'OK') {
                                                            this.toggleBlocking();
                                                            toast.success(retData.message, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        }
                                                        else if (retData.response === 'KO') {
                                                            toast.warn(retData.message, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                            this.toggleBlocking();
                                                        }
                                                }, error => {
                                                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 15000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                    this.toggleBlocking();
                                                }, true, false);        
                                            }
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });
                                            this.toggleBlocking();
                                        });
                                    }
                                    else if (retData.response === 'KO') {
                                        toast.warn(retData.message, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                        this.toggleBlocking();
                                    }
                                }, error => {
                                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 15000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                    this.toggleBlocking();
                                }, true, false);
                        }
                        else {
                            let jsonObject3 = {
                                QueryExec: `START TRANSACTION; UPDATE acsinistri SET data_chi = @dataChiusura, id_stato = @idStato, chiusura_telematica = 0 WHERE id_sinistro = @idSinistro; INSERT INTO _action_log (sigla_studio, id_user, action_data, action, note, param_1, param_2, param_3, action_datatime_xpert) VALUES ('ac', @idUtente, @dataChiusura, 'Chiusura Sinistro', '', @idSinistro, 0, 0, CURRENT_TIMESTAMP); COMMIT;`,
                                dataChiusura: moment(new Date()).format('YYYY-MM-DD'),
                                idUtente: JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user.toString(),
                                idSinistro: args.rowData.id_sinistro.toString(),
                                idStato: '22'
                            };
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject3), retData3 => {
                                if (retData3.response === 'OK') {
                                    this.toggleBlocking();
                                    toast.success('Sinistro chiuso con successo!! Tutte le operazioni post chiusura non gestite da Jellyfish XPERT, dovranno essere eseguite nei rispettivi sistemi di competenza...', {
                                        containerId: 'toastContainer1',
                                        //onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 7000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                    setTimeout(() => {
                                        document.getElementById('aggiornatabella').click();
                                    }, 100);
                                }
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking();
                            });
                        }
                    },
                    buttonModel: {
                        content: 'CONFERMA',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance1.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance1.show();
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {
            this.emettiNotifiche(args.rowData.id_sinistro, args.rowData.sin_num, true, args.rowData.id_compagnia);
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-undo') > -1) {
            this.dialogInstance1.header = '';
            this.dialogInstance1.height = document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'chiudere' ? '20%' : '26%';
            this.dialogInstance1.minHeight = document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'chiudere' ? '20%' : '26%';
            this.dialogInstance1.width = '25%';
            this.dialogInstance1.close = undefined;
            this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.render(<div style={{ textAlign: 'center' }}>
                    <h4>{document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'chiudere' ? 'Confermare la restituzione al perito?' : document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'chiusi' ? 'Confermare la restituzione alla segreteria oppure direttamente al perito?' : ''}</h4>
                    <i className="fa fa-question-circle"></i>Scegli un opzione<br></br>
                </div>);
            };
            this.dialogInstance1.buttons = document.getElementById('ddlFiltroSinistriSegreteria').ej2_instances[0].value === 'chiudere' ? [
                {
                    click: () => {
                        this.dialogInstance1.hide();
                        var jsonObject = {
                            QueryExec: `UPDATE acsinistri SET data_res = @dataRestituzione, id_stato = @idStato, data_chi = @dataChiusura WHERE id_sinistro = ${args.rowData.id_sinistro}`,
                            dataRestituzione: null,
                            dataChiusura: null,
                            idStato: '1'
                        }
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
                            document.getElementById('aggiornatabella').click();
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            }
                            );
                        });
                    },
                    buttonModel: {
                        content: 'CONFERMA',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance1.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
                ] : [
                {
                    click: () => {
                        this.dialogInstance1.hide();
                        var jsonObject = {
                            QueryExec: `UPDATE acsinistri SET data_res = @dataRestituzione, id_stato = @idStato, data_chi = @dataChiusura WHERE id_sinistro = ${args.rowData.id_sinistro}`,
                            dataRestituzione: null,
                            dataChiusura: null,
                            idStato: '1'
                        }
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
                            document.getElementById('aggiornatabella').click();
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            }
                            );
                        });
                    },
                    buttonModel: {
                        content: 'PERITO',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance1.hide();
                        var jsonObject = {
                            QueryExec: `UPDATE acsinistri SET id_stato = @idStato, data_chi = @dataChiusura WHERE id_sinistro = ${args.rowData.id_sinistro}`,
                            dataChiusura: null,
                            idStato: '19'
                        }
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
                            document.getElementById('aggiornatabella').click();
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            }
                            );
                        });
                    },
                    buttonModel: {
                        content: 'SEGRETERIA',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance1.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance1.show();
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-file-pdf') > -1) {
            CustomJS.callAPI(`ChiamateAPISIPAEVO/ScaricaDocumentoPeriziaGenerali/${args.rowData.id_incarico}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                if (retData.response === 'OK') {
                    let element = document.createElement('a');
                    element.setAttribute('href', `data:application/pdf;base64,${retData.data}`);
                    element.setAttribute('download', retData.filename);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
            }, true, false);
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-paper-plane') > -1) {
            this.toggleBlocking();
            this.invioAccettazioneUnipolSAI(args.rowData.guid_incarico, args.rowData.id_sinistro);
            setTimeout(() => {
                this.toggleBlocking();
            }, 2000);
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-euro-sign') > -1) {
            this.dialogInstance1.header = `<div id="header-dialog-${this.dialogInstance1.id}"></div>`;
            this.dialogInstance1.height = '45%';
            this.dialogInstance1.minHeight = '45%';
            this.dialogInstance1.width = '40%';
            this.dialogInstance1.close = undefined;
            this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogSitSinContent');
                const root = createRoot(container);
                root.render(<div style={{ textAlign: 'center' }}>
                    <h5>Inserire le informazioni relative al nuovo importo indennizzabile e confermare l'invio ad UNIPOLSAI</h5>
                    <div className="e-card-resize-container">
                        <div className="row card-layout">
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-content">
                                    <div className="row">
                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" >
                                            <DatePickerComponent id='dataValNuovoImportoIndenn' showClearButton={true} placeholder='Data Valutazione' cssClass='e-outline' floatLabelType='Always'></DatePickerComponent>
                                        </div>
                                        <div className="col-xs-5 col-sm-5 col-lg-5 col-md-3">
                                            <NumericTextBoxComponent id='nuovoImportoIndenn' format="c2" decimals='2' showSpinButton={false} floatLabelType='Auto' placeholder='Nuovo Importo Indennizzabile' 
                                                cssClass='e-outline'></NumericTextBoxComponent>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <TextBoxComponent id='noteNuovoImportoIndenn' floatLabelType='Auto' multiline={true} placeholder='Note' htmlAttributes={{ rows: 3 }} 
                                                cssClass='e-outline'></TextBoxComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <i className="fa fa-question-circle"></i>Scegli un opzione<br></br>
                </div>);
            };
            this.dialogInstance1.buttons = [
                {
                    click: () => {
                        if (CustomJS.isNullEmpty(document.getElementById('dataValNuovoImportoIndenn').ej2_instances[0].value)
                            || CustomJS.isNullEmpty(document.getElementById('nuovoImportoIndenn').ej2_instances[0].value)
                            || document.getElementById('nuovoImportoIndenn').ej2_instances[0].value === 0)
                            return;
                        var jsonObject = {
                            idSinistro: args.rowData.id_sinistro.toString(),
                            dataValutazione: moment(document.getElementById('dataValNuovoImportoIndenn').ej2_instances[0].value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                            importo: document.getElementById('nuovoImportoIndenn').ej2_instances[0].value.toString(),
                            note: document.getElementById('noteNuovoImportoIndenn').ej2_instances[0].value,
                            tipoEvento: 'N_IPV',
                        };
                        this.toggleBlocking2(true);
                        CustomJS.callAPI(`ChiamateAPISIPAEVO/InvioComunicazioniUnipolSAI`, JSON.stringify(jsonObject), 'POST',
                            'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                                if (retData.response === 'OK') {
                                    this.toggleBlocking2(false);
                                    toast.success(retData.message, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                }
                                else if (retData.response === 'KO') {
                                    this.toggleBlocking2(false);
                                    toast.warn(retData.message, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                }
                            }, error => {
                                this.toggleBlocking2(false);
                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                        }, true, false)
                    },
                    buttonModel: {
                        content: 'CONFERMA',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance1.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance1.show();
        }
    }
    invioAccettazioneUnipolSAI(guidIncarico, idSinistro) {
        var jsonObject = {
            guidIncarico2: guidIncarico,
            tipoEvento: 'S_ACC',
            codiceFiscalePerito: 'LNEMDA76L07L219X'
        };
        CustomJS.callAPI(`ChiamateAPISIPAEVO/InvioComunicazioniUnipolSAI`, JSON.stringify(jsonObject), 'POST',
            'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                if (retData.response === 'OK') {
                    toast.success(retData.message, {
                        containerId: 'toastContainer1',
                        //onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    let dataContatto = CustomJS.calcolaDataFerialeSuccessiva(new Date(), random(1,4));
                    let jsonObject = {
                        QueryExec: `UPDATE acsinistri SET esito_contatto = @esitoContatto, data_contatto = @dataContatto, dataContattoNonRiuscito = @dataContattoNonRiuscito, dataProssimoContatto = @dataProssimoContatto WHERE id_sinistro = ${idSinistro}`,
                        dataContatto: moment(dataContatto).format('YYYY-MM-DD'),
                        dataContattoNonRiuscito: moment(dataContatto).format('YYYY-MM-DD'),
                        dataProssimoContatto: moment(CustomJS.calcolaDataFerialeSuccessiva(dataContatto, random(1,3))).format('YYYY-MM-DD'),
                        esitoContatto: '03'
                    };
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData2 => {
                        if (retData2.response === 'OK') {
                            this.invioPrimoContattoUnipolSAI(idSinistro);
                        }
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    });
                }
                else if (retData.response === 'KO') {
                    toast.warn(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, false)
    }
    invioPrimoContattoUnipolSAI(idSinistro) {
        var jsonObject = {
            idSinistro: idSinistro.toString(),
            codiceBelfiore: '',
            tipoEvento: 'E_CON',
        };
        CustomJS.callAPI(`ChiamateAPISIPAEVO/InvioComunicazioniUnipolSAI`, JSON.stringify(jsonObject), 'POST',
            'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                if (retData.response === 'OK') {
                    toast.success(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }
                else if (retData.response === 'KO') {
                    toast.warn(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, false)
    }
    onCommandClick4(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-download') > -1) {
            this.toggleBlocking2(true);
            CustomJS.callAPI(`ChiamateAPISIPAEVO/ImportaIncaricoUnipolSAI/${args.rowData.guid_incarico}/${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user.toString()}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                if (retData.response === 'OK') {
                    const QueryExec = 'SELECT * FROM UNIPOLSAI_ListaIncarichi';
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData2 => {
                        retData2 = JSON.parse(retData2.data);
                        if (retData2.length > 0) {
                            retData2.forEach(elm => {
                                elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                if (elm.id_sinistro === 0)
                                    elm.status = 'Da importare'
                                else
                                    elm.status = 'Importato'
                            });
                            this.gridInstanceImportUnipolSAI2.dataSource = retData2;
                        }
                        else
                            this.gridInstanceImportUnipolSAI2.dataSource = null;
                        toast.success(retData.message, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        let idSinistro = _.find(retData2, elm => { return elm.id_incarico === args.rowData.id_incarico}).id_sinistro;
                        this.invioAccettazioneUnipolSAI(args.rowData.guid_incarico, idSinistro);
                        this.toggleBlocking2(false);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking2(false);
                    });
                }
                else if (retData.response === 'KO') {
                    toast.warn(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }, true, false);
        }
    }
    sortComparer1(reference, comparer) {
        if (moment(reference, 'YYYY-MM-DD').isBefore(moment(comparer, 'YYYY-MM-DD'))) {
            return -1;
        }
        if (moment(reference, 'YYYY-MM-DD').isAfter(moment(comparer, 'YYYY-MM-DD'))) {
            return 1;
        }
        return 0;
    }
    onChangeCompagniaFiltro(args) {
        //Divisione
        const QueryExec = `SELECT T1.id_divisione, T1.divisione FROM _divisioni T1 WHERE id_compagnia = ${args.value};`;
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
            retData = JSON.parse(retData.data);
            if (retData === '[]')
                document.getElementById(`ddlDivisioneFiltro`).ej2_instances[0].dataSource = [];
            else
                document.getElementById(`ddlDivisioneFiltro`).ej2_instances[0].dataSource = retData?.map(elm => { return { VALUE: elm.id_divisione.toString(), TEXT: elm.divisione } });
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
        //Agenzie
        const QueryExec2 = `SELECT T1.id_agenzia, T1.agenzia FROM _agenzie T1 WHERE id_compagnia = ${args.value};`;
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec2, '', retData => {
            retData = JSON.parse(retData.data);
            if (retData === '[]')
                document.getElementById(`ddlAgenziaFiltro`).ej2_instances[0].dataSource = [];
            else
                document.getElementById(`ddlAgenziaFiltro`).ej2_instances[0].dataSource = retData?.map(elm => { return { VALUE: elm.id_agenzia.toString(), TEXT: elm.agenzia } });
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
        //Aree
        const QueryExec3 = `SELECT T1.id_area, T1.area FROM _aree T1 WHERE id_compagnia = ${args.value};`;
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec3, '', retData => {
            retData = JSON.parse(retData.data);
            if (retData === '[]')
                document.getElementById(`ddlAreaFiltro`).ej2_instances[0].dataSource = [];
            else
                document.getElementById(`ddlAreaFiltro`).ej2_instances[0].dataSource = retData?.map(elm => { return { VALUE: elm.id_area.toString(), TEXT: elm.area } });
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
        //Polizza
        const QueryExec4 = `SELECT T1.id_tipo_polizza, T1.tipo_polizza FROM _tipo_polizza T1 WHERE id_compagnia = ${args.value};`;
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec4, '', retData => {
            retData = JSON.parse(retData.data);
            if (retData === '[]')
                document.getElementById(`ddlPolizzaFiltro`).ej2_instances[0].dataSource = [];
            else
                document.getElementById(`ddlPolizzaFiltro`).ej2_instances[0].dataSource = retData?.map(elm => { return { VALUE: elm.id_tipo_polizza.toString(), TEXT: elm.tipo_polizza } });
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
    }
    pulisciFiltri() {
        document.getElementById('ddlReferenteFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlSedeFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlPeritoFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlStatoFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlCompagniaFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlDivisioneFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlAreaFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlAgenziaFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlBrokerFiltro').ej2_instances[0].value = null;
        document.getElementById('numeroRiferimentoFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlPolizzaFiltro').ej2_instances[0].value = null;
        document.getElementById('numeroSinistroFiltro').ej2_instances[0].value = null;
        document.getElementById('dataAccadimento').ej2_instances[0].value = null;
        document.getElementById('contraenteFiltro').ej2_instances[0].value = null;
        document.getElementById('danneggiatoFiltro').ej2_instances[0].value = null;
        document.getElementById('numeroRiferimentoStudioFiltro').ej2_instances[0].value = null;
        document.getElementById('indirizzoFiltro').ej2_instances[0].value = null;
        document.getElementById('ddlCittaFiltro').ej2_instances[0].value = null;
        this.accordInstance.expandItem(false, 0);
        this.caricaDati();
    }
    accordContent0() {
        return (<div className="control-section card-control-section basic_card_layout">
            <div className="e-card-resize-container">
                <div className="row card-layout">
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <div tabIndex="0" className="e-card" id="main_card">
                            <div className="e-card-content">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">
                                        <DropDownListComponent id='ddlReferenteFiltro' fields={this.ddlFields} dataSource={this.dsReferenti}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare un Referente da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">
                                        <DropDownListComponent id='ddlSedeFiltro' fields={this.ddlFields} dataSource={this.dsSedi}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare una Sede da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">
                                        <DropDownListComponent id='ddlPeritoFiltro' fields={this.ddlFields} dataSource={this.dsUtentiFiltrato}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare un Perito da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">
                                        <DropDownListComponent id='ddlStatoFiltro' fields={this.ddlFields} dataSource={this.dsStati}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare uno Stato da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">
                                        <DropDownListComponent id='ddlCompagniaFiltro' fields={this.ddlFields} dataSource={this.dsCompagnie} onChange={this.onChangeCompagniaFiltro.bind(this)}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare una Compagnia da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">
                                        <DropDownListComponent id='ddlDivisioneFiltro' fields={this.ddlFields} dataSource={this.dsDivisioni}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare una Divisione da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">
                                        <DropDownListComponent id='ddlAreaFiltro' fields={this.ddlFields} dataSource={this.dsAree}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare un'Area/CLD da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">
                                        <DropDownListComponent id='ddlAgenziaFiltro' fields={this.ddlFields} dataSource={this.dsAgenzie}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare una Agenzia da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">
                                        <DropDownListComponent id='ddlBrokerFiltro' fields={this.ddlFields} dataSource={this.dsBroker}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare un Broker da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <TextBoxComponent id='numeroRiferimentoFiltro' floatLabelType='Auto' placeholder='Numero Riferimento da filtrare' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <DropDownListComponent id='ddlPolizzaFiltro' fields={this.ddlFields} dataSource={this.dsTipoPolizza}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare una Polizza da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <TextBoxComponent id='numeroSinistroFiltro' floatLabelType='Auto' placeholder='Numero Sinistro da filtrare' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <DateRangePickerComponent id="dataAccadimento" ref={drObj => this.drInstance1 = drObj} placeholder='Data Accadimento Da-A' floatLabelType='Auto' cssClass='e-outline' />
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3'>
                                        <DateRangePickerComponent id="dateRangeRestituzione" ref={drObj => this.drInstance2 = drObj} placeholder='Data Restituzione Da-A' floatLabelType='Auto' cssClass='e-outline' />
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3'>
                                        <DateRangePickerComponent id="dateRangeChiusura" ref={drObj => this.drInstance3 = drObj} placeholder='Data Chiusura Da-A' floatLabelType='Auto' cssClass='e-outline' />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <TextBoxComponent id='contraenteFiltro' floatLabelType='Auto' placeholder='Contraente da filtrare' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <TextBoxComponent id='danneggiatoFiltro' floatLabelType='Auto' placeholder='Danneggiato da filtrare' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <TextBoxComponent id='numeroRiferimentoStudioFiltro' floatLabelType='Auto' placeholder='Numero Riferimento Studio da filtrare' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <TextBoxComponent id='indirizzoFiltro' floatLabelType='Auto' placeholder='Indirizzo da filtrare' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <DropDownListComponent id='ddlCittaFiltro' fields={this.ddlFields} dataSource={this.dsComuni}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Selezionare una Città da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6 text-end">
                                        <ButtonComponent id='BtnApplicaFiltri' alt='Applica Filtri Aggiuntivi' cssClass='e-success'>Applica Filtri</ButtonComponent>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">
                                        <ButtonComponent id='BtnPulisciFiltri' alt='Pulisci Filtri Aggiuntivi' onClick={this.pulisciFiltri.bind(this)} cssClass='e-danger'>Pulisci Filtri</ButtonComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
    render() {
        return (<React.Fragment>
            <div id='blockUIGenerico' style={{ display: 'none' }}></div>
            <div id='spinnerUIGenerico' style={{ display: 'none' }} role="status"></div>
            <div className="sidebarNotifiche" style={{ textAlign: 'center' }}>
                <h3>Notifiche<br />Sinistro {this.state.NumSinistro}</h3>
                <div className='chiudiNotifiche' title='Chiudi Notifiche'>✕</div>
                <div id='elencoNotifiche'></div>
            </div>
            <BlockUi id='blockUIPaginaSituazioneSinistri' style={{ marginTop: '-50px', marginLeft: '64px', overflowX: 'hidden' }} tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialogSitSin" cssClass='dialog-sipa' showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance1 = dialog} isModal={true}>
                    <div id='defaultDialogSitSinContent'></div>
                </DialogComponent>
                <DialogComponent id="defaultDialogSitSin2" cssClass='dialog-sipa' showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance2 = dialog} isModal={true}>
                    <div id='defaultDialogSitSinContent2'></div>
                </DialogComponent>
                <DialogComponent id="defaultDialogSitSin3" cssClass='dialog-sipa' showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance3 = dialog} isModal={true}>
                    <div id='defaultDialogSitSinContent3'></div>
                </DialogComponent>
                <div id='containerSituaSinistri' style={{ marginLeft: '-65px' }} className='container-fluid'>
                    <div id='gridSegreteria'>
                        <div className='row' style={{ marginTop: '100px' }}>
                            <div className="offset-xs-1 col-xs-1 offset-sm-1 col-sm-1 offset-lg-1 col-lg-1 offset-md-1 col-md-1">
                                <DropDownButtonComponent id='btnImportaSinistri' items={this.btnItems} content='IMPORTAZIONE AUTOMATICA<br>SINISTRI' cssClass='e-fill e-info' style={{ width: '200%', marginTop: '30px', marginLeft: '-50px' }} select={this.onSelectSplitBtn.bind(this)}></DropDownButtonComponent >
                            </div>
                            <div className="offset-xs-1 col-xs-9 offset-sm-1 col-sm-9 offset-lg-1 col-lg-9 offset-md-1 col-md-9" style={{ visibility: 'none' }}>
                                <AccordionComponent id='accordionFiltriAggiuntivi' expandMode='Single' ref={accord => this.accordInstance = accord} style={{ marginTop: '30px' }}>
                                    <AccordionItemsDirective>
                                        <AccordionItemDirective header='Filtri Aggiuntivi' expanded={false} content={this.accordContent0.bind(this)} iconCss="e-imageAccordion" />
                                    </AccordionItemsDirective>
                                </AccordionComponent>
                            </div>
                        </div>
                        <div className='row'>
                            <div id='contentGridSituazSinistri' className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ marginTop: '15px', marginLeft: '70px' }}>
                                <GridComponent id="GridSituazioneSinistri" rowDataBound={this.rowDataBound3.bind(this)} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick.bind(this)} hierarchyPrintMode='All' recordDoubleClick={this.onRecordDoubleClick.bind(this)}
                                    allowSelection={true} enableHover={false} showColumnMenu={false} showColumnChooser={false} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={true} pageSettings={this.pageSettings} dataBound={this.onDataBoundSegreteria.bind(this)}
                                    allowGrouping={true} commandClick={this.onCommandClick3.bind(this)} allowExcelExport={true} editSettings={this.editSettings} filterSettings={this.filterSettings} style={{ border: '0px' }} actionBegin={this.onActionBegin.bind(this)} actionComplete={this.actionComplete.bind(this)}>
                                    <ColumnsDirective>
                                        <ColumnDirective field='id_sinistro' headerText='ID' width='10%' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate2} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='compagnia' headerText="Compagnia" width='12%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='agenzia' headerText="Agenzia" width='11%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='id_compagnia' visible={false} headerText="IdCompagnia" width='14%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='sin_num' headerText="Numero Sinistro" width='16%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='contraente' headerText="Contraente" width='13%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='tipo_sinistro' headerText="Tipo" width='11%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='luo_cit' headerText="Comune" width='11%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='data_inc_per' sortComparer={this.sortComparer1} visible={false} headerText="Data Incarico" width='12%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='sin_per' visible={false} sortComparer={this.sortComparer1} headerText="Data Pervenimento" width='15%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='data_chi' headerText="Data Chiusura" visible={false} sortComparer={this.sortComparer1} width='15%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='denom_referente' headerText="Referente" width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='denom' headerText="Perito" visible={false} width='12%' headerTemplate={headerTemplate} template={fieldTemplate2} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='data_res' visible={false} sortComparer={this.sortComparer1} headerText="Data Restituzione" width='15%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='motivo_sospensione' visible={false} headerText="Motivo Sospensione" width='28%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='RITARDO_SINISTRO' headerText="Ritardo (gg)" width='11%' format='n0' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate2}></ColumnDirective>
                                        <ColumnDirective field='DESCRIZ' headerText="Status" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='chiusura_telematica' headerText="CTA" visible={false} width='8%' headerTemplate={headerTemplate} template={fieldTemplate3} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='Notifica' headerText="" width='5%' textAlign='Center' template={fieldTemplate2} allowSorting={false} allowFiltering={false}></ColumnDirective>
                                        <ColumnDirective field='Comandi' headerText="" commands={this.commands3} width='10%' headerTemplate={headerTemplate} textAlign='Right' allowSorting={false} allowFiltering={false}></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                                </GridComponent>
                            </div>
                        </div>
                    </div>
                    <div id='gridPerito' className='row' style={{ marginTop: '120px', marginLeft: '80px' }}>
                        <div id='contentGridSituazSinistriPerito' className='col-lg-10 col-md-10 col-sm-10 col-xs-10' >
                            <GridComponent id="GridSituazioneSinistriPerito" ref={grid => this.gridInstancePerito = grid} toolbar={this.toolbarOptionsPerito} toolbarClick={this.toolbarClick.bind(this)} hierarchyPrintMode='All' recordDoubleClick={this.onRecordDoubleClick.bind(this)} rowDataBound={this.rowDataBound2.bind(this)}
                                allowSelection={true} enableHover={false} showColumnMenu={false} showColumnChooser={false} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings}
                                commandClick={this.onCommandClick.bind(this)} allowGrouping={true} allowExcelExport={true} editSettings={this.editSettingsPerito} style={{ border: '0px' }} dataBound={this.onDataBoundPerito.bind(this)} filterSettings={this.filterSettings} actionBegin={this.onActionBegin.bind(this)} actionComplete={this.actionComplete.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id_sinistro' headerText='ID' width='8%' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate2} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='compagnia' headerText="Compagnia" width='15%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='contraente' headerText="Contraente" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='tipo_sinistro' headerText="Tipo" width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='luo_cit' headerText="Comune" width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='data_inc_per' sortComparer={this.sortComparer1} headerText="Data Incarico" width='10%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='data_sop' sortComparer={this.sortComparer1} headerText="Sopralluogo" width='15%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='DESCRIZ' headerText="Status" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='data_res' sortComparer={this.sortComparer1} headerText="Data Restituzione" width='12%' headerTemplate={headerTemplate} textAlign='Center' visible={false} type='datetime' format='dd/MM/yyyy' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='grado' headerText="Grado" width='10%' headerTemplate={headerTemplate} textAlign='Center' visible={false} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='parcella' headerText="Parcella" width='10%' headerTemplate={headerTemplate} textAlign='Center' visible={false} type='number' editType='NumericEdit' format='N2' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    {/* <ColumnDirective field='check' width='10%' textAlign='Center' visible={false} type='checkbox'></ColumnDirective> */}
                                    <ColumnDirective field='data_fatt' sortComparer={this.sortComparer1} headerText="Data Fatturazione" width='15%' headerTemplate={headerTemplate} textAlign='Center' visible={false} type='datetime' format='dd/MM/yyyy' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='fattura' headerText="Fattura" width='10%' headerTemplate={headerTemplate} textAlign='Center' visible={false} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='chiusura_telematica' headerText="CTA" width='8%' headerTemplate={headerTemplate} template={fieldTemplate3} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='Comandi' commands={this.commands} headerText="" width='15%' headerTemplate={headerTemplate} textAlign='Right' allowSorting={false} allowFiltering={false}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                            </GridComponent>
                        </div>
                        <div className=' col-lg-2 col-md-2 col-sm-2 col-xs-2' id='sfondoGrigio' style={{ marginTop: '100px', marginLeft: '0px' }}>
                            <TextBoxComponent id='riepilogo' floatLabelType='Auto' placeholder='Riepilogo' cssClass='e-outline' ref={txtObj => { this.textBoxInstance1 = txtObj }} multiline={true} value={`In carico:\nDa contattare:\nSopralluogo da pianificare:\nIn attesa di sopralluogo:\nIn attesa di documentazione:\nIn attesa di interlocutoria:\nIn attesa di atto:\nIn revisione CQ:\nIn revisione CR:\nDa restituire:\nChiusi nel mese:\nDa fatturare:`}></TextBoxComponent>
                            <TextBoxComponent id='messaggi' floatLabelType='Auto' placeholder='Centro Messaggi' cssClass='e-outline' ref={txtObj => { this.textBoxInstance2 = txtObj }} multiline={true} ></TextBoxComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        sessionStorage.setItem('BtnSblocca', false);
        if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 9) {
            document.getElementById('titoloPagina').innerText = 'Gestione Sinistri';
            $("#gridSegreteria").hide();
            $('#btnImportaSinistri').hide();
            this.textBoxInstance1.addAttributes({ rows: 12 });
            this.textBoxInstance2.addAttributes({ rows: 5 });
            setTimeout(() => {
                this.caricaDati();
            }, 50);
        }
        else if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 1) {
            document.getElementById('titoloPagina').innerText = 'Gestione Sinistri';
            $("#gridPerito").hide();
            setTimeout(() => {
                this.caricaDati();
            }, 50);
        }
    }
    caricaDati() {
        if (this.props.tipoPrecSin === "Polizza") {
            this.toggleBlocking();
            document.getElementById('ddlFiltriContent').style.display = 'none'
            document.getElementById('sfondoGrigio').style.display = 'none'
            document.getElementById('containerSituaSinistri').style.marginLeft = '0px'
            document.getElementById('blockUIPaginaSituazioneSinistri').style.marginLeft = '0px'
            this.pageSettings = { pageSize: 4, pageCount: 4, pageSizes: ["4"] };
            var QueryExecPolizza = '';
            if (this.props.isPeritoUtil === 'Perito') {
                QueryExecPolizza = `SELECT T1.id_sinistro, T1.id_compagnia, T1.sin_num, T1.pol_num, T1.luo_ind, T1.luo_cit, T1.luo_pro, T1.luo_cap, T1.data_inc_per, T2.compagnia, T3.contraente, T4.tipo_sinistro, T5.DESCRIZ FROM acsinistri T1 LEFT OUTER JOIN _compagnie T2 ON T2.id_compagnia = T1.id_compagnia LEFT OUTER JOIN accontraenti T3 ON T1.id_contraente = T3.id_contraente LEFT OUTER JOIN _tipo_sinistro T4 ON T1.id_tipo_sinistro = T4.id_tipo_sinistro LEFT OUTER JOIN ELEMENTITBSTD T5 ON T5.COD_TAB = 'stati_sinistro' AND T1.id_stato = T5.COD_ELE WHERE T1.pol_num = '${this.props.datiRicerca}' AND T1.id_sinistro != '${this.props.idSinistro}' AND T1.id_perito = '${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user}';`;
                document.getElementById('contentGridSituazSinistriPerito').className = 'col-lg-12 col-md-12 col-sm-12 col-xs-12';
                document.getElementById('gridPerito').style.marginTop = '60px';
                document.getElementById('gridPerito').style.marginLeft = '0px';
            }
            else {
                QueryExecPolizza = `SELECT T1.id_sinistro, T1.id_compagnia, T1.sin_num, T1.pol_num, T1.luo_ind, T1.luo_cit, T1.luo_pro, T1.luo_cap, T1.data_inc_per, T2.compagnia, T3.contraente, T4.tipo_sinistro, T5.DESCRIZ FROM acsinistri T1 LEFT OUTER JOIN _compagnie T2 ON T2.id_compagnia = T1.id_compagnia LEFT OUTER JOIN accontraenti T3 ON T1.id_contraente = T3.id_contraente LEFT OUTER JOIN _tipo_sinistro T4 ON T1.id_tipo_sinistro = T4.id_tipo_sinistro LEFT OUTER JOIN ELEMENTITBSTD T5 ON T5.COD_TAB = 'stati_sinistro' AND T1.id_stato = T5.COD_ELE WHERE T1.pol_num = '${this.props.datiRicerca}' AND T1.id_sinistro != '${this.props.idSinistro}';`;
                document.getElementById('btnImportaSinistri').style.display = 'none';
                document.getElementById('accordionFiltriAggiuntivi').style.display = 'none';
                document.getElementById('contentGridSituazSinistri').style.marginTop = '0px';
                document.getElementById('contentGridSituazSinistri').style.marginLeft = '0px';
            }
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecPolizza, '', retDataPolizza => {
                retDataPolizza = JSON.parse(retDataPolizza.data);
                if (this.props.isPeritoUtil === 'Perito') {
                    this.gridInstancePerito.dataSource = retDataPolizza;
                    setTimeout(() => {
                        this.gridInstancePerito.recordDoubleClick = false;
                    }, 200)
                }
                else {
                    this.gridInstance.dataSource = retDataPolizza;
                    setTimeout(() => {
                        this.gridInstance.recordDoubleClick = false;
                    }, 200)
                }
                this.toggleBlocking();
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            });
        }
        else if (this.props.tipoPrecSin === "Luogo") {
            let idSinistro = this.props.idSinistro;
            this.toggleBlocking();
            document.getElementById('ddlFiltriContent').style.display = 'none';
            document.getElementById('sfondoGrigio').style.display = 'none';
            document.getElementById('containerSituaSinistri').style.marginLeft = '0px';
            document.getElementById('blockUIPaginaSituazioneSinistri').style.marginLeft = '0px';
            this.pageSettings = { pageSize: 4, pageCount: 4, pageSizes: ["4"] };
            var QueryExecLuogo = '';
            if (this.props.isPeritoUtil === 'Perito') {
                QueryExecLuogo = `SELECT T1.id_sinistro, T1.id_compagnia, T1.sin_num, T1.pol_num, T1.luo_ind, T1.luo_cit, T1.luo_pro, T1.luo_cap, T1.data_inc_per, T2.compagnia, T3.contraente, T4.tipo_sinistro, T5.DESCRIZ FROM acsinistri T1 LEFT OUTER JOIN _compagnie T2 ON T2.id_compagnia = T1.id_compagnia LEFT OUTER JOIN accontraenti T3 ON T1.id_contraente = T3.id_contraente LEFT OUTER JOIN _tipo_sinistro T4 ON T1.id_tipo_sinistro = T4.id_tipo_sinistro LEFT OUTER JOIN ELEMENTITBSTD T5 ON T5.COD_TAB = 'stati_sinistro' AND T1.id_stato = T5.COD_ELE WHERE UPPER(REPLACE(luo_ind, ' ', '')) = (SELECT UPPER(REPLACE(luo_ind, ' ', '')) FROM acsinistri WHERE id_sinistro = ${idSinistro}) AND UPPER(REPLACE(luo_cit, ' ', '')) = (SELECT UPPER(REPLACE(luo_cit, ' ', '')) FROM acsinistri WHERE id_sinistro = ${idSinistro}) AND UPPER(REPLACE(luo_pro, ' ', '')) = (SELECT UPPER(REPLACE(luo_pro, ' ', '')) FROM acsinistri WHERE id_sinistro = ${idSinistro}) AND UPPER(REPLACE(luo_cap, ' ', '')) = (SELECT UPPER(REPLACE(luo_cap, ' ', '')) FROM acsinistri WHERE id_sinistro = ${idSinistro}) AND id_sinistro != ${idSinistro} AND id_perito = '${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user}';`;
                document.getElementById('contentGridSituazSinistriPerito').className = 'col-lg-12 col-md-12 col-sm-12 col-xs-12';
                document.getElementById('gridPerito').style.marginTop = '60px';
                document.getElementById('gridPerito').style.marginLeft = '0px';
            }
            else {
                QueryExecLuogo = `SELECT T1.id_sinistro, T1.id_compagnia, T1.sin_num, T1.pol_num, T1.luo_ind, T1.luo_cit, T1.luo_pro, T1.luo_cap, T1.data_inc_per, T2.compagnia, T3.contraente, T4.tipo_sinistro, T5.DESCRIZ FROM acsinistri T1 LEFT OUTER JOIN _compagnie T2 ON T2.id_compagnia = T1.id_compagnia LEFT OUTER JOIN accontraenti T3 ON T1.id_contraente = T3.id_contraente LEFT OUTER JOIN _tipo_sinistro T4 ON T1.id_tipo_sinistro = T4.id_tipo_sinistro LEFT OUTER JOIN ELEMENTITBSTD T5 ON T5.COD_TAB = 'stati_sinistro' AND T1.id_stato = T5.COD_ELE WHERE UPPER(REPLACE(luo_ind, ' ', '')) = (SELECT UPPER(REPLACE(luo_ind, ' ', '')) FROM acsinistri WHERE id_sinistro = ${idSinistro}) AND UPPER(REPLACE(luo_cit, ' ', '')) = (SELECT UPPER(REPLACE(luo_cit, ' ', '')) FROM acsinistri WHERE id_sinistro = ${idSinistro}) AND UPPER(REPLACE(luo_pro, ' ', '')) = (SELECT UPPER(REPLACE(luo_pro, ' ', '')) FROM acsinistri WHERE id_sinistro = ${idSinistro}) AND UPPER(REPLACE(luo_cap, ' ', '')) = (SELECT UPPER(REPLACE(luo_cap, ' ', '')) FROM acsinistri WHERE id_sinistro = ${idSinistro}) AND id_sinistro != ${idSinistro};`
                document.getElementById('btnImportaSinistri').style.display = 'none';
                document.getElementById('accordionFiltriAggiuntivi').style.display = 'none';
                document.getElementById('contentGridSituazSinistri').style.marginTop = '0px';
                document.getElementById('contentGridSituazSinistri').style.marginLeft = '0px';
            }
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecLuogo, '', retDataLuogo => {
                retDataLuogo = JSON.parse(retDataLuogo.data);
                if (this.props.isPeritoUtil === 'Perito') {
                    this.gridInstancePerito.dataSource = retDataLuogo;
                    setTimeout(() => {
                        this.gridInstancePerito.recordDoubleClick = false;
                    }, 200)
                }
                else {
                    this.gridInstance.dataSource = retDataLuogo;
                    setTimeout(() => {
                        this.gridInstance.recordDoubleClick = false;
                    }, 200)
                }
                this.toggleBlocking();
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            });
        }
        else {
            this.toggleBlocking();
            document.getElementById('ddlFiltriContent').style.removeProperty('display');
            document.getElementById('sfondoGrigio').style.removeProperty('display');
            document.getElementById('containerSituaSinistri').style.marginLeft = '-65px';
            document.getElementById('blockUIPaginaSituazioneSinistri').style.marginLeft = '64px';
            let ddlInstance = '';
            let tipoUtente = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 9 ? 'perito'
                : JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 1 ? 'segreteria' : ''

            if (tipoUtente === 'perito') {
                ddlInstance = this.ddlInstance2.value;
                document.getElementById('contentGridSituazSinistriPerito').className = 'col-lg-10 col-md-10 col-sm-10 col-xs-10';
                document.getElementById('gridPerito').style.marginTop = '120px';
                document.getElementById('gridPerito').style.marginLeft = '80px';
            }
            else if (tipoUtente === 'segreteria') {
                ddlInstance = this.ddlInstance1.value;
                document.getElementById('btnImportaSinistri').style.removeProperty('display');
                document.getElementById('accordionFiltriAggiuntivi').style.removeProperty('display');
                document.getElementById('contentGridSituazSinistri').style.marginTop = '15px';
                document.getElementById('contentGridSituazSinistri').style.marginLeft = '70px';
            }
            let idPerito = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 9 ? JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user : 0;
            CustomJS.callAPI(`AccessoDatiSIPAEVO/SituazioneSinistri/${tipoUtente}/${ddlInstance}/${idPerito}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                console.log(JSON.parse(retData.data))
                if (retData.response === 'OK') {
                    if (tipoUtente === 'perito') {
                        this.gridInstancePerito.dataSource = JSON.parse(retData.data);
                    }
                    else if (tipoUtente === 'segreteria') {
                        this.gridInstance.dataSource = JSON.parse(retData.data);
                    }
                }
                else if (retData.response === 'KO') {
                    toast.warn(retData.msg === 'UtenteNonTrovato' ? CustomJS.messaggioUtenteNonTrovato : CustomJS.messaggioErroreNoDBSIPA, {
                        onClose: () => toast.dismiss(),
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }
                this.toggleBlocking();
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
            }, true, false)
        }
    }
    emettiNotifiche(IdSinistro, NumSinistroComp, vediNessunaNotifica, IdCompagnia) {
        let QueryExec = `SELECT 1 AS TipoNotifica, T1.guid_evento, T1.esito_evento, T1.data_evento, T1.risposta_evento, T3.DESCRIZ AS DescrEventoRiferimento FROM UNIPOLSAI_Eventi T1 INNER JOIN UNIPOLSAI_ListaIncarichi T2 ON T1.id_incarico = T2.id_incarico LEFT OUTER JOIN ELEMENTITBSTD T3 ON T3.COD_TAB = 'UNI_evento' AND T3.COD_ELE = T1.tipo_evento_riferimento WHERE T2.id_sinistro = ${IdSinistro}`;
        if (!CustomJS.isNullEmpty(NumSinistroComp))
            QueryExec += ` UNION SELECT 2 AS TipoNotifica, '', '', '', CONCAT('ID Sinistro: ', T1.id_sinistro, ' - ', 'Perito: ', T2.denom, ' - ', 'Stato: ', IF(ISNULL(T1.data_chi), 'Aperto', 'Chiuso')) AS risposta_evento, '' FROM acsinistri T1 LEFT OUTER JOIN _user T2 ON T1.id_perito = T2.id_user WHERE T1.id_sinistro != ${IdSinistro} AND T1.sin_num LIKE CONCAT('%', '${NumSinistroComp}', '%')`;
        QueryExec += ` UNION SELECT 3 AS TipoNotifica, T1.guid_evento, T1.esito_evento, T1.data_evento, T1.risposta_evento, CASE WHEN T1.tipo_evento = 'CHIUSDANN' THEN 'Chiusura Danneggiato' WHEN T1.tipo_evento = 'CHIUSINC' THEN 'Chiusura Incarico' WHEN T1.tipo_evento = 'INVIOALLEG' THEN 'Invio Allegato' END AS DescrEventoRiferimento FROM GENERALI_Eventi T1 INNER JOIN GENERALI_Incarico T2 ON T1.id_incarico = T2.id_incarico WHERE T2.id_sinistro_xpert = ${IdSinistro} ORDER BY 4 DESC`;
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
            retData = JSON.parse(retData.data);
            this.setState({ NumSinistro: IdSinistro });
            if (retData.length > 0) {
                let elencoNotifiche = [];
                retData.forEach(elm => {
                    if (elm.TipoNotifica === 1) {
                        const notifyColor = elm.esito_evento === 'OK' ? 'Chartreuse' : 'Red';
                        elencoNotifiche.push(<div className='notibox' id={elm.guid_evento}><span style={{ fontWeight: 700, textDecorationLine: 'underline' }}>Messaggio da UnipolSAI del {moment(elm.data_evento).format('DD/MM/YYYY HH:mm')}:</span><br /><span style={{ fontWeight: 500 }}>[Evento: {elm.DescrEventoRiferimento}]</span><br /><span style={{ fontWeight: 700, color: notifyColor }}>{elm.risposta_evento}</span><div className='cancelNotibox' title='Cancella Notifica'>✕</div></div>);
                    }
                    else if (elm.TipoNotifica === 2) {
                        elencoNotifiche.push(<div className='notibox'><span style={{ fontWeight: 700, textDecorationLine: 'underline' }}>Presenza altro sinistro Compagnia uguale:</span><br /><br /><span style={{ fontWeight: 700, color: 'cyan' }}>{elm.risposta_evento}</span></div>);
                    }
                    else if (elm.TipoNotifica === 3) {
                        let notifyColor = elm.esito_evento === 'OK' ? 'Chartreuse' : 'Red';
                        elencoNotifiche.push(<div className='notibox' id={elm.guid_evento}><span style={{ fontWeight: 700, textDecorationLine: 'underline' }}>Messaggio da GENERALI del {moment(elm.data_evento).format('DD/MM/YYYY HH:mm')}:</span><br /><span style={{ fontWeight: 500 }}>[Evento: {elm.DescrEventoRiferimento}]</span><br /><span style={{ fontWeight: 700, color: notifyColor }}>{elm.risposta_evento}</span><div className='cancelNotibox' title='Cancella Notifica'>✕</div></div>);
                    }
                });
                let container = document.getElementById('elencoNotifiche');
                let root = createRoot(container);
                root.unmount();
                setTimeout(() => {
                    container = document.getElementById('elencoNotifiche');
                    root = createRoot(container);
                    root.render(<CallbackWrapper callback={() => {
                        $(".sidebarNotifiche").toggleClass('active');
                        $(".cancelNotibox").on('click', function (e) {
                            $(this).parent().toggleClass('goneNotibox');
                            let jsonObject = {
                                QueryExec: IdCompagnia.toString() === CustomJS.codiceCompagniaUnipolSAI ? `DELETE FROM UNIPOLSAI_Eventi WHERE guid_evento = @guidEvento; SELECT COUNT(*) FROM UNIPOLSAI_Eventi T1 INNER JOIN UNIPOLSAI_ListaIncarichi T2 ON T1.id_incarico = T2.id_incarico WHERE T2.id_sinistro = ${IdSinistro}` : IdCompagnia.toString() === CustomJS.codiceCompagniaGenerali ? `DELETE FROM GENERALI_Eventi WHERE guid_evento = @guidEvento; SELECT COUNT(*) FROM GENERALI_Eventi T1 INNER JOIN GENERALI_Incarico T2 ON T1.id_incarico = T2.id_incarico WHERE T2.id_sinistro_xpert = ${IdSinistro}` : '',
                                guidEvento: $(this).parent().attr('id'),
                                CodiceRitorno: 'true',
                            };
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
                                if (retData.response === 'OK') {
                                    toast.success('Notifica cancellata con successo!!', {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                    if (parseInt(retData.data) === 0)
                                        $(".sidebarNotifiche").toggleClass('active');
                                    setTimeout(() => {
                                        document.getElementById('aggiornatabella').click();
                                    }, 500);
                                }
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                }
                                );
                            });
                        });
                        $('.chiudiNotifiche').off();
                        $('.chiudiNotifiche').on('click', function (e) {
                            $(".sidebarNotifiche").toggleClass('active');
                        });
                        $('body,html').off('click', function (e) {
                            $('.sidebarNotifiche').removeClass('active');
                        });
                        $('body,html').on('click', function (e) {
                            $('.sidebarNotifiche').removeClass('active');
                        });
                    }}>{elencoNotifiche}</CallbackWrapper>);
                }, 100);
            }
            else if (vediNessunaNotifica) {
                let container = document.getElementById('elencoNotifiche');
                let root = createRoot(container);
                root.unmount();
                setTimeout(() => {
                    container = document.getElementById('elencoNotifiche');
                    root = createRoot(container);
                    root.render(<CallbackWrapper callback={() => {
                        $(".sidebarNotifiche").toggleClass('active');
                        $(".chiudiNotifiche").off();
                        $(".chiudiNotifiche").on('click', function (e) {
                            $(".sidebarNotifiche").toggleClass('active');
                        });
                        $('body,html').off('click', function (e) {
                            $('.sidebarNotifiche').removeClass('active');
                        });
                        $('body,html').on('click', function (e) {
                            $('.sidebarNotifiche').removeClass('active');
                        });
                    }}><div style={{ textAlign: 'center' }}><span style={{ fontWeight: 700, fontSize: '1.4em', color: 'whitesmoke' }}>Nessuna notifica presente</span></div></CallbackWrapper>);
                }, 100);
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }
    onMinimizeDialogOpen(titolo, widthMinimize, minPosY, initMinHeight, dialogInstance) {
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-restore")?.classList.remove('fa-window-restore');
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize").setAttribute('title', 'Minimizza Finestra');
        dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
        dialogInstance.position = { X: 'center', Y: '70' };
        dialogInstance.allowDragging = true;

        document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
        document.getElementById(dialogInstance.id).style.height = initMinHeight;

        this.minBtn = document.getElementById(`min-btn-${dialogInstance.id}`);
        this.minBtn.onclick = (e) => {
            let minimizeIcon = dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize");
            if (!dialogInstance.element.classList.contains('e-dlg-fullscreen')) {
                if (!dialogInstance.element.classList.contains('dialog-minimized')) {
                    this.dialogOldPositions = { X: dialogInstance.position.X, Y: dialogInstance.position.Y };
                    this.dialogOldWidth = dialogInstance.width;
                    dialogInstance.element.classList.add('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.add('hide-content');
                    dialogInstance.position = { X: 'right', Y: minPosY };
                    dialogInstance.width = widthMinimize;
                    document.getElementById(dialogInstance.id).style.minHeight = '3%';
                    dialogInstance.height = '100px';
                    //dialogInstance.minHeight = '100px';
                    dialogInstance.dataBind();
                    minimizeIcon.classList.add('fa-window-restore');
                    minimizeIcon.setAttribute('title', 'Ripristina Finestra');
                }
                else {
                    dialogInstance.element.classList.remove('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.remove('hide-content');
                    minimizeIcon.classList.add('fa-window-minimize');
                    minimizeIcon.setAttribute('title', 'Minimizza Finestra');
                    minimizeIcon.classList.remove('fa-window-restore');
                    dialogInstance.position = this.dialogOldPositions;
                    dialogInstance.width = this.dialogOldWidth;
                    document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
                    // document.getElementById(`dialog-title-${dialogInstance.id}`).innerHTML = titolo;
                    dialogInstance.dataBind();
                }
            }
            else {
                dialogInstance.show(false);
                dialogInstance.element.classList.remove('dialog-maximized');
                dialogInstance.element.classList.add('dialog-minimized');
                dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                minimizeIcon.classList.remove('fa-window-minimize');
                minimizeIcon.removeAttribute('title');
                dialogInstance.position = { X: 'center', Y: 'bottom' };
                dialogInstance.dataBind();
            }
        };
    }
    headerDialog(dialogInstance, titolo) {
        return <>
            <div className='row'>
                <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10 text-start" >
                    <h5>{titolo}</h5>
                </div>
                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 text-end" style={{ float: 'right' }}>
                    <span class='fas fa-window-minimize' style={{ float: 'right', cursor: 'pointer' }} id={`min-btn-${dialogInstance.id}`}
                        title='Minimizza la finestra'></span>
                </div>
                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 text-end" style={{ float: 'right' }}>
                    <span class='fas fa-window-maximize' style={{ float: 'right', cursor: 'pointer' }} id={`max-btn-${dialogInstance.id}`}
                        title='Massimizza la finestra'></span>
                </div>
            </div>
        </>
    }
}


