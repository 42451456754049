/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { RichTextEditorComponent, Toolbar as RTEtoolbar, Inject as RTEInject, Image, Link, HtmlEditor, Count, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent, CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

import { PdfViewerComponent, Toolbar as PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject as PDFVInject } from '@syncfusion/ej2-react-pdfviewer';

import { Browser, getValue } from '@syncfusion/ej2-base';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';
import PaginaDettaglioSinistro from './PaginaDettaglioSinistro';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import moment from 'moment';
import $ from 'jquery';


function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate2(props) {
    return (
        <div><span style={{ fontSize: '0.7vw', fontWeight: 'bolder', fontFamily: 'Muli, sans-serif' }}>{props[props.column.field]}</span></div>
    );
}

export default class PaginaSituazioneSinistriCorrezione extends SampleBase {
    constructor() {
        super(...arguments);
        this.childKey = 0;
        //gestione componenti
        this.RTEitems = ['Bold', 'Italic', 'Underline',
            'FontName', 'FontSize',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'CreateLink', 'Print', '|', 'Undo', 'Redo',
            'FontColor'
        ];
        this.RTEtoolbarSettings = {
            items: this.RTEitems
        };
        this.fontColor = {
            modeSwitcher: true
        };
        //this.dialogAnimationSettings = { effect: 'FadeIn' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.filterSettings = { type: 'Excel' };
        this.toolbarOptionsCorrettore = ['Search']
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        this.lightboxImage = '';
        //CONTROLLO TIPO UTENTE
        this.tipoUtente = '';
        let profilo = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo;
        let isReferente = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].ck_referente;
        if (profilo === 9) {
            if (isReferente === 1 || isReferente === true)
                this.tipoUtente = 'correttore_cr'
            else
                this.tipoUtente = 'perito'
        }
        else if (profilo === 8) {
            this.tipoUtente = 'correttore_cq'
        }
        else if (profilo === 1) {
            this.tipoUtente = 'segreteria'
        }
        this.idUser = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;
        //command click
        this.commandsInCorrezione = [{ type: "Elimina da correggere", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } }];
        this.commandsAllegati = [{ type: "Visualizza Documento", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" } }, { type: "Scarica Documento", buttonOption: { iconCss: "fas fa-download fa-10x", cssClass: "e-details" } }];
        //gestione data per i salvataggi nella log action
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var todayContrario = yyyy + '-' + mm + '-' + dd;
        this.dataDiOggiContrario = todayContrario;
        //DATASOURCE
        this.dsErroriCorrezione = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabellaaccorrezioni_tipi')).map(elm => { return { num: elm.id_tipo, errore: elm.descrizione, ko: elm.ko, colore: elm.ko === 1 ? 'red' : '', malus: elm.malus + '%', attivo: elm.attivo, idUpdate: 0 } });

    }
    state = {
        blocking: false,
        showDialog1: false,
        showLightbox: false,

    }

    //----------------------------FUNZIONI DI UTILITY INIZIO----------------------------

    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toggleBlocking2(show) {
        let container, root;
        if (show) {
            document.getElementById('blockUIGenerico').style.removeProperty('display');
            document.getElementById('spinnerUIGenerico').style.removeProperty('display');
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
        }
        else {
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.unmount();
            document.getElementById('blockUIGenerico').style.display = 'none';
            document.getElementById('spinnerUIGenerico').style.display = 'none';
        }
    }

    gestioneLog(query) {
        var jsonObject = {
            QueryExec: query,
        }
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
    }

    headerDialog(dialogInstance, titolo) {
        return <>
            <div className='row'>
                <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10 text-start" >
                    <h5>{titolo}</h5>
                </div>
                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 text-end" style={{ float: 'right' }}>
                    <span class='fas fa-window-minimize' style={{ float: 'right', cursor: 'pointer' }} id={`min-btn-${dialogInstance.id}`}
                        title='Minimizza la finestra'></span>
                </div>
                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 text-end" style={{ float: 'right' }}>
                    <span class='fas fa-window-maximize' style={{ float: 'right', cursor: 'pointer' }} id={`max-btn-${dialogInstance.id}`}
                        title='Massimizza la finestra'></span>
                </div>
            </div>
        </>
    }

    //---------------------------MINIMIZE E MAZIMIZE INIZIO---------------------------

    onMaximizeDialogOpen(titolo, widthMaximize, minPosY, initMinHeight, dialogInstance) {
        widthMaximize = '100%'
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-restore")?.classList.remove('fa-window-restore');
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-maximize").setAttribute('title', 'Massimizza Finestra');
        dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
        dialogInstance.position = { X: 'center', Y: '70' };
        dialogInstance.allowDragging = false;

        document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
        document.getElementById(dialogInstance.id).style.height = initMinHeight;

        this.maxBtn = document.getElementById(`max-btn-${dialogInstance.id}`);
        this.maxBtn.onclick = (e) => {
            let minimizeIcon = dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-maximize");
            if (!dialogInstance.element.classList.contains('e-dlg-fullscreen')) {
                if (!dialogInstance.element.classList.contains('dialog-maximize')) {
                    this.dialogOldPositions2 = { X: dialogInstance.position.X, Y: dialogInstance.position.Y };
                    this.dialogOldWidth2 = dialogInstance.width;
                    dialogInstance.element.classList.add('dialog-maximize');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.add('hide-content');
                    dialogInstance.position = { X: 'right', Y: minPosY };
                    dialogInstance.width = widthMaximize;
                    document.getElementById(dialogInstance.id).style.minHeight = '100%';
                    dialogInstance.height = '100%';
                    //dialogInstance.minHeight = '100px';
                    dialogInstance.dataBind();
                    minimizeIcon.classList.add('fa-window-restore');
                    minimizeIcon.setAttribute('title', 'Ripristina Finestra');
                }
                else {
                    dialogInstance.element.classList.remove('dialog-maximize');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.remove('hide-content');
                    minimizeIcon.classList.add('fa-window-maximize');
                    minimizeIcon.setAttribute('title', 'Massimizza Finestra');
                    minimizeIcon.classList.remove('fa-window-restore');
                    dialogInstance.position = this.dialogOldPositions2;
                    dialogInstance.width = this.dialogOldWidth2;
                    document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
                    // document.getElementById(`dialog-title-${dialogInstance.id}`).innerHTML = titolo;
                    dialogInstance.dataBind();
                }
            }
            else {
                dialogInstance.show(false);
                dialogInstance.element.classList.remove('dialog-maximized');
                dialogInstance.element.classList.add('dialog-minimized');
                dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                minimizeIcon.classList.remove('fa-window-minimize');
                minimizeIcon.removeAttribute('title');
                dialogInstance.position = { X: 'center', Y: 'bottom' };
                dialogInstance.dataBind();
            }
        };
    }

    onMinimizeDialogOpen(titolo, widthMinimize, minPosY, initMinHeight, dialogInstance) {
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-restore")?.classList.remove('fa-window-restore');
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize").setAttribute('title', 'Minimizza Finestra');
        dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
        dialogInstance.position = { X: 'center', Y: '70' };
        dialogInstance.allowDragging = true;

        document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
        document.getElementById(dialogInstance.id).style.height = initMinHeight;

        this.minBtn = document.getElementById(`min-btn-${dialogInstance.id}`);
        this.minBtn.onclick = (e) => {
            let minimizeIcon = dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize");
            if (!dialogInstance.element.classList.contains('e-dlg-fullscreen')) {
                if (!dialogInstance.element.classList.contains('dialog-minimized')) {
                    this.dialogOldPositions = { X: dialogInstance.position.X, Y: dialogInstance.position.Y };
                    this.dialogOldWidth = dialogInstance.width;
                    dialogInstance.element.classList.add('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.add('hide-content');
                    dialogInstance.position = { X: 'right', Y: minPosY };
                    dialogInstance.width = widthMinimize;
                    document.getElementById(dialogInstance.id).style.minHeight = '3%';
                    dialogInstance.height = '100px';
                    //dialogInstance.minHeight = '100px';
                    dialogInstance.dataBind();
                    minimizeIcon.classList.add('fa-window-restore');
                    minimizeIcon.setAttribute('title', 'Ripristina Finestra');
                }
                else {
                    dialogInstance.element.classList.remove('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.remove('hide-content');
                    minimizeIcon.classList.add('fa-window-minimize');
                    minimizeIcon.setAttribute('title', 'Minimizza Finestra');
                    minimizeIcon.classList.remove('fa-window-restore');
                    dialogInstance.position = this.dialogOldPositions;
                    dialogInstance.width = this.dialogOldWidth;
                    document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
                    // document.getElementById(`dialog-title-${dialogInstance.id}`).innerHTML = titolo;
                    dialogInstance.dataBind();
                }
            }
            else {
                dialogInstance.show(false);
                dialogInstance.element.classList.remove('dialog-maximized');
                dialogInstance.element.classList.add('dialog-minimized');
                dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                minimizeIcon.classList.remove('fa-window-minimize');
                minimizeIcon.removeAttribute('title');
                dialogInstance.position = { X: 'center', Y: 'bottom' };
                dialogInstance.dataBind();
            }
        };
    }
    //---------------------------MINIMIZE E MAZIMIZE FINE---------------------------

    //----------------------------FUNZIONI DI UTILITY FINE----------------------------


    //----------------------------COMMAND CLICK INIZIO----------------------------
    onCommandClickInCorrezione(args) {
        //ELIMINA SINISTRO IN CORREZIONE
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-trash') > -1) {
            this.dialogInstance2.header = ``;
            this.dialogInstance2.width = '30%';
            this.dialogInstance2.height = '25%';
            this.dialogInstance2.minHeight = '25%';
            this.dialogInstance2.allowDragging = true;
            this.dialogInstance2.showCloseIcon = true;
            this.dialogInstance2.buttons = [
                {
                    click: () => {
                        let jsonObject = {}
                        if (this.tipoUtente === 'correttore_cr') {
                            jsonObject = {
                                QueryExec: `update _log_correzione set data_inizio = @dataInizio, note_correzione = '' where id = ${args.rowData.idLogCorrezione}`,
                                dataInizio: '0000-00-00 00:00:00'
                            };
                        }
                        else if (this.tipoUtente === 'correttore_cq') {
                            jsonObject = {
                                QueryExec: `update _log_correzione set data_inizio = @dataInizio, id_correttore = 0,id_correttore_prec = 0, note_correzione = '' where id = ${args.rowData.idLogCorrezione}`,
                                dataInizio: '0000-00-00 00:00:00'
                            };
                        }

                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
                            this.caricaDati();
                            //inserisci action log
                            this.gestioneLog(`INSERT INTO _action_log (sigla_studio, id_user, action_data, action, note, param_1, param_2, param_3, action_datatime_xpert) VALUES ('ac', ${args.rowData.id_perito}, '${this.dataDiOggiContrario}', 'Annulla Correzione', 'Documento di perizia annullato dalla correzione da ${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].denom}', ${args.rowData.id_sinistro}, 0, 0, CURRENT_TIMESTAMP)`);
                            //aggiorno i badge

                            toast.success("Documento di perizia rimosso dalle correzioni in carico!", {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            }
                            );
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            }
                            );
                        });
                        this.dialogInstance2.hide();
                    },
                    buttonModel: {
                        content: 'ELIMINA',
                        cssClass: 'e-danger',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance2.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA'
                    }
                }
            ]
            this.dialogInstance2.beforeOpen = (args) => {
                const container = document.getElementById('dialogCorrezioneContent2');
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogInstance2.open = (args) => {
                const container = document.getElementById('dialogCorrezioneContent2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {

                }}><><div class="row">
                    <div class="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <h3 class="text-center text-danger">
                            <i class='fas fa-exclamation-triangle'></i>
                        </h3>
                        <p class="text-center" style={{ fontSize: '15px' }}>
                            Vuoi eliminare questo documento dai tuoi sinistri in correzione ?
                        </p>
                    </div>
                </div></></CallbackWrapper>);
            };
            this.dialogInstance2.show();
        }
    }

    rowDataBoundAllegati(args) {
        let estensione = (args.data.nomefile).slice(-3);
        if (estensione === 'zip' || estensione === 'rar') {
            $($(args.row).find('button')[0]).hide(); //visualizza
        }
    }

    onCommandClickAllegati(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {
            if (args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'JPEG'
                || args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'JPG'
                || args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'PNG'
                || args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'TIF'
                || args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'TIFF'
                || args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'GIF'
                || args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'JFIF') {
                this.toggleBlocking();
                CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/ACE/[NO]/[NO]/${args.rowData.id_allegato}/[NO]'}`, {}, 'POST',
                    'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                        this.lightboxImage = `data:image/jpg;base64,${retData.data}`;
                        this.setState({ showLightbox: true });
                        setTimeout(() => {
                            document.querySelector('#lightboxSituaSin > div').style.zIndex = 999999999999999;;
                        }, 350)
                        this.toggleBlocking();
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking();
                    }, true, false);
            }
            if (args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'PDF') {
                this.toggleBlocking();
                if (this.dialogAllegatiPdf.visible === true)
                    this.dialogAllegatiPdf.hide();
                CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/ACE/[NO]/[NO]/${args.rowData.id_allegato}/[NO]'}`, {}, 'POST',
                    'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                        this.dialogAllegatiPdf.header = `<div id="header-dialog-${this.dialogAllegatiPdf.id}"></div>`;
                        this.dialogAllegatiPdf.width = '65%';
                        this.dialogAllegatiPdf.height = '90%';
                        this.dialogAllegatiPdf.enableResize = true;
                        this.dialogAllegatiPdf.resizeHandles = ['All'];
                        this.dialogAllegatiPdf.minHeight = '3%';
                        this.dialogAllegatiPdf.allowDragging = true;
                        this.dialogAllegatiPdf.buttons = [];
                        this.dialogAllegatiPdf.beforeOpen = (args) => {
                            const container = document.getElementById('dialogCorrezioneAllegatiPdfContent');
                            const root = createRoot(container);
                            root.unmount();
                        };
                        this.dialogAllegatiPdf.open = (args) => {
                            //header
                            const containerHeader = document.getElementById(`header-dialog-${this.dialogAllegatiPdf.id}`);
                            const rootHeader = createRoot(containerHeader);
                            rootHeader.render(<CallbackWrapper callback={() => {
                                this.onMinimizeDialogOpen(`VEDI ALLEGATO PDF`, '350px', '200', '90%', this.dialogAllegatiPdf);
                                this.onMaximizeDialogOpen(`VEDI ALLEGATO PDF`, '', '0', '90%', this.dialogAllegatiPdf);
                                const container = document.getElementById('dialogCorrezioneAllegatiPdfContent');
                                const root = createRoot(container);
                                root.render(<CallbackWrapper callback={() => {
                                    document.getElementById('PDFViewerAllegati').ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                                    //document.getElementById(`PDFViewerDettSin`).ej2_instances[0].dataBind(); //NON SEMBRA SERVIRE
                                    setTimeout(() => {
                                        document.getElementById('PDFViewerAllegati').ej2_instances[0].load(`data:application/pdf;base64,${retData.data}`, null);
                                        this.toggleBlocking();
                                        CustomJS.scrollAuto(500, 0, false, true);
                                    }, 250);
                                }}>{this.PDFViewerRenderAllegati()}</CallbackWrapper>);
                            }}>{this.headerDialog(this.dialogAllegatiPdf, "VEDI ALLEGATO PDF")}</CallbackWrapper>);
                        };
                        this.dialogAllegatiPdf.show();
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking();
                    }, true, false);
            }
        }
        else if (args.commandColumn.buttonOption.iconCss.indexOf('fa-download') > -1) {
            if (args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'PDF' ||
                args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'ZIP' ||
                args.rowData.nomefile.substring(args.rowData.nomefile.lastIndexOf('.') + 1).toUpperCase() === 'RAR') {
                this.toggleBlocking();
                CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/ACE/[NO]/[NO]/${args.rowData.id_allegato}/[NO]'}`, {}, 'POST',
                    'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                        this.toggleBlocking();
                        let element = document.createElement('a');
                        element.setAttribute('href', `data:text/plain;base64,${retData.data}`);
                        element.setAttribute('download', args.rowData.nomefile);
                        element.style.display = 'none';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking();
                    }, true, false);
            }
        }
    }

    //----------------------------COMMAND CLICK FINE----------------------------


    //----------------------------RENDER PAGINA INIZIO----------------------------
    render() {
        return (<React.Fragment>
            <div id='blockUIGenerico' style={{ display: 'none' }}></div>
            <div id='spinnerUIGenerico' style={{ display: 'none' }} role="status"></div>
            <div id='lightboxSituaSin'>{this.state.showLightbox ? <Lightbox image={this.lightboxImage} onClose={() => this.setState({ showLightbox: false })}></Lightbox> : null}</div>
            <BlockUi id='blockUIPaginaSituazioneSinistriCorrezione' style={{ marginTop: '-50px', marginLeft: '64px', overflowX: 'hidden' }} tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="dialogCorrezione" showCloseIcon={true} cssClass='dialog-sipa' target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance1 = dialog} isModal={true}>
                    <div id='dialogCorrezioneContent'></div>
                </DialogComponent>
                <DialogComponent id="dialogCorrezione2" showCloseIcon={true} cssClass='dialog-sipa' target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance2 = dialog} isModal={true}>
                    <div id='dialogCorrezioneContent2'></div>
                </DialogComponent>
                <DialogComponent id="dialogCorrezione3" showCloseIcon={true} cssClass='dialog-sipa' target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance3 = dialog} isModal={true}>
                    <div id='dialogCorrezioneContent3'></div>
                </DialogComponent>
                <DialogComponent id="dialogCorrezioneAllegatiPdf" showCloseIcon={true} cssClass='dialog-sipa' target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogAllegatiPdf = dialog} isModal={true}>
                    <div id='dialogCorrezioneAllegatiPdfContent'></div>
                </DialogComponent>
                <div style={{ marginLeft: '-65px' }} className='container-fluid'>
                    <div id='gridCorrettore' style={{ marginTop: '120px', marginLeft: '80px' }}>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <AccordionComponent style={{ marginTop: '50px' }} id="accordionGridCorrettori" ref={acrdn => this.acrdnInstanceCorrettori = acrdn} created={this.onCreatedAccordCorrezione.bind(this)}  >
                                    <AccordionItemsDirective >
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline accordionCorrettore' header='<h5>DA CORREGGERE</h5>' expanded={true} content={this.accordContent0} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline accordionCorrettore' header='<h5>IN CORREZIONE</h5>' expanded={true} content={this.accordContent1} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline accordionCorrettore' header='<h5>CORRETTI</h5>' expanded={true} content={this.accordContent2} />
                                        <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline accordionCorrettore' header='<h5>DATI CONTROLLO QUALITA</h5>' expanded={true} content={this.accordContent3} />
                                    </AccordionItemsDirective>
                                </AccordionComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    onCreatedAccordCorrezione(args) {
        let container = document.getElementById('contenutoAccordDaCorreggere');
        let root = createRoot(container);
        root.render(this.contentDaCorreggereRender());
        container = document.getElementById('contenutoAccordInCorrezione');
        root = createRoot(container);
        root.render(this.contentInCorrezioneRender());
        container = document.getElementById('contenutoAccordCorretti');
        root = createRoot(container);
        root.render(this.contentCorrettiRender());
        container = document.getElementById('contenutoAccordContrQualita');
        root = createRoot(container);
        root.render(this.contentContrQualitaRender());
    }
    accordContent0(args) {
        return (<React.Fragment>
            <div id='contenutoAccordDaCorreggere'></div>
        </React.Fragment>);
    }
    contentDaCorreggereRender() {
        return <>
            {/* DA CORREGGERE */}
            <GridComponent id="GridSitSinDaCorreggere" recordDoubleClick={this.caricaDocPerizia.bind(this, 'da_correggere')} ref={grid => this.gridInstanceDaCorreggere = grid} hierarchyPrintMode='All'
                toolbar={this.toolbarOptionsCorrettore} allowSelection={true} enableHover={true} showColumnMenu={false} showColumnChooser={false} allowTextWrap={true} allowPaging={true} allowSorting={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings}
                allowGrouping={true} style={{ border: '0px' }} filterSettings={this.filterSettings} allowFiltering={true}>
                <ColumnsDirective>
                    <ColumnDirective field='id_sinistro' headerText='ID' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate2} clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='perito' headerText="Perito" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='data_invio_in_correzione' headerText="Inviato il" type='datetime' format='dd/MM/yyyy' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='compagnia' headerText="Compagnia" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='sin_num' headerText="Numero Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='tipo_sinistro' headerText="Tipo Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    {/* rami elementari responsabilità civile */}
                    <ColumnDirective field='re_rc' headerText="RE/RC" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='referente' headerText="Referente Tecnico" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='grado' headerText="Grado" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    {/* <ColumnDirective field='pagine' headerText="Pagine" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective> */}
                    <ColumnDirective field='stato' headerText="Stato" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='correttore' headerText="Già visto da" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
            </GridComponent>
        </>
    }
    accordContent1(args) {
        return (<React.Fragment>
            <div id='contenutoAccordInCorrezione'></div>
        </React.Fragment>);
    }
    contentInCorrezioneRender() {
        return <>
            {/* CORREZIONE */}
            <GridComponent id="GridSitSinInCorrezione" ref={grid => this.gridInstanceInCorrezione = grid} hierarchyPrintMode='All'
                toolbar={this.toolbarOptionsCorrettore} allowSelection={true} enableHover={true} showColumnMenu={false} showColumnChooser={false} allowTextWrap={true} allowPaging={true} enablePersistence={false} pageSettings={this.pageSettings}
                style={{ border: '0px' }} recordDoubleClick={this.caricaDocPerizia.bind(this, 'in_correzione')}
                commandClick={this.onCommandClickInCorrezione.bind(this)} filterSettings={this.filterSettings} allowFiltering={true}>
                <ColumnsDirective>
                    <ColumnDirective field='id_sinistro' headerText='ID' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate2} clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='perito' headerText="Perito" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='data_invio_in_correzione' headerText="Inviato il" type='datetime' format='dd/MM/yyyy' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='compagnia' headerText="Compagnia" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='sin_num' headerText="Numero Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='tipo_sinistro' headerText="Tipo Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='re_rc' headerText="RE/RC" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='referente' headerText="Referente Tecnico" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='grado' headerText="Grado" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='pagine' headerText="Pagine" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='stato' headerText="Stato" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='correttore' headerText="Già visto da" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='command' commands={this.commandsInCorrezione} headerText="Elimina" width='5%' headerTemplate={headerTemplate} textAlign='Center' allowFiltering={false} allowSorting={false} ></ColumnDirective>

                </ColumnsDirective>
                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
            </GridComponent>
        </>
    }
    accordContent2(args) {
        return (<React.Fragment>
            <div id='contenutoAccordCorretti'></div>
        </React.Fragment>);
    }
    contentCorrettiRender() {
        return <>
            {/* CORRETTI */}
            <GridComponent id="GridSitSinCorretti" ref={grid => this.gridInstanceCorretti = grid} hierarchyPrintMode='All'
                toolbar={this.toolbarOptionsCorrettore} allowSelection={true} enableHover={true} showColumnMenu={false} showColumnChooser={false} allowPaging={true} allowSorting={true} enablePersistence={false} pageSettings={this.pageSettings}
                style={{ border: '0px' }} recordDoubleClick={this.caricaDocPerizia.bind(this, 'corretti')} filterSettings={this.filterSettings} allowFiltering={true}>
                <ColumnsDirective>
                    <ColumnDirective field='id_sinistro' headerText='ID' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate2} clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='perito' headerText="Perito" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='data_invio_in_correzione' headerText="Inviato il" type='datetime' format='dd/MM/yyyy' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='compagnia' headerText="Compagnia" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='sin_num' headerText="Numero Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='tipo_sinistro' headerText="Tipo Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='re_rc' headerText="RE/RC" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='referente' headerText="Referente Tecnico" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='grado' headerText="Grado" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='pagine' headerText="Pagine" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='stato' headerText="Stato" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='correttore' headerText="Già visto da" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
            </GridComponent>
        </>
    }
    accordContent3(args) {
        return (<React.Fragment>
            <div id='contenutoAccordContrQualita'></div>
        </React.Fragment>);
    }
    contentContrQualitaRender() {
        return <>
            <GridComponent id="GridSitSinControlloQualita" ref={grid => this.gridInstanceControlloQualita = grid} hierarchyPrintMode='All'
                toolbar={this.toolbarOptionsCorrettore} allowSelection={true} enableHover={true} showColumnMenu={false} showColumnChooser={false} allowTextWrap={true} allowPaging={true} allowSorting={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings}
                allowGrouping={true} style={{ border: '0px' }}  >
                <ColumnsDirective>
                    <ColumnDirective field='id_sinistro' headerText='ID' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate2} clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='num_sinistri' headerText="N° Sinistri" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='num_correzioni' headerText="N° Correzioni" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='t_medio_incarico_anno' headerText="T Medio in carico Anno" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='t_medio_anno' headerText="T Medio Anno" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='t_medio_totale_anno' headerText="T Medio Totale Anno" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='t_max_totale_anno' headerText="T Max Totale Anno" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
            </GridComponent>
        </>
    }
    //----------------------------RENDER PAGINA FINE----------------------------

    //----------------------------INIZIALIZZAZIONE PAGINA INIZIO----------------------------
    componentDidMount() {
        setTimeout(() => {
            document.getElementById('titoloPagina').innerText = 'Gestione Correzioni';
            this.caricaDati();
        }, 50);
    }

    caricaDati() {
        this.toggleBlocking();
        //idUser = @id_correttore della chiamata al backand
        CustomJS.callAPI(`AccessoDatiSIPAEVO/SituazioneSinistriCorrezione/${this.tipoUtente}/${this.idUser}`, '', 'GET', '', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
            console.log(JSON.parse(retData.data))
            if (retData.response === 'OK') {
                var arrayData = JSON.parse(retData.data);
                this.acrdnInstanceCorrettori.expandItem(false, 3); //dati controllo qualità
                var dataSourceDaCorreggere = [];
                var dataSourceInCorrezione = [];
                var dataSourceCorretti = [];
                if (this.tipoUtente === 'correttore_cq') {
                    arrayData.forEach(elm => {
                        if ((elm.in_correzione === 1 || elm.in_correzione === true) &&
                            ((CustomJS.isNullEmpty(elm.id_correttore)) || elm.id_correttore === 0)
                            && elm.profilo === 8) {

                            dataSourceDaCorreggere.push(elm)
                        }
                    })
                    arrayData.forEach(elm => {
                        if (elm.id_correttore === JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user &&
                            (elm.in_correzione === 1 || elm.in_correzione === true)
                            && elm.profilo === 8) {

                            dataSourceInCorrezione.push(elm)
                        }
                    });
                    arrayData.forEach(elm => {
                        if ((elm.in_correzione === 0 || elm.in_correzione === false)
                            && elm.profilo === 8) {

                            dataSourceCorretti.push(elm)
                        }
                    });
                }
                if (this.tipoUtente === 'correttore_cr') {
                    arrayData.forEach(elm => {
                        if ((elm.in_correzione === 1 || elm.in_correzione === true)
                            && (elm.data_inizio === '0000-00-00 00:00:00' || CustomJS.isNullEmpty(elm.data_inizio))
                            && (elm.data_res ?? '0000-00-00') === '0000-00-00' && elm.profilo === 9) {

                            dataSourceDaCorreggere.push(elm)
                        }
                    })
                    arrayData.forEach(elm => {
                        if ((elm.in_correzione === 1 || elm.in_correzione === true)
                            && elm.data_inizio !== '0000-00-00 00:00:00' && !CustomJS.isNullEmpty(elm.data_inizio)
                            && (elm.data_res ?? '0000-00-00') === '0000-00-00' && elm.profilo === 9) {

                            dataSourceInCorrezione.push(elm)
                        }
                    });
                    arrayData.forEach(elm => {
                        if ((elm.in_correzione === 0 || elm.in_correzione === false)
                            && elm.profilo === 9) {

                            dataSourceCorretti.push(elm)
                        }
                    });
                }
                //cambio il datasource delle grid
                this.gridInstanceDaCorreggere.dataSource = dataSourceDaCorreggere; //da correggere
                this.gridInstanceInCorrezione.dataSource = dataSourceInCorrezione;//in correzione
                this.gridInstanceCorretti.dataSource = dataSourceCorretti;//corretti


                //aggiorno i badge
                document.getElementById('badgeDaCorreggere').innerText = dataSourceDaCorreggere.length;
                document.getElementById('badgeInCorrezione').innerText = dataSourceInCorrezione.length;
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.msg === 'UtenteNonTrovato' ? CustomJS.messaggioUtenteNonTrovato : CustomJS.messaggioErroreNoDBSIPA, {
                    onClose: () => toast.dismiss(),
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }
            this.toggleBlocking();
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking();
        }, true, false)
    }
    //----------------------------INIZIALIZZAZIONE PAGINA FINE----------------------------

    //----------------------------APRI DOCUMENTO DI CORREZIONE INIZIO----------------------------
    dialogCaricaDocumentoPerizia(tipoGrid, row, retDataBase64, nomeFile) {
        this.dialogInstance1.header = `<div id="header-dialog-${this.dialogInstance1.id}"></div>`
        this.dialogInstance1.width = '90%';
        this.dialogInstance1.height = '90%';
        this.dialogInstance1.enableResize = true;
        this.dialogInstance1.resizeHandles = ['All'];
        this.dialogInstance1.minHeight = '3%';
        if (tipoGrid === 'corretti') {
            this.dialogInstance1.buttons = [
                {
                    click: () => {
                        this.dialogInstance1.hide();
                    },
                    buttonModel: {
                        content: 'CHIUDI'
                    }
                }
            ]
        }
        else {
            this.dialogInstance1.buttons = [
                {
                    click: () => {
                        this.salvaDocPerizia();
                    },
                    buttonModel: {
                        content: 'SALVA DOCUMENTO',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance1.hide();
                    },
                    buttonModel: {
                        content: 'CHIUDI'
                    }
                }
            ]
        }
        this.dialogInstance1.beforeOpen = (args) => {
            const container = document.getElementById('dialogCorrezioneContent');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance1.open = (args) => {
            const containerHedaer = document.getElementById(`header-dialog-${this.dialogInstance1.id}`);
            const rootHeader = createRoot(containerHedaer);
            rootHeader.render(<CallbackWrapper callback={() => {
                const QueryExec = `SELECT T1.*, T2.denom AS user FROM acallegatisinistro T1 LEFT OUTER JOIN _user T2 ON T1.id_user = T2.id_user WHERE id_sinistro = '${row.rowData.id_sinistro}'`;
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                    let retDataObj = JSON.parse(retData.data);
                    let allegatiCorrezioni = []
                    if (this.tipoUtente === 'correttore_cq')
                        allegatiCorrezioni = retDataObj.filter(obj => (obj.nomefile.toUpperCase()).includes('CQ'));
                    else if (this.tipoUtente === 'correttore_cr')
                        allegatiCorrezioni = retDataObj.filter(obj => (obj.nomefile.toUpperCase()).includes('CR'));

                    //elimino l' allegato aperto cioè l' ultimo corretto 
                    allegatiCorrezioni.forEach((elm, idx, array) => {
                        if (elm.nomefile === nomeFile) {
                            array.splice(idx, 1);
                        }
                    })
                    //massimizza o minimizza
                    this.onMaximizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO PDF DEL SINISTRO ${row.rowData.id_sinistro}`, '', '0', '90%', this.dialogInstance1);
                    this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO PDF DEL SINISTRO ${row.rowData.id_sinistro}`, '500px', '100', '90%', this.dialogInstance1);
                    //pdf viewer
                    const container = document.getElementById('dialogCorrezioneContent');
                    const root = createRoot(container);
                    root.render(<CallbackWrapper callback={() => {
                        document.getElementById('PDFViewerCorrettore').ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                        //document.getElementById(`PDFViewerCorrettore`).ej2_instances[0].dataBind(); //NON SEMBRA SERVIRE
                        if (tipoGrid === 'corretti') {
                            document.getElementById('btnTerminaEConsegna').ej2_instances[0].disabled = true;
                        }
                        else {
                            document.getElementById('btnTerminaEConsegna').ej2_instances[0].disabled = false;
                        }
                        if (allegatiCorrezioni.length === 0) {
                            document.getElementById('btnCorrezioniPrecedenti').ej2_instances[0].disabled = true;
                        }
                        setTimeout(() => {
                            document.getElementById('PDFViewerCorrettore').ej2_instances[0].load(`data:application/pdf;base64,${retDataBase64}`, null);
                            CustomJS.scrollAuto(500, 0, false, true);
                            if (tipoGrid === 'da_correggere') {
                                this.dialogConfermaSinistroInCorrezione(row.rowData.id_sinistro, row.rowData.idLogCorrezione, row.rowData.id_perito);
                            }
                            this.toggleBlocking();
                        }, 250);
                    }}>{this.PDFViewerRender(row.rowData, tipoGrid, allegatiCorrezioni)}</CallbackWrapper>);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });


            }}>{this.headerDialog(this.dialogInstance1, `VISUALIZZAZIONE DOCUMENTO PDF  DEL SINISTRO ${row.rowData.id_sinistro}`)}</CallbackWrapper>);
        };
        this.dialogInstance1.show();
    }

    caricaDocPerizia(tipoGrid, row) {
        //CARICO IL DOCUMENTO DI PERIZIA
        var userCorrettore = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;
        if ((CustomJS.isNullEmpty(row.rowData.id_correttore) || row.rowData.id_correttore === 0) ||
            //questo ultimo controllo va bene anche per il cr
            (row.rowData.id_correttore === userCorrettore)) {
            this.toggleBlocking();
            //1) controllo se il documento è presente nella cartella temp quindi se è in correzione
            let nomeFile = ''
            if (this.tipoUtente === 'correttore_cq') {
                nomeFile = row.rowData.id_sinistro + "-" + row.rowData.num_versione_cq + "-cq.pdf"
            }
            else if (this.tipoUtente === 'correttore_cr') {
                nomeFile = row.rowData.id_sinistro + "-" + row.rowData.num_versione_cr + "-cr.pdf"
            }
            let retDataDoc = "";
            if (tipoGrid === 'da_correggere') {
                //qui al primo salvataggio carico idSinistro.pdf quello dello storage di gbs
                CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/ACE/[NO]/[NO]/${row.rowData.id_allegato}/[NO]'}`, {}, 'POST',
                    'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                        console.log(retData)
                        if (retData.response === 'OK') {
                            retDataDoc = retData.data;
                            this.dialogCaricaDocumentoPerizia(tipoGrid, row, retDataDoc, nomeFile)
                        }
                        else {
                            this.toggleBlocking();
                            toast.error("Il documento non è stato trovato!", {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }
                    }, error => {
                        this.toggleBlocking();
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, false);
            }
            else if (tipoGrid === 'in_correzione') {
                var jsonObject = {
                    datiDocumento: JSON.stringify(
                        [{
                            nomeFile: nomeFile,
                            idSinistro: row.rowData.id_sinistro
                        }]
                    )
                }
                console.log(jsonObject)
                CustomJS.callAPI(`ChiamateAPISIPAEVO/SalvaCaricaDocumentoPeriziaXpert/carica`, JSON.stringify(jsonObject), 'POST', 'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    //qui carico il documento idSinistro-cq.pdf nella temp dell' apicaller3
                    console.log(retData)
                    if (retData.response === "OK") {
                        retDataDoc = JSON.parse(retData.data);
                        debugger;
                        this.dialogCaricaDocumentoPerizia(tipoGrid, row, retDataDoc, nomeFile)
                    }
                    else {
                        //SE LA RISPOSTA NON VA A BUON FINE PERCHE NON HA SALVATO IL DOCUMENTO QUANDO E STATO PRESO IN CORREZIONE
                        //CARICO QUELLO ALLEGATO INZIALMENTE CON idSinistro.pdf 
                        CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/ACE/[NO]/[NO]/${row.rowData.id_allegato}/[NO]'}`, {}, 'POST',
                            'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData2 => {
                                console.log(retData2)
                                if (retData2.response === 'OK') {
                                    retDataDoc = retData2.data;
                                    this.dialogCaricaDocumentoPerizia(tipoGrid, row, retDataDoc, nomeFile)
                                }
                                else {
                                    this.toggleBlocking();
                                    toast.error("Il documento non è stato trovato!", {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 15000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                }
                            }, error => {
                                this.toggleBlocking();
                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, false);
                    }
                }, error => {
                    this.toggleBlocking();
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, false);
            }
            else if (tipoGrid === 'corretti') {
                const QueryExec = `SELECT T1.* FROM acallegatisinistro T1 LEFT OUTER JOIN _user T2 ON T1.id_user = T2.id_user WHERE id_sinistro = '${row.rowData.id_sinistro}'`;
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                    let retDataCorrezioni = JSON.parse(retData.data);
                    if (this.tipoUtente === 'correttore_cq') {
                        retDataCorrezioni = retDataCorrezioni.filter(obj => (obj.nomefile.toUpperCase()).includes('CQ'));
                    }
                    else if (this.tipoUtente === 'correttore_cr') {
                        retDataCorrezioni = retDataCorrezioni.filter(obj => (obj.nomefile.toUpperCase()).includes('CR'));
                    }
                    let objAllegato = retDataCorrezioni.reduce((max, obj) => (obj.id_allegato > max.id_allegato ? obj : max));
                    objAllegato = objAllegato.id_allegato;
                    console.log(objAllegato)
                    CustomJS.callAPI(`ChiamateAPISIPAEVO/GestioneAllegati/Download/ACE/[NO]/[NO]/${objAllegato}/[NO]'}`, {}, 'POST',
                        'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, async (retData) => {
                            console.log(retData)
                            if (retData.response === "OK") {
                                retDataDoc = retData.data;
                                this.dialogCaricaDocumentoPerizia(tipoGrid, row, retDataDoc, nomeFile)
                            }
                            else {
                                this.toggleBlocking();
                                toast.error("Il documento non è stato trovato!", {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking();
                        }, true, false);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            }
        }
        else {
            toast.warn("Questo sinistro è già stato preso in correzione da " + row.rowData.correttore, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }
    }

    dialogConfermaSinistroInCorrezione(idSinistro, idLogCorrezione, idPerito) {
        //-------------------INIZIO DIALOG CONFERMA SINISTRO-------------------------
        this.dialogInstance2.header = ``
        this.dialogInstance2.width = '30%';
        this.dialogInstance2.height = '22%';
        this.dialogInstance2.minHeight = '22%';
        this.dialogInstance2.allowDragging = true;
        this.dialogInstance2.showCloseIcon = false;
        this.dialogInstance2.buttons = [
            {
                click: () => {
                    //salvo il documento di perizia così caricherò sempre quello sulla temp una volta salvato
                    this.salvaDocPerizia();
                    //conferma presa in correzione del sinistro
                    var jsonObject = {
                        QueryExec: `update _log_correzione set data_inizio = @dataInizio, id_correttore = @idCorrettore, id_correttore_prec = @idCorrettore where id = ${idLogCorrezione}`,
                        dataInizio: moment(new Date()).format('YYYY-MM-DD HH:mm').toString(),
                        idCorrettore: JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user.toString()
                    };
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
                        this.caricaDati();
                        //aggiorno i log action
                        this.gestioneLog(`INSERT INTO _action_log (sigla_studio, id_user, action_data, action, note, param_1, param_2, param_3, action_datatime_xpert) VALUES ('ac', ${idPerito}, '${this.dataDiOggiContrario}', 'In correzione', 'Documento di perizia preso in correzione da ${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].denom}', ${idSinistro}, 0, 0, CURRENT_TIMESTAMP)`);

                        toast.success("Documento di perizia preso in correzione!", {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    });
                    this.dialogInstance2.hide();
                },
                buttonModel: {
                    content: 'CONFERMA',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance2.hide();
                    this.dialogInstance1.hide();
                },
                buttonModel: {
                    content: 'CHIUDI'
                }
            }
        ];
        this.dialogInstance2.beforeOpen = (args) => {
            const container = document.getElementById('dialogCorrezioneContent2');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance2.open = (args) => {
            const container = document.getElementById('dialogCorrezioneContent2');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
            }}><><div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                    <h3 class="text-center text-danger">
                        <i class='fas fa-exclamation e-primary'></i>
                    </h3>
                    <p class="text-center" style={{ fontSize: '15px' }}>
                        Vuoi prendere in correzione il documento di perizia del sinistro <strong className='e-primary'>{idSinistro}</strong> ?
                    </p>
                </div>
            </div>
                </></CallbackWrapper>);
        };
        this.dialogInstance2.show();
        //-------------------FINE DIALOG CONFERMA SINISTRO---------------------------
    }
    //----------------------------APRI DOCUMENTO DI CORREZIONE FINE----------------------------


    //---------------------------SALVATAGGIO DOCUMENTO DI PERIZIA INIZIO---------------------------
    salvaDocPerizia() {
        window.addEventListener('beforeunload', function (event) {
            // Annulla il download di tutti i file in corso
            event.preventDefault();
            // Impedisce la visualizzazione della finestra di dialogo di conferma del browser
            event.returnValue = '';
        });
        window.addEventListener('click', function (event) {
            // Verifica se il target del click è un elemento "a" con l'attributo "download"
            if (event.target.tagName === 'A' && event.target.hasAttribute('download')) {
                // Impedisce il download del file
                event.preventDefault();
            }
        });
        // document.getElementById('PDFViewerCorrettore').ej2_instances[0].download();
        document.getElementById('PDFViewerCorrettore_downloadIcon').click()

        // var pdfViewer = document.getElementById('PDFViewerCorrettore').ej2_instances[0];
        // pdfViewer.serverActionSettings.download = "SaveDocument";
        // pdfViewer.download();
        // pdfViewer.serverActionSettings.download = "Download";
    }

    downloadEndDocumento(rowData, args) {
        var stringaBase64 = args.downloadDocument;
        stringaBase64 = stringaBase64.replace('data:application/pdf;base64,', '');
        console.log(stringaBase64)
        let nomeFile = ''

        //controllo se arriva da sipa in quel caso il numero la prima volta è 0 ma deve essere minimo 1!
        debugger;
        if (this.tipoUtente === 'correttore_cq') {
            if (rowData.num_versione_cq > 0) {
                nomeFile = rowData.id_sinistro + "-" + rowData.num_versione_cq + "-cq.pdf"
            }
            else {
                nomeFile = rowData.id_sinistro + "-" + 1 + "-cq.pdf";
                let jsonObjectNumVers = {
                    QueryExec: `update _log_correzione set num_versione_cq = '1' where id = ${rowData.idLogCorrezione};`,
                };
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectNumVers), retData => {

                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            }
        }
        else if (this.tipoUtente === 'correttore_cr') {
            if (rowData.num_versione_cr > 0) {
                nomeFile = rowData.id_sinistro + "-" + rowData.num_versione_cr + "-cr.pdf"
            }
            else {
                nomeFile = rowData.id_sinistro + "-" + 1 + "-cr.pdf"
                let jsonObjectNumVers = {
                    QueryExec: `update _log_correzione set num_versione_cr = '1' where id = ${rowData.idLogCorrezione};`,
                };
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectNumVers), retData => {

                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            }
        }
        let jsonObjectSalva = {
            datiDocumento: JSON.stringify(
                [{
                    nomeFile: nomeFile,
                    base64: stringaBase64,
                    idSinistro: rowData.id_sinistro
                }]
            )
        }
        console.log(jsonObjectSalva)
        CustomJS.callAPI(`ChiamateAPISIPAEVO/SalvaCaricaDocumentoPeriziaXpert/salva`, JSON.stringify(jsonObjectSalva), 'POST', 'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
            console.log(retData)
            if (retData.response === "OK") {
                let jsonObject = {
                    QueryExec: `update _log_correzione set ready_correction = 1,data_creazione = @dataCreazione where id = ${rowData.idLogCorrezione}`,
                    dataCreazione: moment(new Date()).format('YYYY-MM-DD HH:mm').toString(),
                };
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
                    toast.success("Documento salvato!", {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            }
            else {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }
        }, error => {
            this.toggleBlocking();
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, false);
    }
    //---------------------------SALVATAGGIO DOCUMENTO DI PERIZIA FINE---------------------------

    //---------------------------PDF RENDER INIZIO---------------------------
    PDFViewerRenderAllegati() {
        ++this.childKey;
        return <React.Fragment>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-content">
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <PdfViewerComponent id='PDFViewerAllegati' key={this.childKey} width="auto" style={{ 'height': '730px' }} 
                                                ref={PDFObj => this.PDFViewerInstanceAllegati = PDFObj} serviceUrl={`${CustomJS.APIVariousJfishXpertURLProd}/api/PdfViewer`}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, BookmarkView, ThumbnailView, Print, TextSearch, FormFields]} />
                                            </PdfViewerComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }
    PDFViewerRender(rowData, tipoGrid, allegatiCorrezioni) {
        ++this.childKey;
        return <React.Fragment>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="row">
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <ButtonComponent id='btnTerminaEConsegna' onClick={this.terminaEConsegnaCorrezione.bind(this, rowData)} type='button' cssClass='e-fill e-info'>TERMINA E CONSEGNA</ButtonComponent>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md">
                            <ButtonComponent onClick={this.apriAppunti.bind(this, rowData, tipoGrid)} type='button' cssClass='e-fill e-info'>APPUNTI</ButtonComponent>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md">
                            <ButtonComponent onClick={this.apriNotePerito.bind(this, rowData)} type='button' cssClass='e-fill e-info'>NOTE DEL PERITO</ButtonComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <ButtonComponent onClick={this.precedentiSinistri.bind(this, rowData)} type='button' cssClass='e-fill e-info'>SINISTRI PRECEDENTI</ButtonComponent>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                            <ButtonComponent onClick={this.vediAllegati.bind(this, rowData)} type='button' cssClass='e-fill e-info'>VEDI ALLEGATI</ButtonComponent>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md">
                            <ButtonComponent onClick={this.vediPolizza.bind(this, rowData)} type='button' cssClass='e-fill e-info'>VEDI POLIZZA</ButtonComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <ButtonComponent id='btnCorrezioniPrecedenti' onClick={this.correzioniPrecedenti.bind(this, allegatiCorrezioni)} type='button' cssClass='e-fill e-info'>CORREZIONI PRECEDENTI</ButtonComponent>
                        </div>
                        {/* <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <button onClick={this.esporta.bind(this)}>esporta</button>
                        </div> */}
                    </div>
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-content">
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <PdfViewerComponent id='PDFViewerCorrettore' key={this.childKey} width="auto" style={{ 'height': '730px' }} ref={PDFObj => this.pdfViewerRef = PDFObj}
                                                downloadEnd={this.downloadEndDocumento.bind(this, rowData)}
                                                isAnnotationToolbarOpen={true} isAnnotationToolbarVisible={true} isThumbnailViewOpen={true}
                                                freeTextSettings={{ fontColor: 'red' }} serviceUrl={`${CustomJS.APIVariousJfishXpertURLProd}/api/PdfViewer`}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
                                            </PdfViewerComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }

    esporta(args) {
        var exportObject;
        var viewer = document.getElementById('PDFViewerCorrettore').ej2_instances[0];
        viewer.exportAnnotationsAsObject().then(function (value) {
            exportObject = value;
        });
    }
    //---------------------------PDF RENDER FINE---------------------------

    //----------------------------DIALOG NOTE INIZIO----------------------------
    //--------------APPUNTI--------------
    apriAppunti(rowData, tipoGrid) {
        this.dialogInstance2.header = `<div id="header-dialog-${this.dialogInstance2.id}"></div>`
        this.dialogInstance2.width = '60%';
        this.dialogInstance2.height = '40%';
        this.dialogInstance2.enableResize = true;
        this.dialogInstance2.resizeHandles = ['All'];
        this.dialogInstance2.minHeight = '3%';
        this.dialogInstance2.allowDragging = true;
        this.dialogInstance2.showCloseIcon = true;
        this.dialogInstance2.buttons = [
            {
                click: () => {
                    this.dialogInstance2.hide();
                },
                buttonModel: {
                    content: 'CHIUDI',
                }
            }
        ]
        this.dialogInstance2.beforeOpen = (args) => {
            const container = document.getElementById('dialogCorrezioneContent2');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance2.open = (args) => {
            //header
            const containerHeader = document.getElementById(`header-dialog-${this.dialogInstance2.id}`);
            const rootHeader = createRoot(containerHeader);
            rootHeader.render(<CallbackWrapper callback={() => {
                this.onMaximizeDialogOpen(`APPUNTI`, '', '0', '40%', this.dialogInstance2);
                this.onMinimizeDialogOpen(`APPUNTI`, '500px', '200', '40%', this.dialogInstance2);

                //note fine correzione
                const container = document.getElementById('dialogCorrezioneContent2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.caricaAppunti(rowData, 'noteConclusiveFineCorrezione00');
                    if (tipoGrid === 'corretti') {
                        document.getElementById('noteConclusiveFineCorrezione00').ej2_instances[0].readonly = true;
                    }
                    else {
                        document.getElementById('noteConclusiveFineCorrezione00').ej2_instances[0].readonly = false;
                    }
                }}><RichTextEditorComponent id='noteConclusiveFineCorrezione00' showCharCount={true} enableHtmlEncode={true} placeholder=''
                    maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px'
                    fontColor={this.fontColor} >
                        <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                    </RichTextEditorComponent></CallbackWrapper>);
            }}>{this.headerDialog(this.dialogInstance2, "APPUNTI")}</CallbackWrapper>)
        };
        this.dialogInstance2.beforeClose = () => {
            this.salvaAppunti(rowData, 'noteConclusiveFineCorrezione00');
        }
        this.dialogInstance2.show();
    }
    //--------------NOTE DEL PERITO--------------
    apriNotePerito(rowData) {
        this.dialogInstance2.header = `<div id="header-dialog-${this.dialogInstance2.id}"></div>`
        this.dialogInstance2.width = '60%';
        this.dialogInstance2.height = '40%';
        this.dialogInstance2.enableResize = true;
        this.dialogInstance2.resizeHandles = ['All'];
        this.dialogInstance2.minHeight = '3%';
        this.dialogInstance2.allowDragging = true;
        this.dialogInstance2.showCloseIcon = true;
        this.dialogInstance2.buttons = []
        this.dialogInstance2.beforeOpen = (args) => {
            const container = document.getElementById('dialogCorrezioneContent2');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance2.open = (args) => {
            //header
            const containerHeader = document.getElementById(`header-dialog-${this.dialogInstance2.id}`);
            const rootHeader = createRoot(containerHeader);
            rootHeader.render(<CallbackWrapper callback={() => {
                //minimize e maximize
                this.onMaximizeDialogOpen(`NOTE DEL PERITO`, '', '0', '40%', this.dialogInstance2);
                this.onMinimizeDialogOpen(`NOTE DEL PERITO`, '500px', '200', '40%', this.dialogInstance2);
                //note del perito
                const container = document.getElementById('dialogCorrezioneContent2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    document.getElementById('notePerito').ej2_instances[0].value = rowData.note_per_correttore;
                }}><TextBoxComponent readonly={true} htmlAttributes={{ rows: 10 }} multiline={true} id={`notePerito`} placeholder='Note del Perito' /></CallbackWrapper>);

            }}>{this.headerDialog(this.dialogInstance2, "NOTE DEL PERITO")}</CallbackWrapper>);
        };
        //non rimuovere la funzione beforeClose vuota!
        this.dialogInstance2.beforeClose = () => { }
        this.dialogInstance2.show();
    }

    //----------------------------DIALOG NOTE FINE----------------------------

    //----------------------------VEDI DOCUMENTI INIZIO----------------------------
    //--------------VEDI ALLEGATI--------------
    vediAllegati(rowData) {
        this.dialogInstance3.header = `<div id="header-dialog-${this.dialogInstance3.id}"></div>`
        this.dialogInstance3.width = '85%';
        this.dialogInstance3.height = '70%';
        this.dialogInstance3.enableResize = true;
        this.dialogInstance3.resizeHandles = ['All'];
        this.dialogInstance3.minHeight = '3%';
        this.dialogInstance3.allowDragging = true;

        this.dialogInstance3.buttons = [];
        this.dialogInstance3.beforeOpen = (args) => {
            const container = document.getElementById('dialogCorrezioneContent3');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance3.open = (args) => {
            //header
            const containerHeader = document.getElementById(`header-dialog-${this.dialogInstance3.id}`);
            const rootHeader = createRoot(containerHeader);
            rootHeader.render(<CallbackWrapper callback={() => {
                //minimize e maximize
                this.onMaximizeDialogOpen(`VISUALIZZA ALLEGATI`, '', '0', '70%', this.dialogInstance3);
                this.onMinimizeDialogOpen(`VISUALIZZA ALLEGATI`, '500px', '200', '70%', this.dialogInstance3);

                //allegati
                const container = document.getElementById('dialogCorrezioneContent3');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    //CARICO ALLEGATI
                    const QueryExec = `SELECT T1.*, T2.denom AS user FROM acallegatisinistro T1 LEFT OUTER JOIN _user T2 ON T1.id_user = T2.id_user WHERE id_sinistro = '${rowData.id_sinistro}'`;
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                        let retDataObj = JSON.parse(retData.data);
                        console.log("ret data dalla query")
                        console.log(retDataObj)
                        let allegatiIncarico = [];
                        let allegatiFotografieRischio = [];
                        //su altri allegati quello che c'è su altri allegati + elaborato finale tranne idSinistro.pdf
                        let altriAllegati = [];
                        let allegatiElaborato = [];
                        let tuttiAltriAllegati = []

                        const regex = /^\d+(?:-\d+(?:-.*\b(?:atto)\b)?|)\.PDF$|^\d+(?:-\d+)?-.*\b(?:atto)\b.*\.PDF$/i;
                        allegatiIncarico = retDataObj.filter(obj => (obj.tipo === 1 && ((obj.nomefile.toUpperCase()).endsWith('.PDF') || (obj.nomefile.toUpperCase()).endsWith('.ZIP')) && (obj.nomefile.toUpperCase()).includes('INC')));
                        allegatiFotografieRischio = retDataObj.filter(obj => ((obj.nomefile.toUpperCase()).endsWith('.JPG') || (obj.nomefile.toUpperCase()).endsWith('.JPEG')) && (obj.nomefile.toUpperCase()).includes('RISCHIO'));

                        altriAllegati = retDataObj.filter(obj => (obj.nomefile.toUpperCase()).includes('OSE'));
                        allegatiElaborato = retDataObj.filter(obj => regex.test(obj.nomefile.toUpperCase()));
                        allegatiElaborato = allegatiElaborato.filter(obj => obj.nomefile !== `${rowData.id_sinistro + '.pdf'}`);


                        altriAllegati.forEach(elm => {
                            tuttiAltriAllegati.push(elm)
                        })
                        allegatiElaborato.forEach(elm => {
                            tuttiAltriAllegati.push(elm)
                        })

                        console.log("altri allegati")
                        console.log(altriAllegati)
                        console.log("elaborato")
                        console.log(allegatiElaborato)
                        console.log("tutti e due")
                        console.log(tuttiAltriAllegati)
                        setTimeout(() => {
                            document.getElementById(`gridDocumentiIncarico`).ej2_instances[0].dataSource = allegatiIncarico;
                            document.getElementById(`gridFotografiaRischioAssicurato`).ej2_instances[0].dataSource = allegatiFotografieRischio;
                            document.getElementById(`gridAltriAllegati`).ej2_instances[0].dataSource = tuttiAltriAllegati;
                        }, 200)
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    });
                }}><>
                        <div className="control-section card-control-section basic_card_layout">
                            <div className="e-card-resize-container">
                                <div className="row card-layout text-center">
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                            <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                <h5 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Documenti Di Incarico</h5>
                                                <GridComponent id="gridDocumentiIncarico" ref={grid => this.gridInstanceDocumentiIncarico = grid}
                                                    commandClick={this.onCommandClickAllegati.bind(this)} rowDataBound={this.rowDataBoundAllegati.bind(this)} allowTextWrap={true} height='400px' enablePersistence={false}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='nomefile' headerText='Descrizione' width='30%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='user' headerText='Autore' width='35%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='dataupload' headerText='Data' width='23%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' type='datetime' format='dd/MM/yyyy'></ColumnDirective>
                                                        <ColumnDirective field='Comandi' commands={this.commandsAllegati} headerText="" width='12%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                            <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                <h5 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Fotografie Rischio Assicurato</h5>
                                                <GridComponent id="gridFotografiaRischioAssicurato" ref={grid => this.gridInstanceFotografiaRischioAssicurato = grid}
                                                    allowTextWrap={true} height='400px' enablePersistence={false} commandClick={this.onCommandClickAllegati.bind(this)}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='nomefile' headerText='Descrizione' width='30%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='user' headerText='Autore' width='35%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='dataupload' headerText='Data' width='23%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' type='datetime' format='dd/MM/yyyy'></ColumnDirective>
                                                        <ColumnDirective field='Comandi' commands={this.commandsAllegati} headerText="" width='12%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                            <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                <h5 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Altri Allegati</h5>
                                                <GridComponent id="gridAltriAllegati" ref={grid => this.gridInstanceAltriAllegati = grid}
                                                    allowTextWrap={true} height='400px' enablePersistence={false} commandClick={this.onCommandClickAllegati.bind(this)} rowDataBound={this.rowDataBoundAllegati.bind(this)}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='nomefile' headerText='Descrizione' width='30%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='user' headerText='Autore' width='35%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='dataupload' headerText='Data' width='23%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' type='datetime' format='dd/MM/yyyy'></ColumnDirective>
                                                        <ColumnDirective field='Comandi' commands={this.commandsAllegati} headerText="" width='12%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </></CallbackWrapper>);
            }}>{this.headerDialog(this.dialogInstance3, "VISUALIZZA ALLEGATI")}</CallbackWrapper>);
        };
        //non rimuovere la funzione beforeClose vuota!
        this.dialogInstance2.beforeClose = () => { }
        this.dialogInstance3.show();
    }

    //--------------VEDI POLIZZA--------------
    vediPolizza(rowData) {
        this.dialogInstance3.header = `<div id="header-dialog-${this.dialogInstance3.id}"></div>`;
        this.dialogInstance3.width = '35%';
        this.dialogInstance3.height = '60%';
        this.dialogInstance3.enableResize = true;
        this.dialogInstance3.resizeHandles = ['All'];
        this.dialogInstance3.minHeight = '3%';
        this.dialogInstance3.allowDragging = true;

        this.dialogInstance3.buttons = [];
        this.dialogInstance3.beforeOpen = (args) => {
            const container = document.getElementById('dialogCorrezioneContent3');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance3.open = (args) => {
            //header
            const containerHeader = document.getElementById(`header-dialog-${this.dialogInstance3.id}`);
            const rootHeader = createRoot(containerHeader);
            rootHeader.render(<CallbackWrapper callback={() => {
                //minimize e maximize
                this.onMaximizeDialogOpen(`VISUALIZZA POLIZZA`, '', '0', '60%', this.dialogInstance3);
                this.onMinimizeDialogOpen(`VISUALIZZA POLIZZA`, '500px', '200', '60%', this.dialogInstance3);
                //vedi polizza
                const container = document.getElementById('dialogCorrezioneContent3');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    //CARICO ALLEGATI
                    const QueryExec = `SELECT T1.*, T2.denom AS user FROM acallegatisinistro T1 LEFT OUTER JOIN _user T2 ON T1.id_user = T2.id_user WHERE id_sinistro = '${rowData.id_sinistro}'`;
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                        let retDataObj = JSON.parse(retData.data);
                        let allegatiPolizza = [];
                        allegatiPolizza = retDataObj.filter(obj => obj.tipo === 2 && (obj.nomefile.toUpperCase()).includes('CGA') && ((obj.nomefile.toUpperCase()).endsWith('.PDF') || (obj.nomefile.toUpperCase()).endsWith('.DOC') || (obj.nomefile.toUpperCase()).endsWith('.DOCX')));
                        setTimeout(() => {
                            document.getElementById(`gridPolizza`).ej2_instances[0].dataSource = allegatiPolizza;
                        }, 200)
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    });
                }}><>
                        <div className="control-section card-control-section basic_card_layout">
                            <div className="e-card-resize-container">
                                <div className="row card-layout">
                                    <GridComponent id="gridPolizza" ref={grid => this.gridInstancePolizza = grid} commandClick={this.onCommandClickAllegati.bind(this)}
                                        allowTextWrap={true} enablePersistence={false} >
                                        <ColumnsDirective>
                                            <ColumnDirective field='nomefile' headerText='Descrizione' width='30%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='user' headerText='Autore' width='35%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='dataupload' headerText='Data' width='23%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' type='datetime' format='dd/MM/yyyy'></ColumnDirective>
                                            <ColumnDirective field='Comandi' commands={this.commandsAllegati} headerText="" width='12%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                                    </GridComponent>
                                </div>
                            </div>
                        </div>
                    </></CallbackWrapper>);
            }}>{this.headerDialog(this.dialogInstance3, "VISUALIZZA POLIZZA")}</CallbackWrapper>);
        };
        //non rimuovere la funzione beforeClose vuota!
        this.dialogInstance2.beforeClose = () => { }
        this.dialogInstance3.show();
    }

    correzioniPrecedenti(allegatiCorrezioni) {
        this.dialogInstance2.header = `<div id="header-dialog-${this.dialogInstance2.id}"></div>`
        this.dialogInstance2.width = '60%';
        this.dialogInstance2.height = '40%';
        this.dialogInstance2.enableResize = true;
        this.dialogInstance2.resizeHandles = ['All'];
        this.dialogInstance2.minHeight = '3%';
        this.dialogInstance2.allowDragging = true;
        this.dialogInstance2.showCloseIcon = true;
        this.dialogInstance2.buttons = []
        this.dialogInstance2.beforeOpen = (args) => {
            const container = document.getElementById('dialogCorrezioneContent2');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance2.open = (args) => {
            //header
            const containerHeader = document.getElementById(`header-dialog-${this.dialogInstance2.id}`);
            const rootHeader = createRoot(containerHeader);
            rootHeader.render(<CallbackWrapper callback={() => {
                //minimize e maximize
                this.onMaximizeDialogOpen(`CORREZIONI PRECEDENTI`, '', '0', '40%', this.dialogInstance2);
                this.onMinimizeDialogOpen(`CORREZIONI PRECEDENTI`, '500px', '200', '40%', this.dialogInstance2);
                //correzioni precedenti
                const container = document.getElementById('dialogCorrezioneContent2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    document.getElementById(`gridCorrezioniPrecedenti`).ej2_instances[0].dataSource = allegatiCorrezioni;
                }}><>
                        <div className="control-section card-control-section basic_card_layout">
                            <div className="e-card-resize-container">
                                <div className="row card-layout">
                                    <GridComponent id="gridCorrezioniPrecedenti" ref={grid => this.gridInstanceCorrezioniPrec = grid} commandClick={this.onCommandClickAllegati.bind(this)}
                                        allowTextWrap={true} enablePersistence={false} >
                                        <ColumnsDirective>
                                            <ColumnDirective field='nomefile' headerText='Descrizione' width='30%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='user' headerText='Autore' width='35%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='dataupload' headerText='Data' width='23%' readOnly={true} headerTemplate={headerTemplate} textAlign='Center' type='datetime' format='dd/MM/yyyy'></ColumnDirective>
                                            <ColumnDirective field='Comandi' commands={this.commandsAllegati} headerText="" width='12%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                                    </GridComponent>
                                </div>
                            </div>
                        </div>
                    </></CallbackWrapper>);
            }}>{this.headerDialog(this.dialogInstance2, "CORREZIONI PRECEDENTI")}</CallbackWrapper>);
        };
        //non rimuovere la funzione beforeClose vuota!
        this.dialogInstance2.beforeClose = () => { }
        this.dialogInstance2.show();
    }
    //----------------------------VEDI DOCUMENTI FINE----------------------------


    //----------------------------VEDI SINISTRI STESSO LUOGO O POLIZZA INIZIO----------------------------
    precedentiSinistri(rowData) {
        //LUOGO
        var arraySinPrecedenti = []
        var queryExecLuogo = `SELECT T1.id_sinistro, T1.id_compagnia,T1.data_sop, T1.sin_num, T1.pol_num, T1.luo_ind, T1.luo_cit, T1.luo_pro, T1.luo_cap, T1.data_inc_per, T2.compagnia, T3.contraente, T4.tipo_sinistro, T5.DESCRIZ FROM acsinistri T1 LEFT OUTER JOIN _compagnie T2 ON T2.id_compagnia = T1.id_compagnia LEFT OUTER JOIN accontraenti T3 ON T1.id_contraente = T3.id_contraente LEFT OUTER JOIN _tipo_sinistro T4 ON T1.id_tipo_sinistro = T4.id_tipo_sinistro LEFT OUTER JOIN ELEMENTITBSTD T5 ON T5.COD_TAB = 'stati_sinistro' AND T1.id_stato = T5.COD_ELE WHERE T1.luo_ind LIKE "%${rowData.luo_ind}%" AND T1.luo_cit LIKE "%${rowData.luo_cit}%" AND T1.luo_cap = "${rowData.luo_cap}" AND T1.luo_pro = "${rowData.luo_pro}" AND T1.id_sinistro != "${rowData.id_sinistro}" AND T1.id_perito = '${rowData.id_perito}';`;
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryExecLuogo, '', retDataLuogo => {
            retDataLuogo = JSON.parse(retDataLuogo.data);
            //POLIZZA
            var queryExecPolizza = `SELECT T1.id_sinistro, T1.id_compagnia,T1.data_sop, T1.sin_num, T1.pol_num, T1.luo_ind, T1.luo_cit, T1.luo_pro, T1.luo_cap, T1.data_inc_per, T2.compagnia, T3.contraente, T4.tipo_sinistro, T5.DESCRIZ FROM acsinistri T1 LEFT OUTER JOIN _compagnie T2 ON T2.id_compagnia = T1.id_compagnia LEFT OUTER JOIN accontraenti T3 ON T1.id_contraente = T3.id_contraente LEFT OUTER JOIN _tipo_sinistro T4 ON T1.id_tipo_sinistro = T4.id_tipo_sinistro LEFT OUTER JOIN ELEMENTITBSTD T5 ON T5.COD_TAB = 'stati_sinistro' AND T1.id_stato = T5.COD_ELE WHERE T1.pol_num = '${rowData.pol_num}' AND T1.id_sinistro != '${rowData.id_sinistro}' AND T1.id_perito = '${rowData.id_perito}';`;
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryExecPolizza, '', retDataPolizza => {
                retDataPolizza = JSON.parse(retDataPolizza.data);
                retDataLuogo.forEach(sin => {
                    arraySinPrecedenti.push(sin)
                })
                retDataPolizza.forEach(sin => {
                    arraySinPrecedenti.push(sin)
                })
                //tolgo i sinistri doppi
                var sinPrecSenzaDoppi = arraySinPrecedenti.filter((obj, index) => {
                    return index === arraySinPrecedenti.findIndex(o => obj.id_sinistro === o.id_sinistro);
                });
                var numSinPrec = sinPrecSenzaDoppi.length;
                if (numSinPrec > 0) {
                    this.dialogInstance3.header = `<div id="header-dialog-${this.dialogInstance3.id}"></div>`
                    this.dialogInstance3.width = '90%';
                    this.dialogInstance3.height = '90%';
                    this.dialogInstance3.enableResize = true;
                    this.dialogInstance3.resizeHandles = ['All'];
                    this.dialogInstance3.minHeight = '3%';
                    this.dialogInstance3.allowDragging = true;

                    this.dialogInstance3.buttons = [];
                    this.dialogInstance3.beforeOpen = (args) => {
                        const container = document.getElementById('dialogCorrezioneContent3');
                        const root = createRoot(container);
                        root.unmount();
                    };
                    this.dialogInstance3.open = (args) => {
                        //header
                        const containerHeader = document.getElementById(`header-dialog-${this.dialogInstance3.id}`);
                        const rootHeader = createRoot(containerHeader);
                        rootHeader.render(<CallbackWrapper callback={() => {
                            //minimize e maximize
                            this.onMaximizeDialogOpen(`SINISTRI PRECEDENTI`, '', '0', '90%', this.dialogInstance3);
                            this.onMinimizeDialogOpen(`SINISTRI PRECEDENTI`, '500px', '200', '90%', this.dialogInstance3);

                            //sinistri precedenti
                            const container = document.getElementById('dialogCorrezioneContent3');
                            const root = createRoot(container);
                            root.render(<CallbackWrapper callback={() => {
                                document.getElementById('GridImportPrecedentiSinistri').ej2_instances[0].dataSource = sinPrecSenzaDoppi;
                            }}><>
                                    <GridComponent id="GridImportPrecedentiSinistri" ref={grid => this.gridInstancePrecSin = grid} hierarchyPrintMode='All'
                                        allowSelection={true} enableHover={false} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={true}
                                        enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings} allowGrouping={false} allowExcelExport={true} filterSettings={this.filterSettings} >
                                        <ColumnsDirective>
                                            <ColumnDirective field='id_sinistro' headerText='ID' width='8%' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate2} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='compagnia' headerText="Compagnia" width='15%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                            <ColumnDirective field='contraente' headerText="Contraente" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='tipo_sinistro' headerText="Tipo" width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='data_inc_per' headerText="Data Incarico" width='10%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='data_sop' headerText="Sopralluogo" width='15%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='DESCRIZ' headerText="Status" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='pol_num' headerText="Polizza" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='luo_ind' headerText="Indirizzo" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='luo_cit' headerText="Città" width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='luo_pro' headerText="Provincia" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                            <ColumnDirective field='luo_cap' headerText="CAP" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                                    </GridComponent>
                                </></CallbackWrapper>);
                        }}>{this.headerDialog(this.dialogInstance3, "SINISTRI PRECEDENTI")}</CallbackWrapper>);
                    };
                    //non rimuovere la funzione beforeClose vuota!
                    this.dialogInstance2.beforeClose = () => { }
                    this.dialogInstance3.show();
                }
                else {
                    toast.error("Non ci sono sinistri precedenti con la stessa polizza o con lo stesso luogo!", {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            });
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }
    //----------------------------VEDI SINISTRI STESSO LUOGO O POLIZZA FINE----------------------------

    //----------------------------TERMINA CONSEGNA INIZIO----------------------------
    //----------------------------CONTENUTO----------------------------
    contentTerminaCorrezione(rowData) {
        return <>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-content">
                                    <h6>Hai finito la correzione ?</h6>
                                    <h6 style={{ fontSize: '14px' }}>Prima di riconsegnare i documenti al perito ricordati di inserire le note conclusive :</h6>
                                    <RichTextEditorComponent id='noteConclusiveFineCorrezione' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                        maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px'
                                        fontColor={this.fontColor}>
                                        <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                    </RichTextEditorComponent>
                                    <div style={{ marginTop: '50px' }}>
                                        <h6 style={{ fontSize: '14px' }}>Inoltre ricordati di segnalare gli errori riscontrati e di assegnare un valore al lavoro del perito.</h6>
                                        <h6 style={{ backgroundColor: '#f39292', padding: '10px', borderRadius: '15px' }}>Segnalazione errori nell' elaborato</h6>
                                        <div style={{ padding: '10px' }} >
                                            <h6 style={{ fontSize: '14px' }}>Questi sono gli errori che possono essere segnalati al perito riguardo all'elaborato sotto verifica. Gli errori in  rosso prevedono obbligatoriamente la valutazione "K.O." e quindi sia da rivedere </h6>
                                            <div className='row'>
                                                <div className='col-xs-10 col-sm-10 col-lg-10 col-md-10' style={{ textAlign: 'center' }}>
                                                    <h6 style={{ fontWeight: 'bold' }}>Correzione</h6>
                                                </div>
                                                <div className='col-xs-1 col-sm-1 col-lg-1 col-md-1'>
                                                    <h6 style={{ fontWeight: 'bold' }}>Malus</h6>
                                                </div>
                                                <div className='col-xs-1 col-sm-1 col-lg-1 col-md-1'>
                                                    <h6 style={{ fontWeight: 'bold' }}>Errore</h6>
                                                </div>
                                            </div>
                                            <div id='erroriCorrezione'>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 text-end'>
                                                <div id='boxTipoConsegnaPerito' >
                                                    <div style={{ padding: '15px', display: 'inline' }} >
                                                        <RadioButtonComponent id='consegnaPeritoPiu25' label="+25L" name="consegnaPerito" />
                                                    </div>
                                                    <div style={{ padding: '15px', display: 'inline' }}>
                                                        <RadioButtonComponent id='consegnaPeritoStandard' checked={true} label="Standard" name="consegnaPerito" />
                                                    </div>
                                                    <div style={{ padding: '15px', display: 'inline' }}>
                                                        <RadioButtonComponent id='consegnaPeritoMeno25' label="-25" name="consegnaPerito" />
                                                    </div>
                                                    <div style={{ padding: '15px', display: 'inline' }}>
                                                        <RadioButtonComponent id='consegnaPeritoKO' label="KO" name="consegnaPerito" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 text-end'>
                                                <ButtonComponent id='btnTerminaDaRivedere' onClick={this.terminaConsegnaPerito.bind(this, rowData, true)} cssClass='e-fill e-warning' type='button'>Si, termina e consegna da rivedere</ButtonComponent>
                                                <ButtonComponent id='btnTerminaConsegnaPerito' onClick={this.terminaConsegnaPerito.bind(this, rowData, false)} style={{ marginLeft: '15px' }} cssClass='e-fill e-success' type='button'>Si, termina e consegna al perito</ButtonComponent>
                                                <ButtonComponent style={{ marginLeft: '15px' }} cssClass='e-fill e-info' type='button'>Torna indietro</ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
    cambiaCheckCorrezione(num) {
        //CAMBIO CHECK CORREZIONE
        if (!CustomJS.isNullEmpty(num)) {
            if (document.getElementById(`checkCorrezioneC${num}`).ej2_instances[0].checked === true) {
                document.getElementById(`checkCorrezioneC${num}`).ej2_instances[0].label = 'SI';
                document.querySelector(`label[for = "checkCorrezioneC${num}"] .e-label`).style.color = '#007b00';
            }
            else {
                document.getElementById(`checkCorrezioneC${num}`).ej2_instances[0].label = 'NO';
                document.querySelector(`label[for = "checkCorrezioneC${num}"] .e-label`).style.color = 'red';
            }
        }
        //CONTROLLO KO
        var almeno1KO = false;
        var almenoUnErroreNormale = false;
        this.dsErroriCorrezione.forEach(testo => {
            if (testo.ko === 1) {
                if (document.getElementById(`checkCorrezioneC${testo.num}`).ej2_instances[0].checked === true) {
                    almeno1KO = true;
                }
            }
            if (testo.ko !== 1) {
                if (document.getElementById(`checkCorrezioneC${testo.num}`).ej2_instances[0].checked === true) {
                    almenoUnErroreNormale = true;
                }
            }
        })
        //c'è almeno 1 KO
        if (almeno1KO === true) {
            document.getElementById(`btnTerminaDaRivedere`).ej2_instances[0].disabled = true;
            //check ko
            document.getElementById(`consegnaPeritoKO`).ej2_instances[0].checked = true;
            //blocco
            document.getElementById(`consegnaPeritoPiu25`).ej2_instances[0].disabled = true;
            document.getElementById(`consegnaPeritoStandard`).ej2_instances[0].disabled = true;
            document.getElementById(`consegnaPeritoMeno25`).ej2_instances[0].disabled = true;
            document.getElementById(`consegnaPeritoKO`).ej2_instances[0].disabled = true;

        }
        //non c'è neeanche un errore senza ko
        else if (almenoUnErroreNormale === false) {
            document.getElementById(`btnTerminaDaRivedere`).ej2_instances[0].disabled = true;
            //check standard
            document.getElementById(`consegnaPeritoStandard`).ej2_instances[0].checked = true;

            //blocco
            document.getElementById(`consegnaPeritoPiu25`).ej2_instances[0].disabled = true;
            document.getElementById(`consegnaPeritoStandard`).ej2_instances[0].disabled = true;
            document.getElementById(`consegnaPeritoMeno25`).ej2_instances[0].disabled = true;
            document.getElementById(`consegnaPeritoKO`).ej2_instances[0].disabled = true;
        }
        else {
            document.getElementById(`btnTerminaDaRivedere`).ej2_instances[0].disabled = false;
            //sblocco
            document.getElementById(`consegnaPeritoPiu25`).ej2_instances[0].disabled = false;
            document.getElementById(`consegnaPeritoStandard`).ej2_instances[0].disabled = false;
            document.getElementById(`consegnaPeritoMeno25`).ej2_instances[0].disabled = false;
            document.getElementById(`consegnaPeritoKO`).ej2_instances[0].disabled = false;
        }
    }
    //----------------------------SALVATAGGIO----------------------------

    terminaEConsegnaCorrezione(rowData) {
        this.salvaDocPerizia();
        this.dialogInstance2.header = `<div id="header-dialog-${this.dialogInstance2.id}"></div>`
        this.dialogInstance2.width = '80%';
        this.dialogInstance2.height = '90%';
        this.dialogInstance2.enableResize = true;
        this.dialogInstance2.resizeHandles = ['All'];
        this.dialogInstance2.minHeight = '3%';
        this.dialogInstance2.allowDragging = true;
        this.dialogInstance2.showCloseIcon = true;
        this.dialogInstance2.buttons = [
            {
                click: () => {
                    this.salvaNoteEdErroriCorrezione(rowData.idLogCorrezione, rowData.id_sinistro, true)
                    this.dialogInstance2.hide();
                },
                buttonModel: {
                    content: 'SALVA',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance2.hide();
                },
                buttonModel: {
                    content: 'CHIUDI'
                }
            }
        ]

        this.dialogInstance2.beforeClose = () => {
            this.salvaAppunti(rowData, 'noteConclusiveFineCorrezione')
        }
        this.dialogInstance2.beforeOpen = (args) => {
            const container = document.getElementById('dialogCorrezioneContent2');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance2.open = (args) => {
            //header
            const containerHeader = document.getElementById(`header-dialog-${this.dialogInstance2.id}`);
            const rootHeader = createRoot(containerHeader);
            rootHeader.render(<CallbackWrapper callback={() => {
                //minimize e maximize
                this.onMaximizeDialogOpen(`TERMINA E CONSEGNA CORREZIONE`, '', '0', '90%', this.dialogInstance2);
                this.onMinimizeDialogOpen(`TERMINA E CONSEGNA CORREZIONE`, '500px', '200', '90%', this.dialogInstance2);

                //callback termina consegna correzione
                const container = document.getElementById('dialogCorrezioneContent2');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    //carico note
                    this.caricaAppunti(rowData, 'noteConclusiveFineCorrezione')

                    //carico correzioni
                    let funcList = [];
                    this.dsErroriCorrezione.forEach(testo => {
                        const esegui = () => {
                            return new Promise(async (resolve) => {
                                const DivContainer = document.getElementById(`erroriCorrezione`);
                                const newDiv = document.createElement("div");
                                newDiv.setAttribute("id", `containerErrC${testo.num}`);
                                DivContainer.append(newDiv);

                                const container2 = document.getElementById(`containerErrC${testo.num}`);
                                const root2 = createRoot(container2);
                                root2.render(<CallbackWrapper callback={() => {
                                    document.querySelector(`label[for = "checkCorrezioneC${testo.num}"] .e-label`).style.fontWeight = '600';
                                    document.querySelector(`label[for = "checkCorrezioneC${testo.num}"] .e-label`).style.color = 'red';
                                    resolve('Prossima');
                                }}><>
                                        <div className='row correzione-error'>
                                            <div className='col-xs-10 col-sm-10 col-lg-10 col-md-10' >
                                                <h6 style={{ fontWeight: 'bold' }}> </h6>
                                                <h6 style={{ color: `${testo.colore}` }}>{testo.errore}</h6>
                                            </div>
                                            <div className='col-xs-1 col-sm-1 col-lg-1 col-md-1' style={{ textAlign: 'center', fontWeight: '600' }}>
                                                <h6 >{testo.malus}</h6>
                                            </div>
                                            <div className='col-xs-1 col-sm-1 col-lg-1 col-md-1' style={{ textAlign: 'left', fontWeight: '600' }}>
                                                <CheckBoxComponent onChange={this.cambiaCheckCorrezione.bind(this, testo.num)} id={`checkCorrezioneC${testo.num}`} label="NO" />
                                            </div>
                                        </div>
                                    </></CallbackWrapper>);

                            })
                        }
                        funcList.push(esegui);
                    })
                    const eseguiCiclica = (ind) => {
                        if (CustomJS.isNullEmpty(funcList[ind])) {
                            //finito di renderizzare i check delle correzioni
                            //carico le risposte salvate in precedenza

                            var QueryExecCorrezioni = `SELECT * FROM accorrezioni where id_sinistro = ${rowData.id_sinistro};`
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecCorrezioni, '', retDataCorr => {
                                retDataCorr = JSON.parse(retDataCorr.data);
                                retDataCorr.forEach(data => {
                                    if (data.stato === 1 || data.stato === true) {
                                        document.getElementById(`checkCorrezioneC${data.id_tipocorrezione}`).ej2_instances[0].checked = true
                                        document.getElementById(`checkCorrezioneC${data.id_tipocorrezione}`).ej2_instances[0].label = 'SI';
                                        document.querySelector(`label[for = "checkCorrezioneC${data.id_tipocorrezione}"] .e-label`).style.color = '#007b00';
                                    }
                                    else {
                                        document.getElementById(`checkCorrezioneC${data.id_tipocorrezione}`).ej2_instances[0].label = 'NO';
                                        document.querySelector(`label[for = "checkCorrezioneC${data.id_tipocorrezione}"] .e-label`).style.color = 'red';
                                    }
                                    //gestione per salvataggio
                                    this.dsErroriCorrezione.forEach(testo => {
                                        if (testo.num === data.id_tipocorrezione) {
                                            testo.idUpdate = data.id;
                                        }
                                    })
                                })
                                //controllo i casi per disabilitare le opzioni non consentite
                                this.cambiaCheckCorrezione('');
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });
                        }
                        else {
                            funcList[ind]().then(ret => {
                                if (ret === 'Prossima') {
                                    eseguiCiclica(ind + 1);
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                        }
                    }
                    eseguiCiclica(0);
                }}>{this.contentTerminaCorrezione(rowData)}</CallbackWrapper>);
            }}>{this.headerDialog(this.dialogInstance2, "TERMINA E CONSEGNA CORREZIONE")}</CallbackWrapper>);
        };
        this.dialogInstance2.show();
    }


    terminaConsegnaPerito(rowData, daRivedere, args) {
        this.salvaNoteEdErroriCorrezione(rowData, daRivedere);

        var correttore = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].denom;
        //Aggiungo le note del correttore al perito sul pdf corretto con l'azione modifica
        let noteAppunti = CustomJS.isNullEmpty(document.getElementById('noteConclusiveFineCorrezione').ej2_instances[0].value) ? '' : document.getElementById('noteConclusiveFineCorrezione').ej2_instances[0].value;
        let nomeFile = '';
        if (this.tipoUtente === 'correttore_cq') {
            if (rowData.num_versione_cq > 0) {
                nomeFile = rowData.id_sinistro + "-" + rowData.num_versione_cq + "-cq.pdf"
            }
            else {
                nomeFile = rowData.id_sinistro + "-" + 1 + "-cq.pdf"
            }
        }
        else if (this.tipoUtente === 'correttore_cr') {
            if (rowData.num_versione_cr > 0) {
                nomeFile = rowData.id_sinistro + "-" + rowData.num_versione_cr + "-cr.pdf"
            }
            else {
                nomeFile = rowData.id_sinistro + "-" + 1 + "-cr.pdf"
            }
        }
        let jsonObjectMOD = {
            datiDocumento: JSON.stringify(
                [{
                    nomeFile: nomeFile,
                    idSinistro: rowData.id_sinistro,
                    note: noteAppunti,
                    nomeCorrettore: correttore,
                }]
            )
        }
        console.log(jsonObjectMOD)
        CustomJS.callAPI(`ChiamateAPISIPAEVO/SalvaCaricaDocumentoPeriziaXpert/modifica`, JSON.stringify(jsonObjectMOD), 'POST', 'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retDataMOD => {
            console.log(retDataMOD)
            if (retDataMOD.response === "OK") {
                let base64Risposta = JSON.parse(retDataMOD.data)
                if (base64Risposta !== 'KO') {

                    //ALLEGO DOCUMENTO A ACE
                    let nomeFileOrigin = '';
                    if (this.tipoUtente === 'correttore_cq') {
                        if (rowData.num_versione_cq > 0) {
                            nomeFileOrigin = rowData.id_sinistro + "-" + rowData.num_versione_cq + "-cq.pdf"
                        }
                        else {
                            nomeFileOrigin = rowData.id_sinistro + "-" + 1 + "-cq.pdf"
                        }
                    }
                    else if (this.tipoUtente === 'correttore_cr') {
                        if (rowData.num_versione_cr > 0) {
                            nomeFileOrigin = rowData.id_sinistro + "-" + rowData.num_versione_cr + "-cr.pdf"
                        }
                        else {
                            nomeFileOrigin = rowData.id_sinistro + "-" + 1 + "-cr.pdf"
                        }
                    }
                    var jsonObjectACE = {
                        fileBase64: base64Risposta,  //file in base 64 del file
                        nomeFileOriginale: nomeFileOrigin
                    }
                    var idUtente = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;
                    var idCompagnia = '[NO]';
                    var chiamataAllegati = `ChiamateAPISIPAEVO/UploadAllegatoBase64/correzione_ace_cq/${idUtente}/${rowData.id_sinistro}/${idCompagnia}`;
                    CustomJS.callAPI(chiamataAllegati, JSON.stringify(jsonObjectACE), 'POST', 'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retDataACE => {
                        if (retDataACE.response === "OK") {
                            console.log(retDataACE)

                            const QueryExec = `SELECT T1.* FROM acallegatisinistro T1 LEFT OUTER JOIN _user T2 ON T1.id_user = T2.id_user WHERE id_sinistro = '${rowData.id_sinistro}'`;
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                                let retDataCorrezioni = JSON.parse(retData.data);
                                if (this.tipoUtente === 'correttore_cq') {
                                    retDataCorrezioni = retDataCorrezioni.filter(obj => (obj.nomefile.toUpperCase()).includes('CQ'));
                                }
                                else if (this.tipoUtente === 'correttore_cr') {
                                    retDataCorrezioni = retDataCorrezioni.filter(obj => (obj.nomefile.toUpperCase()).includes('CR'));
                                }
                                let objAllegato = retDataCorrezioni.reduce((max, obj) => (obj.id_allegato > max.id_allegato ? obj : max));
                                console.log(objAllegato.id_allegato)

                                var messaggioInvio = '';
                                var stato = ''
                                var valCorrezione = '';
                                var actionLogStato = ''
                                if (daRivedere === true) {
                                    stato = '14' // Controllo CQ da rivedere
                                    valCorrezione = '0' // da rivedere
                                    messaggioInvio = `Documento da rivedere consegnato a ${rowData.perito}!`;
                                    actionLogStato = 'Controllo CQ da rivedere'
                                }
                                else {
                                    if (document.getElementById('consegnaPeritoPiu25').ej2_instances[0].checked === true) {
                                        valCorrezione = '1' // +25
                                    }
                                    else if (document.getElementById('consegnaPeritoStandard').ej2_instances[0].checked === true) {
                                        valCorrezione = '2' // standard
                                    }
                                    else if (document.getElementById('consegnaPeritoMeno25').ej2_instances[0].checked === true) {
                                        valCorrezione = '3' // -25
                                    }
                                    else if (document.getElementById('consegnaPeritoKO').ej2_instances[0].checked === true) {
                                        valCorrezione = '4' // KO
                                    }
                                    messaggioInvio = `Documento corretto consegnato a ${rowData.perito}!`;
                                    stato = '13' // Controllo CQ OK
                                    actionLogStato = 'Controllo CQ OK'
                                }
                                //Se la chiamata ad ACE è OK  :
                                //1) aggiorno lo stato sulla log_correzione
                                let jsonObjectLOG = {
                                    QueryExec: `update _log_correzione set id_allegato = ${objAllegato.id_allegato}, note_correzione = @noteFineCorrezione, ready_correction = 2,in_correzione = 0, data_fine = @dataFine,val_correzione = @valCorrezione where id = ${rowData.idLogCorrezione}`,
                                    noteFineCorrezione: noteAppunti,
                                    dataFine: moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
                                    valCorrezione: valCorrezione
                                };
                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectLOG), retDataLOG => {
                                    //2) cambio stato sinistro
                                    var jsonObjectStato = {
                                        QueryExec: `UPDATE acsinistri SET id_stato = '${stato}' WHERE id_sinistro = '${rowData.id_sinistro}';`,
                                    };
                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectStato), retDataStato => {
                                        this.dialogInstance2.hide();
                                        this.dialogInstance1.hide();
                                        //gestione action log
                                        this.gestioneLog(`INSERT INTO _action_log (sigla_studio, id_user, action_data, action, note, param_1, param_2, param_3, action_datatime_xpert) VALUES ('ac', ${rowData.id_perito}, '${this.dataDiOggiContrario}', '${actionLogStato}', 'Documento di perizia ricevuto dal cq ${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].denom}', ${rowData.id_sinistro}, 0, 0, CURRENT_TIMESTAMP)`);
                                        toast.success(messaggioInvio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                        this.caricaDati();
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                    });
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    }
                                    );
                                });
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                }
                                );
                            });




                        }
                        else {
                            toast.error(retDataACE.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, false);
                }
                else {
                    toast.error("Documento corrotto! La consegna non è andata a buon fine!", { //documento non esiste nella cartella temp dell' apicaller 3 quindi niente base 64 da allegare
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, false);
    }

    salvaAppunti(rowData, idNote) {
        var jsonObject = {
            QueryExec: `update _log_correzione set note_correzione = @noteFineCorrezione where id = ${rowData.idLogCorrezione}`,
            noteFineCorrezione: document.getElementById(idNote) ? (CustomJS.isNullEmpty(document.getElementById(idNote).ej2_instances[0].value) ? '' : document.getElementById(idNote).ej2_instances[0].value) : ''
        };
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), retData => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
    }

    caricaAppunti(rowData, idNote) {
        let QueryExec = `select note_correzione from _log_correzione where id_sinistro = ${rowData.id_sinistro}`
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retDataNote => {
            retDataNote = JSON.parse(retDataNote.data)
            if (!CustomJS.isNullEmpty(retDataNote)) {
                let note = CustomJS.isNullEmpty(retDataNote[0].note_correzione) ? '' : retDataNote[0].note_correzione;
                document.getElementById(idNote).ej2_instances[0].value = note
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
    }

    salvaNoteEdErroriCorrezione(rowData, daRivedere) {
        var idCorrettore = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;
        if (daRivedere === true)
            daRivedere = 1
        else if (daRivedere === false)
            daRivedere = 0
        this.salvaAppunti(rowData, 'noteConclusiveFineCorrezione');
        this.dsErroriCorrezione.forEach(testo => {
            var jsonObject2 = {}
            if (testo.idUpdate === 0) {
                jsonObject2 = {
                    QueryExec: `insert into accorrezioni (id_correzione,id_sinistro,id_correttore,id_tipocorrezione,malus,stato,created_at,da_rivedere) values(${rowData.idLogCorrezione},${rowData.id_sinistro}, ${idCorrettore}, ${testo.num}, @malus, @stato,@dataInizio, ${daRivedere})`,
                    malus: parseFloat((testo.malus).replace("%", '')).toString(),
                    stato: document.getElementById(`checkCorrezioneC${testo.num}`).ej2_instances[0].checked === true ? '1' : '0',
                    dataInizio: moment(new Date()).format('YYYY-MM-DD HH:mm').toString()
                };
            }
            else {
                jsonObject2 = {
                    QueryExec: `update accorrezioni set stato = @stato, updated_at = @dataFine where id = ${testo.idUpdate};`,
                    stato: document.getElementById(`checkCorrezioneC${testo.num}`).ej2_instances[0].checked === true ? '1' : '0',
                    dataFine: moment(new Date()).format('YYYY-MM-DD HH:mm').toString()
                };
            }
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject2), retData2 => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })
        this.caricaDati();
        toast.success("Informazioni per consegna salvate!", {
            containerId: 'toastContainer1',
            onClose: () => toast.dismiss(),
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true
        }
        );
    }

    //----------------------------TERMINA CONSEGNA FINE----------------------------
    distruggiDialog() {
        if (document.getElementById('dialogCorrezione'))
            document.getElementById('dialogCorrezione').ej2_instances[0]?.destroy();
        if (document.getElementById('dialogCorrezione2'))
            document.getElementById('dialogCorrezione2').ej2_instances[0]?.destroy();
        if (document.getElementById('dialogCorrezione3'))
            document.getElementById('dialogCorrezione3').ej2_instances[0]?.destroy();
        if (document.getElementById('dialogCorrezioneAllegatiPdf'))
            document.getElementById('dialogCorrezioneAllegatiPdf').ej2_instances[0]?.destroy();
    }
}


