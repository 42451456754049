/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { Query } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { getValue } from '@syncfusion/ej2-base';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

export default class PaginaTipiPolizza extends SampleBase {
    constructor() {
        super(...arguments);
        this.dsCompagnia = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_compagnie'))?.map(elm => { return { VALUE: elm.id_compagnia, TEXT: elm.compagnia } });
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.childKey = 0;
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.filterSettings = { type: 'CheckBox' };
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        //compagnie
        this.DdlObj3 = null;
        this.elemSelezCompagnia = null;
        this.editTemplate3 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => { return this.DdlObj3.value },
            destroy: () => { this.DdlObj3.destroy() },
            write: (args) => {
                this.elemSelezCompagnia = args.rowData.id_compagnia; this.DdlObj3 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Compagnia', popupHeight: '200px',
                    dataSource: this.dsCompagnia, value: args.rowData.compagnia, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.compagnia = args2.value;
                        this.elemSelezCompagnia = args2.value;
                        let ddlObj = document.getElementById('gridAreeid_divisione').ej2_instances[0];
                        ddlObj.enabled = !CustomJS.isNullEmpty(args2.value);
                        let tempQuery = new Query().where('ID_COMPAGNIA', 'equal', args2.value);
                        ddlObj.query = tempQuery;
                        ddlObj.value = args2.itemData.VALUE;
                        ddlObj.text = args2.itemData.TEXT;
                        ddlObj.dataBind();
                    }
                });
                if (CustomJS.isNullEmpty(this.DdlObj3.value)) {
                    if (!CustomJS.isNullEmpty(args.rowData.id_compagnia)) {
                        this.DdlObj3.value = args.rowData.id_compagnia.toString();
                    }
                }
                this.DdlObj3.appendTo(args.element)
            }
        };
    }
    state = {
        blocking: false,
        showDialog1: false,
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    customizeCell(args) {
        if (args.column.field === "id_compagnia" && args.data && args.cell) {
            args.cell.innerText = '';
            if (getValue('id_compagnia', args.data)) {
                this.dsCompagnia.forEach(element => {
                    if (element.VALUE === getValue('id_compagnia', args.data).toString()) {
                        args.cell.innerHTML = element.TEXT;
                    }
                });
            }
        }
    }
    actionComplete(args) {
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            var dataObj = {}
            if (CustomJS.isNullEmpty(args.data[0]))
                dataObj = args.data;
            else
                dataObj = args.data[0];
            const jsonObject = {
                CodTab: '_tipo_polizza',
                Azione: args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista',
                id_tipo_polizza: CustomJS.isNullEmpty(dataObj.id_tipo_polizza) ? '0' : (dataObj.id_tipo_polizza).toString(),
                id_compagnia: CustomJS.isNullEmpty(dataObj.id_compagnia) ? '0' : (dataObj.id_compagnia).toString(),
                tipo_polizza: CustomJS.isNullEmpty(dataObj.tipo_polizza) ? '0' : (dataObj.tipo_polizza),
            }
            CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
            if (args.action === "add" || args.action === 'edit') {
                setTimeout(() => {
                    this.caricaDati();
                }, 600);
            }
        }
    }
    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                {/* <DialogComponent id="defaultDialog" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialog => this.dialogInstance1 = dialog} isModal={true} height='80%' width='90%' minHeight='90%'>
                    <div id='defaultDialogContent'></div>
                </DialogComponent> */}
                <div className='container-fluid'>
                    <div className='row' >
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' >
                            <GridComponent width={'100%'} id="gridTipiPolizza" queryCellInfo={this.customizeCell.bind(this)} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} hierarchyPrintMode='All' allowSelection={true} enableHover={false} showColumnMenu={false} showColumnChooser={false}
                                allowTextWrap={true} allowPaging={true} toolbarClick={this.toolbarClick.bind(this)} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings}
                                allowGrouping={true} allowExcelExport={true} editSettings={this.editSettings} filterSettings={this.filterSettings} style={{ border: '0px' }} actionComplete={this.actionComplete.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field='tipo_polizza' validationRules={{ required: true }} headerText="Tipo Polizza" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_tipo_polizza' headerText='ID' width='10%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_compagnia' edit={this.editTemplate3} headerText="Compagnia" width='20%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, Edit, ExcelExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        // document.getElementById('titoloPagina').innerText = 'Tipi Polizza';
        //sessionStorage.setItem('BtnSblocca', false);
        setTimeout(() => {
            this.caricaDati();
        }, 300);
    }
    caricaDati() {
        this.toggleBlocking();
        CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, '_tipo_polizza', false, retData => {
            document.getElementById('gridTipiPolizza').ej2_instances[0].dataSource = JSON.parse(retData.data);
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, 'SELECT T1.* FROM _tipo_polizza T1 ORDER BY id_tipo_polizza DESC;', null);
    }
}