/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { Query } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { TextBox } from '@syncfusion/ej2-inputs';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TabComponent, TabItemDirective, TabItemsDirective, TabAnimationSettings } from '@syncfusion/ej2-react-navigations';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { SplitButtonComponent, ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { TextBoxComponent, NumericTextBoxComponent, MaskedTextBox, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent, DateRangePickerComponent, Inject as DTPInject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { RichTextEditorComponent, RichTextEditor, Toolbar as RTEtoolbar, Inject as RTEInject, Image, Link, HtmlEditor, Count, QuickToolbar, Table } from '@syncfusion/ej2-react-richtexteditor';
import { Browser, getValue } from '@syncfusion/ej2-base';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';
import PaginaDettaglioSinistro from './PaginaDettaglioSinistro';
import $ from 'jquery';
import LavoriInCorso from '../assets/LavoriInCorso2.jpg'


function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

export default class PaginaTabelleStandard extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.childKey = 0;
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.filterSettings = { type: 'CheckBox' };
    }
    state = {
        blocking: false,
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
            <div style={{ textAlign: 'center' }}><img src={LavoriInCorso} width='70%' height='auto'></img></div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        // document.getElementById('titoloPagina').innerText = 'Tabelle Standard';
        //sessionStorage.setItem('BtnSblocca', false);
        setTimeout(() => {
            this.caricaDati();
        }, 300);
    }
    caricaDati() {
    }

}
