/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { createRoot } from 'react-dom/client'
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import LogoACE from '../assets/LogoACE.jpeg';
import _ from 'underscore';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import Mappa from './Mappa'
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_amcharts from "@amcharts/amcharts4/themes/amcharts"
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { CallbackWrapper } from 'react-callback';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page } from '@syncfusion/ej2-react-grids';
import PaginaDettaglioSinistro from './PaginaDettaglioSinistro';
import { random, isUndefined } from 'underscore';
import BlockUi from 'react-block-ui';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate2(props) {
    return (
        <div><span style={{ fontSize: '0.7vw', fontWeight: 'bolder', fontFamily: 'Muli, sans-serif' }}>{props[props.column.field]}</span></div>
    );
}

export default class Home extends SampleBase {
    constructor() {
        super(...arguments);
        this.childKey = 0;
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.LogoSocieta = LogoACE;
        this.tooltip = { enable: true };
        this.legendSettings = { visible: true, position: 'Bottom' };
        this.animationSettings = { effect: 'FadeZoom' };
        this.filterSettings = { type: 'Excel' };
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        this.situazSinData = [
            { x: 'Da contattare', y: 16 },
            { x: 'Sopralluogo', y: 16 },
            { x: 'In Attesa', y: 16 },
            { x: 'Da redigere', y: 16 },
            { x: 'In revisione', y: 16 },
            { x: 'Da chiudere', y: 20 }
        ]
        //migliore performance, mese corrente e due precedenti al mese corrente
        this.chiusiMeseData = [
            { x: 'Migliore Performance (mese,anno)', y: 100 },
            { x: 'Giugno', y: 60 },
            { x: 'Luglio', y: 65 },
            { x: 'Mese Corrente (agosto)', y: 40 }
        ]
        this.percScaricoData = [
            { scarico: 'Scarico RE', perito: 65, media: 72 },
            { scarico: 'Scarico RC', perito: 61, media: 65 }]

        this.liquidMedioData = [
            { liquidato: 'Liquidato medio RE', perito: 980, media: 850 },
            { liquidato: 'Liquidato medio RC', perito: 685, media: 720 }]

        this.idUser = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user;

    }

    state = {
        blocking: false,
        showDialog: false,
    }

    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toggleBlocking2(show) {
        let container, root;
        if (show) {
            document.getElementById('blockUIGenerico').style.removeProperty('display');
            document.getElementById('spinnerUIGenerico').style.removeProperty('display');
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
        }
        else {
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.unmount();
            document.getElementById('blockUIGenerico').style.display = 'none';
            document.getElementById('spinnerUIGenerico').style.display = 'none';
        }
    }

    indirizziMappa = [];
    indirizziMappaCorretti = [];

    creaSerieGrafici(chart, tipoChart, category, value, name, isOrizzontale) {
        var series = '';
        if (tipoChart === "ColumnSeries3D") {
            series = chart.series.push(new am4charts.ColumnSeries3D())
            if (isOrizzontale === true) {
                series.dataFields.valueX = value;
                series.dataFields.categoryY = category;
                series.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
            }
            else {
                series.dataFields.valueY = value;
                series.dataFields.categoryX = category;
                series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
            }
            series.name = name;
            series.columns.template.fillOpacity = 0.9;
        }
        else if (tipoChart === "ConeSeries") {
            series = chart.series.push(new am4charts.ConeSeries());
            series.dataFields.valueY = value;
            series.dataFields.categoryX = category;
            series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
            series.name = name;
            series.columns.template.fillOpacity = 0.9;
        }
        else {
            series = chart.series.push(new am4charts.PieSeries3D());
            series.dataFields.value = value;
            series.dataFields.category = category;
            series.slices.template.tooltipText = "{category}: [bold]{value}[/]";
        }
        return series;
    }

    apriSinistro(args) {
        this.toggleBlocking2(true)
        ++this.childKey;
        const container = document.getElementById('contenutoDinamico2');
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
            this.distruggiDialog()
            this.toggleBlocking2(false)
        }}><PaginaDettaglioSinistro key={this.childKey} IdSinistro={args.rowData.id_sinistro} IdCompagnia={args.rowData.id_compagnia}></PaginaDettaglioSinistro></CallbackWrapper>);
    }


    restituitiCQ_CR(tipo) {
        this.dialogDashboard.header = `<div><span id='dialog-title-${this.dialogDashboard.id}' class='title'>Sinistri restituiti dal ${tipo}</span></div>`;
        this.dialogDashboard.width = '80%';
        this.dialogDashboard.height = '90%';

        this.dialogDashboard.beforeOpen = (args) => {
            const container = document.getElementById('dialogDashboardContainer');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogDashboard.open = (args) => {
            const container = document.getElementById('dialogDashboardContainer');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                if (tipo === 'CQ') {
                    if (this.dataSourceCQ[0].num_cq > 0)
                        document.getElementById('gridRestituiti').ej2_instances[0].dataSource = this.dataSourceCQ
                    else
                        document.getElementById('sinistriRestituitiCQ').ej2_instances[0].disabled = true
                }
                else if (tipo === 'CR') {
                    if (this.dataSourceCR[0].num_cr > 0)
                        document.getElementById('gridRestituiti').ej2_instances[0].dataSource = this.dataSourceCR
                    else
                        document.getElementById('sinistriRestituitiCR').ej2_instances[0].disabled = true
                }
            }}><>
                    <GridComponent id="gridRestituiti" recordDoubleClick={this.apriSinistro.bind(this)} ref={grid => this.gridInstanceRestituiti = grid} hierarchyPrintMode='All'
                        allowSelection={true} enableHover={false} allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={true}
                        enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings} allowGrouping={false} allowExcelExport={true} filterSettings={this.filterSettings} >
                        <ColumnsDirective>
                            <ColumnDirective field='id_sinistro' headerText='ID' width='8%' headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate2} clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='id_compagnia' visible={false} headerText="Compagnia" width='15%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='compagnia' headerText="Compagnia" width='15%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            <ColumnDirective field='contraente' headerText="Contraente" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='tipo_sinistro' headerText="Tipo" width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='data_inc_per' headerText="Data Incarico" width='10%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='data_sop' headerText="Sopralluogo" width='15%' headerTemplate={headerTemplate} type='datetime' format='dd/MM/yyyy' textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='pol_num' headerText="Polizza" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='luo_ind' headerText="Indirizzo" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='luo_cit' headerText="Città" width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='luo_pro' headerText="Provincia" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                            <ColumnDirective field='luo_cap' headerText="CAP" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                    </GridComponent></></CallbackWrapper>);
        };
        this.dialogDashboard.show();
    }

    render() {
        return (<React.Fragment>
            <div id='blockUIGenerico' style={{ display: 'none' }}></div>
            <div id='spinnerUIGenerico' style={{ display: 'none' }} role="status"></div>
            <BlockUi id='blockUIPaginaHome' style={{ overflowX: 'hidden' }} tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <DialogComponent id="dialogDashboard" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog}
                    ref={dialog => this.dialogDashboard = dialog} isModal={true}>
                    <div id='dialogDashboardContainer'></div>
                </DialogComponent>
                <div className='container-fluid'>
                    <div className='row' >
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">
                            <div id='dashboardPage'>
                                <div className='row' >
                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                        <ButtonComponent id='mexDaLeggere' type='button' cssClass='e-fill e-info'>Messaggi da Leggere : 0</ButtonComponent>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" >
                                        <ButtonComponent id='interlocutorie' type='button' cssClass='e-fill e-info'>Interlocutorie da inviare : 0</ButtonComponent>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" >
                                        <ButtonComponent onClick={this.restituitiCQ_CR.bind(this, 'CQ')} id='sinistriRestituitiCQ' type='button' cssClass='e-fill e-info'>ID restituiti dal CQ : 0</ButtonComponent>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                        <ButtonComponent onClick={this.restituitiCQ_CR.bind(this, 'CR')} id='sinistriRestituitiCR' type='button' cssClass='e-fill e-info'>ID restituiti dal CR : 0</ButtonComponent>
                                    </div>
                                </div>
                                <div className='row' >
                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ marginTop: '0.5%', marginRight: '-1%' }}>
                                        <h6>Sopralluoghi da effettuare:</h6>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                        <CheckBoxComponent label="Da effettuare" checked={true} />
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                        <CheckBoxComponent label="Programmati" checked={true} />
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                        <CheckBoxComponent label="Tutto lo studio" checked={false} />
                                    </div>
                                </div>
                                <div id='boxMappe'>
                                    <div className='row' style={{ marginLeft: '0.5%' }}>
                                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                                            <ButtonComponent onClick={this.caricaMappa.bind(this, "satellite")} >Satellitare</ButtonComponent>
                                        </div><div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                                            <ButtonComponent onClick={this.caricaMappa.bind(this, "street")} >Stradale</ButtonComponent>
                                        </div>
                                    </div>
                                    <div className='row' >
                                        <div id='divMappa' className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <div id='caricamentoMappa' style={{ textAlign: 'center' }}>
                                                <h3>Attendere il caricamento della Mappa...</h3>
                                                <div id='mioSpinner' className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row' style={{ margin: 'auto', marginTop: '30px' }} >
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 dashRowDiv" style={{ textAlign: 'center' }}>
                                        <h4>Situazione Sinistri</h4>
                                        <div id="situazioneSinistri3D" style={{ width: "100%", height: "450px" }}></div>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 dashRowDiv" style={{ textAlign: 'center' }}>
                                        <h4>Chiusi nel mese</h4>
                                        <div id="chiusiMesi3D" style={{ width: "100%", height: "450px" }}></div>
                                    </div>
                                </div>
                                <div className='row' style={{ margin: 'auto' }} >
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 dashRowDiv" style={{ textAlign: 'center' }}>
                                        <h4>Percentuale di Scarico</h4>
                                        <div id="percScaricoChart" style={{ width: "100%", height: "450px" }}></div>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 dashRowDiv" style={{ textAlign: 'center' }}>
                                        <h4>Liquidato Medio</h4>
                                        <div id="liquidatoMedioChart" style={{ width: "100%", height: "450px" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment >);
    }
    // rendereComplete() {
    //     document.getElementById('titoloPagina').innerText = 'Dashboard';
    // }

    componentDidMount() {
        sessionStorage.setItem('BtnSblocca', false);
        document.getElementById('titoloPagina').innerText = 'Pagina Principale';
        if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 1) {
            $("#btnMappa").hide();
            $("#boxMappe").hide();
            $("#checkMappa").hide();
        }
        setTimeout(() => {
            this.caricaDati();
        }, 100)

        am4core.useTheme(am4themes_animated);
        am4core.useTheme(am4themes_amcharts);

        //CHART 3D SITUAZIONE SINISTRI
        let chart = am4core.create("situazioneSinistri3D", am4charts.PieChart3D);
        chart.legend = new am4charts.Legend();
        chart.data = this.situazSinData;
        this.creaSerieGrafici(chart, "PieSeries3D", "x", "y", "", false)

        //CHART 3D CHIUSI MESE
        let chart2 = am4core.create("chiusiMesi3D", am4charts.XYChart3D);
        chart2.data = this.chiusiMeseData;
        let categoryAxis2 = chart2.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis2.dataFields.category = "x";
        let valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
        let series2 = this.creaSerieGrafici(chart2, "ColumnSeries3D", "x", "y", "", false)
        let columnTemplate2 = series2.columns.template;
        columnTemplate2.adapter.add("fill", function (fill, target) {
            return chart2.colors.getIndex(target.dataItem.index);
        })
        columnTemplate2.adapter.add("stroke", function (stroke, target) {
            return chart2.colors.getIndex(target.dataItem.index);
        })

        //CHART 3D PERCENTUALE SCARICO 
        var chart3 = am4core.create("percScaricoChart", am4charts.XYChart3D);
        chart3.data = this.percScaricoData;
        chart3.legend = new am4charts.Legend();
        var categoryAxis3 = chart3.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis3.dataFields.category = "scarico";
        categoryAxis3.marginTop = 60;
        categoryAxis3.title.text = " ";
        categoryAxis3.renderer.inside = true;
        categoryAxis3.renderer.grid.template.disabled = false;
        var valueAxis3 = chart3.yAxes.push(new am4charts.ValueAxis());
        valueAxis3.renderer.grid.template.disabled = false;
        valueAxis3.numberFormatter.numberFormat = "#'%'";
        this.creaSerieGrafici(chart3, "ConeSeries", "scarico", "perito", "Perito", false)
        this.creaSerieGrafici(chart3, "ConeSeries", "scarico", "media", "Media", false)

        //CHART 3D LIQUIDATO MEDIO 
        var chart4 = am4core.create("liquidatoMedioChart", am4charts.XYChart3D);
        chart4.legend = new am4charts.Legend();
        chart4.data = this.liquidMedioData;
        let categoryAxis4 = chart4.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis4.dataFields.category = "liquidato";
        categoryAxis4.numberFormatter.numberFormat = "#";
        categoryAxis4.renderer.inversed = true;
        categoryAxis4.numberFormatter.numberFormat = "'€'#.00";
        chart4.xAxes.push(new am4charts.ValueAxis());
        this.creaSerieGrafici(chart4, "ColumnSeries3D", "liquidato", "perito", "Perito", true);
        this.creaSerieGrafici(chart4, "ColumnSeries3D", "liquidato", "media", "Media", true);
    }
    caricaMappa(tipoMappa, args) {
        if (document.getElementById('divMappa')) {
            ++this.childKey
            const container = document.getElementById('divMappa');
            const root = createRoot(container);
            root.render(<Mappa key={this.childKey} tuttiGliIndirizzi={this.indirizziMappaCorretti} tipoMappa={tipoMappa} ></Mappa>);
        }
    }
    caricaDati() {
        const QueryExecCQ = (`SELECT distinct COUNT(*) as num_cq FROM acsinistri T1 left outer join _log_correzione T2 on T1.id_sinistro = T2.id_sinistro left outer join _user T3 on T2.id_correttore = T3.id_user left outer join _compagnie T4 on T1.id_compagnia = T4.id_compagnia left outer join accontraenti T5 on T1.id_contraente = T5.id_contraente left outer join _tipo_sinistro T6 on T1.id_tipo_sinistro = T6.id_tipo_sinistro where T1.id_perito = ${this.idUser} and  (T1.id_stato = '14' or T1.id_stato = '13') and T2.in_correzione = 0 and T3.profilo = 8;`);
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecCQ, '', retDataCq => {
            retDataCq = JSON.parse(retDataCq.data);
            if (!CustomJS.isNullEmpty(retDataCq)) {
                if (document.getElementById('sinistriRestituitiCQ')) {
                    document.getElementById('sinistriRestituitiCQ').innerText = `ID restituiti dal CQ : ${retDataCq[0].num_cq}`
                    this.dataSourceCQ = retDataCq;
                }
            }

            const QueryExecCR = (`SELECT distinct COUNT(*) as num_cr FROM acsinistri T1 left outer join _log_correzione T2 on T1.id_sinistro = T2.id_sinistro left outer join _user T3 on T2.id_correttore = T3.id_user left outer join _compagnie T4 on T1.id_compagnia = T4.id_compagnia left outer join accontraenti T5 on T1.id_contraente = T5.id_contraente left outer join _tipo_sinistro T6 on T1.id_tipo_sinistro = T6.id_tipo_sinistro where T1.id_perito = ${this.idUser} and  (T1.id_stato = '14' or T1.id_stato = '13') and T2.in_correzione = 0 and T3.profilo = 9;`);
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecCR, '', retDataCr => {
                retDataCr = JSON.parse(retDataCr.data);
                if (!CustomJS.isNullEmpty(retDataCr)) {
                    if (document.getElementById('sinistriRestituitiCR')) {
                        document.getElementById('sinistriRestituitiCR').innerText = `ID restituiti dal CR : ${retDataCr[0].num_cr}`
                        this.dataSourceCR = retDataCr;
                    }
                }

                const QueryExec = (`SELECT T1.*, T2.danneggiato FROM acsinistri T1 INNER JOIN acdanneggiati T2 ON T1.id_sinistro = T2.id_sinistro WHERE T1.id_perito = ${JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].id_user} LIMIT 10;`);
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                    retData = JSON.parse(retData.data);
                    if (retData.length > 0) {
                        retData.forEach(elm => {
                            //nei retdata manca la regione da mettere come primo dato!!
                            var dato = ["", elm.luo_cit, elm.luo_pro, elm.luo_cap, elm.luo_ind, elm.danneggiato]
                            this.indirizziMappa.push(dato)
                        });
                        this.indirizziMappa.forEach(elm => {
                            var objIndirizzoCorr = {
                                regione: elm[0],
                                citta: elm[1],
                                provincia: elm[2],
                                cap: elm[3],
                                indirizzo: elm[4],
                                danneggiato: elm[5]
                            }
                            this.indirizziMappaCorretti.push(objIndirizzoCorr);
                        })
                    }
                    if (JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOAuthenticationPayload'))[0].profilo === 9)
                        this.caricaMappa('street');
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                });
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            });
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }
    distruggiDialog() {
        if (document.getElementById('dialogDashboard'))
            document.getElementById('dialogDashboard').ej2_instances[0]?.destroy();
    }
}