/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import * as CustomJS from '../Custom';
import BlockUi from 'react-block-ui';
import { Layout } from './Layout';
import LogoJellyfish from '../assets/xpertv3.png';
import LogoDigitalPraesidium from '../assets/LogoDigitalPraesidium.png';
import AmbienteTest from '../assets/AmbienteTest.png';
import RigaIndirizzoDP from '../assets/RigaIndirizzoDP.png';
import 'react-block-ui/style.css';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { random, isUndefined } from 'underscore';
import $ from 'jquery';
import _ from 'underscore/underscore-min';
export default class Login extends Component {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.childKey = 0;
        this.id_user = '';
        //da aggiungere _user
        this.tabList = ['_compagnie', '_aree', '_agenzie', '_broker', '_divisioni', '_periti', '_tipo_sinistro', '_amministratori', '_referenti', '_tipo_polizza', '_sedi', '_liquidatori', '_teamleader', '_tipo_perito', '_stati', '_param_user', 'GEN_um', 'GEN_esito', 'GEN_liquidazione', 'GEN_beneficiario', 'GEN_partita', 'GEN_forma_ass', 'GEN_garanzia', 'GEN_stato_rischio', 'Determ_danno', 'GEN_servizio', 'GEN_pagamento', 'GEN_esito_danno', 'Prezziario', 'GEN_voce', 'Compagnie_prefissi', 'GEN_CodiciAnia', 'GEN_InformazioniRichiesteSuDanno', 'GEN_RisposteInformazioniRichiesteSuDanno', 'GEN_InformazioniRichiesteRiepilogo', 'GEN_RisposteInformazioniRichiesteRiepilogo', 'GEN_formula_ass', 'GEN_garanzia_ric', 'GEN_partita_ric', 'GEN_gruppi_gar', 'GEN_forma_ass', 'GEN_formula_ass', 'UNI_esito_contatto', '_garanzie', '_partite_app', '_um_prezziario', 'UNI_stato_fabbricato', 'UNI_esito_perizia', 'UNI_motivo_mancata_definizione', 'UNI_motivo_negativa_videoper', 'Codici_comuni', 'UNI_motivo_interlocutoria', '_modello_interlocutoria', 'UNIPOLSAI_Partite', 'GEN_causale_non_ind', 'accorrezioni_tipi', '_gradi', 'CompagnieReferente', '_cat_moduli', '_param_moduli', 'UNI_motivo_rivalsa'];
        this.queryList = [
            {
                NomeTabella: '_stati',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'stati_sinistro';"
            },
            {
                NomeTabella: '_periti',
                QueryExec: "SELECT * FROM _user WHERE profilo = 9 AND bloccato = 0 ORDER BY denom ASC;"
            },
            {
                NomeTabella: '_amministratori',
                QueryExec: "SELECT DISTINCT T1.id_contraente, T1.contraente FROM accontraenti T1 INNER JOIN acsinistri T2 ON T1.id_contraente = T2.id_amministratore"
            },
            {
                NomeTabella: '_referenti',
                QueryExec: "SELECT DISTINCT T1.id_user, T1.denom FROM _user T1 WHERE T1.bloccato = 0 AND T1.ck_referente = 1 ORDER BY T1.denom ASC;"
            },
            {
                NomeTabella: '_sedi',
                QueryExec: "SELECT DISTINCT T1.valore, T1.idparam FROM _param_user T1 WHERE tipo = 'sede';"
            },
            {
                NomeTabella: '_liquidatori',
                QueryExec: "SELECT DISTINCT T1.id_liq, T1.nome_liq, T1.cognome_liq, T1.id_compagnia FROM acliquidatori T1;"
            },
            {
                NomeTabella: '_teamleader',
                QueryExec: "SELECT id_team_leader, denom FROM _user WHERE team_leader = 1;"
            },
            {
                NomeTabella: '_param_user',
                QueryExec: "SELECT * FROM _param_user WHERE tipo = 'classe';"
            },
            {
                NomeTabella: 'GEN_um',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_um';"
            },
            {
                NomeTabella: 'GEN_esito',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_esito';"
            },
            {
                NomeTabella: 'GEN_liquidazione',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_liquidazione';"
            },
            {
                NomeTabella: 'GEN_beneficiario',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_beneficiario';"
            },
            {
                NomeTabella: 'GEN_partita',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_partita';"
            },
            {
                NomeTabella: 'GEN_forma_ass',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_forma_ass';"
            },
            {
                NomeTabella: 'GEN_garanzia',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_garanzia';"
            },
            {
                NomeTabella: 'GEN_stato_rischio',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_stato_rischio';"
            },
            {
                NomeTabella: 'Determ_danno',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'determ_danno';"
            },
            {
                NomeTabella: 'GEN_servizio',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_servizio';"
            },
            {
                NomeTabella: 'GEN_pagamento',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_pagamento';"
            },
            {
                NomeTabella: 'GEN_esito_danno',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_esito_danno';"
            },
            {
                NomeTabella: 'Prezziario',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'prezziario';"
            },
            {
                NomeTabella: 'GEN_voce',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_voce';"
            },
            {
                NomeTabella: 'Compagnie_prefissi',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'compagnie_prefissi';"
            },
            {
                NomeTabella: 'GEN_CodiciAnia',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'codici_ANIA';"
            },

            {
                NomeTabella: 'GEN_InformazioniRichiesteSuDanno',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_info_rq_danno';"
            },
            {
                NomeTabella: 'GEN_RisposteInformazioniRichiesteSuDanno',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_risp_info_rq_danno';"
            },
            {
                NomeTabella: 'GEN_InformazioniRichiesteRiepilogo',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_info_rq_riep';"
            },
            {
                NomeTabella: 'GEN_RisposteInformazioniRichiesteRiepilogo',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_risp_info_rq_riep';"
            },
            {
                NomeTabella: 'GEN_formula_ass',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_formula_ass';"
            },
            {
                NomeTabella: 'GEN_garanzia_ric',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'gen_garanzia_ric';"
            },
            {
                NomeTabella: 'GEN_partita_ric',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'gen_partita_ric';"
            },
            {
                NomeTabella: 'GEN_gruppi_gar',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_gruppi_gar';"
            },
            {
                NomeTabella: 'GEN_forma_ass',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_forma_ass';"
            },
            {
                NomeTabella: 'GEN_formula_ass',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_formula_ass';"
            },
            {
                NomeTabella: 'UNI_esito_contatto',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'UNI_esito_contatto';"
            },
            {
                NomeTabella: '_garanzie',
                QueryExec: "SELECT * FROM _garanzie WHERE visibile = 1;"
            },
            {
                NomeTabella: '_partite_app',
                QueryExec: "SELECT * FROM _partite_app WHERE visibile = 1;"
            },
            {
                NomeTabella: '_um_prezziario',
                QueryExec: "SELECT distinct SERVIZIO_STR2 FROM ELEMENTITBSTD WHERE COD_TAB = 'prezziario';"
            },
            {
                NomeTabella: 'UNI_stato_fabbricato',
                QueryExec: "SELECT * FROM ELEMENTITBSTD where COD_TAB = 'UNI_stato_fabbricato'"
            },
            {
                NomeTabella: 'UNI_esito_perizia',
                QueryExec: "SELECT * FROM ELEMENTITBSTD where COD_TAB = 'UNI_esito_perizia'"
            },
            {
                NomeTabella: 'UNI_motivo_mancata_definizione',
                QueryExec: "SELECT * FROM ELEMENTITBSTD where COD_TAB = 'UNI_motivo_mancata_definizione'"
            },
            {
                NomeTabella: 'UNI_motivo_negativa_videoper',
                QueryExec: "SELECT * FROM ELEMENTITBSTD where COD_TAB = 'UNI_motivo_negativa_videoper'"
            },
            {
                NomeTabella: 'Codici_comuni',
                QueryExec: "SELECT COD_ELE, DESCRIZ, SERVIZIO_STR1, SERVIZIO_STR2, SERVIZIO_STR3 FROM ELEMENTITBSTD where COD_TAB = 'codici_comuni'"
            },
            {
                NomeTabella: 'UNI_motivo_interlocutoria',
                QueryExec: "SELECT COD_ELE, DESCRIZ FROM ELEMENTITBSTD WHERE COD_TAB = 'UNI_motivo_interlocutoria'"
            },
            {
                NomeTabella: 'UNI_motivo_rivalsa',
                QueryExec: "SELECT COD_ELE, DESCRIZ FROM ELEMENTITBSTD WHERE COD_TAB = 'UNI_motivo_rivalsa'"
            },
            {
                NomeTabella: '_modello_interlocutoria',
                QueryExec: "SELECT id, testo_select, testo_completo FROM _modelli_interlocutorie"
            },
            {
                NomeTabella: 'UNIPOLSAI_Partite',
                QueryExec: "SELECT * FROM UNIPOLSAI_Partite"
            },
            {
                NomeTabella: 'GEN_causale_non_ind',
                QueryExec: "SELECT * FROM ELEMENTITBSTD WHERE COD_TAB = 'GEN_causale_non_ind';"
            },
            {
                NomeTabella: 'accorrezioni_tipi',
                QueryExec: "SELECT * FROM accorrezioni_tipi;"
            },
            {
                NomeTabella: '_gradi',
                QueryExec: "SELECT * FROM _gradi;"
            },
            {
                NomeTabella: 'CompagnieReferente',
                QueryExec: ''
            }
        ];
    }
    state = {
        blocking: false
    }

    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    eseguiLogin() {
        if (ValidateForm()) {
            let dataObj = {
                CodUtente: document.getElementById("UserCode").value.trim(),
                CodAccesso: document.getElementById("Password").value.trim()
            };
            this.toggleBlocking();
            CustomJS.callAPI(`AccessoDatiSIPAEVO/EseguiLogin`, JSON.stringify(dataObj),
                'POST', 'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    if (retData.response === 'OK') {
                        const obj = _.find(this.queryList, {NomeTabella: 'CompagnieReferente'}).QueryExec = `SELECT T2.id_compagnia, T3.compagnia FROM _user T1 LEFT OUTER JOIN _user_compagnie T2 ON T1.id_user = T2.id_user INNER JOIN _compagnie T3 ON T2.id_compagnia = T3.id_compagnia WHERE T1.profilo = 9 AND T1.bloccato = 0 AND T1.ck_referente = 1 AND T1.id_user = '${JSON.parse(retData.data)[0].id_user}';`;
                        let retDataTemp = JSON.parse(retData.data);
                        retDataTemp.forEach(a=>{
                            Object.keys(a).forEach(b=>{
                              if('note_user' === b)
                                 delete(a[b]);
                            })
                        });
                        sessionStorage.setItem('JellyfishSIPAEVOAuthenticationPayload', JSON.stringify(retDataTemp));
                        this.tabList.forEach(elm => {
                            CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, elm, true, retData => {
                                sessionStorage.setItem('JellyfishSIPAEVOTabella' + elm, retData.data);
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 7000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, _.find(this.queryList, elm2 => { return elm2.NomeTabella === elm })?.QueryExec, null);
                        });
                        setTimeout(() => {
                            this.toggleBlocking();
                            ++this.childKey;
                            $(document).unbind("keypress");
                            const container = document.getElementById('contenutoDinamico1');
                            const root = createRoot(container);
                            root.render(<CallbackWrapper callback={() => {
                                sessionStorage.setItem("JellyfishXpertSessionTimeout", false);
                            }}><Layout key={this.childKey}></Layout></CallbackWrapper>);
                        }, this.tabList.length * 50);
                        sessionStorage.setItem("JellyfishSIPAEVOFiltroComboSegreteria", 'assegnare');
                        sessionStorage.setItem("JellyfishSIPAEVOFiltroComboPerito", 'carico');
                    }
                    else if (retData.response === 'KO') {
                        toast.warn(retData.msg === 'UtenteNonTrovato' ? CustomJS.messaggioUtenteNonTrovato : CustomJS.messaggioErroreNoDBSIPA, {
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking();
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                }, true, false)
        }
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUILogin' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer containerId='toastContfainer1' transition={Zoom} style={{ fontSize: '1.9vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                {/* <div id="bgImageDiv"></div> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="offset-md-4 col-md-4 offset-lg-4 col-lg-4 offset-sm-2 col-sm-8 offset-xs-1 col-xs-10">
                            <img src={LogoJellyfish} style={{ maxWidth: '95%', height: 'auto' }} />
                        </div>
                    </div>
                    <div className="row" id='contentLoginForm'>
                        <div className="offset-md-3 col-md-4 offset-lg-4 col-lg-4 offset-sm-2 col-sm-8 offset-xs-1 col-xs-10">
                            <div className="containerLogin body-content-login">
                                <div id="login-box">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-1">
                                            <h2 style={{ color: '#f0f0e1', fontWeight: 'bold' }}>Login</h2>
                                        </div>
                                        <div className="offset-lg-5 col-lg-3 offset-md-5 col-md-3 offset-sm-5 col-sm-3 offset-xs-3 col-xs-3 text-end" style={{ fontSize: '1.1em', color: '#f0f0e1', fontWeight: 'bolder' }}>
                                            Rel. 1.0.0
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <h6 style={{ color: '#234599', fontWeight: 'bold' }}>Inserire le credenziali d'accesso</h6>
                                    <input type='text' id='UserCode' className='form-login' style={{ color: '#133B95', width: '350px', fontWeight: 'bold' }} placeholder='Codice Utente'></input>
                                    <br />
                                    <br />
                                    <br />
                                    <input type='password' id='Password' className='form-login' style={{ color: '#133B95', width: '350px', fontWeight: 'bold' }} placeholder='Password'></input>
                                    <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-center">
                                            <input type="button" id="BtnLogin" value="LOGIN" className="btn btn-primary btn-lg btn-default-login" onClick={this.eseguiLogin.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="divAmbienteTest" className="row">
                        <div className="offset-md-4 col-md-4 offset-lg-4 col-lg-4 offset-sm-2 col-sm-8 offset-xs-1 col-xs-10">
                            <img src={AmbienteTest} style={{ verticalAlign: 'top', paddingLeft: '100px' }} />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-3 col-xs-3">
                            <img src={LogoDigitalPraesidium} style={{ width: '70%', height: 'auto', verticalAlign: 'top' }} />
                        </div>
                        <div className="offset-lg-4 col-lg-4 offset-md-4 col-md-4 offset-sm-6 col-sm-3 offset-xs-6 col-xs-3">
                            <img src={RigaIndirizzoDP} style={{ width: '90%', height: 'auto', verticalAlign: 'baseline', marginTop: '18.20%' }} />
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        let sessioneScaduta = sessionStorage.getItem("JellyfishXpertSessionTimeout");
        if (sessioneScaduta === 'true') {
            let messaggio = CustomJS.messaggioSessioneScaduta;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                toastId: "sessione-scaduta-msg",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            sessionStorage.setItem("JellyfishXpertSessionTimeout", false)
        }
        sessionStorage.setItem("JellyfishXpertPaginaLogin", "true");
        $(document).on('keypress',
            event => {
                if (event.key === 'Enter') {
                    this.eseguiLogin();
                }
            });
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
        window.onbeforeunload = function () {
            if (sessionStorage.getItem("JellyfishXpertPaginaLogin") === "false")
                return "Attenzione!!! se lasci questa pagina perderai tutte le modifiche fatte e sarai portato alla pagina di Login. Sei sicuro/a di volerlo fare?";
        };
        $(document).on("keydown", disableF5);
        $("#UserCode").focus();
        localStorage.setItem("gridGridSituazioneSinistri", "");
    }
}

function ValidateForm() {
    if ($("#UserCode").val().trim() === "") {
        $("#UserCode").attr("placeholder", 'Codice Utente');
        $("#UserCode").css("border-color", "red");
        $("#UserCode").css("border-width", "3px");
        return false;
    }
    else {
        $("#UserCode").css("border-color", "#133B95");
        $("#UserCode").css("border-width", "1px");
    }

    if ($("#Password").val().trim() === "") {
        $("#Password").attr("placeholder", 'Password');
        $("#Password").css("border-color", "red");
        $("#Password").css("border-width", "3px");
        return false;
    }
    else {
        $("#Password").css("border-color", "#133B95");
        $("#Password").css("border-width", "1px");
    }

    return true;
}

function disableF5(e) {
    if ((e.which || e.keyCode) === 116) e.preventDefault();
}