/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

export default class PaginaCompagnie extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.childKey = 0;
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog' };
        this.editSettingsPerito = { allowEditing: true, mode: 'Normal' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.filterSettings = { type: 'CheckBox' };
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        //insert province
        this.DdlObj = null;
        this.DdlObj2 = null;
        this.elemSelezProvincia = null;
        this.editTemplate2 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => { return this.DdlObj2.value },
            destroy: () => { this.DdlObj2.destroy() },
            write: (args) => {
                this.elemSelezProvincia = args.rowData.provincia; this.DdlObj2 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Provincia', popupHeight: '200px',
                    dataSource: JSON.parse(sessionStorage.getItem('JellyfishXpertTabellaProvince')), value: args.rowData.provincia, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.provincia = args2.value;
                        this.elemSelezProvincia = args2.value;
                    }
                });
                if (this.DdlObj2.value === null) {
                    this.DdlObj2.value = args.rowData.prov;
                }
                this.DdlObj2.appendTo(args.element)
            }
        };
    }
    state = {
        blocking: false,
        showDialog1: false,
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            args.dialog.allowDragging = false;
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuova Compagnia' : `Dettaglio di ${args.rowData.compagnia}`;
        }
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            var dataObj = {}
            if (CustomJS.isNullEmpty(args.data[0]))
                dataObj = args.data;
            else
                dataObj = args.data[0];
            const jsonObject = {
                CodTab: '_compagnie',
                Azione: args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista',
                id_compagnia: CustomJS.isNullEmpty(dataObj.id_compagnia) ? null : (dataObj.id_compagnia).toString(),
                compagnia: CustomJS.isNullEmpty(dataObj.compagnia) ? "" : dataObj.compagnia,
                indir: CustomJS.isNullEmpty(dataObj.indir) ? "" : dataObj.indir,
                citta: CustomJS.isNullEmpty(dataObj.citta) ? "" : dataObj.citta,
                prov: CustomJS.isNullEmpty(dataObj.prov) ? "" : dataObj.prov,
                cap: CustomJS.isNullEmpty(dataObj.cap) ? "" : dataObj.cap,
            }
            CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
            if (args.action === "add" || args.action === 'edit') {
                setTimeout(() => {
                    this.caricaDati();
                }, 600);
            }
        }
    }
    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className='container-fluid'>
                    <div className='row' >
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <GridComponent width={'100%'}id="gridCompagnie" ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} hierarchyPrintMode='All' allowSelection={true} enableHover={false} showColumnMenu={false} showColumnChooser={false}
                                allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} allowPaging={true} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings}
                                allowGrouping={true} allowExcelExport={true} editSettings={this.editSettings} filterSettings={this.filterSettings} style={{ border: '0px' }}
                                actionComplete={this.actionComplete.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field='compagnia' validationRules={{ required: true }} headerText="Compagnia" width='20%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_compagnia' headerText='ID' width='10%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='indir' headerText="Indirizzo" width='20%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='citta' headerText="Città" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='prov' headerText="Provincia" width='10%' edit={this.editTemplate2} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='bonusCompagnia' headerText="Bonus Compagnia" width='15%' visible={false} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cap' headerText="CAP" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, Edit, ExcelExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        // document.getElementById('titoloPagina').innerText = 'Compagnie';
        //sessionStorage.setItem('BtnSblocca', false);
        setTimeout(() => {
            this.caricaDati();
        }, 300);
    }
    caricaDati() {
        this.toggleBlocking();
        CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, '_compagnie', false, retData => {
            document.getElementById('gridCompagnie').ej2_instances[0].dataSource = JSON.parse(retData.data);
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, 'SELECT T1.* FROM _compagnie T1 ORDER BY id_compagnia DESC;', null);
    }
}
