/* eslint-disable default-case */
import { Ajax, Browser } from '@syncfusion/ej2-base';
import $ from 'jquery';
import _ from 'underscore/underscore-min';
import axios from 'axios';
import * as Loader from "react-loader-spinner";
import pako from 'pako';
import { Buffer } from 'buffer';

let qs = require('qs');

export const URLApplicazioneSvil = 'https://localhost:44367';
export const URLApplicazioneTest = 'http://62.149.181.213:8070';
export const URLApplicazioneProd = 'https://xpert.jfish.it';
export const APIVariousJfishXpertURLSvil = 'https://localhost:5001';
export const APIVariousJfishXpertURLTest = 'https://apicallertest3.digitalpraesidium.it';
export const APIVariousJfishXpertURLProd = 'https://apicaller3.digitalpraesidium.it';
export const CartellaTemporaneaUpload = 'UploadTemp';
export const CartellaDocumentazione = 'DocumentazioneIncarichi';
export const TokenAuthGoogleCalendar = '7b0bbb9a809d223e4ca78962ec495bd4512386da26d41de13ef11aea83b50835';
export const tokenAuthAPIJellyfishSIPAEVO = 'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLasguuy553344ssszxbznbxYZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSIKijhyG77S6IkphdmFJblVzZSIsImV4c6iaWF0IjQ0NjM2fQ.uZHXEhsghgHgdEaQllPgHJJGGFGD8JKuGWPJfjaiHzBjhlsHQhhWjeUaVqMyF5YIQ';
export const tokenAuthAPIVarious = 'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNjY0NDYzNiwiaWF0IjoxNjM2NjQ0NjM2fQ.uZHXED8JKuGWPJfjaiHzBjhlsHQhhWjeUaVqMyF5YIQ';
export const CartellaTemporaneaACE = "Temp";
export const messaggioNoBackend = 'Attenzione!! Nessuna comunicazione attiva con il backend. Se il problema persiste, contattare il servizio di supporto Digital Praesidium.'
export const messaggioAPIBackendNonTrovata = 'Attenzione!! Comunicazione attiva con il backend ma API non trovata. Contattare il servizio di supporto Digital Praesidium.';
export const messaggioUtenteNonAutorizzato = 'Attenzione!! Utente non autorizzato alle operazioni di Jellyfish XPERT. Prima di effettuare qualsiasi operazione è necessario accedere al sistema!!';
export const messaggioUtenteNonTrovato = 'Codice Utente e/o Password errati!!';
export const messaggioErroreNoDBSIPA = 'Attenzione!! Comunicazione attiva con il backend ma NON con la base dati SIPA. Se il problema persiste, contattare il servizio di supporto Digital Praesidium.';
export const messaggioSessioneScaduta = 'Attenzione!! La sessione di collegamento è scaduta (durata massima prevista di 4 ore di inattività completa). Accedere nuovamente per continuare le operazioni!!';
export const messaggioConfermaUscita = 'Tutti i dati non salvati andranno persi. Vuoi continuare?';
export const messaggioConfermaSblocco = 'Sbloccare solo se strettamente necessario. Vuoi continuare?';
export const messaggioConfermaFirmaCliente = 'Confermi di voler acquisire la firma di fine lavori del cliente?';
export const messaggioErroreObbligo = 'Non è possibile proseguire con il salvataggio in quanto alcuni dei campi obbligatori non sono stati compilati oppure sono presenti errori formali sulla pagina.';
export const messaggioImportaSelezionatiOK = 'Le operazioni di importazione degli incarichi e relativo invio delle comunicazioni ad UnipolSAI, sono state effettuate con successo per i seguenti incarichi :';
export const messaggioImportaSelezionatiKO = 'Attenzione!! I seguenti incarichi hanno riscontrato problemi durante le operazioni di importazione :';
export const timeout = 1000 * 60 * 60 ; //1 ora di inattività
export const widthScreenResolution = window.screen.width * window.devicePixelRatio;
export const heightScreenResolution = window.screen.height * window.devicePixelRatio;
export const chartWidth = Browser.isDevice ? '92%' : '96%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '85%' :  '80%' : Browser.userAgent.indexOf('Firefox') > -1 ? '105%' :  '110%'; 
export const chartWidth2 = Browser.isDevice ? '92%' : '96%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '115%' :  '120%' : Browser.userAgent.indexOf('Firefox') > -1 ? '95%' :  '100%';
export const chartWidth3 = Browser.isDevice ? '92%' : '96%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '129%' :  '136%' : Browser.userAgent.indexOf('Firefox') > -1 ? '109%' :  '116%';
let chartHeightTemp = Browser.userAgent.indexOf('Firefox') > -1 ? '580' : Browser.userAgent.indexOf('Edg') > -1 ? '585' : Browser.userAgent.indexOf('OPR') > -1 ? '590' : '590';
let chartHeightTemp2 = Browser.userAgent.indexOf('Firefox') > -1 ? '755' : Browser.userAgent.indexOf('Edg') > -1 ? '760' : Browser.userAgent.indexOf('OPR') > -1 ? '760' : '760';
export const chartHeight = (parseInt(chartHeightTemp) - ((1080 - heightScreenResolution) === 0 ? 0 : (1080 - heightScreenResolution) < 0 ? (1080 - heightScreenResolution) : (1080 - heightScreenResolution + 50))).toString();
export const chartHeight2 = (parseInt(chartHeightTemp2) - ((1080 - heightScreenResolution) === 0 ? 0 : (1080 - heightScreenResolution) < 0 ? (1080 - heightScreenResolution) : (1080 - heightScreenResolution + 50))).toString();
export const colorsData = [
    { Color: 'AliceBlue', Code: '#F0F8FF' },
    { Color: 'Aqua', Code: '#00FFFF' },
    { Color: 'Aquamarine', Code: '#7FFFD4' },
    { Color: 'Beige', Code: '#F5F5DC' },
    { Color: 'BurlyWood', Code: '#DEB887' },
    { Color: 'Chartreuse', Code: '#7FFF00' },
    { Color: 'Coral', Code: '#FF7F50' },
    { Color: 'Cornsilk', Code: '#FFF8DC' },
    { Color: 'LightSalmon', Code: '#FFA07A' },
    { Color: 'DarkTurquoise', Code: '#00CED1' },
    { Color: 'DodgerBlue', Code: '#1E90FF' },
    { Color: 'Gainsboro', Code: '#DCDCDC' },
    { Color: 'Gold', Code: '#FFD700' },
    { Color: 'GreenYellow', Code: '#ADFF2F' },
    { Color: 'Khaki', Code: '#F0E68C' },
    { Color: 'Lavender', Code: '#E6E6FA' },
    { Color: 'LawnGreen', Code: '#7CFC00' },
    { Color: 'LightBlue', Code: '#ADD8E6' },
    { Color: 'MediumSpringGreen', Code: '#00FA9A' },
    { Color: 'MistyRose', Code: '#FFE4E1' },
    { Color: 'Plum', Code: '#DDA0DD' },
    { Color: 'SandyBrown', Code: '#F4A460' },
    { Color: 'Thistle', Code: '#D8BFD8' },
    { Color: 'Turquoise', Code: '#40E0D0' },
    { Color: 'Yellow', Code: '#FFFF00' },
    { Color: 'Tomato', Code: '#FF745C' },
    { Color: 'Violet', Code: '#EE82EE' },
];
export const loaderSpinners = [<Loader.Audio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.BallTriangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Bars width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Blocks width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Circles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.CirclesWithBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.ColorRing width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Comment width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.Discuss width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, //<Loader.Dna width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.FallingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.FidgetSpinner width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Grid width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.InfinitySpin width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.LineWave width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.MagnifyingGlass width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.MutatingDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Oval width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.ProgressBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Puff width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Radio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RevolvingDot width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Rings width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.RotatingSquare width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingTriangles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.TailSpin width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.ThreeCircles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.ThreeDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Triangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Vortex width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}/>, <Loader.Watch width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>];
export const codiceCompagniaGenerali = '103';
export const codiceCompagniaUnipolSAI = '131';

export async function getRemoteData(url, data, type, header) { //Chiamata con oggetti Syncfusion - Non funziona...dà errore a livello di CORS
    let ajax = new Ajax({
        url: url,
        type: type,
        mode: false,
        data: data,
        setRequestHeader: header,
        onFailure: (e) => { return false; }
    });

    const response = await ajax.send();
    let retData = JSON.parse(response);
    return { response: retData['response'], retData: retData['data'] };
}

export async function getRemoteData2(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Javascript puro XHR - Funziona bene
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = false;

    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
            if (test) {
                cbFunction(this.responseText);
            }
            else {
                let retData = JSON.parse(this.responseText);
                console.log(this.responseText);
                if (retData['response'].cod === 0)
                    cbFunction(retData['data']);
                else
                    console.log(retData['response'].message);
            }
        }
    });

    xhr.open(type, url);
    xhr.setRequestHeader("Content-Type", header);
    xhr.async = async;

    xhr.send(paramData);
}

export async function getRemoteData3(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Jquery - Funziona bene
    let settings = {
        "url": url,
        "method": type,
        "timeout": 0,
        "headers": header,
        "data": paramData,
        "async": async
    };

    $.ajax(settings).done((response) => {
        if (test) {
            cbFunction(response);
        }
        else {
            let retData = JSON.parse(response);
            console.log(response);
            if (retData['response'].cod === 0)
                cbFunction(retData['data']);
            else
                console.log(retData['response'].message);
        }
    }).fail((response) => {
        console.log(response);
    });
}

export async function getRemoteData4(url, paramData, type, header, cbFunctionOK, cbFunctionKO, async, test) {  //Chiamata Axios - Funziona bene (speriamo)
    let data = qs.stringify(paramData);
    let config = {
        method: type,
        url: url,
        headers: header,
        data: data
    };

    axios(config)
        .then(response => {
            debugger;
            if (test) {
                cbFunctionOK(response.data);
            }
            else {
                let retData = response.data;
                console.log(JSON.stringify(response.data));
                cbFunctionOK(retData);
            }
        })
        .catch(error => {
            console.log(error);
            cbFunctionKO(error);
        });
}

export async function callAPI(url, paramData, type, header, headerAuth, cbFunctionOK, cbFunctionKO, async, test) {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
  
    xhr.onerror = function(err){
      debugger;
      cbFunctionKO(err);
    };
  
    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4 && this.status === 200) {
        if (test) {
          cbFunctionOK(this.responseText);
        }
        else {
          let retData = isNullEmpty(this.responseText) ? [] : JSON.parse(this.responseText);        
          cbFunctionOK(retData);        
        }           
      }
      else if (this.readyState === 4 && this.status === 404)
          cbFunctionKO(this.responseText + this.statusText);
    });
    
    xhr.open(type, url);
    xhr.setRequestHeader("Content-Type", header);
    xhr.setRequestHeader("Authorization", headerAuth);
    xhr.async = async; 
  
    if (!isNullEmpty(paramData))
        xhr.send(paramData);  
    else
        xhr.send();
}

export async function eseguiQuery(URL, token, tipo, QueryExec, paramData, cbFunctionOK, cbFunctionKO, encodeURL, comprimi) {  
    let header = tipo === 'GET' ? '' : 'application/json';    
    //QueryExec = isNullEmpty(comprimi) || comprimi === false ? QueryExec : pako.gzip(QueryExec); 
    //console.log(QueryExec);
    QueryExec = isNullEmpty(comprimi) || comprimi === false ? QueryExec :  Buffer.from(pako.gzip(QueryExec)).toString('base64');  
    //QueryExec = isNullEmpty(comprimi) || comprimi === false ? QueryExec : Buffer.from(new Uint8Array(pako.gzip(QueryExec)).reduce((data,byte) => data + String.fromCharCode(byte),'')).toString('base64'); 
    //console.log(QueryExec);
    //QueryExec = isNullEmpty(comprimi) || comprimi === false ? QueryExec : btoa(String.fromCharCode(...QueryExec));
    QueryExec = isNullEmpty(encodeURL) || encodeURL === true ? encodeURIComponent(QueryExec) : QueryExec;
    //console.log(QueryExec);

    let ApiUrl = tipo === 'GET' ? `${URL}/EseguiQuery?QueryExec=${QueryExec}` : `${URL}/EseguiQuery`;
    callAPI(ApiUrl, paramData, tipo,
        header, token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? messaggioSessioneScaduta : retData.msg.message;
                cbFunctionKO(messaggio);
                return;
            }   
            cbFunctionOK(retData);
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            cbFunctionKO(messaggio);
    }, true, false)
}

export async function caricaCombo(URL, token, CodTab, InserisciRigaBlank, cbFunctionOK, cbFunctionKO, QueryExec, caricaDiretta) {
    QueryExec = isNullEmpty(QueryExec) ? 'NoQuery' : encodeURIComponent(QueryExec);       
    caricaDiretta = isNullEmpty(caricaDiretta) ? 'false' : caricaDiretta;
    callAPI(`${URL}/caricaCombo/${CodTab}/${InserisciRigaBlank}/${QueryExec}/${caricaDiretta}`, '', 'GET',
    '', token, retData => { 
        if (retData.response === 'KO') {
            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? messaggioSessioneScaduta : retData.msg;
            cbFunctionKO(messaggio);
            return;
        }   
        cbFunctionOK(retData);
    }, error => {
        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
        cbFunctionKO(messaggio);
    }, true, false)
}

export async function getProgressivo(URL, token, CodDiv, CodSoc, CodFil, TipoProgr, DatoRif, GestAnno, cbFunctionOK, cbFunctionKO) {
    callAPI(`${URL}/api/JellyfishTeams/getProgressivo/${CodDiv}/${CodSoc}/${CodFil}/${TipoProgr}/${DatoRif}/${GestAnno}`, '', 'GET',
    '', token, retData => { 
        if (retData.response === 'KO') {
            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? messaggioSessioneScaduta : retData.msg;
            cbFunctionKO(messaggio);
            return;
        }   
        cbFunctionOK(retData);
    }, error => {
        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
        cbFunctionKO(messaggio);
    }, true, false)
}

export function handleMouseMove(event) {
    var eventDoc, doc, body;

    event = event || window.event; // IE-ism

    // If pageX/Y aren't available and clientX/Y are,
    // calculate pageX/Y - logic taken from jQuery.
    // (This is to support old IE)
    if (event.pageX == null && event.clientX != null) {
        eventDoc = (event.target && event.target.ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        event.pageX = event.clientX +
            (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
            (doc && doc.clientLeft || body && body.clientLeft || 0);
        event.pageY = event.clientY +
            (doc && doc.scrollTop || body && body.scrollTop || 0) -
            (doc && doc.clientTop || body && body.clientTop || 0);
    }

    //console.log ('X: ' + event.pageX + ' - Y:' + event.pageY);

    //if (document.getElementById('TabMonitor').ej2_instances[0].selected<Index === 0 && event.pageX >= 680 && event.pageX <= 735 && event.pageY >= 620 && event.pageY <= 635) 
}

export function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

export function isNullEmpty(valore, multiArray) {
    try {
        if (multiArray) {
            var _wRet = false;
            for (var i = 0; i < valore.length; i++) {
                if (isNullEmpty(valore[i])) {
                    _wRet = true;
                    break;
                }
            }
            return _wRet;
        } else {
            switch (true) {
                case valore === null:
                    return true;
                    break;
                case Object.prototype.toString.call(valore) === '[object Date]':
                    return false;
                    break;
                case typeof valore === 'undefined':
                case typeof valore === 'null':
                    return true
                    break;
                case typeof valore === 'string':
                    if (valore.toString().trim() == "null" || valore.toString().trim() == "undefined") {
                        return true;
                    } else if (valore.toString().trim().length > 0) {
                        return false;
                    } else {
                        return true;
                    }
                    break;
                case typeof valore === 'boolean':
                    return false;
                    break;
                case typeof valore === 'number':
                    return false;
                    break;
                case typeof valore === 'object':
                    if (Object.keys(valore).length > 0) {
                        return false;
                    } else {
                        return true;
                    }
                    break;
            }
        }

    }
    catch (ex) {
        return true;
    }
}

export function setInterval_(fn, delay) {
    var maxDelay = Math.pow(2, 31) - 1;

    if (delay > maxDelay) {
        var args = arguments;
        args[1] -= maxDelay;

        return setInterval(function () {
            setInterval_.apply(undefined, args);
        }, maxDelay);
    }

    return setInterval.apply(undefined, arguments);
}

//http://byronsalau.com/blog/how-to-create-a-guid-uuid-in-javascript/  
export function createGuid4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}  

export function makeID(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 } 

 export function scrollAuto(speed, timeout, goBack, soloTop) {
    if (!soloTop) {
        $('html, body').animate({ scrollTop: $(document).height() - $(window).height() }, speed, function () {
            if (goBack)
                setTimeout(() => { $(this).animate({ scrollTop: 0 }, speed); }, timeout);
        });
    }
    else
        $('html, body').animate({ scrollTop: 0 }, speed);
}

export function calcolaDataFerialeSuccessiva(dataIniziale, giorniDaAggiungere) {
    var giorniAggiunti = 0;
    var dataTest = new Date(dataIniziale);
  
    while (giorniAggiunti < giorniDaAggiungere) {
      dataTest.setDate(dataTest.getDate() + 1);
  
      if (dataTest.getDay() >= 1 && dataTest.getDay() <= 5) {
        giorniAggiunti++;
      }
    }
  
    return dataTest;
  }

export function ControlloIBAN(IbanValue) {
    let CheckStr;
    let NewIBAN;
    let nASCII;
    let Resto = 0;
    let Cifra;
    let Quoziente;
    let Intero;
    let n;

    IbanValue.value = IbanValue.value.replace(/ /g, "");

    if (IbanValue.value.length !== 27) {
        return false;
    }
    NewIBAN = IbanValue.value.slice(-23) + IbanValue.value.slice(0, 4);
    CheckStr = "";
    for (let i = 0; i < 27; i++) {
        nASCII = NewIBAN.charCodeAt(i);
        if (nASCII >= 48 && nASCII <= 57)   // da 0 a 9
        {
            CheckStr = CheckStr + NewIBAN.charAt(i);
        }
        else if (nASCII >= 65 && nASCII <= 90)
        {
            CheckStr = CheckStr + (nASCII - 55).toString();
        }
        else
        {
            return false;
        }
    }
    n = 0;
    while (n <= CheckStr.length) {
        Cifra = parseFloat(Resto.toString() + CheckStr.slice(n, n+8));
        Quoziente = Cifra / 97;
        Intero = 97 * Math.floor(Quoziente);
        Resto = Cifra - Intero;
        n = n + 8;
    }
    if (Resto === 1) 
        return true;
    
    return false;
}