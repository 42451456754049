/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random, isUndefined } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bold', color: 'black' }}>{props.headerText}</span>
    </div>);
}

export default class PaginaAssicurati extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.childKey = 0;
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.filterSettings = { type: 'CheckBox' };
        this.pageSettings = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        //insert province
        this.DdlObj = null;
        this.DdlObj2 = null;
        this.elemSelezProvincia = null;
        this.editTemplate2 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => { return this.DdlObj2.value },
            destroy: () => { this.DdlObj2.destroy() },
            write: (args) => {
                this.elemSelezProvincia = args.rowData.provincia; this.DdlObj2 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Provincia', popupHeight: '200px',
                    dataSource: JSON.parse(sessionStorage.getItem('JellyfishXpertTabellaProvince')), value: args.rowData.provincia, floatLabelType: 'Auto',
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.provincia = args2.value;
                        this.elemSelezProvincia = args2.value;
                    }
                });
                if (this.DdlObj2.value === null) {
                    this.DdlObj2.value = args.rowData.prov;
                }
                this.DdlObj2.appendTo(args.element)
            }
        };
        this.isFromEdit = false;
        this.isFromAdd = false;
        this.IDAddOrEdit = {};
    }
    state = {
        blocking: false,
        showDialog1: false,
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    actionBegin(args) {
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
        }
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            args.dialog.allowDragging = false;
            var identificativo = '';
            if (args.rowData.contraente === '') {
                if (args.rowData.cognome === '')
                    identificativo = args.rowData.nome;
                else
                    identificativo = args.rowData.cognome;
            }
            else
                identificativo = args.rowData.contraente;
            this.IDAddOrEdit = args.rowData.id_contraente;
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo Assicurato' : `Dettaglio di ${identificativo}`;
        }
        if (args.requestType === 'searching') {
            this.toggleBlocking();
            const QueryExec = `SELECT T1.* FROM accontraenti T1 WHERE T1.id_contraente = '${args.searchString}' OR T1.contraente LIKE '%${args.searchString}%' OR T1.nome LIKE '%${args.searchString}%' OR T1.cognome LIKE '%${args.searchString}%' OR T1.email LIKE '%${args.searchString}%' OR T1.indir LIKE '%${args.searchString}%' OR T1.citta LIKE '%${args.searchString}%' OR T1.prov LIKE '%${args.searchString}%' OR T1.cap LIKE '%${args.searchString}%' OR T1.tel = '${args.searchString}' OR T1.cell = '${args.searchString}' OR T1.fax = '${args.searchString}' OR T1.codfis LIKE '%${args.searchString}%' OR T1.conto LIKE '%${args.searchString}%' OR T1.posta LIKE '%${args.searchString}%' ORDER BY id_contraente DESC;`;
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExec, '', retData => {
                if (JSON.parse(retData.data) === "[]")
                    document.getElementById('gridAssicurati').ej2_instances[0].dataSource = [];
                else
                    document.getElementById('gridAssicurati').ej2_instances[0].dataSource = JSON.parse(retData.data);
                this.toggleBlocking();
                if (this.isFromAdd === true) {
                    this.gridInstance.selectRow(0);
                    document.getElementById(`btnInserisciDefault${this.props.isPerito}`).click();
                    this.isFromAdd = false;
                }
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        }
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            var dataObj = {}
            if (CustomJS.isNullEmpty(args.data[0]))
                dataObj = args.data;
            else
                dataObj = args.data[0];
            const jsonObject = {
                CodTab: 'accontraenti',
                Azione: args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista',
                id_contraente: CustomJS.isNullEmpty(dataObj.id_contraente) ? null : dataObj.id_contraente.toString(),
                contraente: CustomJS.isNullEmpty(dataObj.contraente) ? "" : dataObj.contraente,
                nome: CustomJS.isNullEmpty(dataObj.nome) ? "" : dataObj.nome,
                cognome: CustomJS.isNullEmpty(dataObj.cognome) ? "" : dataObj.cognome,
                email: CustomJS.isNullEmpty(dataObj.email) ? "" : dataObj.email,
                indir: CustomJS.isNullEmpty(dataObj.indir) ? "" : dataObj.indir,
                citta: CustomJS.isNullEmpty(dataObj.citta) ? "" : dataObj.citta,
                prov: CustomJS.isNullEmpty(dataObj.prov) ? "" : dataObj.prov,
                cap: CustomJS.isNullEmpty(dataObj.cap) ? "" : dataObj.cap,
                tel: CustomJS.isNullEmpty(dataObj.tel) ? "" : dataObj.tel,
                cell: CustomJS.isNullEmpty(dataObj.cell) ? "" : dataObj.cell,
                iva: CustomJS.isNullEmpty(dataObj.iva) ? "" : dataObj.iva,
                codfis: CustomJS.isNullEmpty(dataObj.codfis) ? "" : dataObj.codfis,
            }
            CustomJS.callAPI(`AccessoDatiSIPAEVO/SalvaTabella`, JSON.stringify(jsonObject), 'POST',
                'application/json', CustomJS.tokenAuthAPIJellyfishSIPAEVO, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
            if (args.action === "add" || args.action === 'edit') {
                if (args.action === 'edit')
                    this.isFromEdit = true;
                else {
                    this.isFromAdd = true;
                }

                setTimeout(() => {
                    this.caricaDati();
                }, 600);
            }
        }
    }
    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className='container-fluid'>
                    <div className='row' id='gridSegreteria' >
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <GridComponent width={'100%'} id="gridAssicurati" ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} hierarchyPrintMode='All' allowSelection={true} enableHover={false} showColumnMenu={false} showColumnChooser={false}
                                allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} allowPaging={true} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings}
                                allowGrouping={true} allowExcelExport={true} editSettings={this.editSettings} filterSettings={this.filterSettings} style={{ border: '0px' }}
                                actionComplete={this.actionComplete.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field='contraente' validationRules={{ required: true }} headerText="Assicurato" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_contraente' headerText='ID' width='10%' headerTemplate={headerTemplate} visible={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='id_sinistro' visible={false} headerText="ID sinistro" width='15%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='nome' headerText="Nome" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cognome' headerText="Cognome" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='email' headerText="E-mail" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='indir' headerText="Indirizzo" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='citta' headerText="Città" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='prov' headerText="Prov." width='8%' edit={this.editTemplate2} headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cap' headerText="CAP" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='tel' headerText="Telefono" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cell' headerText="Cellulare" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='codfis' headerText="Cod. Fiscale" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='descdanno' headerText="Descrizione Danno" width='10%' headerTemplate={headerTemplate} visible={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='garanzia' headerText="Garanzia" width='10%' headerTemplate={headerTemplate} visible={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='dannolordo' headerText="Danno Lordo" width='10%' headerTemplate={headerTemplate} visible={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='dannonetto' headerText="Danno netto" width='10%' headerTemplate={headerTemplate} visible={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='iva' headerText="P.IVA" width='10%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, ExcelExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        // document.getElementById('titoloPagina').innerText = 'Assicurati';
        //sessionStorage.setItem('BtnSblocca', false);
        setTimeout(() => {
            this.caricaDati();
        }, 300);
    }

    caricaDati() {
        if (this.props.arrivaDaDetSin === true && this.isFromEdit === false && this.isFromAdd === false) {
            return;
        }
        else if (this.props.arrivaDaDetSin === true && this.isFromEdit === true) {
            this.toggleBlocking();
            this.isFromEdit = false;
            CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'accontraenti', false, retData => {
                retData = JSON.parse(retData.data);
                document.getElementById('gridAssicurati').ej2_instances[0].dataSource = retData;
                document.getElementById('gridAssicurati').ej2_instances[0].search(retData[0].contraente)
                this.toggleBlocking();
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            }, `SELECT DISTINCT T1.id_contraente, T1.contraente, T1.nome, T1.cognome,T1.email, T1.indir, T1.citta, T1.prov, T1.cap, T1.tel, T1.iva, T1.codfis FROM accontraenti T1 where id_contraente = ${this.IDAddOrEdit};`, null);
        }
        else {
            this.toggleBlocking();
            CustomJS.caricaCombo('AccessoDatiSIPAEVO', CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'accontraenti', false, retData => {
                document.getElementById('gridAssicurati').ej2_instances[0].dataSource = JSON.parse(retData.data);
                var retData = JSON.parse(retData.data);
                if (this.isFromAdd === true) {
                    document.getElementById('gridAssicurati').ej2_instances[0].search(retData[0].contraente);
                }
                this.toggleBlocking();
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            }, 'SELECT DISTINCT T1.id_contraente, T1.contraente, T1.nome, T1.cognome,T1.email, T1.indir, T1.citta, T1.prov, T1.cap, T1.tel, T1.iva, T1.codfis FROM accontraenti T1 ORDER BY id_contraente DESC LIMIT 10000;', null);
        }
    }
}

