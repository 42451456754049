/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Search, Edit, Inject, Group, Sort, Page } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent, ListBoxComponent, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import { Browser } from '@syncfusion/ej2-base';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { random } from 'underscore';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { MDBFooter } from 'mdb-react-ui-kit';
import { FormValidator } from '@syncfusion/ej2-inputs';
import { RichTextEditorComponent, Toolbar as RTEtoolbar, Inject as RTEInject, Image, Link, HtmlEditor, Count, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import PaginaDettaglioSinistro from './PaginaDettaglioSinistro';
import PaginaPeriziaOnline from './PaginaPeriziaOnline';



export default class PaginaPeriziaOnlineCalcoli extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Search'];
        this.childKey = 0;
        this.editSettings = { allowAdding: false, allowEditing: false, allowDeleting: false, showDeleteConfirmDialog: true, mode: 'Normal' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.searchSettings = { operator: 'contains', ignoreCase: true };
        this.pageSettings = { pageSize: 10, pageCount: 10, pageSizes: ["10", "25", "50", "Tutti"] };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.ddlFieldsList = { text: 'text', value: 'id' };
        this.formOptions = {
            rules: {
            }
        }
        this.RTEitems = ['Bold', 'Italic', 'Underline',
            'FontName', 'FontSize',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'CreateLink', 'Print', '|', 'Undo', 'Redo'
        ];
        this.RTEtoolbarSettings = {
            items: this.RTEitems
        };
        this.prefissoCompagnia = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabellaCompagnie_prefissi')).find(({ COD_ELE }) => COD_ELE === this.props.IdCompagnia.toString())?.DESCRIZ;
        this.nomeCompagnia = JSON.parse(sessionStorage.getItem('JellyfishSIPAEVOTabella_compagnie')).find(({ id_compagnia }) => id_compagnia === this.props.IdCompagnia.toString())?.compagnia;
        this.dsTipoServizio = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaGEN_servizio`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
        this.dsEsitoDanno = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaGEN_esito_danno`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
        this.dsCausaliNonIndennizzo = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaGEN_causale_non_ind`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
        this.animationSettings = { effect: 'FadeZoom' };
        if (this.isCompagniaGenerali()) {
            this.dsPartite = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_partita`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, GUID: '0000-0000-0000-0000', KEY: 0 } });
            this.dsGaranzie = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_garanzia`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, GUID: '0000-0000-0000-0000', SERVIZIO_STR2: elm.SERVIZIO_STR2 } });
            this.dsPrezziarioCategorie = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_voce`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } }); // Categorie dalla Gen_voce di Generali
            this.dsUM = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_um`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.COD_ELE } });
            this.dsCompagnie = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_CodiciAnia`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, SERVIZIO_STR1: elm.SERVIZIO_STR1 } });
            this.dsCompagnie.forEach(element => {
                if (element.SERVIZIO_STR1 === this.props.IdCompagnia.toString()) {
                    this.IdCompagniaGeneraliSuCodiciAnia = element.VALUE;
                    return;
                }
            });
            //Informazioni Richieste su Danno
            this.dsInformazioniRichiesteSuDanno = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_InformazioniRichiesteSuDanno`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, SERVIZIO_STR2: elm.SERVIZIO_STR2 } });
            this.nuovoArrInfoRichDanno = []
            var objNuovoInfoRicDanno = {}
            this.dsInformazioniRichiesteSuDanno.forEach(element => {
                var more = []
                if (element.SERVIZIO_STR2.includes(',')) {
                    more = element.SERVIZIO_STR2.split(',')
                    more.forEach(moreEle => {
                        objNuovoInfoRicDanno = {
                            COD_ELE: element.VALUE,
                            SERVIZIO_STR2: moreEle,
                            DESCRIZ: element.TEXT
                        };
                        this.nuovoArrInfoRichDanno.push(objNuovoInfoRicDanno)
                    });
                }
                else {
                    objNuovoInfoRicDanno = {
                        COD_ELE: element.VALUE,
                        SERVIZIO_STR2: element.SERVIZIO_STR2,
                        DESCRIZ: element.TEXT
                    };
                    this.nuovoArrInfoRichDanno.push(objNuovoInfoRicDanno)
                }
            });
            //Risposte Informazioi Richieste su Danno
            this.dsRisposteInformazioniRichiesteSuDanno = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_RisposteInformazioniRichiesteSuDanno`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, SERVIZIO_STR2: elm.SERVIZIO_STR2 } });
            this.nuovoArrRispInfoRichDanno = []
            var objNuovoRispInfoRicDanno = {}
            this.dsRisposteInformazioniRichiesteSuDanno.forEach(element => {
                var more = []
                if (element.SERVIZIO_STR2.includes(',')) {
                    more = element.SERVIZIO_STR2.split(',')
                    more.forEach(moreEle => {
                        objNuovoRispInfoRicDanno = {
                            COD_ELE: element.VALUE,
                            SERVIZIO_STR2: moreEle,
                            DESCRIZ: element.TEXT
                        };
                        this.nuovoArrRispInfoRichDanno.push(objNuovoRispInfoRicDanno)
                    });
                }
                else {
                    objNuovoRispInfoRicDanno = {
                        COD_ELE: element.VALUE,
                        SERVIZIO_STR2: element.SERVIZIO_STR2,
                        DESCRIZ: element.TEXT
                    };
                    this.nuovoArrRispInfoRichDanno.push(objNuovoRispInfoRicDanno)
                }
            });
            //Informazioni Richieste su Riepilogo
            this.dsInformazioniRichiesteRiepilogo = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_InformazioniRichiesteRiepilogo`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, SERVIZIO_STR2: elm.SERVIZIO_STR2, SERVIZIO_STR3: elm.SERVIZIO_STR3 } });
            this.nuovoArrInfoRichRiep = []
            var objNuovoInfoRicRiep = {}
            this.dsInformazioniRichiesteRiepilogo.forEach(element => {
                var more = []
                if (element.SERVIZIO_STR2.includes(',')) {
                    more = element.SERVIZIO_STR2.split(',')
                    more.forEach(moreEle => {
                        objNuovoInfoRicRiep = {
                            COD_ELE: element.VALUE,
                            DESCRIZ: element.TEXT,
                            SERVIZIO_STR2: moreEle,
                            SERVIZIO_STR3: element.SERVIZIO_STR3
                        };
                        this.nuovoArrInfoRichRiep.push(objNuovoInfoRicRiep)
                    });
                }
                else {
                    objNuovoInfoRicRiep = {
                        COD_ELE: element.VALUE,
                        DESCRIZ: element.TEXT,
                        SERVIZIO_STR2: element.SERVIZIO_STR2,
                        SERVIZIO_STR3: element.SERVIZIO_STR3
                    };
                    this.nuovoArrInfoRichRiep.push(objNuovoInfoRicRiep)
                }
            });
            //Risposte Informazioni Richieste su Riepilogo
            this.dsRisposteInformazioniRichiesteRiepilogo = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella${this.prefissoCompagnia}_RisposteInformazioniRichiesteRiepilogo`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ, SERVIZIO_STR2: elm.SERVIZIO_STR2 } });
            this.nuovoArrRispInfoRicRiep = []
            var objNuovoRispInfoRicRiep = {}
            this.dsRisposteInformazioniRichiesteRiepilogo.forEach(element => {
                var more = []
                if (element.SERVIZIO_STR2.includes(',')) {
                    more = element.SERVIZIO_STR2.split(',')
                    more.forEach(moreEle => {
                        objNuovoRispInfoRicRiep = {
                            COD_ELE: element.VALUE,
                            SERVIZIO_STR2: moreEle,
                            DESCRIZ: element.TEXT
                        };
                        this.nuovoArrRispInfoRicRiep.push(objNuovoRispInfoRicRiep)
                    });
                }
                else {
                    objNuovoRispInfoRicRiep = {
                        COD_ELE: element.VALUE,
                        SERVIZIO_STR2: element.SERVIZIO_STR2,
                        DESCRIZ: element.TEXT
                    };
                    this.nuovoArrRispInfoRicRiep.push(objNuovoRispInfoRicRiep)
                }
            });
        }
        //--------------------DS BASE DI TUTTE LE ALTRE COMPAGNIE INIZIO--------------------
        else {
            if (this.isCompagniaUnipol())
                this.dsPartite = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaUNIPOLSAI_Partite`))?.map(elm => { return { VALUE: elm.id_partita.toString(), TEXT: elm.partita, TIPO_SIN: elm.id_tipo_sinistro } });
            else
                this.dsPartite = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_partite_app`))?.map(elm => { return { VALUE: elm.id_partita_app.toString(), TEXT: elm.partita_app, TIPO_SIN: elm.id_tipo_sinistro } });
            // this.dsPartiteFiltrato = []
            // this.dsPartite.forEach(elm => {
            //     var more = []
            //     if (elm.TIPO_SIN.includes(',')) {
            //         more = elm.TIPO_SIN.split(',');
            //         more.forEach(moreEle => {
            //             if (moreEle === this.props.tipoSinistro) {
            //                 var obj = {
            //                     VALUE: elm.VALUE,
            //                     TEXT: elm.TEXT,
            //                 };
            //                 this.dsPartiteFiltrato.push(obj)
            //             }
            //         });
            //     }
            // })
            // this.dsPartite = this.dsPartiteFiltrato;
            // if (this.dsPartite.length === 0)
            //     this.dsPartite = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_partite_app`))?.map(elm => { return { VALUE: elm.id_partita_app.toString(), TEXT: elm.partita_app, TIPO_SIN: elm.id_tipo_sinistro } });
            this.dsGaranzie = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_garanzie`))?.map(elm => { return { VALUE: elm.id_garanzia.toString(), TEXT: elm.garanzia, TIPO_SIN: elm.id_tipo_sinistro.toString() } });
            // this.dsGaranzieFiltrato = []
            // this.dsGaranzie.forEach(elm => {
            //     if (elm.TIPO_SIN === this.props.tipoSinistro) {
            //         var obj = {
            //             VALUE: elm.VALUE,
            //             TEXT: elm.TEXT,
            //         };
            //         this.dsGaranzieFiltrato.push(elm)
            //     }
            // })
            // this.dsGaranzie = this.dsGaranzieFiltrato;
            // if (this.dsGaranzie.length === 0)
            //     this.dsGaranzie = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_garanzie`))?.map(elm => { return { VALUE: elm.id_garanzia.toString(), TEXT: elm.garanzia, TIPO_SIN: elm.id_tipo_sinistro.toString() } });
            this.dsUM = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_um_prezziario`))?.map(elm => { return { VALUE: elm.SERVIZIO_STR2, TEXT: elm.SERVIZIO_STR2 } });
            //DS di Generali che non sono utilizzati ma devono essere instanziati
            this.dsPrezziarioCategorie = []
            this.dsCompagnie = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabella_compagnie`))?.map(elm => { return { VALUE: elm.id_compagnia, TEXT: elm.compagnia } });

            this.dsInformazioniRichiesteSuDanno = []
            this.nuovoArrInfoRichDanno = []
            this.dsRisposteInformazioniRichiesteSuDanno = [];
            this.nuovoArrRispInfoRichDanno = []
            this.dsInformazioniRichiesteRiepilogo = [];
            this.nuovoArrInfoRichRiep = []
            this.dsRisposteInformazioniRichiesteRiepilogo = []
            this.nuovoArrRispInfoRicRiep = [];
        }
        //DS CONDIVISI CON TUTTE LE COMPAGNIE
        this.dsDeterminazioneDanno = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaDeterm_danno`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
        this.dsPrezziarioCompleto = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaPrezziario`))?.map(elm => { return { COD_ELE: elm.COD_ELE, DESCR_LUNGA: elm.SERVIZIO_STR3, DESCRIZ_CORTA: elm.DESCRIZ, COD_CATEGORIA: elm.SERVIZIO_STR1, MISURA: elm.SERVIZIO_STR2, PREZZO: elm.SERVIZIO_NUM1 } }); // TEXT = Descrizione Lunga
        this.dsPrezziarioDescrizioniLunghe = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaPrezziario`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.SERVIZIO_STR3 } }); // TEXT = Descrizione Lunga
        //DS DI GENERALI CONDIVISI CON TUTTE LE COMPAGNIE
        this.dsFormaGaranzia = JSON.parse(sessionStorage.getItem(`JellyfishSIPAEVOTabellaGEN_forma_ass`))?.map(elm => { return { VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ } });
        //DS DI UTILITY
        this.dsDanneggiati = [];
        this.dsLimitePer = [
            { TEXT: 'Anno', VALUE: 'anno' },
            { TEXT: 'Sinistro', VALUE: 'sinistro' },
        ]
        this.dsFranchigiaOrScoperto = [
            { TEXT: 'Franchigia', VALUE: 'franchigia' },
            { TEXT: 'Scoperto', VALUE: 'scoperto' },
        ]
        this.limitiContrattTipiCoass = [
            { TEXT: 'Franchigia €', VALUE: 'franchigia' },
            { TEXT: 'Scoperto %', VALUE: 'scoperto' },
            { TEXT: 'Limite Indennizzo €', VALUE: 'limite_indennizzo' },
            { TEXT: 'Nessuno', VALUE: 'nessuno' }
        ];
        //Numero di ogni combo per ciclare i box
        this.numPreesistenze = 1;
        this.numRighePreesCostruzioni = 1;
        this.numRighePreesDescrizioni = 1;
        this.numStime = 1;
        this.numTabPrezziari = 1;
        this.numRigheCoassicurazione = 0;
        this.numTabelleRiepilogo = 0;
        this.numRigheRiepilogoGaranzie = 0;
        this.numListboxGaranzia = 2;
        this.numListboxRiepilogo = 3;

        //VARIABILI DI UTILITY
        //altre
        this.allArrayLocalizzazioneForeachGaranzia = [];

        //STRUTTURA INTERNA STATI OPERAZIONI CRUD
        this.strutturaStatiPreesistenzeBase = [];
        this.strutturaStatiPreesistenzePRA = [];
        this.strutturaStatiPreesistenzeCostruzioni = [];
        this.strutturaStatiPreesistenzeDescrizioni = [];
        this.strutturaStatiStimeBase = [];
        this.strutturaStatiStimePrezziari = [];
        this.strutturaStatiCoassicurazioniRighe = [];
        this.strutturaStatiInfoRichiesteDelete = []
        this.strutturaStatiCausali = []

        //Info richieste array di utility per salvataggio e caricamento
        this.nuovoNumIdPrezziario = 0;
        this.strutturaRiepilogoDelete = {
            IdRiepilogo: '',
            IdDanneggiati: [],
            IdRighe: []
        }
        this.riservaCe = false;
        this.contraenteRiserva = "";
        this.tipoServizio = "";
        this.franchigeCondivise = []
        this.scopertiCondivisi = []
        this.periziaModificata = false;

        this.strutturaStatiEliminaDati = {
            idPreesistenzaXpert: [],
            idPreesistenzaCostruz: [],
            idPreesistenzaDescr: [],
            idPreesistenzaGen: [],
            idStimeDescrizioni: [],
            idDannoDettInfoRichGen: [],
            idCoassicurazioni: [],
            idInfoRichieste: [],
            idDanneggiatiGen: [],
            idRiepilogoPerizia: 0,
            idRiepilogoDanneggiati: [],
            idRiepilogoRigheDanneggiato: [],
            idCausali: []
        }
    }
    state = {
        blocking: false,
        showDialog0: false
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    toggleBlocking2(show) {
        let container, root;
        if (show) {
            document.getElementById('blockUIGenerico').style.removeProperty('display');
            document.getElementById('spinnerUIGenerico').style.removeProperty('display');
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
        }
        else {
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.unmount();
            document.getElementById('blockUIGenerico').style.display = 'none';
            document.getElementById('spinnerUIGenerico').style.display = 'none';
        }
    }

    //--------------------FUNZIONI DI UTILITY INIZIO----------------
    rimuoviDaStrutturaStatiElementoNuovoEliminato(value, index, arr) {
        // If the value at the current array index matches the specified value
        if (value.stato_iniziale === 'n' && value.stato === 'd') {
            // Removes the value from the original array
            arr.splice(index, 1);
            return;
        }
        return;
    }

    sinistroChiuso() {
        document.getElementById('statoNonModificabilePeritoP2').innerText = "Sinistro non modificabile!"
        document.getElementById(`salvaEsciP2`).ej2_instances[0].disabled = true;

        for (var i = 0; i < this.numPreesistenze; i++) {
            if (document.getElementById(`tabellaCompletaPreesistenza-T${i}`)) {
                if (document.getElementById(`aggiornaPreesistenza-T${i}`))
                    document.getElementById(`aggiornaPreesistenza-T${i}`).ej2_instances[0].disabled = true;
                //BLOCCO RIGHE COSTRUZIONI
                if (document.getElementById(`btnAggiungiRigaPartFabb-T${i}-R-0`))
                    document.getElementById(`btnAggiungiRigaPartFabb-T${i}-R-0`).ej2_instances[0].disabled = true;
                this.strutturaStatiPreesistenzeCostruzioni.forEach(costruz => {
                    if (document.getElementById(`rigaPreesistenzaCostruzione-T${i}-R-${costruz.r}`)) {
                        document.getElementById(`btnAggiungiRigaPartFabb-T${i}-R-${costruz.r}`).ej2_instances[0].disabled = true
                        if (document.getElementById(`btnEliminaRigaPreesCostruzione-T${i}-R-${costruz.r}`))
                            document.getElementById(`btnEliminaRigaPreesCostruzione-T${i}-R-${costruz.r}`).ej2_instances[0].disabled = true
                    }
                })
                //BLOCCO RIGHE DESCRIZIONI
                if (document.getElementById(`btnAggiungiRigaPreesDescr-T${i}-R-0`))
                    document.getElementById(`btnAggiungiRigaPreesDescr-T${i}-R-0`).ej2_instances[0].disabled = true;
                this.strutturaStatiPreesistenzeDescrizioni.forEach(descr => {
                    if (document.getElementById(`rigaPreesDescrizione-T${i}-R-${descr.r}`)) {
                        document.getElementById(`btnAggiungiRigaPreesDescr-T${i}-R-${descr.r}`).ej2_instances[0].disabled = true
                        if (document.getElementById(`btnEiminaRigaPreesDescr-T${i}-R-${descr.r}`))
                            document.getElementById(`btnEiminaRigaPreesDescr-T${i}-R-${descr.r}`).ej2_instances[0].disabled = true
                    }
                })
            }
        }

        for (var i = 0; i < this.numStime; i++) {
            if (document.getElementById(`tabellaStima-D${i}`)) {
                //BLOCCO CAMPI STIMA
                if (document.getElementById(`determinazioneDanno_D${i}`).ej2_instances[0].value === '001')
                    document.getElementById(`aggiornaTabellaStimaValoreANuovo-D${i}`).ej2_instances[0].disabled = true;
                else if (document.getElementById(`determinazioneDanno_D${i}`).ej2_instances[0].value === '003')
                    document.getElementById(`aggiornaTabellaStimaStatoUso-D${i}`).ej2_instances[0].disabled = true;

                document.getElementById(`btnAggiungiRigaStimaPrezziario-D${i}`).ej2_instances[0].disabled = true
                document.getElementById(`btnFranchigiaManuale_D${i}`).ej2_instances[0].disabled = true

                if (this.isCompagniaGenerali()) {
                    document.getElementById(`esitoDanno_D${i}`).ej2_instances[0].readonly = true
                    document.getElementById(`accordoAniaSi-D${i}`).ej2_instances[0].disabled = true
                    document.getElementById(`accordoAniaNo-D${i}`).ej2_instances[0].disabled = true
                    document.getElementById(`ripristiniUltimSi-D${i}`).ej2_instances[0].disabled = true
                    document.getElementById(`ripristiniUltimNo-D${i}`).ej2_instances[0].disabled = true

                    document.getElementById(`noteRipristini-D${i}`).ej2_instances[0].readonly = true
                }
                document.getElementById(`coassicurazioneSi-D${i}`).ej2_instances[0].disabled = true
                document.getElementById(`coassicurazioneNo-D${i}`).ej2_instances[0].disabled = true

                //BLOCCO RIGHE PREZZIARIO
                this.strutturaStatiStimePrezziari.forEach(prezz => {
                    if (document.getElementById(`tabPrezziario-D${i}-R-${prezz.r}`)) {
                        document.getElementById(`btnCambiaDescriz-D${i}-R${prezz.r}`).ej2_instances[0].disabled = true
                        document.getElementById(`btnApriPrezziario-D${i}-R${prezz.r}`).ej2_instances[0].disabled = true
                        document.getElementById(`btnAggiungiRigaStimaPrezziario-D${i}-R${prezz.r}`).ej2_instances[0].disabled = true
                        if (document.getElementById(`btnEliminaRigaStimaPrezziario-D${i}-R${prezz.r}`))
                            document.getElementById(`btnEliminaRigaStimaPrezziario-D${i}-R${prezz.r}`).ej2_instances[0].disabled = true
                    }
                })
                //BLOCCO RIGHE COASSICURAZIONI
                if (document.getElementById(`btnAggiungiRigaCompagniaR1-D${i}`))
                    document.getElementById(`btnAggiungiRigaCompagniaR1-D${i}`).ej2_instances[0].disabled = true
                this.strutturaStatiCoassicurazioniRighe.forEach(coass => {
                    if (document.getElementById(`btnEliminaCompagniaRiparto1-D${i}-C${coass.c}`))
                        document.getElementById(`btnEliminaCompagniaRiparto1-D${i}-C${coass.c}`).ej2_instances[0].disabled = true
                    if (document.getElementById(`btnAggiungiRigaCompagniaR1-D${i}-R${coass.c}`))
                        document.getElementById(`btnAggiungiRigaCompagniaR1-D${i}-R${coass.c}`).ej2_instances[0].disabled = true
                })
                document.getElementById(`btnAggiornaDatiCoassicuraz-D${i}`).ej2_instances[0].disabled = true
            }
        }
        document.getElementById(`aggiornaRiepilogo`).ej2_instances[0].disabled = true;
    }

    cambiaPaginaFooter(pagina, fromSalva, args) {
        if (fromSalva !== 'fromSalva')
            this.toggleBlocking();
        var componente = <></>;
        if (pagina === 'ElaboratoP1') {
            document.getElementById('elabPeritalePag2').ej2_instances[0].disabled = true;
            componente = <PaginaPeriziaOnline restituito={this.props.restituito} key={this.childKey} IdIncarico={this.props.IdIncarico} IdIncaricoUnipol={this.props.IdIncaricoUnipol} IdSinistro={this.props.IdSinistro} IdCompagnia={this.props.IdCompagnia} numeroPolizza={this.props.numeroPolizza} tipoSinistro={this.props.tipoSinistro}></PaginaPeriziaOnline>
        }
        else if (pagina === 'Dettaglio') {
            document.getElementById('dettSinPag2').ej2_instances[0].disabled = true;
            componente = <PaginaDettaglioSinistro key={this.childKey} IdSinistro={this.props.IdSinistro} IdCompagnia={this.props.IdCompagnia}></PaginaDettaglioSinistro>
        }

        setTimeout(() => {
            ++this.childKey;
            const container = document.getElementById('contenutoDinamico2');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                this.distruggiDialog();
                this.toggleBlocking();
            }}>{componente}</CallbackWrapper>);
        }, 300);
    }
    //--------------------FUNZIONI DI UTILITY FINE----------------


    //--------------------------CREAZIONE TABELLE INIZIO---------------------------
    //---------------------------PREESISTENZA TABELLE INIZIO-----------------
    tabellaPreesistenzaBase(idPartita, descrizione, formaGaranzia, guidPartita, keyPartita, sommaAssicurata) {
        return new Promise((resolve, reject) => {
            var num = this.numPreesistenze;
            this.numPreesistenze++;

            const DivContainer = document.getElementById('contentTutteTabellePreesistenze');
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `tabellaCompletaPreesistenza-T${num}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`tabellaCompletaPreesistenza-T${num}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                //La tabella Fabbricato è l' unica tabella preesistenza a contenere le costruzioni
                if (idPartita === 'TP016' || idPartita === '1') { }
                else
                    document.getElementById(`costruzioniPreesistenza-T${num}`).style.display = 'none';

                document.getElementById(`nomePartita${num}`).ej2_instances[0].value = idPartita;
                setTimeout(() => {
                    var titolo = ''
                    var part = document.getElementById(`nomePartita${num}`).ej2_instances[0].text;
                    var descr = document.getElementById(`descrizionePartita${num}`).ej2_instances[0].value;
                    titolo = CustomJS.isNullEmpty(descr) ? part : part + ' : ' + descr;
                    document.getElementById(`nomePreesistenza-T${num}`).innerText = titolo;
                }, 125)

                document.getElementById(`descrizionePartita${num}`).ej2_instances[0].value = descrizione;
                document.getElementById(`formaGaranzia${num}`).ej2_instances[0].value = formaGaranzia;
                document.getElementById(`idPartitaPO-T${num}`).ej2_instances[0].dataSource = [{ TEXT: keyPartita, VALUE: keyPartita, GUID_PARTITA_GEN: '' }]
                document.getElementById(`sommaAssicurataInput_T${num}`).ej2_instances[0].value = sommaAssicurata;
                document.getElementById(`valoreDegradoInput_T${num}`).ej2_instances[0].value = 25;

                setTimeout(() => {
                    document.getElementById(`idPartitaPO-T${num}`).ej2_instances[0].value = keyPartita;
                    setTimeout(() => {
                        document.getElementById(`idPartitaPO-T${num}`).ej2_instances[0].itemData.GUID_PARTITA_GEN = guidPartita;
                        var valoreDerogato = sommaAssicurata * document.getElementById(`derogaInput_T${num}`).ej2_instances[0].value;
                        document.getElementById(`valoreDerogatoResult_T${num}`).ej2_instances[0].value = valoreDerogato
                        resolve();
                    }, 150)
                }, 150);
                //Per la struttura delle preesistenze base non c'è la key generali in quanto gestita nella struttura this.strutturaStatiPreesistenzeCostruzioni
                var objStrutturaStatiPreesistenza = {
                    id_incarico: this.props.IdIncarico,
                    stato: 'n',
                    stato_iniziale: 'n',
                    keyXpert: null,
                    keyGen: null,
                    id: `tabellaCompletaPreesistenza-T${num}`,
                    t: num,
                    keyPartita: keyPartita
                };
                this.strutturaStatiPreesistenzeBase.push(objStrutturaStatiPreesistenza)
            }}>{<>
                <table className='perizia-table preesistenza-table' style={{ marginTop: '2%' }} >
                    <tr style={{ display: 'none' }}>
                        <th><div style={{ width: '5%' }}>
                            <DropDownListComponent readonly={true} id={`idPartitaPO-T${num}`} fields={this.ddlFields}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Partita Perizia Online" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div></th>
                    </tr>
                    <tr>
                        <th><h4 id={`nomePreesistenza-T${num}`}></h4></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table' >
                    <tr>
                        <th style={{ display: 'none' }}><DropDownListComponent readonly={true} id={`nomePartita${num}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ display: 'none' }}><TextBoxComponent readonly={true} id={`descrizionePartita${num}`} floatLabelType='Auto' placeholder='Descrizione' cssClass='e-outline'></TextBoxComponent></th>
                        <th><div style={{ width: '16.65%' }}><DropDownListComponent readonly={true} id={`formaGaranzia${num}`} fields={this.ddlFields} dataSource={this.dsFormaGaranzia}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Forma Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></div></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table' >
                    <tr>
                        <th style={{ textAlign: 'right' }}>Somma Assicurata :</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} id={`sommaAssicurataInput_T${num}`} onBlur={this.calcolaPreesistenza.bind(this, num, 'noRiga')} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ textAlign: 'right' }}>Deroga :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} id={`derogaInput_T${num}`} onBlur={this.calcolaPreesistenza.bind(this, num, 'noRiga')} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ textAlign: 'right' }}>Valore Derogato</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} value={0} readonly={true} id={`valoreDerogatoResult_T${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                    <tr id={`costruzioniPreesistenza-T${num}`}>
                        <th>Valore Unitario (/m³)</th>
                        <th>Superficie Equivalente (m²)
                        </th>
                        <th >Altezza (m)</th>
                        <th >Volume (m³)</th>
                        <th>Valore di ricostruzione a nuovo</th>
                        <th style={{ textAlign: 'left' }}><ButtonComponent style={{ marginBottom: '12px' }} id={`btnAggiungiRigaPartFabb-T${num}-R-0`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaCostruzionePreesistenza.bind(this, num, '', true)}><i class="fas fa-plus"></i></ButtonComponent></th>
                    </tr>
                </table>
                <table id={`contentRighePreesCostruzioni-T${num}`} className='perizia-table preesistenza-table contentRighePreesistenza costruzioni' >
                    {/* TUTTE LE RIGHE DELLA PREESISTENZA COSTRUZIONI */}
                </table>
                <table className='perizia-table preesistenza-table'>
                    <tr>
                        <th style={{ width: '1000px' }}>Descrizione</th>
                        <th style={{ width: '250px' }}>Valore</th>
                        <th style={{ textAlign: 'left' }}><ButtonComponent id={`btnAggiungiRigaPreesDescr-T${num}-R-0`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaDescrizionePreesistenza.bind(this, num, '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                    </tr>
                </table>
                <table id={`contentRighePreesDescrizioni-T${num}`} className='perizia-table preesistenza-table contentRighePreesistenza descrizioni'>
                    {/* TUTTE LE RIGHE DELLA PREESISTENZA DESCRIZIONI */}
                </table>
                <table className='perizia-table prees-aggiustamento preesistenza-table'>
                    <tr>
                        <th></th>
                        <th></th>
                        <th id={`scoperturaTipo-T${num}`}>Scopertura</th>
                    </tr>
                    <tr>
                        <th style={{ width: '1000px', textAlign: 'right' }}>Totale esistenza a nuovo :</th>
                        <th style={{ width: '250px' }}> <NumericTextBoxComponent value={0} showSpinButton={false} style={{ fontWeight: '500' }} readonly={true} id={`valoreTotEsistNuovoResult_T${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th><NumericTextBoxComponent showSpinButton={false} readonly={true} value={0} id={`valoreScoperturaResult_T${num}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                    <tr id={`rigaDegrado-T${num}`}>
                        <th style={{ width: '1000px', textAlign: 'right' }}>Degrado per stato d'uso :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} onBlur={this.calcolaPreesistenza.bind(this, num, 'noRiga')} id={`valoreDegradoInput_T${num}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                    <tr id={`rigaTotEsStatUso-T${num}`}>
                        <th style={{ width: '1000px', textAlign: 'right' }}>Totale esistenza allo stato d'uso :</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} value={0} readonly={true} id={`valoreTotEsistStatoUsoInput_T${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th><NumericTextBoxComponent showSpinButton={false} value={0} readonly={true} id={`valoreScoperturaStatoUso_T${num}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table prees-aggiustamento preesistenza-table'>
                    <tr>
                        <th style={{ width: '250px' }}> <div style={{ margin: '0.4%' }}>Insuff. Assicurativa</div><NumericTextBoxComponent showSpinButton={false} readonly={true} id={`insuffAssicurativaPerc_T${num}`} value={0} placeholder='Insufficienza Assicurativa' floatLabelType='Auto' format="#.## '%'" cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ width: '1250px' }}><div style={{ marginTop: '1.4%' }}></div><TextBoxComponent readonly={true} id={`messaggioNote-T${num}`} floatLabelType='Auto' placeholder='Note Suff/Insuff Assicurativa' cssClass='e-outline'></TextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table prees-aggiustamento preesistenza-table'>
                    <tr>
                        <th><div style={{ margin: '0.4%' }}>Note Preesistenza</div><TextBoxComponent cssClass='notePreesistenza' htmlAttributes={{ rows: 1 }} multiline={true} id={`notePreesistenza-T${num}`} placeholder='Note Preesistenza' /></th>
                    </tr>
                </table>
                <div style={{ textAlign: 'right', padding: '1%' }}>
                    <ButtonComponent style={{ marginLeft: '2%' }} id={`aggiornaPreesistenza-T${num}`} alt='Aggiorna Preesistenza' cssClass='e-fill e-info' type='button' onClick={this.calcolaPreesistenza.bind(this, num, 'noRiga')}>AGGIORNA PREESISTENZA</ButtonComponent>
                </div>
            </>}</CallbackWrapper>);
        });
    }

    cambiaMetroCuboOQuadro(numPart, num, args) {
        //se è checkkato allora uso i metri quadri
        if (args.checked) {
            document.getElementById(`cubiOQuadri_T${numPart}_R_${num}`).innerText = 'm³'
            //sono in metri quadri
            document.getElementById(`altezzaInput_T${numPart}_R_${num}`).ej2_instances[0].value = 1;
            document.getElementById(`altezzaInput_T${numPart}_R_${num}`).ej2_instances[0].readonly = true;
            document.getElementById(`volumeResult_T${numPart}_R_${num}`).ej2_instances[0].value = 1;
            document.getElementById(`volumeResult_T${numPart}_R_${num}`).ej2_instances[0].readonly = true;
            document.getElementById(`boxAltezza_${numPart}_R_${num}`).style.visibility = 'hidden';
            document.getElementById(`boxVolume_${numPart}_R_${num}`).style.visibility = 'hidden';
        }
        else {
            //uso i metri cubi (default)
            document.getElementById(`cubiOQuadri_T${numPart}_R_${num}`).innerText = 'm²'
            document.getElementById(`altezzaInput_T${numPart}_R_${num}`).ej2_instances[0].readonly = false;
            document.getElementById(`volumeResult_T${numPart}_R_${num}`).ej2_instances[0].readonly = false;
            document.getElementById(`boxAltezza_${numPart}_R_${num}`).style.removeProperty('visibility')
            document.getElementById(`boxVolume_${numPart}_R_${num}`).style.removeProperty('visibility')
        }
        this.calcolaPreesistenza(numPart, num)
    }

    tabellaPreesistenzaPRA(idPartita, descrizione, formaGaranzia, guidPartita, keyPartita) {
        return new Promise((resolve, reject) => {
            var num = this.numPreesistenze;
            this.numPreesistenze++;
            const DivContainer = document.getElementById('contentTutteTabellePreesistenze');
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `tabellaCompletaPreesistenza-T${num}`);
            newDiv.setAttribute("class", `marginSoprsTabPartfabb`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`tabellaCompletaPreesistenza-T${num}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {

                document.getElementById(`nomePartita${num}`).ej2_instances[0].value = idPartita;

                document.getElementById(`descrizionePartita${num}`).ej2_instances[0].value = descrizione;
                document.getElementById(`formaGaranzia${num}`).ej2_instances[0].value = formaGaranzia;
                document.getElementById(`idPartitaPO-T${num}`).ej2_instances[0].dataSource = [{ TEXT: keyPartita, VALUE: keyPartita, GUID_PARTITA_GEN: '' }]

                setTimeout(() => {
                    document.getElementById(`idPartitaPO-T${num}`).ej2_instances[0].value = keyPartita;
                    var titolo = ''
                    var part = document.getElementById(`nomePartita${num}`).ej2_instances[0].text;
                    var descr = document.getElementById(`descrizionePartita${num}`).ej2_instances[0].value;
                    titolo = CustomJS.isNullEmpty(descr) ? part : part + ' : ' + descr;
                    document.getElementById(`nomePreesistenza-T${num}`).innerText = titolo;
                    setTimeout(() => {
                        document.getElementById(`idPartitaPO-T${num}`).ej2_instances[0].itemData.GUID_PARTITA_GEN = guidPartita;
                        resolve()
                    }, 150)
                }, 150)
            }}>{<>
                <table className='perizia-table preesistenza-table partita-pra' style={{ marginTop: '2%' }} >
                    <tr style={{ display: 'none' }}>
                        <th><div style={{ width: '5%' }}>
                            <DropDownListComponent readonly={true} id={`idPartitaPO-T${num}`} fields={this.ddlFields}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Partita Perizia Online" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div></th>
                    </tr>
                    <tr>
                        <th><h4 id={`nomePreesistenza-T${num}`}></h4></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table partita-pra' >
                    <tr>
                        <th style={{ display: 'none' }}><DropDownListComponent readonly={true} id={`nomePartita${num}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ display: 'none' }}><TextBoxComponent readonly={true} id={`descrizionePartita${num}`} floatLabelType='Auto' placeholder='Descrizione' cssClass='e-outline'></TextBoxComponent></th>
                        <th style={{ display: 'none' }}><DropDownListComponent readonly={true} id={`formaGaranzia${num}`} fields={this.ddlFields} dataSource={this.dsFormaGaranzia}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Forma Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table partita-pra' >
                    <tr >
                        <th ><h5 style={{ color: '#e53e14' }}>La partita è prestata nella forma PRIMO RISCHIO ASSOLUTO</h5></th>
                    </tr>
                </table>
            </>}</CallbackWrapper>);
        });
    }

    aggiungiRigaCostruzionePreesistenza(numPart, retDataC, tabFocus) {
        return new Promise((resolve, reject) => {
            var num = this.numRighePreesCostruzioni;
            this.numRighePreesCostruzioni++;
            const DivContainer = document.getElementById(`contentRighePreesCostruzioni-T${numPart}`);
            const newTr = document.createElement("tr");
            newTr.setAttribute("id", `rigaPreesistenzaCostruzione-T${numPart}-R-${num}`);
            DivContainer.append(newTr);

            const container = document.getElementById(`rigaPreesistenzaCostruzione-T${numPart}-R-${num}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {

                if (!CustomJS.isNullEmpty(retDataC)) {
                    document.getElementById(`valoreUnitarioInput_T${numPart}_R_${num}`).ej2_instances[0].value = retDataC.valore_unitario
                    document.getElementById(`superficieEquivalenteInput_T${numPart}_R_${num}`).ej2_instances[0].value = retDataC.superficie_equivalente
                    document.getElementById(`altezzaInput_T${numPart}_R_${num}`).ej2_instances[0].value = retDataC.altezza
                    document.getElementById(`volumeResult_T${numPart}_R_${num}`).ej2_instances[0].value = retDataC.volume
                    document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${num}`).ej2_instances[0].value = retDataC.valore_ricostruz_nuovo

                    var objStrutturaStatiCostruzioni = {
                        stato: 'o',
                        stato_iniziale: 'o',
                        keyXpert: retDataC.id_preesistenza_costruzione,
                        keyGen: retDataC.id_preesistenza_gen,
                        id: `rigaPreesistenzaCostruzione-T${numPart}-R-${num}`,
                        r: num
                    };
                    this.strutturaStatiPreesistenzeCostruzioni.push(objStrutturaStatiCostruzioni)

                    resolve()
                }
                else {
                    //Gestione struttura stati costruzioni tabella preesistenza fabbricato
                    //La struttura delle costruzioni è la struttura PRINCIPALE per l' inserimento dei record nella GENERALI_partita_preesistenze
                    var objStrutturaStatiCostruzioni = {
                        stato: 'n',
                        stato_iniziale: 'n',
                        keyXpert: null,
                        keyGen: null,
                        id: `rigaPreesistenzaCostruzione-T${numPart}-R-${num}`,
                        r: num
                    };
                    this.strutturaStatiPreesistenzeCostruzioni.push(objStrutturaStatiCostruzioni)

                    if (tabFocus === true) {
                        setTimeout(() => {
                            document.getElementById(`superficieEquivalenteInput_T${numPart}_R_${num}`).focus()
                            resolve();
                        }, 100)
                    } else {
                        resolve();
                    }
                }

            }}>{<>
                <th> <NumericTextBoxComponent showSpinButton={false} id={`valoreUnitarioInput_T${numPart}_R_${num}`} onBlur={this.calcolaPreesistenza.bind(this, numPart, num)} value={380} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th>
                    <th className='rimuovi-bordo' style={{ width: '60%' }}>
                        <NumericTextBoxComponent showSpinButton={false} id={`superficieEquivalenteInput_T${numPart}_R_${num}`} placeholder='m²' onBlur={this.calcolaPreesistenza.bind(this, numPart, num)} floatLabelType='Auto' format="#.##" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                    </th>
                    <th className='rimuovi-bordo'>
                        <div className='scegliUmBox'>
                            <SwitchComponent change={this.cambiaMetroCuboOQuadro.bind(this, numPart, num)} />
                            <p className='scegliUmP' id={`cubiOQuadri_T${numPart}_R_${num}`}> m²</p>
                        </div>
                    </th>
                </th>
                <th id={`boxAltezza_${numPart}_R_${num}`}><NumericTextBoxComponent showSpinButton={false} id={`altezzaInput_T${numPart}_R_${num}`} placeholder='m' onBlur={this.calcolaPreesistenza.bind(this, numPart, num)} floatLabelType='Auto' format="#.##" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th id={`boxVolume_${numPart}_R_${num}`}><NumericTextBoxComponent showSpinButton={false} readonly={true} id={`volumeResult_T${numPart}_R_${num}`} placeholder='m³' floatLabelType='Auto' decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th> <NumericTextBoxComponent showSpinButton={false} style={{ width: '190px' }} readonly={true} id={`valoreRicostruzioneResult_T${numPart}_R_${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th>
                    <ButtonComponent style={{ marginRight: '15px' }} id={`btnEliminaRigaPreesCostruzione-T-${numPart}-R${num}`} onClick={this.eliminaRigaCostruzionePreesistenza.bind(this, `rigaPreesistenzaCostruzione-T${numPart}-R-${num}`, `contentRighePreesCostruzioni-T${numPart}`, numPart, num)} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaPrees' type='button' ><i class="fas fa-trash"></i></ButtonComponent>
                    <ButtonComponent id={`btnAggiungiRigaPartFabb-T${numPart}-R-${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaCostruzionePreesistenza.bind(this, numPart, '', true)}><i class="fas fa-plus"></i></ButtonComponent>
                </th>
            </>}</CallbackWrapper>);
        })
    }

    aggiungiRigaDescrizionePreesistenza(numPart, retDataD, elmEffettiDomestici) {
        return new Promise((resolve, reject) => {

            var num = this.numRighePreesDescrizioni;
            this.numRighePreesDescrizioni++;
            const DivContainer2 = document.getElementById(`contentRighePreesDescrizioni-T${numPart}`);
            const newTr2 = document.createElement("tr");
            newTr2.setAttribute("id", `rigaPreesDescrizione-T${numPart}-R-${num}`);
            DivContainer2.append(newTr2);

            const container = document.getElementById(`rigaPreesDescrizione-T${numPart}-R-${num}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                if (!CustomJS.isNullEmpty(retDataD)) {
                    document.getElementById(`descrizioneCorpo_T${numPart}_R_${num}`).ej2_instances[0].value = retDataD.descrizione;
                    document.getElementById(`valoreAltroACorpoResult_T${numPart}_R_${num}`).ej2_instances[0].value = retDataD.prezzo;
                    var objStrutturaStatiDescrizioni = {
                        stato: 'o',
                        stato_iniziale: 'o',
                        key: retDataD.id_preesistenza_descrizione,
                        id: `rigaPreesDescrizione-T${numPart}-R-${num}`,
                        r: num

                    };
                    this.strutturaStatiPreesistenzeDescrizioni.push(objStrutturaStatiDescrizioni)
                    resolve();
                }
                else {
                    if (!CustomJS.isNullEmpty(elmEffettiDomestici)) {
                        document.getElementById(`descrizioneCorpo_T${numPart}_R_${num}`).ej2_instances[0].value = elmEffettiDomestici;
                    }
                    else
                        document.getElementById(`descrizioneCorpo_T${numPart}_R_${num}`).ej2_instances[0].value = "Altro a corpo (eventuali recinzioni, autorimesse, box, cancelli, ecc…) ";
                    //Gestione struttura stati descrizioni tabella preesistenza fabbricato/effetti domestici/restanti
                    //La struttura delle descrizioni NON contiene dati per la GENERALI_partita_preesistenze
                    var objStrutturaStatiDescrizioni = {
                        stato: 'n',
                        stato_iniziale: 'n',
                        key: null,
                        id: `rigaPreesDescrizione-T${numPart}-R-${num}`,
                        r: num
                    };
                    this.strutturaStatiPreesistenzeDescrizioni.push(objStrutturaStatiDescrizioni)
                    resolve();
                }
            }}>{<>
                <td style={{ width: '1000px' }}><TextBoxComponent id={`descrizioneCorpo_T${numPart}_R_${num}`} floatLabelType='Auto' placeholder='Descrizione' cssClass='e-outline'></TextBoxComponent></td>
                <th style={{ width: '250px' }}> <NumericTextBoxComponent value={0} showSpinButton={false} id={`valoreAltroACorpoResult_T${numPart}_R_${num}`} onBlur={this.calcolaPreesistenza.bind(this, numPart, 'noRiga')} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th >
                    <ButtonComponent style={{ marginRight: '15px' }} id={`btnEiminaRigaPreesDescr-T${numPart}-R-${num}`} onClick={this.eliminaRigaDescrizionePreesistenza.bind(this, `rigaPreesDescrizione-T${numPart}-R-${num}`, `contentRighePreesDescrizioni-T${numPart}`, numPart, num)} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaPrees' type='button' ><i class="fa fa-trash"></i></ButtonComponent>
                    <ButtonComponent id={`btnAggiungiRigaPreesDescr-T${numPart}-R-${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaDescrizionePreesistenza.bind(this, numPart, '', '')}><i class="fa fa-plus"></i></ButtonComponent>
                </th>
            </>}</CallbackWrapper>);
        });
    }

    eliminaRigaCostruzionePreesistenza(idContainerRiga, idContainerGenitore, numTab, numRiga, args) {
        delete this.formOptions.rules[`valoreUnitarioInput_T${numTab}_R_${numRiga}`];
        delete this.formOptions.rules[`superficieEquivalenteInput_T${numTab}_R_${numRiga}`];
        delete this.formOptions.rules[`altezzaInput_T${numTab}_R_${numRiga}`];

        let containerDaElimin = document.getElementById(idContainerRiga);
        let containerGenitore = document.getElementById(idContainerGenitore);
        containerGenitore.removeChild(containerDaElimin);
        this.calcolaPreesistenza(numTab, 'noRiga');

        this.strutturaStatiPreesistenzeCostruzioni.forEach(elm => {
            if (elm.id === idContainerRiga)
                elm.stato = 'd';
        });
    }

    eliminaRigaDescrizionePreesistenza(idContainerRiga, idContainerGenitore, numTab, numRiga, args) {
        delete this.formOptions.rules[`descrizioneCorpo_T${numTab}_R_${numRiga}`];
        delete this.formOptions.rules[`valoreAltroACorpoResult_T${numTab}_R_${numRiga}`];

        let containerDaElimin = document.getElementById(idContainerRiga);
        let containerGenitore = document.getElementById(idContainerGenitore);
        containerGenitore.removeChild(containerDaElimin);
        this.calcolaPreesistenza(numTab, 'noRiga');
        this.strutturaStatiPreesistenzeDescrizioni.forEach(elm => {
            if (elm.id === idContainerRiga)
                elm.stato = 'd';
        });
    }


    //---------------------------PREESISTENZA TABELLE FINE-----------------


    //---------------------------STIMA TABELLE INIZIO-----------------
    tabellaStimaValoreANuovo(idStima, idDanno, idDanneggiato, idPartita, descrPartita, idGaranzia, idDeterminazioneDanno, limitePer, franchigiaOScoperto, retDataStimeS, numS) {
        return new Promise((resolve, reject) => {
            var dataSourceStima = [{ TEXT: idStima, VALUE: idStima }];
            var num = this.numStime;
            this.numStime++;

            const DivContainer = document.getElementById('tabellaCompletaTutteLeStime');
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `tabellaStima-D${num}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`tabellaStima-D${num}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                document.getElementById(`danneggiatoFromSin-D${num}`).ej2_instances[0].value = idDanneggiato;
                document.getElementById(`partitaFromSin-D${num}`).ej2_instances[0].value = idPartita;
                document.getElementById(`garanziaFromSin-D${num}`).ej2_instances[0].value = idGaranzia;
                document.getElementById(`determinazioneDanno_D${num}`).ej2_instances[0].value = idDeterminazioneDanno;
                document.getElementById(`limitePer_D${num}`).ej2_instances[0].value = limitePer;
                document.getElementById(`isFranchigiaOrScoperto-D${num}`).ej2_instances[0].value = franchigiaOScoperto;


                var objStrutturaStatiStima = {
                    id_incarico: this.props.IdIncarico,
                    keyXpert: idStima,
                    keyGen: idDanno,
                    id: `tabellaStima-D${num}`,
                    d: num
                };
                this.strutturaStatiStimeBase.push(objStrutturaStatiStima)


                if (retDataStimeS.id_determinazioneDanno === '003') {
                    var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${num}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${num}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${num}`).ej2_instances[0].value;
                    franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                    document.getElementById(`franchigiaValoreANuovo_D${num}`).ej2_instances[0].value = franchigia;
                }


                document.getElementById(`idStima-D${num}`).ej2_instances[0].value = idStima;
                setTimeout(() => {
                    document.getElementById(`idStima-D${num}`).ej2_instances[0].itemData.KEYGEN = idDanno;
                    document.getElementById(`danneggiatoStimaNome-D${num}`).innerHTML = document.getElementById(`danneggiatoFromSin-D${num}`).ej2_instances[0].text;
                    document.getElementById(`partitaStimaNome-D${num}`).innerHTML = document.getElementById(`partitaFromSin-D${num}`).ej2_instances[0].text + (CustomJS.isNullEmpty(descrPartita) ? '' : ` : ${descrPartita}`);
                    document.getElementById(`garanziaStimaNome-D${num}`).innerHTML = document.getElementById(`garanziaFromSin-D${num}`).ej2_instances[0].text;

                    document.getElementById(`accordionCoassicurazione-D${num}`).style.display = 'none'
                    document.getElementById(`accordionCoassicurazione-D${num}`).ej2_instances[0].expandItem(false, 0);
                    resolve();
                }, 150)
            }}>{<>
                <table className='perizia-table' style={{ marginTop: '2%' }} >
                    <tr style={{ display: 'none' }}>
                        <th><div style={{ width: '5%' }}>
                            <DropDownListComponent readonly={true} id={`idStima-D${num}`} fields={this.ddlFields} dataSource={dataSourceStima}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Stima" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div></th>
                    </tr>
                </table>
                <table className='perizia-table titoli-stima' >
                    <tr >
                        <th><h4 id={`danneggiatoStimaNome-D${num}`}></h4></th>
                        <th><h4 id={`partitaStimaNome-D${num}`}></h4></th>
                        <th><h4 id={`garanziaStimaNome-D${num}`}></h4></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`danneggiatoFromSin-D${num}`} fields={this.ddlFields} dataSource={this.dsDanneggiati}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Danneggiato" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`partitaFromSin-D${num}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`garanziaFromSin-D${num}`} fields={this.ddlFields} dataSource={this.dsGaranzie}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>

                        <th style={{ width: '33.3%' }}><DropDownListComponent readonly={true} id={`determinazioneDanno_D${num}`} fields={this.ddlFields} dataSource={this.dsDeterminazioneDanno}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Determinazione del Danno" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th ><DropDownListComponent readonly={true} id={`limitePer_D${num}`} fields={this.ddlFields} dataSource={this.dsLimitePer}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Limite per" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>

                        <th ><NumericTextBoxComponent showSpinButton={false} placeholder='Limite Sinistro' onBlur={this.calcolaStimaValoreANuovo.bind(this, num, true)} id={`limiteSinistro_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ width: '33.3%' }}><div style={{ width: '33.3%' }}><NumericTextBoxComponent showSpinButton={false} placeholder='N° Piano' id={`piano-D${num}`} format='#' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></div></th>
                    </tr>
                </table>
                <table className='perizia-table'>
                    <tr>
                        {this.isCompagniaGenerali() ?
                            //GENERALI
                            <>
                                <th style={{ width: '66.6%' }}>Descrizione Prezziario</th>
                                <th style={{ width: '22.35%' }}>Categoria Prezziario</th>
                            </> :
                            //XPERT
                            <>
                                <th style={{ width: '89%' }}>Descrizione Prezziario</th>
                            </>}
                        <th><ButtonComponent style={{ marginTop: '-3px', marginLeft: '5px' }} id={`btnAggiungiRigaStimaPrezziario-D${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, num, 'tabellaValoreANuovo', '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                    </tr>
                </table>
                <div className='tabPrezziarioBoxPerOgniStima' id={`tabellePrezziarioContent-D${num}`}>
                </div>
                <table className='perizia-table'>
                    <tr >
                        <th style={{ width: '66.6%', textAlign: 'right' }}>Danno Complessivo Accertato :</th>
                        <th style={{ width: '22.35%' }}> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`dannoComplessAccert_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right' }}>Dopo regola proporzionale :</th>
                        <th style={{ width: '22.35%' }}>
                            <div className='row'>
                                <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`dopoRegolaProporzionale_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnDopoRegolaMAN_D${num}`} title='Inserisce Valore Manuale' cssClass='e-fill' type='button' onClick={this.dopoRegolaManuale.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxDopoRegolaManuale_D${num}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaValoreANuovo.bind(this, num, false)} showSpinButton={false} id={`dopoRegolaProporzionaleMAN_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                    <tr style={{ display: 'none' }} id={`ceLimiteIndennizzoONo-D${num}`}>
                        <th style={{ textAlign: 'right' }}>Limite Indennizzo :</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`limiteIndennizzo-D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '66.6%', textAlign: 'right' }}>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%', display: this.isCompagniaGenerali() ? '' : 'none' }}>Accordo Ania :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`accordoAniaSi-D${num}`} label="Si" name={`accordoAnia-D${num}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`accordoAniaNo-D${num}`} label="No" name={`accordoAnia-D${num}`} /></th>
                            <th className='rimuovi-bordo'></th>
                            <div style={{ marginTop: '-45px' }} id={`franchigiaOscopertura-D${num}`}></div>
                        </th>
                        <th style={{ width: '22.35%' }}>
                            <div className='row'>
                                <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigia_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnFranchigiaManuale_D${num}`} title='Cambia Valore Franchigia' cssClass='e-fill' type='button' onClick={this.franchigiaManuale.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxFranchigiaManuale_D${num}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaValoreANuovo.bind(this, num, false)} showSpinButton={false} id={`franchigiaMAN_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                        <th style={{ visibility: 'hidden' }}><DropDownListComponent readonly={true} id={`isFranchigiaOrScoperto-D${num}`} fields={this.ddlFields} dataSource={this.dsFranchigiaOrScoperto}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Franchigia/Scoperto" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                    </tr>
                    <tr>
                        <th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}>Ripristini Ultimati : </th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent onClick={this.ripristiniSuInfoRich.bind(this, num, 'si')} id={`ripristiniUltimSi-D${num}`} label="Si" name={`ripristini-D${num}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent onClick={this.ripristiniSuInfoRich.bind(this, num, 'no')} id={`ripristiniUltimNo-D${num}`} label="No" name={`ripristini-D${num}`} /></th>
                            <th className='rimuovi-bordo'></th>
                            <div id={`coinvoltoLimIndannoNetto_D${num}`} style={{ textAlign: 'right', marginTop: '-50px' }}>Danno netto :</div>
                        </th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`dannoNetto_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>
                            <th className='rimuovi-bordo' style={{ ttextAlign: 'left', width: '23%' }}>Coassicurazione :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%' }}><RadioButtonComponent onChange={this.coassicurazionePresenteBool.bind(this, num)} id={`coassicurazioneSi-D${num}`} label="Si" name={`coassicurazioneBool-D${num}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%' }}><RadioButtonComponent onChange={this.coassicurazionePresenteBool.bind(this, num)} id={`coassicurazioneNo-D${num}`} label="No" name={`coassicurazioneBool-D${num}`} /></th>
                            <th className='rimuovi-bordo'></th>
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </table>
                <table className='perizia-table' style={{ display: this.isCompagniaGenerali() ? '' : 'none' }} >
                    <tr>
                        <th style={{ width: '33.3%' }} className='rimuovi-bordo'>
                            <th className='rimuovi-bordo' style={{ textAlign: 'right', width: '23%' }}>Esito Danno :</th>
                            <th className='rimuovi-bordo' ><DropDownListComponent id={`esitoDanno_D${num}`} onChange={this.mostraCausali.bind(this, num)} fields={this.ddlFields} dataSource={this.dsEsitoDanno}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="Esito Danno" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent></th>
                        </th>
                        <th style={{ visibility: 'hidden' }} id={`boxCausaliNonIndennizzo_D${num}`}>
                            <div className='row' >
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ marginTop: '12px' }}>Causali :</div>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9 text-start" >
                                    <div style={{ marginLeft: '-40px' }}>
                                        <DropDownListComponent style={{ marginLeft: '-15px' }} id={`causaliNonIndennizzo_D${num}`} fields={this.ddlFields} dataSource={this.dsCausaliNonIndennizzo}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Causali" floatLabelType='always' cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th className='rimuovi-bordo'></th>
                    </tr>
                </table>

                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '100%' }}><TextBoxComponent id={`noteRipristini-D${num}`} floatLabelType='Auto' placeholder='Note Ripristini Ultimati' cssClass='e-outline'></TextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <AccordionComponent id={`accordionCoassicurazione-D${num}`} ref={acrdn => this.acrdnCoassicurazione = acrdn}>
                        <AccordionItemsDirective >
                            <AccordionItemDirective expanded={true} content={this.contentCoassicurazione.bind(this, num, 'valoreANuovo')} cssClass='e-outline' header='<h5>Coassicurazione Indiretta</h5>' />
                        </AccordionItemsDirective>
                    </AccordionComponent>
                </table>
                <div style={{ textAlign: 'right', padding: '1%' }}>
                    <ButtonComponent style={{ marginLeft: '2%' }} id={`aggiornaTabellaStimaValoreANuovo-D${num}`} cssClass='e-fill e-info' type='button' onClick={this.aggiornaStimaValoreANuovo.bind(this, num)}>AGGIORNA STIMA (Valore a Nuovo)</ButtonComponent>
                </div>

            </>}</CallbackWrapper>);
        });
    }

    tabellaStimaStatoUso(idStima, idDanno, idDanneggiato, idPartita, descrPartita, idGaranzia, idDeterminazioneDanno, limitePer, franchigiaOScoperto, retDataStimeS, numS) {
        return new Promise((resolve, reject) => {
            var dataSourceStima = [{ TEXT: idStima, VALUE: idStima }];
            var num = this.numStime;
            this.numStime++;
            const DivContainer = document.getElementById('tabellaCompletaTutteLeStime');
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `tabellaStima-D${num}`);
            DivContainer.append(newDiv);
            const container = document.getElementById(`tabellaStima-D${num}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                document.getElementById(`danneggiatoFromSin-D${num}`).ej2_instances[0].value = idDanneggiato;
                document.getElementById(`partitaFromSin-D${num}`).ej2_instances[0].value = idPartita;
                document.getElementById(`garanziaFromSin-D${num}`).ej2_instances[0].value = idGaranzia;
                document.getElementById(`determinazioneDanno_D${num}`).ej2_instances[0].value = idDeterminazioneDanno;
                document.getElementById(`limitePer_D${num}`).ej2_instances[0].value = limitePer;
                document.getElementById(`isFranchigiaOrScoperto-D${num}`).ej2_instances[0].value = franchigiaOScoperto;
                var objStrutturaStatiStima = {
                    id_incarico: this.props.IdIncarico,
                    keyXpert: idStima,
                    keyGen: idDanno,
                    id: `tabellaStima-D${num}`,
                    d: num
                };
                this.strutturaStatiStimeBase.push(objStrutturaStatiStima)


                if (retDataStimeS.id_determinazioneDanno === '003') {
                    // var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${num}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${num}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${num}`).ej2_instances[0].value;
                    // franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                    document.getElementById(`franchigiaValoreANuovo_D${num}`).ej2_instances[0].value = document.getElementById(`franchigia_D${num}`).ej2_instances[0].value

                }

                document.getElementById(`idStima-D${num}`).ej2_instances[0].value = idStima;
                setTimeout(() => {
                    document.getElementById(`idStima-D${num}`).ej2_instances[0].itemData.KEYGEN = idDanno;
                    document.getElementById(`danneggiatoStimaNome-D${num}`).innerHTML = document.getElementById(`danneggiatoFromSin-D${num}`).ej2_instances[0].text;
                    document.getElementById(`partitaStimaNome-D${num}`).innerHTML = document.getElementById(`partitaFromSin-D${num}`).ej2_instances[0].text + (CustomJS.isNullEmpty(descrPartita) ? '' : ` : ${descrPartita}`);
                    document.getElementById(`garanziaStimaNome-D${num}`).innerHTML = document.getElementById(`garanziaFromSin-D${num}`).ej2_instances[0].text;

                    document.getElementById(`accordionCoassicurazione-D${num}`).style.display = 'none'
                    document.getElementById(`accordionCoassicurazione-D${num}`).ej2_instances[0].expandItem(false, 0);
                    resolve();
                }, 150)

            }}>{<>
                <table className='perizia-table' style={{ marginTop: '2%' }} >
                    <tr style={{ display: 'none' }}>
                        <th><div style={{ width: '5%' }}>
                            <DropDownListComponent readonly={true} id={`idStima-D${num}`} fields={this.ddlFields} dataSource={dataSourceStima}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Stima" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div></th>
                    </tr>
                </table>
                <table className='perizia-table titoli-stima' >
                    <tr >
                        <th><h4 id={`danneggiatoStimaNome-D${num}`}></h4></th>
                        <th><h4 id={`partitaStimaNome-D${num}`}></h4></th>
                        <th><h4 id={`garanziaStimaNome-D${num}`}></h4></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`danneggiatoFromSin-D${num}`} fields={this.ddlFields} dataSource={this.dsDanneggiati}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Danneggiato" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`partitaFromSin-D${num}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`garanziaFromSin-D${num}`} fields={this.ddlFields} dataSource={this.dsGaranzie}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '33.3%' }}><DropDownListComponent readonly={true} id={`determinazioneDanno_D${num}`} fields={this.ddlFields} dataSource={this.dsDeterminazioneDanno}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Determinazione del Danno" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th><DropDownListComponent readonly={true} id={`limitePer_D${num}`} fields={this.ddlFields} dataSource={this.dsLimitePer}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Limite per" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th><NumericTextBoxComponent placeholder='Limite Sinistro' showSpinButton={false} onBlur={this.calcolaStimaStatoUso.bind(this, num, true)} id={`limiteSinistro_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ width: '33.3%' }}><div style={{ width: '33.3%' }}><NumericTextBoxComponent showSpinButton={false} id={`piano-D${num}`} placeholder='N° Piano' floatLabelType='Auto' format='#' cssClass='e-outline'></NumericTextBoxComponent></div></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    {this.isCompagniaGenerali() ?
                        <><tr>
                            <th style={{ width: '89%' }}>Prezziario Descrizione</th>
                            <th><ButtonComponent id={`btnAggiungiRigaStimaPrezziario-D${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, num, "tabellaStatoUso", '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                        </tr></>
                        :
                        <><tr>
                            <th style={{ width: '89%' }}>Prezziario Descrizione</th>
                            <th><ButtonComponent id={`btnAggiungiRigaStimaPrezziario-D${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, num, "tabellaStatoUso", '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                        </tr></>}
                </table>
                <div className='tabPrezziarioBoxPerOgniStima' id={`tabellePrezziarioContent-D${num}`}>
                </div>
                <table className='perizia-table' >
                    <tr >
                        <th style={{ textAlign: 'right', width: '49.95%', }}></th>
                        <th >Valore a Nuovo</th>
                        <th >Degrado/Suppl.Indennità</th>
                        <th>Valore in Uso</th>
                    </tr>
                    <tr >
                        <th style={{ textAlign: 'right' }}>Danno Complessivo Accertato :</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreANuovoDannComplessAcc_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`degradoPercDannComplessAcc_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreBeneDannComplessAcc_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                    <tr >
                        <th style={{ textAlign: 'right' }}>Dopo Regola Proporzionale :</th>
                        <th >
                            <div className='row'>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreANuovoDopoRegolaProp_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnValANuovoDoporegolaMAN_D${num}`} title='Inserisce Valore Manuale' cssClass='e-fill' type='button' onClick={this.dopoRegolaManualeValANuovo.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxValANuovoDopoRegola_D${num}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaStatoUso.bind(this, num, false)} showSpinButton={false} id={`valoreANuovoDopoRegolaPropMAN_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                        <th >
                            <div className='row'>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`degrado25PercDopoRegolaProp_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnDegrado25PercDopoRegolaMAN_D${num}`} title='Inserisce Valore Manuale' cssClass='e-fill' type='button' onClick={this.dopoRegolaManualeDegrado.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxDegrado25DopoRegola_D${num}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaStatoUso.bind(this, num, false)} showSpinButton={false} id={`degrado25PercDopoRegolaPropMAN_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                        <th >
                            <div className='row'>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`dopoRegolaProporzionale_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnDopoRegolaMAN_D${num}`} title='Inserisce Valore Manuale' cssClass='e-fill' type='button' onClick={this.dopoRegolaManuale.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxDopoRegolaManuale_D${num}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaStatoUso.bind(this, num, false)} showSpinButton={false} id={`dopoRegolaProporzionaleMAN_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                    </tr>
                    <tr style={{ display: 'none' }} id={`ceLimiteIndennizzoONo-D${num}`} >
                        <th style={{ textAlign: 'right' }}>Limite Indennizzo :</th>
                        <th></th>
                        <th></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`limiteIndennizzo-D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr >
                        <th style={{ width: '49.95%', textAlign: 'right' }}>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%', display: this.isCompagniaGenerali() ? '' : 'none' }}>Accordo Ania :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`accordoAniaSi-D${num}`} label="Si" name={`accordoAnia-D${num}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`accordoAniaNo-D${num}`} label="No" name={`accordoAnia-D${num}`} /></th>
                            <th className='rimuovi-bordo'></th>
                            <div style={{ marginTop: '-45px' }} id={`franchigiaOscopertura-D${num}`} ></div>
                        </th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigiaValoreANuovo_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ visibility: 'hidden' }}><DropDownListComponent readonly={true} id={`isFranchigiaOrScoperto-D${num}`} fields={this.ddlFields} dataSource={this.dsFranchigiaOrScoperto}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Franchigia/Scoperto" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th>
                            <div className='row'>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigia_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnFranchigiaManuale_D${num}`} title='Cambia Valore Franchigia' cssClass='e-fill' type='button' onClick={this.franchigiaManuale.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxFranchigiaManuale_D${num}`} style={{ display: 'none' }}>
                                Valore Manuale (definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaStatoUso.bind(this, num, false)} showSpinButton={false} id={`franchigiaMAN_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                    </tr>
                    <tr >
                        <th style={{ width: '49.95%', textAlign: 'right' }}>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}>Ripristini Ultimati :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`ripristiniUltimSi-D${num}`} onClick={this.ripristiniSuInfoRich.bind(this, num, 'si')} label="Si" name={`ripristini-D${num}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`ripristiniUltimNo-D${num}`} onClick={this.ripristiniSuInfoRich.bind(this, num, 'no')} label="No" name={`ripristini-D${num}`} /></th>
                            <th className='rimuovi-bordo'></th>
                            <div style={{ marginTop: '-50px' }}> Danno Netto :</div>
                        </th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreANuovodannoNetto_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`degrado25PercdannoNetto_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreStatoUsodannoNetto_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                    <tr>
                        <th style={{ width: '49.95%', textAlign: 'right' }}>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%' }} >Coassicurazione :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%' }} ><RadioButtonComponent onChange={this.coassicurazionePresenteBool.bind(this, num)} id={`coassicurazioneSi-D${num}`} label="Si" name={`coassicurazioneBool-D${num}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%' }}><RadioButtonComponent onChange={this.coassicurazionePresenteBool.bind(this, num)} id={`coassicurazioneNo-D${num}`} label="No" name={`coassicurazioneBool-D${num}`} /></th>
                            <th className='rimuovi-bordo'></th>
                        </th>
                        <th ></th>
                        <th ></th>
                        <th ></th>
                    </tr>
                </table>
                <table className='perizia-table' style={{ display: this.isCompagniaGenerali() ? '' : 'none' }} >
                    <tr>
                        <th style={{ width: '33.3%' }} className='rimuovi-bordo'>
                            <th className='rimuovi-bordo' style={{ textAlign: 'right', width: '23%' }}>Esito Danno :</th>
                            <th className='rimuovi-bordo' ><DropDownListComponent id={`esitoDanno_D${num}`} onChange={this.mostraCausali.bind(this, num)} fields={this.ddlFields} dataSource={this.dsEsitoDanno}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="Esito Danno" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent></th>
                        </th>
                        <th style={{ visibility: 'hidden' }} id={`boxCausaliNonIndennizzo_D${num}`}>
                            <div className='row' >
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ marginTop: '12px' }}>Causali :</div>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9 text-start" >
                                    <div style={{ marginLeft: '-40px' }}>
                                        <DropDownListComponent id={`causaliNonIndennizzo_D${num}`} fields={this.ddlFields} dataSource={this.dsCausaliNonIndennizzo}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Causali" floatLabelType='always' cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th className='rimuovi-bordo'></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '100%' }}><TextBoxComponent id={`noteRipristini-D${num}`} floatLabelType='Auto' placeholder='Note Ripristini Ultimati' cssClass='e-outline'></TextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <AccordionComponent id={`accordionCoassicurazione-D${num}`} ref={acrdn => this.acrdnCoassicurazione = acrdn}>
                        <AccordionItemsDirective >
                            <AccordionItemDirective expanded={true} content={this.contentCoassicurazione.bind(this, num, 'statoUso')} cssClass='e-outline' header='<h5>Coassicurazione Indiretta</h5>' />
                        </AccordionItemsDirective>
                    </AccordionComponent>
                </table>
                <div style={{ textAlign: 'right', padding: '1%' }}>
                    <ButtonComponent style={{ marginLeft: '2%' }} id={`aggiornaTabellaStimaStatoUso-D${num}`} alt='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiornaStimaStatoUso.bind(this, num)}>AGGIORNA STIMA (Stato Uso)</ButtonComponent>
                </div>
            </>}</CallbackWrapper>);
        });
    }

    mostraCausali(num, args) {
        if (args.value === "4") {
            document.getElementById(`boxCausaliNonIndennizzo_D${num}`).style.visibility = 'visible'
        }
        else {
            document.getElementById(`boxCausaliNonIndennizzo_D${num}`).style.visibility = 'hidden'
            document.getElementById(`causaliNonIndennizzo_D${num}`).ej2_instances[0].value = "";
        }
    }

    //---------------------------PREZZIARIO RIGHE INIZIO-----------------

    aggiungiRigaStimaPrezziario(numeroDanneggiato, tipoTabella, prezziarioDefault, retDataStimeDescrD) {
        return new Promise((resolve, reject) => {
            var num = this.numTabPrezziari;
            this.numTabPrezziari++;
            const DivContainer = document.getElementById(`tabellePrezziarioContent-D${numeroDanneggiato}`);
            const newTable = document.createElement("table");
            newTable.setAttribute("class", `perizia-table content-table-prezziario`);
            newTable.setAttribute("id", `tabPrezziario-D${numeroDanneggiato}-R-${num}`);
            DivContainer.append(newTable);

            var garanzia = document.getElementById(`garanziaFromSin-D${numeroDanneggiato}`).ej2_instances[0].value;
            var arrayLocalizzazione = [];
            if (this.isCompagniaGenerali()) {
                this.allArrayLocalizzazioneForeachGaranzia.forEach(elm => {
                    if (elm.garanzia === garanzia)
                        arrayLocalizzazione = elm.array;
                });
            }
            if (tipoTabella === 'tabellaStatoUso') {
                const container = document.getElementById(`tabPrezziario-D${numeroDanneggiato}-R-${num}`)
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    if (!CustomJS.isNullEmpty(retDataStimeDescrD)) {
                        setTimeout(() => {
                            var presente = false;
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].dataSource.forEach(elm => {
                                if (elm.VALUE === retDataStimeDescrD.descrizione_lunga_prezziario) {
                                    presente = true;
                                    elm.TEXT = retDataStimeDescrD.descrizione_cambiata;
                                }
                            })
                            if (presente === false) {
                                document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].dataSource.push({
                                    TEXT: retDataStimeDescrD.descrizione_cambiata,
                                    VALUE: retDataStimeDescrD.descrizione_lunga_prezziario
                                })
                            }
                            setTimeout(() => {
                                document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.descrizione_lunga_prezziario;
                            }, 150)
                            if (this.isCompagniaGenerali()) {
                                document.getElementById(`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.categoria_prezziario;//
                            }
                        }, 100)

                        document.getElementById(`UMInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.unita_misura;
                        document.getElementById(`quantitaInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.quantita;
                        document.getElementById(`valUnitarioInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.valore_unitario
                        if (this.isCompagniaGenerali()) {
                            document.getElementById(`localizzazione_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.localizzazione;
                        }

                        document.getElementById(`valANuovoInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.totale
                        document.getElementById(`percDegrSupInd_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.supp_indenn_perc
                        document.getElementById(`importoDegrSupInd_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.supp_indenn_importo
                        document.getElementById(`valoreBeneResult_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.valore_del_bene

                        var objStrutturaStatiPrezziario = {
                            stato: 'o',
                            stato_iniziale: 'o',
                            keyXpert: retDataStimeDescrD.id_stima_descrizione,
                            keyGen: retDataStimeDescrD.id_dettaglio,
                            keyGenInfoDett: retDataStimeDescrD.id_info_richiesta,
                            id: `tabPrezziario-D${numeroDanneggiato}-R-${num}`,
                            r: num
                        };
                        this.strutturaStatiStimePrezziari.push(objStrutturaStatiPrezziario)

                        setTimeout(() => {
                            resolve();
                        }, 100)
                    }
                    else {
                        var objStrutturaStatiPrezziario = {
                            stato: 'n',
                            stato_iniziale: 'n',
                            keyXpert: null,
                            keyGen: null,
                            keyGenInfoDett: null,
                            id: `tabPrezziario-D${numeroDanneggiato}-R-${num}`,
                            r: num
                        };
                        this.strutturaStatiStimePrezziari.push(objStrutturaStatiPrezziario)

                        if (this.isCompagniaGenerali()) {
                            //localizzazione sulle nuove righe
                            var firstChild = document.getElementById(`tabellePrezziarioContent-D${numeroDanneggiato}`).firstChild.id;
                            var numFirstChild = firstChild.substring(firstChild.indexOf("R-") + 2);
                            document.getElementById(`localizzazione_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = document.getElementById(`localizzazione_D${numeroDanneggiato}_R${numFirstChild}`).ej2_instances[0].value;
                        }

                        if (!CustomJS.isNullEmpty(prezziarioDefault)) {
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = prezziarioDefault;
                            var codEleCategoria = '';
                            var um = '';
                            var valoreUnitario = 0;

                            let obj = _.find(this.dsPrezziarioCompleto, elm => {
                                return elm.COD_ELE === prezziarioDefault
                            })
                            if (!CustomJS.isNullEmpty(obj)) {
                                codEleCategoria = obj.COD_CATEGORIA;
                                um = obj.MISURA;
                                valoreUnitario = obj.PREZZO;
                            }

                            if (this.isCompagniaGenerali())
                                document.getElementById(`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = codEleCategoria;
                            document.getElementById(`UMInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = um;
                            document.getElementById(`valUnitarioInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = valoreUnitario;
                            setTimeout(() => {
                                resolve();
                            }, 100)
                        }
                        else {
                            resolve();
                        }
                    }
                }}>{<>
                    {/* TABELLA STATO USO */}
                    {this.isCompagniaGenerali() ?
                        //RIGA PREZZIARIO GENERALI STATO USO
                        <>
                            <tr>
                                <th style={{ width: '89%' }}>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnCambiaDescriz-D${numeroDanneggiato}-R${num}`} onClick={this.cambiaDescrizione.bind(this, numeroDanneggiato, num)} title='Aggiungi/Modifica Descrizione' cssClass='e-fill e-warning' type='button'><i class="fas fa-marker"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnApriPrezziario-D${numeroDanneggiato}-R${num}`} onClick={this.apriDialogPrezziario.bind(this, numeroDanneggiato, num)} title='Apri Prezziario' cssClass='e-fill e-warning' type='button'><i class="fas fa-clipboard"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentDescrizioneBase-D${numeroDanneggiato}-R${num}`} style={{ width: '98%' }}>
                                        Descrizione<DropDownListComponent placeholder="Descrizione Prezziario"
                                            onChange={this.trasportaDatiPrezziarioAggiuntivi.bind(this, numeroDanneggiato, num)}
                                            dataSource={this.dsPrezziarioDescrizioniLunghe} id={`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`}
                                            fields={this.ddlFields} allowFiltering={true} filterType='Contains' showClearButton={true} floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentNuovaDescrizione-D${numeroDanneggiato}-R${num}`} style={{ width: '98%', display: 'none' }}><TextBoxComponent onBlur={() => { document.getElementById(`btnCambiaDescriz-D${numeroDanneggiato}-R${num}`).click() }} placeholder="Aggiungi/Modifica Descrizione" id={`nuovaDescrizioneLibera-D${numeroDanneggiato}-R${num}`} floatLabelType='Auto' cssClass='e-outline'></TextBoxComponent></th>
                                </th>
                                <th >
                                    <ButtonComponent style={{ marginRight: '15px' }} id={`btnEliminaRigaStimaPrezziario-D${numeroDanneggiato}-R${num}`} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaStime' type='button' onClick={this.eliminaRigaStimaPrezziario.bind(this, `tabPrezziario-D${numeroDanneggiato}-R-${num}`, `tabellePrezziarioContent-D${numeroDanneggiato}`, numeroDanneggiato, num, 'tabellaStatoUso')}><i class="fas fa-trash"></i></ButtonComponent>
                                    <ButtonComponent id={`btnAggiungiRigaStimaPrezziario-D${numeroDanneggiato}-R${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, numeroDanneggiato, 'tabellaStatoUso', '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                                </th>
                            </tr>
                            <tr>
                                <th className='rimuovi-bordo'>
                                    <th style={{ width: '20%' }} className='rimuovi-bordo'>
                                        Categoria<DropDownListComponent placeholder="Categoria Prezziario" dataSource={this.dsPrezziarioCategorie} id={`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}`} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th style={{ width: '20%' }} className='rimuovi-bordo'>
                                        <div>Localizzazione<DropDownListComponent onChange={this.localizzazioneSuInfoRich.bind(this, numeroDanneggiato, num)} placeholder="Localizzazione" dataSource={arrayLocalizzazione} id={`localizzazione_D${numeroDanneggiato}_R${num}`} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent></div>
                                    </th>
                                    <th className='rimuovi-bordo'>U.M.<DropDownListComponent placeholder="U.M." id={`UMInput_D${numeroDanneggiato}_R${num}`} fields={this.ddlFields} dataSource={this.dsUM}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline unita-misura'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo'>Quantità<NumericTextBoxComponent value={1} showSpinButton={false} placeholder="N°" onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false)} id={`quantitaInput_D${numeroDanneggiato}_R${num}`} format='#.##' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo' >Valore Unitario<NumericTextBoxComponent value={0} showSpinButton={false} onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false)} id={`valUnitarioInput_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Valore a Nuovo<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`valANuovoInput_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>% Degrado S.I.<NumericTextBoxComponent value={0} showSpinButton={false} onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false)} id={`percDegrSupInd_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'><div style={{ marginTop: '6px' }}>Importo S.I<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`importoDegrSupInd_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent><div style={{ color: '#b0402a' }} id={`ripristinato-D${numeroDanneggiato}-R${num}`}></div></div></th>
                                </th>
                                <th className='rimuovi-bordo'>
                                    <th className='rimuovi-bordo'>Totale<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`valoreBeneResult_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                </th>
                            </tr>
                        </> :
                        //RIGA PREZZIARIO XPERT STATO USO
                        <>
                            <tr>
                                <th style={{ width: '89%' }}>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnCambiaDescriz-D${numeroDanneggiato}-R${num}`} onClick={this.cambiaDescrizione.bind(this, numeroDanneggiato, num)} title='Aggiungi/Modifica Descrizione' cssClass='e-fill e-warning' type='button'><i class="fas fa-marker"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnApriPrezziario-D${numeroDanneggiato}-R${num}`} onClick={this.apriDialogPrezziario.bind(this, numeroDanneggiato, num)} title='Apri Prezziario' cssClass='e-fill e-warning' type='button'><i class="fas fa-clipboard"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentDescrizioneBase-D${numeroDanneggiato}-R${num}`} style={{ width: '98%' }}>
                                        Descrizione<DropDownListComponent placeholder="Descrizione Prezziario"
                                            onChange={this.trasportaDatiPrezziarioAggiuntivi.bind(this, numeroDanneggiato, num)}
                                            dataSource={this.dsPrezziarioDescrizioniLunghe} id={`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`}
                                            fields={this.ddlFields} allowFiltering={true} filterType='Contains' showClearButton={true} floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentNuovaDescrizione-D${numeroDanneggiato}-R${num}`} style={{ width: '98%', display: 'none' }}><TextBoxComponent onBlur={() => { document.getElementById(`btnCambiaDescriz-D${numeroDanneggiato}-R${num}`).click() }} placeholder="Aggiungi/Modifica Descrizione" id={`nuovaDescrizioneLibera-D${numeroDanneggiato}-R${num}`} floatLabelType='Auto' cssClass='e-outline'></TextBoxComponent></th>
                                </th>
                                <th >
                                    <ButtonComponent style={{ marginRight: '15px' }} id={`btnEliminaRigaStimaPrezziario-D${numeroDanneggiato}-R${num}`} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaStime' type='button' onClick={this.eliminaRigaStimaPrezziario.bind(this, `tabPrezziario-D${numeroDanneggiato}-R-${num}`, `tabellePrezziarioContent-D${numeroDanneggiato}`, numeroDanneggiato, num, 'tabellaStatoUso')}><i class="fas fa-trash"></i></ButtonComponent>
                                    <ButtonComponent id={`btnAggiungiRigaStimaPrezziario-D${numeroDanneggiato}-R${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, numeroDanneggiato, 'tabellaStatoUso', '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                                </th>
                            </tr>
                            <tr>
                                <th className='rimuovi-bordo' style={{ width: '89%' }}>
                                    <th className='rimuovi-bordo' style={{ width: '7%' }}></th>
                                    <th className='rimuovi-bordo'>Unità di Misura<DropDownListComponent placeholder="U.M." id={`UMInput_D${numeroDanneggiato}_R${num}`} fields={this.ddlFields} dataSource={this.dsUM}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline unita-misura'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo'>Quantità<NumericTextBoxComponent value={1} showSpinButton={false} placeholder="N°" onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false)} id={`quantitaInput_D${numeroDanneggiato}_R${num}`} format='#.##' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo' >Valore Unitario<NumericTextBoxComponent value={0} showSpinButton={false} onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false)} id={`valUnitarioInput_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Valore a Nuovo<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`valANuovoInput_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>% Degrado Supp.Ind.<NumericTextBoxComponent value={0} showSpinButton={false} onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false)} id={`percDegrSupInd_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Importo S.I<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`importoDegrSupInd_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent><div style={{ color: '#b0402a' }} id={`ripristinato-D${numeroDanneggiato}-R${num}`}></div></th>
                                    <th className='rimuovi-bordo'>Totale<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`valoreBeneResult_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                </th>
                                <th className='rimuovi-bordo'>
                                </th>
                            </tr>
                        </>
                    }


                </>}</CallbackWrapper>);
            }
            else if (tipoTabella === 'tabellaValoreANuovo') {
                const container = document.getElementById(`tabPrezziario-D${numeroDanneggiato}-R-${num}`)
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    if (!CustomJS.isNullEmpty(retDataStimeDescrD)) {
                        var presente = false;
                        document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].dataSource.forEach(elm => {
                            if (elm.VALUE === retDataStimeDescrD.descrizione_lunga_prezziario) {
                                presente = true;
                                elm.TEXT = retDataStimeDescrD.descrizione_cambiata;
                            }
                        })
                        if (presente === false) {
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].dataSource.push({
                                TEXT: retDataStimeDescrD.descrizione_cambiata,
                                VALUE: retDataStimeDescrD.descrizione_lunga_prezziario
                            })
                        }
                        setTimeout(() => {
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.descrizione_lunga_prezziario;
                        }, 150)
                        if (this.isCompagniaGenerali()) {
                            document.getElementById(`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.categoria_prezziario;//
                        }

                        document.getElementById(`UMInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.unita_misura;
                        document.getElementById(`quantitaInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.quantita;
                        document.getElementById(`valUnitarioInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.valore_unitario
                        if (this.isCompagniaGenerali()) {
                            document.getElementById(`localizzazione_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.localizzazione;
                        }
                        document.getElementById(`totaleResult_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = retDataStimeDescrD.totale

                        var objStrutturaStatiPrezziario = {
                            stato: 'o',
                            stato_iniziale: 'o',
                            keyXpert: retDataStimeDescrD.id_stima_descrizione,
                            keyGen: retDataStimeDescrD.id_dettaglio,
                            keyGenInfoDett: retDataStimeDescrD.id_info_richiesta,
                            id: `tabPrezziario-D${numeroDanneggiato}-R-${num}`,
                            r: num
                        };
                        this.strutturaStatiStimePrezziari.push(objStrutturaStatiPrezziario)

                        setTimeout(() => {
                            resolve();
                        }, 100)
                    }
                    else {
                        var objStrutturaStatiPrezziario = {
                            stato: 'n',
                            stato_iniziale: 'n',
                            keyXpert: null,
                            keyGen: null,
                            keyGenInfoDett: null,
                            id: `tabPrezziario-D${numeroDanneggiato}-R-${num}`,
                            r: num
                        };
                        this.strutturaStatiStimePrezziari.push(objStrutturaStatiPrezziario)

                        if (this.isCompagniaGenerali()) {
                            //localizzazione sulle nuove righe
                            var firstChild = document.getElementById(`tabellePrezziarioContent-D${numeroDanneggiato}`).firstChild.id;
                            var numFirstChild = firstChild.substring(firstChild.indexOf("R-") + 2);
                            document.getElementById(`localizzazione_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = document.getElementById(`localizzazione_D${numeroDanneggiato}_R${numFirstChild}`).ej2_instances[0].value;
                        }

                        if (!CustomJS.isNullEmpty(prezziarioDefault)) {
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = prezziarioDefault;
                            var codEleCategoria = '';
                            var um = '';
                            var valoreUnitario = 0;

                            let obj = _.find(this.dsPrezziarioCompleto, elm => {
                                return elm.COD_ELE === prezziarioDefault
                            })
                            if (!CustomJS.isNullEmpty(obj)) {
                                codEleCategoria = obj.COD_CATEGORIA;
                                um = obj.MISURA;
                                valoreUnitario = obj.PREZZO;
                            }

                            if (this.isCompagniaGenerali())
                                document.getElementById(`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = codEleCategoria;
                            document.getElementById(`UMInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = um;
                            document.getElementById(`valUnitarioInput_D${numeroDanneggiato}_R${num}`).ej2_instances[0].value = valoreUnitario;
                            setTimeout(() => {
                                resolve();
                            }, 100)
                        }
                        else {
                            resolve();
                        }
                    }
                }}>{<>
                    {/* TABELLA VALORE A NUOVO */}
                    {this.isCompagniaGenerali() ?
                        //RIGA PREZZIARIO GENERALI A NUOVO
                        <>
                            <tr id={`rigaPrezziarioScelta-D${numeroDanneggiato}-R${num}`}>
                                <th style={{ width: '89%' }}>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent id={`btnCambiaDescriz-D${numeroDanneggiato}-R${num}`} onClick={this.cambiaDescrizione.bind(this, numeroDanneggiato, num)} title='Aggiungi/Modifica Descrizione' cssClass='e-fill e-warning' type='button'><i class="fas fa-marker"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent id={`btnApriPrezziario-D${numeroDanneggiato}-R${num}`} onClick={this.apriDialogPrezziario.bind(this, numeroDanneggiato, num)} title='Apri Prezziario' cssClass='e-fill e-warning' type='button'><i class="fas fa-clipboard"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentDescrizioneBase-D${numeroDanneggiato}-R${num}`} style={{ width: '98%' }}>
                                        Descrizione<DropDownListComponent placeholder="Descrizione Prezziario"
                                            onChange={this.trasportaDatiPrezziarioAggiuntivi.bind(this, numeroDanneggiato, num)}
                                            dataSource={this.dsPrezziarioDescrizioniLunghe} id={`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`}
                                            fields={this.ddlFields} allowFiltering={true} filterType='Contains' showClearButton={true} floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentNuovaDescrizione-D${numeroDanneggiato}-R${num}`} style={{ width: '98%', display: 'none' }}><TextBoxComponent onBlur={() => { document.getElementById(`btnCambiaDescriz-D${numeroDanneggiato}-R${num}`).click() }} placeholder="Aggiungi/Modifica Descrizione" id={`nuovaDescrizioneLibera-D${numeroDanneggiato}-R${num}`} floatLabelType='Auto' cssClass='e-outline'></TextBoxComponent></th>
                                </th>
                                <th >
                                    <ButtonComponent id={`btnEliminaRigaStimaPrezziario-D${numeroDanneggiato}-R${num}`} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaStime' type='button' onClick={this.eliminaRigaStimaPrezziario.bind(this, `tabPrezziario-D${numeroDanneggiato}-R-${num}`, `tabellePrezziarioContent-D${numeroDanneggiato}`, numeroDanneggiato, num, 'tabellaValoreANuovo')}><i class="fas fa-trash"></i></ButtonComponent>
                                    <ButtonComponent style={{ marginLeft: '15px' }} id={`btnAggiungiRigaStimaPrezziario-D${numeroDanneggiato}-R${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, numeroDanneggiato, 'tabellaValoreANuovo', '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                                </th>
                            </tr>
                            <tr >
                                <th>
                                    <th className='rimuovi-bordo' style={{ width: '25%' }}>
                                        Categoria<DropDownListComponent placeholder="Categoria Prezziario" dataSource={this.dsPrezziarioCategorie} id={`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}`} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '25%' }}>Localizzazione<DropDownListComponent onChange={this.localizzazioneSuInfoRich.bind(this, numeroDanneggiato, num)} placeholder="Localizzazione" dataSource={arrayLocalizzazione} id={`localizzazione_D${numeroDanneggiato}_R${num}`} fields={this.ddlFields}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo' >Unità di Misura<DropDownListComponent placeholder="U.M." id={`UMInput_D${numeroDanneggiato}_R${num}`} fields={this.ddlFields} dataSource={this.dsUM}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline unita-misura'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo' >Quantità <NumericTextBoxComponent value={1} showSpinButton={false} onBlur={this.calcolaRigaStimaValoreANuovo.bind(this, numeroDanneggiato, num, false)} id={`quantitaInput_D${numeroDanneggiato}_R${num}`} format='#.##' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo' >Valore Unitario<NumericTextBoxComponent value={0} showSpinButton={false} id={`valUnitarioInput_D${numeroDanneggiato}_R${num}`} onBlur={this.calcolaRigaStimaValoreANuovo.bind(this, numeroDanneggiato, num, false)} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Valore del Bene<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`totaleResult_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                </th>
                            </tr>
                        </> :

                        //RIGA PREZZIARIO XPERT A NUOVO
                        <>
                            <tr>
                                <th style={{ width: '89%' }}>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnCambiaDescriz-D${numeroDanneggiato}-R${num}`} onClick={this.cambiaDescrizione.bind(this, numeroDanneggiato, num)} title='Aggiungi/Modifica Descrizione' cssClass='e-fill e-warning' type='button'><i class="fas fa-marker"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnApriPrezziario-D${numeroDanneggiato}-R${num}`} onClick={this.apriDialogPrezziario.bind(this, numeroDanneggiato, num)} title='Apri Prezziario' cssClass='e-fill e-warning' type='button'><i class="fas fa-clipboard"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentDescrizioneBase-D${numeroDanneggiato}-R${num}`} style={{ width: '98%' }}>
                                        Descrizione<DropDownListComponent placeholder="Descrizione Prezziario"
                                            onChange={this.trasportaDatiPrezziarioAggiuntivi.bind(this, numeroDanneggiato, num)}
                                            dataSource={this.dsPrezziarioDescrizioniLunghe} id={`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}`}
                                            fields={this.ddlFields} allowFiltering={true} filterType='Contains' showClearButton={true} floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentNuovaDescrizione-D${numeroDanneggiato}-R${num}`} style={{ width: '98%', display: 'none' }}><TextBoxComponent onBlur={() => { document.getElementById(`btnCambiaDescriz-D${numeroDanneggiato}-R${num}`).click() }} placeholder="Aggiungi/Modifica Descrizione" id={`nuovaDescrizioneLibera-D${numeroDanneggiato}-R${num}`} floatLabelType='Auto' cssClass='e-outline'></TextBoxComponent></th>
                                </th>
                                <th >
                                    <ButtonComponent id={`btnEliminaRigaStimaPrezziario-D${numeroDanneggiato}-R${num}`} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaStime' type='button' onClick={this.eliminaRigaStimaPrezziario.bind(this, `tabPrezziario-D${numeroDanneggiato}-R-${num}`, `tabellePrezziarioContent-D${numeroDanneggiato}`, numeroDanneggiato, num, 'tabellaValoreANuovo')}><i class="fas fa-trash"></i></ButtonComponent>
                                    <ButtonComponent style={{ marginLeft: '15px' }} id={`btnAggiungiRigaStimaPrezziario-D${numeroDanneggiato}-R${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, numeroDanneggiato, 'tabellaValoreANuovo', '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                                </th>
                            </tr>
                            <tr>
                                <th style={{ width: '89%' }} className='rimuovi-bordo'>
                                    <th className='rimuovi-bordo' style={{ width: '9.5%' }}></th>
                                    <th className='rimuovi-bordo' >Unità di Misura<DropDownListComponent placeholder="U.M." id={`UMInput_D${numeroDanneggiato}_R${num}`} fields={this.ddlFields} dataSource={this.dsUM}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline unita-misura'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo' >Quantità<NumericTextBoxComponent value={1} showSpinButton={false} onBlur={this.calcolaRigaStimaValoreANuovo.bind(this, numeroDanneggiato, num, false)} id={`quantitaInput_D${numeroDanneggiato}_R${num}`} format='#.##' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo' >Valore Unitario<NumericTextBoxComponent value={0} showSpinButton={false} id={`valUnitarioInput_D${numeroDanneggiato}_R${num}`} onBlur={this.calcolaRigaStimaValoreANuovo.bind(this, numeroDanneggiato, num, false)} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Valore del Bene<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`totaleResult_D${numeroDanneggiato}_R${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                </th>
                                <th className='rimuovi-bordo'></th>
                            </tr>
                        </>}
                </>}</CallbackWrapper>);
            }
        });
    }
    eliminaRigaStimaPrezziario(idContainerRiga, idContainerGenitore, numDann, numRiga, tipoTabellaStima, args) {
        delete this.formOptions.rules[`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`];
        delete this.formOptions.rules[`UMInput_D${numDann}_R${numRiga}`];
        delete this.formOptions.rules[`quantitaInput_D${numDann}_R${numRiga}`];
        delete this.formOptions.rules[`valUnitarioInput_D${numDann}_R${numRiga}`];

        if (this.isCompagniaGenerali()) {
            delete this.formOptions.rules[`categoriaSceltaPrezziario_D${numDann}_R${numRiga}`];
            delete this.formOptions.rules[`localizzazione_D${numDann}_R${numRiga}`];
        }
        if (tipoTabellaStima === 'tabellaStatoUso') {
            delete this.formOptions.rules[`percDegrSupInd_D${numDann}_R${numRiga}`];
        }

        let containerDaElimin = document.getElementById(idContainerRiga);
        let containerGenitore = document.getElementById(idContainerGenitore);
        containerGenitore.removeChild(containerDaElimin);

        if (tipoTabellaStima === 'tabellaValoreANuovo') {
            this.calcolaStimaValoreANuovo(numDann, false);
        }
        else if (tipoTabellaStima === 'tabellaStatoUso') {
            this.calcolaStimaStatoUso(numDann, false)
        }

        this.strutturaStatiStimePrezziari.forEach(elm => {
            if (elm.id === idContainerRiga)
                elm.stato = 'd';
        });
    }
    //---------------------------PREZZIARIO RIGHE FINE-----------------

    //---------------------------COASSICURAZIONI TABELLE INIZIO-----------------
    contentCoassicurazione(num, tipoTabella) {
        return (<>
            <div style={{ textAlign: 'right', padding: '1%' }}>
                <ButtonComponent id={`btnAggiornaDatiCoassicuraz-D${num}`} alt='Aggiorna Coassicurazione' cssClass='e-fill e-info' type='button' onClick={this.aggiornaCoassicurazione.bind(this, num)}>Aggiorna Coassicurazione</ButtonComponent>
            </div>
            <table className='perizia-table' >
                <tr>
                    <th style={{ width: '1500px' }}>COASSICURAZIONE INDIRETTA</th>
                </tr>
            </table>
            <table className='perizia-table' >
                <tr>
                    <th>Compagnie Coinvolte</th>
                    <th>Danno a Riparto</th>
                    <th>N° Polizza</th>
                    <th style={{ width: '500px' }}>Limitazioni Contrattuali</th>
                    <th >Danno netto</th>
                    <th><ButtonComponent style={{ marginLeft: '20px' }} id={`btnAggiungiRigaCompagniaR1-D${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaCompagniaCoassicurazioneRiparto1.bind(this, num, '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                </tr>
            </table>
            <table id={`contentTutteLeRigheCompagnieRiparto1-D${num}`} className='perizia-table tutteLeRigheCompagnie' >
            </table>
            <table className='perizia-table' >
                <tr>
                    <th style={{ width: '71.4%', textAlign: 'right' }}>Somma Danni</th>
                    <th><NumericTextBoxComponent showSpinButton={false} ù id={`sommaDanniRiparto1_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th></th>
                </tr>
            </table>
            <table id={`contentTutteLeRigheCompagnieRiparto2-D${num}`} className='perizia-table' >
            </table>
            <table className='perizia-table' id={`contentSommaDanniRip2-D${num}`} >
                <tr>
                    <th style={{ width: '71.4%', textAlign: 'right' }}>Somma Danni</th>
                    <th><NumericTextBoxComponent showSpinButton={false} id={`sommaDanniRiparto2_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th></th>
                </tr>
            </table>
            {tipoTabella === 'statoUso' ? <>
                <table className='perizia-table' id={`contentIndVsCompRip2-D${num}`} >
                    <tr>
                        <th style={{ textAlign: 'right' }}>VSU :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} id={`vsuCoass-D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ textAlign: 'right' }}>Suppl. Indennizzo :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} id={`supplIndennitaCoass-D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ textAlign: 'right' }}>Indenn. A carico Vs Compagnia :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} id={`indennizzoVsCompagnia_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                </table>
            </> :
                <>
                    <table className='perizia-table' id={`contentIndVsCompRip2-D${num}`} >
                        <tr>
                            <th style={{ width: '71.4%', textAlign: 'right' }}>Indennizzo a Carico Vs Compagnia :</th>
                            <th><NumericTextBoxComponent showSpinButton={false} id={`indennizzoVsCompagnia_D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                            <th></th>
                        </tr>
                    </table>
                </>}
            <table className='perizia-table' id={`contentIndVsCompRip2-D${num}`} >
                <tr>
                    <th style={{ width: '14.2%', textAlign: 'right' }}>Note Coassicurazione</th>
                    <th><TextBoxComponent id={`noteCoassicurazione-D${num}`} floatLabelType='Auto' placeholder='Note Coassicurazione' cssClass='e-outline'></TextBoxComponent></th>
                </tr>
            </table>
        </>)
    }

    aggiungiRigaCompagniaCoassicurazioneRiparto1(numeroDanneggiato, retDataCoassC, numRiga0) {
        return new Promise((resolve, reject) => {
            const funzCoass2 = async () => {

                var num = this.numRigheCoassicurazione;
                await this.aggiungiRigaCompagniaCoassicurazioneRiparto2(numeroDanneggiato, num, retDataCoassC)
                this.numRigheCoassicurazione++;

                const DivContainer2 = document.getElementById(`contentTutteLeRigheCompagnieRiparto1-D${numeroDanneggiato}`);
                const newTr2 = document.createElement("tr");
                newTr2.setAttribute("id", `rigaRiparto1-D${numeroDanneggiato}-C${num}`);
                DivContainer2.append(newTr2);

                const container = document.getElementById(`rigaRiparto1-D${numeroDanneggiato}-C${num}`)
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    if (CustomJS.isNullEmpty(retDataCoassC)) {
                        if (!CustomJS.isNullEmpty(numRiga0)) {
                            if (CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value)) {
                                if (this.isCompagniaGenerali()) {
                                    document.getElementById(`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = this.IdCompagniaGeneraliSuCodiciAnia.toString();
                                }
                                else if (this.isCompagniaUnipol()) {
                                    document.getElementById(`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = this.props.IdCompagnia.toString();
                                }
                                if (document.getElementById(`determinazioneDanno_D${numeroDanneggiato}`).ej2_instances[0].value === '001')
                                    document.getElementById(`dannoARipartoRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = document.getElementById(`dannoNetto_D${numeroDanneggiato}`).ej2_instances[0].value

                                else if (document.getElementById(`determinazioneDanno_D${numeroDanneggiato}`).ej2_instances[0].value === '003')
                                    document.getElementById(`dannoARipartoRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = document.getElementById(`valoreANuovodannoNetto_D${numeroDanneggiato}`).ej2_instances[0].value
                            }
                        }
                        document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = 'nessuno';
                        document.getElementById(`limitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = 0;
                        var objStrutturaStatiRigheCoass = {
                            stato: 'n',
                            stato_iniziale: 'n',
                            keyXpert: null,
                            keyGen: null,
                            id: `rigaRiparto1-D${numeroDanneggiato}-C${num}`,
                            c: num
                        };
                        this.strutturaStatiCoassicurazioniRighe.push(objStrutturaStatiRigheCoass);
                        resolve();
                    }
                    else {
                        document.getElementById(`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = retDataCoassC.compagniaCoinvolta;
                        document.getElementById(`dannoARipartoRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = retDataCoassC.dannoARiparto
                        document.getElementById(`numPolizzaRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = retDataCoassC.polizzaCoass
                        document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = retDataCoassC.tipoLimiteContrattuale
                        document.getElementById(`limitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = retDataCoassC.limiteContrattuale
                        document.getElementById(`dannoNettoCoassDirRiparto1-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = retDataCoassC.dannoNetto

                        var objStrutturaStatiRigheCoass = {
                            stato: 'o',
                            stato_iniziale: 'o',
                            keyXpert: retDataCoassC.id_compagnia_coass,
                            keyGen: retDataCoassC.id_coass_gen,
                            id: `rigaRiparto1-D${numeroDanneggiato}-C${num}`,
                            c: num
                        };
                        this.strutturaStatiCoassicurazioniRighe.push(objStrutturaStatiRigheCoass);

                        resolve();
                    }
                }}>{<>
                    <th><DropDownListComponent onChange={this.cambiaCompagniaSuRip2.bind(this, numeroDanneggiato, num)} id={`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}`} fields={this.ddlFields}
                        allowFiltering={true} dataSource={this.dsCompagnie} filterType='Contains' showClearButton={true}
                        floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                    </DropDownListComponent></th>
                    <th><NumericTextBoxComponent showSpinButton={false} onBlur={this.calcolaRigaCoassicurazione.bind(this, numeroDanneggiato, num, false)} id={`dannoARipartoRiparto1-D${numeroDanneggiato}-C${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th><TextBoxComponent onBlur={this.calcolaRigaCoassicurazione.bind(this, numeroDanneggiato, num, false)} id={`numPolizzaRiparto1-D${numeroDanneggiato}-C${num}`} floatLabelType='Auto' placeholder='N° Polizza' cssClass='e-outline'></TextBoxComponent></th>
                    <th><DropDownListComponent onChange={this.calcolaRigaCoassicurazione.bind(this, numeroDanneggiato, num, false)} id={`tipoLimitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}`} fields={this.ddlFields} dataSource={this.limitiContrattTipiCoass}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                    </DropDownListComponent></th>
                    <th ><NumericTextBoxComponent showSpinButton={false} onBlur={this.calcolaRigaCoassicurazione.bind(this, numeroDanneggiato, num, false)} id={`limitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}`} floatLabelType='Auto' format="#.##" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th> <NumericTextBoxComponent showSpinButton={false} id={`dannoNettoCoassDirRiparto1-D${numeroDanneggiato}-C${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th >
                        <ButtonComponent id={`btnEliminaCompagniaRiparto1-D${numeroDanneggiato}-C${num}`} title='Elimina Riga' cssClass='e-fill e-danger eliminaBottoneCoassSecond' type='button' onClick={this.eliminaRigaCompagniaRiparto1.bind(this, numeroDanneggiato, num, false)}><i class="fas fa-trash"></i></ButtonComponent>
                        <ButtonComponent style={{ marginLeft: '15px' }} id={`btnAggiungiRigaCompagniaR1-D${numeroDanneggiato}-R${num}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaCompagniaCoassicurazioneRiparto1.bind(this, numeroDanneggiato, '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                    </th>
                </>}</CallbackWrapper>);
            }
            funzCoass2();
        });
    }
    aggiungiRigaCompagniaCoassicurazioneRiparto2(numeroDanneggiato, num, retDataCoassC) {
        return new Promise((resolve, reject) => {

            const DivContainer2 = document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${numeroDanneggiato}`);
            const newTr2 = document.createElement("tr");
            newTr2.setAttribute("id", `rigaRiparto2-D${numeroDanneggiato}-C${num}`);
            DivContainer2.append(newTr2);

            const container = document.getElementById(`rigaRiparto2-D${numeroDanneggiato}-C${num}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                if (!CustomJS.isNullEmpty(retDataCoassC)) {
                    document.getElementById(`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = retDataCoassC.compagniaCoinvolta;
                    document.getElementById(`formulaCoass-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = retDataCoassC.formula;
                    document.getElementById(`dannNettoRip2-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = retDataCoassC.dannoNettoRip2
                    resolve()
                }
                else {
                    if (CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value)) {
                        if (this.isCompagniaGenerali()) {
                            document.getElementById(`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = this.IdCompagniaGeneraliSuCodiciAnia.toString();
                        }
                        else if (this.isCompagniaUnipol()) {
                            document.getElementById(`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}`).ej2_instances[0].value = this.props.IdCompagnia.toString();
                        }
                    }
                    resolve();
                }
            }}>{<>
                <th><DropDownListComponent id={`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}`} fields={this.ddlFields} dataSource={this.dsCompagnie}
                    allowFiltering={true} filterType='Contains' showClearButton={true}
                    floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                </DropDownListComponent></th>
                <th style={{ width: '750px' }} ><TextBoxComponent id={`formulaCoass-D${numeroDanneggiato}-C${num}`} floatLabelType='Auto' placeholder='Formula' cssClass='e-outline'></TextBoxComponent></th>
                <th><NumericTextBoxComponent showSpinButton={false} id={`dannNettoRip2-D${numeroDanneggiato}-C${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
            </>}</CallbackWrapper>);
        });

    }

    eliminaRigaCompagniaRiparto1(numeroDanneggiato, numeroRiga, fromCaricaDati, args) {
        document.getElementById(`dannoARipartoRiparto1-D${numeroDanneggiato}-C${numeroRiga}`).ej2_instances[0].value = 0;
        document.getElementById(`limitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${numeroRiga}`).ej2_instances[0].value = 0;
        document.getElementById(`dannoNettoCoassDirRiparto1-D${numeroDanneggiato}-C${numeroRiga}`).ej2_instances[0].value = 0;
        let d = document.getElementById(`contentTutteLeRigheCompagnieRiparto1-D${numeroDanneggiato}`);
        d.removeChild(document.getElementById(`rigaRiparto1-D${numeroDanneggiato}-C${numeroRiga}`));
        let d2 = document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${numeroDanneggiato}`);
        d2.removeChild(document.getElementById(`rigaRiparto2-D${numeroDanneggiato}-C${numeroRiga}`));
        if (fromCaricaDati !== true) {
            setTimeout(() => {
                this.calcolaCoassicurazioneCompleta(numeroDanneggiato, false)
            }, 350)
        }
        this.strutturaStatiCoassicurazioniRighe.forEach(elm => {
            if (elm.id === `rigaRiparto1-D${numeroDanneggiato}-C${numeroRiga}`) {
                elm.stato = 'd'
            }
        })
        this.strutturaStatiCoassicurazioniRighe.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)
    }

    apriCoassicurazione(num) {
        const startTime = performance.now();
        document.getElementById(`accordionCoassicurazione-D${num}`).ej2_instances[0].expandItem(true, 0);
        var numRiga0 = 0;
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}`)) {
                numRiga0 = index;
                break;
            }
        }
        const endTime = performance.now();
        const totalTime = endTime - startTime;
        setTimeout(async () => {
            for (let index = 0; index < 2; index++) {
                await this.aggiungiRigaCompagniaCoassicurazioneRiparto1(num, '', numRiga0)
                if (index === 1) {
                    document.getElementById(`accordionCoassicurazione-D${num}`).style.removeProperty('display');
                }
            }
        }, totalTime)
    }

    chiudiCoassicurazione(num) {
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}`)) {
                let containerDaElimin = document.getElementById(`rigaRiparto1-D${num}-C${index}`);
                let containerGenitore = document.getElementById(`contentTutteLeRigheCompagnieRiparto1-D${num}`);
                containerGenitore.removeChild(containerDaElimin);
                let containerDaElimin2 = document.getElementById(`rigaRiparto2-D${num}-C${index}`);
                let containerGenitore2 = document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${num}`);
                containerGenitore2.removeChild(containerDaElimin2);

                this.strutturaStatiCoassicurazioniRighe.forEach(elm => {
                    if (elm.id === `rigaRiparto1-D${num}-C${index}`) {
                        elm.stato = 'd'
                    }
                })
                this.strutturaStatiCoassicurazioniRighe.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)
            }
        }
        document.getElementById(`accordionCoassicurazione-D${num}`).ej2_instances[0].expandItem(false, 0);
        document.getElementById(`accordionCoassicurazione-D${num}`).style.display = 'none';
    }
    coassicurazionePresenteBool(numDann) {
        //Se c'è la coassicurazione
        if (document.getElementById(`coassicurazioneSi-D${numDann}`).ej2_instances[0].checked)
            this.apriCoassicurazione(numDann)
        else
            this.chiudiCoassicurazione(numDann)
        setTimeout(() => {
            this.trasportaDatiRiepilogo();
        }, 300)
    }

    //---------------------------COASSICURAZIONI TABELLE FINE-----------------

    //---------------------------STIMA TABELLE FINE-----------------

    //--------------------------RIEPILOGO TABELLA INIZIO-------------------------
    async tabellaRiepilogo0(idDanneggiato, comboTabelleStima) {
        return new Promise((resolve, reject) => {
            var num = this.numTabelleRiepilogo;
            this.numTabelleRiepilogo++;

            const DivContainer = document.getElementById('tabellaCompletaTutteIRiepiloghi');
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `containerTabRiepilogo-D${num}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`containerTabRiepilogo-D${num}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                const funzioneAsync = async () => {
                    document.getElementById(`danneggiatoRiep-D${num}`).ej2_instances[0].value = idDanneggiato;
                    setTimeout(async () => {
                        document.getElementById(`danneggiatoRiepTesto-D${num}`).innerText = document.getElementById(`danneggiatoRiep-D${num}`).ej2_instances[0].text;

                        document.getElementById(`nomeDanneggiatoTotIndLordoRiep-D${num}`).innerText = "Totale : " + document.getElementById(`danneggiatoRiep-D${num}`).ej2_instances[0].text;
                        for (let index = 0; index < comboTabelleStima.danneggiati.length; index++) {
                            if (comboTabelleStima.danneggiati[index] === idDanneggiato) {
                                await this.tabellaRiepilogo1(num, comboTabelleStima.garanzie[index], comboTabelleStima.partite[index]);
                                this.numRigheRiepilogoGaranzie++;
                                if (index === comboTabelleStima.danneggiati.length - 1)
                                    resolve();
                            }
                            else {
                                if (index === comboTabelleStima.danneggiati.length - 1)
                                    resolve();
                            }
                        }
                    }, 150)
                }
                funzioneAsync();
            }}>{<>
                <table className='perizia-table' >
                    <tr className='riepilogoParziale'>
                        <th>
                            <div style={{ display: 'none' }}>
                                <DropDownListComponent readonly={true} id={`danneggiatoRiep-D${num}`} fields={this.ddlFields} dataSource={this.dsDanneggiati}
                                    allowFiltering={true} filterType='Contains' showClearButton={true}
                                    placeholder="Danneggiato" floatLabelType='always' cssClass='e-outline'>
                                </DropDownListComponent>
                            </div>
                            <h5 className='danneggiato-testp-riep' id={`danneggiatoRiepTesto-D${num}`}></h5>
                        </th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th></th>
                        <th >Indennizzo Lordo in Uso</th>
                        <th>Franchigia/Scoperto</th>
                        <th >Supplemento Indennità</th>
                        <th >Indennizzo Netto</th>
                    </tr>
                </table>
                <table id={`quanteGaranzieRiep-D${num}`} className='perizia-table' >
                </table>
                <table className='perizia-table riepilogoParziale'>
                    <tr id={`rigaTotaliDanneggiatiRiepilogo-D${num}`}>
                        <th ><h6 style={{ fontFamily: 'Franklin Gothic Medium', fontSize: '18px', fontWeight: 'bold', fontStyle: 'italic' }} id={`nomeDanneggiatoTotIndLordoRiep-D${num}`}></h6></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennizzoLordo-D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleFranchige-D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleSuppIndenn-D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennizzo-D${num}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                    </tr>
                </table>
            </>}</CallbackWrapper>);
        });

    }
    tabellaRiepilogo1(num, idGaranzia, idPartita) {
        return new Promise((resolve, reject) => {
            var riga = this.numRigheRiepilogoGaranzie
            const DivContainer = document.getElementById(`quanteGaranzieRiep-D${num}`);
            const newDiv = document.createElement("tr");
            newDiv.setAttribute("id", `garanziaRiepIntestazione-D${num}-R-${riga}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`garanziaRiepIntestazione-D${num}-R-${riga}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                document.getElementById(`partitadannRiep-D${num}-R-${riga}`).ej2_instances[0].value = idPartita;
                document.getElementById(`garanziadannRiep-D${num}-R-${riga}`).ej2_instances[0].value = idGaranzia;
                setTimeout(() => {
                    document.getElementById(`partitadannRiepTesto-D${num}-R-${riga}`).innerText = document.getElementById(`partitadannRiep-D${num}-R-${riga}`).ej2_instances[0].text;
                    document.getElementById(`garanziadannRiepTesto-D${num}-R-${riga}`).innerText = document.getElementById(`garanziadannRiep-D${num}-R-${riga}`).ej2_instances[0].text;
                    resolve();
                }, 100)
            }}>{<>
                <th>
                    <div style={{ display: 'none' }}>
                        <DropDownListComponent readonly={true} id={`partitadannRiep-D${num}-R-${riga}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                        <DropDownListComponent readonly={true} id={`garanziadannRiep-D${num}-R-${riga}`} fields={this.ddlFields} dataSource={this.dsGaranzie}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <h6 style={{ fontStyle: 'italic' }} id={`partitadannRiepTesto-D${num}-R-${riga}`}></h6>
                    <h6 style={{ fontStyle: 'italic' }} id={`garanziadannRiepTesto-D${num}-R-${riga}`}></h6>
                </th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoLordoRiep-D${num}-${riga}`} value={0} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigiaRiep-D${num}-${riga}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`supplIndennRiepilogo-D${num}-${riga}`} value={0} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoNettoRiep-D${num}-${riga}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
            </>}</CallbackWrapper>);
        });
    }
    tabellaRiepilogo2(num) {
        return new Promise((resolve, reject) => {
            var riga = this.numRigheRiepilogoGaranzie;
            const DivContainer = document.getElementById(`quanteGaranzieRiep-D${num}`);
            const newDiv = document.createElement("tr");
            newDiv.setAttribute("id", `garanziaRiep-D${num}-R-${riga}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`garanziaRiep-D${num}-R-${riga}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                resolve();
            }}>{<>
                <th></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoLordoRiep-D${num}-${riga}`} value={0} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigiaRiep-D${num}-${riga}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`supplIndennRiepilogo-D${num}-${riga}`} value={0} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoNettoRiep-D${num}-${riga}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
            </>}</CallbackWrapper>);
        });
    }
    //--------------------------RIEPILOGO TABELLA FINE----------------------------

    //----------------------------LISTBOX TABELLE INIZIO-------------------------
    cambiaListBox(t, index, numCategorie) {
        for (let n = 0; n < numCategorie; n++) {
            if (document.getElementById(`lista-t${t}-n${n}`)) {
                document.getElementById(`lista-t${t}-n${n}`).style.display = 'none';
                document.getElementById(`btnSwitchCategorie-t${t}-n${n}`).style.backgroundColor = '#FFFFFF'
            }
        }
        document.getElementById(`lista-t${t}-n${index}`).style.removeProperty('display');
        document.getElementById(`btnSwitchCategorie-t${t}-n${index}`).style.backgroundColor = '#cac6da'

    }
    cambiaRispostaList(idRisp, args) {
        var risp = args.value;
        var risposta = ''
        args.target.jsonData.forEach(elm => {
            risp.forEach(elm2 => {
                if (elm.id === elm2) {
                    risposta += elm.text + ', '
                }
            })
        })
        document.getElementById(idRisp).innerText = risposta.substring(0, risposta.length - 2);
    }

    ripristiniSuInfoRich(numeroDanneggiato, siNo) {
        //TG002 = elettronica
        if (document.getElementById(`garanziaFromSin-D${numeroDanneggiato}`).ej2_instances[0].value === 'TG002') {
            var str2 = document.getElementById(`garanziaFromSin-D${numeroDanneggiato}`).ej2_instances[0].itemData.SERVIZIO_STR2;
            var categorie = []
            this.nuovoArrInfoRichRiep.forEach((elmS) => {
                if (elmS.SERVIZIO_STR2 === str2)
                    categorie.push({
                        VALUE: elmS.COD_ELE
                    })
            })
            for (let n = 0; n < categorie.length; n++) {
                //ripristini
                if (categorie[n].VALUE === '22') {
                    if (document.getElementById(`listbox-t${numeroDanneggiato}-n${n}`)) {
                        if (siNo === 'si') {
                            document.getElementById(`risposta-t${numeroDanneggiato}-n${n}`).innerText = "Ripristini avvenuti";
                            document.getElementById(`listbox-t${numeroDanneggiato}-n${n}`).ej2_instances[0].value = ['00131'];
                        }
                        else if (siNo === 'no') {
                            document.getElementById(`risposta-t${numeroDanneggiato}-n${n}`).innerText = "Ripristini non avvenuti";
                            document.getElementById(`listbox-t${numeroDanneggiato}-n${n}`).ej2_instances[0].value = ['00132'];
                        }
                        else {
                            document.getElementById(`risposta-t${numeroDanneggiato}-n${n}`).innerText = "Ripristini parziali";
                            document.getElementById(`listbox-t${numeroDanneggiato}-n${n}`).ej2_instances[0].value = ['00133'];
                        }
                    }
                }
            }
        }
    }

    localizzazioneSuInfoRich(numeroDanneggiato, num) {
        //locale interessato
        //acqua condotta
        if (document.getElementById(`garanziaFromSin-D${numeroDanneggiato}`).ej2_instances[0].value === 'TG001') {

            var localizzazioni = []
            this.strutturaStatiStimePrezziari.forEach(prezz => {
                if (document.getElementById(`tabPrezziario-D${numeroDanneggiato}-R-${prezz.r}`)) {
                    let localName = document.getElementById(`localizzazione_D${numeroDanneggiato}_R${prezz.r}`).ej2_instances[0].text;
                    localName = CustomJS.isNullEmpty(localName) ? '' : localName.toLowerCase().trim()
                    localizzazioni.push(localName)
                }
            })

            var localizzazioniUnique = localizzazioni.filter((obj, index) => {
                return index === localizzazioni.findIndex(o => obj === o);

            });
            localizzazioni = localizzazioniUnique;

            var str2 = document.getElementById(`garanziaFromSin-D${numeroDanneggiato}`).ej2_instances[0].itemData.SERVIZIO_STR2;
            var categorie = []
            this.nuovoArrInfoRichRiep.forEach((elmS) => {
                if (elmS.SERVIZIO_STR2 === str2)
                    categorie.push({
                        VALUE: elmS.COD_ELE
                    })
            })
            for (let n = 0; n < categorie.length; n++) {
                //locale interessato
                if (categorie[n].VALUE === '1') {
                    if (document.getElementById(`listbox-t${numeroDanneggiato}-n${n}`)) {
                        var arrayListbox = [];
                        var risposta = "";
                        localizzazioni.forEach(localizzazione => {
                            document.getElementById(`listbox-t${numeroDanneggiato}-n${n}`).ej2_instances[0].jsonData.forEach(locale => {
                                if (!CustomJS.isNullEmpty(locale)) {
                                    if (locale.text.toLowerCase().trim() === localizzazione) {
                                        arrayListbox.push(locale.id)
                                        if (CustomJS.isNullEmpty(risposta))
                                            risposta = locale.text;
                                        else
                                            risposta += ", " + locale.text;
                                    }
                                }
                            });
                        })
                        document.getElementById(`risposta-t${numeroDanneggiato}-n${n}`).innerText = risposta;
                        document.getElementById(`listbox-t${numeroDanneggiato}-n${n}`).ej2_instances[0].value = arrayListbox;
                    }
                }
            }
        }
    }

    nuovaCreaInfoRichieste(dann, part, gar, garCodEle, idStima, idDanno, num, codEle, str2, retData) {
        var idStimaDB = [{ TEXT: idStima, VALUE: idStima }];
        const DivContainer = document.getElementById('containerNuovoTutteLeListBox');
        const newDiv = document.createElement("div");
        newDiv.setAttribute("id", `tabellaInfoRichieste-t${num}`);
        newDiv.setAttribute("class", `tabellaInfoRichieste`);
        DivContainer.append(newDiv);

        const container = document.getElementById(`tabellaInfoRichieste-t${num}`);
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {

            //-------CALLBACK TABELLA LIST INIZIO--------
            var dataBaseCategorie = []
            this.nuovoArrInfoRichRiep.forEach((elm) => {
                if (elm.SERVIZIO_STR2 === str2)
                    dataBaseCategorie.push({
                        TEXT: elm.DESCRIZ,
                        VALUE: elm.COD_ELE,
                        TIPOLOGIA: elm.SERVIZIO_STR3,
                    })
            })
            const DivContainer2 = document.getElementById(`boxBottoniList-t${num}`);
            const DivContainer3 = document.getElementById(`boxList-t${num}`);
            for (let index = 0; index < dataBaseCategorie.length; index++) {
                //-------CALLBACK BOTTONI DINAMICI INIZIO--------
                var newDiv2 = document.createElement("div");
                newDiv2.setAttribute("id", `categoria-t${num}-n${index}`);
                DivContainer2.append(newDiv2);
                const container2 = document.getElementById(`categoria-t${num}-n${index}`);
                const root2 = createRoot(container2);
                root2.render(<CallbackWrapper callback={() => {
                    document.getElementById(`elmCategorie-t${num}-n${index}`).ej2_instances[0].value = dataBaseCategorie[index].VALUE

                    //-------CALLBACK LISTA INIZIO--------
                    var data = []
                    this.nuovoArrRispInfoRicRiep.forEach(element => {
                        if (element.SERVIZIO_STR2 === dataBaseCategorie[index].VALUE) {
                            data.push({
                                id: element.COD_ELE,
                                text: (element.DESCRIZ.toLowerCase()).charAt(0).toUpperCase() + (element.DESCRIZ.toLowerCase()).slice(1)
                            })
                        }
                    })
                    var newDiv3 = document.createElement("div");
                    newDiv3.setAttribute("id", `lista-t${num}-n${index}`);
                    newDiv3.style.display = 'none'
                    DivContainer3.append(newDiv3);
                    const container3 = document.getElementById(`lista-t${num}-n${index}`);
                    const root3 = createRoot(container3);

                    // N = singolo
                    if (dataBaseCategorie[index].TIPOLOGIA === 'N') {
                        root3.render(<CallbackWrapper callback={() => {
                            if (index === 0)
                                document.getElementById(`lista-t${num}-n${index}`).style.removeProperty('display')

                            if (CustomJS.isNullEmpty(retData)) {
                                //TG019 = ricerca guasto / TG001 = acqua condotta / TG002 = elettronica
                                if (codEle === 'TG019' || codEle === 'TG001' || codEle === 'TG002') {
                                    //modalità esecuzione perizia
                                    if (dataBaseCategorie[index].VALUE === '7') {
                                        if (this.tipoServizio === "video perizia") {
                                            document.getElementById(`listbox-t${num}-n${index}`).ej2_instances[0].value = ["00036"]
                                            document.getElementById(`risposta-t${num}-n${index}`).innerText = 'Videoperizia'
                                        }
                                        else if (this.tipoServizio === "perizia tradizionale") {
                                            document.getElementById(`listbox-t${num}-n${index}`).ej2_instances[0].value = ["00037"]
                                            document.getElementById(`risposta-t${num}-n${index}`).innerText = 'Sopralluogo in Loco'
                                        }
                                        else if (this.tipoServizio === "riparazione in forma specifica") {
                                            document.getElementById(`listbox-t${num}-n${index}`).ej2_instances[0].value = ["00038"]
                                            document.getElementById(`risposta-t${num}-n${index}`).innerText = 'Riparazione in forma specifica'
                                        }
                                    }

                                    //TG019 = ricerca guasto / TG001 = acqua condotta
                                    if (codEle === 'TG019' || codEle === 'TG001') {
                                        //grado difficioltà
                                        if (dataBaseCategorie[index].VALUE === '4') {
                                            document.getElementById(`listbox-t${num}-n${index}`).ej2_instances[0].value = ["00026"]
                                            document.getElementById(`risposta-t${num}-n${index}`).innerText = 'Medio'
                                        }
                                        //proprietà
                                        if (dataBaseCategorie[index].VALUE === '2') {
                                            document.getElementById(`listbox-t${num}-n${index}`).ej2_instances[0].value = ["00022"]
                                            document.getElementById(`risposta-t${num}-n${index}`).innerText = 'Privata'
                                        }
                                    }

                                }
                            } else {
                                var arrayElementi = []
                                var risposta = ''
                                for (let r = 0; r < retData.length; r++) {
                                    if (retData[r].id_categoria === dataBaseCategorie[index].VALUE) {
                                        arrayElementi.push(retData[r].id_elemento)
                                        risposta = retData[r].risposta
                                    }
                                }
                                document.getElementById(`listbox-t${num}-n${index}`).ej2_instances[0].value = arrayElementi
                                document.getElementById(`risposta-t${num}-n${index}`).innerText = risposta
                            }
                        }}>{<>
                            <div className='row'>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-right">
                                    <ButtonComponent id={`btnCopiaRisposte-t${num}-n${index}`} type='button' title='Copia risposte della stessa garanzia' cssClass='e-fill' onClick={this.copiaRisposteStessaGaranzia.bind(this, num)}>COPIA RISPOSTE STESSA GARANZIA</ButtonComponent>
                                </div>
                            </div>
                            <ListBoxComponent id={`listbox-t${num}-n${index}`} onChange={this.cambiaRispostaList.bind(this, `risposta-t${num}-n${index}`)} dataSource={data} fields={this.ddlFieldsList} cssClass='listboxRiepCss' selectionSettings={{ mode: "Single", showCheckbox: false }} >
                                <Inject services={[CheckBoxSelection]} />
                            </ListBoxComponent>
                        </>}</CallbackWrapper>);
                    }
                    //S = multiplo
                    else if (dataBaseCategorie[index].TIPOLOGIA === 'S') {
                        root3.render(<CallbackWrapper callback={() => {
                            if (index === 0)
                                document.getElementById(`lista-t${num}-n${index}`).style.removeProperty('display')
                            if (CustomJS.isNullEmpty(retData)) {

                            }
                            else {
                                var arrayElementi = []
                                var arrayDescrizioni = []
                                for (let r = 0; r < retData.length; r++) {
                                    if (retData[r].id_categoria === document.getElementById(`elmCategorie-t${num}-n${index}`).ej2_instances[0].value) {
                                        arrayElementi.push(retData[r].id_elemento);
                                        arrayDescrizioni.push(retData[r].risposta)
                                    }
                                }
                                var risposta = ''
                                arrayDescrizioni.forEach(elm => {
                                    risposta += elm + ', '
                                })
                                document.getElementById(`listbox-t${num}-n${index}`).ej2_instances[0].value = arrayElementi
                                document.getElementById(`risposta-t${num}-n${index}`).innerText = risposta

                            }

                        }}>{<>
                            <div className='row'>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-right">
                                    <ButtonComponent id={`btnCopiaRisposte-t${num}-n${index}`} type='button' title='Copia risposte della stessa garanzia' cssClass='e-fill' onClick={this.copiaRisposteStessaGaranzia.bind(this, num)}>COPIA RISPOSTE STESSA GARANZIA</ButtonComponent>
                                </div>
                            </div>
                            <ListBoxComponent id={`listbox-t${num}-n${index}`} onChange={this.cambiaRispostaList.bind(this, `risposta-t${num}-n${index}`)} dataSource={data} fields={this.ddlFieldsList} cssClass='listboxRiepCss' selectionSettings={{ mode: "Multiple", showCheckbox: true }}  >
                                <Inject services={[CheckBoxSelection]} />
                            </ListBoxComponent>
                        </>}</CallbackWrapper>);
                    }

                    //-------CALLBACK LISTA FINE--------
                    document.getElementById(`idStimaList-t${num}`).ej2_instances[0].value = idStima;
                    setTimeout(() => {
                        document.getElementById(`idStimaList-t${num}`).ej2_instances[0].itemData.KEYGEN = idDanno;
                    }, 300)
                }}>{<>
                    <div className='row e-box-riga-inforichieste'>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ padding: '2%' }}>
                            <ButtonComponent style={{ backgroundColor: 'white' }} onClick={this.cambiaListBox.bind(this, num, index, dataBaseCategorie.length)} id={`btnSwitchCategorie-t${num}-n${index}`} cssClass='e-fill e-info-richieste-btn' type='button'>{dataBaseCategorie[index].TEXT}</ButtonComponent>
                            <div style={{ display: 'none' }}> <DropDownListComponent dataSource={dataBaseCategorie} id={`elmCategorie-t${num}-n${index}`} fields={this.ddlFields}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                floatLabelType='always' popupHeight="250px" cssClass='e-outline buttondivLabelRiep2'>
                            </DropDownListComponent></div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div className='e-risposta-inforichieste'>
                                <h6 id={`risposta-t${num}-n${index}`}></h6>
                            </div>
                        </div>
                    </div>
                </>}</CallbackWrapper>);
                //-------CALLBACK BOTTONI DINAMICI FINE--------
            }
        }}>{<>
            <div className="row card-layout intestazione-info-richieste">
                <div className="row">
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <h5 id={`danneggiatoInfoRichieste-t${num}`}>{dann}</h5>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <h5 id={`partitaInfoRichieste-t${num}`}>{part}</h5>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <h5 id={`garanziaInfoRichieste-t${num}`}>{gar}</h5>
                        <h5 style={{ display: 'none' }} id={`garanziaCodEleInfoRichieste-t${num}`}>{garCodEle}</h5>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                    <div tabIndex="0" className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                        <div style={{ display: 'none' }}>
                            <DropDownListComponent readonly={true} id={`idStimaList-t${num}`} fields={this.ddlFields} dataSource={idStimaDB}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Stima" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div id={`boxBottoniList-t${num}`} className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                            {/* qui i bottoni dinamici */}
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                    <div tabIndex="0" className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                        <div id={`boxList-t${num}`} className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                            {/* qui listbox */}
                        </div>
                    </div>
                </div>
            </div>
        </>}</CallbackWrapper>);
        //-------CALLBACK TABELLA LIST FINE--------

    }

    copiaRisposteStessaGaranzia(num) {
        for (let stima = 1; stima < this.numStime; stima++) {
            if (document.getElementById(`boxList-t${stima}`)) {
                var figli = document.getElementById(`boxList-t${stima}`).childNodes;
                for (let index = 0; index < figli.length; index++) {
                    if (document.getElementById(`garanziaCodEleInfoRichieste-t${stima}`).innerText === document.getElementById(`garanziaCodEleInfoRichieste-t${num}`).innerText) {
                        document.getElementById(`listbox-t${num}-n${index}`).ej2_instances[0].value = document.getElementById(`listbox-t${stima}-n${index}`).ej2_instances[0].value
                        document.getElementById(`risposta-t${num}-n${index}`).innerText = document.getElementById(`risposta-t${stima}-n${index}`).innerText
                    }
                }

            }
        }
    }
    //----------------------------LISTBOX TABELLE FINE---------------------------

    //--------------------------CREAZIONE TABELLE FINE--------------------------


    //----------------------------PREESISTENZA CALCOLI INIZIO----------------------------

    calcolaPreesistenza(numPart, numRiga) {
        //VALORE DEROGATO
        var somma = CustomJS.isNullEmpty(document.getElementById(`sommaAssicurataInput_T${numPart}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaAssicurataInput_T${numPart}`).ej2_instances[0].value;
        var deroga = CustomJS.isNullEmpty(document.getElementById(`derogaInput_T${numPart}`).ej2_instances[0].value) ? 0 : document.getElementById(`derogaInput_T${numPart}`).ej2_instances[0].value;
        var percentuale = (somma / 100) * deroga;
        var risultato = somma + percentuale;
        document.getElementById(`valoreDerogatoResult_T${numPart}`).ej2_instances[0].value = parseFloat(risultato.toFixed(2));

        //VOLUME E RICOSTRUZIONE A NUOVO
        if (numRiga !== 'noRiga') {
            var risultato = 0;
            var risultato2 = 0;
            var valoreUnitario = CustomJS.isNullEmpty(document.getElementById(`valoreUnitarioInput_T${numPart}_R_${numRiga}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreUnitarioInput_T${numPart}_R_${numRiga}`).ej2_instances[0].value;
            var superficie = CustomJS.isNullEmpty(document.getElementById(`superficieEquivalenteInput_T${numPart}_R_${numRiga}`).ej2_instances[0].value) ? 0 : document.getElementById(`superficieEquivalenteInput_T${numPart}_R_${numRiga}`).ej2_instances[0].value;
            var altezza = CustomJS.isNullEmpty(document.getElementById(`altezzaInput_T${numPart}_R_${numRiga}`).ej2_instances[0].value) ? 0 : document.getElementById(`altezzaInput_T${numPart}_R_${numRiga}`).ej2_instances[0].value;
            risultato = superficie * altezza;
            document.getElementById(`volumeResult_T${numPart}_R_${numRiga}`).ej2_instances[0].value = parseFloat(risultato.toFixed(2))
            risultato2 = valoreUnitario * risultato;
            document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${numRiga}`).ej2_instances[0].value = parseFloat(risultato2.toFixed(2))
        }

        //TOTALE ESISTENZA A NUOVO
        var valRicNuovoCell = 0;
        var totaleRicNuovo = 0;
        for (let index = 1; index < this.numRighePreesCostruzioni; index++) {
            if (document.getElementById(`rigaPreesistenzaCostruzione-T${numPart}-R-${index}`)) {
                if (CustomJS.isNullEmpty(document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${index}`).ej2_instances[0].value))
                    document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${index}`).ej2_instances[0].value = 0
                valRicNuovoCell = parseFloat(document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${index}`).ej2_instances[0].value);
                totaleRicNuovo += valRicNuovoCell;
            }
        }
        var valoreAltroACorpo = 0;
        var totaleAltroACorpo = 0;
        for (let index = 1; index < this.numRighePreesDescrizioni; index++) {
            if (document.getElementById(`rigaPreesDescrizione-T${numPart}-R-${index}`)) {
                valoreAltroACorpo = CustomJS.isNullEmpty(document.getElementById(`valoreAltroACorpoResult_T${numPart}_R_${index}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreAltroACorpoResult_T${numPart}_R_${index}`).ej2_instances[0].value;
                totaleAltroACorpo += valoreAltroACorpo;
            }
        }

        var risultato = totaleRicNuovo + totaleAltroACorpo;
        document.getElementById(`valoreTotEsistNuovoResult_T${numPart}`).ej2_instances[0].value = risultato;

        //DEGRADO STATO USO
        var risultato = 0;
        var percentuale = 0;
        var degrado = document.getElementById(`valoreDegradoInput_T${numPart}`).ej2_instances[0].value;
        degrado = parseFloat(CustomJS.isNullEmpty(degrado) ? '0' : degrado);
        var convertita = document.getElementById(`valoreTotEsistNuovoResult_T${numPart}`).ej2_instances[0].value;
        percentuale = (convertita / 100) * degrado;
        risultato = convertita - percentuale;
        document.getElementById(`valoreTotEsistStatoUsoInput_T${numPart}`).ej2_instances[0].value = risultato

        //SCOPERTURA 
        var percNote = '';
        var risultato = 0;
        var valoreDerogato = document.getElementById(`valoreDerogatoResult_T${numPart}`).ej2_instances[0].value;
        var valoreANuovo = document.getElementById(`valoreTotEsistNuovoResult_T${numPart}`).ej2_instances[0].value;
        var valoreInUso = document.getElementById(`valoreTotEsistStatoUsoInput_T${numPart}`).ej2_instances[0].value;
        if (valoreDerogato >= valoreANuovo) {
            document.getElementById(`valoreScoperturaResult_T${numPart}`).ej2_instances[0].value = 0
            document.getElementById(`valoreScoperturaStatoUso_T${numPart}`).ej2_instances[0].value = 0
            document.getElementById(`messaggioNote-T${numPart}`).ej2_instances[0].value = "La somma assicurata è risultata sufficiente per il valore a nuovo";
            document.getElementById(`scoperturaTipo-T${numPart}`).innerText = "Scopertura";
            document.getElementById(`insuffAssicurativaPerc_T${numPart}`).ej2_instances[0].value = '0';
        }
        else if (valoreDerogato < valoreANuovo && valoreDerogato > valoreInUso) {
            risultato = (1 - (valoreDerogato - valoreInUso) / (valoreANuovo - valoreInUso)) * 100;
            document.getElementById(`valoreScoperturaResult_T${numPart}`).ej2_instances[0].value = risultato
            document.getElementById(`valoreScoperturaStatoUso_T${numPart}`).ej2_instances[0].value = 0
            percNote = (1 - (valoreDerogato / valoreANuovo)) * 100;
            document.getElementById(`insuffAssicurativaPerc_T${numPart}`).ej2_instances[0].value = parseFloat(percNote.toFixed(2))
            document.getElementById(`messaggioNote-T${numPart}`).ej2_instances[0].value = `La somma assicurata (comprensiva di deroga) non è risultata sufficiente per il ${percNote.toFixed(1)}%; L'eventuale supplemento di indennizzo viene ridotto del ${risultato.toFixed(1)}%;`;
            document.getElementById(`scoperturaTipo-T${numPart}`).innerText = "Scopertura su S.I.";
        }
        else if (valoreDerogato < valoreInUso) {
            risultato = (1 - (valoreDerogato / valoreInUso)) * 100;
            document.getElementById(`valoreScoperturaResult_T${numPart}`).ej2_instances[0].value = 0;
            document.getElementById(`valoreScoperturaStatoUso_T${numPart}`).ej2_instances[0].value = risultato
            percNote = (1 - (valoreDerogato / valoreANuovo)) * 100;
            document.getElementById(`insuffAssicurativaPerc_T${numPart}`).ej2_instances[0].value = parseFloat(percNote.toFixed(2))
            document.getElementById(`messaggioNote-T${numPart}`).ej2_instances[0].value = `La somma assicurata (comprensiva di deroga) non è risultata sufficiente per il ${risultato.toFixed(2)}% rispetto al valore allo stato d'uso e del ${percNote.toFixed(2)}% rispetto al valore a nuovo;`;
            document.getElementById(`scoperturaTipo-T${numPart}`).innerText = "Scopertura su V.s.u";
        }

        //AGGIORNAMENTO SU STIME
        //todo da rivedere l' aggiornamento dopo regola prop sulle stime
        var indici = []
        for (let index = 1; index < this.numStime; index++) {
            if (document.getElementById(`tabellaStima-D${index}`)) {
                if (document.getElementById(`nomePartita${numPart}`).ej2_instances[0].value === document.getElementById(`partitaFromSin-D${index}`).ej2_instances[0].value) {
                    if (document.getElementById(`formaGaranzia${numPart}`).ej2_instances[0].value === '2') {
                        indici.push(index)
                    }
                }
            }
        }
        setTimeout(() => {
            for (let index = 0; index < indici.length; index++) {
                if (!CustomJS.isNullEmpty(document.getElementById(`determinazioneDanno_D${indici[index]}`))) {
                    if (document.getElementById(`determinazioneDanno_D${indici[index]}`).ej2_instances[0].value === '001')
                        this.calcolaStimaValoreANuovo(indici[index], false)
                    else if (document.getElementById(`determinazioneDanno_D${indici[index]}`).ej2_instances[0].value === '003')
                        this.calcolaStimaStatoUso(indici[index], false)
                }
            }
        }, 250)
    }

    //----------------------------PREESISTENZA CALCOLI FINE----------------------------

    //-------------------------STIME CALCOLI INIZIO------------------------


    cambiaLimiteSinistro(numDann) {
        this.strutturaStatiStimeBase.forEach(stima => {
            if (document.getElementById(`tabellaStima-D${stima.d}`)) {
                if (stima.d !== numDann) {
                    if (document.getElementById(`garanziaFromSin-D${numDann}`).ej2_instances[0].value === document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value) {
                        document.getElementById(`limiteSinistro_D${stima.d}`).ej2_instances[0].value = document.getElementById(`limiteSinistro_D${numDann}`).ej2_instances[0].value;
                    }
                }
            }
        })
    }


    //STIMA VALORE A NUOVO INIZIO---------------------------------
    aggiornaStimaValoreANuovo(numDanneggiato) {
        //Calcolo prima tutte le righe singole e poi i parametri finali
        let tempoCalcolaRighe = 50;
        this.strutturaStatiStimePrezziari.forEach(prezz => {
            if (document.getElementById(`tabPrezziario-D${numDanneggiato}-R-${prezz.r}`)) {
                tempoCalcolaRighe += 50;
                this.calcolaRigaStimaValoreANuovo(numDanneggiato, prezz.r, true);
            }
        })
        //dopo aver aggiornato ogni riga aggiorno la stima completa
        setTimeout(() => {
            this.calcolaStimaValoreANuovo(numDanneggiato, false)
        }, tempoCalcolaRighe);
    }


    calcolaRigaStimaValoreANuovo(numDann, numR, fromAggiorna, args) {
        //controllo se la quantià è vuota o minore uguale a 0 metto minimo 1
        if (CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${numDann}_R${numR}`).ej2_instances[0].value) ||
            document.getElementById(`quantitaInput_D${numDann}_R${numR}`).ej2_instances[0].value <= 0) {
            document.getElementById(`quantitaInput_D${numDann}_R${numR}`).ej2_instances[0].value = 1;
        }
        let quantita = document.getElementById(`quantitaInput_D${numDann}_R${numR}`).ej2_instances[0].value;
        let valUnitario = document.getElementById(`valUnitarioInput_D${numDann}_R${numR}`).ej2_instances[0].value;
        let totale = parseFloat((quantita * valUnitario).toFixed(2));
        document.getElementById(`totaleResult_D${numDann}_R${numR}`).ej2_instances[0].value = totale
        if (fromAggiorna !== true) // se non proviene dal tasto aggiorna per ogni riga cui valore viene cambiato aggiorna tutta la stima
            this.calcolaStimaValoreANuovo(numDann, false);
    }

    calcolaStimaValoreANuovo(numDanneggiato, fromLimite, args) {
        if (fromLimite === true) {
            this.cambiaLimiteSinistro(numDanneggiato);
        }
        //DANNO COMPLESSIVO ACCERTATO
        var valTot = 0;
        for (let index = 1; index < this.numTabPrezziari; index++) {
            if (document.getElementById(`tabPrezziario-D${numDanneggiato}-R-${index}`)) {
                if (CustomJS.isNullEmpty(document.getElementById(`totaleResult_D${numDanneggiato}_R${index}`)))
                    continue;
                else {
                    if (CustomJS.isNullEmpty(document.getElementById(`totaleResult_D${numDanneggiato}_R${index}`).ej2_instances[0].value)) {
                        document.getElementById(`totaleResult_D${numDanneggiato}_R${index}`).ej2_instances[0].value = 0
                    }
                    valTot += document.getElementById(`totaleResult_D${numDanneggiato}_R${index}`).ej2_instances[0].value;
                }
            }
        }
        valTot = valTot < 0 ? 0 : valTot;
        document.getElementById(`dannoComplessAccert_D${numDanneggiato}`).ej2_instances[0].value = valTot

        //DOPO REGOLA PROPORZIONALE
        for (let index = 1; index < this.numPreesistenze; index++) {
            if (document.getElementById(`tabellaCompletaPreesistenza-T${index}`)) {
                if (document.getElementById(`partitaFromSin-D${numDanneggiato}`).ej2_instances[0].value === document.getElementById(`nomePartita${index}`).ej2_instances[0].value) {
                    if (document.getElementById(`formaGaranzia${index}`).ej2_instances[0].value === '2') {
                        var totEsisNuovo = document.getElementById(`valoreTotEsistNuovoResult_T${index}`).ej2_instances[0].value;
                        var valoreDerogato = document.getElementById(`valoreDerogatoResult_T${index}`).ej2_instances[0].value;
                        if (totEsisNuovo > valoreDerogato) {
                            document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}`).ej2_instances[0].value = valTot * (valoreDerogato / totEsisNuovo)
                        }
                        else {
                            if (document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}`))
                                document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}`).ej2_instances[0].value = valTot
                        }
                    }
                    else if (document.getElementById(`formaGaranzia${index}`).ej2_instances[0].value === '1') {
                        document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}`).ej2_instances[0].value = document.getElementById(`dannoComplessAccert_D${numDanneggiato}`).ej2_instances[0].value;
                    }
                    //CALCOLO FRANCHIGIA O SCOPERTO
                    //la franchigia/scoperto condivisa/o chiama la >>>> limiteCondivisoStessaGaranzia 
                    //che chiama la >>>> calcolaStimaValoreANuovoParteFinale per ogni stima in un ciclo for

                    if (document.getElementById(`isFranchigiaOrScoperto-D${numDanneggiato}`).ej2_instances[0].value === 'franchigia') {
                        this.nuovaCalcolaFranchigeCondivise(numDanneggiato);
                    }
                    else {
                        this.nuovoScopertoCondiviso(numDanneggiato);
                    }
                    //all' interno di franchigia o scoperto condivisa/o c'è la gestione del limite condiviso o meno e 
                    //la parte finale del calcolo della stima con le funzioni
                }
            }
        }
    }
    calcolaStimaValoreANuovoParteFinale(numDanneggiato, limite) {
        //LIMITE 
        let dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${numDanneggiato}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${numDanneggiato}`).ej2_instances[0].value
        dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp

        var franchigiaScoperto = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${numDanneggiato}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${numDanneggiato}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${numDanneggiato}`).ej2_instances[0].value;
        franchigiaScoperto = CustomJS.isNullEmpty(franchigiaScoperto) ? 0 : franchigiaScoperto;

        var dannoNetto = parseFloat((dopoRegolaProp - franchigiaScoperto).toFixed(2));

        if (dannoNetto > limite) {
            dannoNetto = limite;
            document.getElementById(`ceLimiteIndennizzoONo-D${numDanneggiato}`).style.removeProperty('display');
            document.getElementById(`limiteIndennizzo-D${numDanneggiato}`).ej2_instances[0].value = limite;
            document.getElementById(`coinvoltoLimIndannoNetto_D${numDanneggiato}`).innerHTML = 'Danno netto (coinvolto il limite indennizzo) :';
        }
        else {
            document.getElementById(`limiteIndennizzo-D${numDanneggiato}`).ej2_instances[0].value = 0;
            document.getElementById(`ceLimiteIndennizzoONo-D${numDanneggiato}`).style.display = 'none';
            document.getElementById(`coinvoltoLimIndannoNetto_D${numDanneggiato}`).innerHTML = 'Danno netto';
        }
        dannoNetto = dannoNetto < 0 ? 0 : dannoNetto;
        document.getElementById(`dannoNetto_D${numDanneggiato}`).ej2_instances[0].value = dannoNetto;

    }

    //STIMA VALORE A NUOVO FINE-----------------------------------

    //STIMA STATO USO INIZIO---------------------------------
    aggiornaStimaStatoUso(numDann) {
        //Calcolo prima tutte le righe singole e poi i parametri finali
        let tempoCalcolaRighe = 75;
        this.strutturaStatiStimePrezziari.forEach(prezz => {
            if (document.getElementById(`tabPrezziario-D${numDann}-R-${prezz.r}`)) {
                tempoCalcolaRighe += 50;
                this.calcolaRigaStimaStatoUso(numDann, prezz.r, true);
            }
        })
        //dopo aver aggiornato ogni riga aggiorno la stima completa
        setTimeout(() => {
            this.calcolaStimaStatoUso(numDann, false)
        }, tempoCalcolaRighe);

    }


    calcolaRigaStimaStatoUso(dann, riga, fromAggiorna, args) {
        if (CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${dann}_R${riga}`).ej2_instances[0].value) ||
            document.getElementById(`quantitaInput_D${dann}_R${riga}`).ej2_instances[0].value <= 0) {
            document.getElementById(`quantitaInput_D${dann}_R${riga}`).ej2_instances[0].value = 1;
        }
        //GESTIONE RIGA PREZZIARIO : (Degrado/Suppl.Indennizzo + Importo Degrado Supp.Indennizzo + Valore del Bene)
        var quantita = document.getElementById(`quantitaInput_D${dann}_R${riga}`).ej2_instances[0].value;
        var valUnitario = CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${dann}_R${riga}`).ej2_instances[0].value) ? 0 : document.getElementById(`valUnitarioInput_D${dann}_R${riga}`).ej2_instances[0].value;
        var valNuovo = parseFloat((valUnitario * quantita).toFixed(2));
        document.getElementById(`valANuovoInput_D${dann}_R${riga}`).ej2_instances[0].value = valNuovo
        var percentuale = CustomJS.isNullEmpty(document.getElementById(`percDegrSupInd_D${dann}_R${riga}`).ej2_instances[0].value) ? 0 : document.getElementById(`percDegrSupInd_D${dann}_R${riga}`).ej2_instances[0].value;
        var importo = parseFloat(((valNuovo / 100) * percentuale).toFixed(2));
        if (percentuale === 0) {
            document.getElementById(`importoDegrSupInd_D${dann}_R${riga}`).ej2_instances[0].value = 0
            document.getElementById(`ripristinato-D${dann}-R${riga}`).innerText = 'Ripristinato'
        }
        else {
            document.getElementById(`importoDegrSupInd_D${dann}_R${riga}`).ej2_instances[0].value = importo
            document.getElementById(`ripristinato-D${dann}-R${riga}`).innerText = ''
        }
        var valoreBene = parseFloat((valNuovo - importo).toFixed(2));
        document.getElementById(`valoreBeneResult_D${dann}_R${riga}`).ej2_instances[0].value = valoreBene;

        if (fromAggiorna !== true) { //se non arriva dal tasto aggiorna
            this.calcolaStimaStatoUso(dann, false)
        }
    }

    calcolaStimaStatoUso(dann, fromLimite) {
        if (fromLimite === true) {
            this.cambiaLimiteSinistro(dann);
        }
        //GESTIONE RIGA DANNO COMPLESSIVO ACCERTATO (Valore a nuovo  + Degrado/Supp. Indennità + Valore in Uso)
        var dannComplAccValANuovo = 0;
        var dannComplAccImporto = 0;
        var dannComplAccValBene = 0;
        for (let index = 1; index < this.numTabPrezziari; index++) {
            if (document.getElementById(`tabPrezziario-D${dann}-R-${index}`)) {
                var valNuovoMom = CustomJS.isNullEmpty(document.getElementById(`valANuovoInput_D${dann}_R${index}`).ej2_instances[0].value) ? 0 : document.getElementById(`valANuovoInput_D${dann}_R${index}`).ej2_instances[0].value;
                dannComplAccValANuovo += valNuovoMom;
                var importoMom = CustomJS.isNullEmpty(document.getElementById(`importoDegrSupInd_D${dann}_R${index}`).ej2_instances[0].value) ? 0 : document.getElementById(`importoDegrSupInd_D${dann}_R${index}`).ej2_instances[0].value;
                dannComplAccImporto += importoMom;
                var valoreBeneMom = CustomJS.isNullEmpty(document.getElementById(`valoreBeneResult_D${dann}_R${index}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreBeneResult_D${dann}_R${index}`).ej2_instances[0].value;
                dannComplAccValBene += valoreBeneMom;
            }
        }
        dannComplAccValANuovo = dannComplAccValANuovo < 0 ? 0 : dannComplAccValANuovo;
        dannComplAccImporto = dannComplAccImporto < 0 ? 0 : dannComplAccImporto;
        dannComplAccValBene = dannComplAccValBene < 0 ? 0 : dannComplAccValBene;
        document.getElementById(`valoreANuovoDannComplessAcc_D${dann}`).ej2_instances[0].value = dannComplAccValANuovo
        document.getElementById(`degradoPercDannComplessAcc_D${dann}`).ej2_instances[0].value = dannComplAccImporto
        document.getElementById(`valoreBeneDannComplessAcc_D${dann}`).ej2_instances[0].value = dannComplAccValBene

        //GESTIONE RIGA DOPO REGOLA PROPORZIONALE (Valore a nuovo  + Degrado/Supp. Indennità + Valore in Uso)
        for (let index = 1; index < this.numPreesistenze; index++) {
            if (document.getElementById(`nomePartita${index}`)) {
                if (document.getElementById(`nomePartita${index}`).ej2_instances[0].value === document.getElementById(`partitaFromSin-D${dann}`).ej2_instances[0].value) {
                    if (document.getElementById(`formaGaranzia${index}`).ej2_instances[0].value === '2') {
                        var scoperturaTipo = document.getElementById(`scoperturaTipo-T${index}`).innerText;
                        if (scoperturaTipo === 'Scopertura') {
                            document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}`).ej2_instances[0].value = document.getElementById(`valoreANuovoDannComplessAcc_D${dann}`).ej2_instances[0].value;
                            document.getElementById(`degrado25PercDopoRegolaProp_D${dann}`).ej2_instances[0].value = document.getElementById(`degradoPercDannComplessAcc_D${dann}`).ej2_instances[0].value;
                            document.getElementById(`dopoRegolaProporzionale_D${dann}`).ej2_instances[0].value = document.getElementById(`valoreBeneDannComplessAcc_D${dann}`).ej2_instances[0].value;
                        }
                        else if (scoperturaTipo === 'Scopertura su S.I.') {
                            document.getElementById(`dopoRegolaProporzionale_D${dann}`).ej2_instances[0].value = document.getElementById(`valoreBeneDannComplessAcc_D${dann}`).ej2_instances[0].value

                            let dannoComplAccDegrado = document.getElementById(`degradoPercDannComplessAcc_D${dann}`).ej2_instances[0].value
                            let scopertura = document.getElementById(`valoreScoperturaResult_T${index}`).ej2_instances[0].value

                            let dopoRegolaPropDegrado = dannoComplAccDegrado - (dannoComplAccDegrado * scopertura / 100);
                            document.getElementById(`degrado25PercDopoRegolaProp_D${dann}`).ej2_instances[0].value = parseFloat(dopoRegolaPropDegrado.toFixed(2))

                            let dopoRegolaPropUso = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${dann}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${dann}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${dann}`).ej2_instances[0].value
                            dopoRegolaPropUso = CustomJS.isNullEmpty(dopoRegolaPropUso) ? 0 : dopoRegolaPropUso

                            let doporegolaPropNuovo = dopoRegolaPropUso + dopoRegolaPropDegrado;
                            document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}`).ej2_instances[0].value = parseFloat(doporegolaPropNuovo.toFixed(2))
                        }
                        else if (scoperturaTipo === 'Scopertura su V.s.u') {
                            //degrado dopo rela prop sempre 0
                            document.getElementById(`degrado25PercDopoRegolaProp_D${dann}`).ej2_instances[0].value = 0;
                            //dopo regola prop a nuovo
                            let insuffAss = document.getElementById(`insuffAssicurativaPerc_T${index}`).ej2_instances[0].value
                            let dannComplAccNuovo = document.getElementById(`valoreANuovoDannComplessAcc_D${dann}`).ej2_instances[0].value
                            let scoperturaSINuovo = (dannComplAccNuovo / 100) * insuffAss; //scopertura da sottrarre
                            let dopoRegolaPropNuovo = dannComplAccNuovo - scoperturaSINuovo;
                            document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}`).ej2_instances[0].value = parseFloat(dopoRegolaPropNuovo.toFixed(2));
                            //stesso risultato anche su dopo regola prop a nuovo
                            document.getElementById(`dopoRegolaProporzionale_D${dann}`).ej2_instances[0].value = parseFloat(dopoRegolaPropNuovo.toFixed(2));
                        }
                    }
                    else if (document.getElementById(`formaGaranzia${index}`).ej2_instances[0].value === '1') {
                        document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}`).ej2_instances[0].value = document.getElementById(`valoreANuovoDannComplessAcc_D${dann}`).ej2_instances[0].value
                        document.getElementById(`degrado25PercDopoRegolaProp_D${dann}`).ej2_instances[0].value = document.getElementById(`degradoPercDannComplessAcc_D${dann}`).ej2_instances[0].value
                        document.getElementById(`dopoRegolaProporzionale_D${dann}`).ej2_instances[0].value = document.getElementById(`valoreBeneDannComplessAcc_D${dann}`).ej2_instances[0].value
                    }
                }
            }
        }
        //AGGIORNO FRANCHIGIA O SCOPERTO
        //la franchigia/scoperto condivisa/o chiama la >>>> limiteCondivisoStessaGaranzia
        // che chiama la >>>> calcolaStimaStatoUsoParteFinale  per ogni stima in un ciclo for
        if (document.getElementById(`isFranchigiaOrScoperto-D${dann}`).ej2_instances[0].value === 'franchigia')
            this.nuovaCalcolaFranchigeCondivise();
        else
            this.nuovoScopertoCondiviso();

        setTimeout(() => {
            this.trasportaDatiRiepilogo();
            return;
        }, 500)
    }

    calcolaStimaStatoUsoParteFinale(dann, limite) {
        //GESTIONE RIGA DANNO NETTO (Valore a nuovo  + Degrado/Supp. Indennità + Valore in Uso)
        let valoreInUsoDRP = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${dann}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${dann}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${dann}`).ej2_instances[0].value
        valoreInUsoDRP = CustomJS.isNullEmpty(valoreInUsoDRP) ? 0 : valoreInUsoDRP

        var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${dann}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${dann}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${dann}`).ej2_instances[0].value;
        franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
        franchigia = parseFloat(franchigia);

        document.getElementById(`franchigiaValoreANuovo_D${dann}`).ej2_instances[0].value = franchigia;


        var valoreInUsoDN = valoreInUsoDRP - franchigia;
        valoreInUsoDN = valoreInUsoDN < 0 ? 0 : valoreInUsoDN;
        document.getElementById(`valoreStatoUsodannoNetto_D${dann}`).ej2_instances[0].value = valoreInUsoDN;


        let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${dann}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${dann}`).ej2_instances[0].value
        valoreANuovoDRP = CustomJS.isNullEmpty(valoreANuovoDRP) ? 0 : valoreANuovoDRP

        var valoreANuovoDN = valoreANuovoDRP - franchigia;
        valoreANuovoDN = valoreANuovoDN < 0 ? 0 : valoreANuovoDN;
        document.getElementById(`valoreANuovodannoNetto_D${dann}`).ej2_instances[0].value = valoreANuovoDN

        var degradoDN = valoreANuovoDN - valoreInUsoDN;
        degradoDN = degradoDN < 0 ? 0 : degradoDN
        document.getElementById(`degrado25PercdannoNetto_D${dann}`).ej2_instances[0].value = degradoDN;


        if (document.getElementById(`valoreStatoUsodannoNetto_D${dann}`).ej2_instances[0].value === 0 && document.getElementById(`valoreANuovodannoNetto_D${dann}`).ej2_instances[0].value === 0)
            document.getElementById(`degrado25PercdannoNetto_D${dann}`).ej2_instances[0].value = 0

        //GESTIONE LIMITE SULLA RIGA DANNO NETTO
        var valoreInUsoDN = document.getElementById(`valoreStatoUsodannoNetto_D${dann}`).ej2_instances[0].value;

        if (valoreInUsoDN > limite) {
            document.getElementById(`ceLimiteIndennizzoONo-D${dann}`).style.removeProperty('display');
            document.getElementById(`limiteIndennizzo-D${dann}`).ej2_instances[0].value = limite;
            document.getElementById(`valoreStatoUsodannoNetto_D${dann}`).ej2_instances[0].value = limite;
            document.getElementById(`valoreANuovodannoNetto_D${dann}`).ej2_instances[0].value = limite;
            document.getElementById(`degrado25PercdannoNetto_D${dann}`).ej2_instances[0].value = 0;
        }
        else {
            document.getElementById(`limiteIndennizzo-D${dann}`).ej2_instances[0].value = 0;
            document.getElementById(`ceLimiteIndennizzoONo-D${dann}`).style.display = 'none'
        }
    }

    limiteCondivisoStessaGaranzia() {
        var arrayStime = []
        this.strutturaStatiStimeBase.forEach(stima => {
            var garanzia = document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value;
            var idStima = document.getElementById(`idStima-D${stima.d}`).ej2_instances[0].value;
            var limite = document.getElementById(`limiteSinistro_D${stima.d}`).ej2_instances[0].value;
            if (CustomJS.isNullEmpty(limite)) {
                limite = 0;
                document.getElementById(`limiteSinistro_D${stima.d}`).ej2_instances[0].value = 0;
            }
            var dopoRegolaProp = 0;
            if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === "001") {
                dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value
                dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
            }
            else if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === "003") {
                dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value
                dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
            }

            arrayStime.push({ garanzia: garanzia, idStima: idStima, limite: limite, dopoRegolaProp: dopoRegolaProp, numStima: stima.d })
        })
        var gruppiConStessaGaranzia = _.groupBy(arrayStime, 'garanzia');

        var chiaviGruppiGaranzia = Object.keys(gruppiConStessaGaranzia);
        for (let gruppoGaranzia of chiaviGruppiGaranzia) {
            var gruppiConStessoLimite = _.groupBy(gruppiConStessaGaranzia[gruppoGaranzia], 'limite');
            var chiaviGruppiLimite = Object.keys(gruppiConStessoLimite);
            for (let keyGruppoLimite of chiaviGruppiLimite) {
                var sommaDopoRegoleProp = 0;
                for (let stima of gruppiConStessoLimite[keyGruppoLimite]) {
                    sommaDopoRegoleProp += stima.dopoRegolaProp;
                }
                sommaDopoRegoleProp = sommaDopoRegoleProp === NaN ? 0 : sommaDopoRegoleProp;
                sommaDopoRegoleProp = sommaDopoRegoleProp < 0 ? 0 : sommaDopoRegoleProp;
                for (let stima of gruppiConStessoLimite[keyGruppoLimite]) {
                    var limiteCondiviso = parseFloat(((stima.limite / sommaDopoRegoleProp) * stima.dopoRegolaProp).toFixed(2));
                    limiteCondiviso = limiteCondiviso === NaN ? 0 : limiteCondiviso;
                    limiteCondiviso = CustomJS.isNullEmpty(limiteCondiviso) ? 0 : limiteCondiviso;
                    limiteCondiviso = limiteCondiviso < 0 ? 0 : limiteCondiviso;

                    if (document.getElementById(`determinazioneDanno_D${stima.numStima}`).ej2_instances[0].value === "001") {
                        this.calcolaStimaValoreANuovoParteFinale(stima.numStima, limiteCondiviso)
                    }
                    else if (document.getElementById(`determinazioneDanno_D${stima.numStima}`).ej2_instances[0].value === "003") {
                        this.calcolaStimaStatoUsoParteFinale(stima.numStima, limiteCondiviso)
                    }
                }
            }
        }

        setTimeout(() => {
            this.trasportaDatiRiepilogo();
        }, 250 * this.strutturaStatiStimeBase.length)
    }

    //STIMA STATO USO FINE---------------------------------

    //SCOPERTO E FRANCHIGIA INZIZIO------------

    nuovaCalcolaFranchigeCondivise() {
        var gruppi = Object.keys(this.franchigeCondivise); // restituisce un array con i nomi delle proprietà
        for (let gruppo of gruppi) { // itera sui nomi delle proprietà
            var franchigiaEuroCondivisa = 0;
            var sommaDopoRegoleProp = 0; // somma delle dopo regole proporzionali per ogni gruppo di franchige condivise 
            this.strutturaStatiStimeBase.forEach(stima => {
                for (let franch of this.franchigeCondivise[gruppo]) { // itera sull'array contenuto nella proprietà
                    if (franch.idStimaCondivisa === document.getElementById(`idStima-D${stima.d}`).ej2_instances[0].value) {

                        var tipologia = franch.tipo.charAt(0).toUpperCase() + franch.tipo.slice(1) + " :";
                        if (this.franchigeCondivise[gruppo].length > 1) {
                            tipologia = tipologia + "\n" + "Valore Iniziale : " + franch.franchigia + " €";
                            tipologia = tipologia + " " + "(Condivisa)";
                        }
                        document.getElementById(`franchigiaOscopertura-D${stima.d}`).innerText = tipologia;

                        franchigiaEuroCondivisa = franch.franchigia;
                        var dopoRegolaProp = 0;
                        if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '001') {
                            dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value
                            dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
                        }
                        else if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {
                            let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value
                            drp = CustomJS.isNullEmpty(drp) ? 0 : drp

                            let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value

                            dopoRegolaProp = CustomJS.isNullEmpty(valoreANuovoDRP) ? 0 : drp
                        }
                        sommaDopoRegoleProp += dopoRegolaProp;
                    }
                }
            })
            //formula : la franchigia diviso la somma delle dopo regole prop per la singola dopo regola proporzionale
            this.strutturaStatiStimeBase.forEach(stima => {
                for (let franch of this.franchigeCondivise[gruppo]) { // itera sull'array contenuto nella proprietà
                    if (franch.idStimaCondivisa === document.getElementById(`idStima-D${stima.d}`).ej2_instances[0].value) {
                        var dopoRegolaPropStima = 0;
                        if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '001') {
                            dopoRegolaPropStima = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value
                            dopoRegolaPropStima = CustomJS.isNullEmpty(dopoRegolaPropStima) ? 0 : dopoRegolaPropStima
                        }
                        else if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {
                            let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value
                            drp = CustomJS.isNullEmpty(drp) ? 0 : drp

                            let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value

                            dopoRegolaPropStima = CustomJS.isNullEmpty(valoreANuovoDRP) ? 0 : drp;
                        }
                        var calcoloFinale = 0
                        if (sommaDopoRegoleProp > 0) //controllo perchè è impossibile dividere per 0
                            calcoloFinale = parseFloat(((franchigiaEuroCondivisa / sommaDopoRegoleProp) * dopoRegolaPropStima).toFixed(2));
                        else {
                            //altrimenti rimetto il valore di franchigia originale
                            calcoloFinale = franchigiaEuroCondivisa
                        }
                        //controlli finali
                        calcoloFinale = calcoloFinale < 0 ? 0 : calcoloFinale;
                        document.getElementById(`franchigia_D${stima.d}`).ej2_instances[0].value = calcoloFinale;
                        if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {

                            var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${stima.d}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${stima.d}`).ej2_instances[0].value;
                            franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                            document.getElementById(`franchigiaValoreANuovo_D${stima.d}`).ej2_instances[0].value = franchigia;
                        }
                    }
                }
            })
        }
        //SULLA STIMA PRESENTE BISOGNA AGGIORNARE ANCHE I VALORI DEI LIMITI E DEI TOTALI RIAGGIORNANDO LA STIMA
        this.limiteCondivisoStessaGaranzia();
    }

    nuovoScopertoCondiviso() {
        var gruppi = Object.keys(this.scopertiCondivisi);
        for (let gruppo of gruppi) {
            var sommaScoperture = 0; // somma degli scoperti calcolati dalle percentuali per ogni gruppo 
            var sommaDopoRegoleProp = 0; // somma delle dopo regole proporzionali per ogni gruppo di scoperti condivisi 
            var minimo = 0;
            var massimo = 0;
            this.strutturaStatiStimeBase.forEach(stima => {
                for (let franch of this.scopertiCondivisi[gruppo]) {
                    if (franch.idStimaCondivisa === document.getElementById(`idStima-D${stima.d}`).ej2_instances[0].value) {
                        var tipologia = franch.tipo.charAt(0).toUpperCase() + franch.tipo.slice(1);
                        tipologia = tipologia + "\n" + "%" + franch.scopertoPerc + ", ";
                        tipologia = tipologia + " " + "Min " + franch.scopertoMin + " €, ";
                        tipologia = tipologia + " " + "Max " + franch.scopertoMax + " €";
                        if (this.scopertiCondivisi[gruppo].length > 1)
                            tipologia = tipologia;
                        document.getElementById(`franchigiaOscopertura-D${stima.d}`).innerText = tipologia;

                        if (franch.scopertoPerc > 0) {
                            minimo = franch.scopertoMin;
                            massimo = franch.scopertoMax;
                            var dopoRegolaProp = 0;
                            //se c'è la percentuale dello scoperto anche se c'è la condivisione calcolo solo la percentuale sulla dopo regola prop della singola stima
                            //formula scopero percentuale : dopo regola prop diviso 100 per la percentuale dello scoperto
                            if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '001') {
                                dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value
                                dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
                            }
                            else if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {
                                let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value
                                drp = CustomJS.isNullEmpty(drp) ? 0 : drp

                                let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value

                                dopoRegolaProp = CustomJS.isNullEmpty(valoreANuovoDRP) ? 0 : drp;
                            }
                            var scopertoPercRisultato = parseFloat(((dopoRegolaProp / 100) * franch.scopertoPerc).toFixed(2));
                            document.getElementById(`franchigia_D${stima.d}`).ej2_instances[0].value = scopertoPercRisultato;

                            sommaScoperture += scopertoPercRisultato;
                            sommaDopoRegoleProp += dopoRegolaProp;
                        }
                    }
                }
            })
            if (sommaScoperture > massimo) {
                if (massimo !== 0)
                    sommaScoperture = massimo;
            }
            if (sommaScoperture < minimo) {
                sommaScoperture = minimo;
            }
            var risultatoScoperto = 0;
            this.strutturaStatiStimeBase.forEach(stima => {
                for (let franch of this.scopertiCondivisi[gruppo]) {
                    if (franch.idStimaCondivisa === document.getElementById(`idStima-D${stima.d}`).ej2_instances[0].value) {
                        var dopoRegolaProp = 0;
                        if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '001') {
                            dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value
                            dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
                        }
                        else if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {
                            let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value
                            drp = CustomJS.isNullEmpty(drp) ? 0 : drp
                            dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}`).ej2_instances[0].value) ? 0 : drp;
                        }
                        dopoRegolaProp = dopoRegolaProp < 0 ? 0 : dopoRegolaProp;

                        risultatoScoperto = (dopoRegolaProp / sommaDopoRegoleProp) * sommaScoperture;

                        document.getElementById(`franchigia_D${stima.d}`).ej2_instances[0].value = risultatoScoperto
                        if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {
                            var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${stima.d}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${stima.d}`).ej2_instances[0].value;
                            franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                            document.getElementById(`franchigiaValoreANuovo_D${stima.d}`).ej2_instances[0].value = franchigia;

                        }
                    }
                }
            })
        }
        //SULLA STIMA PRESENTE BISOGNA AGGIORNARE ANCHE I VALORI DEI LIMITI E DEI TOTALI RIAGGIORNANDO LA STIMA
        this.limiteCondivisoStessaGaranzia();
    }
    //SCOPERTO  E FRANCHIGIA FINE--------------

    //PREZZIARIO INIZIO-----------------------

    trasportaDatiPrezziarioAggiuntivi(numDann, numRiga, args) {
        var codEle = document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value;
        setTimeout(() => {
            document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value = codEle;
        }, 100)
        var unitaMisura = '';
        var prezzo = 0;
        var descrLunga = ''
        var codEleCategoria = '';

        let obj = _.find(this.dsPrezziarioCompleto, elm => {
            return elm.COD_ELE === codEle;
        });

        if (!CustomJS.isNullEmpty(obj)) {
            unitaMisura = obj.MISURA;
            prezzo = obj.PREZZO;
            descrLunga = obj.DESCR_LUNGA;
            codEleCategoria = obj.COD_CATEGORIA;
        }

        if (this.isCompagniaGenerali())
            document.getElementById(`categoriaSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value = codEleCategoria;
        document.getElementById(`UMInput_D${numDann}_R${numRiga}`).ej2_instances[0].value = unitaMisura;
        document.getElementById(`valUnitarioInput_D${numDann}_R${numRiga}`).ej2_instances[0].value = prezzo;
        document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value = descrLunga;
    }

    cambiaDescrizione(numDann, numRiga) {
        var idFittizio = 'new' + numDann + numRiga + this.nuovoNumIdPrezziario;
        this.nuovoNumIdPrezziario++;
        var testo = '';
        var id = '';
        id = document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value;
        if (document.getElementById(`btnCambiaDescriz-D${numDann}-R${numRiga}`).innerHTML === `<i class="fas fa-marker"></i>`) {
            //cambio icona
            document.getElementById(`btnCambiaDescriz-D${numDann}-R${numRiga}`).innerHTML = `<i class="fas fa-share"></i>`;

            testo = document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].text;
            document.getElementById(`contentNuovaDescrizione-D${numDann}-R${numRiga}`).style.removeProperty('display');
            document.getElementById(`contentDescrizioneBase-D${numDann}-R${numRiga}`).style.display = 'none';
            document.getElementById(`nuovaDescrizioneLibera-D${numDann}-R${numRiga}`).ej2_instances[0].value = testo;

        }
        else if (document.getElementById(`btnCambiaDescriz-D${numDann}-R${numRiga}`).innerHTML === `<i class="fas fa-share"></i>`) {
            //cambio icona
            document.getElementById(`btnCambiaDescriz-D${numDann}-R${numRiga}`).innerHTML = `<i class="fas fa-marker"></i>`;

            testo = document.getElementById(`nuovaDescrizioneLibera-D${numDann}-R${numRiga}`).ej2_instances[0].value;
            document.getElementById(`contentNuovaDescrizione-D${numDann}-R${numRiga}`).style.display = 'none';
            document.getElementById(`contentDescrizioneBase-D${numDann}-R${numRiga}`).style.removeProperty('display')
            document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value = null;
            if (CustomJS.isNullEmpty(id)) {
                document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].dataSource.push({
                    TEXT: testo,
                    VALUE: idFittizio
                })
                document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].refresh();
                setTimeout(() => {
                    document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value = idFittizio
                    document.getElementById(`categoriaSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value = '0300007'
                }, 200)
            }
            else {
                _.find(document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].dataSource, elm => {
                    return elm.VALUE === id;
                }).TEXT = testo;
                document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].refresh();

                setTimeout(() => {
                    document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value = id
                }, 200)
            }
        }
    }

    //PREZZIARIO FINE-------------------------



    //----------------------------CALCOLI COASSICURAZIONI INIZIO------------------------
    aggiornaCoassicurazione(num) {
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}`)) {
                this.calcolaRigaCoassicurazione(num, index, true)
            }
        }
        this.calcolaCoassicurazioneCompleta(num, false);
    }

    calcolaRigaCoassicurazione(num, riga, fromAggiorna) {
        var dannoARiparto = CustomJS.isNullEmpty(document.getElementById(`dannoARipartoRiparto1-D${num}-C${riga}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoARipartoRiparto1-D${num}-C${riga}`).ej2_instances[0].value;
        var tipo = document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${num}-C${riga}`).ej2_instances[0].value;
        var limitazione = CustomJS.isNullEmpty(document.getElementById(`limitazioneContrattualeRiparto1-D${num}-C${riga}`).ej2_instances[0].value) ? 0 : document.getElementById(`limitazioneContrattualeRiparto1-D${num}-C${riga}`).ej2_instances[0].value;
        var dannoNetto = 0;
        if (tipo === 'scoperto') {
            dannoNetto = (dannoARiparto / 100) * limitazione;
        }
        else if (tipo === 'limite_indennizzo') {
            if (dannoARiparto > limitazione)
                dannoNetto = limitazione;
            else
                dannoNetto = dannoARiparto
        }
        else if (tipo === 'franchigia') {
            dannoNetto = dannoARiparto - limitazione;
        }
        else if (tipo === 'nessuno') {
            document.getElementById(`limitazioneContrattualeRiparto1-D${num}-C${riga}`).ej2_instances[0].value = 0;
            dannoNetto = dannoARiparto;
        }
        document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${riga}`).ej2_instances[0].value = dannoNetto < 0 ? 0 : dannoNetto;
        if (fromAggiorna !== true) {
            setTimeout(() => {
                this.calcolaCoassicurazioneCompleta(num, false);
            }, 100)
        }
    }

    calcolaCoassicurazioneCompleta(num, fromcaricaDati) {
        var variabilePrimaRigaCoass = 0
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}`)) {
                variabilePrimaRigaCoass = index;
                break;
            }
        }
        var eseguiMath = this.calcolaSommaDanniCoassicurazione(num, fromcaricaDati);
        if (eseguiMath !== true) {
            this.calcolaFormulaCoassicurazione(num)
            if (fromcaricaDati !== true)
                document.getElementById(`indennizzoVsCompagnia_D${num}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`dannNettoRip2-D${num}-C${variabilePrimaRigaCoass}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannNettoRip2-D${num}-C${variabilePrimaRigaCoass}`).ej2_instances[0].value
        }

        //SOLO NEI CASO IN CUI LA STIMA E' STATO USO
        if (document.getElementById(`determinazioneDanno_D${num}`).ej2_instances[0].value === '003') {
            var dannNettoValoreUso = document.getElementById(`valoreStatoUsodannoNetto_D${num}`).ej2_instances[0].value;
            var indennizzoACarico = document.getElementById(`indennizzoVsCompagnia_D${num}`).ej2_instances[0].value;
            if (dannNettoValoreUso < indennizzoACarico) {
                document.getElementById(`vsuCoass-D${num}`).ej2_instances[0].value = dannNettoValoreUso
                document.getElementById(`supplIndennitaCoass-D${num}`).ej2_instances[0].value = parseFloat((indennizzoACarico - dannNettoValoreUso).toFixed(2))
            }
            else {
                document.getElementById(`vsuCoass-D${num}`).ej2_instances[0].value = document.getElementById(`indennizzoVsCompagnia_D${num}`).ej2_instances[0].value;
                document.getElementById(`supplIndennitaCoass-D${num}`).ej2_instances[0].value = '0';
            }
        }
        setTimeout(() => {
            this.trasportaDatiRiepilogo();
        }, 300)

    }
    calcolaSommaDanniCoassicurazione(num, fromcaricaDati) {
        var sommaDanni = 0;
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}`)) {
                var dannoNetto = CustomJS.isNullEmpty(document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${index}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${index}`).ej2_instances[0].value
                sommaDanni += dannoNetto;
            }
        }
        //controllo
        var dannoRiparto = 0;
        var variabilePrimaRigaCoass = 0;
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}`)) {
                dannoRiparto = CustomJS.isNullEmpty(document.getElementById(`dannoARipartoRiparto1-D${num}-C${index}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoARipartoRiparto1-D${num}-C${index}`).ej2_instances[0].value;
                variabilePrimaRigaCoass = index;
                break;
            }
        }
        document.getElementById(`sommaDanniRiparto1_D${num}`).ej2_instances[0].value = sommaDanni < 0 ? 0 : sommaDanni
        if (sommaDanni < dannoRiparto) {
            for (let index = 0; index < this.numRigheCoassicurazione; index++) {
                if (document.getElementById(`compagniaCoinvoltaRiparto2-D${num}-C${index}`)) {
                    document.getElementById(`dannNettoRip2-D${num}-C${index}`).ej2_instances[0].value = 0;
                    document.getElementById(`formulaCoass-D${num}-C${index}`).ej2_instances[0].value = '';
                }
            }
            document.getElementById(`indennizzoVsCompagnia_D${num}`).ej2_instances[0].value = 0;
            document.getElementById(`sommaDanniRiparto2_D${num}`).ej2_instances[0].value = 0;
            document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${num}`).style.display = 'none'
            document.getElementById(`contentSommaDanniRip2-D${num}`).style.display = 'none'
            document.getElementById(`contentIndVsCompRip2-D${num}`).style.display = 'none'
            return true;
        }
        else {
            if (fromcaricaDati !== true)
                document.getElementById(`indennizzoVsCompagnia_D${num}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`dannNettoRip2-D${num}-C${variabilePrimaRigaCoass}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannNettoRip2-D${num}-C${variabilePrimaRigaCoass}`).ej2_instances[0].value
            document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${num}`).style.removeProperty('display')
            document.getElementById(`contentSommaDanniRip2-D${num}`).style.removeProperty('display')
            document.getElementById(`contentIndVsCompRip2-D${num}`).style.removeProperty('display')
            return false;
        }
    }
    calcolaFormulaCoassicurazione(num) {
        var variabilePrimaRigaCoass = 0;
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}`)) {
                variabilePrimaRigaCoass = index;
                break;
            }
        }
        var sommaDanniRip2 = 0;
        var dannoNettoRiga = 0;
        var numeratore = CustomJS.isNullEmpty(document.getElementById(`dannoARipartoRiparto1-D${num}-C${variabilePrimaRigaCoass}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoARipartoRiparto1-D${num}-C${variabilePrimaRigaCoass}`).ej2_instances[0].value;
        var den = CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto1_D${num}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaDanniRiparto1_D${num}`).ej2_instances[0].value;

        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto2-D${num}-C${index}`)) {
                var moltipl = CustomJS.isNullEmpty(document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${index}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${index}`).ej2_instances[0].value
                document.getElementById(`formulaCoass-D${num}-C${index}`).ej2_instances[0].value = '' + numeratore + ' / ' + '' + den + ' ' + '* ' + moltipl;
                dannoNettoRiga = ((numeratore / den) * moltipl);
                document.getElementById(`dannNettoRip2-D${num}-C${index}`).ej2_instances[0].value = parseFloat(dannoNettoRiga.toFixed(2));
                sommaDanniRip2 += dannoNettoRiga;
            }
        }
        document.getElementById(`sommaDanniRiparto2_D${num}`).ej2_instances[0].value = parseFloat(sommaDanniRip2.toFixed(2));
    }

    cambiaCompagniaSuRip2(numDann, numRiga) {
        document.getElementById(`compagniaCoinvoltaRiparto2-D${numDann}-C${numRiga}`).ej2_instances[0].value = document.getElementById(`compagniaCoinvoltaRiparto1-D${numDann}-C${numRiga}`).ej2_instances[0].value;
    }

    //----------------------------CALCOLI COASSICURAZIONI FINE--------------------------
    //-------------------------STIME CALCOLI FINE------------------------

    //---------------------------- CALCOLI RIEPILOGO INIZIO-----------------------
    trasportaDatiRiepilogo() {
        //Se la coassicurazione c'è allora metto il campo testo coassicurazione per unipol
        if (this.isCompagniaUnipol()) {
            //Cerco coassicurazione
            var almenoUnaCoass = false;
            for (var d = 1; d < this.numStime; d++) {
                if (document.getElementById(`tabellaStima-D${d}`)) {
                    if (document.getElementById(`coassicurazioneSi-D${d}`).ej2_instances[0].checked) {
                        almenoUnaCoass = true;
                        break;
                    }
                }
            }
            if (almenoUnaCoass === true)
                document.getElementById(`tableNoteCoassiurazioneUnipol`).style.removeProperty('display')
            else {
                document.getElementById(`noteCoassicurazioneUnipol`).ej2_instances[0].value = '';
                document.getElementById(`tableNoteCoassiurazioneUnipol`).style.display = 'none'
            }
        }
        var sommaDannComplAc = 0;
        var limiteIndennizziStime = [];
        var objLimiteIndennizzi = {}
        for (let index0 = 1; index0 < this.numStime; index0++) {
            var limitODPR = 0;
            var bool = false;
            if (document.getElementById(`tabellaStima-D${index0}`)) {
                objLimiteIndennizzi = {
                    garanzia: document.getElementById(`garanziaFromSin-D${index0}`).ej2_instances[0].value,
                    // danneggiato: document.getElementById(`danneggiatoFromSin-D${index0}`).ej2_instances[0].value,
                    limite: document.getElementById(`limiteSinistro_D${index0}`).ej2_instances[0].value,
                }
                limiteIndennizziStime.push(objLimiteIndennizzi);
                if (document.getElementById(`determinazioneDanno_D${index0}`).ej2_instances[0].value === `003`) {
                    var dannComplAc = document.getElementById(`valoreANuovoDannComplessAcc_D${index0}`).ej2_instances[0].value;
                    sommaDannComplAc += dannComplAc;
                    bool = true;

                    if (document.getElementById(`valoreANuovoDannComplessAcc_D${index0}`).ej2_instances[0].value > document.getElementById(`limiteSinistro_D${index0}`).ej2_instances[0].value)
                        limitODPR = document.getElementById(`limiteIndennizzo-D${index0}`).ej2_instances[0].value;
                    else
                        limitODPR = document.getElementById(`valoreANuovoDannComplessAcc_D${index0}`).ej2_instances[0].value;
                }
                else if (document.getElementById(`determinazioneDanno_D${index0}`).ej2_instances[0].value === `001`) {
                    var dannComplAc = document.getElementById(`dannoComplessAccert_D${index0}`).ej2_instances[0].value;
                    sommaDannComplAc += dannComplAc;
                    bool = true;

                    let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${index0}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${index0}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${index0}`).ej2_instances[0].value
                    drp = CustomJS.isNullEmpty(drp) ? 0 : drp

                    if (drp > document.getElementById(`limiteSinistro_D${index0}`).ej2_instances[0].value)
                        limitODPR = CustomJS.isNullEmpty(document.getElementById(`limiteIndennizzo-D${index0}`).ej2_instances[0].value) ? 0 : document.getElementById(`limiteIndennizzo-D${index0}`).ej2_instances[0].value;
                    else
                        limitODPR = drp;
                }
            }
            if (bool === true) {
                for (let index = 0; index < this.numTabelleRiepilogo; index++) {
                    for (let z = 0; z < this.numRigheRiepilogoGaranzie; z++) {
                        if (document.getElementById(`garanziadannRiep-D${index}-R-${z}`)) {
                            if (document.getElementById(`danneggiatoRiep-D${index}`).ej2_instances[0].value === document.getElementById(`danneggiatoFromSin-D${index0}`).ej2_instances[0].value) {
                                if (document.getElementById(`garanziadannRiep-D${index}-R-${z}`).ej2_instances[0].value === document.getElementById(`garanziaFromSin-D${index0}`).ej2_instances[0].value) {
                                    if (document.getElementById(`partitadannRiep-D${index}-R-${z}`).ej2_instances[0].value === document.getElementById(`partitaFromSin-D${index0}`).ej2_instances[0].value) {

                                        var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${index0}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${index0}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${index0}`).ej2_instances[0].value;
                                        franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;

                                        document.getElementById(`franchigiaRiep-D${index}-${z}`).ej2_instances[0].value = franchigia;

                                        if (document.getElementById(`determinazioneDanno_D${index0}`).ej2_instances[0].value === `003`) {
                                            if (document.getElementById(`coassicurazioneSi-D${index0}`).ej2_instances[0].checked) {
                                                document.getElementById(`indennizzoLordoRiep-D${index}-${z}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`vsuCoass-D${index0}`).ej2_instances[0].value) ? 0 : document.getElementById(`vsuCoass-D${index0}`).ej2_instances[0].value;
                                                document.getElementById(`indennizzoNettoRiep-D${index}-${z}`).ej2_instances[0].value = document.getElementById(`indennizzoVsCompagnia_D${index0}`).ej2_instances[0].value;
                                                document.getElementById(`supplIndennRiepilogo-D${index}-${z}`).ej2_instances[0].value = document.getElementById(`supplIndennitaCoass-D${index0}`).ej2_instances[0].value;
                                            }
                                            else {
                                                let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${index0}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${index0}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${index0}`).ej2_instances[0].value
                                                drp = CustomJS.isNullEmpty(drp) ? 0 : drp
                                                document.getElementById(`indennizzoLordoRiep-D${index}-${z}`).ej2_instances[0].value = drp
                                                document.getElementById(`indennizzoNettoRiep-D${index}-${z}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`valoreANuovodannoNetto_D${index0}`)) ? 0 : document.getElementById(`valoreANuovodannoNetto_D${index0}`).ej2_instances[0].value;

                                                let drpDegrado = CustomJS.isNullEmpty(document.getElementById(`degrado25PercDopoRegolaPropMAN_D${index0}`).ej2_instances[0].value) ? document.getElementById(`degrado25PercDopoRegolaProp_D${index0}`).ej2_instances[0].value : document.getElementById(`degrado25PercDopoRegolaPropMAN_D${index0}`).ej2_instances[0].value
                                                drpDegrado = CustomJS.isNullEmpty(drpDegrado) ? 0 : drpDegrado

                                                document.getElementById(`supplIndennRiepilogo-D${index}-${z}`).ej2_instances[0].value = drpDegrado;
                                            }
                                        }
                                        else if (document.getElementById(`determinazioneDanno_D${index0}`).ej2_instances[0].value === `001`) {
                                            document.getElementById(`indennizzoLordoRiep-D${index}-${z}`).ej2_instances[0].value = limitODPR;
                                            document.getElementById(`indennizzoNettoRiep-D${index}-${z}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${index0}`)) ? 0 : document.getElementById(`dannoNetto_D${index0}`).ej2_instances[0].value;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        var indennizziNettiRiepilogo = []
        var objIndennizzo = {}
        for (let index = 0; index < this.numTabelleRiepilogo; index++) {
            var sommaLordo = 0;
            var sommaFranchigie = 0
            var sommaSupplIndenn = 0
            for (let z = 0; z < this.numRigheRiepilogoGaranzie; z++) {
                if (document.getElementById(`garanziadannRiep-D${index}-R-${z}`)) {
                    objIndennizzo = {
                        garanzia: document.getElementById(`garanziadannRiep-D${index}-R-${z}`).ej2_instances[0].value,
                        indennizzo: document.getElementById(`indennizzoNettoRiep-D${index}-${z}`).ej2_instances[0].value
                    }
                    indennizziNettiRiepilogo.push(objIndennizzo)
                    var indLordo = CustomJS.isNullEmpty(document.getElementById(`indennizzoLordoRiep-D${index}-${z}`).ej2_instances[0].value) ? 0 : document.getElementById(`indennizzoLordoRiep-D${index}-${z}`).ej2_instances[0].value;
                    sommaLordo += indLordo;
                    var franch = document.getElementById(`franchigiaRiep-D${index}-${z}`).ej2_instances[0].value;
                    sommaFranchigie += franch;
                    var indenn = document.getElementById(`supplIndennRiepilogo-D${index}-${z}`).ej2_instances[0].value;
                    sommaSupplIndenn += indenn;
                }
            }
            //CONFRONTO I LIMITI CON GLI INDENNIZZI INIZIO
            //Tolgo i doppioni dall' array limiteIndennizziStime che hanno lo stesso danneggiato, garanzia e limite uguali
            const unique2 = limiteIndennizziStime.filter((obj, index) => {
                return index === limiteIndennizziStime.findIndex(o => obj.garanzia === o.garanzia && obj.limite === o.limite);

            });
            limiteIndennizziStime = unique2;

            var sommeGaranziaDanneggiato = _.groupBy(indennizziNettiRiepilogo, 'garanzia');
            //CICLO LE GARANZIE
            var sommeIndennizziPerGaranzia = []
            for (var i = 0; i < Object.keys(sommeGaranziaDanneggiato).length; i++) {
                var arrayPerGaranzia = sommeGaranziaDanneggiato[Object.keys(sommeGaranziaDanneggiato)[i]];
                //CICLO GLI INDENNIZZI
                var sommaIndennizziPerGaranzia = 0;
                arrayPerGaranzia.forEach(elm => {
                    sommaIndennizziPerGaranzia += elm.indennizzo;
                });
                sommeIndennizziPerGaranzia.push({
                    garanzia: arrayPerGaranzia[0].garanzia,
                    sommaIndennizzi: sommaIndennizziPerGaranzia
                })
            }
            var sommeTotaliIndennizzi = 0;
            for (let z = 0; z < this.numRigheRiepilogoGaranzie; z++) {
                if (document.getElementById(`garanziadannRiep-D${index}-R-${z}`)) {
                    limiteIndennizziStime.forEach(elm => {
                        sommeIndennizziPerGaranzia.forEach(elm2 => {
                            if (elm.garanzia === elm2.garanzia) {
                                if (elm2.sommaIndennizzi > elm.limite) {
                                    var indennizzoNetto = document.getElementById(`indennizzoNettoRiep-D${index}-${z}`).ej2_instances[0].value;
                                    var indennizzoNettoNuovo = (indennizzoNetto / elm2.sommaIndennizzi) * elm.limite;
                                    document.getElementById(`indennizzoNettoRiep-D${index}-${z}`).ej2_instances[0].value = indennizzoNettoNuovo;
                                }
                            }
                        })
                    })
                    sommeTotaliIndennizzi += document.getElementById(`indennizzoNettoRiep-D${index}-${z}`).ej2_instances[0].value;
                }
            }

            //CONFRONTO I LIMITI CON GLI INDENNIZZI FINE
            if (document.getElementById(`rigaTotaliDanneggiatiRiepilogo-D${index}`)) {
                document.getElementById(`totaleIndennizzo-D${index}`).ej2_instances[0].value = sommeTotaliIndennizzi
                document.getElementById(`totaleIndennizzoLordo-D${index}`).ej2_instances[0].value = sommaLordo
                document.getElementById(`totaleFranchige-D${index}`).ej2_instances[0].value = sommaFranchigie
                document.getElementById(`totaleSuppIndenn-D${index}`).ej2_instances[0].value = sommaSupplIndenn
            }
        }
        var sommaTotIndennLordi = 0;
        var sommaTotFranchScop = 0;
        var sommaTotSupllIndennita = 0;
        var sommaTotIndennNetti = 0;

        for (let index = 0; index < this.numTabelleRiepilogo; index++) {
            if (document.getElementById(`rigaTotaliDanneggiatiRiepilogo-D${index}`)) {
                sommaTotIndennLordi += document.getElementById(`totaleIndennizzoLordo-D${index}`).ej2_instances[0].value;
                sommaTotFranchScop += document.getElementById(`totaleFranchige-D${index}`).ej2_instances[0].value;
                sommaTotSupllIndennita += document.getElementById(`totaleSuppIndenn-D${index}`).ej2_instances[0].value;
                sommaTotIndennNetti += document.getElementById(`totaleIndennizzo-D${index}`).ej2_instances[0].value;
            }
        }

        if (CustomJS.isNullEmpty(document.getElementById(`totaleIndennizziLordi`)))
            return;

        document.getElementById(`totaleIndennizziLordi`).ej2_instances[0].value = sommaTotIndennLordi
        document.getElementById(`totaleFranchigeScoperti`).ej2_instances[0].value = sommaTotFranchScop
        document.getElementById(`totaleSupplIndennita`).ej2_instances[0].value = sommaTotSupllIndennita
        document.getElementById(`totaleIndennizziNetti`).ej2_instances[0].value = sommaTotIndennNetti

        var arrotondo = Math.round(sommaTotIndennNetti)
        arrotondo = this.arrotondaIndennizziNettiComplessivi(arrotondo)
        document.getElementById(`totaleIndennNettoComplApprossRiep`).ej2_instances[0].value = arrotondo
        document.getElementById(`totaleDanniComplessiviAccertati`).ej2_instances[0].value = sommaDannComplAc

        var indennDopoEventualeScop = parseFloat((sommaTotIndennLordi - sommaTotFranchScop).toFixed(2));
        document.getElementById(`indennizzoDopoEventualeScop`).ej2_instances[0].value = indennDopoEventualeScop < 0 ? 0 : indennDopoEventualeScop
    }

    arrotondaIndennizziNettiComplessivi(arrotondo) {
        const replaceLast = (str, pattern, replacement) => {
            const match =
                typeof pattern === 'string'
                    ? pattern
                    : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
            if (!match) return str;
            const last = str.lastIndexOf(match);
            return last !== -1
                ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
                : str;
        };
        var stringa = arrotondo.toString();
        var last = stringa.charAt(stringa.length - 1)
        stringa = replaceLast(stringa, last, '0');
        return stringa;
    }
    //----------------------------CALCOLI RIEPILOGO FINE---------------------------


    render() {
        return (<React.Fragment>
            <div id='blockUIGenerico' className='blockUIPerizia' style={{ display: ' none' }}></div>
            <div id='spinnerUIGenerico' style={{ display: 'none' }} role="status"></div>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="dialogPrezziario" cssClass='dialog-sipa' showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog}
                    ref={dialog => this.dialogInstance0 = dialog} isModal={true} >
                    <div id='dialogPrezziarioContent'></div>
                </DialogComponent>
                <DialogComponent id="dialogEliminaCalcoli" showCloseIcon={true} animationSettings={this.animationSettings} visible={false}
                    ref={dialog => this.dialogInstanceElimina = dialog} isModal={true}>
                    <div id='containerDialogEliminaCalcoli'></div>
                </DialogComponent>
                <div className='container-fluid'>
                    <div className="control-section card-control-section basic_card_layout" >
                        <div className="e-card-resize-container">
                            <div className="row card-layout">
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ width: '96.5%' }}>
                                    <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #8fb3d2', marginTop: '100px', marginLeft: '70px' }}>
                                        <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                            <div style={{ marginLeft: '1.75%' }} className='idSinistroIncaricoElab'>
                                                <p title='Sinistro importato' className='importatoGen' id='sinistroImportatoGenElab2'></p>
                                                <h4 >ID Sinistro : {this.props.IdSinistro}</h4>
                                                <h4 id='idIncaricoTestaElab2'></h4>
                                                <h4 id='compagniaTesta2'>Compagnia : {this.nomeCompagnia}</h4>
                                            </div>
                                            <div className='row'>
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center" >
                                                    <h4 id='avvisoPeriziaModificata' style={{ color: 'red' }} className="card-title"></h4>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-end" >
                                                    {/* <ButtonComponent title='ELIMINA CALCOLI' style={{ marginRight: '3%' }} cssClass='e-fill e-danger' type='button' onClick={this.dialogEliminaCalcoli.bind(this)}>ELIMINA CALCOLI</ButtonComponent> */}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <form id="formElaboratoPeritaleCalcoli">
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center" >
                                                        <h3 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Preesistenze</h3>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                        <div className='box-tutte-le-tabelle' id='contentTutteTabellePreesistenze'>
                                                            {/* CONTENUTO DINAMICO DI TUTTE LE PREESISTENZE */}
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left" style={{ marginTop: '50px', textAlign: 'center' }}>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                            <h3 className="card-title" style={{ color: 'black', fontWeight: '500', marginBottom: '25px' }}>Stime</h3>
                                                        </div>
                                                        <div className='box-tutte-le-tabelle' id='tabellaCompletaTutteLeStime'>
                                                            {/* CONTENUTO DINAMICO DI TUTTE LE STIME */}
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left" style={{ marginTop: '50px', textAlign: 'center' }}>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                        <h3 className="card-title" style={{ color: 'black', fontWeight: '500', marginBottom: '25px' }}>Riepilogo</h3>
                                                    </div>
                                                    <div className='box-tutte-le-tabelle'>
                                                        <div id='contentAggiornaRiep' style={{ textAlign: 'right', padding: '1%' }}>
                                                            <ButtonComponent id={`aggiornaRiepilogo`} cssClass='e-fill e-info' type='button' onClick={this.trasportaDatiRiepilogo.bind(this)}>Aggiorna Riepilogo</ButtonComponent>
                                                        </div>
                                                        <div id='tabellaCompletaTutteIRiepiloghi' >
                                                            {/* CONTENUTO DINAMICO DEL RIEPILOGO */}
                                                        </div>
                                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                            <h5>Totali Complessivi</h5>
                                                        </div>
                                                        <table className='perizia-table riepilogoTotale' >
                                                            <tr>
                                                                <th ></th>
                                                                <th >Totale indennizzi lordi</th>
                                                                <th >Totale Franchige/Scoperti</th>
                                                                <th >Totale Supplementi Indennità</th>
                                                                <th >Totale indennizzi Netti</th>
                                                            </tr>
                                                            <tr>
                                                                <th style={{ textAlign: 'right' }}>Totali Complessivi Danneggiati : </th>
                                                                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennizziLordi`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleFranchigeScoperti`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleSupplIndennita`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennizziNetti`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                            </tr>
                                                        </table>
                                                        <table className='perizia-table riepilogoTotale2' >
                                                            <tr>
                                                                <th></th>
                                                                <th>Totale Danni Complessivi Accertati</th>
                                                                <th></th>
                                                                <th>Indennizzo Dopo Eventuale Scopertura</th>
                                                                <th>Totale Indennizzi Netti Complessivi Per Arrotondamento</th>
                                                            </tr>

                                                            <tr>
                                                                <th style={{ textAlign: 'right' }}>Altri Totali Complessivi :</th>
                                                                <th><NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleDanniComplessiviAccertati`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                                <th></th>
                                                                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoDopoEventualeScop`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                                <th>
                                                                    <div className='row'>
                                                                        <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
                                                                            <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennNettoComplApprossRiep`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent >
                                                                            <div id='boxArrotondamentoMAN' style={{ display: 'none' }}>
                                                                                Valore manuale (definitivo)
                                                                                <NumericTextBoxComponent alt='Cambio Valore Manuale' showSpinButton={false} id={`totaleIndennNettoComplApprossRiepMAN`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent >
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <ButtonComponent style={{ color: 'red' }} id={`sbloccaTotaleIndennNettoComplApprossRiep`} alt='Sblocca Totale Arrotondamento' cssClass='e-fill' type='button' onClick={this.totaleIndennizzoManuale.bind(this)}><i class="fas fa-pen"></i></ButtonComponent>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                        <table id='tableNoteCoassiurazioneUnipol' style={{ display: 'none' }} className='perizia-table riepilogoTotale' >
                                                            <tr>
                                                                <th>
                                                                    <TextBoxComponent id={`noteCoassicurazioneUnipol`} placeholder="Note Coassicurazione Unipol" floatLabelType="Auto" cssClass='e-outline' />
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div id='containerInformazioniRichiesteDallaCompagniaGenerali2' style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center" >
                                                            <h3 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Informazioni Richieste dalla Compagnia</h3>
                                                        </div>
                                                        <div className='box-tutte-le-tabelle' id='containerNuovoTutteLeListBox'>
                                                            {/* QUI TUTTI I LISTBOX NUOVI DELLE INFO RICHIESTE*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center" >
                                                <h3 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Definizione</h3>
                                            </div>
                                            <div className="row card-layout" style={{ marginTop: '2%' }}>
                                                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                    <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                        <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                            <div className='row'>
                                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                                    <h4>Note</h4>
                                                                    <RichTextEditorComponent id='noteDefinizione' showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                        maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                                        <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                                    </RichTextEditorComponent>
                                                                    <ButtonComponent id="eliminaNoteDefinizione" style={{ marginTop: '5%' }} onClick={() => { document.getElementById('noteDefinizione').ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                    <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                        <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                            <div className='row' >
                                                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ textAlign: 'center' }}>
                                                                    <ButtonComponent id="btnDefinizioneDefault" onClick={this.generaDescrizioneDefinizione.bind(this, 'default')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Default</ButtonComponent>
                                                                </div>
                                                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ textAlign: 'center' }}>
                                                                    <ButtonComponent id="btnDefinizioneAttesaAtto" onClick={this.generaDescrizioneDefinizione.bind(this, 'attesaAtto')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>In Attesa di Atto</ButtonComponent>
                                                                </div>
                                                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ textAlign: 'center' }}>
                                                                    <ButtonComponent id="btnDefinizioneAccordoVerbale" onClick={this.generaDescrizioneDefinizione.bind(this, 'accordoVerbale')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Accordo Verbale</ButtonComponent>
                                                                </div>
                                                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ textAlign: 'center' }}>
                                                                    <ButtonComponent id="btnDefinizioneAtto" onClick={this.generaDescrizioneDefinizione.bind(this, 'atto')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Atto</ButtonComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '7%' }}></div>
                <MDBFooter id='footerElaboratoP1' bgColor='light' className='fixed-bottom ' style={{ marginLeft: '65px', zIndex: 10 }}>
                    <div className='row' style={{ backgroundColor: 'rgb(239, 239, 239)', borderTop: '1px solid #202351' }}>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-start">
                            <ButtonComponent id='dettSinPag2' style={{ marginLeft: '5%' }} alt='Pagina Dettaglio Sinistro' cssClass='e-fill e-info' type='button' onClick={this.cambiaPaginaFooter.bind(this, 'Dettaglio', '')}>←← Dettaglio Sinistro</ButtonComponent>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-center">
                            <h3 id='statoNonModificabilePeritoP2' style={{ color: 'red' }}></h3>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                            <ButtonComponent id='elabPeritalePag2' alt='Pagina Elaborato' cssClass='e-fill e-info' type='button' onClick={this.cambiaPaginaFooter.bind(this, 'ElaboratoP1', '')}>← Elaborato Peritale</ButtonComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                            <ButtonComponent id='salvaEsciP2' style={{ marginRight: '5%' }} alt='Salva ed Esci' cssClass='e-fill e-success' type='button' onClick={this.requiredCampiTabelle.bind(this)}>← Salva ed Esci</ButtonComponent>
                        </div>
                    </div>
                </MDBFooter>
            </BlockUi>
        </React.Fragment>);
    }

    totaleIndennizzoManuale() {
        if (document.getElementById('sbloccaTotaleIndennNettoComplApprossRiep').innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById('sbloccaTotaleIndennNettoComplApprossRiep').innerHTML = '<i class="fas fa-undo"></i>'
            document.getElementById('sbloccaTotaleIndennNettoComplApprossRiep').style.color = "blue";
            document.getElementById('boxArrotondamentoMAN').style.removeProperty('display');
        }
        else if (document.getElementById('sbloccaTotaleIndennNettoComplApprossRiep').innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById('sbloccaTotaleIndennNettoComplApprossRiep').innerHTML = '<i class="fas fa-pen"></i>'
            document.getElementById('sbloccaTotaleIndennNettoComplApprossRiep').style.color = "red";
            document.getElementById('boxArrotondamentoMAN').style.display = 'none';
            document.getElementById('totaleIndennNettoComplApprossRiepMAN').ej2_instances[0].value = null;
        }
    }

    franchigiaManuale(numDann) {
        if (document.getElementById(`btnFranchigiaManuale_D${numDann}`).innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById(`btnFranchigiaManuale_D${numDann}`).innerHTML = '<i class="fas fa-undo"></i>';
            document.getElementById(`btnFranchigiaManuale_D${numDann}`).style.color = 'blue';
            document.getElementById(`boxFranchigiaManuale_D${numDann}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnFranchigiaManuale_D${numDann}`).innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById(`btnFranchigiaManuale_D${numDann}`).innerHTML = '<i class="fas fa-pen"></i>';
            document.getElementById(`btnFranchigiaManuale_D${numDann}`).style.color = 'red';
            document.getElementById(`boxFranchigiaManuale_D${numDann}`).style.display = 'none';
            document.getElementById(`franchigiaMAN_D${numDann}`).ej2_instances[0].value = null;
            if (document.getElementById(`determinazioneDanno_D${numDann}`).ej2_instances[0].value === "001")
                this.calcolaStimaValoreANuovo(numDann, false);
            else if (document.getElementById(`determinazioneDanno_D${numDann}`).ej2_instances[0].value === "003")
                this.calcolaStimaStatoUso(numDann, false);
        }
    }

    dopoRegolaManuale(numDann) {

        if (document.getElementById(`btnDopoRegolaMAN_D${numDann}`).innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById(`btnDopoRegolaMAN_D${numDann}`).innerHTML = '<i class="fas fa-undo"></i>';
            document.getElementById(`btnDopoRegolaMAN_D${numDann}`).style.color = 'blue';
            document.getElementById(`boxDopoRegolaManuale_D${numDann}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnDopoRegolaMAN_D${numDann}`).innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById(`btnDopoRegolaMAN_D${numDann}`).innerHTML = '<i class="fas fa-pen"></i>';
            document.getElementById(`btnDopoRegolaMAN_D${numDann}`).style.color = 'red';
            document.getElementById(`boxDopoRegolaManuale_D${numDann}`).style.display = 'none';

            document.getElementById(`dopoRegolaProporzionaleMAN_D${numDann}`).ej2_instances[0].value = null;
            if (document.getElementById(`determinazioneDanno_D${numDann}`).ej2_instances[0].value === "001")
                this.calcolaStimaValoreANuovo(numDann, false);
            else if (document.getElementById(`determinazioneDanno_D${numDann}`).ej2_instances[0].value === "003")
                this.calcolaStimaStatoUso(numDann, false);
        }
    }
    dopoRegolaManualeValANuovo(numDann) {
        if (document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}`).innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}`).innerHTML = '<i class="fas fa-undo"></i>';
            document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}`).style.color = 'blue';
            document.getElementById(`boxValANuovoDopoRegola_D${numDann}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}`).innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}`).innerHTML = '<i class="fas fa-pen"></i>';
            document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}`).style.color = 'red';
            document.getElementById(`boxValANuovoDopoRegola_D${numDann}`).style.display = 'none';

            document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${numDann}`).ej2_instances[0].value = null;
            if (document.getElementById(`determinazioneDanno_D${numDann}`).ej2_instances[0].value === "001")
                this.calcolaStimaValoreANuovo(numDann, false);
            else if (document.getElementById(`determinazioneDanno_D${numDann}`).ej2_instances[0].value === "003")
                this.calcolaStimaStatoUso(numDann, false);
        }
    }

    dopoRegolaManualeDegrado(numDann) {
        if (document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}`).innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}`).innerHTML = '<i class="fas fa-undo"></i>';
            document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}`).style.color = 'blue';
            document.getElementById(`boxDegrado25DopoRegola_D${numDann}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}`).innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}`).innerHTML = '<i class="fas fa-pen"></i>';
            document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}`).style.color = 'red';
            document.getElementById(`boxDegrado25DopoRegola_D${numDann}`).style.display = 'none';

            document.getElementById(`degrado25PercDopoRegolaPropMAN_D${numDann}`).ej2_instances[0].value = null;
            if (document.getElementById(`determinazioneDanno_D${numDann}`).ej2_instances[0].value === "001")
                this.calcolaStimaValoreANuovo(numDann, false);
            else if (document.getElementById(`determinazioneDanno_D${numDann}`).ej2_instances[0].value === "003")
                this.calcolaStimaStatoUso(numDann, false);
        }
    }

    generaDescrizioneDefinizione(scelta) {
        var totale = CustomJS.isNullEmpty(document.getElementById('totaleIndennNettoComplApprossRiepMAN').ej2_instances[0].value) ? document.getElementById('totaleIndennNettoComplApprossRiep').ej2_instances[0].value : document.getElementById('totaleIndennNettoComplApprossRiepMAN').ej2_instances[0].value
        var testo = ''
        if (scelta === 'default') {
            if (this.riservaCe === true)
                // se c'è la riserva :
                testo = `Al termine degli accertamenti peritali riteniamo che il sinistro non rientri nelle garanzie di polizza in quanto sussistono riserve di operatività.`
            else
                // se non c'è la riserva :
                testo = `Al termine degli accertamenti peritali riteniamo che il sinistro rientri nelle garanzie di polizza in quanto non sussistono riserve di operatività.`
        }
        else if (scelta === 'attesaAtto')
            testo = `Per il presente sinistro abbiamo inviato all'amm.re pro tempore del condomino  assicurato atto con RISERVA che ad oggi non ci è stato restituito perfezionato.`
        else if (scelta === 'accordoVerbale')
            testo = `Il danno stimato è stato concordato verbalmente nella persona dell'amm.re pro-tempore sig. ${this.contraenteRiserva}.  per un importo pari a  ${totale} €  al netto di franchigia.`
        else if (scelta === 'atto')
            testo = `Il danno è stato definito mediante Atto con il sig. ${this.contraenteRiserva} in qualità di amm.re pro tempore del condomino assicurato per un importo pari a ${totale} € al netto delle franchigie.`
        document.getElementById('noteDefinizione').ej2_instances[0].value = testo;
    }
    //------------------------------------FUNZIONI CREAZIONE CARICA SALVA INIZIO------------------------------------
    async componentDidMount() {
        document.getElementById('titoloPagina').innerText = 'Elaborato Peritale ' + this.props.IdSinistro;
        document.getElementById('dettSinPag2').ej2_instances[0].disabled = true;
        document.getElementById('elabPeritalePag2').ej2_instances[0].disabled = true;
        document.getElementById('salvaEsciP2').ej2_instances[0].disabled = true;
        this.toggleBlocking();
        await this.nuovaCreaTabelle();
        this.toggleBlocking();
        setTimeout(() => {
            this.nuovaCaricaDati()
        }, 200)
    }
    async nuovaCreaTabelle() {
        return new Promise((resolveCreaTabelle) => {
            //RIEPILOGO TIPI DI TABELLE 

            //----------------------GENERALI----------------------
            //PARTITE/PREESISTENZE = 3 In base alla Partita con Forma Garanzia : 'VALORE INTERO' = '2'
            //1)'FABBRICATO' = 'TP016'  (Fabbricato)
            //2)'CONTENUTO DIMORA SALTUARIA' = 'TP005' || 'EFFETTI DOMESTICI' = 'TP014' || 'EFFETTI DOMESTICI NELL''ABITAZIONE' = 'TP015' (Effetti domestici)
            //3)RESTANTI
            //Le Tabelle Preesistenza sono racchiuse tutte in un unica funzione render : tabellaPreesistenzaBase e gestite nella caricaDati

            //PARTITE/PREESISTENZE = 1 In base alla Forma Garanzia : 'P.R.A.' = '1' con qualunque partita
            //STIME/DANNI : 2 in base alla Determinazione del Danno
            //1)'Valore a Nuovo' = '001' BASE
            //2)'Valore allo stato d''uso e supplemento di indennizzo' = '003' COMPLESSA

            //----------------------DEFAULT----------------------

            //Id sinistro ed eventuale Id Incarico Top Pagina
            if (this.isCompagniaGenerali()) {
                document.getElementById('sinistroImportatoGenElab2').innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                document.getElementById('idIncaricoTestaElab2').innerHTML = 'ID Incarico : ' + this.props.IdIncarico;
            }
            else if (this.isCompagniaUnipol())
                document.getElementById('idIncaricoTestaElab2').innerHTML = 'ID Incarico : ' + this.props.IdIncaricoUnipol;

            //Dichiarazione Array per Info richieste
            var infoRichPartite = [];
            var infoRichGaranzie = [];
            var infoRichStime = []
            const queryPartite = (`SELECT distinct T1.*, T2.id_partita as guid_partita FROM _partitePeriziaOnline T1 left outer join GENERALI_polizza_partite T2 on T1.id_partitaPeriziaOnline = T2.id_partita_xpert WHERE T1.id_sinistro = ${this.props.IdSinistro}`);
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryPartite, '', retDataPartite => {
                retDataPartite = JSON.parse(retDataPartite.data);
                //ordino le partite
                var nuovoRetDataPartite = [];
                retDataPartite.forEach(elm => {
                    if (elm.id_partita === '1' || elm.id_partita === 'TP016')
                        nuovoRetDataPartite.unshift(elm)
                    else
                        nuovoRetDataPartite.push(elm)
                })
                retDataPartite = nuovoRetDataPartite;
                let funcListPartite = [];

                //creo le tabelle delle preesistenze dalle partite
                retDataPartite.forEach(dataPartita => {
                    const esegui = () => {
                        return new Promise(async (resolvePartita) => {
                            //Inserimento id_partita per Info Richieste
                            infoRichPartite.push(dataPartita.id_partita)
                            //FORMA GARANZIA : 'VALORE A NUOVO'
                            if (dataPartita.formaGaranzia === '2') {
                                await this.tabellaPreesistenzaBase(dataPartita.id_partita, dataPartita.descrizione, dataPartita.formaGaranzia, dataPartita.guid_partita, dataPartita.id_partitaPeriziaOnline, dataPartita.sommaAssicurataPartita);
                            }
                            //PRIMO RISCHIO ASSOLUTO
                            else if (dataPartita.formaGaranzia === '1') {
                                await this.tabellaPreesistenzaPRA(dataPartita.id_partita, dataPartita.descrizione, dataPartita.formaGaranzia, dataPartita.guid_partita, dataPartita.id_partitaPeriziaOnline);
                            }
                            resolvePartita('Prossima');
                        })
                    }
                    funcListPartite.push(esegui);
                })

                const eseguiCiclicaPartite = (ind) => {
                    if (CustomJS.isNullEmpty(funcListPartite[ind])) {
                        //------------FINITO DI CREARE LE PREESISTENZE-----------
                        //per evitare le partite doppie con descrizione diversa
                        infoRichPartite = [...new Map(infoRichPartite?.map(item => [item, item])).values()];
                    }
                    else {
                        funcListPartite[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclicaPartite(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclicaPartite(0);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            });
            //CARICO LE GARANZIE NELL' ARRAY PER LE INFO RICHIESTE
            const queryGaranzie = (`SELECT * FROM _garanziePeriziaOnline WHERE id_sinistro = ${this.props.IdSinistro};`);
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryGaranzie, '', retDataGaranzie => {
                retDataGaranzie = JSON.parse(retDataGaranzie.data);
                retDataGaranzie.forEach(garanzia => {
                    //Inserimento id_garanzia per Info Richieste
                    infoRichGaranzie.push(garanzia.id_garanzia);
                })
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            });
            const queryStime = (`SELECT distinct T4.id_danno,T4.id_danneggiato as guid_danneggiato,T1.*,T2.id_garanzia as cod_ele_garanzia, T2.id_determinazioneDanno, T2.limitePer,T2.franchigiaCondivisa,T2.franchigiaEuro,T2.scopertoPerc,T2.scopertoMin,T2.scopertoMax, T3.danneggiato,T3.nome, T3.cognome, T5.id_partita as cod_ele_partita,T5.descrizione as descrizione_partita FROM _stime_xpert T1 left outer join _garanziePeriziaOnline T2 on T1.id_garanzia = T2.id_garanziaPeriziaOnline left outer join _partitePeriziaOnline T5 on T1.id_partita = T5.id_partitaPeriziaOnline left outer join acdanneggiati T3 on T1.id_danneggiato = T3.id_danneggiato left outer join GENERALI_danni T4 on T1.id_stima = T4.id_stima_xpert where T1.id_sinistro = ${this.props.IdSinistro};`);
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryStime, '', async (retDataStime) => {
                retDataStime = JSON.parse(retDataStime.data);
                var danneggiati = [];
                var partite = [];
                var garanzie = [];

                const fineCreaStime = () => {
                    return new Promise((resolveFineStime) => {
                        let funcListStime = [];
                        retDataStime.forEach((dataStima, idx) => {
                            const eseguiStima = () => {
                                return new Promise(async (resolveStima) => {

                                    //Inserimento combo stima per Info Richieste
                                    infoRichStime.push({ idStima: dataStima.id_stima, idDanno: dataStima.id_danno, idDanneggiato: dataStima.id_danneggiato, idPartita: dataStima.cod_ele_partita, idGaranzia: dataStima.cod_ele_garanzia })
                                    if (this.isCompagniaGenerali()) {
                                        //Localizzazione
                                        var servizioStringa2 = ''
                                        let obj = _.find(this.dsGaranzie, elm => {
                                            return elm.VALUE === dataStima.cod_ele_garanzia;
                                        });
                                        if (!CustomJS.isNullEmpty(obj))
                                            servizioStringa2 = obj.SERVIZIO_STR2;

                                        var codEle = '';
                                        let obj2 = _.find(this.nuovoArrInfoRichDanno, elm => {
                                            return elm.SERVIZIO_STR2 === servizioStringa2;
                                        });
                                        if (!CustomJS.isNullEmpty(obj2))
                                            codEle = obj2.COD_ELE;

                                        var arrayLocalizzazione = []
                                        this.nuovoArrRispInfoRichDanno.forEach(elm => {
                                            if (elm.SERVIZIO_STR2 === codEle)
                                                arrayLocalizzazione.push({ VALUE: elm.COD_ELE, TEXT: elm.DESCRIZ });
                                        });
                                        this.allArrayLocalizzazioneForeachGaranzia.push({ garanzia: dataStima.cod_ele_garanzia, array: arrayLocalizzazione });
                                    }
                                    //Danneggiati
                                    this.dsDanneggiati.push({ TEXT: CustomJS.isNullEmpty(dataStima.danneggiato) ? (dataStima.nome + ' ' + dataStima.cognome) : dataStima.danneggiato, VALUE: dataStima.id_danneggiato, GUID: dataStima.guid_danneggiato });

                                    //Gestione franchigia o scoperto
                                    var franchigiaOScoperto = ''; // default
                                    if (dataStima.scopertoPerc !== 0 || dataStima.scopertoMin !== 0 || dataStima.scopertoMax !== 0)
                                        franchigiaOScoperto = 'scoperto';
                                    else if (dataStima.scopertoPerc === 0 && dataStima.scopertoMin === 0 && dataStima.scopertoMax === 0)
                                        franchigiaOScoperto = 'franchigia';

                                    //Creazione Stime in base alla Determinazione del danno //
                                    if (dataStima.id_determinazioneDanno === '001') {
                                        await this.tabellaStimaValoreANuovo(dataStima.id_stima, dataStima.id_danno, dataStima.id_danneggiato, dataStima.cod_ele_partita, dataStima.descrizione_partita, dataStima.cod_ele_garanzia, dataStima.id_determinazioneDanno, dataStima.limitePer, franchigiaOScoperto, dataStima, idx);
                                    }
                                    else if (dataStima.id_determinazioneDanno === '003') {
                                        await this.tabellaStimaStatoUso(dataStima.id_stima, dataStima.id_danno, dataStima.id_danneggiato, dataStima.cod_ele_partita, dataStima.descrizione_partita, dataStima.cod_ele_garanzia, dataStima.id_determinazioneDanno, dataStima.limitePer, franchigiaOScoperto, dataStima, idx);
                                    }
                                    //utili per la creazione della tabella Riepilogo
                                    danneggiati.push(dataStima.id_danneggiato);
                                    partite.push(dataStima.cod_ele_partita);
                                    garanzie.push(dataStima.cod_ele_garanzia);

                                    resolveStima('Prossima');
                                })
                            }
                            funcListStime.push(eseguiStima);
                        })

                        const eseguiCiclicaStime = (ind) => {
                            if (CustomJS.isNullEmpty(funcListStime[ind])) {
                                resolveFineStime();
                            }
                            else {
                                funcListStime[ind]().then(ret => {
                                    if (ret === 'Prossima') {
                                        eseguiCiclicaStime(ind + 1);
                                    }
                                }).catch(err => {
                                    console.log(err)
                                })
                            }
                        }
                        eseguiCiclicaStime(0);
                    });
                }
                await fineCreaStime()

                const fineRiepilogo = () => {
                    return new Promise((resolveFineRiepilogo) => {

                        //Creazione Riepilogo
                        var arrayComboStime = {
                            danneggiati: danneggiati,
                            partite: partite,
                            garanzie: garanzie
                        }
                        //Eliminazione doppioni nell' array : arrayComboStime.danneggiati
                        var distinctDanneggiati = arrayComboStime.danneggiati;
                        distinctDanneggiati = [...new Map(distinctDanneggiati?.map(item => [item, item])).values()];

                        let funcListDanneggiati = [];
                        for (let dd = 0; dd < distinctDanneggiati.length; dd++) {
                            const eseguiDanneggiato = () => {
                                return new Promise(async (resolveDanneggiato) => {
                                    await this.tabellaRiepilogo0(distinctDanneggiati[dd], arrayComboStime);
                                    resolveDanneggiato('Prossima')
                                })
                            }
                            funcListDanneggiati.push(eseguiDanneggiato);
                        }
                        const eseguiCiclica = (ind) => {
                            if (CustomJS.isNullEmpty(funcListDanneggiati[ind])) {
                                resolveFineRiepilogo();
                            }
                            else {
                                funcListDanneggiati[ind]().then(ret => {
                                    if (ret === 'Prossima') {
                                        eseguiCiclica(ind + 1);
                                    }
                                }).catch(err => {
                                    console.log(err)
                                })
                            }
                        }
                        eseguiCiclica(0);
                    });
                }
                await fineRiepilogo();

                if (this.isCompagniaUnipol()) {
                    const queryCoassUnipol = (`SELECT noteCoassicurazioniIndirette FROM UNIPOLSAI_Osservazioni where id_sinistro_xpert = '${this.props.IdSinistro}';`);
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryCoassUnipol, '', retDataCoassDescr => {
                        retDataCoassDescr = JSON.parse(retDataCoassDescr.data);
                        document.getElementById('noteCoassicurazioneUnipol').ej2_instances[0].value = retDataCoassDescr[0].noteCoassicurazioniIndirette;
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    });
                }
                resolveCreaTabelle();
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            });
        });
    }



    async nuovaCaricaDati() {
        this.toggleBlocking();

        const finePreesistenze = () => {
            return new Promise((resolveFinePreesistenze) => {

                const queryPreesistenze = (`SELECT distinct T1.*,T3.id_preesistenza as id_preesistenza_gen,T4.id_partita as guid_partita_gen FROM _preesistenza_xpert T1 left outer join _partitePeriziaOnline T2 on T1.id_partitaPeriziaOnline = T2.id_partitaPeriziaOnline left outer join GENERALI_partita_preesistenze T3 on T1.id_preesistenza = T3.id_preesistenza_xpert left outer join GENERALI_polizza_partite T4 on T4.id_partita_xpert = T2.id_partitaPeriziaOnline WHERE T1.id_sinistro = '${this.props.IdSinistro}'`);
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryPreesistenze, '', retDataPrees => {
                    retDataPrees = JSON.parse(retDataPrees.data);
                    var idPreesistenze = []
                    let funcListPreesistenze = [];

                    retDataPrees.forEach((dataPrees, idxDataPrees) => {
                        this.strutturaStatiPreesistenzeBase.forEach(prees => {
                            if (document.getElementById(`tabellaCompletaPreesistenza-T${prees.t}`)) {
                                if (dataPrees.id_partitaPeriziaOnline === document.getElementById(`idPartitaPO-T${prees.t}`).ej2_instances[0].value) {
                                    //Tabella preesistenza trovata
                                    const eseguiPrees = () => {
                                        return new Promise((resolvePreesistenza) => {
                                            if (document.getElementById(`formaGaranzia${prees.t}`).ej2_instances[0].value === '2') {
                                                var isPreesistenzaCaricata = false;
                                                idPreesistenze.forEach((id) => {
                                                    if (id === dataPrees.id_partitaPeriziaOnline)
                                                        isPreesistenzaCaricata = true;
                                                })
                                                if (isPreesistenzaCaricata === false) {
                                                    //Utile per distinguere le tabelle fabbricato (3 totali)
                                                    var tipoTabella = document.getElementById(`nomePartita${prees.t}`).ej2_instances[0].value;
                                                    var arrayIdPrees = [{ TEXT: dataPrees.id_partitaPeriziaOnline, VALUE: dataPrees.id_partitaPeriziaOnline, GUID_PARTITA_GEN: dataPrees.guid_partita_gen }]
                                                    document.getElementById(`idPartitaPO-T${prees.t}`).ej2_instances[0].dataSource = arrayIdPrees;
                                                    document.getElementById(`idPartitaPO-T${prees.t}`).ej2_instances[0].value = dataPrees.id_partitaPeriziaOnline;

                                                    document.getElementById(`sommaAssicurataInput_T${prees.t}`).ej2_instances[0].value = dataPrees.somma_assicurata;
                                                    document.getElementById(`derogaInput_T${prees.t}`).ej2_instances[0].value = dataPrees.deroga
                                                    document.getElementById(`valoreDerogatoResult_T${prees.t}`).ej2_instances[0].value = dataPrees.valore_derogato
                                                    document.getElementById(`valoreTotEsistNuovoResult_T${prees.t}`).ej2_instances[0].value = dataPrees.totale_esistenza_nuovo
                                                    document.getElementById(`valoreScoperturaResult_T${prees.t}`).ej2_instances[0].value = dataPrees.scopertura_tot_esist_nuovo
                                                    document.getElementById(`valoreDegradoInput_T${prees.t}`).ej2_instances[0].value = dataPrees.degrado_stato_uso
                                                    document.getElementById(`valoreTotEsistStatoUsoInput_T${prees.t}`).ej2_instances[0].value = dataPrees.totale_esistenza_stato_uso
                                                    document.getElementById(`valoreScoperturaStatoUso_T${prees.t}`).ej2_instances[0].value = dataPrees.scopertura_stato_uso
                                                    document.getElementById(`insuffAssicurativaPerc_T${prees.t}`).ej2_instances[0].value = dataPrees.insufficienza_assicurativa_perc
                                                    document.getElementById(`messaggioNote-T${prees.t}`).ej2_instances[0].value = dataPrees.noteInsuffAss
                                                    document.getElementById(`notePreesistenza-T${prees.t}`).ej2_instances[0].value = dataPrees.notePreesistenza


                                                    //la struttura degli stati preesistenza vine creato sempre nuovo nella creaTabelle
                                                    this.strutturaStatiPreesistenzeBase.forEach(elm => {
                                                        if (elm.id === `tabellaCompletaPreesistenza-T${prees.t}`) {
                                                            elm.stato = 'o';
                                                            elm.stato_iniziale = 'o';
                                                            elm.keyXpert = dataPrees.id_preesistenza;
                                                            elm.keyGen = dataPrees.id_preesistenza_gen
                                                        }
                                                    });
                                                    //Per ogni preesistenza esistente cerco i record delle righe nella _preesistenza_costruzioni associati alla GENERALI_partita_preesistenze e nella _preesistenza_descrizioni associate solo alla _preesistenza_xpert
                                                    //--------------COSTRUZIONI--------------
                                                    const queryPreesCostruzioni = (`SELECT  T1.*,T2.id_preesistenza as id_preesistenza_gen FROM _preesistenza_costruzioni T1 left outer join GENERALI_partita_preesistenze T2 on  T2.id_prees_costruz_xpert = T1.id_preesistenza_costruzione WHERE T1.id_preesistenza = ${dataPrees.id_preesistenza}`);
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryPreesCostruzioni, '', async (retDataCostruz) => {
                                                        retDataCostruz = JSON.parse(retDataCostruz.data);
                                                        if (retDataCostruz.length > 0) {
                                                            retDataCostruz.forEach(async (dataCostruzione, idxCostruz) => {
                                                                if (idxDataPrees === 0 && idxCostruz === 0)
                                                                    await this.aggiungiRigaCostruzionePreesistenza(prees.t, dataCostruzione, true);
                                                                else
                                                                    await this.aggiungiRigaCostruzionePreesistenza(prees.t, dataCostruzione, false);
                                                            })
                                                        }
                                                        else {
                                                            //Solo la tabella preesistenza fabbricato (TP016) ha le righe delle costruzioni collegate a Generali
                                                            if (tipoTabella === 'TP016' || tipoTabella === '1')
                                                                //Aggiungo una riga (default della tabella fabbricato)
                                                                if (idxDataPrees === 0)
                                                                    await this.aggiungiRigaCostruzionePreesistenza(prees.t, '', true);
                                                                else
                                                                    await this.aggiungiRigaCostruzionePreesistenza(prees.t, '', false);
                                                        }
                                                        //--------------DESCRIZIONI--------------
                                                        const queryPreesDescrizioni = (`SELECT * FROM _preesistenza_descrizioni WHERE id_preesistenza = '${dataPrees.id_preesistenza}';`);
                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryPreesDescrizioni, '', async (retDataDescr) => {
                                                            retDataDescr = JSON.parse(retDataDescr.data);
                                                            if (retDataDescr.length > 0) {
                                                                retDataDescr.forEach(async (dataDescr) => {
                                                                    await this.aggiungiRigaDescrizionePreesistenza(prees.t, dataDescr, '');
                                                                })
                                                            }
                                                            else {
                                                                if (tipoTabella === 'TP016' || tipoTabella === '1') {
                                                                    await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', '');
                                                                }
                                                                //tabella effetti domestici ( 3 righe di descrizioni)
                                                                else if (tipoTabella === 'TP005' || tipoTabella === 'TP014' || tipoTabella === 'TP015' || tipoTabella === '2') {
                                                                    var arrayEffettiDomestici = ['Elettrodomestici', 'Oggetti Personali', 'Gioielli'];
                                                                    for (let index = 0; index < arrayEffettiDomestici.length; index++) {
                                                                        await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', arrayEffettiDomestici[index]);
                                                                    }
                                                                }
                                                                else {
                                                                    await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', '');
                                                                }
                                                            }
                                                            idPreesistenze.push(dataPrees.id_partitaPeriziaOnline)
                                                            resolvePreesistenza('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        });
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    });
                                                }
                                                else {
                                                    idPreesistenze.push(dataPrees.id_partitaPeriziaOnline)
                                                    resolvePreesistenza('Prossima')
                                                }
                                            }
                                            else {
                                                resolvePreesistenza('Prossima')
                                            }
                                        })
                                    }
                                    funcListPreesistenze.push(eseguiPrees);
                                }
                            }
                        })
                    });

                    const eseguiCiclica = (ind) => {
                        if (CustomJS.isNullEmpty(funcListPreesistenze[ind])) {
                            //Se non ci sono record nelle preesistenze genero le righe costruzioni e descrizioni iniziali di ogni tipo di preesistenza
                            let funcListPreesistenze = [];
                            this.strutturaStatiPreesistenzeBase.forEach(prees => {
                                const eseguiPrees = () => {
                                    return new Promise(async (resolvePreesistenza) => {
                                        if (prees.stato === 'n') {
                                            if (document.getElementById(`tabellaCompletaPreesistenza-T${prees.t}`)) {
                                                //Utile per distinguere le tabelle fabbricato (3 totali)
                                                var tipoTabella = document.getElementById(`nomePartita${prees.t}`).ej2_instances[0].value;
                                                var formaGaranzia = document.getElementById(`formaGaranzia${prees.t}`).ej2_instances[0].value;
                                                if (tipoTabella === 'TP016' && formaGaranzia !== '1' ||
                                                    //Xpert
                                                    tipoTabella === '1' && formaGaranzia !== '1') {
                                                    //Aggiungo una riga costruzione e una descrizione (default della tabella fabbricato)
                                                    if (prees.t === 1)
                                                        await this.aggiungiRigaCostruzionePreesistenza(prees.t, '', true);
                                                    else
                                                        await this.aggiungiRigaCostruzionePreesistenza(prees.t, '', false);

                                                    await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', '');
                                                }
                                                else if (tipoTabella === 'TP005' && formaGaranzia !== '1'
                                                    || tipoTabella === 'TP014' && formaGaranzia !== '1' ||
                                                    tipoTabella === 'TP015' && formaGaranzia !== '1' ||
                                                    //Xpert
                                                    tipoTabella === '2' && formaGaranzia !== '1') {
                                                    var arrayEffettiDomestici = ['Elettrodomestici', 'Oggetti Personali', 'Gioielli'];
                                                    for (let index = 0; index < arrayEffettiDomestici.length; index++) {
                                                        await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', arrayEffettiDomestici[index]);
                                                    }
                                                }
                                                else {
                                                    if (formaGaranzia !== '1')
                                                        await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', '');
                                                }
                                                resolvePreesistenza('Prossima')
                                            }
                                            else {
                                                resolvePreesistenza('Prossima')
                                            }
                                        }
                                        else {
                                            resolvePreesistenza('Prossima')
                                        }
                                    })
                                }
                                funcListPreesistenze.push(eseguiPrees);
                            })
                            const eseguiCiclica = (ind) => {
                                if (CustomJS.isNullEmpty(funcListPreesistenze[ind])) {
                                    resolveFinePreesistenze();
                                }
                                else {
                                    funcListPreesistenze[ind]().then(ret => {
                                        if (ret === 'Prossima') {
                                            eseguiCiclica(ind + 1);
                                        }
                                    }).catch(err => {
                                        console.log(err)
                                    })
                                }
                            }
                            eseguiCiclica(0);
                        }
                        else {
                            funcListPreesistenze[ind]().then(ret => {
                                if (ret === 'Prossima') {
                                    eseguiCiclica(ind + 1);
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                        }
                    }
                    eseguiCiclica(0);

                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                });
            });
        }
        await finePreesistenze();

        const fineCaricamentoStime = () => {
            return new Promise((resolveFineStime) => {
                //CARICA STIME
                const queryStime = (`SELECT T3.id_causale,T3.codice as codiceCausale, T2.id_danno,T1.* FROM _stime_xpert T1 left outer join GENERALI_danni T2 on T1.id_stima = T2.id_stima_xpert left outer join GENERALI_danno_causali_nonIndennizzo T3 on T2.id_danno = T3.id_danno WHERE T1.id_sinistro = '${this.props.IdSinistro}'`);
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryStime, '', retDataStime => {
                    retDataStime = JSON.parse(retDataStime.data);
                    let funcListStime = [];
                    retDataStime.forEach(dataStima => {
                        this.strutturaStatiStimeBase.forEach(stima => {
                            if (document.getElementById(`tabellaStima-D${stima.d}`)) {
                                if (document.getElementById(`idStima-D${stima.d}`).ej2_instances[0].value === dataStima.id_stima) {
                                    //stima trovata
                                    const eseguiStima = () => {
                                        return new Promise((resolveStima) => {

                                            //Campi comuni ai due tipi di Stima esistenti
                                            document.getElementById(`limiteSinistro_D${stima.d}`).ej2_instances[0].value = dataStima.limite_sinistro
                                            document.getElementById(`piano-D${stima.d}`).ej2_instances[0].value = dataStima.piano;
                                            if (dataStima.franchigiaSalvata === true || dataStima.franchigiaSalvata === 1) {
                                                this.periziaModificata = true; //se il campo è true allora la perizia è stata salvata almeno una volta
                                                document.getElementById(`franchigia_D${stima.d}`).ej2_instances[0].value = dataStima.franchigiaOScoperto
                                                if (!CustomJS.isNullEmpty(dataStima.franchigiaOScopertoMAN) && dataStima.franchigiaOScopertoMAN !== 0) {
                                                    document.getElementById(`btnFranchigiaManuale_D${stima.d}`).click();
                                                    document.getElementById(`franchigiaMAN_D${stima.d}`).ej2_instances[0].value = dataStima.franchigiaOScopertoMAN;
                                                }
                                            }
                                            document.getElementById(`ripristiniUltimSi-D${stima.d}`).ej2_instances[0].checked = dataStima.ripristini_ultimati === true ? true : false;
                                            document.getElementById(`ripristiniUltimNo-D${stima.d}`).ej2_instances[0].checked = dataStima.ripristini_ultimati === false ? true : false;
                                            document.getElementById(`accordoAniaSi-D${stima.d}`).ej2_instances[0].checked = dataStima.accordo_ania === true ? true : false
                                            document.getElementById(`accordoAniaNo-D${stima.d}`).ej2_instances[0].checked = dataStima.accordo_ania === false ? true : false
                                            document.getElementById(`noteRipristini-D${stima.d}`).ej2_instances[0].value = dataStima.note_ripristini_ultimati;
                                            document.getElementById(`esitoDanno_D${stima.d}`).ej2_instances[0].value = dataStima.esito_danno;
                                            if (!CustomJS.isNullEmpty(dataStima.id_causale) && dataStima.id_causale !== 0) {
                                                document.getElementById(`boxCausaliNonIndennizzo_D${stima.d}`).style.visibility = 'visible'
                                                document.getElementById(`causaliNonIndennizzo_D${stima.d}`).ej2_instances[0].value = dataStima.codiceCausale;
                                                this.strutturaStatiCausali.push({ idDanno: dataStima.id_danno, idCausale: dataStima.id_causale })
                                            }

                                            if (dataStima.limiteIndennizzo !== -1 && !CustomJS.isNullEmpty(dataStima.limiteIndennizzo)) {
                                                document.getElementById(`ceLimiteIndennizzoONo-D${stima.d}`).style.removeProperty('display');
                                                document.getElementById(`limiteIndennizzo-D${stima.d}`).ej2_instances[0].value = dataStima.limiteIndennizzo
                                            }
                                            //Tabella Valore a Nuovo
                                            if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '001') {
                                                document.getElementById(`dannoComplessAccert_D${stima.d}`).ej2_instances[0].value = dataStima.danno_compless_accertato
                                                if (!CustomJS.isNullEmpty(dataStima.dopo_regola_proporzionaleMAN) && dataStima.dopo_regola_proporzionaleMAN !== 0) {
                                                    document.getElementById(`btnDopoRegolaMAN_D${stima.d}`).click();
                                                    document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value = dataStima.dopo_regola_proporzionaleMAN;
                                                }
                                                document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value = dataStima.dopo_regola_proporzionale
                                                document.getElementById(`dannoNetto_D${stima.d}`).ej2_instances[0].value = dataStima.danno_netto
                                            }
                                            //Tabella Stato Uso
                                            else if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {
                                                var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${stima.d}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${stima.d}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${stima.d}`).ej2_instances[0].value;
                                                franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                                                document.getElementById(`franchigiaValoreANuovo_D${stima.d}`).ej2_instances[0].value = franchigia;

                                                document.getElementById(`valoreANuovoDannComplessAcc_D${stima.d}`).ej2_instances[0].value = dataStima.DCA_valore_a_nuovo
                                                document.getElementById(`degradoPercDannComplessAcc_D${stima.d}`).ej2_instances[0].value = dataStima.DCA_degrado25
                                                document.getElementById(`valoreBeneDannComplessAcc_D${stima.d}`).ej2_instances[0].value = dataStima.DCA_valore_stato_uso

                                                if (!CustomJS.isNullEmpty(dataStima.DRP_valore_a_nuovoMAN) && dataStima.DRP_valore_a_nuovoMAN !== 0) {
                                                    document.getElementById(`btnValANuovoDoporegolaMAN_D${stima.d}`).click();
                                                    document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value = dataStima.DRP_valore_a_nuovoMAN;
                                                }
                                                document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}`).ej2_instances[0].value = dataStima.DRP_valore_a_nuovo

                                                if (!CustomJS.isNullEmpty(dataStima.DRP_degrado25MAN) && dataStima.DRP_degrado25MAN !== 0) {
                                                    document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${stima.d}`).click();
                                                    document.getElementById(`degrado25PercDopoRegolaPropMAN_D${stima.d}`).ej2_instances[0].value = dataStima.DRP_degrado25MAN;
                                                }
                                                document.getElementById(`degrado25PercDopoRegolaProp_D${stima.d}`).ej2_instances[0].value = dataStima.DRP_degrado25

                                                if (!CustomJS.isNullEmpty(dataStima.DRP_valore_stato_usoMAN) && dataStima.DRP_valore_stato_usoMAN !== 0) {
                                                    document.getElementById(`btnDopoRegolaMAN_D${stima.d}`).click();
                                                    document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}`).ej2_instances[0].value = dataStima.DRP_valore_stato_usoMAN;
                                                }
                                                document.getElementById(`dopoRegolaProporzionale_D${stima.d}`).ej2_instances[0].value = dataStima.DRP_valore_stato_uso

                                                document.getElementById(`valoreANuovodannoNetto_D${stima.d}`).ej2_instances[0].value = dataStima.DN_valore_a_nuovo
                                                document.getElementById(`degrado25PercdannoNetto_D${stima.d}`).ej2_instances[0].value = dataStima.DN_degrado25
                                                document.getElementById(`valoreStatoUsodannoNetto_D${stima.d}`).ej2_instances[0].value = dataStima.DN_valore_stato_uso
                                            }
                                            //-------------DESCRIZIONI PREZZIARIO------------
                                            const queryStimeDescr = (`SELECT T2.id_dettaglio,T3.id_info_richiesta, T1.* FROM _stime_descrizioni T1 left outer join GENERALI_danno_dettagli T2 on T1.id_stima_descrizione = T2.id_stima_descrizione left outer join GENERALI_dannoDettagli_infoRichieste T3 on T2.id_dettaglio = T3.id_dettaglio where T1.id_stima = ${dataStima.id_stima}`);
                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryStimeDescr, '', async (retDataStimeDescr) => {
                                                retDataStimeDescr = JSON.parse(retDataStimeDescr.data);

                                                if (retDataStimeDescr.length > 0) {
                                                    //CARICO DESCRIZIONI
                                                    retDataStimeDescr.forEach(async (prezz) => {
                                                        if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '001') {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaValoreANuovo', '', prezz)
                                                        }
                                                        else if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaStatoUso', '', prezz)
                                                        }
                                                    })
                                                }
                                                else {
                                                    //CARICO UNA RIGA DESCRIZIONE PER OGNI TABELLA
                                                    if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '001') {
                                                        //acqua condotta
                                                        if (document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === 'TG001' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '2' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '3' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '14' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '48' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '71' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '72' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '74'
                                                        ) {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaValoreANuovo', '00003', '')
                                                        }
                                                        //ricerca guasto
                                                        else if (document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === 'TG019' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === 'TG020' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === 'TG021' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '70' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '73' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '74') {
                                                            var ricercaGuasto = ['00036', '00030']
                                                            for (var i = 0; i < ricercaGuasto.length; i++) {
                                                                await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaValoreANuovo', ricercaGuasto[i], '')
                                                            }
                                                        }
                                                        //altre
                                                        else {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaValoreANuovo', '', '')
                                                        }
                                                    }
                                                    else if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {
                                                        //acqua condotta
                                                        if (document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === 'TG001' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '2' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '3' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '14' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '48' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '71' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '72' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '74') {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaStatoUso', '00003', '')
                                                        }
                                                        //ricerca guasto
                                                        else if (document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === 'TG019' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === 'TG020' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === 'TG021' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '70' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '73' || document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value === '74') {
                                                            var ricercaGuasto = ['00036', '00030']
                                                            for (var i = 0; i < ricercaGuasto.length; i++) {
                                                                await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaStatoUso', ricercaGuasto[i], '')
                                                            }
                                                        }
                                                        //altre
                                                        else {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaStatoUso', '', '')
                                                        }
                                                    }
                                                }
                                                //-----------COASSICURAZIONI------------
                                                const queryCoass = (`SELECT T1.*, T2.id_coassi_indiretta as id_coass_gen FROM _righeCompagnieCoassicurazione T1 left outer join GENERALI_coassi_indirette T2 on T1.id_compagnia_coass = T2.id_compagnia_coass_xpert where T1.id_stima = ${dataStima.id_stima}`);
                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryCoass, '', retDataCoassVecchio => {
                                                    retDataCoassVecchio = JSON.parse(retDataCoassVecchio.data);
                                                    var retDataCoass = [];
                                                    retDataCoassVecchio.forEach(elm => {
                                                        //compagnia Generali
                                                        if (elm.compagniaCoinvolta === '5')
                                                            retDataCoass.unshift(elm)
                                                        else
                                                            retDataCoass.push(elm)
                                                    })
                                                    if (retDataCoass.length > 0) {
                                                        const startTimeC = performance.now();
                                                        document.getElementById(`coassicurazioneSi-D${stima.d}`).ej2_instances[0].checked = true;
                                                        document.getElementById(`accordionCoassicurazione-D${stima.d}`).style.removeProperty('display');
                                                        document.getElementById(`accordionCoassicurazione-D${stima.d}`).ej2_instances[0].expandItem(true, 0);
                                                        const endTimeC = performance.now();
                                                        const totalTimeC = endTimeC - startTimeC;
                                                        setTimeout(async () => {
                                                            for (let c = 0; c < retDataCoass.length; c++) {
                                                                await this.aggiungiRigaCompagniaCoassicurazioneRiparto1(stima.d, retDataCoass[c], '');
                                                            }
                                                            //inserisco i dati coassicurazioni contenuti nella tabella stime_xpert
                                                            document.getElementById(`sommaDanniRiparto1_D${stima.d}`).ej2_instances[0].value = dataStima.sommaDanniRiparto1
                                                            document.getElementById(`sommaDanniRiparto2_D${stima.d}`).ej2_instances[0].value = dataStima.sommaDanniRiparto2
                                                            document.getElementById(`indennizzoVsCompagnia_D${stima.d}`).ej2_instances[0].value = dataStima.indennizzoACaricoVsComp
                                                            if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003') {
                                                                document.getElementById(`supplIndennitaCoass-D${stima.d}`).ej2_instances[0].value = dataStima.suppl_indenn_coass
                                                                document.getElementById(`vsuCoass-D${stima.d}`).ej2_instances[0].value = dataStima.vsu_coass
                                                            }
                                                            document.getElementById(`noteCoassicurazione-D${stima.d}`).ej2_instances[0].value = dataStima.descrizione_coass;
                                                            setTimeout(() => {
                                                                this.calcolaCoassicurazioneCompleta(stima.d, true);
                                                            }, 200)
                                                        }, totalTimeC)
                                                    }
                                                    else {
                                                        document.getElementById(`coassicurazioneNo-D${stima.d}`).ej2_instances[0].checked = true;
                                                    }

                                                    //---------INFORMAZIONI RICHIESTE DALLA COMPAGNIA GENERALI---------
                                                    if (this.isCompagniaGenerali()) {
                                                        const queryInfoRichieste = (`SELECT T1.*,T2.* FROM _info_richieste_compagnia_riep T1 left outer join GENERALI_danno_infoRichieste T2 on T1.id_info_richieste_compagnia = T2.id_info_rich_xpert where T1.id_stima = ${dataStima.id_stima}`);
                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryInfoRichieste, '', retDataInfoRich => {
                                                            retDataInfoRich = JSON.parse(retDataInfoRich.data);

                                                            for (let index = 0; index < retDataInfoRich.length; index++) {
                                                                this.strutturaStatiInfoRichiesteDelete.push({ keyXpert: retDataInfoRich[index].id_info_richieste_compagnia, keyGen: retDataInfoRich[index].id_info_richiesta })
                                                            }

                                                            var garanziaCodEle = document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value;
                                                            var garanziaSTR2 = document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].itemData.SERVIZIO_STR2;

                                                            var dannNome = document.getElementById(`danneggiatoFromSin-D${stima.d}`).ej2_instances[0].text;
                                                            var partNome = document.getElementById(`partitaFromSin-D${stima.d}`).ej2_instances[0].text;
                                                            var garNome = document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].text;
                                                            var garCodEle = document.getElementById(`garanziaFromSin-D${stima.d}`).ej2_instances[0].value;

                                                            this.nuovaCreaInfoRichieste(dannNome, partNome, garNome, garCodEle, dataStima.id_stima, dataStima.id_danno, stima.d, garanziaCodEle, garanziaSTR2, retDataInfoRich);
                                                            resolveStima('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        });
                                                    }
                                                    else {
                                                        resolveStima('Prossima')
                                                    }
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                });
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            });
                                        })
                                    }
                                    funcListStime.push(eseguiStima);
                                }
                            }
                        })
                    })
                    const eseguiCiclica = (ind) => {
                        if (CustomJS.isNullEmpty(funcListStime[ind])) {
                            resolveFineStime();
                        }
                        else {
                            funcListStime[ind]().then(ret => {
                                if (ret === 'Prossima') {
                                    eseguiCiclica(ind + 1);
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                        }
                    }
                    eseguiCiclica(0);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                });
            });
        }

        await fineCaricamentoStime();

        //PRENDO DATI RIEPILOGO
        const queryRiep0 = (`SELECT * FROM _riepilogo_perizia where id_sinistro = ${this.props.IdSinistro};`);
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryRiep0, '', retDataRiep0 => {
            retDataRiep0 = JSON.parse(retDataRiep0.data);
            if (retDataRiep0.length > 0) {
                this.strutturaRiepilogoDelete.IdRiepilogo = retDataRiep0[0].idRiepilogo_perizia;
                if (!CustomJS.isNullEmpty(retDataRiep0[0].finali_indenn_netti_arrotond_MAN) && retDataRiep0[0].finali_indenn_netti_arrotond_MAN !== 0) {
                    document.getElementById(`sbloccaTotaleIndennNettoComplApprossRiep`).click();
                    document.getElementById(`totaleIndennNettoComplApprossRiepMAN`).ej2_instances[0].value = retDataRiep0[0].finali_indenn_netti_arrotond_MAN;
                }
                const queryRiepDanneggiati = (`SELECT idDanneggiatoRiep FROM _perizia_riepilogo_danneggiati where id_sinistro = ${this.props.IdSinistro};`);
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryRiepDanneggiati, '', retDataDann => {
                    retDataDann = JSON.parse(retDataDann.data);
                    for (let d = 0; d < retDataDann.length; d++) {
                        this.strutturaRiepilogoDelete.IdDanneggiati.push(retDataDann[d].idDanneggiatoRiep)
                    }
                    const queryRiepRighe = (`SELECT idGarPart FROM _perizia_riep_righeDanneggiato where id_sinistro = ${this.props.IdSinistro};`);
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', queryRiepRighe, '', retDataRighe => {
                        retDataRighe = JSON.parse(retDataRighe.data);
                        for (let r = 0; r < retDataRighe.length; r++) {
                            this.strutturaRiepilogoDelete.IdRighe.push(retDataRighe[r].idGarPart);
                        }
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    });
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                });
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });

        //RISERVA E DEFINIZIONE
        const riservaSiNo = (`SELECT T3.contraente,T3.nome,T3.cognome,T1.Ris_note,T1.servizio_eseguito,T1.D_note FROM _perizia_incarico_info T1 left outer join acsinistri T2 on T1.id_sinistro = T2.id_sinistro left outer join accontraenti T3 on T2.id_contraente = T3.id_contraente where T1.id_sinistro = ${this.props.IdSinistro};`);
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', riservaSiNo, '', retDataRis => {
            retDataRis = JSON.parse(retDataRis.data);
            //Carico Riserva
            if (CustomJS.isNullEmpty(retDataRis[0].Ris_note))
                this.riservaCe = false;
            else
                this.riservaCe = true;

            if (CustomJS.isNullEmpty(retDataRis[0].contraente))
                this.contraenteRiserva = retDataRis[0].nome + " " + retDataRis[0].cognome;
            else
                this.contraenteRiserva = retDataRis[0].contraente;

            this.dsTipoServizio.forEach(elm => {
                if (elm.VALUE === retDataRis[0].servizio_eseguito)
                    this.tipoServizio = elm.TEXT.toLowerCase().trim();
            })
            //Carico definizione
            document.getElementById('noteDefinizione').ej2_instances[0].value = retDataRis[0].D_note;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });

        //carico franchige condivise
        const franchigeCondivise = (`SELECT * from _franchige_condivise_perizia where id_sinistro = ${this.props.IdSinistro};`);
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', franchigeCondivise, '', retDataFranc => {
            retDataFranc = JSON.parse(retDataFranc.data);
            for (let index = 0; index < retDataFranc.length; index++) {
                this.strutturaStatiStimeBase.forEach(stima => {
                    if (document.getElementById(`tabellaStima-D${stima.d}`)) {
                        if (document.getElementById(`idStima-D${stima.d}`).ej2_instances[0].value === retDataFranc[index].idStimaCondivisa) {
                            document.getElementById(`franchigiaOscopertura-D${stima.d}`).innerHTML = retDataFranc[index].tipo.charAt(0).toUpperCase() + retDataFranc[index].tipo.slice(1) + " :";
                        }
                    }
                })
            }

            //FRANCHIGE
            this.franchigeCondivise = []
            retDataFranc.forEach(dato => {
                if (dato.tipo === 'franchigia')
                    this.franchigeCondivise.push(dato)
            })
            this.franchigeCondivise = _.groupBy(this.franchigeCondivise, 'gruppo');


            //SCOPERTI
            this.scopertiCondivisi = []
            retDataFranc.forEach(dato => {
                if (dato.tipo === 'scoperto')
                    this.scopertiCondivisi.push(dato)
            })
            this.scopertiCondivisi = _.groupBy(this.scopertiCondivisi, 'gruppo');
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });

        //AGGIORNO TUTTI I CAMPI
        this.strutturaStatiPreesistenzeBase.forEach(prees => {
            document.getElementById(`aggiornaPreesistenza-T${prees.t}`).click();
        })
        this.strutturaStatiStimeBase.forEach(stima => {
            if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '001')
                document.getElementById(`aggiornaTabellaStimaValoreANuovo-D${stima.d}`).click();
            else if (document.getElementById(`determinazioneDanno_D${stima.d}`).ej2_instances[0].value === '003')
                document.getElementById(`aggiornaTabellaStimaStatoUso-D${stima.d}`).click();
        })



        //CALCOLO RIEPILOGO
        setTimeout(() => {
            setTimeout(() => {
                //se l' id riepilogo è vuoto ma la perizia contiene almente una stima 
                if (CustomJS.isNullEmpty(this.strutturaRiepilogoDelete.IdRiepilogo) && this.periziaModificata === true) {
                    document.getElementById('avvisoPeriziaModificata').innerText = 'PERIZIA MODIFICATA! Si ricorda di salvare i calcoli!'
                }
                document.getElementById('dettSinPag2').ej2_instances[0].disabled = false;
                document.getElementById('elabPeritalePag2').ej2_instances[0].disabled = false;
                document.getElementById('salvaEsciP2').ej2_instances[0].disabled = false;
                //controllo se il sinistro è chiuso
                if (this.props.restituito === true) {
                    this.sinistroChiuso()
                }
                this.toggleBlocking();
            }, 600)
            this.trasportaDatiRiepilogo()
        }, 400)
    }


    async salvaDati() {
        this.toggleBlocking();

        var tempoOne = 75;
        var tempoOneLong = 150;
        var tempoPreesistenze = (tempoOne * this.strutturaStatiPreesistenzeBase.length) + (tempoOne * this.strutturaStatiPreesistenzeCostruzioni.length) + (tempoOne * this.strutturaStatiPreesistenzeDescrizioni.length);
        var tempoStime = ((tempoOne * this.strutturaStatiStimeBase.length) * 2) + (tempoOne * this.strutturaStatiStimePrezziari.length) + (tempoOne * this.strutturaStatiCoassicurazioniRighe.length);


        var tempoRiepilogo =
            //tempo delete 
            (tempoOne * this.strutturaRiepilogoDelete.IdDanneggiati.length) + (tempoOne * this.strutturaRiepilogoDelete.IdRighe.length) +
            //tempo insert 
            (tempoOneLong + (tempoOneLong * this.numTabelleRiepilogo - 1) + (tempoOneLong * this.numRigheRiepilogoGaranzie - 1)) +
            tempoOne * 2 //unipol e generali update su dati dal riepilogo

        var tempoDefinizione = tempoOne * 3;
        var tempoInfoRichieste = 0;
        if (this.isCompagniaGenerali()) {
            var categoriePerOgniStimaIdea = tempoOne * 5; //idea base con un elemento
            tempoInfoRichieste = (categoriePerOgniStimaIdea * this.strutturaStatiStimeBase.length)
        }
        var tempoTotale = tempoPreesistenze + tempoStime + tempoRiepilogo + tempoDefinizione + tempoInfoRichieste;

        //-----------------------INIZIO QUERY PREESISTENZE-----------------------

        console.log('inizio preesistenze')
        const finePreesistenze = () => {
            return new Promise((resolveFinePreesistenze) => {
                let funcListPreesistenze = [];
                this.strutturaStatiPreesistenzeBase.forEach((elm) => {
                    const eseguiPreesistenza = () => {
                        return new Promise(async (resolvePreesistenza) => {

                            var guidPreesGenerata = CustomJS.createGuid4()
                            var tipoPartita = document.getElementById(`nomePartita${elm.t}`).ej2_instances[0].value;

                            var preesistenzaXpert = () => {
                                return new Promise((resolvePreesXpert) => {
                                    //utile per la GENERALI_partita_preesistenze
                                    var queryPreesistenzaXpert = '';
                                    //La query per la preesistenza generali nella preesistenza costruzioni
                                    if (elm.stato === 'n') {
                                        queryPreesistenzaXpert = `START TRANSACTION;INSERT INTO _preesistenza_xpert (id_sinistro,id_partitaPeriziaOnline, somma_assicurata,deroga,valore_derogato,totale_esistenza_nuovo,scopertura_tot_esist_nuovo,degrado_stato_uso,totale_esistenza_stato_uso,scopertura_stato_uso,insufficienza_assicurativa_perc,noteInsuffAss,notePreesistenza,guid_utile) VALUES (@idSinistro,@idPartita,@sommaAssicurata,@deroga,@valoreDerogato,@totaleEsistenzaNuovo,@scoperturaTotEsistenzaNuovo,@degradoStatoUso,@totaleEsistenzaStatoUso,@scoperturaStatoUso,@insufficienzaAssicurativaPerc,@notePres,@notePres2,'${guidPreesGenerata}');update _partitePeriziaOnline set sommaAssicurataPartita = @sommaAssicurata where id_partitaPeriziaOnline = ${elm.keyPartita};COMMIT;`;
                                    }
                                    else if (elm.stato === 'o') {
                                        queryPreesistenzaXpert = `START TRANSACTION;UPDATE _preesistenza_xpert SET somma_assicurata = @sommaAssicurata, deroga = @deroga, valore_derogato = @valoreDerogato, totale_esistenza_nuovo = @totaleEsistenzaNuovo, scopertura_tot_esist_nuovo = @scoperturaTotEsistenzaNuovo, degrado_stato_uso = @degradoStatoUso, totale_esistenza_stato_uso = @totaleEsistenzaStatoUso, scopertura_stato_uso = @scoperturaStatoUso,insufficienza_assicurativa_perc = @insufficienzaAssicurativaPerc, noteInsuffAss = @notePres, notePreesistenza = @notePres2 WHERE id_preesistenza = ${elm.keyXpert};update _partitePeriziaOnline set sommaAssicurataPartita = @sommaAssicurata where id_partitaPeriziaOnline = ${elm.keyPartita};COMMIT;`;
                                    }
                                    //LA DELETE (STATO 'D') SI SVOLGE NELLA PAGINA PRECEDENTE DELLA PERIZIA ONLINE!
                                    var jsonObjectPreesXPert = {
                                        QueryExec: queryPreesistenzaXpert,
                                        idSinistro: this.props.IdSinistro.toString(),
                                        idPartita: CustomJS.isNullEmpty(document.getElementById(`idPartitaPO-T${elm.t}`).ej2_instances[0].value) ? '0' : document.getElementById(`idPartitaPO-T${elm.t}`).ej2_instances[0].value.toString(), // key della tabella _partitePeriziaOnline
                                        sommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`sommaAssicurataInput_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`sommaAssicurataInput_T${elm.t}`).ej2_instances[0].value).toString(),
                                        deroga: CustomJS.isNullEmpty(document.getElementById(`derogaInput_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`derogaInput_T${elm.t}`).ej2_instances[0].value).toString(),
                                        valoreDerogato: CustomJS.isNullEmpty(document.getElementById(`valoreDerogatoResult_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreDerogatoResult_T${elm.t}`).ej2_instances[0].value).toString(),
                                        totaleEsistenzaNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}`).ej2_instances[0].value).toString(),
                                        scoperturaTotEsistenzaNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreScoperturaResult_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreScoperturaResult_T${elm.t}`).ej2_instances[0].value).toString(),
                                        degradoStatoUso: CustomJS.isNullEmpty(document.getElementById(`valoreDegradoInput_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreDegradoInput_T${elm.t}`).ej2_instances[0].value).toString(),
                                        totaleEsistenzaStatoUso: CustomJS.isNullEmpty(document.getElementById(`valoreTotEsistStatoUsoInput_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreTotEsistStatoUsoInput_T${elm.t}`).ej2_instances[0].value).toString(),
                                        scoperturaStatoUso: CustomJS.isNullEmpty(document.getElementById(`valoreScoperturaStatoUso_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreScoperturaStatoUso_T${elm.t}`).ej2_instances[0].value).toString(),
                                        insufficienzaAssicurativaPerc: CustomJS.isNullEmpty(document.getElementById(`insuffAssicurativaPerc_T${elm.t}`).ej2_instances[0].value) ? '0' : document.getElementById(`insuffAssicurativaPerc_T${elm.t}`).ej2_instances[0].value.toString(),
                                        notePres: CustomJS.isNullEmpty(document.getElementById(`messaggioNote-T${elm.t}`).ej2_instances[0].value) ? '' : document.getElementById(`messaggioNote-T${elm.t}`).ej2_instances[0].value,
                                        notePres2: CustomJS.isNullEmpty(document.getElementById(`notePreesistenza-T${elm.t}`).ej2_instances[0].value) ? '' : document.getElementById(`notePreesistenza-T${elm.t}`).ej2_instances[0].value,
                                    };
                                    console.log(jsonObjectPreesXPert)
                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectPreesXPert), retDataPreesXpert => {
                                        resolvePreesXpert()
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                    });
                                });
                            }
                            await preesistenzaXpert();

                            //Se la partita è Fabbricato le query per Generali vengono inserite in base alle righe delle costruzioni
                            //-------------GESTIONE PREESISTENZA XPERT GENERALI PARTE 1-------------
                            if (tipoPartita === 'TP016' || tipoPartita === '1') {
                                const fineCostruzioni = () => {
                                    return new Promise((resolveFineCostruzioni) => {

                                        let funcListCostruzioni = [];
                                        this.strutturaStatiPreesistenzeCostruzioni.forEach((elm2) => {
                                            const eseguiCostruzione = () => {
                                                return new Promise(async (resolveCostruzione) => {
                                                    if (elm2.id === `rigaPreesistenzaCostruzione-T${elm.t}-R-${elm2.r}`) {

                                                        var guidCostruzioneGenerata = CustomJS.createGuid4()
                                                        //COSTRUZIONE XPERT
                                                        var crudCostruzioneXpert = () => {
                                                            return new Promise(async (resolveCrudXpert) => {
                                                                var queryPreesCostruzioniXpert = '';
                                                                var jsonObjectCostruzioneXpert = {}

                                                                if (elm2.stato === 'n' && elm.stato === 'n') {
                                                                    queryPreesCostruzioniXpert = `START TRANSACTION;INSERT INTO _preesistenza_costruzioni (id_preesistenza,id_sinistro, valore_unitario, superficie_equivalente,altezza,volume,valore_ricostruz_nuovo,guid_utile_costruzione) VALUES ((select id_preesistenza from _preesistenza_xpert where id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}' FOR UPDATE),@idSinistro,@valoreUnitarioCostruz,@superficieEquivalente,@altezza,@volume,@valoreRicostruzNuovo,'${guidCostruzioneGenerata}');COMMIT;`;
                                                                }
                                                                else if (elm2.stato === 'n' && elm.stato === 'o') {
                                                                    queryPreesCostruzioniXpert = `INSERT INTO _preesistenza_costruzioni (id_preesistenza,id_sinistro, valore_unitario, superficie_equivalente,altezza,volume,valore_ricostruz_nuovo,guid_utile_costruzione) VALUES (${elm.keyXpert},@idSinistro,@valoreUnitarioCostruz,@superficieEquivalente,@altezza,@volume,@valoreRicostruzNuovo,'${guidCostruzioneGenerata}');`;
                                                                }
                                                                else if (elm2.stato === 'o') {
                                                                    queryPreesCostruzioniXpert = `UPDATE _preesistenza_costruzioni SET valore_unitario = @valoreUnitarioCostruz, superficie_equivalente = @superficieEquivalente, altezza = @altezza,volume = @volume,valore_ricostruz_nuovo = @valoreRicostruzNuovo WHERE id_preesistenza_costruzione = ${elm2.keyXpert}`;
                                                                }
                                                                else if (elm2.stato === 'd') {
                                                                    queryPreesCostruzioniXpert = `DELETE FROM _preesistenza_costruzioni WHERE id_preesistenza_costruzione = ${elm2.keyXpert}`;
                                                                }
                                                                if (elm2.stato === 'd') {
                                                                    jsonObjectCostruzioneXpert = {
                                                                        QueryExec: queryPreesCostruzioniXpert
                                                                    }
                                                                }
                                                                else {
                                                                    jsonObjectCostruzioneXpert = {
                                                                        QueryExec: queryPreesCostruzioniXpert,
                                                                        idSinistro: this.props.IdSinistro.toString(),
                                                                        valoreUnitarioCostruz: CustomJS.isNullEmpty(document.getElementById(`valoreUnitarioInput_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreUnitarioInput_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value).toString(),
                                                                        superficieEquivalente: CustomJS.isNullEmpty(document.getElementById(`superficieEquivalenteInput_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`superficieEquivalenteInput_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value.toString(),
                                                                        altezza: CustomJS.isNullEmpty(document.getElementById(`altezzaInput_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`altezzaInput_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value.toString(),
                                                                        volume: CustomJS.isNullEmpty(document.getElementById(`volumeResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`volumeResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value.toString(),
                                                                        valoreRicostruzNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreRicostruzioneResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`valoreRicostruzioneResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value.toString(),
                                                                    };
                                                                }
                                                                console.log(jsonObjectCostruzioneXpert)
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCostruzioneXpert), retDataPreesCostruzXpert => {
                                                                    resolveCrudXpert()
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                            });
                                                        }
                                                        await crudCostruzioneXpert();

                                                        //COSTRUZIONI GENERALI 
                                                        if (this.isCompagniaGenerali()) {
                                                            var crudCostruzioneGEN = () => {
                                                                return new Promise(async (resolveCrudGEN) => {
                                                                    var queryCostruzGenerali = '';
                                                                    var jsonObjectCostruzGenerali = {}

                                                                    if (elm2.stato === 'n' && elm.stato === 'n') {
                                                                        queryCostruzGenerali = `START TRANSACTION; INSERT INTO GENERALI_partita_preesistenze (id_preesistenza_xpert,id_prees_costruz_xpert,id_partita,id_incarico,descrizione,valore_assicurato,deroga_percentuale,valore_riferimento,unita_misura,valore_unitario,quantita,totale_preesistenza) VALUES ((select id_preesistenza from _preesistenza_xpert where id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}' FOR UPDATE),(select id_preesistenza_costruzione from _preesistenza_costruzioni where id_sinistro = ${this.props.IdSinistro} and guid_utile_costruzione = '${guidCostruzioneGenerata}' FOR UPDATE),@guidPartita,'${this.props.IdIncarico.toString()}',@partitaDescr,@sommaAssicurata,@deroga,@valoreDerogato,@unitaMisura,@valoreUnitarioCostruz,@quantita,@totaleEsistenzaNuovo);COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'n' && elm.stato === 'o') {
                                                                        queryCostruzGenerali = `START TRANSACTION; INSERT INTO GENERALI_partita_preesistenze (id_preesistenza_xpert,id_prees_costruz_xpert,id_partita,id_incarico,descrizione,valore_assicurato,deroga_percentuale,valore_riferimento,unita_misura,valore_unitario,quantita,totale_preesistenza) VALUES (${elm.keyXpert},(select id_preesistenza_costruzione from _preesistenza_costruzioni where id_sinistro = ${this.props.IdSinistro} and guid_utile_costruzione = '${guidCostruzioneGenerata}' FOR UPDATE),@guidPartita,'${this.props.IdIncarico.toString()}',@partitaDescr,@sommaAssicurata,@deroga,@valoreDerogato,@unitaMisura,@valoreUnitarioCostruz,@quantita,@totaleEsistenzaNuovo);COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'o') {
                                                                        queryCostruzGenerali = `UPDATE GENERALI_partita_preesistenze SET valore_assicurato = @sommaAssicurata, deroga_percentuale = @deroga,valore_riferimento = @valoreDerogato, unita_misura = @unitaMisura,valore_unitario = @valoreUnitarioCostruz,quantita = @quantita, totale_preesistenza = @totaleEsistenzaNuovo WHERE id_preesistenza = ${elm2.keyGen}`
                                                                    }
                                                                    else if (elm2.stato === 'd') {
                                                                        queryCostruzGenerali = `DELETE FROM GENERALI_partita_preesistenze WHERE id_preesistenza = ${elm2.keyGen}`
                                                                    }
                                                                    if (elm2.stato === 'd') {
                                                                        jsonObjectCostruzGenerali = {
                                                                            QueryExec: queryCostruzGenerali
                                                                        }
                                                                    }
                                                                    else {
                                                                        jsonObjectCostruzGenerali = {
                                                                            QueryExec: queryCostruzGenerali,
                                                                            guidPartita: CustomJS.isNullEmpty(document.getElementById(`idPartitaPO-T${elm.t}`).ej2_instances[0].value) ? '' : document.getElementById(`idPartitaPO-T${elm.t}`).ej2_instances[0].itemData.GUID_PARTITA_GEN,
                                                                            partitaDescr: CustomJS.isNullEmpty(document.getElementById(`nomePartita${elm.t}`).ej2_instances[0].value) ? '' : document.getElementById(`nomePartita${elm.t}`).ej2_instances[0].text,
                                                                            sommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`sommaAssicurataInput_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`sommaAssicurataInput_T${elm.t}`).ej2_instances[0].value).toString(),
                                                                            deroga: CustomJS.isNullEmpty(document.getElementById(`derogaInput_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`derogaInput_T${elm.t}`).ej2_instances[0].value).toString(),
                                                                            valoreDerogato: CustomJS.isNullEmpty(document.getElementById(`valoreDerogatoResult_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreDerogatoResult_T${elm.t}`).ej2_instances[0].value).toString(),
                                                                            unitaMisura: 'nr',
                                                                            valoreUnitarioCostruz: CustomJS.isNullEmpty(document.getElementById(`valoreUnitarioInput_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreUnitarioInput_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value).toString(),
                                                                            quantita: CustomJS.isNullEmpty(document.getElementById(`volumeResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`volumeResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value).toString(),
                                                                            totaleEsistenzaNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}`).ej2_instances[0].value).toString(),
                                                                        };
                                                                    }
                                                                    console.log(jsonObjectCostruzGenerali)
                                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCostruzGenerali), retDataCostruzioneGen => {
                                                                        resolveCrudGEN()
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        }
                                                                        );
                                                                    });
                                                                });
                                                            }
                                                            await crudCostruzioneGEN();
                                                        }
                                                        resolveCostruzione('Prossima');
                                                    }
                                                    else {
                                                        resolveCostruzione('Prossima');
                                                    }
                                                })
                                            }
                                            funcListCostruzioni.push(eseguiCostruzione);
                                        })
                                        const eseguiCiclica = (ind) => {
                                            if (CustomJS.isNullEmpty(funcListCostruzioni[ind])) {
                                                resolveFineCostruzioni();
                                            }
                                            else {
                                                funcListCostruzioni[ind]().then(ret => {
                                                    if (ret === 'Prossima') {
                                                        eseguiCiclica(ind + 1);
                                                    }
                                                }).catch(err => {
                                                    console.log(err)
                                                })
                                            }
                                        }
                                        eseguiCiclica(0);
                                    })
                                }
                                await fineCostruzioni();

                                //DESCRIZIONI XPERT
                                const fineDescrizioni = () => {
                                    return new Promise((resolveFineDescrizioni) => {
                                        let funcListDescrizioni = [];
                                        this.strutturaStatiPreesistenzeDescrizioni.forEach((elm2) => {
                                            const eseguiDescrizione = () => {
                                                return new Promise((resolveDescrizione) => {
                                                    if (elm2.id === `rigaPreesDescrizione-T${elm.t}-R-${elm2.r}`) {
                                                        var queryDescrizioneXpert = '';
                                                        var jsonObjectDescrizioneXpert = {}

                                                        //se la descrizione è nuova e la preesistenza è nuova :
                                                        if (elm2.stato === 'n' && elm.stato === 'n') {
                                                            queryDescrizioneXpert = `START TRANSACTION;INSERT INTO _preesistenza_descrizioni (id_preesistenza,id_sinistro,descrizione, prezzo) VALUES ((select id_preesistenza from _preesistenza_xpert where id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}' FOR UPDATE),@idSinistro,@descrizioneOggetto,@prezzoOggetto);COMMIT;`;
                                                        }
                                                        //se la descrizione è nuova ma la preesistenza esiste :
                                                        else if (elm2.stato === 'n' && elm.stato === 'o') {
                                                            queryDescrizioneXpert = `INSERT INTO _preesistenza_descrizioni (id_preesistenza,id_sinistro,descrizione,prezzo) VALUES (${elm.keyXpert},@idSinistro,@descrizioneOggetto,@prezzoOggetto);`;
                                                        }
                                                        else if (elm2.stato === 'o') {
                                                            queryDescrizioneXpert = `UPDATE _preesistenza_descrizioni SET descrizione = @descrizioneOggetto, prezzo = @prezzoOggetto WHERE id_preesistenza_descrizione = ${elm2.key};`;
                                                        }
                                                        else if (elm2.stato === 'd') {
                                                            queryDescrizioneXpert = `DELETE FROM _preesistenza_descrizioni WHERE id_preesistenza_descrizione = ${elm2.key};`;
                                                        }
                                                        if (elm2.stato === 'd') {
                                                            jsonObjectDescrizioneXpert = {
                                                                QueryExec: queryDescrizioneXpert
                                                            }
                                                        }
                                                        else {
                                                            jsonObjectDescrizioneXpert = {
                                                                QueryExec: queryDescrizioneXpert,
                                                                idSinistro: this.props.IdSinistro.toString(),
                                                                descrizioneOggetto: CustomJS.isNullEmpty(document.getElementById(`descrizioneCorpo_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneCorpo_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value,
                                                                prezzoOggetto: CustomJS.isNullEmpty(document.getElementById(`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value.toString(),
                                                            }
                                                        }
                                                        console.log(jsonObjectDescrizioneXpert)
                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDescrizioneXpert), retDataDescrizioneXpert => {
                                                            resolveDescrizione('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            }
                                                            );
                                                        });
                                                    }
                                                    else {
                                                        resolveDescrizione('Prossima')
                                                    }
                                                })
                                            }
                                            funcListDescrizioni.push(eseguiDescrizione);
                                        });
                                        const eseguiCiclica = (ind) => {
                                            if (CustomJS.isNullEmpty(funcListDescrizioni[ind])) {
                                                resolveFineDescrizioni();
                                            }
                                            else {
                                                funcListDescrizioni[ind]().then(ret => {
                                                    if (ret === 'Prossima') {
                                                        eseguiCiclica(ind + 1);
                                                    }
                                                }).catch(err => {
                                                    console.log(err)
                                                })
                                            }
                                        }
                                        eseguiCiclica(0);
                                    })
                                }
                                await fineDescrizioni();
                            }
                            //Se la partita NON è Fabbricato le query per Generali vengono inserite in base ali dati di una preesistenza
                            //-------------GESTIONE PREESISTENZA XPERT GENERALI PARTE 2-------------
                            else {
                                const fineDescrizioni2 = () => {
                                    return new Promise((resolveFineDescrizioni2) => {
                                        let funcListDescrizioni2 = [];

                                        this.strutturaStatiPreesistenzeDescrizioni.forEach((elm2) => {
                                            const eseguiDescrizione2 = () => {
                                                return new Promise((resolveDescrizione2) => {
                                                    if (elm2.id === `rigaPreesDescrizione-T${elm.t}-R-${elm2.r}`) {

                                                        var jsonObjectDescrizione2 = {}
                                                        var queryDescrizioneXpert2 = '';
                                                        //se la descrizione è nuova e la preesistenza è nuova :
                                                        if (elm2.stato === 'n' && elm.stato === 'n') {
                                                            queryDescrizioneXpert2 = `START TRANSACTION;INSERT INTO _preesistenza_descrizioni (id_preesistenza,id_sinistro,descrizione, prezzo) VALUES ((select id_preesistenza from _preesistenza_xpert where id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}' FOR UPDATE),@idSinistro,@descrizioneOggetto,@prezzoOggetto);COMMIT;`;
                                                        }
                                                        //se la descrizione è nuova ma la preesistenza esiste :
                                                        else if (elm2.stato === 'n' && elm.stato === 'o') {
                                                            queryDescrizioneXpert2 = `INSERT INTO _preesistenza_descrizioni (id_preesistenza,id_sinistro,descrizione, prezzo) VALUES (${elm.keyXpert},@idSinistro,@descrizioneOggetto,@prezzoOggetto);`;
                                                        }
                                                        else if (elm2.stato === 'o') {
                                                            queryDescrizioneXpert2 = `UPDATE _preesistenza_descrizioni SET descrizione = @descrizioneOggetto, prezzo = @prezzoOggetto WHERE id_preesistenza_descrizione = ${elm2.key}`;
                                                        }
                                                        else if (elm2.stato === 'd') {
                                                            queryDescrizioneXpert2 = `DELETE FROM _preesistenza_descrizioni WHERE id_preesistenza_descrizione = ${elm2.key}`;
                                                        }
                                                        if (elm2.stato === 'd') {
                                                            jsonObjectDescrizione2 = {
                                                                QueryExec: queryDescrizioneXpert2
                                                            }
                                                        }
                                                        else {
                                                            jsonObjectDescrizione2 = {
                                                                QueryExec: queryDescrizioneXpert2,
                                                                idSinistro: this.props.IdSinistro.toString(),
                                                                descrizioneOggetto: CustomJS.isNullEmpty(document.getElementById(`descrizioneCorpo_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneCorpo_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value,
                                                                prezzoOggetto: CustomJS.isNullEmpty(document.getElementById(`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}`).ej2_instances[0].value.toString(),
                                                            }
                                                        }
                                                        console.log(jsonObjectDescrizione2)
                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDescrizione2), retDataDescrizioneXpert => {
                                                            resolveDescrizione2('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            }
                                                            );
                                                        });
                                                    }
                                                    else {
                                                        resolveDescrizione2('Prossima')
                                                    }
                                                })
                                            }
                                            funcListDescrizioni2.push(eseguiDescrizione2);
                                        });
                                        const eseguiCiclica = (ind) => {
                                            if (CustomJS.isNullEmpty(funcListDescrizioni2[ind])) {
                                                resolveFineDescrizioni2();
                                            }
                                            else {
                                                funcListDescrizioni2[ind]().then(ret => {
                                                    if (ret === 'Prossima') {
                                                        eseguiCiclica(ind + 1);
                                                    }
                                                }).catch(err => {
                                                    console.log(err)
                                                })
                                            }
                                        }
                                        eseguiCiclica(0);
                                    })
                                }
                                await fineDescrizioni2();

                                if (this.isCompagniaGenerali()) {
                                    const crudPreesistenzaGEN = () => {
                                        return new Promise((resolvePreesistenzaGEN) => {
                                            var queryPreesGen = '';
                                            var jsonObjectPreesGen = {}
                                            //Se la partita NON è Fabbricato viene inserito un solo record per preesistenza sulla GENERALI_partita_preesistenze
                                            //Nell' inserimento dei valori delle preesistenze diverse da fabbricato non sono previsti i campi valore_unitario, quantita, unita_misura
                                            if (elm.stato === 'n') {
                                                queryPreesGen = `START TRANSACTION;INSERT INTO GENERALI_partita_preesistenze (id_preesistenza_xpert,id_partita,id_incarico,descrizione,valore_assicurato,deroga_percentuale,valore_riferimento,totale_preesistenza,unita_misura,valore_unitario,quantita) VALUES ((select id_preesistenza from _preesistenza_xpert where id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}' FOR UPDATE),@guidPartita,'${this.props.IdIncarico.toString()}',@partitaDescr,@sommaAssicurata,@deroga,@valoreDerogato,@totaleEsistenzaNuovo,@unitaMisura,0,0);COMMIT;`;
                                            }
                                            else if (elm.stato === 'o') {
                                                queryPreesGen = `UPDATE GENERALI_partita_preesistenze SET valore_assicurato = @sommaAssicurata, deroga_percentuale = @deroga,valore_riferimento = @valoreDerogato, totale_preesistenza = @totaleEsistenzaNuovo, unita_misura = @unitaMisura,valore_unitario = 0,quantita = 0 WHERE id_preesistenza = ${elm.keyGen};`
                                            }
                                            else if (elm.stato === 'd') {
                                                queryPreesGen = `DELETE FROM GENERALI_partita_preesistenze WHERE id_preesistenza = ${elm.keyGen};`
                                            }
                                            //Preesistenza generali
                                            if (elm.stato === 'd') {
                                                jsonObjectPreesGen = {
                                                    QueryExec: queryPreesGen
                                                }
                                            }
                                            else {
                                                jsonObjectPreesGen = {
                                                    QueryExec: queryPreesGen,
                                                    guidPartita: CustomJS.isNullEmpty(document.getElementById(`idPartitaPO-T${elm.t}`).ej2_instances[0].value) ? '' : document.getElementById(`idPartitaPO-T${elm.t}`).ej2_instances[0].itemData.GUID_PARTITA_GEN,
                                                    partitaDescr: CustomJS.isNullEmpty(document.getElementById(`nomePartita${elm.t}`).ej2_instances[0].value) ? '' : document.getElementById(`nomePartita${elm.t}`).ej2_instances[0].text,
                                                    sommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`sommaAssicurataInput_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`sommaAssicurataInput_T${elm.t}`).ej2_instances[0].value).toString(),
                                                    deroga: CustomJS.isNullEmpty(document.getElementById(`derogaInput_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`derogaInput_T${elm.t}`).ej2_instances[0].value).toString(),
                                                    valoreDerogato: CustomJS.isNullEmpty(document.getElementById(`valoreDerogatoResult_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreDerogatoResult_T${elm.t}`).ej2_instances[0].value).toString(),
                                                    totaleEsistenzaNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}`).ej2_instances[0].value).toString(),
                                                    unitaMisura: 'nr'
                                                }
                                            }
                                            console.log(jsonObjectPreesGen)
                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectPreesGen), retDataPreesGen => {
                                                resolvePreesistenzaGEN();
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                }
                                                );
                                            });
                                        });
                                    }
                                    await crudPreesistenzaGEN();
                                }
                            }
                            resolvePreesistenza('Prossima');
                        })
                    }
                    funcListPreesistenze.push(eseguiPreesistenza);
                })

                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListPreesistenze[ind])) {
                        resolveFinePreesistenze();
                    }
                    else {
                        funcListPreesistenze[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }
        await finePreesistenze();
        //-----------------------FINE QUERY PREESISTENZE-----------------------

        //-------------------------INIZIO QUERY STIME-------------------------
        console.log('inizio stime')

        const fineStime = () => {
            return new Promise((resolveFineStime) => {

                let funcListStime = [];
                this.strutturaStatiStimeBase.forEach((elm) => {
                    const eseguiStima = () => {
                        return new Promise(async (resolveStima) => {
                            //------DATI PER LE STIME
                            //insufficienza assicurativa
                            var insuffAss = 0;
                            this.strutturaStatiPreesistenzeBase.forEach(elmPrees => {
                                if (document.getElementById(`partitaFromSin-D${elm.d}`).ej2_instances[0].value === document.getElementById(`nomePartita${elmPrees.t}`).ej2_instances[0].value) {
                                    if (document.getElementById(`formaGaranzia${elmPrees.t}`).ej2_instances[0].value !== '1') // '1' = P.R.A.
                                        insuffAss = document.getElementById(`insuffAssicurativaPerc_T${elmPrees.t}`).ej2_instances[0].value
                                }
                            })
                            //indennizzo finale
                            var indennizzoFinale = document.getElementById(`indennizzoVsCompagnia_D${elm.d}`) ? ((CustomJS.isNullEmpty(document.getElementById(`indennizzoVsCompagnia_D${elm.d}`).ej2_instances[0].value) ? 0 : document.getElementById(`indennizzoVsCompagnia_D${elm.d}`).ej2_instances[0].value)) : 0;
                            var limiteIndennizzo = document.getElementById(`ceLimiteIndennizzoONo-D${elm.d}`).style.display === 'none' ? '-1' : (CustomJS.isNullEmpty(document.getElementById(`limiteIndennizzo-D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`limiteIndennizzo-D${elm.d}`).ej2_instances[0].value).toString());

                            //-------INSERIMENTO STIME
                            //XPERT
                            var queryStimaXpert = ''; // cambia in base al tipo stima
                            var jsonObjectStimaXpert = {};

                            //GESTIONE ESITO 
                            if (this.isCompagniaGenerali()) {

                                var esitoDanno = document.getElementById(`esitoDanno_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].value) : '';
                                //4 = esito non indennizzabile
                                if (esitoDanno === "4") {
                                    const eseguiCausale = () => {
                                        return new Promise(async (resolveCausale) => {
                                            var queryNonIndennizzo = `insert into GENERALI_danno_causali_nonIndennizzo (id_danno,id_incarico,codice,descrizione) values (${elm.keyGen},'${this.props.IdIncarico}',@codiceCausaliNonIndenn, @descrCausaliNonIndenn);`;
                                            this.strutturaStatiCausali.forEach(causale => {
                                                if (causale.idDanno === elm.keyGen) {
                                                    queryNonIndennizzo = `update GENERALI_danno_causali_nonIndennizzo set codice = @codiceCausaliNonIndenn, descrizione = @descrCausaliNonIndenn where id_causale = ${causale.idCausale};`
                                                }
                                            })
                                            var jsonObjectCausali = {
                                                QueryExec: queryNonIndennizzo,
                                                codiceCausaliNonIndenn: CustomJS.isNullEmpty(document.getElementById(`causaliNonIndennizzo_D${elm.d}`).ej2_instances[0].value) ? '' : (document.getElementById(`causaliNonIndennizzo_D${elm.d}`).ej2_instances[0].value),
                                                descrCausaliNonIndenn: CustomJS.isNullEmpty(document.getElementById(`causaliNonIndennizzo_D${elm.d}`).ej2_instances[0].text) ? '' : (document.getElementById(`causaliNonIndennizzo_D${elm.d}`).ej2_instances[0].text),
                                            }
                                            console.log(jsonObjectCausali)
                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCausali), retDataCausali => {
                                                resolveCausale()
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                }
                                                );
                                            });
                                        })
                                    }
                                    await eseguiCausale();
                                }
                                else {
                                    const eliminaCausale = () => {
                                        return new Promise(async (resolveDelete) => {
                                            var queryNonIndennizzo = ""
                                            this.strutturaStatiCausali.forEach(causale => {
                                                if (causale.idDanno === elm.keyGen) {
                                                    queryNonIndennizzo = `delete from GENERALI_danno_causali_nonIndennizzo where id_causale = ${causale.idCausale};`
                                                }
                                            })
                                            if (!CustomJS.isNullEmpty(queryNonIndennizzo)) {
                                                var jsonObjectCausaliDaElim = {
                                                    QueryExec: queryNonIndennizzo,
                                                }
                                                console.log(jsonObjectCausaliDaElim)
                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCausaliDaElim), eliminato => {
                                                    resolveDelete()
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    }
                                                    );
                                                });
                                            }
                                            else {
                                                resolveDelete()
                                            }
                                        })
                                    }
                                    await eliminaCausale();
                                }
                            }

                            const updateStimaXpert = () => {
                                return new Promise(async (resolveUpdateXpert) => {
                                    //STIMA VALORE A NUOVO (XPERT)
                                    if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '001') {
                                        queryStimaXpert = `UPDATE _stime_xpert SET danno_compless_accertato = @dannoComplessivoAccertato,dopo_regola_proporzionale = @dopoRegolaProp,dopo_regola_proporzionaleMAN = @dopoRegolaPropMAN,  limite_sinistro = @limiteSinistro, piano = @piano, limiteIndennizzo = @limiteIndennizzo,franchigiaSalvata = @franchigiaSalvata, franchigiaOScoperto = @franchigiaOScoperto,franchigiaOScopertoMAN = @franchigiaOScopertoMAN, danno_netto = @dannoNetto, ripristini_ultimati = @ripristiniUltimati,note_ripristini_ultimati = @noteRipristiniUltimati, accordo_ania = @accordoAnia, coassicurazione = @coassicurazione,sommaDanniRiparto1 = @sommaDanniRiparto1, sommaDanniRiparto2 = @sommaDanniRiparto2,indennizzoACaricoVsComp = @indennizzoACaricVsCompagnia,descrizione_coass = @noteCoassicurazione, esito_danno = @esitoCodice WHERE id_stima = ${elm.keyXpert};`;

                                        jsonObjectStimaXpert = {
                                            QueryExec: queryStimaXpert,
                                            limiteSinistro: CustomJS.isNullEmpty(document.getElementById(`limiteSinistro_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`limiteSinistro_D${elm.d}`).ej2_instances[0].value).toString(),
                                            piano: CustomJS.isNullEmpty(document.getElementById(`piano-D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`piano-D${elm.d}`).ej2_instances[0].value.toString(),
                                            dannoComplessivoAccertato: CustomJS.isNullEmpty(document.getElementById(`dannoComplessAccert_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dannoComplessAccert_D${elm.d}`).ej2_instances[0].value).toString(),
                                            dopoRegolaProp: CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionale_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dopoRegolaProporzionale_D${elm.d}`).ej2_instances[0].value).toString(),
                                            dopoRegolaPropMAN: CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}`).ej2_instances[0].value).toString(),
                                            limiteIndennizzo: limiteIndennizzo,
                                            franchigiaSalvata: '1',
                                            franchigiaOScoperto: (CustomJS.isNullEmpty(document.getElementById(`franchigia_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`franchigia_D${elm.d}`).ej2_instances[0].value).toString()),
                                            franchigiaOScopertoMAN: (CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`franchigiaMAN_D${elm.d}`).ej2_instances[0].value).toString()),
                                            dannoNetto: (CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value).toString()),
                                            ripristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`ripristiniUltimSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`ripristiniUltimSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                            noteRipristiniUltimati: (CustomJS.isNullEmpty(document.getElementById(`noteRipristini-D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRipristini-D${elm.d}`).ej2_instances[0].value),
                                            accordoAnia: CustomJS.isNullEmpty(document.getElementById(`accordoAniaSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`accordoAniaSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                            coassicurazione: CustomJS.isNullEmpty(document.getElementById(`coassicurazioneSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`coassicurazioneSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                            sommaDanniRiparto1: document.getElementById(`sommaDanniRiparto1_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto1_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`sommaDanniRiparto1_D${elm.d}`).ej2_instances[0].value.toString()) : '0',
                                            sommaDanniRiparto2: document.getElementById(`sommaDanniRiparto1_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto2_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`sommaDanniRiparto2_D${elm.d}`).ej2_instances[0].value.toString()) : '0',
                                            indennizzoACaricVsCompagnia: document.getElementById(`indennizzoVsCompagnia_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`indennizzoVsCompagnia_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`indennizzoVsCompagnia_D${elm.d}`).ej2_instances[0].value.toString()) : '0',
                                            noteCoassicurazione: document.getElementById(`noteCoassicurazione-D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazione-D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazione-D${elm.d}`).ej2_instances[0].value) : '',
                                            esitoCodice: esitoDanno,
                                        }
                                    }
                                    //STIMA STATO USO (XPERT)
                                    else if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '003') {

                                        queryStimaXpert = `UPDATE _stime_xpert SET limite_sinistro = @limiteSinistro, piano = @piano, limiteIndennizzo = @limiteIndennizzo,franchigiaSalvata = @franchigiaSalvata, franchigiaOScoperto = @franchigiaOScoperto,franchigiaOScopertoMAN = @franchigiaOScopertoMAN, DCA_valore_a_nuovo = @DCAValoreANuovo,DCA_degrado25 = @DCADegrado25,DCA_valore_stato_uso = @DCAValoreStatoUso,DRP_valore_a_nuovo = @DRPValoreANuovo,DRP_valore_a_nuovoMAN = @DRPValoreANuovoMAN,DRP_degrado25 = @DRPDegrado25,DRP_degrado25MAN = @DRPDegrado25MAN,DRP_valore_stato_uso = @DRPValoreStatoUso,DRP_valore_stato_usoMAN = @DRPValoreStatoUsoMAN,DN_valore_a_nuovo = @DNValAnuovo,DN_degrado25 = @DNDegrado25,DN_valore_stato_uso = @DNValStatoUso, ripristini_ultimati = @ripristiniUltimati,note_ripristini_ultimati = @noteRipristiniUltimati, accordo_ania = @accordoAnia, coassicurazione = @coassicurazione, sommaDanniRiparto1 = @sommaDanniRiparto1, sommaDanniRiparto2 = @sommaDanniRiparto2,indennizzoACaricoVsComp = @indennizzoACaricVsCompagnia,suppl_indenn_coass = @supplIndennCoass,vsu_coass = @vsuCoass, descrizione_coass = @noteCoassicurazione,esito_danno = @esitoCodice WHERE id_stima = ${elm.keyXpert};`;
                                        jsonObjectStimaXpert = {
                                            QueryExec: queryStimaXpert,
                                            limiteSinistro: (CustomJS.isNullEmpty(document.getElementById(`limiteSinistro_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`limiteSinistro_D${elm.d}`).ej2_instances[0].value).toString()),
                                            piano: (CustomJS.isNullEmpty(document.getElementById(`piano-D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`piano-D${elm.d}`).ej2_instances[0].value.toString()),
                                            limiteIndennizzo: limiteIndennizzo,
                                            franchigiaSalvata: '1',
                                            franchigiaOScoperto: (CustomJS.isNullEmpty(document.getElementById(`franchigia_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`franchigia_D${elm.d}`).ej2_instances[0].value).toString()),
                                            franchigiaOScopertoMAN: (CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`franchigiaMAN_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DCAValoreANuovo: (CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDannComplessAcc_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreANuovoDannComplessAcc_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DCADegrado25: (CustomJS.isNullEmpty(document.getElementById(`degradoPercDannComplessAcc_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`degradoPercDannComplessAcc_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DCAValoreStatoUso: (CustomJS.isNullEmpty(document.getElementById(`valoreBeneDannComplessAcc_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreBeneDannComplessAcc_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DRPValoreANuovo: (CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaProp_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreANuovoDopoRegolaProp_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DRPValoreANuovoMAN: (CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DRPDegrado25: (CustomJS.isNullEmpty(document.getElementById(`degrado25PercDopoRegolaProp_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`degrado25PercDopoRegolaProp_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DRPDegrado25MAN: (CustomJS.isNullEmpty(document.getElementById(`degrado25PercDopoRegolaPropMAN_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`degrado25PercDopoRegolaPropMAN_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DRPValoreStatoUso: (CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionale_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dopoRegolaProporzionale_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DRPValoreStatoUsoMAN: (CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DNValAnuovo: (CustomJS.isNullEmpty(document.getElementById(`valoreANuovodannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreANuovodannoNetto_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DNDegrado25: (CustomJS.isNullEmpty(document.getElementById(`degrado25PercdannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`degrado25PercdannoNetto_D${elm.d}`).ej2_instances[0].value).toString()),
                                            DNValStatoUso: (CustomJS.isNullEmpty(document.getElementById(`valoreStatoUsodannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreStatoUsodannoNetto_D${elm.d}`).ej2_instances[0].value).toString()),
                                            ripristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`ripristiniUltimSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`ripristiniUltimSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                            noteRipristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`noteRipristini-D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRipristini-D${elm.d}`).ej2_instances[0].value,
                                            accordoAnia: CustomJS.isNullEmpty(document.getElementById(`accordoAniaSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`accordoAniaSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                            coassicurazione: CustomJS.isNullEmpty(document.getElementById(`coassicurazioneSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`coassicurazioneSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                            sommaDanniRiparto1: document.getElementById(`sommaDanniRiparto1_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto1_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`sommaDanniRiparto1_D${elm.d}`).ej2_instances[0].value.toString()) : '0',
                                            sommaDanniRiparto2: document.getElementById(`sommaDanniRiparto1_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto2_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`sommaDanniRiparto2_D${elm.d}`).ej2_instances[0].value.toString()) : '0',
                                            indennizzoACaricVsCompagnia: document.getElementById(`indennizzoVsCompagnia_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`indennizzoVsCompagnia_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`indennizzoVsCompagnia_D${elm.d}`).ej2_instances[0].value.toString()) : '0',
                                            supplIndennCoass: document.getElementById(`supplIndennitaCoass-D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`supplIndennitaCoass-D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`supplIndennitaCoass-D${elm.d}`).ej2_instances[0].value.toString()) : '0',
                                            vsuCoass: document.getElementById(`vsuCoass-D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`vsuCoass-D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`vsuCoass-D${elm.d}`).ej2_instances[0].value.toString()) : '0',
                                            noteCoassicurazione: document.getElementById(`noteCoassicurazione-D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazione-D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazione-D${elm.d}`).ej2_instances[0].value) : '',
                                            esitoCodice: esitoDanno,
                                        }
                                    }
                                    console.log(jsonObjectStimaXpert)
                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectStimaXpert), retDataStimeXpert => {
                                        resolveUpdateXpert();
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                    });
                                })
                            }
                            await updateStimaXpert();
                            //GENERALI
                            if (this.isCompagniaGenerali()) {
                                const updateStimaGEN = () => {
                                    return new Promise(async (resolveUpdateGEN) => {
                                        var queryStimaGen = `UPDATE GENERALI_danni set totale_danno = @dannoComplessivoAccertato, ripristini_ultimati = @ripristiniUltimati, note = @noteRipristiniUltimati,supplemento_indennizzo = @supplementoIndennizzo,totale_lordo_limitazioni = @totLordoLimitazioni,insufficienza_assicurativa_percentuale = @insufficienzaAssicurativaPerc,alNettoDi_insufficienza_assicurativa = @alNettoInsufficienzaAssicurativa,scoperto_percentuale = @scopertoPerc,alNettoDi_scoperto = @alNettoDiScoperto,scoperto_minimo = @scopertoMin,franchigia = @franchigia,alNettoDi_franchigia_scopertoMinimo = @alNettoFranchigiaScopMin,massimale = @massimale,indennizzo_netto = @dannoNetto,coassicurazione_indiretta_presente = @coassicurazIndirPresente,coassicurazione_indiretta_accordo_ANIA = @accordoAnia,note_riparto_coassicurazione = @noteRipCoassicurazione, indennizzo_finale = @indennizzoFinale,esito_codice = @esitoCodice,esito_descrizione = @esitoDescrizione  WHERE id_danno = ${elm.keyGen}`;
                                        var jsonObjectStimaGen = {};
                                        var franchigaDaInserire = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${elm.d}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${elm.d}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${elm.d}`).ej2_instances[0].value;

                                        let dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${elm.d}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}`).ej2_instances[0].value
                                        dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? '0' : dopoRegolaProp.toString()
                                        //STIMA VALORE A NUOVO (GENERALI)
                                        if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '001') {
                                            //todo DA RIGARDARE L'INDENNIZZO FINALE
                                            if (indennizzoFinale === 0)
                                                indennizzoFinale = (CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value);
                                            jsonObjectStimaGen = {
                                                QueryExec: queryStimaGen,
                                                dannoComplessivoAccertato: (CustomJS.isNullEmpty(document.getElementById(`dannoComplessAccert_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dannoComplessAccert_D${elm.d}`).ej2_instances[0].value).toString()),
                                                ripristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`ripristiniUltimSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`ripristiniUltimSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                                noteRipristiniUltimati: (CustomJS.isNullEmpty(document.getElementById(`noteRipristini-D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRipristini-D${elm.d}`).ej2_instances[0].value),
                                                supplementoIndennizzo: '0',
                                                totLordoLimitazioni: (CustomJS.isNullEmpty(franchigaDaInserire) ? '0' : franchigaDaInserire.toString()),
                                                insufficienzaAssicurativaPerc: CustomJS.isNullEmpty(insuffAss.toString()) ? '0' : insuffAss.toString(),
                                                alNettoInsufficienzaAssicurativa: dopoRegolaProp,
                                                // todo scopertoPerc: scopertoPerc.toString(),
                                                alNettoDiScoperto: (CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value.toString()),
                                                // todo scopertoMin: scopertoMin.toString(),
                                                franchigia: (CustomJS.isNullEmpty(franchigaDaInserire) ? '0' : (franchigaDaInserire).toString()),
                                                alNettoFranchigiaScopMin: (CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value.toString()),
                                                massimale: (CustomJS.isNullEmpty(document.getElementById(`limiteSinistro_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`limiteSinistro_D${elm.d}`).ej2_instances[0].value.toString()),
                                                dannoNetto: (CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dannoNetto_D${elm.d}`).ej2_instances[0].value).toString()),
                                                coassicurazIndirPresente: CustomJS.isNullEmpty(document.getElementById(`coassicurazioneSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`coassicurazioneSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                                accordoAnia: CustomJS.isNullEmpty(document.getElementById(`accordoAniaSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`accordoAniaSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                                noteRipCoassicurazione: document.getElementById(`noteCoassicurazione-D${elm.d}`) ? ((CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazione-D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazione-D${elm.d}`).ej2_instances[0].value)) : '',
                                                indennizzoFinale: indennizzoFinale.toString(), // Indennizzo a carico Vs Compagnia 
                                                esitoCodice: document.getElementById(`esitoDanno_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].value) : '',
                                                esitoDescrizione: document.getElementById(`esitoDanno_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].text) ? '' : document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].text) : '',
                                            }
                                        }
                                        //STIMA STATO USO (GENERALI)
                                        else if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '003') {

                                            let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${elm.d}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${elm.d}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${elm.d}`).ej2_instances[0].value
                                            valoreANuovoDRP = CustomJS.isNullEmpty(valoreANuovoDRP) ? '0' : valoreANuovoDRP.toString()

                                            if (indennizzoFinale === 0)
                                                indennizzoFinale = CustomJS.isNullEmpty(document.getElementById(`valoreStatoUsodannoNetto_D${elm.d}`)) ? '0' : (CustomJS.isNullEmpty(document.getElementById(`valoreStatoUsodannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`valoreStatoUsodannoNetto_D${elm.d}`).ej2_instances[0].value);
                                            jsonObjectStimaGen = {
                                                QueryExec: queryStimaGen,
                                                dannoComplessivoAccertato: (CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDannComplessAcc_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreANuovoDannComplessAcc_D${elm.d}`).ej2_instances[0].value).toString()),
                                                ripristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`ripristiniUltimSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`ripristiniUltimSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                                noteRipristiniUltimati: (CustomJS.isNullEmpty(document.getElementById(`noteRipristini-D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRipristini-D${elm.d}`).ej2_instances[0].value),
                                                supplementoIndennizzo: (CustomJS.isNullEmpty(document.getElementById(`degrado25PercdannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`degrado25PercdannoNetto_D${elm.d}`).ej2_instances[0].value.toString()),
                                                totLordoLimitazioni: (CustomJS.isNullEmpty(franchigaDaInserire) ? '0' : franchigaDaInserire.toString()),
                                                insufficienzaAssicurativaPerc: CustomJS.isNullEmpty(insuffAss.toString()) ? '0' : insuffAss.toString(),
                                                alNettoInsufficienzaAssicurativa: valoreANuovoDRP,
                                                //todo scopertoPerc: scopertoPerc.toString(),
                                                alNettoDiScoperto: (CustomJS.isNullEmpty(document.getElementById(`valoreANuovodannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`valoreANuovodannoNetto_D${elm.d}`).ej2_instances[0].value.toString()),
                                                //todo scopertoMin: scopertoMin.toString(),
                                                franchigia: (CustomJS.isNullEmpty(franchigaDaInserire) ? '0' : franchigaDaInserire.toString()),
                                                alNettoFranchigiaScopMin: (CustomJS.isNullEmpty(document.getElementById(`valoreANuovodannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`valoreANuovodannoNetto_D${elm.d}`).ej2_instances[0].value.toString()),
                                                massimale: (CustomJS.isNullEmpty(document.getElementById(`limiteSinistro_D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`limiteSinistro_D${elm.d}`).ej2_instances[0].value.toString()),
                                                dannoNetto: (CustomJS.isNullEmpty(document.getElementById(`valoreANuovodannoNetto_D${elm.d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreANuovodannoNetto_D${elm.d}`).ej2_instances[0].value).toString()),
                                                coassicurazIndirPresente: CustomJS.isNullEmpty(document.getElementById(`coassicurazioneSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`coassicurazioneSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                                accordoAnia: CustomJS.isNullEmpty(document.getElementById(`accordoAniaSi-D${elm.d}`).ej2_instances[0].checked) ? '0' : (document.getElementById(`accordoAniaSi-D${elm.d}`).ej2_instances[0].checked === true ? '1' : '0'),
                                                noteRipCoassicurazione: document.getElementById(`noteCoassicurazione-D${elm.d}`) ? ((CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazione-D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazione-D${elm.d}`).ej2_instances[0].value)) : '',
                                                //se ce coassicurazione Indennizzo a carico Vs Compagnia :
                                                indennizzoFinale: indennizzoFinale.toString(),
                                                esitoCodice: document.getElementById(`esitoDanno_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].value) ? '' : document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].value) : '',
                                                esitoDescrizione: document.getElementById(`esitoDanno_D${elm.d}`) ? (CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].text) ? '' : document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].text) : '',
                                            }
                                        }
                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectStimaGen), retDataStimeGen => {
                                            resolveUpdateGEN();
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            }
                                            );
                                        });
                                    })
                                }
                                await updateStimaGEN();
                            }
                            const finePrezziari = () => {
                                return new Promise((resolveFinePrezziari) => {
                                    console.log('inizio prezziari')

                                    let funcListPrezziari = [];
                                    this.strutturaStatiStimePrezziari.forEach((elm2) => {
                                        const eseguiPrezziario = () => {

                                            return new Promise(async (resolvePrezziario) => {
                                                if (elm2.id === `tabPrezziario-D${elm.d}-R-${elm2.r}`) {
                                                    if (elm2.stato !== 'd') {
                                                        //-------DATI PREZZIARIO
                                                        //Gestione descrizioni libere o scelte
                                                        var categoriaPrezziario = '';
                                                        var descrizionePrezziarioGen = '';

                                                        if (!CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].value)) {
                                                            descrizionePrezziarioGen = document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].text;
                                                            if (this.isCompagniaGenerali())
                                                                categoriaPrezziario = document.getElementById(`categoriaSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].value
                                                        }
                                                    }
                                                    //INSERIMENTO PREZZIARIO
                                                    var guidPrezzGenerata = CustomJS.createGuid4();
                                                    //PREZZIARIO XPERT
                                                    const funcPrezziarioXpert = () => {
                                                        return new Promise((resolvePrezzXpert) => {
                                                            //DESCRIZIONI XPERT
                                                            var queryStimaDescrXpert = '';
                                                            var jsonObjectStimaDescrXpert = {};

                                                            //VALORE A NUOVO DESCRIZIONI PREZZIARIO
                                                            if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '001') {
                                                                if (elm2.stato === 'n') {
                                                                    queryStimaDescrXpert = `INSERT INTO _stime_descrizioni (id_stima,id_sinistro,descrizione_lunga_prezziario,descrizione_cambiata,categoria_prezziario,localizzazione,unita_misura,quantita,valore_unitario,totale,guid_utile_descr) VALUES (${elm.keyXpert},${this.props.IdSinistro},@descrizioneLunga,@descrizioneCambiata,@categoriaPrezziario,@localizzazione,@unitaMisura,@quantita,@valoreUnitarioPrez,@totaleRigaPrez,'${guidPrezzGenerata}');`;
                                                                }
                                                                else if (elm2.stato === 'o') {
                                                                    queryStimaDescrXpert = `UPDATE _stime_descrizioni SET  descrizione_lunga_prezziario = @descrizioneLunga,descrizione_cambiata = @descrizioneCambiata,categoria_prezziario = @categoriaPrezziario,localizzazione = @localizzazione,unita_misura = @unitaMisura, quantita = @quantita,valore_unitario = @valoreUnitarioPrez,totale = @totaleRigaPrez where id_stima_descrizione = ${elm2.keyXpert};`;
                                                                }
                                                                else if (elm2.stato === 'd') {
                                                                    queryStimaDescrXpert = `DELETE FROM _stime_descrizioni WHERE id_stima_descrizione = ${elm2.keyXpert}`;
                                                                }
                                                                if (elm2.stato === 'd') {
                                                                    jsonObjectStimaDescrXpert = {
                                                                        QueryExec: queryStimaDescrXpert
                                                                    };
                                                                }
                                                                else {
                                                                    jsonObjectStimaDescrXpert = {
                                                                        QueryExec: queryStimaDescrXpert,
                                                                        localizzazione: this.isCompagniaGenerali() ? (CustomJS.isNullEmpty(document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) : '',
                                                                        descrizioneLunga: CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].value,
                                                                        descrizioneCambiata: CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].text,
                                                                        categoriaPrezziario: categoriaPrezziario,
                                                                        unitaMisura: CustomJS.isNullEmpty(document.getElementById(`UMInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`UMInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value,
                                                                        quantita: (CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value.toString()),
                                                                        valoreUnitarioPrez: CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}`)) ? '0' : (CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                        totaleRigaPrez: (CustomJS.isNullEmpty(document.getElementById(`totaleResult_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleResult_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                    }
                                                                }
                                                            }
                                                            //STIMA STATO USO DESCRIZIONI PREZZIARIO
                                                            else if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '003') {
                                                                if (elm2.stato === 'n') {
                                                                    queryStimaDescrXpert = `INSERT INTO _stime_descrizioni (id_stima,id_sinistro,descrizione_lunga_prezziario,descrizione_cambiata,categoria_prezziario,localizzazione,unita_misura,quantita,valore_unitario,totale,supp_indenn_perc,supp_indenn_importo,valore_del_bene,guid_utile_descr) VALUES (${elm.keyXpert},${this.props.IdSinistro},@descrizioneLunga,@descrizioneCambiata,@categoriaPrezziario,@localizzazione,@unitaMisura,@quantita,@valoreUnitarioPrez,@totaleRigaPrez,@supplIndennPerc,@supplIndennImporto,@valoreDelBene, '${guidPrezzGenerata}');`;
                                                                }
                                                                else if (elm2.stato === 'o') {
                                                                    queryStimaDescrXpert = ` UPDATE _stime_descrizioni SET descrizione_lunga_prezziario = @descrizioneLunga,descrizione_cambiata = @descrizioneCambiata, categoria_prezziario = @categoriaPrezziario, localizzazione = @localizzazione, unita_misura = @unitaMisura, quantita = @quantita, valore_unitario = @valoreUnitarioPrez,totale = @totaleRigaPrez, supp_indenn_perc = @supplIndennPerc, supp_indenn_importo = @supplIndennImporto, valore_del_bene = @valoreDelBene where id_stima_descrizione = ${elm2.keyXpert};`;
                                                                }
                                                                else if (elm2.stato === 'd') {
                                                                    queryStimaDescrXpert = `DELETE FROM _stime_descrizioni WHERE id_stima_descrizione = ${elm2.keyXpert}`;
                                                                }
                                                                if (elm2.stato === 'd') {
                                                                    jsonObjectStimaDescrXpert = {
                                                                        QueryExec: queryStimaDescrXpert
                                                                    };
                                                                }
                                                                else {
                                                                    jsonObjectStimaDescrXpert = {
                                                                        QueryExec: queryStimaDescrXpert,
                                                                        localizzazione: this.isCompagniaGenerali() ? (CustomJS.isNullEmpty(document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) : '',
                                                                        descrizioneLunga: CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].value,
                                                                        descrizioneCambiata: CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`).ej2_instances[0].text,
                                                                        categoriaPrezziario: categoriaPrezziario,
                                                                        unitaMisura: CustomJS.isNullEmpty(document.getElementById(`UMInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`UMInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value,
                                                                        quantita: (CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value.toString()),
                                                                        valoreUnitarioPrez: (CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                        totaleRigaPrez: (CustomJS.isNullEmpty(document.getElementById(`valANuovoInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valANuovoInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                        supplIndennPerc: (CustomJS.isNullEmpty(document.getElementById(`percDegrSupInd_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`percDegrSupInd_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                        supplIndennImporto: (CustomJS.isNullEmpty(document.getElementById(`importoDegrSupInd_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`importoDegrSupInd_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString(),
                                                                        valoreDelBene: (CustomJS.isNullEmpty(document.getElementById(`valoreBeneResult_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreBeneResult_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString())
                                                                    }
                                                                }
                                                            }
                                                            console.log(jsonObjectStimaDescrXpert)
                                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectStimaDescrXpert), retDataStimeDescrXpert => {
                                                                resolvePrezzXpert()
                                                            }, messaggio => {
                                                                toast.warn(messaggio, {
                                                                    containerId: 'toastContainer1',
                                                                    onClose: () => toast.dismiss(),
                                                                    position: "bottom-right",
                                                                    autoClose: 5000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: false,
                                                                    progress: undefined,
                                                                    newestOnTop: true,
                                                                    rtl: false,
                                                                    pauseOnFocusLoss: true
                                                                }
                                                                );
                                                            });
                                                        })
                                                    }
                                                    await funcPrezziarioXpert();

                                                    //------PREZZIARIO + LOCALIZZAZIONE GENERALI-------
                                                    if (this.isCompagniaGenerali()) {
                                                        var guidGenerataDettaglio = CustomJS.createGuid4()
                                                        const funcPrezziarioGen = () => {
                                                            return new Promise((resolvePrezzGen) => {
                                                                var queryStimaDescrGenerali = '';
                                                                var jsonObjectStimaDescrGen = {};
                                                                //VALORE A NUOVO PREZZIARIO
                                                                if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '001') {
                                                                    if (elm2.stato === 'n') {
                                                                        queryStimaDescrGenerali = `START TRANSACTION;INSERT INTO GENERALI_danno_dettagli (id_danno,id_stima_descrizione,id_incarico,codice_tipologia_voce,descrizione,unita_misura,quantita,valore_unitario,degrado_percentuale,valore_unitario_residuo,valore_danno,guid_utile_dettaglio) VALUES (${elm.keyGen},(select id_stima_descrizione from _stime_descrizioni where guid_utile_descr = '${guidPrezzGenerata}' and id_sinistro = ${this.props.IdSinistro} for update),'${this.props.IdIncarico}',@codiceTipVoce,@descrizioneStima,@unitaMisuraGen,@quantitaGen,@valoreUnitarioPrezGen,@degradoPerc,@valoreUnitResiduo,@totaleRigaPrezGen,'${guidGenerataDettaglio}');COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'o') {
                                                                        queryStimaDescrGenerali = `START TRANSACTION;UPDATE GENERALI_danno_dettagli SET codice_tipologia_voce = @codiceTipVoce, descrizione = @descrizioneStima, unita_misura = @unitaMisuraGen, quantita = @quantitaGen, valore_unitario = @valoreUnitarioPrezGen,degrado_percentuale = @degradoPerc, valore_unitario_residuo = @valoreUnitResiduo,valore_danno = @totaleRigaPrezGen where id_dettaglio = ${elm2.keyGen};COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'd') {
                                                                        queryStimaDescrGenerali = `DELETE FROM GENERALI_danno_dettagli WHERE id_dettaglio = ${elm2.keyGen};`;
                                                                    }
                                                                    if (elm2.stato === 'd') {
                                                                        jsonObjectStimaDescrGen = {
                                                                            QueryExec: queryStimaDescrGenerali
                                                                        }
                                                                    }
                                                                    else {
                                                                        jsonObjectStimaDescrGen = {
                                                                            QueryExec: queryStimaDescrGenerali,
                                                                            codiceTipVoce: categoriaPrezziario,
                                                                            descrizioneStima: descrizionePrezziarioGen,
                                                                            unitaMisuraGen: CustomJS.isNullEmpty(document.getElementById(`UMInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`UMInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value,
                                                                            quantitaGen: (CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value.toString()),
                                                                            valoreUnitarioPrezGen: (CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                            degradoPerc: '0',
                                                                            valoreUnitResiduo: '0',
                                                                            totaleRigaPrezGen: (CustomJS.isNullEmpty(document.getElementById(`totaleResult_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleResult_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                        }
                                                                    }
                                                                }
                                                                //STATO USO PREZZIARIO
                                                                else if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '003') {
                                                                    if (elm2.stato === 'n') {
                                                                        queryStimaDescrGenerali = `START TRANSACTION;INSERT INTO GENERALI_danno_dettagli (id_danno,id_stima_descrizione,id_incarico,codice_tipologia_voce,descrizione,unita_misura,quantita,valore_unitario,degrado_percentuale,valore_unitario_residuo,valore_danno,guid_utile_dettaglio) VALUES (${elm.keyGen},(select id_stima_descrizione from _stime_descrizioni where guid_utile_descr = '${guidPrezzGenerata}' and id_sinistro = ${this.props.IdSinistro} for update),'${this.props.IdIncarico}',@codiceTipVoce,@descrizioneStima,@unitaMisuraGen,@quantitaGen,@valoreUnitarioPrezGen,@degradoPerc,@valoreUnitResiduo,@totaleRigaPrezGen ,'${guidGenerataDettaglio}');COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'o') {
                                                                        queryStimaDescrGenerali = `START TRANSACTION;UPDATE GENERALI_danno_dettagli SET codice_tipologia_voce = @codiceTipVoce, descrizione = @descrizioneStima, unita_misura = @unitaMisuraGen, quantita = @quantitaGen, valore_unitario = @valoreUnitarioPrezGen,degrado_percentuale = @degradoPerc, valore_unitario_residuo = @valoreUnitResiduo,valore_danno = @totaleRigaPrezGen where id_dettaglio = ${elm2.keyGen};COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'd') {
                                                                        queryStimaDescrGenerali = `DELETE FROM GENERALI_danno_dettagli WHERE id_dettaglio = ${elm2.keyGen};`;
                                                                    }
                                                                    if (elm2.stato === 'd') {
                                                                        jsonObjectStimaDescrGen = {
                                                                            QueryExec: queryStimaDescrGenerali
                                                                        }
                                                                    }
                                                                    else {
                                                                        jsonObjectStimaDescrGen = {
                                                                            QueryExec: queryStimaDescrGenerali,
                                                                            codiceTipVoce: categoriaPrezziario,
                                                                            descrizioneStima: descrizionePrezziarioGen,
                                                                            unitaMisuraGen: CustomJS.isNullEmpty(document.getElementById(`UMInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`UMInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value,
                                                                            quantitaGen: (CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value.toString()),
                                                                            valoreUnitarioPrezGen: (CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                            degradoPerc: (CustomJS.isNullEmpty(document.getElementById(`percDegrSupInd_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`percDegrSupInd_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                            valoreUnitResiduo: (CustomJS.isNullEmpty(document.getElementById(`importoDegrSupInd_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`importoDegrSupInd_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                            totaleRigaPrezGen: (CustomJS.isNullEmpty(document.getElementById(`valoreBeneResult_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '0' : (document.getElementById(`valoreBeneResult_D${elm.d}_R${elm2.r}`).ej2_instances[0].value).toString()),
                                                                        }
                                                                    }
                                                                }
                                                                console.log(jsonObjectStimaDescrGen)
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectStimaDescrGen), retDataStimeDescrGen => {
                                                                    resolvePrezzGen()
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                            })
                                                        }
                                                        await funcPrezziarioGen()
                                                        //GESTIONE DANNO DETTAGLI INFO RICHIESTE GENERALI ( LOCALIZZAZIONE)
                                                        const localizzazioneGEN = () => {
                                                            return new Promise((resolveLocalizzazioneGEN) => {
                                                                var descrizInfoRic = '';
                                                                var servizioStringa2 = '';

                                                                if (elm2.stato !== 'd') {
                                                                    var codiceInfoRic = document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}`).ej2_instances[0].value;
                                                                    let obj = _.find(this.nuovoArrRispInfoRichDanno, elm => {
                                                                        return elm.COD_ELE === codiceInfoRic;
                                                                    });
                                                                    if (!CustomJS.isNullEmpty(obj))
                                                                        servizioStringa2 = obj.SERVIZIO_STR2;

                                                                    let obj2 = _.find(this.nuovoArrInfoRichDanno, elm => {
                                                                        return elm.COD_ELE === servizioStringa2;
                                                                    });
                                                                    if (!CustomJS.isNullEmpty(obj2))
                                                                        descrizInfoRic = obj2.DESCRIZ;
                                                                }

                                                                var queryInfoRichiesta = ''
                                                                var jsonObjectInfoRich = {}

                                                                if (elm2.stato === 'n') {
                                                                    queryInfoRichiesta = `INSERT INTO GENERALI_dannoDettagli_infoRichieste (id_dettaglio,id_incarico,codice,descrizione,risposta) VALUES ((select id_dettaglio from GENERALI_danno_dettagli where guid_utile_dettaglio = '${guidGenerataDettaglio}' and id_incarico = '${this.props.IdIncarico}' FOR UPDATE),'${this.props.IdIncarico}',@codiceElemento,@descrizioneElemento,@noteRisposte);`;
                                                                }
                                                                else if (elm2.stato === 'o') {
                                                                    queryInfoRichiesta = `UPDATE GENERALI_dannoDettagli_infoRichieste set codice = @codiceElemento, descrizione = @descrizioneElemento, risposta = @noteRisposte where id_info_richiesta = ${elm2.keyGenInfoDett};`;
                                                                }
                                                                else if (elm2.stato === 'd') {
                                                                    queryInfoRichiesta = `DELETE FROM GENERALI_dannoDettagli_infoRichieste WHERE id_info_richiesta = ${elm2.keyGenInfoDett};`;
                                                                }
                                                                if (elm2.stato === 'd') {
                                                                    jsonObjectInfoRich = {
                                                                        QueryExec: queryInfoRichiesta
                                                                    }
                                                                }
                                                                else {
                                                                    jsonObjectInfoRich = {
                                                                        QueryExec: queryInfoRichiesta,
                                                                        codiceElemento: servizioStringa2,
                                                                        descrizioneElemento: descrizInfoRic,
                                                                        noteRisposte: CustomJS.isNullEmpty(document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}`).ej2_instances[0].value) ? '' : document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}`).ej2_instances[0].text
                                                                    }
                                                                }
                                                                console.log(jsonObjectInfoRich)
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectInfoRich), retDataInfo => {
                                                                    resolveLocalizzazioneGEN();
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                            })
                                                        }
                                                        await localizzazioneGEN()
                                                    }

                                                    resolvePrezziario('Prossima');
                                                }
                                                else {
                                                    resolvePrezziario('Prossima')
                                                }
                                            })
                                        }
                                        funcListPrezziari.push(eseguiPrezziario);
                                    })
                                    const eseguiCiclica = (ind) => {
                                        if (CustomJS.isNullEmpty(funcListPrezziari[ind])) {
                                            resolveFinePrezziari();
                                        }
                                        else {
                                            funcListPrezziari[ind]().then(ret => {
                                                if (ret === 'Prossima') {
                                                    eseguiCiclica(ind + 1);
                                                }
                                            }).catch(err => {
                                                console.log(err)
                                            })
                                        }
                                    }
                                    eseguiCiclica(0);
                                })
                            }
                            await finePrezziari();

                            //COASSICURAZIONI 
                            if (document.getElementById(`coassicurazioneSi-D${elm.d}`).ej2_instances[0].checked === true) {
                                console.log('inizio coassicurazioni si')
                                const fineCoassicurazioni = () => {
                                    return new Promise((resolveFineCoass) => {
                                        let funcListCoassicurazioniSI = [];
                                        this.strutturaStatiCoassicurazioniRighe.forEach((elm3) => {
                                            const eseguiCoassSI = () => {
                                                return new Promise(async (resolveCoassSI) => {

                                                    var guidGenerataCoass = CustomJS.createGuid4()
                                                    if (elm3.id === `rigaRiparto1-D${elm.d}-C${elm3.c}`) {
                                                        //COASSICURAZIONI XPERT
                                                        const coassicurazioneXpertSI = () => {
                                                            return new Promise((resolveCoassXpert) => {
                                                                var queryCoassXpert = ''
                                                                var jsonObjectCoassXpert = {}

                                                                if (elm3.stato === 'n') {
                                                                    queryCoassXpert = `INSERT INTO _righeCompagnieCoassicurazione (id_stima,id_sinistro,compagniaCoinvolta,dannoARiparto,polizzaCoass,tipoLimiteContrattuale,limiteContrattuale,dannoNetto,formula,dannoNettoRip2,guid_utile_coass) VALUES (${elm.keyXpert},${this.props.IdSinistro},@compagniaCoinvolta,@dannoARiparto,@numeroPolizzaCoass,@tipoLimiteContratt,@limiteContrattuale,@dannoNettoRip1,@formula,@dannoNettoRip2, '${guidGenerataCoass}');`;
                                                                }
                                                                else if (elm3.stato === 'o') {
                                                                    queryCoassXpert = `UPDATE _righeCompagnieCoassicurazione set compagniaCoinvolta = @compagniaCoinvolta, dannoARiparto = @dannoARiparto, polizzaCoass = @numeroPolizzaCoass, tipoLimiteContrattuale = @tipoLimiteContratt, limiteContrattuale = @limiteContrattuale, dannoNetto = @dannoNettoRip1, formula = @formula, dannoNettoRip2 = @dannoNettoRip2 WHERE id_compagnia_coass = ${elm3.keyXpert};`;
                                                                }
                                                                else if (elm3.stato === 'd') {
                                                                    queryCoassXpert = `DELETE FROM _righeCompagnieCoassicurazione  WHERE id_compagnia_coass = ${elm3.keyXpert};`;
                                                                }
                                                                if (elm3.stato === 'd') {
                                                                    jsonObjectCoassXpert = {
                                                                        QueryExec: queryCoassXpert
                                                                    }
                                                                }
                                                                else {
                                                                    jsonObjectCoassXpert = {
                                                                        QueryExec: queryCoassXpert,
                                                                        compagniaCoinvolta: CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '' : (document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value),
                                                                        dannoARiparto: CustomJS.isNullEmpty(document.getElementById(`dannoARipartoRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dannoARipartoRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value.toString()),
                                                                        numeroPolizzaCoass: CustomJS.isNullEmpty(document.getElementById(`numPolizzaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '' : (document.getElementById(`numPolizzaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value),
                                                                        tipoLimiteContratt: CustomJS.isNullEmpty(document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '' : (document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value),
                                                                        limiteContrattuale: CustomJS.isNullEmpty(document.getElementById(`limitazioneContrattualeRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '0' : (document.getElementById(`limitazioneContrattualeRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value.toString()),
                                                                        dannoNettoRip1: CustomJS.isNullEmpty(document.getElementById(`dannoNettoCoassDirRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dannoNettoCoassDirRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value.toString()),
                                                                        formula: CustomJS.isNullEmpty(document.getElementById(`formulaCoass-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '' : (document.getElementById(`formulaCoass-D${elm.d}-C${elm3.c}`).ej2_instances[0].value.toString()),
                                                                        dannoNettoRip2: CustomJS.isNullEmpty(document.getElementById(`dannNettoRip2-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '0' : (document.getElementById(`dannNettoRip2-D${elm.d}-C${elm3.c}`).ej2_instances[0].value.toString()),
                                                                    }
                                                                }
                                                                console.log(jsonObjectCoassXpert)
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCoassXpert), retDataCoassXpert => {
                                                                    resolveCoassXpert()
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                            })
                                                        }
                                                        await coassicurazioneXpertSI()
                                                        //COASSICURAZIONI GENERALI
                                                        var queryCoassGen = '';
                                                        var jsonObjectCoassGen = {}

                                                        if (elm3.stato === 'n') {
                                                            queryCoassGen = `START TRANSACTION; INSERT INTO GENERALI_coassi_indirette (id_compagnia_coass_xpert,id_danno,id_incarico,codice_ANIA,descrizione,numero_polizza,percentuale_rischio) VALUES ((select id_compagnia_coass from _righeCompagnieCoassicurazione where guid_utile_coass = '${guidGenerataCoass}' and id_sinistro = ${this.props.IdSinistro} FOR UPDATE),${elm.keyGen},'${this.props.IdIncarico}',@codiceAnia,@descrizione,@numeroPolizza,@quotaPerc); COMMIT;`;
                                                        }
                                                        else if (elm3.stato === 'o') {
                                                            queryCoassGen = `UPDATE GENERALI_coassi_indirette set codice_ANIA = @codiceAnia, descrizione = @descrizione, numero_polizza = @numeroPolizza, percentuale_rischio = @quotaPerc WHERE id_coassi_indiretta = ${elm3.keyGen};`;
                                                        }
                                                        else if (elm3.stato === 'd') {
                                                            queryCoassGen = `DELETE FROM GENERALI_coassi_indirette WHERE id_coassi_indiretta = ${elm3.keyGen};`;
                                                        }
                                                        if (elm3.stato === 'd') {
                                                            jsonObjectCoassGen = {
                                                                QueryExec: queryCoassGen
                                                            }
                                                        }
                                                        else {
                                                            jsonObjectCoassGen = {
                                                                QueryExec: queryCoassGen,
                                                                codiceAnia: CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '' : (document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value),
                                                                descrizione: CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '' : (document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].text),
                                                                numeroPolizza: CustomJS.isNullEmpty(document.getElementById(`numPolizzaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value) ? '' : (document.getElementById(`numPolizzaRiparto1-D${elm.d}-C${elm3.c}`).ej2_instances[0].value),
                                                                quotaPerc: '0' //percentuale rischio da gestire
                                                            }
                                                        }
                                                        console.log(jsonObjectCoassGen)
                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCoassGen), retDataCoassGen => {
                                                            resolveCoassSI('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            }
                                                            );
                                                        });
                                                    }
                                                    else {
                                                        resolveCoassSI('Prossima')
                                                    }
                                                })
                                            }
                                            funcListCoassicurazioniSI.push(eseguiCoassSI);
                                        })
                                        const eseguiCiclica = (ind) => {
                                            if (CustomJS.isNullEmpty(funcListCoassicurazioniSI[ind])) {
                                                resolveFineCoass();
                                            }
                                            else {
                                                funcListCoassicurazioniSI[ind]().then(ret => {
                                                    if (ret === 'Prossima') {
                                                        eseguiCiclica(ind + 1);
                                                    }
                                                }).catch(err => {
                                                    console.log(err)
                                                })
                                            }
                                        }
                                        eseguiCiclica(0);
                                    })
                                }
                                await fineCoassicurazioni();
                            }
                            else {
                                //Elimino tutte le coassicurazioni presenti se tolgo la coassicurazione del tutto
                                console.log('inizio coassicurazioni no')
                                const fineCoassNo = () => {
                                    return new Promise((resolveFineCoassNo) => {
                                        if (this.strutturaStatiCoassicurazioniRighe.length !== 0) {

                                            let funcListCoassNO = [];
                                            this.strutturaStatiCoassicurazioniRighe.forEach((elm3) => {
                                                const eseguiCoassNO = () => {
                                                    return new Promise((resolveCoassNO) => {
                                                        if (elm3.id === `rigaRiparto1-D${elm.d}-C${elm3.c}`) {
                                                            if (elm3.stato === 'd') {
                                                                //Variabili query
                                                                var queryCoassXpert = ''
                                                                var queryCoassGen = ''
                                                                //Variabili JsonObject
                                                                var jsonObjectCoassXpert = {}
                                                                var jsonObjectCoassGen = {}

                                                                queryCoassXpert = `DELETE FROM _righeCompagnieCoassicurazione  WHERE id_compagnia_coass = ${elm3.keyXpert};`;
                                                                if (this.isCompagniaGenerali())
                                                                    queryCoassGen = `DELETE FROM GENERALI_coassi_indirette WHERE id_coassi_indiretta = ${elm3.keyGen};`;

                                                                jsonObjectCoassXpert = {
                                                                    QueryExec: queryCoassXpert
                                                                }
                                                                if (this.isCompagniaGenerali()) {
                                                                    jsonObjectCoassGen = {
                                                                        QueryExec: queryCoassGen
                                                                    }
                                                                }
                                                                console.log(jsonObjectCoassXpert)
                                                                console.log(jsonObjectCoassGen)
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCoassXpert), retDataCoassXpert => {
                                                                    if (this.isCompagniaGenerali()) {
                                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCoassGen), retDataCoassGen => {
                                                                            resolveCoassNO('Prossima');
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            }
                                                                            );
                                                                        });
                                                                    }
                                                                    else {
                                                                        resolveCoassNO('Prossima');
                                                                    }
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                });
                                                            }
                                                            else {
                                                                resolveCoassNO('Prossima');
                                                            }
                                                        } else {
                                                            resolveCoassNO('Prossima');
                                                        }
                                                    })
                                                }
                                                funcListCoassNO.push(eseguiCoassNO);
                                            })
                                            const eseguiCiclica = (ind) => {
                                                if (CustomJS.isNullEmpty(funcListCoassNO[ind])) {
                                                    resolveFineCoassNo();
                                                }
                                                else {
                                                    funcListCoassNO[ind]().then(ret => {
                                                        if (ret === 'Prossima') {
                                                            eseguiCiclica(ind + 1);
                                                        }
                                                    }).catch(err => {
                                                        console.log(err)
                                                    })
                                                }
                                            }
                                            eseguiCiclica(0);
                                        }
                                        else {
                                            //neanche una coassicurazione
                                            resolveFineCoassNo()
                                        }
                                    })
                                }
                                await fineCoassNo();
                            }
                            resolveStima('Prossima');
                        })
                    }
                    funcListStime.push(eseguiStima);
                })

                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListStime[ind])) {
                        resolveFineStime()
                    }
                    else {
                        funcListStime[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }
        await fineStime();
        //-------------------------FINE QUERY STIME-------------------------

        //-----------------------INIZIO QUERY INFO RICHIESTE COMPAGNIA-----------------------
        if (this.isCompagniaGenerali()) {
            console.log('inizio info richieste')

            this.strutturaStatiInfoRichiesteDelete.forEach(elm => {
                var queryElimina = `DELETE FROM _info_richieste_compagnia_riep WHERE id_info_richieste_compagnia = ${elm.keyXpert};DELETE FROM GENERALI_danno_infoRichieste WHERE id_info_richiesta = ${elm.keyGen};`;
                var jsonObjectElim = {
                    QueryExec: queryElimina,
                }
                console.log(jsonObjectElim)
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectElim), retDataEliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            })

            const fineInfoRichiesteStime = () => {
                return new Promise((resolveFineInfoRichieste) => {

                    let funcListStime = [];
                    this.strutturaStatiStimeBase.forEach(async (elm) => {
                        const eseguiStima = () => {
                            return new Promise(async (resolveStima) => {

                                var str2 = document.getElementById(`garanziaFromSin-D${elm.d}`).ej2_instances[0].itemData.SERVIZIO_STR2;
                                var categorie = []
                                this.nuovoArrInfoRichRiep.forEach((elmS) => {
                                    if (elmS.SERVIZIO_STR2 === str2)
                                        categorie.push({
                                            VALUE: elmS.COD_ELE
                                        })
                                })
                                for (let n = 0; n < categorie.length; n++) {
                                    if (document.getElementById(`listbox-t${elm.d}-n${n}`)) {
                                        var numeroElementi = document.getElementById(`listbox-t${elm.d}-n${n}`).ej2_instances[0].value;
                                        for (let index = 0; index < numeroElementi.length; index++) {
                                            var guidGenerata = CustomJS.createGuid4();
                                            var descrizioneElemento = '';
                                            document.getElementById(`listbox-t${elm.d}-n${n}`).ej2_instances[0].jsonData.forEach(descr => {
                                                if (descr.id === numeroElementi[index])
                                                    descrizioneElemento = descr.text;
                                            })
                                            const funcListbox = () => {
                                                return new Promise((resolveList) => {
                                                    var queryInfoRichieste = `INSERT INTO _info_richieste_compagnia_riep (id_stima,id_sinistro,id_categoria,id_elemento,guid_utile) VALUES (@idStima,${this.props.IdSinistro},@idCategoria,@idElemento,@guidUtile);`;
                                                    var jsonObjectInfoRichieste = {
                                                        QueryExec: queryInfoRichieste,
                                                        idStima: CustomJS.isNullEmpty(document.getElementById(`idStima-D${elm.d}`).ej2_instances[0].value) ? '0' : document.getElementById(`idStima-D${elm.d}`).ej2_instances[0].value.toString(),
                                                        idCategoria: CustomJS.isNullEmpty(document.getElementById(`elmCategorie-t${elm.d}-n${n}`).ej2_instances[0].value) ? '' : document.getElementById(`elmCategorie-t${elm.d}-n${n}`).ej2_instances[0].value,
                                                        idElemento: numeroElementi[index],
                                                        guidUtile: guidGenerata
                                                    }
                                                    console.log(jsonObjectInfoRichieste)
                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectInfoRichieste), retDataInfoRichieste => {
                                                        resolveList();
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        }
                                                        );
                                                    });
                                                });
                                            }
                                            await funcListbox();
                                            var queryInfoRichieste2 = `INSERT INTO GENERALI_danno_infoRichieste (id_info_rich_xpert,id_incarico,id_danno,codice,descrizione,risposta) VALUES ((SELECT id_info_richieste_compagnia FROM _info_richieste_compagnia_riep WHERE guid_utile = '${guidGenerata}' and id_sinistro = ${this.props.IdSinistro} FOR UPDATE),'${this.props.IdIncarico}',@idDanno,@codiceElemento,@categoriaDescrizione,@descrizioneElemento);`
                                            var jsonObjectInfoRichieste2 = {
                                                QueryExec: queryInfoRichieste2,
                                                //Generali
                                                idDanno: CustomJS.isNullEmpty(document.getElementById(`idStima-D${elm.d}`).ej2_instances[0].itemData.KEYGEN) ? '0' : document.getElementById(`idStima-D${elm.d}`).ej2_instances[0].itemData.KEYGEN.toString(),
                                                codiceElemento: CustomJS.isNullEmpty(document.getElementById(`elmCategorie-t${elm.d}-n${n}`).ej2_instances[0].value) ? '' : document.getElementById(`elmCategorie-t${elm.d}-n${n}`).ej2_instances[0].value,
                                                categoriaDescrizione: CustomJS.isNullEmpty(document.getElementById(`elmCategorie-t${elm.d}-n${n}`).ej2_instances[0].text) ? '' : document.getElementById(`elmCategorie-t${elm.d}-n${n}`).ej2_instances[0].text,
                                                descrizioneElemento: descrizioneElemento
                                            }
                                            console.log(jsonObjectInfoRichieste2)
                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectInfoRichieste2), retDataInfoRichieste2 => {
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                }
                                                );
                                            });
                                        }
                                    }
                                }
                                resolveStima('Prossima');
                            })
                        }
                        funcListStime.push(eseguiStima);
                    })
                    const eseguiCiclica = (ind) => {
                        if (CustomJS.isNullEmpty(funcListStime[ind])) {
                            resolveFineInfoRichieste();
                        }
                        else {
                            funcListStime[ind]().then(ret => {
                                if (ret === 'Prossima') {
                                    eseguiCiclica(ind + 1);
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                        }
                    }
                    eseguiCiclica(0);
                })
            }
            await fineInfoRichiesteStime();

        }
        //-----------------------FINE QUERY INFO RICHIESTE COMPAGNIA-----------------------

        console.log('inizio altre operazioni')

        //-----------------------UPDATE TOTALI NELLA PERIZIA INCARICO INIZIO-----------------------
        var queryUpdateTotali = `UPDATE _perizia_incarico_info set tot_indennizzo_lordo_compl = @totIndennizzoLordoComplessivo, tot_indennizzo_netto_compl = @totIndennizzoNettoComplessivo, tot_indennizzo_netto_compl_arrot = @totIndennizzoNettoComplArrotondato, tot_danni_accertati = @totDanniAccertati where id_sinistro = ${this.props.IdSinistro};`;
        var totIndennizzoNettoComplArrotondato = CustomJS.isNullEmpty(document.getElementById('totaleIndennNettoComplApprossRiepMAN').ej2_instances[0].value) ? document.getElementById('totaleIndennNettoComplApprossRiep').ej2_instances[0].value : document.getElementById('totaleIndennNettoComplApprossRiepMAN').ej2_instances[0].value

        var jsonObjectUpdateTotali = {
            QueryExec: queryUpdateTotali,
            totIndennizzoLordoComplessivo: CustomJS.isNullEmpty(document.getElementById('totaleIndennizziLordi').ej2_instances[0].value) ? '0' : document.getElementById('totaleIndennizziLordi').ej2_instances[0].value.toString(),
            totIndennizzoNettoComplessivo: CustomJS.isNullEmpty(document.getElementById('totaleIndennizziNetti').ej2_instances[0].value) ? '0' : document.getElementById('totaleIndennizziNetti').ej2_instances[0].value.toString(),
            totIndennizzoNettoComplArrotondato: CustomJS.isNullEmpty(totIndennizzoNettoComplArrotondato) ? '0' : totIndennizzoNettoComplArrotondato.toString(),
            totDanniAccertati: CustomJS.isNullEmpty(document.getElementById('totaleDanniComplessiviAccertati').ej2_instances[0].value) ? '0' : document.getElementById('totaleDanniComplessiviAccertati').ej2_instances[0].value.toString(),
        }
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectUpdateTotali), retDataUpdateTotali => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });

        //-----------------------UPDATE TOTALI NELLA PERIZIA INCARICO FINE-----------------------

        //-----------------------UPDATE GENERALI DANNEGGIATI IMPORTO NETTO E LORDO + UPDATE GENERALI DANNEGGIATI LIQUIDAZIONI-----------------------

        if (this.isCompagniaGenerali()) {
            var importoNetto = 0
            var importoLordo = 0
            for (let d = 0; d < this.numTabelleRiepilogo; d++) {
                if (document.getElementById(`containerTabRiepilogo-D${d}`)) {
                    //il lordo e il netto sono scambiati
                    importoLordo = CustomJS.isNullEmpty(document.getElementById(`totaleIndennizzoLordo-D${d}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleIndennizzoLordo-D${d}`).ej2_instances[0].value;
                    var suppInde = CustomJS.isNullEmpty(document.getElementById(`totaleSuppIndenn-D${d}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleSuppIndenn-D${d}`).ej2_instances[0].value;
                    importoLordo = parseFloat((importoLordo + suppInde).toFixed(2));
                    var totFranchige = CustomJS.isNullEmpty(document.getElementById(`totaleFranchige-D${d}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleFranchige-D${d}`).ej2_instances[0].value;
                    importoNetto = parseFloat((importoLordo - totFranchige).toFixed(2));
                    importoNetto = importoNetto < 0 ? 0 : importoNetto;
                    var queryDaEseguire5 = `UPDATE GENERALI_danneggiati SET importo_netto = @importoNetto, importo_lordo = @importoLordo WHERE id_danneggiato = '${document.getElementById(`danneggiatoRiep-D${d}`).ej2_instances[0].itemData.GUID}';`;
                    var jsonObjectGenDann = {
                        QueryExec: queryDaEseguire5,
                        importoNetto: importoNetto.toString(),
                        importoLordo: importoLordo.toString()
                    }
                    console.log(jsonObjectGenDann)
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectGenDann), retDataGenDann => {
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    });
                    var importoLiq = CustomJS.isNullEmpty(document.getElementById(`totaleIndennizzo-D${d}`).ej2_instances[0].value) ? 0 : (document.getElementById(`totaleIndennizzo-D${d}`).ej2_instances[0].value);

                    var queryDaEseguireLiq = `START TRANSACTION;UPDATE GENERALI_danneggiato_liquidazioni SET arrotondamento = @arrotondamento, importo_liquidazione = @importoLiquidazione WHERE id_danneggiato = '${document.getElementById(`danneggiatoRiep-D${d}`).ej2_instances[0].itemData.GUID}'; SELECT ROW_COUNT(); || insert into GENERALI_danneggiato_liquidazioni (id_danneggiato,arrotondamento,importo_liquidazione) values ('${document.getElementById(`danneggiatoRiep-D${d}`).ej2_instances[0].itemData.GUID}',@arrotondamento, @importoLiquidazione);COMMIT;`;
                    var jsonObjectLiqGen = {
                        QueryExec: queryDaEseguireLiq,
                        SecondaQueryDipendente: 'true',
                        arrotondamento: '0', //todo chiedi a giovanni
                        importoLiquidazione: importoLiq.toString()
                    }
                    console.log(jsonObjectLiqGen)
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectLiqGen), retDataLiqGen => {
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    });
                }
            }
        }
        if (this.isCompagniaUnipol()) {
            //Cerco coassicurazione
            var almenoUnaCoass = false;
            for (var d = 1; d < this.numStime; d++) {
                if (document.getElementById(`tabellaStima-D${d}`)) {
                    if (document.getElementById(`coassicurazioneSi-D${d}`).ej2_instances[0].checked) {
                        almenoUnaCoass = true;
                        break;
                    }
                }
            }
            //TOTALI RIEPILOGO
            var queryTotRiepilogo = `update UNIPOLSAI_Perizia set importoPeriziato = @totDanniAccertati, importoConcordato = @totIndennizzoNettoComplArrotondato, importoLiquidabile = @totIndennizzoNettoComplArrotondato2, presenzaAltreAssicurazioniStessoRischio = @coassicurazIndirPresente where id_sinistro_xpert = ${this.props.IdSinistro};`;
            var totIndennizzoNettoComplArrotondato = CustomJS.isNullEmpty(document.getElementById('totaleIndennNettoComplApprossRiepMAN').ej2_instances[0].value) ? document.getElementById('totaleIndennNettoComplApprossRiep').ej2_instances[0].value : document.getElementById('totaleIndennNettoComplApprossRiepMAN').ej2_instances[0].value

            var jsonObjectRiepilogo = {
                QueryExec: queryTotRiepilogo,
                totDanniAccertati: CustomJS.isNullEmpty(document.getElementById(`totaleDanniComplessiviAccertati`).ej2_instances[0].value) ? '0' : document.getElementById(`totaleDanniComplessiviAccertati`).ej2_instances[0].value.toString(),
                totIndennizzoNettoComplArrotondato: CustomJS.isNullEmpty(totIndennizzoNettoComplArrotondato) ? '0' : totIndennizzoNettoComplArrotondato.toString(),
                totIndennizzoNettoComplArrotondato2: CustomJS.isNullEmpty(totIndennizzoNettoComplArrotondato) ? '0' : totIndennizzoNettoComplArrotondato.toString(),
                coassicurazIndirPresente: almenoUnaCoass === true ? '1' : '0'
            }
            var queryCoassUnipol = `update UNIPOLSAI_Osservazioni set noteCoassicurazioniIndirette = @noteCoassicurazione where id_sinistro_xpert = ${this.props.IdSinistro};`;
            var jsonObjectCoassUnipol = {
                QueryExec: queryCoassUnipol,
                noteCoassicurazione: CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazioneUnipol`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazioneUnipol`).ej2_instances[0].value,
            }
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectRiepilogo), retDataRiep => {
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectCoassUnipol), retDataCoass => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        }

        //------------------------ELIMINA PRECEDENTI RIEPILOGO INIZIO--------------------------------
        this.strutturaRiepilogoDelete.IdDanneggiati.forEach(elm => {
            var queryEliminaDann = `DELETE FROM _perizia_riepilogo_danneggiati WHERE idDanneggiatoRiep = ${elm};`;
            var jsonObjectEliminDann = {
                QueryExec: queryEliminaDann,
            }
            console.log(jsonObjectEliminDann)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminDann), retDataEliminato1 => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        });
        this.strutturaRiepilogoDelete.IdRighe.forEach(elm => {
            var queryEliminaRiga = `DELETE FROM _perizia_riep_righeDanneggiato WHERE idGarPart = ${elm};`;
            var jsonObjectEliminRiga = {
                QueryExec: queryEliminaRiga,
            }
            console.log(jsonObjectEliminRiga)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminRiga), retDataEliminato2 => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        })

        //------------------------ELIMINA PRECEDENTI RIEPILOGO FINE----------------------------------

        //----------------------SALVA DATI RIEPILOGO PER DOC PERIZIA INIZIO----------

        var queryRiep = ""
        if (!CustomJS.isNullEmpty(this.strutturaRiepilogoDelete.IdRiepilogo)) {
            queryRiep = `update _riepilogo_perizia set finali_indennizzi_lordi = @totIndennizzoLordoComplessivo, finali_franchigie = @finaliFranchigie, finali_suppl_indenn = @finaliSupplIndenn,finali_indenn_netti = @totIndennizzoNettoComplessivo, finali_danni_compl_acc = @totDanniAccertati, finali_indenn_dopo_scop = @finaliIndennDopoScop, finali_indenn_netti_arrotond = @totIndennizzoNettoComplArrotondato, finali_indenn_netti_arrotond_MAN = @totIndennizzoNettoComplArrotondato2 where idRiepilogo_perizia = ${this.strutturaRiepilogoDelete.IdRiepilogo};`;
        }
        else {
            queryRiep = `insert into _riepilogo_perizia (id_sinistro,finali_indennizzi_lordi,finali_franchigie,finali_suppl_indenn,finali_indenn_netti,finali_danni_compl_acc,finali_indenn_dopo_scop,finali_indenn_netti_arrotond,finali_indenn_netti_arrotond_MAN) values (${this.props.IdSinistro},@totIndennizzoLordoComplessivo,@finaliFranchigie,@finaliSupplIndenn,@totIndennizzoNettoComplessivo,@totDanniAccertati,@finaliIndennDopoScop,@totIndennizzoNettoComplArrotondato,@totIndennizzoNettoComplArrotondato2);`;
        }

        var jsonObjectRiep = {
            QueryExec: queryRiep,
            totIndennizzoLordoComplessivo: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizziLordi`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleIndennizziLordi`).ej2_instances[0].value.toString()),
            finaliFranchigie: CustomJS.isNullEmpty(document.getElementById(`totaleFranchigeScoperti`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleFranchigeScoperti`).ej2_instances[0].value.toString()),
            finaliSupplIndenn: CustomJS.isNullEmpty(document.getElementById(`totaleSupplIndennita`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleSupplIndennita`).ej2_instances[0].value.toString()),
            totIndennizzoNettoComplessivo: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizziNetti`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleIndennizziNetti`).ej2_instances[0].value.toString()),
            totDanniAccertati: CustomJS.isNullEmpty(document.getElementById(`totaleDanniComplessiviAccertati`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleDanniComplessiviAccertati`).ej2_instances[0].value.toString()),
            finaliIndennDopoScop: CustomJS.isNullEmpty(document.getElementById(`indennizzoDopoEventualeScop`).ej2_instances[0].value) ? '0' : (document.getElementById(`indennizzoDopoEventualeScop`).ej2_instances[0].value.toString()),
            totIndennizzoNettoComplArrotondato: CustomJS.isNullEmpty(document.getElementById(`totaleIndennNettoComplApprossRiep`).ej2_instances[0].value) ? '0' : document.getElementById(`totaleIndennNettoComplApprossRiep`).ej2_instances[0].value.toString(),
            //totIndennizzoNettoComplArrotondato2 = valore manuale
            totIndennizzoNettoComplArrotondato2: CustomJS.isNullEmpty(document.getElementById(`totaleIndennNettoComplApprossRiepMAN`).ej2_instances[0].value) ? '0' : document.getElementById(`totaleIndennNettoComplApprossRiepMAN`).ej2_instances[0].value.toString()
        }
        if (jsonObjectRiep.totIndennizzoNettoComplArrotondato2 !== '0') {
            jsonObjectRiep.totIndennizzoNettoComplArrotondato = jsonObjectRiep.totIndennizzoNettoComplArrotondato2
        }
        console.log(jsonObjectRiep)
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectRiep), async (retDataRiep) => {
            for (let d = 0; d < this.numTabelleRiepilogo; d++) {
                if (document.getElementById(`containerTabRiepilogo-D${d}`)) {
                    var guiUtileDann = CustomJS.createGuid4();
                    //DANNEGGIATO
                    const funcDanneggiato = () => {
                        return new Promise((resolveDann) => {
                            var queryDanneggiato = `insert into _perizia_riepilogo_danneggiati (id_sinistro,danneggiato,id_danneggiato,tot_indenn_lordo,tot_franchigia,tot_supp_indenn,tot_indenn_netto,guid_utile_dann) values (${this.props.IdSinistro},@danneggiato,@idDanneggiato,@totIndennizzoLordoDann,@totFranchigiaDann,@totSupplIndennDann,@totIndennNettoDann,'${guiUtileDann}');`;
                            var jsonObjectDann = {
                                QueryExec: queryDanneggiato,
                                danneggiato: CustomJS.isNullEmpty(document.getElementById(`danneggiatoRiep-D${d}`).ej2_instances[0].text) ? '' : (document.getElementById(`danneggiatoRiep-D${d}`).ej2_instances[0].text),
                                idDanneggiato: CustomJS.isNullEmpty(document.getElementById(`danneggiatoRiep-D${d}`).ej2_instances[0].value) ? '' : (document.getElementById(`danneggiatoRiep-D${d}`).ej2_instances[0].value.toString()),
                                totIndennizzoLordoDann: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizzoLordo-D${d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleIndennizzoLordo-D${d}`).ej2_instances[0].value.toString()),
                                totFranchigiaDann: CustomJS.isNullEmpty(document.getElementById(`totaleFranchige-D${d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleFranchige-D${d}`).ej2_instances[0].value.toString()),
                                totSupplIndennDann: CustomJS.isNullEmpty(document.getElementById(`totaleSuppIndenn-D${d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleSuppIndenn-D${d}`).ej2_instances[0].value.toString()),
                                totIndennNettoDann: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizzo-D${d}`).ej2_instances[0].value) ? '0' : (document.getElementById(`totaleIndennizzo-D${d}`).ej2_instances[0].value.toString())
                            }
                            console.log(jsonObjectDann)
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDann), retDataDann => {
                                resolveDann();
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                }
                                );
                            });
                        })
                    }
                    await funcDanneggiato();

                    for (let g = 0; g < this.numRigheRiepilogoGaranzie; g++) {
                        if (document.getElementById(`garanziaRiepIntestazione-D${d}-R-${g}`)) {
                            const funcRigaDanneggiato = () => {
                                return new Promise((resolveRiga) => {
                                    var queryGarPart = `START TRANSACTION;insert into _perizia_riep_righeDanneggiato (id_riep_danneggiato,id_sinistro,garanzia,partita,indenn_lordo_uso,franchigia,suppl_indenn,indenn_netto) values ((select idDanneggiatoRiep from _perizia_riepilogo_danneggiati where guid_utile_dann = '${guiUtileDann}' for update),${this.props.IdSinistro},@garanziaDescr,@partitaDescr,@indennLordo,@franchigia,@supplIndennImporto,@indennNetto);COMMIT;`;
                                    var jsonObjectGarPart = {
                                        QueryExec: queryGarPart,
                                        garanziaDescr: CustomJS.isNullEmpty(document.getElementById(`garanziadannRiep-D${d}-R-${g}`).ej2_instances[0].text) ? '' : (document.getElementById(`garanziadannRiep-D${d}-R-${g}`).ej2_instances[0].text),
                                        partitaDescr: CustomJS.isNullEmpty(document.getElementById(`partitadannRiep-D${d}-R-${g}`).ej2_instances[0].text) ? '' : (document.getElementById(`partitadannRiep-D${d}-R-${g}`).ej2_instances[0].text),
                                        indennLordo: CustomJS.isNullEmpty(document.getElementById(`indennizzoLordoRiep-D${d}-${g}`).ej2_instances[0].value) ? '0' : (document.getElementById(`indennizzoLordoRiep-D${d}-${g}`).ej2_instances[0].value.toString()),
                                        franchigia: CustomJS.isNullEmpty(document.getElementById(`franchigiaRiep-D${d}-${g}`).ej2_instances[0].value) ? '0' : (document.getElementById(`franchigiaRiep-D${d}-${g}`).ej2_instances[0].value.toString()),
                                        supplIndennImporto: CustomJS.isNullEmpty(document.getElementById(`supplIndennRiepilogo-D${d}-${g}`).ej2_instances[0].value) ? '0' : (document.getElementById(`supplIndennRiepilogo-D${d}-${g}`).ej2_instances[0].value.toString()),
                                        indennNetto: CustomJS.isNullEmpty(document.getElementById(`indennizzoNettoRiep-D${d}-${g}`).ej2_instances[0].value) ? '0' : (document.getElementById(`indennizzoNettoRiep-D${d}-${g}`).ej2_instances[0].value.toString()),
                                    }
                                    console.log(jsonObjectGarPart)
                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectGarPart), retDataGP => {
                                        resolveRiga()
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                    });
                                })
                            }
                            await funcRigaDanneggiato();
                        }
                    }
                }
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
        //---------------------------SALVO DEFINIZIONE INIZIO-------------------------
        var definizione = `update _perizia_incarico_info set D_note = @definizioneNote where id_sinistro = ${this.props.IdSinistro}`;
        var jsonObjectDefinizione = {
            QueryExec: definizione,
            definizioneNote: CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value) ? '' : document.getElementById('noteDefinizione').ej2_instances[0].value,
        }
        console.log(jsonObjectDefinizione)
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDefinizione), retDataDef => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });

        if (this.isCompagniaGenerali()) {
            var definizioneGen = `update GENERALI_Incarico set note_osservazioni_finali = @definizioneNote where id_incarico = '${this.props.IdIncarico}';`;
            var jsonObjectDefinizioneGen = {
                QueryExec: definizioneGen,
                definizioneNote: CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value) ? '' : document.getElementById('noteDefinizione').ej2_instances[0].value,
            }
            console.log(jsonObjectDefinizioneGen)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDefinizioneGen), retDataDef => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        }

        if (this.isCompagniaUnipol()) {
            var definizioneUni = `update UNIPOLSAI_Osservazioni set noteConclusive = @definizioneNote where id_sinistro_xpert = ${this.props.IdSinistro}`;
            var jsonObjectDefinizioneUni = {
                QueryExec: definizioneUni,
                definizioneNote: CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value) ? '' : document.getElementById('noteDefinizione').ej2_instances[0].value,
            }
            console.log(jsonObjectDefinizioneUni)
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectDefinizioneUni), retDataDef => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        }
        //---------------------------SALVO DEFINIZIONE FINE-------------------------
        //---------------SALVA DATI RIEPILOGO PER DOC PERIZIA FINE----------

        // VAI ALLA PAGINA PERIZIA UNO
        setTimeout(() => {
            console.log("tempo totale : " + tempoTotale)
            this.cambiaPaginaFooter('Dettaglio', 'fromSalva');
        }, tempoTotale)
    }
    //------------------------------------FUNZIONI CREAZIONE CARICA SALVA INIZIO------------------------------------

    requiredCampiTabelle() {
        //PREESISTENZE
        this.strutturaStatiPreesistenzeBase.forEach(elm => {
            this.formOptions.rules[`sommaAssicurataInput_T${elm.t}`] = {
                required: [true, "Somma Assicurata è un campo obbligatorio!"]
            }
            this.formOptions.rules[`derogaInput_T${elm.t}`] = {
                required: [true, "Deroga è un campo obbligatorio!"]
            }
            this.formOptions.rules[`valoreDegradoInput_T${elm.t}`] = {
                required: [true, "Valore Derogato è un campo obbligatorio!"]
            }
            if (document.getElementById(`nomePartita${elm.t}`).ej2_instances[0].value === 'TP016') {
                this.strutturaStatiPreesistenzeCostruzioni.forEach(elm2 => {
                    if (elm2.stato !== 'd') {
                        if (document.getElementById(`rigaPreesistenzaCostruzione-T${elm.t}-R-${elm2.r}`)) {
                            this.formOptions.rules[`valoreUnitarioInput_T${elm.t}_R_${elm2.r}`] = {
                                required: [true, "Valore Unitario è un campo obbligatorio!"]
                            }
                            this.formOptions.rules[`superficieEquivalenteInput_T${elm.t}_R_${elm2.r}`] = {
                                required: [true, "Superficie è un campo obbligatorio!"]
                            }
                            this.formOptions.rules[`altezzaInput_T${elm.t}_R_${elm2.r}`] = {
                                required: [true, "Altezza è un campo obbligatorio!"]
                            }
                        }
                    }
                    else if (elm2.stato === 'd') {
                        if (this.formOptions.rules[`volumeResult_T${elm.t}_R_${elm2.r}`])
                            delete this.formOptions.rules[`volumeResult_T${elm.t}_R_${elm2.r}`];
                        if (this.formOptions.rules[`valoreRicostruzioneResult_T${elm.t}_R_${elm2.r}`])
                            delete this.formOptions.rules[`valoreRicostruzioneResult_T${elm.t}_R_${elm2.r}`];
                    }
                })
            }
            this.strutturaStatiPreesistenzeDescrizioni.forEach(elm2 => {
                if (elm2.stato !== 'd') {
                    if (document.getElementById(`rigaPreesDescrizione-T${elm.t}-R-${elm2.r}`)) {
                        this.formOptions.rules[`descrizioneCorpo_T${elm.t}_R_${elm2.r}`] = {
                            required: [true, "Descrizione è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}`] = {
                            required: [true, "Valore è un campo obbligatorio!"]
                        }
                    }
                }
            })
        })
        //STIME
        this.strutturaStatiStimeBase.forEach(elm => {
            this.formOptions.rules[`piano-D${elm.d}`] = {
                required: [true, "Piano è un campo obbligatorio!"]
            }
            if (this.isCompagniaGenerali()) {
                this.formOptions.rules[`esitoDanno_D${elm.d}`] = {
                    required: [true, "Esito Danno è un campo obbligatorio!"]
                }
                //non indennizzabile
                if (document.getElementById(`esitoDanno_D${elm.d}`).ej2_instances[0].value === '4') {
                    this.formOptions.rules[`causaliNonIndennizzo_D${elm.d}`] = {
                        required: [true, `Causali Non indennizzo è un campo obbligatorio se l' esito è "Non Indennizabile"!`]
                    }
                }
            }
            //PREZZIARI
            this.strutturaStatiStimePrezziari.forEach(elm2 => {
                if (elm2.stato !== 'd') {
                    if (document.getElementById(`tabPrezziario-D${elm.d}-R-${elm2.r}`)) {
                        this.formOptions.rules[`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}`] = {
                            required: [true, "Descrizione Prezziario è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`UMInput_D${elm.d}_R${elm2.r}`] = {
                            required: [true, "Unità Misura è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`quantitaInput_D${elm.d}_R${elm2.r}`] = {
                            required: [true, "Quantità è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`valUnitarioInput_D${elm.d}_R${elm2.r}`] = {
                            required: [true, "Valore Unitario è un campo obbligatorio!"]
                        }
                        if (this.isCompagniaGenerali()) {
                            this.formOptions.rules[`categoriaSceltaPrezziario_D${elm.d}_R${elm2.r}`] = {
                                required: [true, "Categoria Prezziario è un campo obbligatorio!"]
                            }
                            this.formOptions.rules[`localizzazione_D${elm.d}_R${elm2.r}`] = {
                                required: [true, "Localizzazione è un campo obbligatorio!"]
                            }
                        }
                        if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '003') {
                            this.formOptions.rules[`percDegrSupInd_D${elm.d}_R${elm2.r}`] = {
                                required: [true, "Percentuale Degrado è un campo obbligatorio!"]
                            }
                        }
                    }
                }
                else if (elm2.stato === 'd') {
                    if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '001') {
                        if (this.formOptions.rules[`totaleResult_D${elm.d}_R${elm2.r}`])
                            delete this.formOptions.rules[`totaleResult_D${elm.d}_R${elm2.r}`];
                    }
                    else if (document.getElementById(`determinazioneDanno_D${elm.d}`).ej2_instances[0].value === '003') {
                        if (this.formOptions.rules[`valANuovoInput_D${elm.d}_R${elm2.r}`])
                            delete this.formOptions.rules[`valANuovoInput_D${elm.d}_R${elm2.r}`];
                        if (this.formOptions.rules[`importoDegrSupInd_D${elm.d}_R${elm2.r}`])
                            delete this.formOptions.rules[`importoDegrSupInd_D${elm.d}_R${elm2.r}`];
                        if (this.formOptions.rules[`valoreBeneResult_D${elm.d}_R${elm2.r}`])
                            delete this.formOptions.rules[`valoreBeneResult_D${elm.d}_R${elm2.r}`];
                    }
                }
            })
            //COASSICURAZIONI
            this.strutturaStatiCoassicurazioniRighe.forEach(elm2 => {
                if (this.formOptions.rules[`dannoARipartoRiparto1-D${elm.d}-C${elm2.c}`])
                    delete this.formOptions.rules[`dannoARipartoRiparto1-D${elm.d}-C${elm2.c}`];
                if (this.formOptions.rules[`limitazioneContrattualeRiparto1-D${elm.d}-C${elm2.c}`])
                    delete this.formOptions.rules[`limitazioneContrattualeRiparto1-D${elm.d}-C${elm2.c}`];
                if (this.formOptions.rules[`dannoNettoCoassDirRiparto1-D${elm.d}-C${elm2.c}`])
                    delete this.formOptions.rules[`dannoNettoCoassDirRiparto1-D${elm.d}-C${elm2.c}`];
                if (this.formOptions.rules[`dannNettoRip2-D${elm.d}-C${elm2.c}`])
                    delete this.formOptions.rules[`dannNettoRip2-D${elm.d}-C${elm2.c}`];
            })
        });

        this.formObject = new FormValidator('#formElaboratoPeritaleCalcoli', this.formOptions);
        console.log(this.formObject)

        if (this.formObject.validate() && !CustomJS.isNullEmpty( document.getElementById('noteDefinizione').ej2_instances[0].value?.trim())) {
            document.getElementById('salvaEsciP2').ej2_instances[0].disabled = true;
            this.salvaDati();
        }
        else {
            var messaggioCampiMancanti = '';
            this.formObject.errorRules.forEach(elm => {
                messaggioCampiMancanti = messaggioCampiMancanti + elm.message + '\n';
            });
            if (CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value?.trim()))
                messaggioCampiMancanti += 'Note Definizione è un campo obbligatorio';
            toast.warn(messaggioCampiMancanti, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }
    }

    inserisciValorePrezziarioSuDropDown(numDann, numRiga, args) {
        document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value = args.rowData.COD_ELE;
        this.dialogInstance0.hide();
    }


    apriDialogPrezziario(numDann, numRiga) {
        this.dialogInstance0.width = '40%';
        this.dialogInstance0.height = '75%';
        this.dialogInstance0.minHeight = '75%';
        this.dialogInstance0.buttons = [];
        this.dialogInstance0.beforeOpen = (args) => {
            const container = document.getElementById('dialogPrezziarioContent');
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance0.open = (args) => {
            const container = document.getElementById('dialogPrezziarioContent');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                document.getElementById(`gridPrezziario`).ej2_instances[0].pageSettings.pageSize = "10";
                //se c'è la descrizione prezziario quando apri la dialog è già selezionato in blu
                var codElePrezziario = document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}`).ej2_instances[0].value;
                if (!CustomJS.isNullEmpty(codElePrezziario)) {
                    document.getElementById(`gridPrezziario`).ej2_instances[0].pageSettings.pageSize = "Tutti";
                    setTimeout(() => {
                        let idx = _.findIndex(document.getElementById(`gridPrezziario`).ej2_instances[0].dataSource, elm => {
                            if (elm.COD_ELE === codElePrezziario)
                                return elm
                        })
                        if (!CustomJS.isNullEmpty(idx)) {
                            document.getElementById(`gridPrezziario`).ej2_instances[0].selectedRowIndex = idx;
                        }
                    }, 300)
                }
            }}>{this.contenutoGridPrezziario(numDann, numRiga)}</CallbackWrapper>);
        };
        this.dialogInstance0.show();
    }

    contenutoGridPrezziario(numDann, numRiga) {
        return <React.Fragment>
            <h6 style={{ fontSize: '13.5px', textAlign: 'center' }}>Prezziario</h6>
            <GridComponent id="gridPrezziario" dataSource={this.dsPrezziarioCompleto} recordDoubleClick={this.inserisciValorePrezziarioSuDropDown.bind(this, numDann, numRiga)} ref={grid => this.gridInstancePrezziario = grid}
                toolbar={this.toolbarOptions} allowSelection={true} enableHover={true} allowTextWrap={true} allowPaging={true} allowSorting={true} enablePersistence={false} pageSettings={this.pageSettings}
                style={{ border: '0px' }}>
                <ColumnsDirective>
                    <ColumnDirective field='DESCR_LUNGA' width="80%" headerText="Descrizione" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='COD_ELE' visible={false} headerText="Codice Elemento" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='DESCRIZ_CORTA' visible={false} headerText="Descrizione Corta" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='COD_CATEGORIA' visible={false} headerText="Categoria" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='MISURA' headerText="M" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='PREZZO' headerText="€" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Toolbar, Edit, ExcelExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
            </GridComponent>
        </React.Fragment>
    }

    distruggiDialog() {
        if (document.getElementById('dialogPrezziario'))
            document.getElementById('dialogPrezziario').ej2_instances[0]?.destroy();
        if (document.getElementById('dialogEliminaCalcoli'))
            document.getElementById('dialogEliminaCalcoli').ej2_instances[0]?.destroy();
        //
    }

    //CONTROLLI PER COMPAGNIA VALIDI SOLO PER I SINISTRI IMPORTATI!
    isCompagniaGenerali() {
        return this.props.IdCompagnia.toString() === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(this.props.IdIncarico)
    }
    isCompagniaUnipol() {
        return this.props.IdCompagnia.toString() === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(this.props.IdIncaricoUnipol)
    }

    dialogEliminaCalcoli() {
        this.dialogInstanceElimina.height = '25%';
        this.dialogInstanceElimina.minHeight = '25%';
        this.dialogInstanceElimina.width = '40%';
        this.dialogInstanceElimina.beforeOpen = (args) => {
            const container = document.getElementById('containerDialogEliminaCalcoli');
            const root = createRoot(container);
            root.unmount();
        }
        this.dialogInstanceElimina.open = (args) => {
            const container = document.getElementById('containerDialogEliminaCalcoli');
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
            }}>{<>
                <div style={{ textAlign: 'center' }}>
                    <h4 style={{ color: 'red' }}>ATTENZIONE!</h4>
                    <h6>Sei sicuro di voler eliminare i dati di questa pagina ?</h6>
                </div>
            </>}</CallbackWrapper>);
        }
        this.dialogInstanceElimina.buttons = [
            {
                click: () => {
                    this.dialogInstanceElimina.hide();
                    this.eliminaCalcoli();

                },
                buttonModel: {
                    content: 'ELIMINA',
                    cssClass: 'e-primary',
                    isPrimary: true,
                }
            },
            {
                click: () => {
                    this.dialogInstanceElimina.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogInstanceElimina.show();
    }

    eliminaCalcoli() {
        this.toggleBlocking2(true);

        //--------------------------CARICO DATI----------------------------------------
        const QueryExecIdPreesistenza = (`SELECT id_preesistenza FROM _preesistenza_xpert where id_sinistro = '${this.props.IdSinistro}'`);
        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdPreesistenza, '', retDataIdPrees => {
            retDataIdPrees = JSON.parse(retDataIdPrees.data);
            for (let index = 0; index < retDataIdPrees.length; index++) {
                this.strutturaStatiEliminaDati.idPreesistenzaXpert.push(retDataIdPrees[index].id_preesistenza)
            }
            //ID PREESISTENZA COSTRUZIONI
            const QueryExecIdPreesCostr = (`SELECT id_preesistenza_costruzione FROM _preesistenza_costruzioni where id_sinistro = '${this.props.IdSinistro}'`);
            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdPreesCostr, '', retDataIdPreesCostr => {
                retDataIdPreesCostr = JSON.parse(retDataIdPreesCostr.data);
                for (let index = 0; index < retDataIdPreesCostr.length; index++) {
                    this.strutturaStatiEliminaDati.idPreesistenzaCostruz.push(retDataIdPreesCostr[index].id_preesistenza_costruzione)
                }
                //ID PREESISTENZA DESCRIZIONI
                const QueryExecIdPreesDescr = (`SELECT id_preesistenza_descrizione FROM _preesistenza_descrizioni where id_sinistro = '${this.props.IdSinistro}'`);
                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdPreesDescr, '', retDataIdPreesDescr => {
                    retDataIdPreesDescr = JSON.parse(retDataIdPreesDescr.data);
                    for (let index = 0; index < retDataIdPreesDescr.length; index++) {
                        this.strutturaStatiEliminaDati.idPreesistenzaDescr.push(retDataIdPreesDescr[index].id_preesistenza_descrizione)
                    }
                    //ID PREESISTENZA GENERALI
                    const QueryExecIdPreesGen = (`SELECT id_preesistenza FROM GENERALI_partita_preesistenze where id_incarico = '${this.props.IdIncarico}'`);
                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdPreesGen, '', retDataIdPreesGen => {
                        retDataIdPreesGen = JSON.parse(retDataIdPreesGen.data);
                        for (let index = 0; index < retDataIdPreesGen.length; index++) {
                            this.strutturaStatiEliminaDati.idPreesistenzaGen.push(retDataIdPreesGen[index].id_preesistenza)
                        }
                        const QueryExecTabStima = ``; //todo query stima
                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecTabStima, '', retDataStime => {
                            retDataStime = JSON.parse(retDataStime.data);
                            for (let index = 0; index < retDataStime.length; index++) {
                                this.strutturaStatiEliminaDati.idStime.push({
                                    keyXpert: retDataStime[index].id_stima,
                                    keyGen: retDataStime[index].id_danno
                                })
                            }
                            //ID STIME DESCRIZIONI
                            const QueryExecIdStimeDescr = (`SELECT T1.id_stima_descrizione,T2.id_dettaglio FROM _stime_descrizioni T1 left outer join GENERALI_danno_dettagli T2 on T1.id_stima_descrizione = T2.id_stima_descrizione where id_sinistro = ${this.props.IdSinistro}`);
                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdStimeDescr, '', retDataIdStimeDescr => {
                                retDataIdStimeDescr = JSON.parse(retDataIdStimeDescr.data);
                                for (let index = 0; index < retDataIdStimeDescr.length; index++) {
                                    this.strutturaStatiEliminaDati.idStimeDescrizioni.push({
                                        keyXpert: retDataIdStimeDescr[index].id_stima_descrizione,
                                        keyGen: retDataIdStimeDescr[index].id_dettaglio,
                                    })
                                }
                                //ID GENERALI DANNO DETTAGLI INFO RICHIESTE
                                const QueryExecIdDannoDettInfoRichGen = (`SELECT id_info_richiesta,id_dettaglio FROM GENERALI_dannoDettagli_infoRichieste where id_incarico = '${this.props.IdIncarico}';`);
                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdDannoDettInfoRichGen, '', retDataIdDannoDettInfoRichGen => {
                                    retDataIdDannoDettInfoRichGen = JSON.parse(retDataIdDannoDettInfoRichGen.data);
                                    for (let index = 0; index < retDataIdDannoDettInfoRichGen.length; index++) {
                                        this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.push(
                                            {
                                                idInfo: retDataIdDannoDettInfoRichGen[index].id_info_richiesta,
                                                idDettaglio: retDataIdDannoDettInfoRichGen[index].id_dettaglio
                                            }

                                        )
                                    }
                                    //ID COASSICURAZIONI
                                    const QueryExecCoass = (`SELECT T1.id_compagnia_coass,T2.id_coassi_indiretta FROM _righeCompagnieCoassicurazione T1 left outer join GENERALI_coassi_indirette T2 on T1.id_compagnia_coass = T2.id_compagnia_coass_xpert WHERE id_sinistro = ${this.props.IdSinistro};`);
                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecCoass, '', retDataCoass => {
                                        retDataCoass = JSON.parse(retDataCoass.data);
                                        for (let index = 0; index < retDataCoass.length; index++) {
                                            this.strutturaStatiEliminaDati.idCoassicurazioni.push({
                                                keyXpert: retDataCoass[index].id_compagnia_coass,
                                                keyGen: retDataCoass[index].id_coassi_indiretta
                                            })
                                        }
                                        //ID INFO RICHIESTE
                                        const QueryExecInfoRichieste = (`SELECT T1.id_info_richieste_compagnia, T2.id_info_richiesta FROM _info_richieste_compagnia_riep T1 left outer join GENERALI_danno_infoRichieste T2 on T1.id_info_richieste_compagnia = T2.id_info_rich_xpert where id_sinistro = ${this.props.IdSinistro};`);
                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecInfoRichieste, '', retDataInfo => {
                                            retDataInfo = JSON.parse(retDataInfo.data);
                                            for (let index = 0; index < retDataInfo.length; index++) {
                                                this.strutturaStatiEliminaDati.idInfoRichieste.push({
                                                    keyXpert: retDataInfo[index].id_info_richieste_compagnia,
                                                    keyGen: retDataInfo[index].id_info_richiesta,
                                                })
                                            }
                                            //ID DANNEGGIATI GEN
                                            const QueryExecTabDanneggiati = `SELECT distinct T2.id_danneggiato as guid_danneggiato,T3.id_liquidazione FROM acdanneggiati T1 left outer join  GENERALI_danneggiati T2 on T1.id_danneggiato = T2.id_danneggiato_xpert left outer join GENERALI_danneggiato_liquidazioni T3 on T2.id_danneggiato = T3.id_danneggiato WHERE T1.id_sinistro = '${this.props.IdSinistro}';`;
                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecTabDanneggiati, '', retDataDann => {
                                                retDataDann = JSON.parse(retDataDann.data);
                                                for (let d = 0; d < retDataDann.length; d++) {
                                                    this.strutturaStatiEliminaDati.idDanneggiatiGen.push({
                                                        keyGen: retDataDann[d].guid_danneggiato,
                                                        keyGenLiquidazione: retDataDann[d].id_liquidazione
                                                    })
                                                }
                                                //ID RIEPILOGO
                                                const QueryExecIdRiep = (`SELECT idRiepilogo_perizia FROM _riepilogo_perizia where id_sinistro = ${this.props.IdSinistro};`);
                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdRiep, '', retDataIdPerizia => {
                                                    retDataIdPerizia = JSON.parse(retDataIdPerizia.data);
                                                    if (retDataIdPerizia.length > 0) {
                                                        this.strutturaStatiEliminaDati.idRiepilogoPerizia = retDataIdPerizia[0].idRiepilogo_perizia;
                                                        const QueryExecIdRiepDanneggiati = (`SELECT idDanneggiatoRiep FROM _perizia_riepilogo_danneggiati where id_sinistro = ${this.props.IdSinistro};`);
                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdRiepDanneggiati, '', retDataIdPeriziaDann => {
                                                            retDataIdPeriziaDann = JSON.parse(retDataIdPeriziaDann.data);
                                                            for (let index = 0; index < retDataIdPeriziaDann.length; index++) {
                                                                this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.push(retDataIdPeriziaDann[index].idDanneggiatoRiep)
                                                            }
                                                            const QueryExecIdRiepRigheDanneggiato = (`SELECT idGarPart FROM _perizia_riep_righeDanneggiato where id_sinistro = ${this.props.IdSinistro};`);
                                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdRiepRigheDanneggiato, '', retDataIdPeriziaRigheDann => {
                                                                retDataIdPeriziaRigheDann = JSON.parse(retDataIdPeriziaRigheDann.data);
                                                                for (let index = 0; index < retDataIdPeriziaRigheDann.length; index++) {
                                                                    this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.push(retDataIdPeriziaRigheDann[index].idGarPart)
                                                                }

                                                                const QueryExecIdCausali = (`SELECT id_causale,id_danno FROM GENERALI_danno_causali_nonIndennizzo where id_incarico = '${this.props.IdIncarico}';`);
                                                                CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'GET', QueryExecIdCausali, '', retDataIdCaus => {
                                                                    retDataIdCaus = JSON.parse(retDataIdCaus.data);
                                                                    if (retDataIdCaus.length > 0) {
                                                                        for (let index = 0; index < retDataIdCaus.length; index++) {
                                                                            this.strutturaStatiEliminaDati.idCausali.push({
                                                                                idCausale: retDataIdCaus[index].id_causale,
                                                                                idDanno: retDataIdCaus[index].id_danno
                                                                            })
                                                                        }
                                                                    }
                                                                    //---------------------------ELIMINO DATI---------------------------------------
                                                                    let tempoOneDelete = 75;
                                                                    let tempoPreesistenze = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaXpert.length;
                                                                    let tempoPreesCostruz = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaCostruz.length;
                                                                    let tempoPreesDescr = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaDescr.length;
                                                                    let tempoPreesGen = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaGen.length;
                                                                    let tempoStimeDescr = tempoOneDelete * this.strutturaStatiEliminaDati.idStimeDescrizioni.length;
                                                                    let tempoDannoDettInfoRichGen = tempoOneDelete * this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.length;
                                                                    let tempoCoassicurazioni = tempoOneDelete * this.strutturaStatiEliminaDati.idCoassicurazioni.length;
                                                                    let tempoInfoRichiesteGen = tempoOneDelete * this.strutturaStatiEliminaDati.idInfoRichieste.length;
                                                                    let tempoIdDanneggiati = tempoOneDelete * this.strutturaStatiEliminaDati.idDanneggiatiGen.length;
                                                                    let tempoUnipol = tempoOneDelete;
                                                                    let tempoTotaliPerizia = tempoOneDelete;
                                                                    let tempoIdRiepilogo = tempoOneDelete;
                                                                    let tempoIdRiepilogoDanneggiati = tempoOneDelete * this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.length;
                                                                    let tempoIdRiepilogoRigheDanneggiato = tempoOneDelete * this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.length;
                                                                    let tempoIdCausali = tempoOneDelete * this.strutturaStatiEliminaDati.idCausali.length;
                                                                    //TOTALE
                                                                    let tempoTotale = tempoPreesistenze + tempoPreesCostruz + tempoPreesDescr + tempoPreesGen + tempoStimeDescr +
                                                                        tempoDannoDettInfoRichGen + tempoCoassicurazioni + tempoInfoRichiesteGen + tempoIdDanneggiati + tempoUnipol +
                                                                        tempoTotaliPerizia + tempoIdRiepilogo + tempoIdRiepilogoDanneggiati + tempoIdRiepilogoRigheDanneggiato + tempoIdCausali;


                                                                    console.log(this.strutturaStatiEliminaDati)
                                                                    //ELIMINA PREESISTENZE
                                                                    this.strutturaStatiEliminaDati.idPreesistenzaXpert.forEach(elm => {
                                                                        let queryEliminaPreesistenze = `DELETE FROM _preesistenza_xpert WHERE id_preesistenza = ${elm};`;
                                                                        let jsonObjectEliminaPreesistenze = {
                                                                            QueryExec: queryEliminaPreesistenze,
                                                                        }
                                                                        console.log(jsonObjectEliminaPreesistenze)
                                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaPreesistenze), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            }
                                                                            );
                                                                        });
                                                                    })
                                                                    //ELIMINA PREESISTENZE COSTRUZIONI
                                                                    this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(elm => {
                                                                        let queryEliminaPreesistenzeCostr = `DELETE FROM _preesistenza_costruzioni WHERE id_preesistenza_costruzione = ${elm};`;
                                                                        let jsonObjectEliminaPreesistenzeCostr = {
                                                                            QueryExec: queryEliminaPreesistenzeCostr,
                                                                        }
                                                                        console.log(jsonObjectEliminaPreesistenzeCostr)
                                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaPreesistenzeCostr), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            }
                                                                            );
                                                                        });
                                                                    })
                                                                    //ELIMINA PREESISTENZE DESCRIZIONI
                                                                    this.strutturaStatiEliminaDati.idPreesistenzaDescr.forEach(elm => {
                                                                        let queryEliminaPreesistenzeDescr = `DELETE FROM _preesistenza_descrizioni WHERE id_preesistenza_descrizione = ${elm};`;
                                                                        let jsonObjectEliminaPreesistenzeDescr = {
                                                                            QueryExec: queryEliminaPreesistenzeDescr,
                                                                        }
                                                                        console.log(jsonObjectEliminaPreesistenzeDescr)
                                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaPreesistenzeDescr), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            }
                                                                            );
                                                                        });
                                                                    })
                                                                    //ELIMINA PREESISTENZE GENERALI
                                                                    if (this.isCompagniaGenerali()) {
                                                                        this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(elm => {
                                                                            let queryEliminaPreesistenzeGen = `DELETE FROM GENERALI_partita_preesistenze WHERE id_preesistenza = ${elm};`;
                                                                            let jsonObjectEliminaPreesistenzeGen = {
                                                                                QueryExec: queryEliminaPreesistenzeGen,
                                                                            }
                                                                            console.log(jsonObjectEliminaPreesistenzeGen)
                                                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaPreesistenzeGen), retDataEliminato => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                }
                                                                                );
                                                                            });
                                                                        })
                                                                    }
                                                                    //ELIMINO STIME DESCRIZIONI
                                                                    this.strutturaStatiEliminaDati.idStimeDescrizioni.forEach(elm => {
                                                                        let queryEliminaStimaDescr = `DELETE FROM _stime_descrizioni WHERE id_stima_descrizione = ${elm.keyXpert}; DELETE FROM GENERALI_danno_dettagli WHERE id_dettaglio = ${elm.keyGen};`;
                                                                        let jsonObjectEliminaStimaDescr = {
                                                                            QueryExec: queryEliminaStimaDescr,
                                                                        }
                                                                        console.log(jsonObjectEliminaStimaDescr)
                                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaStimaDescr), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            }
                                                                            );
                                                                        });
                                                                    })
                                                                    if (this.isCompagniaGenerali()) {
                                                                        //ELIMINO DANNO DETTAGLI INFO RICHIESTE
                                                                        this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.forEach(elm => {
                                                                            let queryEliminaDannDettInfoRich = `DELETE FROM GENERALI_dannoDettagli_infoRichieste WHERE id_info_richiesta = ${elm.idInfo};`;
                                                                            let jsonObjectEliminaDannDettInfoRich = {
                                                                                QueryExec: queryEliminaDannDettInfoRich,
                                                                            }
                                                                            console.log(jsonObjectEliminaDannDettInfoRich)
                                                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaDannDettInfoRich), retDataEliminato => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                }
                                                                                );
                                                                            });
                                                                        })
                                                                    }
                                                                    //ELIMINA COASSICURAZIONI
                                                                    this.strutturaStatiEliminaDati.idCoassicurazioni.forEach(elm => {
                                                                        let queryEliminaCoass = `DELETE FROM _righeCompagnieCoassicurazione WHERE id_compagnia_coass = ${elm.keyXpert}; DELETE FROM GENERALI_coassi_indirette WHERE id_coassi_indiretta = ${elm.keyGen};`;
                                                                        let jsonObjectEliminaCoass = {
                                                                            QueryExec: queryEliminaCoass,
                                                                        }
                                                                        console.log(jsonObjectEliminaCoass)
                                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaCoass), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            }
                                                                            );
                                                                        });
                                                                    })
                                                                    //ELIMINA INFO RICHIESTE
                                                                    this.strutturaStatiEliminaDati.idInfoRichieste.forEach(elm => {
                                                                        let queryEliminaInfoRich = `DELETE FROM _info_richieste_compagnia_riep WHERE id_info_richieste_compagnia = ${elm.keyXpert}; DELETE FROM GENERALI_danno_infoRichieste WHERE id_info_richiesta = ${elm.keyGen};`;
                                                                        let jsonObjectEliminaInfoRich = {
                                                                            QueryExec: queryEliminaInfoRich,
                                                                        }
                                                                        console.log(jsonObjectEliminaInfoRich)
                                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaInfoRich), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            }
                                                                            );
                                                                        });
                                                                    })
                                                                    if (this.isCompagniaGenerali()) {
                                                                        this.strutturaStatiEliminaDati.idDanneggiatiGen.forEach(elm => {
                                                                            let queryUpdateDanneggiatiGen = `START TRANSACTION;UPDATE GENERALI_danneggiati set importo_netto = null, importo_lordo = null WHERE id_danneggiato = '${elm.keyGen}';UPDATE GENERALI_danneggiato_liquidazioni set arrotondamento = 0, importo_liquidazione = 0 WHERE id_liquidazione = '${elm.keyGenLiquidazione}';COMMIT;`;
                                                                            let jsonObjectUpdateDanneggiati = {
                                                                                QueryExec: queryUpdateDanneggiatiGen,
                                                                            }
                                                                            console.log(jsonObjectUpdateDanneggiati)
                                                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectUpdateDanneggiati), retDataUpdate => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                }
                                                                                );
                                                                            });
                                                                        })
                                                                    }
                                                                    //UPDATE UNIPOL PERIZIA IMPORTI
                                                                    if (this.isCompagniaUnipol()) {
                                                                        let queryUpdatePeriziaUnipol = `update UNIPOLSAI_Perizia set importoPeriziato = null, importoConcordato = null, importoLiquidabile = null, presenzaAltreAssicurazioniStessoRischio = null where id_sinistro_xpert = ${this.props.IdSinistro};`;
                                                                        let jsonObjectUpdateUnipol = {
                                                                            QueryExec: queryUpdatePeriziaUnipol,
                                                                        }
                                                                        console.log(jsonObjectUpdateUnipol)
                                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectUpdateUnipol), retDataUpdate => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            }
                                                                            );
                                                                        });
                                                                    }
                                                                    let queryUpdatePeriziaTot = `update _perizia_incarico_info set tot_indennizzo_lordo_compl = null, tot_indennizzo_netto_compl = null, tot_indennizzo_netto_compl_arrot = null, tot_danni_accertati = null, periziaNoCalcoli = 0 where id_sinistro = ${this.props.IdSinistro}`;
                                                                    let jsonObjectUpdateTot = {
                                                                        QueryExec: queryUpdatePeriziaTot,
                                                                    }
                                                                    console.log(jsonObjectUpdateTot)
                                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectUpdateTot), retDataUpdate => {
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        }
                                                                        );
                                                                    });
                                                                    //ELIMINO DATI DAL RIEPILOGO
                                                                    //RIEPILOGO
                                                                    let queryRiep = `delete from _riepilogo_perizia where idRiepilogo_perizia = ${this.strutturaStatiEliminaDati.idRiepilogoPerizia};`;
                                                                    let jsonObjectRiep = {
                                                                        QueryExec: queryRiep,
                                                                    }
                                                                    console.log(jsonObjectRiep)
                                                                    CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectRiep), eliminatoRiep => {
                                                                        //ELIMINO DANNEGGIATI RIEPILOGO
                                                                        this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.forEach(idDann => {
                                                                            let query = `delete from _perizia_riepilogo_danneggiati where idDanneggiatoRiep = ${idDann};`;
                                                                            let jsonObject = {
                                                                                QueryExec: query,
                                                                            }
                                                                            console.log(jsonObject)
                                                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), eliminato => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                }
                                                                                );
                                                                            });
                                                                        })
                                                                        //ELIMINO RIGHE PARTITA GARANZIA PER OGNI DANNEGGIATO
                                                                        this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.forEach(idRiga => {
                                                                            let query = `delete from _perizia_riep_righeDanneggiato where idGarPart = ${idRiga};`;
                                                                            let jsonObject = {
                                                                                QueryExec: query,
                                                                            }
                                                                            console.log(jsonObject)
                                                                            CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObject), eliminato => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                }
                                                                                );
                                                                            });
                                                                        })
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        }
                                                                        );
                                                                    });
                                                                    //ELIMINA CAUSALI
                                                                    this.strutturaStatiEliminaDati.idCausali.forEach(elm => {
                                                                        let queryEliminaCaus = `DELETE FROM GENERALI_danno_causali_nonIndennizzo WHERE id_causale = ${elm.idCausale};`;
                                                                        let jsonObjectEliminaCaus = {
                                                                            QueryExec: queryEliminaCaus,
                                                                        }
                                                                        console.log(jsonObjectEliminaCaus)
                                                                        CustomJS.eseguiQuery(`AccessoDatiSIPAEVO`, CustomJS.tokenAuthAPIJellyfishSIPAEVO, 'POST', '', JSON.stringify(jsonObjectEliminaCaus), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            }
                                                                            );
                                                                        });
                                                                    })
                                                                    this.distruggiDialog();
                                                                    setTimeout(() => {
                                                                        this.toggleBlocking2(false);
                                                                        toast.success("Calcoli eliminati!", {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        });
                                                                        const container = document.getElementById('contenutoDinamico2');
                                                                        const root = createRoot(container);
                                                                        root.render(<CallbackWrapper callback={() => {
                                                                        }}><PaginaPeriziaOnlineCalcoli restituito={this.props.restituito} key={this.childKey} IdIncarico={this.props.IdIncarico} IdIncaricoUnipol={this.props.IdIncaricoUnipol} IdSinistro={this.props.IdSinistro} IdCompagnia={this.props.IdCompagnia} numeroPolizza={this.props.numeroPolizza} tipoSinistro={this.props.tipoSinistro}></PaginaPeriziaOnlineCalcoli></CallbackWrapper>);
                                                                    }, tempoTotale)
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                });
                                                            }, messaggio => {
                                                                toast.warn(messaggio, {
                                                                    containerId: 'toastContainer1',
                                                                    onClose: () => toast.dismiss(),
                                                                    position: "bottom-right",
                                                                    autoClose: 5000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: false,
                                                                    progress: undefined,
                                                                    newestOnTop: true,
                                                                    rtl: false,
                                                                    pauseOnFocusLoss: true
                                                                });
                                                            });
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        });
                                                    }
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                });
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            });
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });
                                        });
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    });
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                });
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    });
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                });
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            });
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });






    }

}

