import React, { Component } from 'react';
import { createRoot } from 'react-dom/client'
import {setCulture, setCurrencyCode} from '@syncfusion/ej2-base';
import { loadCldr, L10n } from '@syncfusion/ej2-base';
import PaginaLogin from './components/PaginaLogin';
import itNumberData from 'cldr-numbers-full/main/it/numbers.json';
import itTimeZoneData from 'cldr-dates-full/main/it/timeZoneNames.json';
import itCAGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import itNumberSystems from 'cldr-core/supplemental/numberingSystems.json';
import itCurrencies from 'cldr-numbers-full/main/it/currencies.json';
import itTranslations from '@syncfusion/ej2-locale/src/it.json';

L10n.load(itTranslations);
L10n.load({
    'it': {
        datepicker: { day: 'Giorno' , month: 'Mese', year: 'Anno' }
    }
});
setCulture('it');
setCurrencyCode('EUR');
loadCldr(itNumberData, itTimeZoneData, itCAGregorian, itNumberSystems, itCurrencies);

export default class App extends Component {   
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }
    state = {
        blocking: false
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    render() {
        return (<div id='contenutoDinamico1'></div>);
    }
    componentDidMount() {
        const container = document.getElementById('contenutoDinamico1');
        const root = createRoot(container); 
        root.render(<PaginaLogin></PaginaLogin>);
    }
}